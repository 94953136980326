import { Base } from '../data';
export class WidgetService {
  public options: any;
  public name: string;

  constructor(options) {
    this.options = options;
    this.name = options.name;
  }

  getDialogPanel(child: Base) {
    return child.initFilterDialog();
  }

  getDrawPanel(child: Base) {
    let drawPanel: any = child.initDrawPanel();
    drawPanel.serviceName = this.options.name;
    drawPanel.style = Object.assign(drawPanel.style);
    if (child.filterDialog) {
      const dialogOptions = child.initFilterDialog();
      drawPanel = Object.assign(drawPanel, dialogOptions);
    }
    return drawPanel;
  }
}
