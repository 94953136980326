import React, { FC, useState, useEffect, useRef, CSSProperties } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import './index.less';

type VideoFormat = 'video/mp4' | 'video/webm';
type EditModeType = 'edit' | 'preview';
interface IProps {
  option: {
    loop: boolean;
    autoPlay: boolean;
    data: string;
    source: {
      type: VideoFormat;
    };
  };
  compStyle?: CSSProperties;
  editMode?: EditModeType;
}
const Video: FC<IProps> = ({ option, compStyle, editMode }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [style, setStyle] = useState<{ [key: string]: CSSProperties }>({
    container: {
      borderRadius: 0,
      backgroundColor: 'rgba(255,255,255,1)',
      overflow: 'hidden'
    }
  });

  useEffect(() => {
    compStyle && initStyle();
  }, [compStyle]);

  useEffect(() => {
    if (option.data && (option.data.includes('mp4') || option.data.includes('webm'))) {
      videoRef.current.load();
    }
  }, [option.data]);

  const initStyle = () => {
    const newStyle = _.cloneDeep(style);
    newStyle.container.borderRadius = compStyle.borderRadius;
    newStyle.container.backgroundColor = compStyle.backgroundColor;
    setStyle({
      ...style,
      ...newStyle
    });
  };

  return (
    <div className={classNames('video-comp-wrapper', { 'not-clickable': editMode === 'edit' })} style={style.container}>
      {option.data && (
        <video
          className="video-js full"
          controls
          loop={option.loop}
          autoPlay={option.autoPlay}
          preload="auto"
          ref={videoRef}
        >
          <source src={option.data} type={option.source.type}></source>
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener noreferrer">
              supports HTML5 video
            </a>
          </p>
        </video>
      )}
      {!option.data && <p className="no-data-tips">请点击添加配置视频信息...</p>}
    </div>
  );
};

export default Video;
