import { formatNumber } from '@/utils/utils';

export const BASE_GAUGE = {
  // 因为仪表盘值能拖入一个度量，所以不需要tooltip
  // tooltip: {
  //   formatter: (data) => {
  //     return `<h4>${data.seriesName}:</h4>${data.value ? formatNumber(data.value) + '%' : 0}`;
  //   },
  // },
  series: [
    {
      name: 'Pressure',
      type: 'gauge',
      itemStyle: {},
      splitNumber: 4,
      progress: {
        show: true
      },
      axisTick: {
        length: 3,
        lineStyle: {
          color: 'rgba(204,204,204,0.55)'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(204,204,204,0.75)'
        }
      },
      axisLabel: {
        show: true,
        formatter: value => `${value}%`,
        color: 'rgba(0,0,0,0.6)'
      },
      detail: {
        valueAnimation: true,
        /* formatter: value => {
          return !value ? 0 : formatNumber(value) + '%';
        }, */
        formatter: value => `${value}%`,
        offsetCenter: [0, '90%'],
        fontSize: 20
      },
      data: []
    }
  ]
};
