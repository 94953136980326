import React, { useState } from 'react';
import cls from 'classnames';
import _ from 'lodash';
import { Tooltip, Icon, Dropdown, Menu, message } from 'antd';
import { useStore } from '@/store/useStore';
import { convertValFormat, resolveFieldCaption } from './util';
import { granularityIconMap } from '../../dataBoard/components/FieldsOperation/contrast';
import SvgIcon from '@/components/SvgIcon';
import ModalFilter from './modal-filter';
import ModalGranularityFieldFilter from './modal-granularity-field-filter';
import ModalContrast from './modal-contrast';
import ModalSettingCompareField from './modal-setting-compare-field';
import ModalDisplayFormatSetting from './modal-display-format-setting';
import ModalSettingFieldAliasName from './modal-setting-field-alias-name';
import { filterOutNormalFields, getContrastMenuByFieldGranularity, generateContrastConfig } from './contrast';
import {
  FIELD_VALUE_TYPE_ICONS,
  SORT_TYPES,
  CALCULATOR_TYPES,
  GRANULARITY_TYPE_ICONS,
  DIMENSION_FIELD_DROPDOWN_MENU,
  MEASURE_FIELD_DROPDOWN_MENU,
  CONTRAST_BASE_MENU,
  CONTRAST_TYPES,
  DATE_DISPLAY_FORMAT_TYPES,
  VALUE_DISPLAY_FORMAT,
  granularityMap
} from '@/utils/enum';
import { generateDateDisplayFormatMenu } from '@/pages/DataSet/Edit/util';

const { SubMenu } = Menu;
function FieldItem({ itemField, fieldAreaType, index, onChartTypeValueChange }) {
  const { dashboardStore } = useStore();
  const { activeChart } = dashboardStore;
  const {
    attribute: { type, valueType, dateFormat, customFieldFunction, granularity, valueMagnitude },
    sort,
    sortType,
    calculatorType,
    customName,
    derivedFields,
    chartType
  } = itemField;
  const [visibleModalFilter, setVisibleModalFilter] = useState(false);
  const [contrastConfig, setContrastConfig] = useState(generateContrastConfig());
  const [visibleModalSettingCompareField, setVisibleModalSettingCompareField] = useState(false);
  const [derivedFieldFilterConfig, setDerivedFieldFilterConfig] = useState({
    visible: false,
    dataSetName: '',
    itemField: null
  });
  const [displayFormatConfig, setDisplayFormatConfig] = useState({
    visible: false,
    pattern: '#,##0.0',
    valueMagnitude: valueMagnitude || 'MAGNITUDE_NONE'
  });
  const [aliasNameConfig, setAliasNameConfig] = useState({
    visible: false,
    extraConfigField: null
  });

  const renderDropDownMenu = (fieldType, itemField) => {
    const selectedKeys = [];
    const {
      sort,
      sortType,
      calculatorType,
      ratioCalculator,
      attribute: { type, valueType, granularity, displayFormat, dataSetFieldValueFormat }
    } = itemField;
    const isDateField = type === 'DIMENSION' && valueType === 'DATE';
    if (sort && sortType) selectedKeys.push(sortType);
    if (calculatorType) selectedKeys.push(calculatorType);
    if (ratioCalculator)
      selectedKeys.push(
        ratioCalculator.contrastType === 'SPECIFIC' ? ratioCalculator.type : ratioCalculator.contrastType
      );
    if (isDateField && displayFormat) {
      selectedKeys.push(displayFormat);
    }
    if (type === 'MEASURE' && dataSetFieldValueFormat) {
      selectedKeys.push(dataSetFieldValueFormat);
    }
    const loop = data =>
      data.map(item => {
        if (item.children) {
          return (
            <SubMenu key={item.label} title={item.label} popupClassName="field-dropdown-submenu">
              {loop(item.children)}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={item.value} onClick={() => handleMenuItemClick(item.value)}>
            {item.label}
          </Menu.Item>
        );
      });
    // console.log('renderDropDownMenu');
    const dimFieldDropdownMenu = isDateField
      ? [...generateDateDisplayFormatMenu(granularityMap[granularity]), ...DIMENSION_FIELD_DROPDOWN_MENU]
      : DIMENSION_FIELD_DROPDOWN_MENU;
    const meaFieldDropdownMenu = [
      ...mergeContrastMenu(),
      {
        label: '字段显示内容',
        value: 'ALIAS_NAME'
      }
    ];
    if (activeChart.chartType !== 'tableMultidimensional') {
      // 对比交叉表不显示格式化
      meaFieldDropdownMenu.unshift({
        label: '数据展示格式',
        children: [].concat(
          Object.keys(VALUE_DISPLAY_FORMAT).map(key => ({
            label: VALUE_DISPLAY_FORMAT[key],
            value: key
          })),
          [{ label: '自定义', value: 'CUSTOM' }]
        )
      });
    }
    return (
      <Menu selectedKeys={selectedKeys}>
        {loop(fieldType === 'dimension' ? dimFieldDropdownMenu : meaFieldDropdownMenu)}
      </Menu>
    );
  };

  const mergeContrastMenu = () => {
    // 动态生成同环比菜单
    // 1.判断维度字段列表中是否存在粒度字段，存在则追加同环比菜单
    // 2.如果拖入的维度字段列表只有一个粒度字段，根据映射规则生成对应菜单
    // 3.如果存在多个粒度字段，菜单列表中只会保留自定义和无，且自定义对比类型取高权重粒度
    const isDimFieldsIncludeDerivedField = activeChart.dataConfig.dataSetFields.some(
      field => field.attribute.valueType === 'DATE' && field.attribute.granularity
    );
    let measureDropdownMenu = MEASURE_FIELD_DROPDOWN_MENU;
    if (isDimFieldsIncludeDerivedField) {
      const isDimFieldAreaEmpty =
        !activeChart.dataConfig.dimensionFields ||
        (Array.isArray(activeChart.dataConfig.dimensionFields) && !activeChart.dataConfig.dimensionFields.length);
      if (isDimFieldAreaEmpty) {
        measureDropdownMenu = [
          ...MEASURE_FIELD_DROPDOWN_MENU,
          {
            label: '同环比',
            children: [...CONTRAST_BASE_MENU]
          }
        ];
      } else {
        const derivedFields = filterOutNormalFields(activeChart.dataConfig.dimensionFields);
        const size = derivedFields.length;
        measureDropdownMenu = [
          ...MEASURE_FIELD_DROPDOWN_MENU,
          {
            label: '同环比',
            children:
              size === 1
                ? [
                    ...getContrastMenuByFieldGranularity(
                      GRANULARITY_TYPE_ICONS[derivedFields[0].attribute.granularity]
                    ),
                    ...CONTRAST_BASE_MENU
                  ]
                : [...CONTRAST_BASE_MENU]
          }
        ];
      }
    }
    return measureDropdownMenu;
  };

  const handleMenuItemClick = selectedVal => {
    isItemFieldUsedByWarningConfig();
    // 同环比设置
    if (selectedVal === 'CONTRAST_CUSTOM' || selectedVal === 'NONE' || selectedVal.includes('RATIO')) {
      contrastConfigHandler(selectedVal);
      return;
    }
    // 数值展示格式设置
    if (selectedVal === 'CUSTOM') {
      setDisplayFormatConfig({
        ...displayFormatConfig,
        visible: true
      });
      return;
    }
    // 字段别名设置
    if (selectedVal === 'ALIAS_NAME') {
      let extraConfigField;
      if (activeChart.chartType === 'combination') {
        extraConfigField = activeChart.dataConfig.extraConfigFieldMap[fieldAreaType].find((_, idx) => idx === index);
      } else {
        extraConfigField = activeChart.dataConfig.extraConfigFields.find((_, idx) => idx === index);
      }
      setAliasNameConfig({
        visible: true,
        extraConfigField
      });
      return;
    }
    const updatedItem = _.cloneDeep(activeChart);
    const isSortType = Object.keys(SORT_TYPES).includes(selectedVal);
    const isCalculatorType = Object.keys(CALCULATOR_TYPES).includes(selectedVal);
    const isDateDisplayFormatType = Object.keys(DATE_DISPLAY_FORMAT_TYPES).includes(selectedVal);
    const isValueDisplayFormatType = Object.keys(VALUE_DISPLAY_FORMAT).includes(selectedVal);
    updatedItem.dataConfig[fieldAreaType] = updatedItem.dataConfig[fieldAreaType].map((field, idx) => {
      if (idx === index) {
        const {
          sortType,
          calculatorType,
          attribute: { displayFormat, dataSetFieldValueFormat }
        } = field;
        const newSortType = isSortType ? selectedVal : sortType;
        const newCalculatorType = isCalculatorType ? selectedVal : calculatorType;
        const newDisplayFormat = isDateDisplayFormatType ? selectedVal : displayFormat;
        const newDataSetFieldValueFormat = isValueDisplayFormatType ? selectedVal : dataSetFieldValueFormat;
        return {
          ...field,
          sortType: newSortType,
          sort: isSortType ? true : field.sort,
          calculatorType: newCalculatorType,
          attribute: {
            ...field.attribute,
            sortType: newSortType,
            displayFormat: newDisplayFormat,
            dataSetFieldValueFormat: newDataSetFieldValueFormat
          }
        };
      }
      return field;
    });
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    if (isCalculatorType) {
      let updatedExtraConfigItem;
      if (activeChart.chartType === 'combination') {
        updatedExtraConfigItem = _.cloneDeep(
          activeChart.dataConfig.extraConfigFieldMap[fieldAreaType].filter((item, idx) => idx === index)[0]
        );
      } else {
        updatedExtraConfigItem = _.cloneDeep(
          activeChart.dataConfig.extraConfigFields.filter((item, idx) => idx === index)[0]
        );
      }
      const [uniqueId, calculatorType, contrastType, contrastValueType] = updatedExtraConfigItem.vid.split('|');
      updatedExtraConfigItem.calculatorType = selectedVal;
      updatedExtraConfigItem.vid = [uniqueId, selectedVal, contrastType, contrastValueType].join('|');
      let params: any = {
        frontChartId: activeChart.frontChartId,
        index,
        updatedItem: updatedExtraConfigItem
      };
      if (activeChart.chartType === 'combination') {
        params.fieldAreaType = fieldAreaType;
      }
      dashboardStore.modExtraConfig(params);
    }
  };

  const contrastConfigHandler = val => {
    const derivedFields = filterOutNormalFields(activeChart.dataConfig.dimensionFields);
    const isDimFieldAreaIncludeDerivedField = !!derivedFields.length;
    if (val === 'CONTRAST_CUSTOM') {
      const fieldOpts = [];
      if (!isDimFieldAreaIncludeDerivedField) {
        activeChart.dataConfig.dataSetFields
          .filter(field => field.attribute.type.toLowerCase() === 'dimension')
          .forEach(field => {
            if (field.derivedFields.length) {
              fieldOpts.push(...field.derivedFields);
            }
          });
      } else {
        fieldOpts.push(...derivedFields);
      }
      const {
        uniqueId,
        attribute: { granularity }
      } = fieldOpts[0];
      const type = getContrastMenuByFieldGranularity(GRANULARITY_TYPE_ICONS[granularity])[0].value;
      const granularities = derivedFields.map(item => item.attribute.granularity);
      const ratioCalculator = activeChart.dataConfig[fieldAreaType][index].ratioCalculator;
      const fieldsValue =
        ratioCalculator && granularities.includes(ratioCalculator.granularity)
          ? ratioCalculator
          : {
              ...contrastConfig.fieldsValue,
              dateField: uniqueId,
              type,
              granularity
            };
      setContrastConfig({
        ...contrastConfig,
        visibleModalContrastConfig: true,
        fieldOpts,
        fieldsValue,
        hasDynamicRingRatio: !isDimFieldAreaIncludeDerivedField
      });
    } else if (val === 'NONE' || val.includes('RATIO')) {
      contrastTypeValChangeHandler(val, derivedFields.length ? derivedFields[0] : null);
      if (val === 'NONE') {
        resetContrastConfig();
      } else {
        const {
          uniqueId,
          attribute: { granularity }
        } = activeChart.dataConfig.measureFields[index];
        const fieldsValue = {
          dateField: uniqueId,
          type: val,
          valueType: 'INCREASE_RATIO',
          granularity,
          hasDynamicRingRatio: false
        };
        setContrastConfig({
          ...contrastConfig,
          fieldsValue,
          hasDynamicRingRatio: !isDimFieldAreaIncludeDerivedField
        });
      }
    }
  };

  const contrastTypeValChangeHandler = (val, derivedField) => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig.measureFields = updatedItem.dataConfig.measureFields.map((field, idx) => {
      if (idx === index) {
        if (!derivedField) {
          if (val === 'NONE' && field.ratioCalculator) {
            delete field.ratioCalculator;
            return field;
          }
          return {
            ...field,
            ratioCalculator: {
              contrastType: val,
              dateField: field.uniqueId
            }
          };
        }
        const {
          uniqueId,
          attribute: { granularity }
        } = derivedField;
        const contrastType = val.includes('RATIO') ? 'SPECIFIC' : val;
        const ratioCalculator: any = {
          contrastType,
          dateField: uniqueId
        };
        if (contrastType === 'SPECIFIC') {
          ratioCalculator.type = val;
          ratioCalculator.valueType = 'INCREASE_RATIO';
          ratioCalculator.granularity = granularity;
        }
        return {
          ...field,
          ratioCalculator
        };
      }
      return field;
    });
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    let updatedExtraConfigItem;
    if (activeChart.chartType === 'combination') {
      updatedExtraConfigItem = _.cloneDeep(
        activeChart.dataConfig.extraConfigFieldMap[fieldAreaType].filter((item, idx) => idx === index)[0]
      );
    } else {
      updatedExtraConfigItem = _.cloneDeep(
        activeChart.dataConfig.extraConfigFields.filter((item, idx) => idx === index)[0]
      );
    }
    const [uniqueId, calculatorType] = updatedExtraConfigItem.vid.split('|');
    updatedExtraConfigItem.contrastType = val;
    updatedExtraConfigItem.contrastValueType = 'INCREASE_RATIO';
    if (val === 'NONE') {
      updatedExtraConfigItem.contrastType = '';
      updatedExtraConfigItem.contrastValueType = '';
    }
    updatedExtraConfigItem.vid = [
      uniqueId,
      calculatorType,
      val === 'NONE' ? '' : val,
      val === 'NONE' ? '' : 'INCREASE_RATIO'
    ].join('|');
    let params: any = {
      frontChartId: activeChart.frontChartId,
      index,
      updatedItem: updatedExtraConfigItem
    };
    if (activeChart.chartType === 'combination') {
      params.fieldAreaType = fieldAreaType;
    }
    dashboardStore.modExtraConfig(params);
  };

  const cancelSettingContrast = () => {
    setContrastConfig({
      ...contrastConfig,
      visibleModalContrastConfig: false
    });
  };

  const confirmSettingContrast = formData => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig[fieldAreaType] = updatedItem.dataConfig[fieldAreaType].map((field, idx) => {
      if (idx === index) {
        return {
          ...field,
          ratioCalculator: { ...formData, contrastType: 'CONTRAST_CUSTOM' }
        };
      }
      return field;
    });
    setContrastConfig({
      ...contrastConfig,
      visibleModalContrastConfig: false,
      fieldsValue: formData
    });
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    let updatedExtraConfigItem;
    if (activeChart.chartType === 'combination') {
      updatedExtraConfigItem = _.cloneDeep(
        activeChart.dataConfig.extraConfigFieldMap[fieldAreaType].filter((item, idx) => idx === index)[0]
      );
    } else {
      updatedExtraConfigItem = _.cloneDeep(
        activeChart.dataConfig.extraConfigFields.filter((item, idx) => idx === index)[0]
      );
    }
    const [uniqueId, calculatorType] = updatedExtraConfigItem.vid.split('|');
    updatedExtraConfigItem.contrastType = formData.type;
    updatedExtraConfigItem.contrastValueType = formData.valueType;
    updatedExtraConfigItem.vid = [uniqueId, calculatorType, formData.type, formData.valueType].join('|');
    let params: any = {
      frontChartId: activeChart.frontChartId,
      index,
      updatedItem: updatedExtraConfigItem
    };
    if (activeChart.chartType === 'combination') {
      params.fieldAreaType = fieldAreaType;
    }
    dashboardStore.modExtraConfig(params);
  };

  const resetContrastConfig = () => {
    setContrastConfig(generateContrastConfig());
  };

  const handleSettingFilter = () => {
    const isDerivedField = itemField.attribute.valueType === 'DATE' && itemField.attribute.granularity !== null;
    if (isDerivedField) {
      const dataSetName = dashboardStore.state.dataSets.find(
        item => item.key === activeChart.dataConfig.dataSetId
      ).value;
      setDerivedFieldFilterConfig({
        visible: true,
        dataSetName,
        itemField
      });
      return;
    }
    setVisibleModalFilter(true);
  };

  const confirmSettingFilter = filters => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig.filterHashMap[itemField.uniqueId] = filters;
    updatedItem.dataConfig.filters = Object.values(updatedItem.dataConfig.filterHashMap).flat();
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    setVisibleModalFilter(false);
  };

  const cancelSettingFilter = () => {
    setVisibleModalFilter(false);
  };

  const handleSettingCompareField = () => {
    setVisibleModalSettingCompareField(true);
  };

  const confirmSettingCompareField = compareField => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig.compareField = compareField;
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    setVisibleModalSettingCompareField(false);
  };

  const cancelSettingCompareField = () => {
    setVisibleModalSettingCompareField(false);
  };

  const handleFieldItemRemove = ev => {
    ev.stopPropagation();
    isItemFieldUsedByWarningConfig();
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig[fieldAreaType] = updatedItem.dataConfig[fieldAreaType].filter((_, idx) => idx !== index);
    if (fieldAreaType === 'filterFields') {
      delete updatedItem.dataConfig.filterHashMap[itemField.uniqueId];
      updatedItem.dataConfig.filters = Object.values(updatedItem.dataConfig.filterHashMap).flat();
    } else if (fieldAreaType === 'compareFields') {
      updatedItem.dataConfig.compareField = null;
    }
    if (activeChart.chartType === 'combination' && fieldAreaType.includes('yaxis')) {
      updatedItem.dataConfig.extraConfigFieldMap = {
        ...updatedItem.dataConfig.extraConfigFieldMap,
        [fieldAreaType]: updatedItem.dataConfig.extraConfigFieldMap[fieldAreaType].filter((_, idx) => idx !== index)
      };
    } else if (type === 'MEASURE') {
      updatedItem.dataConfig.extraConfigFields = updatedItem.dataConfig.extraConfigFields.filter(
        (_, idx) => idx !== index
      );
    }
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
  };

  const renderFieldBox = () => {
    let fieldCaption = customName;
    if (fieldAreaType === 'measureFields' || fieldAreaType.includes('yaxis')) {
      fieldCaption += `(${CALCULATOR_TYPES[calculatorType]}${calContrastType()})`;
    }
    if (valueType === 'DATE' && dateFormat && !dateFormat.includes('HMS') && !customFieldFunction) {
      fieldCaption += `(${granularityMap[granularity]})`;
    }
    return (
      <div className={cls(['field-box-wrapper', `${type.toLowerCase()}-field-box`])}>
        <span className="field-icon">
          <i className="svg-icon-wrapper">
            <SvgIcon
              iconClass={cls({
                hierarchy: isHierarchyField,
                [GRANULARITY_TYPE_ICONS[granularity]]: isDerivedField,
                [FIELD_VALUE_TYPE_ICONS[valueType]]: isNormalField
              })}
            />
          </i>
        </span>
        {sort && sortType && (
          <span className="sort-icon">
            <i className="svg-icon-wrapper">
              <SvgIcon iconClass={sortType.toLowerCase()} />
            </i>
          </span>
        )}
        <span className="field-caption" title={fieldCaption}>
          {fieldCaption}
        </span>
        <div className="right-hover-icons">
          {(fieldAreaType === 'dimensionFields' ||
            fieldAreaType === 'measureFields' ||
            fieldAreaType.includes('axis') ||
            fieldAreaType === 'legend') && (
            <Tooltip title="更多">
              <i className="icon-wrapper icon-arrow-down">
                <SvgIcon iconClass="arrow-down" />
              </i>
            </Tooltip>
          )}
          {fieldAreaType === 'filterFields' && (
            <Tooltip title="筛选">
              <i className="icon-wrapper icon-funnel" onClick={handleSettingFilter}>
                <SvgIcon iconClass="funnel-1" />
              </i>
            </Tooltip>
          )}
          {fieldAreaType === 'compareFields' && (
            <Tooltip title="配置对比字段">
              <Icon type="setting" onClick={handleSettingCompareField} />
            </Tooltip>
          )}
          <Tooltip title="删除">
            <i className="icon-wrapper icon-del" onClick={handleFieldItemRemove}>
              <SvgIcon iconClass="delete-1" />
            </i>
          </Tooltip>
        </div>
        {fieldAreaType.includes('yaxis') && (
          <Dropdown
            overlayClassName="chart-type-dropdown-menu"
            overlay={
              <Menu
                onClick={({ key, domEvent }) => {
                  domEvent.stopPropagation();
                  onChartTypeValueChange(fieldAreaType, index, key);
                }}
              >
                <Menu.Item key="bar">
                  <i className="svg-icon-wrapper">
                    <SvgIcon iconClass="chart-histogram" />
                  </i>
                  柱
                </Menu.Item>
                <Menu.Item key="line">
                  <i className="svg-icon-wrapper">
                    <SvgIcon iconClass="chart-line" />
                  </i>
                  线
                </Menu.Item>
                <Menu.Item key="area">
                  <i className="svg-icon-wrapper">
                    <SvgIcon iconClass="chart-area" />
                  </i>
                  面
                </Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <i className="svg-icon-wrapper chart-type-icon selected-icon light">
              <SvgIcon
                iconClass={cls({
                  'chart-histogram': chartType === 'bar',
                  'chart-line': chartType === 'line',
                  'chart-area': chartType === 'area'
                })}
              />
            </i>
          </Dropdown>
        )}
      </div>
    );
  };

  const cancelDerivedFieldFilterConfig = () => {
    setDerivedFieldFilterConfig({
      ...derivedFieldFilterConfig,
      visible: false
    });
  };

  const confirmDerivedFieldFilterConfig = complexFilter => {
    const { dateFilterType, intervalType, values } = complexFilter;
    const updatedItem = _.cloneDeep(activeChart);
    let newComplexFilter = null;
    updatedItem.dataConfig.filterFields = updatedItem.dataConfig.filterFields.map((field, idx) => {
      if (idx === index) {
        newComplexFilter = {
          dateFilterType,
          intervalType,
          filters: [...(dateFilterType === 'SINGLE' ? values.slice(0, 1) : values)].map((val, valIdx) => ({
            fieldUniqueId: field.uniqueId,
            operator: dateFilterType === 'SINGLE' ? 'EQUALS' : valIdx === 0 ? 'GE' : 'LE',
            values: [convertValFormat(val, granularityIconMap[field.attribute.granularity])],
            frontAdditionalInfo: {
              type: field.attribute.type,
              valueType: field.attribute.valueType,
              fieldCustomName: field.customName,
              dateFormat: field.attribute.dateFormat,
              customFieldFunction: field.attribute.customFieldFunction,
              granularity: field.attribute.granularity,
              isDerivedField: true
            }
          }))
        };
        return {
          ...field,
          complexFilter: newComplexFilter
        };
      }
      return field;
    });
    if (updatedItem.dataConfig.filters.some(filter => filter.fieldUniqueId === itemField.uniqueId)) {
      updatedItem.dataConfig.filters = updatedItem.dataConfig.filters.filter(
        filter => filter.fieldUniqueId !== itemField.uniqueId
      );
    }
    updatedItem.dataConfig.filters = [
      ...updatedItem.dataConfig.filters,
      ...newComplexFilter.filters.map(filter => ({
        ...filter,
        dateFilterType,
        intervalType,
        conditionJoin: 'AND'
      }))
    ];
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    setDerivedFieldFilterConfig({
      ...derivedFieldFilterConfig,
      visible: false,
      itemField: {
        ...derivedFieldFilterConfig.itemField,
        complexFilter: newComplexFilter
      }
    });
  };

  const cancelSettingDisplayFormat = () => {
    setDisplayFormatConfig({
      ...displayFormatConfig,
      visible: false
    });
  };

  const confirmSettingDisplayFormat = ({ pattern, numberType, valueMagnitude }) => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig[fieldAreaType] = updatedItem.dataConfig[fieldAreaType].map((field, idx) => {
      if (idx === index) {
        return {
          ...field,
          attribute: {
            ...field.attribute,
            dataSetFieldValueFormat: 'CUSTOM',
            displayFormat: pattern,
            valueMagnitude: numberType === 'number' ? valueMagnitude : null
          }
        };
      }
      return field;
    });
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    setDisplayFormatConfig({
      ...displayFormatConfig,
      visible: false,
      pattern,
      valueMagnitude
    });
  };

  const cancelSettingAliasName = () => {
    setAliasNameConfig({
      ...aliasNameConfig,
      visible: false
    });
  };

  const confirmSettingAliasName = aliasName => {
    let updatedItem;
    if (activeChart.chartType === 'combination') {
      updatedItem = _.cloneDeep(
        activeChart.dataConfig.extraConfigFieldMap[fieldAreaType].find((_, idx) => idx === index)
      );
    } else {
      updatedItem = _.cloneDeep(activeChart.dataConfig.extraConfigFields.find((_, idx) => idx === index));
    }
    updatedItem.aliasName = aliasName;
    const params: any = {
      frontChartId: activeChart.frontChartId,
      index,
      updatedItem
    };
    if (activeChart.chartType === 'combination') {
      params.fieldAreaType = fieldAreaType;
    }
    dashboardStore.modExtraConfig(params);
    cancelSettingAliasName();
  };

  const isItemFieldUsedByWarningConfig = () => {
    if (activeChart.dataConfig.warningConfig) {
      const dependedFieldCaptions = [
        ...new Set(
          Object.values(activeChart.dataConfig.warningConfig)
            .flat(Infinity)
            .map(field => resolveFieldCaption(field))
        )
      ];
      if (dependedFieldCaptions.includes(resolveFieldCaption(itemField))) {
        message.warning('请检查该图表上的预警，可能受影响');
      }
    }
  };

  const calContrastType = () => {
    const { ratioCalculator } = itemField;
    return ratioCalculator && ratioCalculator.contrastType !== 'NONE' ? `-${CONTRAST_TYPES[ratioCalculator.type]}` : '';
  };
  const isHierarchyField = valueType === 'DATE' && derivedFields?.length;
  const isDerivedField = valueType === 'DATE' && !!customFieldFunction;
  const isNormalField = !isHierarchyField && !isDerivedField;
  const fieldType = type.toLowerCase();
  const isDimensionOrMeasureFieldArea = [
    'dimensionFields',
    'measureFields',
    'xaxis',
    'yaxis',
    'yaxisExt',
    'legend'
  ].includes(fieldAreaType);
  // console.log('render FieldItem: ', isDimensionOrMeasureFieldArea, itemField, sortType, calculatorType);
  return (
    <>
      {isDimensionOrMeasureFieldArea ? (
        <Dropdown
          overlay={renderDropDownMenu(fieldType, itemField)}
          trigger={['click']}
          placement="bottomCenter"
          overlayClassName="field-dropdown-menu"
        >
          {renderFieldBox()}
        </Dropdown>
      ) : (
        <>{renderFieldBox()}</>
      )}
      <ModalFilter
        visible={visibleModalFilter}
        filters={activeChart.dataConfig.filterHashMap[itemField.uniqueId] || []}
        itemField={itemField}
        onCancel={cancelSettingFilter}
        onOk={confirmSettingFilter}
      />
      {derivedFieldFilterConfig.visible && (
        <ModalGranularityFieldFilter
          {...derivedFieldFilterConfig}
          onCancel={cancelDerivedFieldFilterConfig}
          onOk={confirmDerivedFieldFilterConfig}
        />
      )}
      <ModalContrast
        visible={contrastConfig.visibleModalContrastConfig}
        fieldOpts={contrastConfig.fieldOpts}
        fieldsValue={contrastConfig.fieldsValue}
        hasDynamicRingRatio={contrastConfig.hasDynamicRingRatio}
        onCancel={cancelSettingContrast}
        onOk={confirmSettingContrast}
      />
      <ModalSettingCompareField
        visible={visibleModalSettingCompareField}
        dataSetId={activeChart.dataConfig.dataSetId}
        activeField={itemField}
        flattenedFields={activeChart.dataConfig.flattenedFields}
        compareField={activeChart.dataConfig.compareField}
        onCancel={cancelSettingCompareField}
        onOk={confirmSettingCompareField}
      />
      {displayFormatConfig.visible && (
        <ModalDisplayFormatSetting
          visible={displayFormatConfig.visible}
          pattern={displayFormatConfig.pattern}
          valueMagnitude={displayFormatConfig.valueMagnitude}
          onCancel={cancelSettingDisplayFormat}
          onOk={confirmSettingDisplayFormat}
        />
      )}
      {aliasNameConfig.visible && (
        <ModalSettingFieldAliasName
          visible={aliasNameConfig.visible}
          extraConfigField={aliasNameConfig.extraConfigField}
          onCancel={cancelSettingAliasName}
          onOk={confirmSettingAliasName}
        />
      )}
    </>
  );
}

export default FieldItem;
