import React from 'react';
import { Button } from 'antd';
import { IProps } from './data';

function IbResetButton(props: IProps) {
  const { widget, inDraw, onReset } = props;

  const triggerReset = () => {
    onReset();
  };

  return (
    <Button type={widget.options.attrs.type} onClick={triggerReset}>
      {widget.options.value}
    </Button>
  );
}

export default IbResetButton;
