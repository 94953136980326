import React, { FC } from 'react';
import SvgIcon from '@/components/SvgIcon';
import { granularityMap } from '@/utils/enum';

export const ICON_VALUE_TYPE_MAP = new Map().set('TEXT', 'Str').set('NUMBER', 'No').set('DATE', 'Date');
const FieldItem: FC<any> = ({ item }) => {
  // console.log('FieldItem render: ', item);
  const {
    attribute: { valueType, dateFormat, granularity, customFieldFunction },
    uniqueId,
    customName
  } = item;
  let fieldCaption = customName;
  if (valueType === 'DATE' && !dateFormat.includes('HMS') && !customFieldFunction) {
    fieldCaption += `(${granularityMap[granularity]})`;
  }
  return (
    <div className="item-field-wrapper" key={uniqueId}>
      <i className="svg-icon-wrapper">
        <SvgIcon iconClass={ICON_VALUE_TYPE_MAP.get(valueType)} />
      </i>
      <span className="field-name">{fieldCaption}</span>
    </div>
  );
};

export default FieldItem;
