// 地图色阶颜色
export const colorArr = [
  ['#85D8EE', '#71B6D3', '#5C94B8', '#47729B', '#334F80', '#1F2F65'],
  ['#9BFDFF', '#7CF2FF', '#5CE7FF', '#7CF2FF', '#1FD2FF', '#00C7FF'],
  ['#00C7FF', '#B4EFD1', '#8DE0B3', '#66CF95', '#3FBE77', '#16AE5B'],
  ['#16AE5B', '#70D1BD', '#58A1A6', '#58A1A6', '#264474', '#0D175D'],
  ['#FFD97E', '#FAC46A', '#F4AF55', '#EF9A41', '#E8852C', '#E46F1A'],
  ['#16AE5B', '#8DE0B3', '#FAC46A', '#EF9A41', '#C26678', '#A00721'],
  ['#E2E2E2', '#C4C4C4', '#A6A6A6', '#898989', '#6B6B6B', '#4D4D4D'],
  ['#3BB8FF', '#438BE0', '#4D89BF', '#6AAD96', '#A7C653', '#FFC900'],
];

// 配色设置颜色值
export const colors: string[] = ['#1a7dff', '#ff751a', '#16cad6', '#7067f5', '#66a3cc', '#ffae0f', '#db7972', '#96b1fa', '#34ad8d', '#f593ad', '#8c90b8', '#ccb18f'];
