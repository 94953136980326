import React, { FC, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/store/useStore';
import { Tooltip } from 'antd';
import SvgIcon from '@/components/SvgIcon';
import RenderEngine from '../Canvas/RenderEngine';
import * as echarts from 'echarts';
import themeDarkJson from '../theme/dark.json';
import { http } from '@/lib';
import APIS from '@/apis/api';
import { API_CHART_TYPE_MAP } from '@/pages/dataBoard/components/ChartType/util';
import { fetchApi } from '@/pages/Screen/Workspace/components/Property/Charts/DataConfig/PanelTarget';
import ComponentLoader from '../Canvas/component-loader';
import { fetchDataComps } from '../../components/TopDrawer/enum';
import { ScreenWorkplaceCtx } from '../../context';
import './index.less';

const Preview: FC<any> = props => {
  const { id: screenId } = useParams<{ id: string }>();
  const { screenStore, udMainStore } = useStore();
  const { screenConfig, componentList } = screenStore.state;
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const timerRefArr = useRef([]);
  useEffect(() => {
    fetchScreenDetail();
    udMainStore.setIsShowFrame(false);
    echarts.registerTheme('chalk', themeDarkJson);
    return () => {
      udMainStore.setIsShowFrame(true);
      screenStore.resetState();
    };
  }, []);
  useEffect(() => {
    if (Array.isArray(screenStore.state.componentList) && !screenStore.state.componentList.length) {
      return;
    }
    const dataBindingByApiComps = screenStore.state.componentList.filter(
      comp =>
        comp.dataConfig &&
        comp.dataConfig.dataBindType &&
        comp.dataConfig.dataBindType === 'api' &&
        comp.dataConfig.responseData
    );
    if (dataBindingByApiComps.length) {
      dataBindingByApiComps.forEach((comp, index) => {
        const { type, dataConfig, compId } = comp;
        timerRefArr.current[index] = setInterval(async () => {
          const {
            data: { measureData }
          } = await fetchApi({
            ...dataConfig,
            type
          });
          screenStore.setCompList(
            screenStore.state.componentList.map(comp => {
              if (comp.compId === compId) {
                return {
                  ...comp,
                  dataConfig: {
                    ...comp.dataConfig,
                    responseData: Object.values(measureData)[0][0]
                  },
                  element: {
                    ...comp.element,
                    value: Object.values(measureData)[0][0]
                  }
                };
              }
              return comp;
            })
          );
        }, dataConfig.refresh * 1000);
      });
    }
    return () => {
      timerRefArr.current.forEach((item, index) => {
        clearInterval(timerRefArr.current[index]);
      });
    };
  }, [screenStore.state.componentList]);
  const fetchScreenDetail = async () => {
    const isTypeLink = props.type && props.type === 'link';
    const reqViewURL = isTypeLink ? APIS.LinkScreenDetail : APIS.DataScreenDetail;
    const { data } = await http.post(
      reqViewURL,
      { id: screenId || props.screenId },
      Object.assign(
        {},
        isTypeLink
          ? {
              headers: {
                Authorization: props.token
              }
            }
          : {}
      )
    );
    const { screenConfig, components } = data;
    screenStore.setScreenConfig(JSON.parse(screenConfig));
    screenStore.setCompList(
      components.map(comp => {
        /*  if (comp.type.includes('image') || comp.type.includes('statistic') || comp.type.includes('ranking-list')) {
          comp.element = JSON.parse(comp.element);
        } */
        const dataConfig = JSON.parse(comp.dataConfig);
        return {
          ...comp,
          baseConfig: JSON.parse(comp.baseConfig),
          styleConfig: JSON.parse(comp.styleConfig),
          dataConfig: fetchDataComps.includes(comp.type)
            ? {
                ...dataConfig,
                status: fetchDataComps.includes(comp.type) ? 'getChartData' : dataConfig.status
              }
            : dataConfig,
          type: comp.type
        };
      })
    );
  };
  const ctxValue = {
    triggerUpdate,
    setTriggerUpdate
  };
  return (
    <div
      className="screen-preview-container"
      style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'auto' }}
    >
      <div
        className="canvas-wrapper"
        style={{
          position: 'absolute',
          width: screenConfig.width,
          height: screenConfig.height,
          transformOrigin: 'top left',
          backgroundColor: screenConfig.backgroundColor
        }}
      >
        {componentList.map((comp, index) => {
          const {
            compId,
            baseConfig: { width, height, zIndex, x, y }
          } = comp;
          return (
            <div
              key={compId}
              style={{
                position: 'absolute',
                display: 'inline-block',
                width: width || 200,
                height: height || 100,
                zIndex: zIndex || 1,
                transform: `translate(${x}px, ${y}px)`
              }}
            >
              <div className="comp-item-container">
                {fetchDataComps.includes(comp.type) &&
                  comp.dataConfig.status === 'done' &&
                  comp.styleConfig.title &&
                  comp.styleConfig.title.show && (
                    <div className="comp-item-header-wrapper">
                      <div
                        className="caption-wrapper"
                        style={{
                          backgroundColor: comp.styleConfig.customStyle.backgroundColor
                        }}
                      >
                        <div
                          className="caption"
                          style={{
                            textAlign: comp.styleConfig.title.hPosition,
                            fontSize: comp.styleConfig.title.textStyle.fontSize,
                            color: comp.styleConfig.title.textStyle.color,
                            fontStyle: comp.styleConfig.title.textStyle.fontStyle,
                            fontWeight: comp.styleConfig.title.textStyle.fontWeight
                          }}
                        >
                          {comp.styleConfig.title.text}
                          {comp.styleConfig.mark &&
                            comp.styleConfig.mark.show &&
                            comp.styleConfig.mark.position === 'title' &&
                            comp.styleConfig.mark.content && (
                              <Tooltip
                                title={comp.styleConfig.mark.content}
                                placement="bottomLeft"
                                overlayClassName="no-arrow theme-light"
                              >
                                <i className="svg-icon-wrapper icon-info">
                                  <SvgIcon iconClass="info-circle" />
                                </i>
                              </Tooltip>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                {fetchDataComps.includes(comp.type) &&
                  comp.dataConfig.status === 'done' &&
                  comp.styleConfig.mark &&
                  comp.styleConfig.mark.show &&
                  comp.styleConfig.mark.position === 'chart' &&
                  comp.styleConfig.mark.content && (
                    <div
                      className="mark-wrapper"
                      style={{
                        backgroundColor: comp.styleConfig.customStyle.backgroundColor
                      }}
                    >
                      <p>{comp.styleConfig.mark.content}</p>
                    </div>
                  )}
                <div className="comp-item-body-wrapper">
                  {fetchDataComps.includes(comp.type) && (
                    <ScreenWorkplaceCtx.Provider value={ctxValue}>
                      <ComponentLoader itemComp={comp} index={index} />
                    </ScreenWorkplaceCtx.Provider>
                  )}
                  {!fetchDataComps.includes(comp.type) && <RenderEngine comp={comp} editMode="preview" />}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Preview);
