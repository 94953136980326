import React, { FC, useState, useEffect, useRef } from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import { rgba2Obj } from '@/utils/utils';
import './index.less';

export type ColorConfigType = string;

interface ColorProps {
  value?: ColorConfigType;
  onChange?: (v: ColorConfigType) => void;
  theme?: string;
  verticalAlign?: string;
}

const POPOVER_CONTAINER_HEIGHT = 296;
const DEFAULT_STYLE = {
  position: 'fixed',
  zIndex: 2000,
  transition: 'top ease-in 0.3s, bottom ease-in 0.3s',
  height: `${POPOVER_CONTAINER_HEIGHT}px`
};
const ColorPicker: FC<ColorProps> = ({ value, onChange, theme, verticalAlign }) => {
  const [color, setColor] = useState(rgba2Obj(value));
  const [popover, setPopover] = useState<{ visible: boolean; style: React.CSSProperties }>({
    visible: false,
    style: JSON.parse(JSON.stringify(DEFAULT_STYLE))
  });
  const colorInputRef = useRef();

  useEffect(() => {
    setColor(rgba2Obj(value));
  }, [value]);
  const handleClick = () => {
    if (popover.visible) {
      const newStyle = JSON.parse(JSON.stringify(DEFAULT_STYLE));
      setPopover({
        ...popover,
        visible: false,
        style: newStyle
      });
      return;
    }
    const { x, y, height } = getRect(colorInputRef.current);
    const offset = 4;

    setPopover({
      ...popover,
      visible: true,
      style: {
        ...popover.style,
        // [window.innerHeight - y - height >= POPOVER_CONTAINER_HEIGHT ? 'top' : 'bottom']: height + offset,
        top:
          window.innerHeight - y - height >= POPOVER_CONTAINER_HEIGHT
            ? y + height + offset
            : y - POPOVER_CONTAINER_HEIGHT - offset,
        left: x
      }
    });
  };

  const handleClose = () => {
    setPopover({
      ...popover,
      visible: false
    });
  };

  const handleChange = (color: ColorResult) => {
    // console.log('color change: ', color);
    setColor(color.rgb);
    onChange && onChange(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`);
  };

  const getRect = element => {
    if (!element) {
      return {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 0,
        height: 0
      };
    }

    return element.getBoundingClientRect();
  };
  // console.log('ColorPicker render: ', popover, color, value);
  return (
    <div className="color-picker-wrapper" style={{ position: 'relative' }}>
      <div
        style={{
          background: `${theme && theme === 'dark' ? 'transparent' : '#fff'}`,
          borderRadius: '1px',
          boxShadow: `0 0 0 1px ${theme && theme === 'dark' ? 'rgba(255,255,255,0.15)' : '#d9d9d9'}`,
          display: 'inline-block',
          verticalAlign: verticalAlign || 'top',
          cursor: 'pointer',
          padding: '4px'
        }}
        onClick={handleClick}
        ref={colorInputRef}
      >
        <div
          style={{
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `rgba(${color?.r},${color?.g},${color?.b},${color?.a})`
          }}
        />
      </div>
      {popover.visible ? (
        <React.Fragment>
          <div style={popover.style}>
            <SketchPicker color={color} onChange={handleChange} />
          </div>
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1000
            }}
            onClick={handleClose}
          />
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default ColorPicker;
