export const BASE_RADAR = {
  legend: {
    type: 'scroll',
    data: []
  },
  tooltip: {
    show: true,
    trigger: 'item',
    formatter: params => {
      const {
        name,
        marker,
        data: { displayValue, indicator }
      } = params;
      let str = `${name}<br/>
        ${indicator
          .map((item, index) => {
            return `${marker} ${item.name}: ${displayValue[index]}${index < indicator.length - 1 ? '<br/>' : ''}`;
          })
          .join('')}
      `;
      return str;
    }
  },
  radar: {
    center: ['50%', '54%'],
    nameGap: 10,
    // shape: 'circle',
    // radius: 90, //缩放
    indicator: []
  },
  series: [
    {
      name: '预算 vs 开销（Budget vs spending）',
      type: 'radar',
      data: []
    }
  ]
};
