import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useStore } from '@/store/useStore';
import { WorkplaceContext } from './context';
import _ from 'lodash';
import cls from 'classnames';
import { message, Spin } from 'antd';
import * as echarts from 'echarts';
import { http } from '@/lib';
import axios from 'axios';
import APIS from '@/apis/api';
import { isEmptyObj } from '@/utils/is';
import { getChartConfig } from '@/pages/dataBoard/components/ChartsPanel/utils';
import { calculateExtraConfigFieldCount } from './util';
import { CALCULATOR_TYPES, CONTRAST_TYPES, CONTRAST_VALUE_TYPES, SINGLE_FIELD_AREA_CHARTS } from '@/utils/enum';
import { convertDataConfig, mergeOptions } from './chart-type';
import RenderEngine from './render-engine';
import Loading from '@/assets/loading.png';
import './style/component-loader.less';

function ComponentLoader({ item, index, mode, isTypeLink, dashboardId, token }: any) {
  const { dashboardStore } = useStore();
  const { triggerUpdate, setTriggerUpdate, chartConditionsMap } = useContext(WorkplaceContext);
  const [loading, setLoading] = useState(false);
  const {
    frontChartId,
    chartType,
    dataConfig,
    dataConfig: { status, branchTag, mapName, adcode, freshTime },
    styleConfig,
    advancedConfig
  } = item;
  const hasError = useMemo(() => {
    return status === 'intoCanvas' || status === 'queryError' || status === 'noData';
  }, [status]);
  const isDataEmpty = useMemo(() => {
    return status === 'intoCanvas' || status === 'noData';
  }, [status]);

  useEffect(() => {
    let timer = null;
    if (status === 'getChartData' || status === 'refreshRelationChartData') {
      fetchData();
    } else if (status === 'intoCanvas') {
      setLoading(true);
      timer = setTimeout(() => {
        setLoading(false);
      }, 200);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [status]);

  useEffect(() => {
    if (triggerUpdate && index === dashboardStore.state.activeIndex) {
      fetchData();
    }
  }, [triggerUpdate]);

  useEffect(() => {
    let autoRefreshTimer = null;
    if (freshTime && freshTime.isFreshEnable) {
      if (autoRefreshTimer) clearInterval(autoRefreshTimer);
      autoRefreshTimer = setInterval(() => {
        fetchData();
      }, freshTime.freshTimeNumber * 1000 * (freshTime.freshTimeUnit === 'min' ? 60 : 1));
    } else {
      clearInterval(autoRefreshTimer);
    }

    return () => {
      clearInterval(autoRefreshTimer);
    };
  }, [JSON.stringify(freshTime)]);

  const fetchData = async () => {
    let activeAreaGEOJson;
    const params = convertDataConfig({ chartType, dataConfig });
    if (chartType.includes('map') && chartType !== 'treemap') {
      if (!adcode) {
        message.error('缺少关联字段');
        return;
      }
      const res = await axios.get(`${APIS.GetAreaGEOJson}/${adcode}.json`);
      activeAreaGEOJson = res.data;
      echarts.registerMap(mapName, activeAreaGEOJson);
    }
    if (!isEmptyObj(chartConditionsMap) && Object.keys(chartConditionsMap).includes(frontChartId)) {
      params.filters = _.uniqBy([...params.filters, ...chartConditionsMap[frontChartId]], 'id');
    }
    if (
      params.filters.some(
        filter => Array.isArray(filter.values) && (!filter.values.length || filter.values.includes(''))
      )
    ) {
      params.filters = params.filters.filter(
        filter => Array.isArray(filter.values) && filter.values.filter(val => val).length
      );
    }
    setLoading(true);
    http
      .post(
        isTypeLink ? APIS.LinkGetChartsDetail : APIS.DataBoardGetChartsData,
        {
          ...params,
          ...(isTypeLink ? { resourceId: dashboardId } : {})
        },
        Object.assign(
          {},
          isTypeLink
            ? {
                headers: {
                  Authorization: token
                }
              }
            : {}
        )
      )
      .then(res => {
        const { dimensionData, measureData, mapChartsData, compareTableData } = res.data;
        // data empty 容错处理
        if (isEmptyObj(dimensionData) && !SINGLE_FIELD_AREA_CHARTS.includes(chartType)) {
          const updatedItem = _.cloneDeep(item);
          updatedItem.dataConfig.status = 'noData';
          updatedItem.dataConfig.chartOptions = null;
          setTriggerUpdate(false);
          dashboardStore.modChart({ frontChartId, updatedItem });
          return;
        }
        const extraConfigFields =
          chartType === 'combination'
            ? Object.values(item.dataConfig.extraConfigFieldMap).flat(Infinity)
            : item.dataConfig.extraConfigFields;
        const extraConfigFieldCountMap = calculateExtraConfigFieldCount(extraConfigFields);
        const mappedExtraConfigFields = extraConfigFields.map(field => {
          const { vid, customName } = field;
          const [uniqueId, calculatorType, contrastType, contrastValueType] = vid.split('|');
          let fieldCaptionExcludeCalcType = `${uniqueId}`;
          if (contrastType) fieldCaptionExcludeCalcType += `_${contrastType}_${contrastValueType}`;
          let caption = customName;
          if (extraConfigFieldCountMap[fieldCaptionExcludeCalcType] > 1) {
            caption += `_${CALCULATOR_TYPES[calculatorType]}`;
          }
          if (contrastType) {
            caption += `_${CONTRAST_TYPES[contrastType]}_${CONTRAST_VALUE_TYPES[contrastValueType]}`;
          }
          return {
            ...field,
            caption
          };
        });
        const newMeasureData = {};
        measureData &&
          Object.keys(measureData).forEach(key => {
            const aliasName = mappedExtraConfigFields.find(field => field.caption === key).aliasName;
            newMeasureData[`${aliasName}${key.split('_').length > 1 ? '_' : ''}${key.split('_').slice(1).join('_')}`] =
              measureData[key];
          });
        const chartOptions = getChartConfig(
          res.data,
          chartType === 'map' || chartType === 'bubble-map' ? mapChartsData : dimensionData, //如果是地图，则只会有mapChartsData数据
          newMeasureData,
          chartType,
          branchTag || 'dimension',
          styleConfig,
          null,
          chartType === 'tableMultidimensional' || (chartType.includes('map') && chartType !== 'treemap')
            ? mappedExtraConfigFields
            : _.cloneDeep(item.dataConfig),
          chartType.includes('map') && chartType !== 'treemap' ? { mapName, GEOJson: activeAreaGEOJson } : null
        );
        const updatedItem = _.cloneDeep(item);
        updatedItem.dataConfig.status = 'done';
        updatedItem.dataConfig.chartOptions = mergeOptions({
          ...item,
          chartIndex: index,
          dataConfig: Object.assign(
            {},
            {
              ...item.dataConfig,
              chartOptions
            },
            chartType === 'radar' ? { dimensionData, measureData: newMeasureData } : {}
          )
        });
        if (chartType === 'radar') {
          updatedItem.dataConfig.dimensionData = dimensionData;
          updatedItem.dataConfig.measureData = newMeasureData;
        }
        if (updatedItem.dataConfig.isActionDrill) {
          updatedItem.dataConfig.isActionDrill = false;
        } else {
          updatedItem.dataConfig.index = 0;
        }
        if (chartType === 'combination') {
          updatedItem.dataConfig.chartOptions.series = updatedItem.dataConfig.chartOptions.series.map(item => {
            const result = item;
            if (item.type === 'area') {
              result.type = 'line';
              result.smooth = true;
              result.areaStyle = {};
            }
            return result;
          });
        }
        setTriggerUpdate(false);
        dashboardStore.modChart({ frontChartId, updatedItem });
      })
      .catch(err => {
        console.log('queryError: ', err);
        const updatedItem = _.cloneDeep(item);
        updatedItem.dataConfig.status = 'queryError';
        setTriggerUpdate(false);
        dashboardStore.modChart({ frontChartId, updatedItem });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // console.log('render ComponentLoader: ', status);
  return (
    <div className={cls('component-loader', { 'loader-has-loading': loading, 'loader-has-error': hasError })}>
      <div className="component-wrapper">
        {status === 'done' && (
          <RenderEngine
            chartIndex={index}
            chartType={chartType}
            dataConfig={dataConfig}
            styleConfig={styleConfig}
            advancedConfig={advancedConfig}
          />
        )}
      </div>
      <div className="component-loader-masks"></div>
      {loading && (
        <div className="component-loader-loading">
          <div className="loading-content">
            {/* <img src={Loading} alt="" />
            <div className="loading-text">数据加载中...</div> */}
            <Spin tip="数据加载中..." />
          </div>
        </div>
      )}
      {hasError && (
        <div
          className={cls(['component-loader-error', 'default', `${chartType}`], {
            'error-empty-notice': isDataEmpty
          })}
        >
          <div className="error-image"></div>
          <div className="error-description">
            {status === 'queryError' && <span>数据查询失败</span>}
            {isDataEmpty && <div className="detail">当前图表无数据</div>}
          </div>
        </div>
      )}
    </div>
  );
}

export default ComponentLoader;
