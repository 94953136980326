import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { ScreenStore } from '@/store/Screen';
import SvgIcon from '@/components/SvgIcon';
import SortListItem from './SortListItem';
import {
  SORT_TYPES,
  CALCULATOR_TYPES,
  FIELD_AREAS,
  FIELD_AREA_TIPS,
  DATE_DISPLAY_FORMAT_TYPES,
  VALUE_DISPLAY_FORMAT
} from '@/utils/enum';
import { convertValFormat } from '@/pages/dashboard/workplace/util';
import { granularityIconMap } from '@/pages/dataBoard/components/FieldsOperation/contrast';

export type TypeArea =
  | 'dimensionFields'
  | 'measureFields'
  | 'filterFields'
  | 'filters'
  | 'compareFields'
  | 'xaxis'
  | 'yaxis'
  | 'yaxisExt'
  | 'legend';
interface IProps {
  type: TypeArea;
  screenStore?: ScreenStore;
  activeComp: any;
  onFieldOperate: (item: any) => void;
  onChartTypeValueChange: Function;
  onFieldExtraConfigChange: Function;
}
const Container = styled.div<any>`
  border: 1px ${props => (props.isDraggingOver ? 'dashed rgba(255, 255, 255, 0.4)' : 'dashed rgba(255, 255, 255, 0.2)')};
  background: rgba(255, 255, 255, 0.05);
  padding: 4px;
  border-radius: 2px;
  width: 184px;
`;
const Notice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 4px 0;
  border: 1px solid transparent;
  color: rgba(255, 255, 255, 0.5);
`;
const List = styled.div<any>`
  transform: ${props => !props.isDragging && 'translate(0px, 0px) !important'};
  margin-top: ${props => (props.index === 0 ? '0px' : '6px')};
`;
const FieldArea: FC<IProps> = ({
  type,
  activeComp,
  onFieldOperate,
  onChartTypeValueChange,
  onFieldExtraConfigChange
}) => {
  const handleFieldDropdownMenuChange = (fieldAreaType, selectedVal, fieldIdx) => {
    const updatedItem = _.cloneDeep(activeComp);
    const isSortType = Object.keys(SORT_TYPES).includes(selectedVal);
    const isCalculatorType = Object.keys(CALCULATOR_TYPES).includes(selectedVal);
    const isDateDisplayFormatType = Object.keys(DATE_DISPLAY_FORMAT_TYPES).includes(selectedVal);
    const isValueDisplayFormatType = Object.keys(VALUE_DISPLAY_FORMAT).includes(selectedVal);

    updatedItem.dataConfig[fieldAreaType] = updatedItem.dataConfig[fieldAreaType].map((field, idx) => {
      if (idx === fieldIdx) {
        const {
          sortType,
          calculatorType,
          attribute: { displayFormat, dataSetFieldValueFormat }
        } = field;
        const newSortType = isSortType ? selectedVal : sortType;
        const newCalculatorType = isCalculatorType ? selectedVal : calculatorType;
        const newDisplayFormat = isDateDisplayFormatType ? selectedVal : displayFormat;
        const newDataSetFieldValueFormat = isValueDisplayFormatType ? selectedVal : dataSetFieldValueFormat;
        return {
          ...field,
          sortType: newSortType,
          sort: isSortType ? true : field.sort,
          calculatorType: newCalculatorType,
          attribute: {
            ...field.attribute,
            sortType: newSortType,
            displayFormat: newDisplayFormat,
            dataSetFieldValueFormat: newDataSetFieldValueFormat
          }
        };
      }
      return field;
    });
    onFieldOperate(updatedItem);
    if (isCalculatorType) {
      let updatedExtraConfigItem;
      if (activeComp.chartType === 'combination') {
        updatedExtraConfigItem = _.cloneDeep(
          activeComp.dataConfig.extraConfigFieldMap[fieldAreaType].filter((item, idx) => idx === fieldIdx)[0]
        );
      } else {
        updatedExtraConfigItem = _.cloneDeep(
          activeComp.dataConfig.extraConfigFields.filter((item, idx) => idx === fieldIdx)[0]
        );
      }
      const [uniqueId, calculatorType, contrastType, contrastValueType] = updatedExtraConfigItem.vid.split('|');
      updatedExtraConfigItem.calculatorType = selectedVal;
      updatedExtraConfigItem.vid = [uniqueId, selectedVal, contrastType, contrastValueType].join('|');
      let params: any = {
        frontChartId: activeComp.frontChartId,
        index: fieldIdx,
        updatedItem: updatedExtraConfigItem
      };
      if (activeComp.chartType === 'combination') {
        params.fieldAreaType = fieldAreaType;
      }
      onFieldExtraConfigChange(params);
    }
  };

  const handleFieldDisplayFormatChange = (fieldAreaType, fieldIdx, pattern, numberType, valueMagnitude) => {
    const updatedItem = _.cloneDeep(activeComp);
    updatedItem.dataConfig[fieldAreaType] = updatedItem.dataConfig[fieldAreaType].map((field, idx) => {
      if (idx === fieldIdx) {
        return {
          ...field,
          attribute: {
            ...field.attribute,
            dataSetFieldValueFormat: 'CUSTOM',
            displayFormat: pattern,
            valueMagnitude: numberType === 'number' ? valueMagnitude : null
          }
        };
      }
      return field;
    });
    onFieldOperate(updatedItem);
  };

  const handleFieldExtraConfigChange = params => {
    onFieldExtraConfigChange(params);
  };

  const handleFieldRemove = (propType, fieldIndex) => {
    const newItem = {
      ...activeComp,
      dataConfig: {
        ...activeComp.dataConfig,
        [propType]: activeComp.dataConfig[propType].filter((itemField, index) => index !== fieldIndex)
      }
    };
    if (propType === 'filterFields') {
      const filterFieldUniqueIds = newItem.dataConfig.filterFields.map(item => item.uniqueId);
      newItem.dataConfig.filters = newItem.dataConfig.filters.filter(item =>
        filterFieldUniqueIds.includes(item.uniqueId)
      );
    } else if (propType === 'compareFields') {
      newItem.dataConfig.compareField = null;
    }
    onFieldOperate(newItem);
  };

  const handleFieldFiltersChange = filters => {
    const newItem = {
      ...activeComp,
      dataConfig: {
        ...activeComp.dataConfig,
        filters
      }
    };
    onFieldOperate(newItem);
  };

  const handleDerivedFieldFilterChange = ({ complexFilter, index, itemField }) => {
    const { dateFilterType, intervalType, values } = complexFilter;
    let newComplexFilter = null;
    const newItem = _.cloneDeep(activeComp);
    newItem.dataConfig.filterFields = newItem.dataConfig.filterFields.map((field, idx) => {
      if (idx === index) {
        newComplexFilter = {
          dateFilterType,
          intervalType,
          filters: [...(dateFilterType === 'SINGLE' ? values.slice(0, 1) : values)].map((val, valIdx) => ({
            fieldUniqueId: field.uniqueId,
            operator: dateFilterType === 'SINGLE' ? 'EQUALS' : valIdx === 0 ? 'GE' : 'LE',
            values: [convertValFormat(val, granularityIconMap[field.attribute.granularity])],
            frontAdditionalInfo: {
              type: field.attribute.type,
              valueType: field.attribute.valueType,
              fieldCustomName: field.customName,
              granularity: field.attribute.granularity,
              isDerivedField: true
            }
          }))
        };
        return {
          ...field,
          complexFilter: newComplexFilter
        };
      }
      return field;
    });
    if (newItem.dataConfig.filters.some(filter => filter.fieldUniqueId === itemField.uniqueId)) {
      newItem.dataConfig.filters = newItem.dataConfig.filters.filter(
        filter => filter.fieldUniqueId !== itemField.uniqueId
      );
    }

    let newFilters = newComplexFilter.filters;
    if (dateFilterType === 'INTERVAL' && intervalType.includes('WITH')) {
      const _startIdx = intervalType === 'START_WITH' ? 0 : 1;
      const _endIndx = intervalType === 'START_WITH' ? 1 : newFilters.length;
      newFilters = newFilters.slice(_startIdx, _endIndx);
    }
    newItem.dataConfig.filters = [
      ...newItem.dataConfig.filters,
      ...newFilters.map(filter => ({
        ...filter,
        dateFilterType,
        intervalType,
        conditionJoin: 'AND'
      }))
    ];
    onFieldOperate(newItem);
  };
  const handleFieldCompareConfigChange = compareField => {
    const newItem = {
      ...activeComp,
      dataConfig: {
        ...activeComp.dataConfig,
        compareField
      }
    };
    onFieldOperate(newItem);
  };
  // console.log('FieldArea render');
  return (
    <div className="field-area-wrapper">
      <div className="field-area-header">
        <div className="area-name">
          {(type === 'dimensionFields' || type === 'measureFields' || type === 'compareFields' || type === 'xaxis') && (
            <i className="svg-icon-wrapper require-icon">
              <SvgIcon iconClass="asterisk" />
            </i>
          )}
          {FIELD_AREAS[type]}
        </div>
      </div>
      <div className="field-area-body">
        <Droppable droppableId={type}>
          {(provided, snapshot) => (
            <Container ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
              {activeComp.dataConfig[type].length
                ? activeComp.dataConfig[type].map((item, index) => (
                    <Draggable key={item.uniqueId} draggableId={item.uniqueId} index={index}>
                      {(provided, snapshot) => (
                        <List
                          ref={provided.innerRef}
                          isDragging={snapshot.isDragging}
                          style={provided.draggableProps.style}
                          index={index}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <SortListItem
                            activeComp={_.cloneDeep(activeComp)}
                            type={type}
                            item={item}
                            index={index}
                            filters={activeComp.dataConfig.filters}
                            dataSetId={activeComp.dataConfig.dataSetId}
                            dataSetName={activeComp.dataConfig.dataSetName}
                            dataSetFields={activeComp.dataConfig.dataSetFields}
                            flattenedFields={activeComp.dataConfig.flattenedFields}
                            compareField={activeComp.dataConfig.compareField}
                            onFieldDropdownMenuChange={handleFieldDropdownMenuChange}
                            onFieldRemove={handleFieldRemove}
                            onFieldFiltersChange={handleFieldFiltersChange}
                            onDerivedFieldFilterChange={handleDerivedFieldFilterChange}
                            onFieldCompareConfigChange={handleFieldCompareConfigChange}
                            onChartTypeValueChange={onChartTypeValueChange}
                            onFieldDisplayFormatChange={handleFieldDisplayFormatChange}
                            onFieldExtraConfigChange={handleFieldExtraConfigChange}
                          />
                        </List>
                      )}
                    </Draggable>
                  ))
                : provided.placeholder && <Notice>拖动{FIELD_AREA_TIPS[type]}字段至此处</Notice>}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default FieldArea;
