import React from 'react';
import { Tooltip, Dropdown, Menu } from 'antd';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import SvgIcon from '@/components/SvgIcon';
import { genCustomCompLayout } from './index';
import './style/canvas-opt-bar.less';

function CanvasOptBar({ itemComp, customComps, allComps, onEdit, onCopy, onRemove }) {
  const handleMenuClick = actionType => {
    switch (actionType) {
      case 'edit':
        onEdit(itemComp.widgetId);
        break;
      case 'copy': {
        const widgetId = `${itemComp.serviceName}-${uuidv4().slice(0, 6)}`;
        const newItem = {
          ..._.cloneDeep(itemComp),
          widgetId,
          layout: {
            ...genCustomCompLayout(itemComp, {
              charts: allComps.filter(comp => !(comp.type && comp.type === 'custom')),
              customComps
            }),
            i: widgetId,
            w: itemComp.layout.w,
            h: itemComp.layout.h
          },
          order: allComps.length
        };
        onCopy(newItem);
        break;
      }
      case 'remove':
        onRemove(
          customComps.filter(comp => comp.widgetId !== itemComp.widgetId),
          itemComp
        );
        break;
    }
  };

  return (
    <div className="canvas-opt-bar">
      <Tooltip placement="top" title="编辑" overlayClassName="canvas-opt-bar-tooltip">
        <i className="svg-icon-wrapper icon-edit" onClick={() => handleMenuClick('edit')}>
          <SvgIcon iconClass="edit" />
        </i>
      </Tooltip>
      <Tooltip placement="top" title="设置" overlayClassName="canvas-opt-bar-tooltip">
        <Dropdown
          overlayClassName="canvas-opt-bar-dropdown-menu"
          overlay={
            <Menu>
              <Menu.Item onClick={() => handleMenuClick('edit')}>
                <i className="svg-icon-wrapper icon-edit">
                  <SvgIcon iconClass="edit" />
                </i>
                编辑
              </Menu.Item>
              {!itemComp.component.includes('button') && (
                <Menu.Item onClick={() => handleMenuClick('copy')}>
                  <i className="svg-icon-wrapper icon-clone">
                    <SvgIcon iconClass="clone" />
                  </i>
                  复制
                </Menu.Item>
              )}
              <Menu.Item onClick={() => handleMenuClick('remove')}>
                <i className="svg-icon-wrapper icon-delete">
                  <SvgIcon iconClass="delete" />
                </i>
                删除
              </Menu.Item>
            </Menu>
          }
        >
          <i className="svg-icon-wrapper icon-setting">
            <SvgIcon iconClass="setting" />
          </i>
        </Dropdown>
      </Tooltip>
    </div>
  );
}

export default CanvasOptBar;
