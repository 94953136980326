import _ from 'lodash'
import React, { Component } from 'react'
import { Button, Tooltip, Modal, Checkbox, message } from 'antd'
import { IColumnStandard } from '../../../'

class UdTableColumnCustomize extends Component<IUdTableColumnCustomizeProps, IUdTableColumnCustomizeState> {

  constructor(props: IUdTableColumnCustomizeProps) {
    super(props)
    this.state = {
      values: [],
      columns: [],
      modalVisible: false
    }
  }

  componentDidMount() {
    this.handleProps(this.props)
  }

  componentWillReceiveProps(nextProps: Readonly<IUdTableColumnCustomizeProps>) {
    if (!_.isEqual(this.props, nextProps)) {
      this.handleProps(nextProps)
    }
  }

  render() {
    let { values, columns, modalVisible } = this.state
    let nodes = []
    if (columns && columns.length > 0) {
      // if (this.hasGroup(columns)) {

      // } else {
      for (const item of columns as IColumnStandard[]) {
        nodes.push(<div className="item">
          <Checkbox
            key={item.dataIndex}
            value={item.dataIndex}
            disabled={values.length == 1 && values[0] == item.dataIndex}
          >{item.title}</Checkbox>
        </div>)
      }
      // }
    }

    return (
      <React.Fragment>
        <Tooltip title="配置要显示的列" placement={'left'}>
          <Button icon="setting" onClick={() => {
            this.setState({ modalVisible: true })
          }}></Button>
        </Tooltip>
        <Modal visible={modalVisible} onCancel={() => {
          this.setState({ modalVisible: false })
        }} onOk={this.handleOk}>
          <div className="ud-table-column-customize">
            <Checkbox.Group defaultValue={values} onChange={this.onChange}>
              {nodes}
            </Checkbox.Group>
          </div>
        </Modal>
      </React.Fragment>
    )
  }


  private handleProps = (props: IUdTableColumnCustomizeProps) => {
    let values = props.values || []
    let columns = _.cloneDeep(props.columns)
    if (columns && columns.length > 0) {
      if (values && values.length == 0) {
        // if (this.hasGroup(columns)) {

        // } else {
        _.remove(columns as IColumnStandard[], n => n.allowCustomize === false)
        values = _.map(columns as IColumnStandard[], 'dataIndex')
        // }
      }
    }
    this.setState({ values, columns })
  }

  // private hasGroup = (columns: IColumn[] | IColumnGroup[]) => {
  //   return (columns as IColumnGroup[])[0].name != null && (columns as IColumnGroup[])[0].name != ''
  // }

  private onChange = (checkedValues: string[]) => {
    this.setState({ values: checkedValues })
  }

  private handleOk = () => {
    this.props.onChange(this.state.values)
    this.setState({ modalVisible: false })
  }

}

interface IUdTableColumnCustomizeProps {
  values: string[]
  columns: IColumnStandard[]
  onChange: (values: string[]) => void
}

interface IUdTableColumnCustomizeState {
  values: string[]
  columns: IColumnStandard[]
  modalVisible: boolean
}

export default UdTableColumnCustomize
