import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { DEFAULT_TEXT_STYLE } from '../../../element/element';
import './index.less';

const Text: FC<any> = ({ option }) => {
  const history = useHistory();
  const [style, setStyle] = useState<any>({
    bg: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      background: 'rgba(255, 255, 255, 0)'
    },
    ...DEFAULT_TEXT_STYLE
  });

  useEffect(() => {
    initStyle();
  }, [option]);

  const initStyle = () => {
    const newStyle = _.cloneDeep(style);
    if (option.styleConfig) {
      // bg
      newStyle.bg.background = option.styleConfig.backgroundColor;
      // text
      newStyle.hPosition = option.styleConfig.hPosition;
      newStyle.fontSize = option.styleConfig.fontSize;
      newStyle.color = option.styleConfig.color;
      newStyle.fontStyle = option.styleConfig.fontStyle;
      newStyle.fontWeight = option.styleConfig.fontWeight;
      newStyle.letterSpacing = option.styleConfig.letterSpacing;
      // a
      newStyle.a.enable = option.styleConfig.a.enable;
      newStyle.a.href = option.styleConfig.a.href;
      newStyle.a.target = option.styleConfig.a.target;
    }
    setStyle({
      ...style,
      ...newStyle
    });
  };

  return (
    <div style={style.bg} className={classNames({ 'not-clickable': !history.location.pathname.includes('preview') })}>
      <p
        className="base-css"
        style={{
          justifyContent: style.hPosition || 'center',
          fontSize: style.fontSize + 'px',
          color: style.color,
          fontStyle: style.fontStyle,
          fontWeight: style.fontWeight,
          letterSpacing: style.letterSpacing + 'px'
        }}
      >
        {style.a.enable && (
          <a href={style.a.href} target={style.a.target}>
            {option.dataConfig.static}
          </a>
        )}
        {!style.a.enable && <span>{option.dataConfig.static}</span>}
      </p>
    </div>
  );
};

export default Text;
