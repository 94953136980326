export const generateFieldList = (data, fieldList) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key } = node;
    fieldList.push({ key, title: key });
    if (node.children) {
      generateFieldList(node.children, fieldList);
    }
  }
};

export const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export const flattenDataSetFields = dataSetFields => {
  const ret = [];
  const recursionHelperFn = data => {
    if (Array.isArray(data) && data.length) {
      for (let i = 0; i < data.length; i++) {
        ret.push(data[i]);
        if (data[i].derivedFields) {
          recursionHelperFn(data[i].derivedFields);
        }
      }
    }
  };
  recursionHelperFn(dataSetFields);
  return ret;
};
