import React, { useEffect, useContext } from 'react';
import { toJS } from 'mobx';
import cls from 'classnames';
import _ from 'lodash';
import { useStore } from '@/store/useStore';
import { WorkplaceContext } from './context';
import IbDate from '@/components/widget/ib-widget/ib-date';
import IbSelect from '@/components/widget/ib-widget/ib-select';
import IbSelectGrid from '@/components/widget/ib-widget/ib-select-grid';
import IbInputSearch from '@/components/widget/ib-widget/ib-input-search';
import IbSelectTree from '@/components/widget/ib-widget/ib-select-tree';
import IbNumberRange from '@/components/widget/ib-widget/ib-number-range';
import IbButton from '@/components/widget/ib-widget/ib-button';
import IbResetButton from '@/components/widget/ib-widget/ib-reset-button';
import CanvasOptBar from './canvas-opt-bar';

const CUSTOM_COMPS = {
  'ib-date': IbDate,
  'ib-select': IbSelect,
  'ib-select-grid': IbSelectGrid,
  'ib-input-search': IbInputSearch,
  'ib-select-tree': IbSelectTree,
  'ib-number-range': IbNumberRange,
  'ib-button': IbButton,
  'ib-reset-button': IbResetButton
};

function GridCustomItem({
  mode,
  item,
  index,
  customComps,
  allComps,
  onGridItemClick,
  onCopy,
  onRemove,
  onEdit,
  onQuery,
  onReset,
  onWidgetOptionValChange,
  onUpdateChartConditionsMap
}: any) {
  const { dashboardStore } = useStore();
  const { chartConditionsMap } = useContext(WorkplaceContext);

  useEffect(() => {
    if (item.refresh) {
      dashboardStore.modComp({
        compId: item.widgetId,
        updatedItem: {
          ...item,
          refresh: false
        }
      });
    }
  }, [item.refresh]);

  const handleDateValChange = dateStr => {
    widgetConditionValChangeHandler(dateStr);
  };

  const handleSelectedValChange = selectedVal => {
    widgetConditionValChangeHandler(selectedVal.join(','));
  };

  const handleCheckedValChange = checkedVal => {
    widgetConditionValChangeHandler(checkedVal.join(','));
  };

  const handleSearch = keyword => {
    widgetConditionValChangeHandler(keyword);
  };

  const handleTreeSelectChange = data => {
    widgetConditionValChangeHandler(data.join(','));
  };

  const handleInputNumberValChange = inputVal => {
    widgetConditionValChangeHandler(inputVal);
  };

  const handleQuery = () => {
    onQuery(chartConditionsMap);
  };

  const handleReset = () => {
    onReset();
  };

  const widgetConditionValChangeHandler = val => {
    onWidgetOptionValChange({
      ...item,
      options: {
        ...item.options,
        value: val
      }
    });
  };

  const renderCustomComp = () => {
    const CustomComp = CUSTOM_COMPS[item.component];
    return item.component === 'ib-date' ? (
      <IbDate
        widgetId={item.widgetId}
        className="relative-time"
        mode={item.options.attrs.type}
        inDraw={true}
        readOnly={false}
        element={item}
        defaultValue={item.options.value}
        showTime={item.options.attrs.showTime}
        accuracy={item.options.attrs.accuracy}
        onDateChange={handleDateValChange}
      />
    ) : (
      <CustomComp
        mode={mode}
        widgetId={item.widgetId}
        widget={item}
        inDraw={true}
        defaultValue={item.options.value}
        onWidgetPropChange={handleWidgetPropChange}
        onWidgetMultiPropsChange={handleWidgetMultiPropsChange}
        onSelectedValChange={handleSelectedValChange}
        onCheckedValChange={handleCheckedValChange}
        onSearch={handleSearch}
        onTreeSelectChange={handleTreeSelectChange}
        onInputNumberValChange={handleInputNumberValChange}
        onUpdateChartConditionsMap={onUpdateChartConditionsMap}
        onQuery={handleQuery}
        onReset={handleReset}
      />
    );
  };

  const handleWidgetPropChange = (key, val) => {};

  const handleWidgetMultiPropsChange = obj => {};

  // console.log('render GridItem: ', toJS(item));
  return (
    <div className="layout-item" data-grid={item.layout} onClick={() => onGridItemClick(index)}>
      <div className="layout-item-box">
        <div
          className={cls('component-card-wrapper custom-component', {
            'custom-button-comp': item.component.includes('button')
          })}
        >
          <div className="custom-component-container">
            {item.style.showTitle && (
              <label
                className="custom-comp-label"
                style={{
                  display: item.style.labelPos === 'top' ? 'block' : 'inline-block',
                  paddingBottom: item.style.labelPos === 'top' ? '8px' : 0,
                  width: item.style.labelPos === 'top' ? '100%' : 'inherit',
                  fontSize: item.style.fontSize + 'px',
                  color: item.style.color,
                  fontWeight: item.style.bold ? 'bold' : 'normal',
                  fontStyle: item.style.italic ? 'italic' : 'normal',
                  letterSpacing: item.style.letterSpacing
                }}
              >
                {item.style.mainTitle}
              </label>
            )}
            <span className="custom-comp-control" style={{ height: `calc(100% - ${item.style.showTitle ? 32 : 0}px)` }}>
              {renderCustomComp()}
            </span>
          </div>
        </div>
        {mode === 'edit' && (
          <CanvasOptBar
            itemComp={item}
            customComps={customComps}
            allComps={allComps}
            onCopy={onCopy}
            onRemove={onRemove}
            onEdit={onEdit}
          />
        )}
      </div>
    </div>
  );
}

export default GridCustomItem;
