import React, { useState, useContext } from 'react';
import cls from 'classnames';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip, Icon, message } from 'antd';
import { http } from '@/lib';
import APIS from '@/apis/api';
import { convertDataConfig } from './chart-type';
import { DRILL_DOWN_CHARTS, GRID_LAYOUT_BREAK_POINTS } from '@/utils/enum';
import { WorkplaceContext } from './context';
import SvgIcon from '@/components/SvgIcon';

function CardMenu({
  dashboardId,
  dashboardName,
  activeBreakPoint,
  charts,
  customComps,
  item,
  index,
  isHovered,
  onCopy,
  onRemove,
  onSettingWarning,
  onSettingDrillDown
}: any) {
  const {
    id,
    chartType,
    dataConfig,
    dataConfig: { status },
    styleConfig
  } = item;
  const { activeDevice } = useContext(WorkplaceContext);

  const handleSettingWarning = ev => {
    ev.stopPropagation();
    onSettingWarning(index);
  };

  const handleCopy = ev => {
    ev.stopPropagation();
    const frontChartId = `${chartType}-${uuidv4().slice(0, 6)}`;
    const newChart = _.cloneDeep(item);
    const allComp = [...charts, ...customComps];
    const size = allComp.length;
    const lastComp = allComp[size - 1];
    const {
      layout: { x: lastChartX, w: lastChartW }
    } = lastComp;
    newChart.frontChartId = frontChartId;
    newChart.styleConfig.chartName = `${newChart.styleConfig.chartName}副本`;
    newChart.layout = {
      i: frontChartId,
      x:
        lastChartX + lastChartW + newChart.layout.w > GRID_LAYOUT_BREAK_POINTS[activeBreakPoint].cols
          ? 0
          : lastChartX + lastChartW,
      y: Infinity,
      w: newChart.layout.w,
      h: newChart.layout.h
    };
    newChart.order = size;
    onCopy([...charts, newChart]);
  };

  const handleExport = ev => {
    ev.stopPropagation();
    const params = {
      ...convertDataConfig({ chartType, dataConfig }),
      exportChartName: `${dashboardName}-${styleConfig.title}`
    };
    http.post(APIS.DataBoardExportChart, params).then(res => {
      message.success('导出成功，请前往任务列表下载');
    });
  };

  const handleRemove = ev => {
    ev.stopPropagation();
    // const newCharts = charts.filter((_, idx) => idx !== index);
    const newCharts = charts.filter(chart => chart.order !== index).map(chart => {
      // fix: 删除导致组件列表order未更新
      if (chart.order > index) {
        chart.order = chart.order - 1;
      }
      return chart;
    });
    onRemove(newCharts);
  };

  const handleSettingDrillDown = ev => {
    ev.stopPropagation();
    onSettingDrillDown(index);
  };
  return (
    <div
      className={cls('card-menu-wrapper', {
        'card-menu-show': isHovered,
        'card-menu-hide': !isHovered
      })}
    >
      {activeDevice === 'pc' && (
        <>
          {id && (
            <Tooltip title="预警">
              <Icon type="bell" onClick={ev => handleSettingWarning(ev)} />
            </Tooltip>
          )}
          {/* {DRILL_DOWN_CHARTS.includes(chartType) && (
          <Tooltip title="钻取">
            <i className="svg-icon-wrapper icon-drill-down" onClick={ev => handleSettingDrillDown(ev)}>
              <SvgIcon iconClass="drill-down" />
            </i>
          </Tooltip>
        )} */}
          <Tooltip title="复制">
            <Icon type="copy" onClick={ev => handleCopy(ev)} />
          </Tooltip>
          <Tooltip title="导出">
            <Icon
              className={cls({ 'is-disabled': status !== 'done' })}
              type="export"
              onClick={ev => handleExport(ev)}
            />
          </Tooltip>
        </>
      )}
      <Tooltip title="删除">
        <Icon type="delete" onClick={ev => handleRemove(ev)} />
      </Tooltip>
    </div>
  );
}

export default CardMenu;
