export type FieldType = 'DIMENSION' | 'MEASURE';
export interface IItemMenu {
  label: string;
  value?: string;
  children?: Array<IItemMenu>;
}

/**
 * 字段相关
 */

export const FIELD_AREAS = {
  dimensionFields: '维度',
  measureFields: '度量',
  filterFields: '筛选',
  compareFields: '对比字段',
  drillDownFields: '下钻',
  xaxis: '类别轴 / 维度',
  yaxis: '主轴值 / 度量',
  yaxisExt: '副轴值 / 度量',
  legend: '颜色图例 / 维度'
};

export const FIELD_AREA_TIPS = {
  dimensionFields: '维度',
  measureFields: '度量',
  filterFields: '任意',
  compareFields: '维度',
  drillDownFields: '维度',
  xaxis: '维度',
  yaxis: '度量',
  yaxisExt: '度量',
  legend: '维度'
};

export const FIELD_TYPES = {
  dimension: '维度',
  measure: '度量'
};

export const FIELD_VALUE_TYPE_ICONS = {
  TEXT: 'Str',
  NUMBER: 'No',
  DATE: 'Date',
  DECIMAL: 'No'
};

export const CALCULATOR_TYPES = {
  SUM: '求和',
  COUNT: '计数',
  DISTINCT_COUNT: '去重计数',
  AVG: '平均值',
  MAX: '最大值',
  MIN: '最小值'
};

export const SORT_TYPES = {
  // NONE: '无',
  ASC: '升序',
  DESC: '降序'
};

export const GRANULARITY_TYPE_ICONS = {
  YEAR: 'year',
  YEAR_MONTH: 'month',
  YEAR_QUARTER: 'quarter',
  YEAR_MONTH_DAY: 'day'
};

export const DIMENSION_FIELD_DROPDOWN_MENU = [
  {
    label: '排序',
    children: [
      { label: '无', value: 'NONE' },
      { label: '升序', value: 'ASC' },
      { label: '降序', value: 'DESC' }
    ]
  }
];

export const MEASURE_FIELD_DROPDOWN_MENU = [
  {
    label: '聚合方式',
    children: [
      { label: '求和', value: 'SUM' },
      { label: '计数', value: 'COUNT' },
      { label: '去重计数', value: 'DISTINCT_COUNT' },
      { label: '平均值', value: 'AVG' },
      { label: '最大值', value: 'MAX' },
      { label: '最小值', value: 'MIN' }
    ]
  },
  {
    label: '排序',
    children: [
      // { label: '无', value: 'NONE' },
      { label: '升序', value: 'ASC' },
      { label: '降序', value: 'DESC' }
    ]
  }
];

export const CONTRAST_BASE_MENU = [
  { label: '自定义', value: 'CONTRAST_CUSTOM' },
  { label: '无', value: 'NONE' }
];

export const CONTRAST_TYPES = {
  YEAR_RING_RATIO: '年环比',
  QUARTER_RING_RATIO: '季环比',
  QUARTER_YEAR_RATIO: '年同比',
  MONTH_RING_RATIO: '月环比',
  MONTH_YEAR_RATIO: '年同比',
  DAY_RING_RATIO: '日环比',
  DAY_MONTH_RATIO: '月同比',
  DAY_YEAR_RATIO: '年同比',
  DYNAMIC_RING_RATIO: '动态环比'
};

export const CONTRAST_VALUE_TYPES = {
  LAST_VALUE: '具体数值',
  INCREASE_VALUE: '对比值',
  INCREASE_RATIO: '百分率',
  PROPORTION: '比值'
};

export const VALUE_MAGNITUDE_TYPES = {
  MAGNITUDE_NONE: '无',
  THOUSAND: '千',
  TEN_THOUSAND: '万',
  MILLION: '百万',
  TEN_MILLION: '千万',
  HUNDRED_MILLION: '亿'
};

export const LINE_CALCULATED_TYPES = {
  average: '平均值',
  max: '最大值',
  min: '最小值',
  median: '中位数'
};

export const enumGranularity = ['DATE_TIME', 'YEAR', 'YEAR_MONTH', 'YEAR_MONTH_DAY', 'YEAR_QUARTER'];

export const granularityMap = {
  YEAR: 'year',
  YEAR_MONTH: 'month',
  YEAR_MONTH_DAY: 'day',
  YEAR_QUARTER: 'quarter',
  DATE_TIME: 'time'
};

export const FIELD_VALUE_TYPE = {
  TEXT: '文本',
  NUMBER: '数字',
  DATE: '日期'
};

export const VALUE_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  KEEP_INTEGER: '整数',
  KEEP_ONE_DECIMALS: '保留1位小数',
  KEEP_TWO_DECIMALS: '保留2位小数',
  KEEP_INTEGER_FOLLOW_PERCENT_SIGN: '百分比',
  KEEP_ONE_DECIMALS_FOLLOW_PERCENT_SIGN: '百分比1位小数',
  FOLLOW_PERCENT_SIGN: '百分比2位小数'
};

export const DATE_YEAR_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  YEAR_FMT_1: 'YYYY年 (例:2018年)',
  YEAR_FMT_2: '中文YYYY年 (例:二〇〇二年)'
};

export const DATE_QUARTER_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  QUARTER_FMT_1: 'YYYY-Q季度 (例:2018-3季度)',
  QUARTER_FMT_2: 'YYYY年第Q季度 (例:2018第3季度)',
  QUARTER_FMT_3: '中文YYYY年第Q季度 (例:二〇一八第三季度)'
};

export const DATE_MONTH_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  MONTH_FMT_1: 'YYYY-MM (例:2018-08)',
  MONTH_FMT_2: 'YYYY/MM (例:2018/08)',
  MONTH_FMT_3: 'YYYY年M月 (例:2018年8月)',
  MONTH_FMT_4: '中文YYYY年M月 (例:二〇〇二年八月)'
};

export const DATE_DAY_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  DAY_FMT_1: 'YYYY-MM-DD (例:2018-08-02)',
  DAY_FMT_2: 'YYYY/MM/DD (例:2018/08/02)',
  DAY_FMT_3: 'YYYY年M月D日 (例:2018年8月2日)',
  DAY_FMT_4: '中文YYYY年M月D日 (例:二〇〇二年八月二日)'
};

export const DATE_TIME_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  TIME_FMT_1: 'YYYY-MM-DD HH:mm:ss (例:2018-08-02 22:02:03)',
  TIME_FMT_2: 'YYYY/MM/DD HH:mm:ss (例:2018/08/02 22:02:03)',
  TIME_FMT_3: 'YYYY年M月D日 HH时mm分ss秒 (例:2018年08月02日 22时02分03秒)'
};

export const SHORT_DATE_YEAR_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  YEAR_FMT_1: 'YYYY年',
  YEAR_FMT_2: '中文YYYY年'
};

export const SHORT_DATE_QUARTER_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  QUARTER_FMT_1: 'YYYY-Q季度',
  QUARTER_FMT_2: 'YYYY年第Q季度',
  QUARTER_FMT_3: '中文YYYY年第Q季度'
};

export const SHORT_DATE_MONTH_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  MONTH_FMT_1: 'YYYY-MM',
  MONTH_FMT_2: 'YYYY/MM',
  MONTH_FMT_3: 'YYYY年M月',
  MONTH_FMT_4: '中文YYYY年M月'
};

export const SHORT_DATE_DAY_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  DAY_FMT_1: 'YYYY-MM-DD',
  DAY_FMT_2: 'YYYY/MM/DD',
  DAY_FMT_3: 'YYYY年M月D日',
  DAY_FMT_4: '中文YYYY年M月D日'
};

export const SHORT_DATE_TIME_DISPLAY_FORMAT = {
  AUTOMATIC: '自动',
  TIME_FMT_1: 'YYYY-MM-DD HH:mm:ss',
  TIME_FMT_2: 'YYYY/MM/DD HH:mm:ss',
  TIME_FMT_3: 'YYYY年M月D日 HH时mm分ss秒'
};

export const DATE_DISPLAY_FORMAT_TYPES = Object.assign(
  {},
  SHORT_DATE_YEAR_DISPLAY_FORMAT,
  SHORT_DATE_QUARTER_DISPLAY_FORMAT,
  SHORT_DATE_MONTH_DISPLAY_FORMAT,
  SHORT_DATE_DAY_DISPLAY_FORMAT,
  SHORT_DATE_TIME_DISPLAY_FORMAT
);

export const DIMENSION_GROUP_TYPES = {
  TEXT: 'LIST_GROUP',
  NUMBER: 'CUSTOM_GROUP',
  DATE: 'NORMAL_GROUP'
};

export const DATE_MODE_TYPES = {
  YEAR: 'year',
  YEAR_QUARTER: 'quarter',
  YEAR_MONTH: 'month',
  YEAR_MONTH_DAY: 'date'
};

export const DATE_MODE_FORMAT_TYPES = {
  YEAR: 'YYYY',
  YEAR_QUARTER: 'YYYY-[Q]Q',
  YEAR_MONTH: 'YYYY-MM',
  YEAR_MONTH_DAY: 'YYYY-MM-DD',
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss'
};

/**
 * 图表相关
 */

export const CHART_STATUS = ['intoCanvas', 'getChartData', 'done', 'queryError', 'refreshRelationChartData', 'noData'];

export const DRILL_DOWN_CHARTS = [
  'line',
  'area',
  'bar',
  'bar-horizontal',
  'pie',
  'pie-rose',
  'radar',
  'scatter',
  'map',
  'bubble-map',
  'table',
  'tableMultidimensional'
];

export const PROXIMITY_ANALYSIS_CHARTS = [
  'line',
  'area',
  'bar',
  'bar-stack',
  'bar-stack-percent',
  'bar-horizontal',
  'strip-stack',
  'strip-stack-percent',
  'radar'
];

export const AUXILIARY_LINE_CHARTS = [
  'line',
  'area',
  'bar',
  'bar-stack',
  'bar-stack-percent',
  'bar-horizontal',
  'strip-stack',
  'strip-stack-percent'
];

export const AXIS_STYLE_CHARTS = [
  'line',
  'area',
  'combination',
  'bar',
  'bar-stack',
  'bar-stack-percent',
  'bar-horizontal',
  'strip-stack',
  'strip-stack-percent',
  'scatter'
];

export const SINGLE_FIELD_AREA_CHARTS = ['kpi', 'gauge', 'funnel']; // 不包含维度

export const AXIS_LABELS = {
  xAxis: 'X轴',
  yAxis: '左Y轴',
  y2Axis: '右Y轴'
};

export const API_CHART_TYPE = {
  line: 'LINE',
  area: 'AREA',
  bar: 'BAR',
  'bar-stack': 'BAR_STACK',
  'bar-stack-percent': 'BAR_STACK_PERCENT',
  'bar-horizontal': 'BAR_HORIZONTAL',
  'strip-stack': 'STRIP_STACK',
  'strip-stack-percent': 'STRIP_STACK_PERCENT',
  pie: 'PIE',
  'pie-rose': 'PIE_ROSE',
  map: 'MAP',
  'bubble-map': 'BUBBLE_MAP',
  gauge: 'GAUGE',
  funnel: 'FUNNEL',
  radar: 'RADAR',
  scatter: 'SCATTER',
  table: 'TABLE',
  tableMultidimensional: 'COMPARE_TABLE',
  kpi: 'KPI_CAR',
  treemap: 'TREEMAP',
  combination: 'COMBINATION',
  'ranking-list': 'RANKING_LIST'
};

const swapApiChartType = () => {
  const ret = {};
  for (let key in API_CHART_TYPE) {
    ret[API_CHART_TYPE[key]] = key;
  }
  return ret;
};

export const SWAPPED_API_CHART_TYPE = swapApiChartType();

/**
 * Grid layout
 */

export const GRID_LAYOUT_BREAK_POINTS = {
  lg: { width: 1024, cols: 12 },
  md: { width: 996, cols: 10 },
  sm: { width: 768, cols: 6 },
  xs: { width: 480, cols: 4 },
  xxs: { width: 0, cols: 2 }
};
