import React, { useRef } from 'react';
import { Modal } from 'antd';
import DisplayFormatSettingForm from './display-format-setting-form';

function ModalDisplayFormatSetting({ visible, pattern, valueMagnitude, onOk, onCancel }) {
  const displayFormatSettingFormRef = useRef(null);

  const handleOk = () => {
    const { numberType, decimals, thousands, valueMagnitude } = displayFormatSettingFormRef.current.formData;
    let _pattern = `#${thousands ? ',' : ''}##0`;
    if (decimals > 0) {
      _pattern += `.${new Array(decimals).fill(0).join('')}`;
    }
    if (numberType === 'percent') {
      _pattern += '%';
    }
    onOk({ pattern: _pattern, numberType, valueMagnitude });
  };

  return (
    <Modal
      visible={visible}
      wrapClassName="modal-display-format"
      width={500}
      title="数值展示格式设置"
      okText="确认"
      cancelText="取消"
      onOk={handleOk}
      onCancel={onCancel}
    >
      <DisplayFormatSettingForm ref={displayFormatSettingFormRef} pattern={pattern} valueMagnitude={valueMagnitude} />
    </Modal>
  );
}

export default ModalDisplayFormatSetting;
