import React, { useContext } from 'react';
import classNames from 'classnames';
import { List, Button, Icon, Tag } from 'antd';
import { useMitt } from 'react-mitt';
import APIS from '@/apis/api';
import { http } from '@/lib';
import { downBlobFile } from '@/utils/methods';
import ExportTaskCtx from './ctx';
import './TaskList.less';

export type NoticeIconListProps = {
  count?: number;
  style?: React.CSSProperties;
  emptyText?: string;
  list: any[];
};
const TaskList: React.FC<NoticeIconListProps> = ({ list = [], emptyText }) => {
  const { emitter } = useMitt();
  const ctx = useContext(ExportTaskCtx);
  if (!list || list.length === 0) {
    return (
      <div className="not-found">
        <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="not found" />
        <div>{emptyText}</div>
      </div>
    );
  }

  /**
   * 删除任务
   * @param item 任务项
   */
  const handleDelete = item => {
    const { exportCode, filename } = item;
    http
      .post(APIS.DeleteExportTask, {
        exportCode,
        fileName: filename
      })
      .then(res => {
        ctx.handleDeleteTask(item);
      });
  };
  return (
    <div>
      <h3 className="list-title">导出任务</h3>
      <List
        className="list"
        dataSource={list}
        renderItem={(item, i) => {
          const itemCls = classNames('item', {
            'is-exporting': item.status === 'GENERATED',
            'is-fail': item.status === 'FAIL'
          });
          const itemActionButton =
            item.status === 'GENERATED' ? (
              <>
                <Button type="primary" ghost loading size="small">
                  导出中
                </Button>
                <Icon type="delete" onClick={() => handleDelete(item)} style={{ marginLeft: '8px', color: '#f00' }} />
              </>
            ) : item.status === 'FAIL' ? (
              <>
                <Tag color="red">失败</Tag>
                <Icon type="delete" onClick={() => handleDelete(item)} style={{ marginLeft: '8px', color: '#f00' }} />
              </>
            ) : (
              <Button type="primary" size="small" href={item.downloadUrl}>
                下载
              </Button>
            );
          return (
            <List.Item className={itemCls} key={item.key || i}>
              <List.Item.Meta
                className="meta"
                title={
                  <div className="title" style={{ maxWidth: item.status === 'GENERATED' ? '100px' : '158px' }}>
                    {item.filename}
                  </div>
                }
                description={itemActionButton}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default TaskList;
