import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button, message } from 'antd';
import SvgIcon from '@/components/SvgIcon';

interface IProps {
  editMode: 'designer' | 'viewer';
  tplName?: string;
  tplId?: string;
  onSave?: Function;
}
function Header({ editMode, tplName, tplId, onSave }: IProps) {
  const history = useHistory();
  const [state, setState] = useState({
    tplName: void 0
  });

  useEffect(() => {
    setState({
      tplName
    });
  }, [tplName]);

  const handleTplNameValueChange = ev => {
    setState({
      ...state,
      tplName: ev.target.value
    });
  };

  const handleSave = () => {
    if (!state.tplName) {
      message.error('报表名不能为空');
      return;
    }
    onSave({ tplName: state.tplName });
  };

  const handlePreview = () => {
    history.push({
      pathname: '/intBI/excelReport/viewer',
      search: `?tplId=${tplId}`
    });
  };

  const handleBack = () => {
    if (editMode === 'designer') {
      history.push('/intBI/dataReport/list');
    } else {
      history.goBack();
    }
  };

  return (
    <div className="rp-header-wrapper">
      <div className="header-icon header-back">
        <i className="svg-icon-wrapper icon-arrow-left" onClick={handleBack}>
          <SvgIcon iconClass="arrow-left" />
        </i>
      </div>
      <div className="header-content">
        <div className="action-panel-left">
          <div className="header-name">
            <div className="work-type-icon">
              <div className="header-icon">
                <i className="svg-icon-wrapper icon-report-small">
                  <SvgIcon iconClass="table" />
                </i>
              </div>
            </div>
            {editMode === 'designer' ? (
              <Input
                type="text"
                className="ant-v4-input header-name-input"
                placeholder="请输入报表名称"
                value={state.tplName}
                onChange={handleTplNameValueChange}
              />
            ) : (
              <span className="rp-tpl-name">{tplName}</span>
            )}
          </div>
        </div>
        {editMode === 'designer' && (
          <div className="action-panel-right">
            <div className="header-btn-wrapper">
              <Button type="primary" className="header-btn" onClick={handleSave}>
                保存
              </Button>
              <Button type="primary" ghost className="header-btn" disabled={!tplId} onClick={handlePreview}>
                预览
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
