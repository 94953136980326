import _ from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Modal } from 'antd';
import { destroyFns, ModalProps } from 'antd/lib/modal/Modal';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import ConfigProvider from 'antd/lib/config-provider';

// TODO destroyFns 和运行时的antd destroyFns 不是同一个变量，导致无法通过 Modal.destroyAll() 来销毁，可能是打包工具的问题，发布后再测试。

const IS_REACT_16 = !!ReactDOM.createPortal;

const UdModal = {
  open: (config: IUdModelProps) => {
    const div = document.createElement('div');
    document.body.appendChild(div);

    let currentConfig = { ...config, confirmLoading: false, visible: true } as any;
    let contentHandler;

    let content = React.cloneElement(currentConfig.content, {
      getHandler: handler => {
        contentHandler = handler;
      }
    });

    function render(props: IUdModelProps) {
      ReactDOM.render(
        <ConfigProvider locale={zh_CN}>
          <Modal centered {...props} onOk={ok} onCancel={() => close({ triggerCancel: true })}>
            {content}
          </Modal>
        </ConfigProvider>,
        div
      );
    }

    function ok() {
      let value = null;
      let inner = () => {
        if (config.onOk) {
          let result = config.onOk(value);
          if (result && _.isFunction(result.then)) {
            update({ confirmLoading: true });
            result
              .finally(() => {
                update({ confirmLoading: false });
              })
              .then(() => {
                close();
              });
          } else {
            close();
          }
        } else {
          close();
        }
      };
      if (contentHandler) {
        value = contentHandler();
        if (value && _.isFunction(value.then)) {
          update({ confirmLoading: true });
          value
            .finally(() => {
              update({ confirmLoading: false });
            })
            .then(res => {
              value = res;
              inner();
            });
        } else {
          inner();
        }
      } else {
        inner();
      }
    }

    function update(newConfig: Partial<IUdModelProps>) {
      currentConfig = { ...currentConfig, ...newConfig };
      render(currentConfig);
    }

    function close(...args: any[]) {
      currentConfig = {
        ...currentConfig,
        visible: false,
        afterClose: destroy.bind(this, ...args)
      };
      if (IS_REACT_16) {
        render(currentConfig);
      } else {
        destroy(...args);
      }
    }

    function destroy(...args: any[]) {
      const unmountResult = ReactDOM.unmountComponentAtNode(div);
      if (unmountResult && div.parentNode) {
        div.parentNode.removeChild(div);
      }
      const triggerCancel = args.some(param => param && param.triggerCancel);
      if (config.onCancel && triggerCancel) {
        config.onCancel(...args);
      }
      for (let i = 0; i < destroyFns.length; i++) {
        const fn = destroyFns[i];
        if (fn === close) {
          destroyFns.splice(i, 1);
          break;
        }
      }
    }

    render(currentConfig);

    destroyFns.push(close);

    return {
      destroy: close,
      update
    };
  }
};

export interface IUdModelProps extends ModalProps {
  /**
   * 模态框内容
   */
  content: React.ReactNode;
  /**
   * OK事件
   */
  onOk?: (value: any) => void | Promise<any>;
  /**
   * Cancel事件
   */
  onCancel?: (...args: any[]) => void;
}

export interface IUdModalContentProps {
  getHandler?: (handler: () => any) => void;
}

export { UdModal };
