import { createContext } from 'react';

export type DeviceType = 'pc' | 'mobile';

export const WorkplaceContext = createContext<{
  triggerUpdate?: boolean;
  setTriggerUpdate?: (value: boolean) => void;
  activeGridItemLayout?: any;
  setActiveGridItemLayout?: (value: any) => void;
  widgetMap?: { [key: string]: any };
  widgetConditionMap?: { [key: string]: any };
  setWidgetConditionMap?: (widgetConditionMap: { [key: string]: any }) => void;
  chartConditionsMap?: any;
  setChartConditionsMap?: (chartConditionsMap: any) => void;
  activeDevice?: DeviceType;
  setActiveDevice?: React.Dispatch<React.SetStateAction<DeviceType>>;
  dashboardName?: string;
  setDashboardName?: (value: string) => void;
  QRCodeConfig?: any;
  setQRCodeConfig?: (value: any) => void;
  showWatermark?: boolean;
  setShowWatermark?: (value: boolean) => void;
}>({});
