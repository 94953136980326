import React, { useState, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Modal } from 'antd';
import ConfigCompareFieldModalContent, {
  dateFormat,
  dateErrorMsgTip,
  validateDynamicBaseValue,
  validateDynamicIncrement,
  validateRangeItemCondition
} from '@/pages/dataBoard/components/ConfigCompareFieldModalContent';

const generateFormModel = () => {
  return {
    valueCategory: 'STATIC_MULTIVALUED',
    staticValues: [{ value: '', validateStatus: 'success', errorMsg: dateFormat }],
    rangeValues: [{ start: '', end: '', validateStatus: 'success', errorMsg: dateFormat }],
    dynamicValue: {
      baseValue: {
        value: ''
      },
      increment: {
        value: ''
      },
      diffType: 'minus'
    }
  };
};
function ModalSettingCompareField({ visible, onOk, onCancel, dataSetId, activeField, flattenedFields, compareField }) {
  const settingCompareFieldRef = useRef(null);
  const [formData, setFormData] = useState(generateFormModel());

  const handleOk = () => {
    const { formData: refFormData, selectedFieldValues } = settingCompareFieldRef.current;
    const {
      uniqueId,
      attribute: { valueType }
    } = activeField;
    const compareFieldParams: { [key: string]: any } = {
      fieldUniqueId: uniqueId
    };

    if (valueType === 'DATE') {
      compareFieldParams.valueCategory = refFormData.valueCategory;
      if (refFormData.valueCategory === 'STATIC_MULTIVALUED') {
        const staticValues = _.cloneDeep(refFormData.staticValues);
        const isValid = staticValues.every(val => val.validateStatus === 'success' && val.value);
        if (!isValid) {
          setFormData({
            ...formData,
            valueCategory: refFormData.valueCategory,
            staticValues: staticValues.map(val => ({
              ...val,
              validateStatus: !val.value ? 'error' : 'success',
              errorMsg: !val.value ? dateErrorMsgTip : null
            }))
          });
          return;
        }
        compareFieldParams.staticValues = staticValues.map(val => moment(val.value).format(dateFormat));
      } else if (refFormData.valueCategory === 'DYNAMIC_MULTIVALUED') {
        const { dynamicValue } = _.cloneDeep(refFormData);
        const isValid = Object.keys(dynamicValue)
          .filter(key => key !== 'diffType')
          .every(key => dynamicValue[key].value !== '');
        const dynamicBaseValue = dynamicValue.baseValue.value;
        const dynamicIncrement = dynamicValue.increment.value;
        const dynamicDiffType = dynamicValue.diffType;
        if (!isValid) {
          setFormData({
            ...formData,
            valueCategory: refFormData.valueCategory,
            dynamicValue: {
              ...formData.dynamicValue,
              baseValue: {
                ...validateDynamicBaseValue(dynamicBaseValue),
                value: dynamicBaseValue
              },
              increment: {
                ...validateDynamicIncrement(dynamicIncrement),
                value: dynamicIncrement
              }
            }
          });
          return;
        }
        compareFieldParams.dynamicValue = {
          baseValue: moment(dynamicBaseValue).format(dateFormat),
          increment: dynamicDiffType === 'minus' ? `-${dynamicIncrement}` : `${dynamicIncrement}`
        };
      } else {
        const { rangeValues } = _.cloneDeep(refFormData);
        const isValid = rangeValues
          .map(({ start, end }) => ({
            ...validateRangeItemCondition([start, end]),
            start,
            end
          }))
          .every(val => val.validateStatus === 'success');
        if (!isValid) {
          setFormData({
            ...formData,
            valueCategory: refFormData.valueCategory,
            rangeValues: rangeValues.map(({ start, end }) => ({
              ...validateRangeItemCondition([start, end]),
              start,
              end
            }))
          });
          return;
        }
        compareFieldParams.rangeValues = rangeValues.map(({ start, end }) => ({
          start: moment(start).format(dateFormat),
          end: moment(end).format(dateFormat)
        }));
      }
    } else {
      compareFieldParams.valueCategory = 'STATIC_MULTIVALUED';
      compareFieldParams.staticValues = selectedFieldValues.map(fieldVal => fieldVal.value);
    }
    onOk(compareFieldParams);
  };

  const handleCancel = () => {
    resetFormData();
    onCancel();
  };

  const resetFormData = () => {
    setFormData(generateFormModel());
  };

  return (
    <Modal
      title="对比字段设置"
      visible={visible}
      maskClosable={false}
      width={520}
      wrapClassName="dlg-compare-field-wrapper"
      okText="确认"
      cancelText="取消"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <ConfigCompareFieldModalContent
        ref={settingCompareFieldRef}
        dataSetId={dataSetId}
        compareField={activeField}
        fields={flattenedFields}
        compareFieldConfig={compareField}
        compareFieldConfigFormData={formData}
        selectedFieldValuesFromParent={compareField ? compareField.staticValues : []}
      />
    </Modal>
  );
}

export default ModalSettingCompareField;
