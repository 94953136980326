import React, { FC, useState, useEffect, useRef, CSSProperties } from 'react';
import _ from 'lodash';
import { isHexColor, hexToRGBA, replaceAlpha } from '@/utils/utils';
import './index.less';

interface IProps {
  element?: {
    // 显示表头
    visibleHeader?: boolean;
    // 列表数据
    data?: any[];
    // 动画
    animate?: {
      // 开启轮播
      loop: boolean;
      // 时间间隔 @defaultValue 3000ms
      interval: number;
    };
    // 显示行数 @defaultValue 5
    displayRows?: number;
    // 序号形状 @defaultValue circle
    serialNoShape: 'circle' | 'diamond';
  };
  compStyle?: { [key: string]: CSSProperties | any };
}
const INDEX_MAP = {
  0: 'first',
  1: 'second',
  2: 'third'
};
const RankingList: FC<IProps> = props => {
  const topListRef = useRef(null);
  const timer = useRef(null);
  const [list, setList] = useState([
    { key: '1', 详细信息: '对客户：提供极致的服务和体验' },
    { key: '2', 详细信息: '对合作伙伴：创造共生、互生、再生的行业生态，共享商业成功' },
    { key: '3', 详细信息: '对员工：提供公平发展的舞台，实现更有尊严的生活' },
    { key: '4', 详细信息: '对股东：带来持续、稳健的价值回报' },
    { key: '5', 详细信息: '对社会：做心存感恩，责任担当的企业公民' }
  ]);
  const [style, setStyle] = useState<{ [key: string]: CSSProperties | any }>({
    tHeader: {
      lineHeight: 32,
      backgroundColor: 'rgba(0,0,0,1)',
      fontSize: 12,
      color: 'rgba(255,255,255,0.85)',
      fontWeight: 'normal',
      fontFamily: 'siyuan'
    },
    defaultRow: {
      bgColorGroup: ['rgba(27,100,238,0.1)', 'rgba(27,100,238,0.1)']
    },
    selectedRow: {
      backgroundColor: 'rgba(27,100,238,1)'
    },
    specialRow: {
      bgColorGroup: ['#c66e16', '#c3a408', '#049867']
    },
    serialNo: {
      symbolSize: 25,
      defaultRow: {
        backgroundColor: 'rgba(27,100,238,1)'
      },
      firstRow: {
        backgroundColor: '#c66e16',
        fontSize: 12,
        color: 'rgba(255,255,255,1)',
        fontWeight: 'normal',
        fontFamily: 'STHeiti'
      },
      secondRow: {
        backgroundColor: '#c3a408',
        fontSize: 12,
        color: 'rgba(255,255,255,1)',
        fontWeight: 'normal',
        fontFamily: 'STHeiti'
      },
      thirdRow: {
        backgroundColor: '#049867',
        fontSize: 12,
        color: 'rgba(255,255,255,1)',
        fontWeight: 'normal',
        fontFamily: 'STHeiti'
      }
    }
  });
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    if (props.element.animate.loop) {
      timer.current = setInterval(() => {
        setActiveIndex(activeIndex === props.element.displayRows - 1 || activeIndex === -1 ? 0 : activeIndex + 1);
      }, props.element.animate.interval);
    } else {
      clearInterval(timer.current);
      setActiveIndex(-1);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [activeIndex, props.element.animate.loop, props.element.animate.interval]);

  useEffect(() => {
    if (props.compStyle) {
      initStyle();
    }
  }, [props.compStyle]);

  useEffect(() => {
    if (!props.element.data.length) return;
    setList(props.element.data);
    setActiveIndex(0);
  }, [props.element.data]);

  const initStyle = () => {
    let newStyle = _.cloneDeep(style);
    const { compStyle } = props;
    newStyle.tHeader.lineHeight = compStyle.tHeader.lineHeight;
    newStyle.tHeader.backgroundColor = compStyle.tHeader.backgroundColor;
    newStyle.tHeader.fontSize = compStyle.tHeader.fontSize;
    newStyle.tHeader.color = compStyle.tHeader.color;
    newStyle.tHeader.fontFamily = compStyle.tHeader.fontFamily;
    newStyle.tHeader.fontWeight = compStyle.tHeader.fontWeight;
    newStyle.defaultRow.bgColorGroup[0] = compStyle.row.default.odd.backgroundColor;
    newStyle.defaultRow.bgColorGroup[1] = compStyle.row.default.even.backgroundColor;
    newStyle.selectedRow.backgroundColor = compStyle.row.selected.backgroundColor;
    newStyle.specialRow.bgColorGroup[0] = compStyle.row.special.firstBgColor;
    newStyle.specialRow.bgColorGroup[1] = compStyle.row.special.secondBgColor;
    newStyle.specialRow.bgColorGroup[2] = compStyle.row.special.thirdBgColor;
    newStyle.serialNo.symbolSize = compStyle.serialNo.default.symbolSize;
    newStyle.serialNo.defaultRow.backgroundColor = compStyle.serialNo.default.backgroundColor;
    newStyle.serialNo.firstRow.backgroundColor = compStyle.serialNo.specialRow.first.backgroundColor;
    newStyle.serialNo.firstRow.fontSize = compStyle.serialNo.specialRow.first.fontSize;
    newStyle.serialNo.firstRow.color = compStyle.serialNo.specialRow.first.color;
    newStyle.serialNo.firstRow.fontWeight = compStyle.serialNo.specialRow.first.fontWeight;
    newStyle.serialNo.firstRow.fontFamily = compStyle.serialNo.specialRow.first.fontFamily;
    newStyle.serialNo.secondRow.backgroundColor = compStyle.serialNo.specialRow.second.backgroundColor;
    newStyle.serialNo.secondRow.fontSize = compStyle.serialNo.specialRow.second.fontSize;
    newStyle.serialNo.secondRow.color = compStyle.serialNo.specialRow.second.color;
    newStyle.serialNo.secondRow.fontWeight = compStyle.serialNo.specialRow.second.fontWeight;
    newStyle.serialNo.secondRow.fontFamily = compStyle.serialNo.specialRow.second.fontFamily;
    newStyle.serialNo.thirdRow.backgroundColor = compStyle.serialNo.specialRow.third.backgroundColor;
    newStyle.serialNo.thirdRow.fontSize = compStyle.serialNo.specialRow.third.fontSize;
    newStyle.serialNo.thirdRow.color = compStyle.serialNo.specialRow.third.color;
    newStyle.serialNo.thirdRow.fontWeight = compStyle.serialNo.specialRow.third.fontWeight;
    newStyle.serialNo.thirdRow.fontFamily = compStyle.serialNo.specialRow.third.fontFamily;
    setStyle({
      ...style,
      ...newStyle
    });
  };

  const calColumnWidth = () => {
    if (!topListRef.current) return { width: 352 };
    if (topListRef.current) {
      return topListRef.current.getBoundingClientRect();
    }
  };

  const getRowSelectedStyleBgColor = index => {
    if (activeIndex === index) {
      if (index > 2) {
        return `linear-gradient(to right, ${replaceAlpha(
          isHexColor(style.selectedRow.backgroundColor)
            ? hexToRGBA(style.selectedRow.backgroundColor)
            : style.selectedRow.backgroundColor,
          '0.65'
        )}, ${replaceAlpha(
          isHexColor(style.selectedRow.backgroundColor)
            ? hexToRGBA(style.selectedRow.backgroundColor)
            : style.selectedRow.backgroundColor,
          '0.25'
        )})`;
      }
      return `linear-gradient(to right, ${replaceAlpha(
        isHexColor(style.specialRow.bgColorGroup[index])
          ? hexToRGBA(style.specialRow.bgColorGroup[index])
          : style.specialRow.bgColorGroup[index],
        '0.65'
      )}, ${replaceAlpha(
        isHexColor(style.specialRow.bgColorGroup[index])
          ? hexToRGBA(style.specialRow.bgColorGroup[index])
          : style.specialRow.bgColorGroup[index],
        '0.25'
      )})`;
    }
    return style.defaultRow.bgColorGroup[index % 2 === 0 ? 0 : 1];
  };
  // console.log('RankingList render: ', activeIndex);
  return (
    <div className="top-list-wrapper">
      <ul className="top-list" ref={topListRef}>
        {props.element.visibleHeader && (
          <li
            className="title"
            style={{
              ...style.tHeader,
              height: style.tHeader.lineHeight + 'px',
              lineHeight: style.tHeader.lineHeight + 'px',
              fontSize: style.tHeader.fontSize + 'px'
            }}
          >
            <div className="style-th" style={{ width: `${calColumnWidth().width * 0.1}px` }}>
              排名
            </div>

            {Object.keys(list[0])
              .filter(key => key !== 'key')
              .map(key => (
                <div className="style-th" key={key}>
                  {key}
                </div>
              ))}
          </li>
        )}
        {list.slice(0, props.element.displayRows).map((item, index) => {
          const styleBgColor = getRowSelectedStyleBgColor(index);
          return (
            <li key={item.key} className="item">
              <div className="style-td" style={{ width: `${calColumnWidth().width * 0.1}px` }}>
                <span
                  style={{
                    height: style.serialNo.symbolSize + 'px',
                    fontSize: index > 2 ? '12px' : style.serialNo[`${INDEX_MAP[index]}Row`].fontSize + 'px',
                    color: index > 2 ? '#fff' : style.serialNo[`${INDEX_MAP[index]}Row`].color,
                    fontWeight: index > 2 ? 'normal' : style.serialNo[`${INDEX_MAP[index]}Row`].fontWeight,
                    fontFamily: index > 2 ? 'STHeiti' : style.serialNo[`${INDEX_MAP[index]}Row`].fontFamily
                  }}
                >
                  <i
                    className={`style-${props.element.serialNoShape}`}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      zIndex: -1,
                      marginTop: `-${style.serialNo.symbolSize / 2}px`,
                      marginLeft: `-${style.serialNo.symbolSize / 2}px`,
                      width: style.serialNo.symbolSize + 'px',
                      height: style.serialNo.symbolSize + 'px',
                      backgroundColor:
                        index > 2
                          ? style.serialNo.defaultRow.backgroundColor
                          : style.serialNo[`${INDEX_MAP[index]}Row`].backgroundColor
                    }}
                  ></i>
                  {index + 1}
                </span>
              </div>
              {Object.keys(item)
                .filter(key => key !== 'key')
                .map(key => (
                  <div className="style-td" key={key} style={{ fontSize: '12px', color: '#fff' }}>
                    {item[key]}
                  </div>
                ))}
              <div
                className="style-bg"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: `${(calColumnWidth().width * 0.1) / 2}px`,
                  transform: 'translateY(-50%)',
                  width: `${calColumnWidth().width}px`,
                  height: `${style.serialNo.symbolSize}px`,
                  background: `${styleBgColor}`
                }}
              ></div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

RankingList.defaultProps = {
  element: {
    visibleHeader: true,
    animate: {
      loop: true,
      interval: 3000
    },
    serialNoShape: 'circle',
    displayRows: 5
  }
};

export default RankingList;
