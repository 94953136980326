import { IMenuExtraPath, IMenuRelevants, IUaaMenu } from '../lib/types'

let menus: IUaaMenu[] = []

if (process.env.REACT_APP_ENV === 'local') {
  menus = [
    {
      customId: 'home_page',
      text: '首页',
      link: '/',
      icon: 'user',
    },
    {
      customId: 'user_management',
      text: '用户管理',
      link: '/UserManagement',
      icon: 'user',
      items: [
        {
          customId: 'M_ACCOUNT_SYSTEM_MANAGE_USER_LIST',
          text: '账号列表',
          link: '/InternalStaffManagement/List',
          // icon: 'sound'
        },
        {
          customId: 'M_ACCOUNT_SYSTEM_MANAGE_ROLE_LIST',
          text: '角色列表',
          link: '/RolePermissions/List',
          // icon: 'sound'
        },
        {
          customId: 'M_ACCOUNT_SYSTEM_MANAGE_DEPARTMENT_LIST',
          text: '岗位列表',
          link: '/Job/List',
          // icon: 'sound'
        },
      ]
    },
    // {
    //   customId: 'interface_log',
    //   text: '接口日志',
    //   link: '/interfaceLog/list',
    //   icon: 'audit'
    // },
    {
      customId: 'M_DATA_MANAGE_DATA_CENTER_TABLE_LIST',
      text: '数据中心',
      link: '/dataCenter/list',
      icon: 'audit'
    },
    {
      customId: 'M_DATA_MANAGE_DATA_PROCESSING_LIST',
      text: '数据加工',
      link: '/dataProcess/list',
      icon: 'audit'
    },
    {
      customId: 'M_DATA_MANAGE_DATA_BOARD_LIST',
      text: '数据看板列表',
      link: '/dataBoard/list',
      icon: 'audit'
    },
    {
        customId: 'M_DATA_MANAGE_DATA_MONITOR_MANAGE',
        text: '预警管理',
        link: '/warningManage',
        icon: 'bell',
        items: [
            {
                customId: 'M_DATA_MANAGE_DATA_MONITOR_LIST',
                text: '预警列表',
                link: '/warningManage/list',
                icon: 'bars'
            },
            {
                customId: 'M_DATA_MANAGE_DATA_MONITOR_LOG_LIST',
                text: '预警日志',
                link: '/warningManage/log/list',
                icon: 'file'
            }
        ]
    }
    // {
    //   customId: 'user_system_menu_manage',
    //   text: '菜单管理',
    //   link: '/menus',
    //   icon: 'setting',
    // },
  ]
}

let menuRelevants: IMenuRelevants = {
  '_supplier_business_purchase_findListByCommodityProvince': [
    '_supplier_business_purchase_findByStoreAndCity'
  ]
}

let menuExtraPaths: IMenuExtraPath = {
  'qrc_dispatch_data': (path) => path + '?conditions={"dispatchNo":"QD1199953842324418560"}'
}

export { menus, menuRelevants, menuExtraPaths }

