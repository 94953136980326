/**
 * 图表基本配置项 以及 数据组装方法
 *
 */

import chinaJson from '@/assets/china.json';
import { IStyleConfig } from '@/store/DataBoard';
import { colorArr, colors } from '@/utils/colors';
import {
  formatNumber,
  toThousands,
  formatEchartsValue,
  formatEchartsValueWithDecimal,
  formatNumber1
} from '@/utils/utils';
import { isArrayIncludeNegativeItem } from '@/utils/is';
import _ from 'lodash';
import {
  BASE_LINE,
  AREA_LINE,
  BASE_BAR,
  HORIZONTAL_BAR,
  BAR_STACK_PERCENT,
  STRIP_STACK_PERCENT,
  BASE_PIE,
  ROSE_PIE,
  BASE_TREEMAP,
  BASE_TABLE,
  MULTI_DIMENSIONAL_TABLE,
  BASE_KPI,
  BASE_GAUGE,
  BASE_RADAR,
  BASE_FUNNEL,
  BASE_SCATTER,
  BASE_MAP,
  BUBBLE_MAP,
  BASE_MIX
} from '../chart/option';
import { ECHART_SERIES_TYPE_MAP, funcSize } from '../ChartType/util';
import {
  measureFieldCaptionExcludeCalculatorType,
  groupSeriesByChartType,
  calculateFieldVid
} from '@/pages/dashboard/workplace/util';
interface IHighConfig {
  proximityAnalysisStatus: boolean;
}

const chartUtils = (chartType: string) => {
  switch (chartType) {
    case 'line':
      return BASE_LINE;
    case 'area':
      return AREA_LINE;
    case 'bar':
    case 'bar-stack':
      return BASE_BAR;
    case 'bar-stack-percent':
      return BAR_STACK_PERCENT;
    case 'bar-horizontal':
    case 'strip-stack':
      return HORIZONTAL_BAR;
    case 'strip-stack-percent':
      return STRIP_STACK_PERCENT;
    case 'pie':
      return BASE_PIE;
    case 'pie-rose':
      return ROSE_PIE;
    case 'treemap':
      return BASE_TREEMAP;
    case 'table':
      return BASE_TABLE;
    case 'tableMultidimensional':
      return MULTI_DIMENSIONAL_TABLE;
    case 'kpi':
      return BASE_KPI;
    case 'gauge':
      return BASE_GAUGE;
    case 'radar':
      return BASE_RADAR;
    case 'funnel':
      return BASE_FUNNEL;
    case 'scatter':
      return BASE_SCATTER;
    case 'map':
      return BASE_MAP;
    case 'bubble-map':
      return BUBBLE_MAP;
    case 'combination':
      return BASE_MIX;
  }
};

/**
 * 获取地图Pieces图例
 * @param styleConfig
 */
const getVisualMapPieces = (styleConfig: Partial<IStyleConfig>, dimensionFirstFieldsValues: number[]) => {
  let { min, max, colorBarIndex } = styleConfig;
  let colors = colorArr[colorBarIndex];
  let piecesValues = [];
  // 默认取第一个度量的值最大最小值
  min = min || Number(_.min(dimensionFirstFieldsValues));
  max = max || Number(_.max(dimensionFirstFieldsValues));
  let interval = (max - min) / 6;
  colors?.forEach((color, index) => {
    let tempMin = Math.floor(min + interval * index);
    let tempMax = Math.floor(min + interval * (index + 1));
    piecesValues.push({
      min: tempMin,
      max: tempMax,
      color: color,
      label: `${tempMin}~${tempMax}`
    });
  });
  return piecesValues;
};

/**
 * 计算系列数据最小值和基数
 */
export const getSeriesDataMinAndRadix = seriesData => {
  const zippedSeriesData = _.zip(...seriesData.map(item => item.value.map(val => (!val ? 0 : val))));
  const zippedMin = zippedSeriesData.map(item => Math.min(...item.map(val => +val)));
  // console.log('getSeriesDataMinAndRadix: ', zippedSeriesData, zippedMin);
  const radix = zippedMin.map(item => {
    const strItem = item.toString();
    const [integer] = strItem.split('.');
    if (parseInt(integer) === 0) {
      return 0;
    }
    let integerLen = integer.length - 1;
    let ret = '1';
    while (integerLen) {
      ret += '0';
      integerLen--;
    }
    return +ret;
  });
  return {
    zippedMin,
    radix
  };
};

const mapMeasureData = data => {
  return data.map(({ originalValue, displayValue }) => ({
    value: originalValue,
    displayValue
  }));
};

/**
 * 设置对比交叉表表头title别名
 * @param columns
 * @param extraConfigFields
 */
const setColumnTitle = (columns, extraConfigFields) => {
  const extraConfigFieldCaptions = extraConfigFields.map(field => field.caption);
  const helperFn = (data, fieldCaptions) => {
    if (fieldCaptions.includes(data.title)) {
      const { aliasName } = extraConfigFields.find(field => field.caption === data.title);
      data.title = aliasName;
    }
    if (data.children) {
      data.children.forEach(child => helperFn(child, fieldCaptions));
    }
    return data;
  };
  return columns.map(col => helperFn(col, extraConfigFieldCaptions));
};

/**
 * 获取图表具体配置
 *
 * @param dimensionData 维度数据
 * @param measureData 度量数据
 * @param chartType 图表类型
 *
 * @returns 返回具体的配置信息
 */
const getChartConfig = (
  chartsData,
  dimensionData,
  measureData,
  chartType,
  branchTag: string,
  styleConfig?: Partial<IStyleConfig>,
  highConfig?: IHighConfig,
  chartMixDataConfig?: any,
  mapGEOConfig?: any
) => {
  let { endValue, xName, xUnit, yName, yUnit } = styleConfig;
  // 拼装渲染数据
  let legend = [],
    series = [];
  if (
    !['kpi', 'table', 'radar', 'pie', 'pie-rose', 'treemap', 'funnel', 'map', 'bubble-map', 'combination'].includes(
      chartType
    )
  ) {
    for (const key in measureData) {
      legend.push(key);
      series.push({
        data: mapMeasureData(measureData[key]),
        name: key,
        type: ECHART_SERIES_TYPE_MAP.get(chartType)
      });
    }
  }

  let options: any = _.cloneDeep(chartUtils(chartType)) || {};
  if (chartType === 'tableMultidimensional') {
    const extraConfigFields = chartMixDataConfig;
    let compareTableData = chartsData.compareTableData;
    let columns = compareTableData?.header;
    let dataSource = compareTableData?.data;
    options = {
      ...options,
      columns: setColumnTitle(columns, extraConfigFields),
      dataSource
    };
    series = options.series;
  } else if (chartType === 'kpi') {
    // 指标卡
    let measureValue = [];
    for (const key in measureData) {
      measureValue.push({
        name: key,
        value: measureData[key].map(({ originalValue }) => originalValue),
        displayValue: measureData[key].map(({ displayValue }) => displayValue)
      });
    }

    legend = measureValue[0].name;
    /* let sum: any = 0;
    measureValue[0].value.filter(Boolean).forEach(item => {
      // sum += (parseFloat(item) * 1000) / 1000;
      sum = parseFloat((sum + +item).toFixed(10));
    });
    // 格式化千分位处理，并保留小数位2位
    sum = formatEchartsValueWithDecimal(sum);
    sum = (styleConfig.prefix || '') + sum + (' ' + (styleConfig.suffix || '')); */
    series = [{ value: measureValue[0]['displayValue'], type: 'kpi', data: [] }];
    options.color = styleConfig.color || '#e20023';
    options.fontSize = styleConfig.fontSize || 20;
  } else if (chartType === 'table') {
    const columns = [];
    const dataSource = [];
    const datas = [];

    for (const key in dimensionData) {
      columns.push({
        title: key,
        dataIndex: key,
        key: key
      });
      datas.push(dimensionData[key]);
    }

    for (const key in measureData) {
      columns.push({
        title: key,
        dataIndex: key,
        key: key
      });
      datas.push(measureData[key]);
    }

    Array.isArray(datas[0]) &&
      datas[0].forEach((item, index) => {
        let data = {};
        columns.forEach((column, columnIndex) => {
          let value = datas[columnIndex][index]['originalValue'];
          // data[column.key] = _.isNumber(value) ? toThousands(value) : value;
          data[column.key] = datas[columnIndex][index]['displayValue'];
        });
        dataSource.push(data);
      });

    //设置一下key
    dataSource.forEach((data, index) => {
      data['key'] = index;
    });

    // 交叉表格
    options = {
      ...options,
      columns,
      dataSource
    };
    series = options.series;
  } else if (chartType === 'pie' || chartType === 'pie-rose' || chartType === 'treemap') {
    // 饼图
    let seriesData = [];
    dimensionData.merge_dimension.forEach(item => {
      legend.push(item.displayValue);
      seriesData.push({
        name: item.displayValue
      });
    });

    for (const key in measureData) {
      measureData[key].forEach((item, index) => {
        seriesData[index].value = item.originalValue;
        seriesData[index].displayValue = item.displayValue;
      });
    }
    options.series[0].data = seriesData;
    series = options.series;
  } else if (chartType === 'radar') {
    // 雷达图
    let indicator = [],
      seriesData = [];
    // 此处需要判断以维度还是度量作为分支标签
    if (branchTag === 'dimension') {
      dimensionData.merge_dimension?.map(item => {
        // data_board_charts_dimension
        indicator.push({
          name: item.displayValue
        });
      });
      for (const key in measureData) {
        legend.push(key);
        seriesData.push({
          value: measureData[key].map(item => item.originalValue),
          name: key,
          displayValue: measureData[key].map(item => item.displayValue)
        });
      }
    } else {
      legend = dimensionData.merge_dimension.map(item => item.displayValue);

      let seriesDataValues = [];
      let seriesDataDisplayValues = [];
      for (const key in measureData) {
        indicator.push({
          name: key
        });
        seriesDataValues.push(measureData[key].map(item => item.originalValue));
        seriesDataDisplayValues.push(measureData[key].map(item => item.displayValue));
      }
      legend.forEach((item, index) => {
        let dataValues = [];
        const dataDisplayValues = [];
        seriesDataValues.forEach(seriesDataValueItem => {
          dataValues.push(seriesDataValueItem[index]);
        });
        seriesDataDisplayValues.forEach(itemDisplayVal => {
          dataDisplayValues.push(itemDisplayVal[index]);
        });
        seriesData.push({
          name: item,
          value: dataValues,
          displayValue: dataDisplayValues
        });
      });
    }
    const { zippedMin, radix } = getSeriesDataMinAndRadix(seriesData);
    // console.log('radar: ', indicator, seriesData, zippedMin, radix);
    options.radar.indicator = indicator.map((item, index) => {
      return Object.assign(
        {},
        { ...item, color: '#333' },
        highConfig && highConfig.proximityAnalysisStatus
          ? { min: zippedMin[index] - radix[index] }
          : {
            min: seriesData.map(itemSeriesData => itemSeriesData.value).some(val => +val < 0)
              ? Math.min(...seriesData.map(itemSeriesData => itemSeriesData.value).map(val => Number(val)))
              : 0
          }
      );
    });
    options.legend.data = legend;
    series.push({
      name: '雷达图',
      type: chartType,
      data: seriesData.map(item => ({
        ...item,
        indicator
      }))
    });
  } else if (chartType === 'gauge') {
    // 仪表盘
    let seriesData = [];
    /* dimensionData.merge_dimension.forEach(item => {
      legend.push(item);
    }); */
    for (const key in measureData) {
      measureData[key].forEach((item, index) => {
        // item = endValue ? parseFloat(((item * 100) / endValue).toFixed(2)) : item;
        // 仪表盘显示NaN bug修复
        seriesData.push({
          value: endValue && !isNaN(item) ? parseFloat(((item * 100) / endValue).toFixed(2)) : 100,
          originalValue: item.originalValue,
          displayValue: item.displayValue
        });
      });
    }
    options.series[0].data = seriesData;
    options.series[0].itemStyle.color = styleConfig.color || colors[0];
    if (styleConfig.flexBase < 49) {
      options.series[0].axisLabel.show = false;
    }
    series = options.series;
  } else if (chartType === 'map') {
    // 区域地图
    const extraConfigFields = _.cloneDeep(chartMixDataConfig);
    const extraConfigFieldCaptions = extraConfigFields.map(field => field.caption);
    const { mapName, GEOJson } = mapGEOConfig;
    const areaFeatures = _.cloneDeep(GEOJson.features);
    let dimensionFirstFieldsValues = [];
    areaFeatures.forEach((area: any) => {
      for (const key in dimensionData) {
        if (!key) {
          continue;
        }
        let dimensionAreaName = dimensionData[key].areaName;
        if (!dimensionAreaName) {
          continue;
        }
        if (area.properties.name.includes(dimensionAreaName) || dimensionAreaName.includes(area.properties.name)) {
          let measureValue = dimensionData[key].measureValue;
          let label = Object.keys(measureValue)[0];
          const value = measureValue[label].originalValue;
          const displayValue = measureValue[label].displayValue;
          const areaData = {};
          Object.keys(measureValue).forEach(key => {
            if (extraConfigFieldCaptions.includes(key)) {
              const { aliasName } = extraConfigFields.find(field => field.caption === key);
              areaData[aliasName] = measureValue[key];
            } else {
              areaData[key] = measureValue[key];
            }
          });
          area.value = value;
          area.displayValue = displayValue;
          area.areaData = areaData;
          area.adcode = area.properties.adcode;
          dimensionFirstFieldsValues.push(value);
        }
      }
    });
    let data = areaFeatures.map((item: any) => {
      return {
        name: item.properties.name,
        value: item.value,
        displayValue: item.displayValue,
        areaData: item.areaData,
        adcode: item.adcode
      };
    });

    series = _.cloneDeep(options.series);
    series[0].data = data;
    series[0].map = mapName;
    options.geo.map = mapName;
    let visualMapPieces = getVisualMapPieces(
      styleConfig,
      dimensionFirstFieldsValues.map(value => parseFloat(value))
    );
    if (visualMapPieces.length) {
      options.visualMap.show = true;
    }
    options.visualMap.pieces = visualMapPieces;
  } else if (chartType === 'bubble-map') {
    // console.log('chinaJson: ', _.cloneDeep(chinaJson.features), dimensionData);
    const extraConfigFields = _.cloneDeep(chartMixDataConfig);
    const extraConfigFieldCaptions = extraConfigFields.map(field => field.caption);
    const { mapName, GEOJson } = mapGEOConfig;
    const areaFeaturesProperties = _.cloneDeep(GEOJson.features).map(({ properties }) => properties);
    // Coordinates of scatter points on the map
    const geoCoordMap = {};
    const adcodeMap = {};
    areaFeaturesProperties.forEach(({ name, adcode, center }) => {
      // 处理后台接口返回字段名和JSON预设字段名不统一
      const areaNames = dimensionData.map(item => item.areaName);
      areaNames.forEach(areaName => {
        if (name.startsWith(areaName)) {
          geoCoordMap[areaName] = center;
          adcodeMap[areaName] = adcode;
        }
      });
    });
    // Map coordinates to values
    const convertData = data => {
      const res = [];
      data.forEach(({ name, value, displayValue, seriesName }) => {
        const geoCoord = geoCoordMap[name];
        const adcode = adcodeMap[name];
        if (geoCoord) {
          res.push({
            name,
            value: geoCoord.concat(+value),
            displayValue,
            seriesName,
            adcode
          });
        }
      });
      return res;
    };
    options.series[0].data = convertData(
      dimensionData.map(({ areaName, measureValue }) => {
        const seriesName = Object.keys(measureValue)[0];
        const { originalValue, displayValue } = measureValue[seriesName];
        const { aliasName } = extraConfigFields.find(field => field.caption === seriesName);
        return {
          name: areaName,
          value: originalValue,
          displayValue,
          seriesName: extraConfigFieldCaptions.includes(seriesName) ? aliasName : seriesName
        };
      })
    );
    // symbolSize
    const bubbleArray = (Object.values(measureData)[0] as Array<{ originalValue: any; displayValue: any }>).map(
      val => +val.originalValue
    );
    options.geo.map = mapName;
    options.series[0].symbolSize = value => funcSize(value[2], bubbleArray);
    series = options.series;
  } else if (
    [
      'line',
      'area',
      'bar',
      'bar-stack',
      'bar-stack-percent',
      'bar-horizontal',
      'strip-stack',
      'strip-stack-percent'
    ].includes(chartType)
  ) {
    options.grid.right = xName || xUnit ? 64 : 18;
    options.grid.top = yName || yUnit ? 64 : 36;
    options.xAxis.name = styleConfig.xName
      ? styleConfig.xUnit
        ? `${styleConfig.xName}(${styleConfig.xUnit})`
        : styleConfig.xName
      : '';
    options.yAxis.name = styleConfig.yName
      ? styleConfig.yUnit
        ? `${styleConfig.yName}(${styleConfig.yUnit})`
        : styleConfig.yName
      : '';
    let chartsColors = [];
    if (styleConfig.color && _.isArray(styleConfig.color) && styleConfig.color.length) {
      chartsColors = styleConfig.color.map((item: any) => colors[item.colorIndex]);
    } else {
      chartsColors = colors;
    }
    options.color = chartsColors;
    options[options.xAxis.type === 'category' ? 'xAxis' : 'yAxis'].data = dimensionData.merge_dimension.map(
      item => item.displayValue || '-'
    );
    series = series
      .map((item, index) => ({ ...options.series[0], ...item }))
      .map(item => ({
        ...item,
        data: item.data.map(({ value, displayValue }, index) => ({
          name: dimensionData.merge_dimension[index].displayValue,
          value,
          displayValue
        }))
      }));
    if (highConfig && highConfig.proximityAnalysisStatus) {
      options.yAxis = {
        ...options.yAxis,
        min: value => value.min
      };
    } else {
      // options.yAxis.min = 0;
      options[options.xAxis.type === 'category' ? 'yAxis' : 'xAxis'].min = series.some(item =>
        item.data.some(itemData => itemData.value < 0)
      )
        ? Math.min(...series.map(item => item.data.map(itemData => +itemData.value)).flat(Infinity))
        : 0;
      if (
        chartType.includes('percent') &&
        series.some(itemSeries => isArrayIncludeNegativeItem(itemSeries.data.map(itemData => itemData.value)))
      ) {
        options[options.xAxis.type === 'category' ? 'yAxis' : 'xAxis'].min = -1;
      }
    }
    if (chartType.includes('stack') && !chartType.includes('percent')) {
      series = series.map(item => ({
        ...item,
        stack: legend[0]
      }));
    } else if (chartType.includes('percent')) {
      const categoryAxisData = options[options.xAxis.type === 'category' ? 'xAxis' : 'yAxis'].data;
      const totalMap = categoryAxisData.reduce((map, key) => {
        map[key] = series
          .map(item => item.data)
          .flat(Infinity)
          .filter(item => item.name === key)
          .reduce((total, cur) => total + Math.abs(Number(cur.value)), 0);
        return map;
      }, {});
      series = series.map(item => ({
        ...item,
        data: item.data.map(({ name, value, displayValue }) => ({
          name,
          value: parseFloat((value / totalMap[name]).toFixed(10)),
          displayValue
        })),
        stack: legend[0]
      }));
    }
  } else if (chartType === 'funnel') {
    // legend
    Object.keys(measureData).forEach(item => {
      legend.push(item);
    });
    // series
    const seriesData = [];
    for (let [key, value] of Object.entries(measureData)) {
      if (Array.isArray(value) && value.length) {
        value.forEach((item, index) => {
          seriesData.push({
            name: key,
            value: item.originalValue,
            displayValue: item.displayValue
          });
        });
      }
    }
    options.legend.data = legend;
    options.series[0].data = seriesData;
    // series label percent
    const seriesDataValues = seriesData.map(item => item.value).map(val => +val);
    options.series[0].label.formatter = ({ dataIndex, value }) => {
      return `${dataIndex === 0 ? 100 : ((value / seriesDataValues[dataIndex - 1]) * 100).toFixed(2)}%`;
    };
    // series max
    options.series[0].max = Math.max(...seriesDataValues);
    series = options.series;
  } else if (chartType === 'scatter') {
    // xAxis category
    options.xAxis.data = dimensionData.merge_dimension.map(item => item.displayValue);
    // symbolSize
    const bubbleArray = (Object.values(measureData)[0] as Array<{ originalValue: string; displayValue: string }>).map(
      val => +val.originalValue
    );
    options.series[0].symbolSize = value => funcSize(value, bubbleArray);
    series = series.map((item, index) => ({
      ...options.series[index],
      ...item,
      data: item.data.map((d, idx) => ({
        name: options.xAxis.data[idx],
        value: d.value,
        displayValue: d.displayValue
      }))
    }));
  } else if (chartType === 'combination') {
    const { yaxis, yaxisExt, extraConfigFieldMap } = chartMixDataConfig;
    const yAxisKeys = Object.keys(measureData);
    const xAxisKeys = Object.keys(dimensionData);
    const xAxisKeyLen = xAxisKeys.length;
    const yAxisKeyLen = yAxisKeys.length;
    const yaxisFields = [...(yaxis ? yaxis : []), ...(yaxisExt ? yaxisExt : [])];
    const meaFieldChartTypeMap = {};
    yaxisFields.forEach(field => {
      const extraConfigFields: Array<any> = Object.values(extraConfigFieldMap).flat(Infinity);
      const { aliasName } = extraConfigFields.find(item => item.vid === calculateFieldVid(field));
      // meaFieldChartTypeMap[measureFieldCaptionExcludeCalculatorType(field)] = field.chartType;
      meaFieldChartTypeMap[aliasName] = field.chartType;
    });

    if (xAxisKeyLen > 1) {
      const xAxisExtKey = xAxisKeys.filter(key => key !== 'merge_dimension')[0];
      const xAxisExtData = dimensionData[xAxisExtKey];
      if (Array.isArray(yaxis) && !!yaxis.length && Array.isArray(yaxisExt) && !!yaxisExt.length) {
        // 主副轴值都存在，动态计算图例值
        for (let i = 0; i < yAxisKeys.length; i++) {
          for (let j = 0; j < xAxisExtData.length; j++) {
            const key = yAxisKeys[i];
            const legendName = `${key}-${xAxisExtData[j].displayValue}`;
            const { originalValue, displayValue } = measureData[key][j];
            const appendedItemData = {
              value: originalValue,
              displayValue
            };
            legend.push({
              name: legendName,
              icon: meaFieldChartTypeMap[key] === 'bar' ? 'rect' : 'circle'
            });
            if (series.some(item => item.name === legendName)) {
              series = series.map(item => {
                if (item.name === legendName) {
                  return {
                    ...item,
                    data: [...item.data, appendedItemData]
                  };
                }
                return item;
              });
            } else {
              series.push({
                name: legendName,
                data: [appendedItemData],
                type: meaFieldChartTypeMap[key]
              });
            }
          }
        }
      } else if ((Array.isArray(yaxis) && !!yaxis.length) || (Array.isArray(yaxisExt) && !!yaxisExt.length)) {
        for (let i = 0; i < xAxisExtData.length; i++) {
          const name = xAxisExtData[i];
          const key = yAxisKeys[0];
          const newItemData = measureData[key][i];
          legend.push({
            name,
            icon: meaFieldChartTypeMap[key] === 'bar' ? 'rect' : 'circle'
          });
          if (series.some(item => item.name === name)) {
            series = series.map(item => {
              if (item.name === name) {
                return {
                  ...item,
                  data: [...item.data, newItemData]
                };
              }
              return item;
            });
          } else {
            series.push({
              name,
              data: [newItemData],
              type: meaFieldChartTypeMap[key]
            });
          }
        }
      }
    } else {
      yAxisKeys.forEach(item => {
        legend.push({
          name: item,
          icon: meaFieldChartTypeMap[item] === 'bar' ? 'rect' : 'circle'
        });
      });
      legend.forEach(item => {
        const newItem: any = {
          name: item.name,
          data: measureData[item.name].map(({ originalValue, displayValue }) => ({
            name: item.name,
            value: originalValue,
            displayValue
          })),
          type: meaFieldChartTypeMap[item.name]
        };
        series.push(newItem);
      });
    }
    const xAxisData = [...new Set(dimensionData['merge_dimension'].map(item => item.displayValue))];
    options.xAxis.data = xAxisData;
    if (yAxisKeyLen === 1) {
      options.yAxis.splice(1, 1);
    }
    options.yAxis = yAxisKeys.map(key => {
      const data = measureData[key].map(itemData => Number(itemData.originalValue));
      const min = Math.min(...data);
      const max = Math.max(...data);
      return {
        name: key,
        type: 'value',
        min: min > 0 ? 0 : min,
        max,
        interval: parseFloat(((max - (min > 0 ? 0 : min)) / 4).toFixed(1)),
        axisLabel: {
          formatter: value => formatEchartsValue(value)
        }
      };
    });
    const groupedSeries = groupSeriesByChartType(series);
    const convertedSeries = [];
    const seriesChartTypes = Object.keys(groupedSeries);
    if (seriesChartTypes.length > 1) {
      seriesChartTypes.forEach((chartType, index) => {
        if (index > 0) {
          convertedSeries.push(...groupedSeries[chartType].map(item => ({ ...item, yAxisIndex: 1 })));
        } else {
          convertedSeries.push(...groupedSeries[chartType]);
        }
      });
      series = convertedSeries;
    }
    legend = [...new Set(legend)];
  }
  options.legend = { ...options.legend, data: legend };
  options.series = series;
  return options;
};

const convertCompareTableDataHeader = (data, dataSource) => {
  return removeTreeNodeEmptyChildren(listToTree(treeTraverse(data)));
};

/**
 * 树形结构遍历
 * @param data
 * @param pId
 * @param ret
 * @returns
 */
const treeTraverse = (data, pId = '-1', ret = []) => {
  if (Array.isArray(data) && data.length) {
    data.forEach(({ key, children, ...restProps }) => {
      ret.push(
        Object.assign(
          {},
          {
            ...restProps,
            key,
            id: key,
            pId
          },
          !children
            ? {
              width: 140,
              ellipsis: true
            }
            : {}
        )
      );
      children && treeTraverse(children, key, ret);
    });
    return ret;
  }
};
/**
 * 列表转树
 * @param list
 * @returns
 */
const listToTree = list => {
  let map = {},
    node,
    roots = [],
    i;
  for (i = 0; i < list.length; i++) {
    map[list[i].id] = i;
    list[i].children = [];
  }
  for (i = 0; i < list.length; i++) {
    node = list[i];
    if (node.pId !== '-1') {
      list[map[node.pId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};
/**
 * 移除树形节点空children字段
 * @param data
 * @returns
 */
const removeTreeNodeEmptyChildren = data => {
  data.forEach(node => {
    if (!node.children.length) {
      delete node.children;
    } else {
      removeTreeNodeEmptyChildren(node.children);
    }
  });
  return data;
};

export { getChartConfig, chartUtils, convertCompareTableDataHeader };
