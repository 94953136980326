import React, { useState, useRef, useContext } from 'react';
import cls from 'classnames';
import { Tooltip } from 'antd';
import SvgIcon from '@/components/SvgIcon';
import useClickOutside from '@/hooks/useClickOutside';
import FilterGroup from './widget-filter/filter-group';
import { IComponentGroupItem, IGroupItem } from './data';
import './style/components-panel.less';

export const ALL_CHARTS: IComponentGroupItem[] = [
  {
    label: '指标',
    value: 'indicator',
    groupItems: [
      { label: 'kpi指标卡', value: 'kpi', icon: 'kpi-text', isRecommend: true, sortedIndex: 2 },
      { label: '仪表盘', value: 'gauge', icon: 'gauge' }
    ]
  },
  {
    label: '表格',
    value: 'table',
    groupItems: [
      { label: '交叉表', value: 'table', icon: 'table-normal', isRecommend: true, sortedIndex: 1 },
      { label: '对比交叉表', value: 'tableMultidimensional', icon: 'table-info' }
    ]
  },
  {
    label: '趋势',
    value: 'trend',
    groupItems: [
      { label: '折线图', value: 'line', icon: 'line', isRecommend: true, sortedIndex: 3 },
      { label: '面积图', value: 'area', icon: 'area' },
      { label: '组合图', value: 'combination', icon: 'combination', isRecommend: true, sortedIndex: 5 }
    ]
  },
  {
    label: '比较',
    value: 'compare',
    groupItems: [
      { label: '柱状图', value: 'bar', icon: 'bar', isRecommend: true, sortedIndex: 4 },
      { label: '堆积柱状图', value: 'bar-stack', icon: 'bar-stack' },
      { label: '百分比堆积柱状图', value: 'bar-stack-percent', icon: 'bar-stack-percent' },
      { label: '条形图', value: 'bar-horizontal', icon: 'bar-horizontal' },
      { label: '堆积条形图', value: 'strip-stack', icon: 'strip-stack' },
      { label: '百分比堆积条形图', value: 'strip-stack-percent', icon: 'strip-stack-percent' }
    ]
  },
  {
    label: '分布',
    value: 'distribution',
    groupItems: [
      { label: '饼图', value: 'pie', icon: 'pie', isRecommend: true, sortedIndex: 6 },
      { label: '雷达图', value: 'radar', icon: 'radar' },
      { label: '玫瑰图', value: 'pie-rose', icon: 'pie-rose' },
      { label: '矩形树图', value: 'treemap', icon: 'treemap' }
    ]
  },
  {
    label: '关系',
    value: 'relation',
    groupItems: [
      { label: '漏斗图', value: 'funnel', icon: 'funnel' },
      { label: '散点图', value: 'scatter', icon: 'scatter' }
    ]
  },
  {
    label: '空间',
    value: 'space',
    groupItems: [
      { label: '色彩地图', value: 'map', icon: 'map', isRecommend: true, sortedIndex: 7 },
      { label: '气泡地图', value: 'bubble-map', icon: 'bubble-map' }
    ]
  }
];
const flatGroupItems = ALL_CHARTS.map(chart => chart.groupItems).flat(Infinity) as IGroupItem[];
export const RECOMMENDED_CHARTS: IGroupItem[] = flatGroupItems
  .filter((groupItem: IGroupItem) => groupItem.isRecommend)
  .sort((a, b) => a.sortedIndex - b.sortedIndex);

function ComponentsItem({
  item,
  isGroupItem,
  onSelect
}: {
  item: IGroupItem;
  isGroupItem?: boolean;
  onSelect: Function;
}) {
  const CompItemIcon = () => (
    <Tooltip title={item.label} placement="top" overlayClassName="theme-light">
      <div className="components-panel-icon-container">
        <div className={cls(['component-type-icon', `component-type-${item.value}`])}></div>
      </div>
    </Tooltip>
  );

  const handleItemCompClick = itemComp => {
    onSelect(itemComp);
  };
  return (
    <div className="components-item" onClick={() => handleItemCompClick(item)}>
      {isGroupItem ? (
        <div className="components-group-item">
          <CompItemIcon />
        </div>
      ) : (
        <CompItemIcon />
      )}
    </div>
  );
}

function ComponentsChartsAllGroup({ itemGroup, onItemCompSelect }) {
  const styleSeparate = {
    display: 'none'
  };
  return (
    <div className="components-charts-all-group">
      <i
        className="components-charts-all-separate"
        style={['indicator'].includes(itemGroup.value) ? styleSeparate : {}}
      ></i>
      <div className="components-charts-all-content">
        <div className="components-charts-all-title">{itemGroup.label}</div>
        <div className="components-charts-all-items">
          {itemGroup.groupItems.map(item => (
            <ComponentsItem key={item.value} item={item} onSelect={onItemCompSelect} />
          ))}
        </div>
      </div>
    </div>
  );
}

function ComponentsPanel({ onCompSelect, onCustomCompSelect }) {
  const [toggleAllCharts, setToggleAllCharts] = useState(false);
  const [toggleFilterWidgets, setToggleFilterWidgets] = useState(false);
  const componentsChartsAllPanelRef = useRef(null);
  const filterWidgetPanelRef = useRef(null);
  useClickOutside(componentsChartsAllPanelRef, () => {
    if (toggleAllCharts) setToggleAllCharts(false);
  });
  useClickOutside(filterWidgetPanelRef, () => {
    if (toggleFilterWidgets) setToggleFilterWidgets(false);
  });

  const handleItemCompSelect = itemComp => {
    setToggleAllCharts(false);
    onCompSelect(itemComp);
  };

  const handleItemWidgetSelect = itemWidget => {
    onCustomCompSelect(itemWidget);
    setToggleFilterWidgets(false);
  };

  // console.log('render ComponentsPanel: ', toggleAllCharts);
  return (
    <div className="components-panel-container">
      <div className="components-panel">
        <div className="guid-tip-container bottom-right auto-width" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="components-charts">
            {RECOMMENDED_CHARTS.map(chart => (
              <ComponentsItem key={chart.value} item={chart} isGroupItem onSelect={handleItemCompSelect} />
            ))}
          </div>
        </div>
        <div className="components-charts-all">
          <div className="components-item" onClick={() => setToggleAllCharts(!toggleAllCharts)}>
            <div className={cls('components-group-item', { expand: toggleAllCharts })}>
              <Tooltip title="所有图表类型" placement="top" overlayClassName="theme-light">
                <i className="components-charts-all-icon svg-icon-wrapper icon-all">
                  <SvgIcon iconClass="chart-more" />
                </i>
              </Tooltip>
            </div>
          </div>
          {toggleAllCharts && (
            <div className="components-charts-all-panel" ref={componentsChartsAllPanelRef}>
              {ALL_CHARTS.map(group => (
                <ComponentsChartsAllGroup key={group.label} itemGroup={group} onItemCompSelect={handleItemCompSelect} />
              ))}
              <div className="component-panel-dropdown-desc">
                <i className="info-circle svg-icon-wrapper">
                  <SvgIcon iconClass="info-circle" />
                </i>
                <span className="text">单击添加图表至选中组件下方。</span>
              </div>
            </div>
          )}
        </div>
        <div className="separate"></div>
        <div className="add-query-btn" onClick={() => setToggleFilterWidgets(!toggleFilterWidgets)}>
          <i className="svg-icon-wrapper">
            <SvgIcon iconClass="btn-query" />
          </i>
          添加查询组件
        </div>
        {toggleFilterWidgets && (
          <div className="filter-widgets-panel" ref={filterWidgetPanelRef}>
            <FilterGroup onSelect={handleItemWidgetSelect} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ComponentsPanel;
