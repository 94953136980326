import { makeAutoObservable } from "mobx"
import { uaaApp } from "@/lib";

class UdMainStore implements IUdMainStore {
  constructor() {
    makeAutoObservable(this)
  }
  public contentLoading: boolean = false;
  public collapse: boolean = false;

  public brandList: any[] = [];
  public initialBrandValue: string | undefined = undefined;
  public activeApp = null;
  public isShowFrame = true; // 是否展示头部和侧边栏（详情页不显示）
  /**更新加载状态*/
  public updateContentLoading = (status) => {
    this.contentLoading = status;
  };
  /**更新菜单展开状态*/
  public updateCollapse = (status) => {
    this.collapse = status;
  };

  /** 更新登录人品牌公司 */
  public updateBrandList = (brandList) => {
    this.brandList = brandList
  }

  public logOut = () => {
    uaaApp.signOut()
  }

  public setActiveApp = payload => {
      this.activeApp = payload;
  }
  public setIsShowFrame = payload => {
      this.isShowFrame = payload;
  }
}

export interface IUdMainStore {
  contentLoading: boolean
  collapse: boolean
  brandList: any[]
  initialBrandValue: string | undefined
  isShowFrame: boolean
  activeApp: any
  updateContentLoading: (status) => void
  updateCollapse: (status) => void
  logOut: () => void
  updateBrandList: (list: any[]) => void
  setActiveApp: (app: any) => void
  setIsShowFrame: (status: boolean) => void
}

const udMainStore: IUdMainStore = new UdMainStore();

export default udMainStore;