import stores from "@/store";
import { Empty } from "antd";
import React, { FC } from "react";


interface ILicenseInvalidProps {
}

const LicenseInvalid: FC<ILicenseInvalidProps> = (props) => {
    const {state} = stores.licenseStore;
    return (
        <Empty
            description={
                <span>
                    <p>{state?.licenseValidMsg ? state?.licenseValidMsg : '获取License信息异常'}</p>
                    <p>请联系矩子数智</p>
                </span>
            }
        >
        </Empty>
    );
};

export default LicenseInvalid;