import React, { FC, useState, useEffect } from 'react';
import { useMitt } from 'react-mitt';
import NoticeIcon from './NoticeIcon';
import APIS from '@/apis/api';
import { http } from '@/lib';
import { uuid } from '@/utils/utils';
import ExportTaskCtx from './ctx';

interface IItemTask {
  exportCode?: string;
  ftpFileName: string;
  filename: string;
  status: 'FAIL' | 'WAITING' | 'GENERATED' | 'SUCCESS';
  id?: string;
}

const ExportTask: FC = props => {
  const { emitter } = useMitt();
  const [count, setCount] = useState<number>(0);
  const [taskList, setTaskList] = useState<IItemTask[]>([]);
  let unmounted = false;
  useEffect(() => {
    fetchExportTaskList();
    // 监听任务列表刷新事件
    emitter.on('refreshTaskList', handleRefresh);
    return () => {
      emitter.off('refreshTaskList', handleRefresh);
      unmounted = true;
    };
  }, []);
  /**
   * 刷新任务列表
   */
  const handleRefresh = () => {
    fetchExportTaskList();
  };
  /**
   * 获取导出任务列表
   */
  const fetchExportTaskList = async () => {
    const res = await http.post(APIS.GetExportTaskList);
    if (!unmounted) {
      setTaskList(res.data.map(itemTask => ({ ...itemTask, id: uuid() })));
      setCount(res.data.length);
    }
  };
  /**
   * 删除任务
   * @param itemDel 待删除项
   */
  const handleDeleteTask = itemDel => {
    setTaskList(taskList.filter(itemTask => itemTask.id !== itemDel.id));
    setCount(taskList.length - 1);
  };
  return (
    <ExportTaskCtx.Provider value={{ handleDeleteTask }}>
      <div className="export-task">
        <NoticeIcon count={count} list={taskList} loading={false} />
      </div>
    </ExportTaskCtx.Provider>
  );
};

export default ExportTask;
