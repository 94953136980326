import APIS from '@/apis/api';
import { http } from '@/lib';
import { ILicenseInfo } from '@/model/common/commons';
import { action, makeObservable, observable } from 'mobx';

export class LicenseStore {
  state: ILicense = {
    licenseValid: true,
    licenseValidMsg: '',
    licenseInfo: null
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      setState: action
    });
  }

  setState(state: ILicense) {
    this.state = state;
  }

  updateLicense(valid: boolean, msg: string) {
    this.state = {
      ...this.state,
      licenseValid: valid,
      licenseValidMsg: msg
    };
  }

  setLicenseInfo(license: ILicenseInfo) {
    this.state = {
      ...this.state,
      licenseInfo: license
    };
  }

  public init() {
    http
      .get<ILicenseInfo>(APIS.LicenseInfo)
      .then(res => {
        // this.setLicenseInfo(res.data);
        // this.updateLicense(true, '');
        this.setState({
          ...this.state,
          licenseInfo: res.data,
          licenseValid: true,
          licenseValidMsg: ''
        });
      })
      .catch(error => {
        this.updateLicense(false, error.data?.msg);
      });
  }
}

export interface ILicense {
  licenseInfo: ILicenseInfo;
  licenseValid: boolean;
  licenseValidMsg: string;
}

export default new LicenseStore();
