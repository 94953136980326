import React, { useState, useEffect, useMemo } from 'react';
import { Select, Checkbox } from 'antd';
import useLocale from '@/utils/useLocale';
import locale from '../locale';
import { http } from '@/lib';
import APIS from '@/apis/api';
import { IProps } from './data';

const { Option } = Select;

function IbSelect(props: IProps) {
  const { widget, inDraw, defaultValue, onDataOptsChange, onSelectedValChange } = props;
  const t = useLocale(locale);
  const [value, setValue] = useState(undefined);
  const options = useMemo(() => {
    return widget.options.attrs.dataOpts;
  }, [JSON.stringify(widget.options.attrs.dataOpts)]);
  const isMultiple = useMemo(() => {
    return widget.options.attrs.multiple;
  }, [widget.options.attrs.multiple]);
  const checkAll = useMemo(() => {
    if (!value || !value.length) return false;
    return value.length === options.length;
  }, [JSON.stringify(value)]);
  const indeterminate = useMemo(() => {
    if (!value || !value.length) return false;
    return value.length !== options.length;
  }, [JSON.stringify(value)]);
  const placeholderSuffix = useMemo(() => {
    const i18nValue = isMultiple ? t['panel.multipleChoice'] : t['panel.singleChoice'];
    return `(${i18nValue})`;
  }, [isMultiple]);

  useEffect(() => {
    setValue(!defaultValue || !defaultValue.split(',').length ? undefined : defaultValue.split(','));
  }, [defaultValue]);

  useEffect(() => {
    if (inDraw || !widget.options.attrs.fieldUniqueId) return;

    fetchData();
  }, [widget.options.attrs.fieldUniqueId]);

  const fetchData = () => {
    http
      .post(APIS.GetDataSetDataByFields, {
        id: widget.options.attrs.dataSetId,
        queryFields: {
          fieldUniqueIds: widget.options.attrs.fieldUniqueId,
          groupDuplicate: true
        },
        page: 1,
        size: 10000
      })
      .then(res => {
        const { content } = res.data;
        onDataOptsChange(
          content
            .map(item => Object.values(item))
            .flat(Infinity)
            .map(item => item.toString())
        );
      });
  };

  const handleChange = val => {
    setValue(isMultiple ? val : [val]);
    onSelectedValChange && onSelectedValChange(isMultiple ? val : [val]);
  };

  const toggleAll = ev => {
    const newValue = ev.target.checked ? options : undefined;
    setValue(newValue);
    onSelectedValChange && onSelectedValChange(newValue);
  };

  // console.log('render IbSelect: ', props, checkAll, value);
  return (
    <div className="ib-select-wrapper">
      <Select
        showSearch
        mode={isMultiple ? 'multiple' : 'default'}
        maxTagCount={isMultiple ? 1 : 0}
        maxTagPlaceholder={omittedValues => `+${omittedValues.length}`}
        defaultValue={isMultiple ? value : !value ? undefined : value[0]}
        value={isMultiple ? value : !value ? undefined : value[0]}
        onChange={handleChange}
        placeholder={t[widget.options.attrs.placeholder] + placeholderSuffix}
        optionFilterProp="children"
        filterOption={(input, option: any) => option.props.children.toLowerCase().includes(input.toLowerCase())}
        dropdownRender={menu => (
          <>
            {widget.options.attrs.multiple && (
              <p
                className="select-all"
                onMouseDown={ev => ev.preventDefault()}
                style={{ margin: 0, padding: '5px 12px' }}
              >
                <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={toggleAll}>
                  {t['dataset.checkAll']}
                </Checkbox>
              </p>
            )}
            {menu}
          </>
        )}
      >
        {options.map((opt, index) => (
          <Option key={`${opt}-${index}`} value={opt}>
            {opt}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default IbSelect;
