import _, { values } from 'lodash';
import axios, { AxiosRequestConfig } from 'axios';
import { udConfigProvider } from '..';
import qs from 'qs';
import stores from '@/store';

const httpFactory = {
  create: (config?: AxiosRequestConfig) => {
    const http = axios.create(config);

    http.interceptors.request.use(
      (value: any) => {
        value = _.extend({}, udConfigProvider.http, value);
        // console.log(value);

        if (value.method == 'post' && value.url.includes('/admin/')) {
          value.data = qs.stringify(value.data);
        }

        if (value.url.indexOf('http') == 0) {
          value.baseURL = '';
        }
        value = value.requestBeforeSetToken(value);
        if (value.useRpcWrap) {
          value.headers['x-rpc-wrap'] = '1';
        }
        value = value.requestBefore(value);
        return value;
      },
      (error) => {
        let func = _.get(error, 'config.errorHandler.request', udConfigProvider.http.errorHandler.request);
        func(error);
        return Promise.reject(error);
      }
    );

    http.interceptors.response.use(
      (res: any) => {

        if (res.status === 200) {
          if (res.config && res.config.responseType === 'arraybuffer') {
            try {
              const data = arraybufferToObject(res.data);
              res.data = data;
            } catch (error) {
              return Promise.resolve(res);
            }
          }
          if (res.config.useRpcWrap) {
            if (res.data.code === 200 || res.data.message == 'success') {
              res.originalData = res.data;
              res.data = res.data.data;
              return Promise.resolve(res);
            } else {
              const error = res.data.error
              if (error) {
                if (_.isFunction(res.config.bizErrorHandler[(error as IException).identifier])) {
                  res.config.bizErrorHandler[(error as IException).identifier](error);
                } else {
                  res.config.bizErrorHandler.global(error);
                }
              } else {
                res.config.errorTip({ description: res.data.msg, traceId: res.data?.traceId });
              }

              /* if (res.data.code >= 54200 && res.data.code <= 54205) {
                stores.licenseStore.updateLicense(
                  false,
                  res.data.msg
                );
              } else {
                if (_.isFunction(res.config.errorHandler[res.data.code])) {
                  res.config.errorHandler[res.data.code](res);
                } else {
                  if (res.config.useBizErrorHandler) {
                    res.config.errorTip({ description: res.data.msg });
                  }
                }
              } */

              return Promise.reject(res);
            }
          }
          return Promise.resolve(res);
        } else {
          res.config.errorTip({ description: res.statusText, traceId: res.data?.traceId });
        }
        return Promise.reject(res);
      },
      (error) => {
        console.log(error, error.response);
        if (error.config && error.config.responseType === 'arraybuffer') {
          try {
            const data = arraybufferToObject(error.response.data);
            error.response.data = data;
          } catch (error) {
            return Promise.reject(error);
          }
        }
        if (error.config && error.config.useSysErrorHandler) {
          let handler = error.config.errorHandler.other;
          if (error.response) {
            if (_.isFunction(error.config.errorHandler[error.response.status])) {
              handler = error.config.errorHandler[error.response.status];
            }
          }
          handler(error);
        }
        return Promise.reject(error);
      }
    );

    return http;
  },
};

/** ajax 请求对象 */
const http = httpFactory.create();

export { http, httpFactory };

export function arraybufferToObject(arraybuffer) {
  let uint8: any = new Uint8Array(arraybuffer); // 提取uint8Array
  let fromCharCode = String.fromCharCode(...uint8);
  let resToString = decodeURIComponent(escape(fromCharCode));
  // 不是文件流数据，将转换好的数据重新覆盖
  return JSON.parse(resToString);
}
