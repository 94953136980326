import _ from 'lodash';
import {
  API_CHART_TYPE,
  PROXIMITY_ANALYSIS_CHARTS,
  DRILL_DOWN_CHARTS,
  AUXILIARY_LINE_CHARTS,
  AXIS_STYLE_CHARTS
} from '@/utils/enum';
import { hexToRGBA, isHexColor, formatNumber1, toThousands } from '@/utils/utils';

const genChartDataBaseConfig = (chartType?: string) => {
  const baseConfig: any = {
    dataSetId: undefined,
    dataSetFields: [],
    measureFields: undefined,
    filterFields: undefined,
    filters: [],
    filterHashMap: {},
    isFetchingData: false,
    status: 'intoCanvas',
    chartOptions: null,
    dataMaxLengthLimit: 1000,
    warningConfig: null,
    extraConfigFields: [], // 字段额外配置(注意: 组合图度量值区分主轴值、副轴值,需单独处理)
    freshTime: {
      isFreshEnable: false,
      freshTimeNumber: 5,
      freshTimeUnit: 'min'
    }
  };
  return baseConfig;
};

export const replaceFieldUniqueId = fieldUniqueId => fieldUniqueId.replace('-clone', '');

export const formatCompareFieldDate = compareField => {
  const regExp = /-/g;
  const newCompareField = _.cloneDeep(compareField);
  if (newCompareField.valueCategory === 'STATIC_MULTIVALUED') {
    newCompareField.staticValues = newCompareField.staticValues.map(val => val.replace(regExp, ''));
  } else if (newCompareField.valueCategory === 'DYNAMIC_MULTIVALUED') {
    newCompareField.dynamicValue.baseValue = newCompareField.dynamicValue.baseValue.replace(regExp, '');
  } else {
    newCompareField.rangeValues = newCompareField.rangeValues.map(val => ({
      start: val.start.replace(regExp, ''),
      end: val.end.replace(regExp, '')
    }));
  }
  return newCompareField;
};

export const getDataConfig = chartType => {
  switch (chartType) {
    case 'line':
    case 'area':
    case 'bar':
    case 'bar-horizontal':
    case 'pie':
    case 'pie-rose':
    case 'scatter':
    case 'map':
    case 'bubble-map':
    case 'table':
      return {
        ...genChartDataBaseConfig(chartType),
        dimensionFields: undefined,
        drillDownFields: undefined,
        index: 0,
        pointParam: null,
        isActionDrill: false,
        pointValues: []
      };
    case 'combination': {
      const chartCombinationConfig = {
        ...genChartDataBaseConfig(),
        xaxis: undefined,
        yaxis: undefined,
        yaxisExt: undefined,
        legend: undefined,
        extraConfigFieldMap: {
          yaxis: [],
          yaxisExt: []
        }
      };
      delete chartCombinationConfig.measureFields;
      delete chartCombinationConfig.extraConfigFields;
      return chartCombinationConfig;
    }
    case 'treemap':
    case 'bar-stack':
    case 'bar-stack-percent':
    case 'strip-stack':
    case 'strip-stack-percent':
      return {
        ...genChartDataBaseConfig(),
        dimensionFields: undefined
      };
    case 'radar':
      return {
        ...genChartDataBaseConfig(),
        dimensionFields: undefined,
        branchTag: 'dimension',
        dimensionData: null,
        measureData: null,
        drillDownFields: undefined,
        index: 0,
        pointParam: null,
        isActionDrill: false,
        pointValues: []
      };
    case 'kpi':
    case 'gauge':
    case 'funnel':
      return genChartDataBaseConfig();
    case 'tableMultidimensional':
      return {
        ...genChartDataBaseConfig(),
        dimensionFields: undefined,
        compareFields: undefined,
        compareField: null,
        drillDownFields: undefined,
        index: 0,
        pointParam: null,
        isActionDrill: false,
        pointValues: []
      };
  }
};

export const convertDataConfig = ({ chartType, dataConfig }) => {
  const {
    dataSetId,
    dimensionFields,
    measureFields,
    filters,
    compareField,
    isActionDrill,
    drillDownFields,
    index,
    pointParam,
    dataMaxLengthLimit,
    xaxis,
    yaxis,
    yaxisExt,
    legend,
    relationChartParam
  } = dataConfig;
  let tempDimFields = null;
  let tempMeaFields = null;
  if (chartType === 'combination') {
    if (isActionDrill) {
      tempDimFields = [drillDownFields[index]].map(field => ({
        ...field,
        uniqueId: replaceFieldUniqueId(field.uniqueId)
      }));
    } else {
      tempDimFields = [
        ...(xaxis ? xaxis.map(item => ({ ...item, fieldType: 'MAIN_DIMENSION' })) : []),
        ...(legend ? legend.map(item => ({ ...item, fieldType: 'ASSISTANT_DIMENSION' })) : [])
      ].map(field => ({
        ...field,
        uniqueId: replaceFieldUniqueId(field.uniqueId)
      }));
    }
    tempMeaFields = [
      ...(yaxis ? yaxis.map(item => ({ ...item, fieldType: 'MAIN_MEASURE' })) : []),
      ...(yaxisExt ? yaxisExt.map(item => ({ ...item, fieldType: 'ASSISTANT_MEASURE' })) : [])
    ].map(field => {
      return field.ratioCalculator
        ? {
            ...field,
            uniqueId: replaceFieldUniqueId(field.uniqueId),
            ratioCalculator: {
              ...field.ratioCalculator,
              dateField: replaceFieldUniqueId(field.ratioCalculator.dateField)
            }
          }
        : { ...field, uniqueId: replaceFieldUniqueId(field.uniqueId) };
    });
  } else {
    if (isActionDrill) {
      tempDimFields = [drillDownFields[index]].map(field => ({
        ...field,
        uniqueId: replaceFieldUniqueId(field.uniqueId)
      }));
    } else {
      tempDimFields = dimensionFields
        ? dimensionFields.map(field => ({ ...field, uniqueId: replaceFieldUniqueId(field.uniqueId) }))
        : [];
    }
    tempMeaFields = measureFields
      ? measureFields.map(field => {
          return field.ratioCalculator
            ? {
                ...field,
                uniqueId: replaceFieldUniqueId(field.uniqueId),
                ratioCalculator: {
                  ...field.ratioCalculator,
                  dateField: replaceFieldUniqueId(field.ratioCalculator.dateField)
                }
              }
            : { ...field, uniqueId: replaceFieldUniqueId(field.uniqueId) };
        })
      : [];
  }
  return {
    chartsType: API_CHART_TYPE[chartType],
    dataSetId,
    dimensionFields: tempDimFields,
    measureFields: tempMeaFields,
    filters: isActionDrill
      ? [
          ...filters.map(condition => ({
            ...condition,
            fieldUniqueId: replaceFieldUniqueId(condition.fieldUniqueId)
          })),
          {
            fieldUniqueId: replaceFieldUniqueId(drillDownFields[index - 1]['uniqueId']),
            operator: 'EQUALS',
            values: [pointParam.data.name],
            conditionJoin: 'AND'
          }
        ]
      : [
          ...(Array.isArray(relationChartParam) && relationChartParam.length
            ? [...relationChartParam, ...filters]
            : filters)
        ].map(condition => ({
          ...condition,
          fieldUniqueId: replaceFieldUniqueId(condition.fieldUniqueId)
        })),
    compareField: compareField
      ? {
          ...formatCompareFieldDate(compareField),
          fieldUniqueId: replaceFieldUniqueId(compareField.fieldUniqueId)
        }
      : null,
    dimensionMerge: !['table', 'map', 'bubble-map'].includes(chartType),
    size: dataMaxLengthLimit
  };
};

export const mergeOptions = itemComp => {
  const {
    chartIndex,
    chartType,
    dataConfig: { chartOptions, branchTag, dimensionData, measureData, drillDownFields, index },
    styleConfig,
    advancedConfig
  } = itemComp;
  const {
    colorCase: { colors },
    xAxis,
    yAxis,
    y2Axis
  } = styleConfig;
  const { proximityAnalysisStatus, auxiliaryLines } = advancedConfig;
  const newChartOptions = _.cloneDeep(chartOptions);
  newChartOptions.color = colors;
  if (chartType === 'radar') {
    const { zippedMin, radix } = getSeriesDataMinAndRadix(newChartOptions.series[0].data);
    const { color } = newChartOptions.radar.indicator[0];
    if (branchTag === 'dimension') {
      newChartOptions.legend.data = Object.keys(measureData);
      newChartOptions.radar.indicator = dimensionData.merge_dimension.map((item, index) => ({
        name: item.displayValue,
        min: proximityAnalysisStatus ? zippedMin[index] - radix[index] : 0,
        color
      }));
      newChartOptions.series[0].data = Object.keys(measureData).map(key => ({
        name: key,
        value: measureData[key].map(item => item.originalValue),
        displayValue: measureData[key].map(item => item.displayValue),
        indicator: newChartOptions.radar.indicator
      }));
    } else if (branchTag === 'measure') {
      newChartOptions.legend.data = dimensionData.merge_dimension.map(item => item.displayValue);
      newChartOptions.radar.indicator = Object.keys(measureData).map((item, index) => ({
        name: item,
        min: proximityAnalysisStatus ? zippedMin[index] - radix[index] : 0,
        color
      }));
      newChartOptions.series[0].data = dimensionData.merge_dimension.map((item, index) => ({
        name: item,
        value: Object.keys(measureData)
          .map(key => measureData[key][index])
          .map(item => item.originalValue),
        displayValue: Object.keys(measureData)
          .map(key => measureData[key][index])
          .map(item => item.displayValue),
        indicator: newChartOptions.radar.indicator
      }));
    }
  } else if (PROXIMITY_ANALYSIS_CHARTS.includes(chartType)) {
    const isXAxisCategory = newChartOptions.xAxis.type === 'category';
    newChartOptions[isXAxisCategory ? 'yAxis' : 'xAxis'].min = proximityAnalysisStatus
      ? val => val.min
      : newChartOptions[isXAxisCategory ? 'yAxis' : 'xAxis'].min;
  } else if (chartType === 'map') {
    const seriesDataValues = newChartOptions.series[0].data.map(item => (!item.value ? 0 : +item.value));
    const color = isHexColor(newChartOptions.color[0]) ? hexToRGBA(newChartOptions.color[0]) : newChartOptions.color[0];
    newChartOptions.visualMap = newChartOptions.series[0].data.length
      ? {
          min: Math.min(...seriesDataValues),
          max: Math.max(...seriesDataValues),
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          inRange: {
            color: new Array(5)
              .fill(null)
              .map((item, index) => color.replace(/[\d\.]+\)$/g, `${parseFloat(((index + 1) * 0.2).toFixed(10))})`))
          },
          formatter: val => toThousands(val)
        }
      : null;
    newChartOptions.geo.itemStyle.areaColor = 'transparent';
    newChartOptions.geo.itemStyle.borderColor = 'transparent';
    newChartOptions.series[0].data = newChartOptions.series[0].data.map(itemSeriesData => {
      if (itemSeriesData.value) {
        return {
          ...itemSeriesData,
          itemStyle: {
            areaColor: 'transparent',
            borderColor: 'transparent',
            borderWidth: 0
          }
        };
      }
      return {
        ...itemSeriesData,
        itemStyle: {
          areaColor: '#ebedf0',
          borderColor: '#fff'
        }
      };
    });
  } else if (chartType === 'gauge') {
    newChartOptions.series[0].itemStyle.color = newChartOptions.color[0];
  }

  if (AUXILIARY_LINE_CHARTS.includes(chartType)) {
    if (Array.isArray(auxiliaryLines) && auxiliaryLines.length) {
      newChartOptions.series = newChartOptions.series.map(itemSeries => {
        return {
          ...itemSeries,
          markLine: {
            symbol: 'none',
            data: auxiliaryLines.map(itemLine => {
              const { type, value, calculatedType, lineStyle, lineColor } = itemLine;
              const ret: any = {
                symbol: 'none',
                label: {
                  position: 'start',
                  padding: 6,
                  backgroundColor: lineColor,
                  color: '#fff',
                  formatter: params => {
                    const {
                      data: { value }
                    } = params;
                    return formatNumber1(value);
                  }
                },
                lineStyle: {
                  color: lineColor,
                  type: lineStyle
                }
              };
              if (type === 'calculated') {
                ret.name = calculatedType;
                ret.type = calculatedType;
              } else if (type === 'fixed') {
                ret[newChartOptions.xAxis.type === 'category' ? 'yAxis' : 'xAxis'] = value;
                ret.value = value;
              }
              return ret;
            })
          }
        };
      });
    }
  }

  if (DRILL_DOWN_CHARTS.includes(chartType)) {
    newChartOptions.series[0].data = newChartOptions.series[0].data.map(item => ({
      ...item,
      index,
      chartIndex
    }));
    if (chartType.includes('table')) {
      newChartOptions.dataSource = newChartOptions.dataSource.map(item => ({
        ...item,
        index,
        chartIndex
      }));
    }
  }

  if (AXIS_STYLE_CHARTS.includes(chartType)) {
    const NAME_GAP = 36;
    const OFFSET = 12;
    // x-axis options
    if (xAxis.showTitleAndUnit) {
      const name = `${xAxis.title}${xAxis.unit ? `(${xAxis.unit})` : ''}`;

      newChartOptions.xAxis = {
        ...newChartOptions.xAxis,
        name,
        nameGap: NAME_GAP,
        nameLocation: 'center',
        nameTextStyle: {
          fontSize: xAxis.titleFontStyle.fontSize,
          color: xAxis.titleFontStyle.fontColor,
          fontStyle: xAxis.titleFontStyle.italic ? 'italic' : 'normal',
          fontWeight: xAxis.titleFontStyle.bold ? 'bold' : 'normal'
        }
      };
      newChartOptions.grid = {
        ...newChartOptions.grid,
        bottom: name ? NAME_GAP : newChartOptions.grid.bottom
      };
    }
    // y-axis options
    if (yAxis.showTitleAndUnit) {
      const name = `${yAxis.title}${yAxis.unit ? `(${yAxis.unit})` : ''}`;
      const value = {
        name,
        nameGap: NAME_GAP + OFFSET,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: yAxis.titleFontStyle.fontSize,
          color: yAxis.titleFontStyle.fontColor,
          fontStyle: yAxis.titleFontStyle.italic ? 'italic' : 'normal',
          fontWeight: yAxis.titleFontStyle.bold ? 'bold' : 'normal'
        }
      };

      if (chartType === 'combination') {
        newChartOptions.yAxis[0] = {
          ...newChartOptions.yAxis[0],
          ...value
        };
      } else {
        newChartOptions.yAxis = {
          ...newChartOptions.yAxis,
          ...value
        };
      }
      newChartOptions.grid = {
        ...newChartOptions.grid,
        left: name ? NAME_GAP : newChartOptions.grid.left
      };
    }
    // y2-axis options
    if (chartType === 'combination' && y2Axis.showTitleAndUnit) {
      const name = `${y2Axis.title}${y2Axis.unit ? `(${y2Axis.unit})` : ''}`;

      newChartOptions.yAxis[1] = {
        ...newChartOptions.yAxis[1],
        name,
        nameGap: NAME_GAP + OFFSET,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: y2Axis.titleFontStyle.fontSize,
          color: y2Axis.titleFontStyle.fontColor,
          fontStyle: y2Axis.titleFontStyle.italic ? 'italic' : 'normal',
          fontWeight: y2Axis.titleFontStyle.bold ? 'bold' : 'normal'
        }
      };
      newChartOptions.grid = {
        ...newChartOptions.grid,
        right: name ? NAME_GAP : newChartOptions.grid.right
      };
    }
  }

  if (chartType.includes('pie') || chartType.includes('treemap')) {
    newChartOptions.series[0].label = {
      formatter: params => {
        const total = newChartOptions.series[0].data.map(item => +item.value).reduce((prev, curr) => prev + curr, 0);
        const {
          data: { name, value }
        } = params;
        const percent = (value / total) * 100;
        return `${name}-${percent.toFixed(2)}%`;
      }
    };
  }
  return newChartOptions;
};

/**
 * 计算系列数据最小值和基数
 */
export const getSeriesDataMinAndRadix = seriesData => {
  const zippedSeriesData = _.zip(...seriesData.map(item => item.value.map(val => (!val ? 0 : val))));
  const zippedMin = zippedSeriesData.map(item => Math.min(...item.map(val => +val)));
  const radix = zippedMin.map(item => {
    const strItem = item.toString();
    const [integer] = strItem.split('.');
    if (parseInt(integer) === 0) {
      return 0;
    }
    let integerLen = integer.length - 1;
    let ret = '1';
    while (integerLen) {
      ret += '0';
      integerLen--;
    }
    return +ret;
  });
  return {
    zippedMin,
    radix
  };
};

/**
 * 生成图表高亮样式(主要用于图表关联)
 * @returns
 */
export const genHighlightOption = () => {
  return {
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        opacity: 1
      },
      label: {
        fontWeight: 'bold',
        color: '#000',
        opacity: 1
      }
    },
    itemStyle: {
      opacity: 0.4
    }
  };
};

/**
 * 生成关联表字段映射表
 * @param relationChart
 */
export const genRelationChartMap = relationChart => {
  const result = {};
  Object.keys(relationChart).forEach(key => {
    const [chartId, passiveFieldUniqueId, fieldUniqueId, passiveChartId] = key.split('*-');

    if (result[chartId]) {
      result[chartId] = [...new Set([...result[chartId], fieldUniqueId])];
    } else {
      result[chartId] = [fieldUniqueId];
    }
  });
  return result;
};
