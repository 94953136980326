import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, message } from 'antd';

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 18 }
  }
};
function ModalSettingFieldAliasName({ visible, extraConfigField, onOk, onCancel }) {
  const [aliasName, setAliasName] = useState('');

  useEffect(() => {
    setAliasName(extraConfigField.aliasName);
  }, []);

  const handleValChange = ev => {
    setAliasName(ev.target.value);
  };

  const handleOk = () => {
    if (!aliasName) {
      message.error('字段名称不能为空!');
      return;
    }
    onOk(aliasName);
  };

  return (
    <Modal
      visible={visible}
      width={520}
      title="字段显示内容"
      okText="确认"
      cancelText="取消"
      onOk={handleOk}
      onCancel={onCancel}
    >
      <Form {...formItemLayout} colon={false} labelAlign="right">
        <Form.Item label="字段原名">
          <span>{extraConfigField.customName}</span>
        </Form.Item>
        <Form.Item label="显示名称">
          <Input placeholder="请输入字段别名" value={aliasName} onChange={handleValChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalSettingFieldAliasName;
