export const BASE_SCATTER = {
  title: {
    text: '',
    textStyle: {
      fontWeight: 'normal'
    }
  },
  grid: {
    top: 36,
    right: 18,
    bottom: 18,
    left: 18,
    containLabel: true
  },
  tooltip: {
    trigger: 'item',
    formatter: params => {
      const {
        name,
        seriesName,
        marker,
        data: { displayValue }
      } = params;
      return `${seriesName}<br/>${marker} ${name} <label style="margin-left: 32px;">${displayValue}</label>`;
    }
  },
  legend: {
    show: true,
    type: 'scroll',
    itemWidth: 10,
    itemHeight: 10,
    icon: 'rect',
    data: []
  },
  xAxis: {
    data: [],
    boundaryGap: false,
    axisTick: {
      show: false
    }
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      roam: false,
      data: []
    }
  ]
  /* dataZoom: [
    {
      type: 'slider',
      show: false,
      xAxisIndex: [0],
      start: 0,
      end: 100
    },
    {
      type: 'slider',
      show: false,
      yAxisIndex: [0],
      left: '93%',
      start: 0,
      end: 100
    },
    {
      type: 'inside',
      xAxisIndex: [0],
      start: 0,
      end: 100
    },
    {
      type: 'inside',
      yAxisIndex: [0],
      start: 0,
      end: 100
    }
  ] */
};
