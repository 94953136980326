import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import chinaJson from '@/assets/china.json';
import themeDarkJson from '../theme/dark.json';
import { Spin } from 'antd';
import { funcSize } from '@/pages/dataBoard/components/ChartType/util';
import KpiCard from '@/components/chart/components/normal/KpiCard';
import TableNormal from '@/components/chart/components/normal/TableNormal';
import Text from '@/components/canvas/custom-component/Text';
import CurTime from '@/components/canvas/custom-component/CurTime';
import Picture from '@/components/canvas/custom-component/Picture';
import Video from '@/components/canvas/custom-component/Video';
import DigitalFlopper from '@/components/canvas/custom-component/DigitalFlopper';
import RankingList from '@/components/canvas/custom-component/RankingList';
import Border from '@/components/canvas/decoration/Border';
import Line from '@/components/canvas/decoration/Line';
import { resolveHighConfig } from '@/components/chart/chart-option';

export const supportAnimateBorders = ['border2', 'border3', 'border4', 'border5'];
const RenderEngine: FC<any> = ({ comp, editMode }) => {
  const eChartsRef = useRef(null);
  const {
    // dataConfig: { status, isFetchingData, chartOptions },
    type: chartType,
    compGroup
  } = comp;
  const [option, setOption] = useState(null);

  useEffect(() => {
    // console.log('useEffect setOption');
    if (compGroup === 'charts') {
      setOption(getOption());
    }
    // fix: 解决option属性值发生变化,ReactEcharts不重新渲染问题
    if (eChartsRef && eChartsRef.current) {
      eChartsRef.current?.getEchartsInstance().setOption(getOption());
    }
  }, [comp]);

  const getOption = () => {
    const {
      dataConfig: { chartOptions }
    } = comp;
    return chartOptions
      ? chartType.includes('percent')
        ? {
            ...mergeChartPercentOption(chartType, chartOptions),
            color: chartOptions.color || themeDarkJson.color
          }
        : {
            ...chartOptions,
            color: chartOptions.color || themeDarkJson.color
          }
      : null;
  };

  const mergeChartPercentOption = (chartType, chartOptions) => {
    return {
      ...chartOptions,
      tooltip: {
        trigger: 'axis',
        formatter: params => {
          const { name } = params[0];
          const paramsSize = params.length;
          let str = `${name}<br/>
              ${params
                .map(({ seriesName, value, marker }, index) => {
                  return `${marker} ${seriesName}: ${(value * 100).toFixed(1) + '%'}${
                    index < paramsSize - 1 ? '<br/>' : ''
                  }`;
                })
                .join('')}
            `;
          return str;
        }
      },
      [chartType.includes('bar') ? 'yAxis' : 'xAxis']: {
        type: 'value',
        min: 0,
        max: 1,
        splitNumber: 4,
        interval: 0.25,
        axisLabel: {
          formatter: value => {
            return parseFloat((value * 100).toFixed(10)) + '%';
          }
        }
      },
      series: chartOptions.series.map(item => {
        item.label.formatter = ({ value }) => {
          return (value * 100).toFixed(1) + '%';
        };
        return item;
      })
    };
  };

  const renderComp = compGroup => {
    switch (compGroup) {
      case 'charts':
        return renderEchartsComp();
      case 'element':
        return renderElementsComp();
      case 'media':
        return renderMediaCompGroup();
      case 'decoration':
        return renderDecorationCompGroup();
    }
  };

  const renderEchartsComp = () => {
    // console.log('renderEchartsComp: ', comp.type, element);
    return (
      <>
        {option && renderChart()}
        {chartType.includes('ranking-list') && <RankingList element={comp.element} compStyle={comp.styleConfig} />}
      </>
    );
  };

  const renderChart = () => {
    if (chartType === 'map' || chartType === 'bubble-map') {
      echarts.registerMap('china', chinaJson as any);
      if (chartType === 'bubble-map') {
        option.series[0].symbolSize = value =>
          funcSize(
            value[2],
            option.series[0].data.map(({ name, value }) => value[2]).map(val => +val)
          );
      }
    } else if (chartType === 'scatter') {
      // symbol size
      option.series[0].symbolSize = value =>
        funcSize(
          value,
          option.series[0].data.map(val => +val)
        );
    } else if (chartType === 'kpi') {
      return <KpiCard hideTitle chart={option} />;
    } else if (chartType.includes('table')) {
      return (
        <TableNormal
          hideTitle
          chartType={chartType}
          chart={option}
          tableFields={[...comp.dataConfig.dimensionFields, ...comp.dataConfig.measureFields]}
        />
      );
    }
    // console.log('renderChart: ', JSON.stringify(option));
    if (comp.styleConfig.highConfig) {
      resolveHighConfig(chartType, option, comp.styleConfig.highConfig.proximityAnalysis);
    }

    return (
      <ReactEcharts
        option={option}
        notMerge={true}
        lazyUpdate={true}
        theme="chalk"
        style={{ width: '100%', height: '100%' }}
        ref={eChartsRef}
      />
    );
  };

  const renderElementsComp = () => {
    return <div className="element-wrapper">{renderElement(chartType)}</div>;
  };

  const renderElement = chartType => {
    switch (chartType) {
      case 'text':
        return <Text option={comp} />;
      case 'image':
        return (
          <Picture
            element={comp.element}
            editMode={editMode}
            compStyle={{ ...comp.styleConfig, backgroundColor: comp.baseConfig.backgroundColor }}
          />
        );
      case 'statistic':
        return <DigitalFlopper {...comp.element} compStyle={comp.styleConfig} />;
      case 'curTime':
        return <CurTime option={comp} />;
    }
  };

  const renderDecorationCompGroup = () => {
    return <div className="decoration-wrapper">{renderDecorationComp(chartType)}</div>;
  };

  const renderDecorationComp = chartType => {
    if (chartType.includes('border')) {
      const {
        styleConfig: {
          skin: { color, animate, rotationDir }
        },
        baseConfig
      } = comp;
      const props: {
        index: number;
        fill?: string;
        stroke?: string;
        animate?: boolean;
        rotationDir?: boolean;
        borderColor?: string;
      } = {
        index: Number(chartType.replace('border', '')),
        stroke: color,
        fill: color
      };
      if (supportAnimateBorders.includes(chartType)) {
        props.animate = animate;
        props.rotationDir = rotationDir;
        props.borderColor = color;
      }
      return <Border {...props} baseConfig={baseConfig} />;
    } else if (chartType.includes('d-line')) {
      return <Line {...comp.styleConfig} />;
    }
  };

  const renderMediaCompGroup = useCallback(() => {
    return <div className="media-wrapper">{renderMediaComp(chartType)}</div>;
  }, [comp]);

  const renderMediaComp = chartType => {
    switch (chartType) {
      case 'video':
        return (
          <Video
            option={comp.element}
            editMode={editMode}
            compStyle={{ ...comp.styleConfig, backgroundColor: comp.baseConfig.backgroundColor }}
          />
        );
    }
  };
  // console.log('RenderEngine render: ', comp, option, editMode);
  return <div className="render-engine-wrapper">{renderComp(compGroup)}</div>;
};

export default RenderEngine;
