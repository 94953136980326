import APIS from '@/apis/api';
import { http } from '@/lib';
import { DataSetCategory } from '@/model/dataset/model';
import { makeAutoObservable } from 'mobx';
interface IDataSetDataProps {
  dataSourceId: string;
  dataSourceOpts: any[];
  tableName: string;
  dimensions: any[];
  measures: any[];
  dataSetName: string;
  category: DataSetCategory;
  sqlCommand: string;
}
export interface IFunctionsProps {
  description: string;
  name: string;
  pattern: string;
  status: boolean;
  demo: string;
}

export interface ISqlCondition {
  key: string;
  value: string;
  title?: string;
}

export class DataSetStore {
  constructor() {
    makeAutoObservable(this);
  }

  // 数据集数据详情
  public dataSetData: IDataSetDataProps;
  public loading: boolean = false; // 是否loading
  public functionList: IFunctionsProps[] = []; // 函数列表
  public tableName: string = ''; // 数据集关联的表名称名称
  public isDetail: boolean = false; // 是否详情页面
  public fieldsFilters = { fields: [], conditionArr: [] }; // 数据集过滤条件
  public sqlCondition: ISqlCondition[] = []; //sql下拉筛选条件
  public activeActionTable = { tableUniqueId: '', tableName: '' }; // 当前操作的数据集表
  public filters: any[] = [];
  //public dataSetSql: string = '';

  public setDataSetData = (data: IDataSetDataProps) => {
    // 为数据创建唯一key
    /* data.data.content.forEach((item, index) => {
      item.uniqueMuziId = index;
    }); */
    this.dataSetData = data;
  };

  public setFunctionList = list => {
    this.functionList = list;
  };

  public setTableName = name => {
    this.tableName = name;
  };

  public setIsDetail = bol => {
    this.isDetail = bol;
  };

  public setLoading = loading => {
    this.loading = loading;
  };

  public setDataSourceId = dataSourceId => {
    this.dataSetData = { ...this.dataSetData, dataSourceId };
  };

  setDataSourceOpts = dataSourceOpts => {
    this.dataSetData = {
      ...this.dataSetData,
      dataSourceOpts
    };
  };

  /**
   * 设置数据集过滤条件
   * @param filters
   */
  public setFieldsFilters = filters => {
    this.fieldsFilters = filters;
  };

  /**
   * 获取SQL下拉内容
   * @returns
   */
  public getSqlCondition = async () => {
    if (this.sqlCondition.length) {
      return this.sqlCondition;
    } else {
      let sqlCondition = await http.get(APIS.FindSqlCondition);
      // 筛选条件操作符隐藏开区间
      this.sqlCondition = sqlCondition.data
        .filter(item => item.key !== 'OPEN_RANGE')
        .map((item: ISqlCondition) => {
          item.title = item.value;
          item.value = item.key;
          return item;
        });
    }
  };

  /**
   * 加工数据变化后调用预览接口
   * @param params
   */
  public dataSetRequest = params => {
    this.setLoading(true);
    return new Promise((resolve, reject) => {
      http
        .post(APIS.DataProcessingPreview, params)
        .then(res => {
          this.setDataSetData(res.data);
          resolve(true);
        })
        .catch(() => reject())
        .finally(() => this.setLoading(false));
    });
  };

  /**
   * 更新维度或度量
   */
  updateItemDimensionOrMeasure = ({ type, payload }) => {
    if (type === 'dimension') {
      const newDataSetData = {
        ...this.dataSetData,
        dimensions: this.dataSetData.dimensions.map(itemDimension => {
          if (itemDimension.name === payload.name) {
            return { ...itemDimension, ...payload };
          }
          return itemDimension;
        })
      };
      this.dataSetData = newDataSetData;
    } else {
      const newDataSetData = {
        ...this.dataSetData,
        measures: this.dataSetData.measures.map(itemMeasure => {
          if (itemMeasure.name === payload.name) {
            return { ...itemMeasure, ...payload };
          }
          return itemMeasure;
        })
      };
      this.dataSetData = newDataSetData;
    }
  };

  setTableFields = tableFields => {
    const dimensions = tableFields.filter(itemField => itemField.attribute.type === 'DIMENSION');
    const measures = tableFields.filter(itemField => itemField.attribute.type === 'MEASURE');
    const newDataSetData = {
      ...this.dataSetData,
      dimensions,
      measures
    };
    this.dataSetData = newDataSetData;
  };

  setActiveActionTable = ({ tableUniqueId, tableName }) => {
    this.activeActionTable = {
      tableUniqueId,
      tableName
    };
  };

  setFilters = filters => {
    this.filters = filters;
  };

  public setDataSetSql(sql: string) {
    this.dataSetData = {
      ...this.dataSetData,
      sqlCommand: sql
    }
  }

  public setDataSetCategory(category: DataSetCategory) {
    this.dataSetData = {
      ...this.dataSetData,
      category: category
    }
  }

  public isSqlDataSet(): boolean {
    return this.dataSetData?.category === 'COMMAND';
  }
}

const dataSetStore = new DataSetStore();

export default dataSetStore;
