import { IUdFormItem } from '../types/form'
import { ValidationRule } from 'antd/lib/form/Form'

export type Validator = (item: IUdFormItem) => ValidationRule

let validators = {
  /** 必填项 */
  required: (item: IUdFormItem): ValidationRule => {
    return { required: true, message: item.label + '不能为空' }
  },
  /** 
   * 手机号码，最宽松模式
   */
  phone: (item: IUdFormItem): ValidationRule => {
    return {
      pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
      message: '请输入正确的手机号码'
    }
  },
  /**
   * 手机号码，严谨模式。
   */
  phoneCommonly: (item: IUdFormItem): ValidationRule => {
    return {
      pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
      message: '请输入正确的手机号码'
    }
  },
  /**
   * 手机号码，最严格模式。
   */
  phoneStrict: (item: IUdFormItem): ValidationRule => {
    return {
      pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
      message: '请输入正确的手机号码'
    }
  },

  /** 最小值 */
  min: (min: number): Validator => {
    return (item: IUdFormItem): ValidationRule => {
      return { min, message: '长度不能小于' + min }
    }
  },
  max: (max: number): Validator => {
    return (item: IUdFormItem): ValidationRule => {
      return { max, message: '长度不能大于' + max }
    }
  },
  notContainEmoji: (): ValidationRule => {
    return {
      validator: (rule, value, callback) => {
        let reg = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
        if (reg.test(value)) {
          callback('不能包含emoji表情')
        } else {
          callback()
        }

      }
    }
  },
  noSymbol: (): ValidationRule => {
    return {
      pattern: /^[\w\u4e00-\u9fa5a-z]+$/gi,
      message: '只能包含汉字、字母和数字'
    }
  },
  noSpecialSymbol: (): ValidationRule => {
    return {
      pattern: /^[，。.？：；’‘”“！\w\u4e00-\u9fa5]+$/,
      message: '不能包含特殊字符'
    }
  },
  email: (): ValidationRule => {
    return {
      pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: '请输入正确的邮箱地址'
    }
  },
  whitespace: (item): ValidationRule => {
    return { whitespace: true, message: item.label + '不能为空' }
  },
  userName: (): ValidationRule => {
    return {
      pattern: /^[\u4e00-\u9fa5A-Za-z ]+$/gi,
      message: '只能包含汉字、字母和空格'
    }
  },
  userCode: (): ValidationRule => {
    return {
      pattern: /^[A-Za-z0-9]+$/,
      message: '只能包含字母和数字'
    }
  },
}

export { validators }
