import { camelCase, upperFirst } from 'lodash';
const req = require.context('./service-impl', false, /\.ts$/);
const requireAll = requireContext => requireContext.keys();

const widgetMap = {};
requireAll(req).forEach(key => {
  // console.log('req: ', key, req(key).default);
  // widgetMap[upperFirst(camelCase(key.replace(/(\.\/|\.ts)/g, '')))] = req(key).default;
  widgetMap[req(key).default.name] = req(key).default;
});

export default widgetMap;
