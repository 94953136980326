import React, { useState } from 'react';
import classNames from 'classnames';
import { Badge, Spin, Icon } from 'antd';
import TaskList from './TaskList';
import HeaderDropdown from '../HeaderDropdown';
import './index.less';

export type NoticeIconProps = {
  count?: number;
  list?: any[];
  className?: string;
  loading?: boolean;
  style?: React.CSSProperties;
  emptyImage?: string;
};

const NoticeIcon: React.FC<NoticeIconProps> = (props) => {
    const getNotificationBox = (): React.ReactNode => {
        return (
            <>
                <Spin spinning={loading} delay={300}>
                    <TaskList list={list} emptyText="暂无导出任务" />
                </Spin>
            </>
        );
    };

    const { className, count, list, loading } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const noticeButtonClass = classNames(className, 'noticeButton');
    const notificationBox = getNotificationBox();
    const NoticeDownloadIcon = <Icon type="download" style={{ fontSize: '16px' }}/>
    const trigger = (
        <span className={classNames(noticeButtonClass, { opened: visible })}>
        <Badge count={count} style={{ boxShadow: 'none' }} className="badge">
            {NoticeDownloadIcon}
        </Badge>
        </span>
    );
    if (!notificationBox) {
        return trigger;
    }

    return (
        <HeaderDropdown
            placement="bottomRight"
            overlay={notificationBox}
            overlayClassName="popover"
            trigger={['click']}
            visible={visible}
            onVisibleChange={setVisible}
        >
            {trigger}
        </HeaderDropdown>
    );
};

NoticeIcon.defaultProps = {
  emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
};

export default NoticeIcon;
