import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { Icon } from 'antd';
import { IUdMainStore } from '@/store/UdMain';

/**
 * 左侧边栏
 */
@inject('udMainStore')
@observer
class UdSidebar extends Component<IUdSidebarProps, IUdSidebarState> {
  constructor(props: IUdSidebarProps) {
    super(props);
  }

  toggleCollapsed = () => {
    const { collapse, updateCollapse } = this.props.udMainStore;
    updateCollapse(!collapse);
  }

  public render() {
    let {
      udMainStore: { collapse },
      footer,
      title,
      children,
    } = this.props;
    return (
      <React.Fragment>
        <div className={classNames('ud-sidebar')} style={{ width: collapse ? 48 : 208 }}>
          <div className="ud-sidebar-body">{children}</div>
          {footer && <div className="ud-sidebar-footer">{footer}</div>}
            <div className="sidebar-collapse">
                <Icon type={collapse ? 'menu-unfold' : 'menu-fold'} onClick={this.toggleCollapsed} />
            </div>
        </div>
        {/* <div className={classNames('ud-sidebar-collapse', { collapse: collapse })}>
          <div className="ud-sidebar-collapse-bg" onClick={this.collapse}>
            <Icon type={collapse ? 'menu-unfold' : 'menu-fold'} className="icon" />
          </div>
        </div> */}
      </React.Fragment>
    );
  }

  protected collapse = () => {
    let { collapse, updateCollapse } = this.props.udMainStore;
    updateCollapse(!collapse);
  };
}

interface IUdSidebarProps {
  /**
   * 标题
   */
  title?: ReactNode;

  /**
   * 主体
   */
  children?: ReactNode;

  /**
   * 底部
   */
  footer?: ReactNode;

  udMainStore?: IUdMainStore;
}

interface IUdSidebarState {
  /**
   * 是否收缩
   */
  collapse: boolean;
}

export default UdSidebar;
