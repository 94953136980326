import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import cls from 'classnames';
import { useStore } from '@/store/useStore';
import { WorkplaceContext } from './context';
import _ from 'lodash';
import { http } from '@/lib';
import APIS from '@/apis/api';
import {
  hasWidgetSureBtn,
  filterOutWidgetBtn,
  isWidgetRangeEmpty,
  updateConditionMapByWidget,
  containEmptyStr
} from './util';
import GridLayout from './grid-layout';
import GridLayoutMobile from './grid-layout-mobile';
import CanvasPanelMobile from './canvas-panel-mobile';
import ModalSettingWarning from './modal-setting-warning';
import ModalSettingDrillDown from './modal-setting-drill-down';
import WatermarkCon from '@/components/Watermark';
import './style/canvas-panel.less';
import { message } from 'antd';
import AddComponentGuid from '@/images/add-component-guid.png';

function CanvasPanel({
  mode,
  isTypeLink,
  boardId,
  token,
  onFilterWidgetEdit
}: {
  mode: 'edit' | 'preview';
  isTypeLink?: boolean;
  boardId?: string;
  token?: string;
  onFilterWidgetEdit?: (widgetId: string) => void;
  onWidgetOptionValChange?: (widget: any) => void;
}) {
  const {
    setActiveGridItemLayout,
    chartConditionsMap,
    setChartConditionsMap,
    widgetConditionMap,
    setWidgetConditionMap,
    activeDevice,
    showWatermark
  } = useContext(WorkplaceContext);
  const { dashboardStore } = useStore();
  const { dashboardId, dashboardName, activeBreakPoint, charts, customComps, activeIndex } = dashboardStore.state;
  const [state, setState] = useState({
    visibleModalSettingWarning: false,
    visibleModalSettingDrillDown: false,
    activeChart: null,
    activeChartIndex: -1
  });
  const allComps = [...charts, ...customComps];
  // const layouts = allComps.map(comp => (activeDevice === 'pc' ? comp.layout : comp.mobileTileLayout));
  const chartConditionsMapRef = useRef(chartConditionsMap);
  const layouts = useMemo(() => {
    return allComps.map(comp => (activeDevice === 'pc' ? comp.layout : comp.mobileTileLayout));
  }, [activeDevice, JSON.stringify(allComps)]);

  useEffect(() => {
    chartConditionsMapRef.current = chartConditionsMap;
  }, [chartConditionsMap]);

  const handleLayoutChange = (currentLayout, allLayouts, deviceType) => {
    if (deviceType !== activeDevice) return;
    // console.log('handleLayoutChange: ', currentLayout, allLayouts[activeBreakPoint], deviceType);
    const layouts = allLayouts[activeBreakPoint];
    /* const newComps = [...charts, ...customComps].map(comp => {
      // const { frontChartId } = chart;
      const compId = comp.type && comp.type === 'custom' ? comp.widgetId : comp.frontChartId;
      const { i, x, y, w, h } = layouts.find(layout => layout.i === compId);
      return {
        ...comp,
        layout: {
          i,
          x,
          y,
          w,
          h
        }
      };
    }); */
    const newComps = layouts
      .filter(layout => currentLayout.map(item => item.i).includes(layout.i))
      .map(layout => {
        const { i } = layout;
        const comp = allComps.find(comp => comp.widgetId === i || comp.frontChartId === i);
        if (activeDevice === 'pc') {
          comp.layout = layout;
        } else if (activeDevice === 'mobile') {
          comp.mobileTileLayout = layout;
        }
        return comp;
      });
    // dashboardStore.setCharts(newCharts);
    dashboardStore.setComps(newComps);
  };

  const handleResizeStop = (layout, oldItem, newItem) => {
    // console.log('handleResizeStop: ', layout, oldItem, newItem);
    setActiveGridItemLayout(newItem);
  };

  const handleGridItemClick = index => {
    if (mode === 'preview') {
      dashboardStore.setActiveIndex(index);
      return;
    }
    fetchChartWarningConfig(index);
  };

  const handleCopy = charts => {
    chartsValChangeHandler(charts);
  };

  const handleRemove = charts => {
    chartsValChangeHandler(charts);
  };

  const handleGridCustomItemClick = index => {
    dashboardStore.setActiveIndex(index);
  };

  const handleCustomEdit = widgetId => {
    onFilterWidgetEdit(widgetId);
  };

  const handleCustomCopy = newComp => {
    dashboardStore.addComp(newComp);
  };

  const handleUpdateChartConditionsMap = (widgetId, val) => {
    const newChartConditionsMap = _.cloneDeep(chartConditionsMapRef.current);
    Object.keys(newChartConditionsMap).forEach(key => {
      let value = newChartConditionsMap[key];
      if (Array.isArray(value) && value.length) {
        value = value.map(condition => {
          if (condition.frontAdditionalInfo.widgetId === widgetId) {
            return {
              ...condition,
              values: [val]
            };
          }
          return condition;
        });
      }
      newChartConditionsMap[key] = value;
    });
    setChartConditionsMap({
      ...chartConditionsMap,
      ...newChartConditionsMap
    });
  };

  const handleCustomRemove = (customComps, removedItem) => {
    const widgetIds = customComps.map(comp => comp.widgetId);
    setCustomComps(customComps);
    setWidgetConditionMap(
      Object.fromEntries(Object.entries(widgetConditionMap).filter(([key, val]) => widgetIds.includes(key)))
    );
    if (removedItem.serviceName.startsWith('button')) return;
    const { id: removedItemConId } = widgetConditionMap[removedItem.widgetId];
    setChartConditionsMap(
      Object.keys(chartConditionsMap).map(key => {
        if (widgetIds.includes(key)) return { [key]: chartConditionsMap[key] };
        return {
          [key]: chartConditionsMap[key].filter(val => val.id !== removedItemConId)
        };
      })
    );
  };

  const handleQuery = (newChartConditionsMap = chartConditionsMap) => {
    const chartIds = Object.keys(newChartConditionsMap);
    let refreshedChartIds = chartIds;

    if (
      dashboardStore.state.customComps.some(comp => comp.name === 'numberRangeWidget') &&
      dashboardStore.state.customComps
        .filter(comp => comp.name === 'numberRangeWidget')
        .some(comp => comp.options.value && comp.options.value.split(',').some(containEmptyStr))
    ) {
      message.warning('【数值区间】起始结束值均不能为空');
      return;
    }
    // 查询按钮控制范围
    if (hasWidgetSureBtn(dashboardStore)) {
      const {
        options: {
          attrs: { customRange, filterIds }
        }
      } = dashboardStore.state.customComps.find(comp => comp.name === 'buttonSureWidget');
      if (customRange) {
        refreshedChartIds = [
          ...new Set(
            customComps
              .filter(filterOutWidgetBtn)
              .flatMap(comp => (filterIds.includes(comp.widgetId) ? comp.options.attrs.chartIds : []))
          )
        ];
      }
    }

    setChartConditionsMap(newChartConditionsMap);
    dashboardStore.setCharts(
      charts.map(chart => {
        if (refreshedChartIds.includes(chart.frontChartId)) {
          return {
            ...chart,
            dataConfig: {
              ...chart.dataConfig,
              status: 'refreshRelationChartData'
            }
          };
        }
        return chart;
      })
    );
  };

  const handleReset = () => {
    const newWidgetConditonMap = {};
    const newChartConditionsMap = {};
    Object.keys(widgetConditionMap).forEach(widgetId => {
      newWidgetConditonMap[widgetId] = {
        ...newWidgetConditonMap[widgetId],
        values: []
      };
    });
    Object.keys(chartConditionsMap).forEach(chartId => {
      newChartConditionsMap[chartId] = chartConditionsMap[chartId].map(condition => ({
        ...condition,
        values: []
      }));
    });
    setWidgetConditionMap({
      ...widgetConditionMap,
      ...newWidgetConditonMap
    });
    setChartConditionsMap({
      ...chartConditionsMap,
      ...newChartConditionsMap
    });
    handleQuery(newChartConditionsMap);
    dashboardStore.setCustomComps(
      customComps.map(comp => {
        if (!comp.component.includes('button')) {
          return {
            ...comp,
            options: {
              ...comp.options,
              value: ''
            }
          };
        }
        return comp;
      })
    );
  };

  const setCustomComps = customComps => {
    dashboardStore.setCustomComps(customComps);
  };

  const chartsValChangeHandler = charts => {
    dashboardStore.setCharts(charts);
  };

  const handleSettingWarning = chartIndex => {
    const {
      chartType,
      dataConfig: { dimensionFields, measureFields, xaxis, legend, yaxis, yaxisExt }
    } = _.cloneDeep(charts[chartIndex]);
    setState({
      ...state,
      visibleModalSettingWarning: true,
      activeChart: {
        ...charts[chartIndex],
        dimensionFields: chartType === 'combination' ? [...xaxis, ...(legend ? legend : [])] : dimensionFields,
        measureFields:
          chartType === 'combination' ? [...(yaxis ? yaxis : []), ...(yaxisExt ? yaxisExt : [])] : measureFields
      },
      activeChartIndex: chartIndex
    });
  };

  const handleCancelSettingWarning = () => {
    setState({
      ...state,
      visibleModalSettingWarning: false
    });
  };

  const handleSettingDrillDown = chartIndex => {
    const activeChart = _.cloneDeep(charts[chartIndex]);
    if (!activeChart.dataConfig.dataSetId) {
      message.error('请选择数据集');
      return;
    }
    setState({
      ...state,
      visibleModalSettingDrillDown: true,
      activeChart,
      activeChartIndex: chartIndex
    });
  };

  const handleConfirmSettingDrillDown = selectedFields => {
    const activeChart = charts[state.activeChartIndex];
    const { frontChartId } = activeChart;
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.drillDownConfig = selectedFields;
    dashboardStore.modChart({ frontChartId, updatedItem });
    handleCancelSettingDrillDown();
  };

  const handleCancelSettingDrillDown = () => {
    setState({
      ...state,
      visibleModalSettingDrillDown: false
    });
  };

  const refreshActiveChartWarningConfig = id => {
    const index = dashboardStore.state.charts.findIndex(chart => chart.id === id);
    fetchChartWarningConfig(index);
  };

  const fetchChartWarningConfig = index => {
    const activeChart = dashboardStore.state.charts.find(chart => chart.order === index);
    const { id, frontChartId } = activeChart;
    http.post(APIS.FindChartWarningConfig, { id }).then(res => {
      const resData = res.data;
      if (!resData.length) {
        dashboardStore.setActiveIndex(index);
        return;
      }
      const updatedItem = _.cloneDeep(activeChart);
      const warningConfig = {};
      resData.forEach(item => {
        const { monitorWarnName, boardChartFieldVos } = item;
        warningConfig[monitorWarnName] = boardChartFieldVos;
      });
      updatedItem.dataConfig.warningConfig = warningConfig;
      dashboardStore.modChart({ frontChartId, updatedItem });
      dashboardStore.setActiveIndex(index);
    });
  };

  const handleWidgetOptionValChange = useCallback(widget => {
    dashboardStore.setCustomComps(
      dashboardStore.state.customComps.map(comp => {
        if (comp.widgetId === widget.widgetId) {
          return {
            ...comp,
            options: {
              ...comp.options,
              value: widget.options.value
            }
          };
        }
        return comp;
      })
    );
    if (isWidgetRangeEmpty(widget) || hasWidgetSureBtn(dashboardStore)) {
      updateConditionMap(widget);
      return;
    }
    setRangeChartsFilterConditions(widget);
  }, []);

  const updateConditionMap = itemComp => {
    const value = itemComp.options.value || '';
    const values = value.indexOf(',') > -1 ? value.split(',') : [value];
    const chartIds = itemComp.options.attrs.chartIds;
    const newChartConditionsMap = _.cloneDeep(chartConditionsMapRef.current);
    setWidgetConditionMap({
      widgetConditionMap,
      [itemComp.widgetId]: {
        ...widgetConditionMap[itemComp.widgetId],
        values
      }
    });
    Object.keys(newChartConditionsMap).forEach(chartId => {
      if (chartIds.includes(chartId)) {
        newChartConditionsMap[chartId] = newChartConditionsMap[chartId].map(condition => {
          if (condition.frontAdditionalInfo.widgetId === itemComp.widgetId) {
            return {
              ...condition,
              values
            };
          }
          return condition;
        });
      }
    });
    setChartConditionsMap({
      ...chartConditionsMap,
      ...newChartConditionsMap
    });
  };

  const setRangeChartsFilterConditions = itemComp => {
    const {
      options: {
        attrs: { chartIds }
      }
    } = itemComp;
    const { newWidgetConditionMap, newChartConditionsMap } = updateConditionMapByWidget({
      itemComp,
      widgetConditionMap,
      dashboardStore
    });
    setWidgetConditionMap({
      ...widgetConditionMap,
      ...newWidgetConditionMap
    });
    setChartConditionsMap({
      ...chartConditionsMap,
      ...newChartConditionsMap
    });
    dashboardStore.setCharts(
      dashboardStore.state.charts.map(chart => {
        if (chartIds.includes(chart.frontChartId)) {
          return {
            ...chart,
            dataConfig: {
              ...chart.dataConfig,
              status: 'refreshRelationChartData'
            }
          };
        }
        return chart;
      })
    );
  };

  // console.log('render CanvasPanel: ', toJS(charts), toJS(layouts), toJS(chartConditionsMap));
  return (
    <>
      <CanvasPanelMobile
        mode={mode}
        style={{
          visibility: activeDevice === 'mobile' ? 'visible' : 'hidden',
          zIndex: activeDevice === 'mobile' ? 1 : 0
        }}
      >
        {!!allComps.length && (
          <GridLayoutMobile
            dashboardId={dashboardId || boardId}
            dashboardName={dashboardName}
            data={allComps}
            layouts={layouts}
            activeIndex={activeIndex}
            activeBreakPoint={activeBreakPoint}
            mode={mode}
            isTypeLink={mode === 'preview' && isTypeLink}
            token={token}
            onLayoutChange={(currentLayout, allLayouts) => handleLayoutChange(currentLayout, allLayouts, 'mobile')}
            onResizeStop={handleResizeStop}
            onGridItemClick={handleGridItemClick}
            onGridCustomItemClick={handleGridCustomItemClick}
            onRemove={handleRemove}
            onCustomEdit={handleCustomEdit}
            onCustomRemove={handleCustomRemove}
            onQuery={handleQuery}
            onReset={handleReset}
            onWidgetOptionValChange={handleWidgetOptionValChange}
            onUpdateChartConditionsMap={handleUpdateChartConditionsMap}
          />
        )}
      </CanvasPanelMobile>
      <div
        className={cls('canvas-panel-container dashboard-layout pc-container', {
          edit: mode === 'edit'
        })}
        style={{
          visibility: activeDevice === 'pc' ? 'visible' : 'hidden',
          zIndex: activeDevice === 'pc' ? 1 : 0
        }}
      >
        <div className="dashboard-content">
          {!!allComps.length && (
            <>
              {/* 水印 */}
              {showWatermark && (<WatermarkCon />)}
              <GridLayout
                dashboardId={dashboardId || boardId}
                dashboardName={dashboardName}
                data={allComps}
                layouts={layouts}
                cols={{ lg: 24 }}
                activeIndex={activeIndex}
                activeBreakPoint={activeBreakPoint}
                mode={mode}
                isTypeLink={mode === 'preview' && isTypeLink}
                token={token}
                onLayoutChange={(currentLayout, allLayouts) => handleLayoutChange(currentLayout, allLayouts, 'pc')}
                onResizeStop={handleResizeStop}
                onGridItemClick={handleGridItemClick}
                onCopy={handleCopy}
                onRemove={handleRemove}
                onSettingWarning={handleSettingWarning}
                onSettingDrillDown={handleSettingDrillDown}
                onGridCustomItemClick={handleGridCustomItemClick}
                onCustomCopy={handleCustomCopy}
                onCustomRemove={handleCustomRemove}
                onCustomEdit={handleCustomEdit}
                onQuery={handleQuery}
                onReset={handleReset}
                onWidgetOptionValChange={handleWidgetOptionValChange}
                onUpdateChartConditionsMap={handleUpdateChartConditionsMap}
              />
            </>
          )}
          {!allComps.length && (
            <div className="add-component-guid-container">
              <img src={AddComponentGuid} alt="" />
              <div className="start-text" style={{ color: 'rgb(102,102,102)' }}>
                从上方工具栏中选择组件，添加至画布
              </div>
            </div>
          )}
        </div>
        {state.visibleModalSettingWarning && (
          <ModalSettingWarning
            visible={state.visibleModalSettingWarning}
            dashboardId={dashboardId}
            activeChart={state.activeChart}
            sourceType="dashboard"
            onSave={refreshActiveChartWarningConfig}
            onCancel={handleCancelSettingWarning}
          />
        )}
        {state.visibleModalSettingDrillDown && (
          <ModalSettingDrillDown
            visible={state.visibleModalSettingDrillDown}
            dimensionFields={state.activeChart.dataConfig.dataSetFields.filter(
              field => field.attribute.type === 'DIMENSION'
            )}
            drillDownConfig={state.activeChart.drillDownConfig}
            onCancel={handleCancelSettingDrillDown}
            onOk={handleConfirmSettingDrillDown}
          />
        )}
      </div>
    </>
  );
}

export default observer(CanvasPanel);
