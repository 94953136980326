import React, { FC, useState, forwardRef, useImperativeHandle } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Input } from 'antd';
import DragItem from './components/DragItem';
import './style/index.less';

interface IProps {
  dimensionFields: any;
}
const { Search } = Input;

const List = styled.div<any>`
  border: 1px ${props => (props.isDraggingOver ? 'dashed #4099ff' : 'dashed #ddd')};
  padding: 0.5rem;
  border-radius: 2px;
  width: 288px;
`;
const Aside = styled.div<any>`
  position: relative;
`;
const Container = styled(List)`
  background: rgba(204, 204, 204, 0.15);
`;
const Notice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid transparent;
  height: 28px;
  line-height: 1.5;
  color: #aaa;
`;
const Item = styled.div`
  position: relative;
`;
/* const Item = styled.div`
  border: 1px ${props => (props.isDragging ? 'dashed $4099ff' : 'solid #ddd')};
`; */
const Clone = styled(Item)``;
const DrillDown: FC<any> = forwardRef<any, any>(({ dimensionFields, destinationFields }, ref) => {
  useImperativeHandle(ref, () => ({
    state
  }));
  const [state, setState] = useState({
    id2List: {
      droppable: 'items',
      droppable2: 'selected'
    },
    items: dimensionFields,
    selected: destinationFields || []
  });
  const handleFieldSearch = val => {
    setState({
      ...state,
      items:
        val === ''
          ? JSON.parse(JSON.stringify(dimensionFields))
          : JSON.parse(JSON.stringify(dimensionFields)).filter(
              item => item.customName.indexOf(val) !== -1
            )
    });
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const copy = (source, destination, droppableSource, droppableDestination) => {
    console.log('==> dest', destination);
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item: any = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, { ...item, id: uuidv4() });
    return destClone;
  };
  const move = (
    source,
    destination,
    droppableSource,
    droppableDestination
  ): { droppable?: any; droppable2?: any } => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };
  const onDragEnd = result => {
    const { source, destination } = result;
    console.log('===> result', result);
    // dropped outside the list
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        setState({
          ...state,
          selected: reorder(getList(source.droppableId), source.index, destination.index)
        });
        break;
      case 'droppable':
        console.log('copy');
        setState({
          ...state,
          selected: copy(
            getList(source.droppableId),
            getList(destination.droppableId),
            source,
            destination
          )
        });
        break;
      default:
        setState({
          ...state,
          selected: move(
            getList(source.droppableId),
            getList(destination.droppableId),
            source,
            destination
          ).droppable2
        });
        break;
    }
  };
  const handleDragItemRemove = itemField => {
    setState({
      ...state,
      selected: state.selected.filter(item => item.id !== itemField.id)
    });
  };
  const getList = id => state[id === 'droppable' ? 'items' : 'selected'];
  console.log('render: ', state);
  return (
    <div className="drill-down-wrapper">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="drill-down-source">
          <div className="drill-down-source-head">
            <label>维度</label>
            <Search placeholder="搜索维度" onSearch={value => handleFieldSearch(value)} />
          </div>
          <div className="drill-down-source-con">
            <Droppable droppableId="droppable" isDropDisabled={true}>
              {(provided, snapshot) => (
                <Aside ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                  {state.items.map((field, index) => (
                    <Draggable key={field.uniqueId} draggableId={field.uniqueId} index={index}>
                      {(provided, snapshot) => (
                        <React.Fragment>
                          <Item
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            style={{
                              ...provided.draggableProps.style,
                              transform: snapshot.isDragging
                                ? provided.draggableProps.style?.transform
                                : 'translate(0px, 0px)'
                            }}
                          >
                            <DragItem
                              isLast={index === state.items.length - 1}
                              fieldFrom="source"
                              itemField={field}
                              key={field.uniqueId}
                            />
                          </Item>
                          {snapshot.isDragging && (
                            <Clone style={{ transform: 'none !important' }}>
                              <DragItem
                                isLast={index === state.items.length - 1}
                                fieldFrom="source"
                                itemField={field}
                                key={field.uniqueId}
                              />
                            </Clone>
                          )}
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Aside>
              )}
            </Droppable>
          </div>
        </div>
        <div className="drill-down-target">
          <div className="drill-down-target-head">
            <h3>下钻顺序</h3>
          </div>
          <div className="drill-down-target-con">
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => {
                // console.log('provided: ', provided.placeholder, snapshot.isUsingPlaceholder);
                return (
                  <Container ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                    {state.selected.length
                      ? state.selected.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                isDragging={snapshot.isDragging}
                                style={provided.draggableProps.style}
                              >
                                <DragItem
                                  isLast={index === state.selected.length - 1}
                                  fieldFrom="destination"
                                  itemField={item}
                                  key={item.uniqueId}
                                  onDragItemRemove={handleDragItemRemove}
                                />
                              </Item>
                            )}
                          </Draggable>
                        ))
                      : !snapshot.isUsingPlaceholder && <Notice>拖动字段至此处</Notice>}
                    {provided.placeholder}
                  </Container>
                );
              }}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    </div>
  );
});

export default DrillDown;
