import _ from 'lodash'
import React, { Component } from 'react'
import { Form, Input, Icon, Button } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { http } from '../..'
import { IUaaTokenData, IUaaAppInfo } from '../../types'
import { uaaApp } from '../../core/uaaApp'

const styles = {
  login: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: 'linear-gradient(45deg, gray, #e6e6e6)'
  },
  panel: {
    width: '320px',
    borderRadius: '6px',
    marginTop: '-50px',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.08)',
    background: 'rgba(255, 255, 255, 0.3)'
  },
  panelH1: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 300,
    padding: '14px 0',
    marginBottom: '0',
    color: 'rgba(0, 0, 0, 0.7)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  },
  vercodeBox: {
    display: 'flex'
  },
  vercode: {
    minWidth: '130px',
    maxHeight: '32px',
    cursor: 'pointer',
    marginLeft: '10px',
    border: '1px solid #ccc'
  }
}

const colors = [
  'linear-gradient(45deg, #2e3192, #1bffff)',
  'linear-gradient(45deg, #d4145a, #fbb03b)',
  'linear-gradient(45deg, #009245, #fcee21)',
  'linear-gradient(45deg, #662d8c, #ed1e79)',
  'linear-gradient(45deg, #ed1c24, #fcee21)',
  'linear-gradient(45deg, #00a8c5, #ffff7e)',
  'linear-gradient(45deg, #fb872b, #d9e021)',
  'linear-gradient(45deg, #312a6c, #852d91)',
  'linear-gradient(45deg, #009e00, #ffff96)',
  'linear-gradient(45deg, #b066fe, #63e2ff)',
  'linear-gradient(45deg, gray, #e6e6e6)',
  'linear-gradient(45deg, #00ffa1, aqua)',
  'linear-gradient(45deg, #8e78ff, #ff7d78)',
  'linear-gradient(45deg, #00537e, #136d7e 33%, #3aa17e)',
  'linear-gradient(45deg, #fca5f1, #b5ffff)',
  'linear-gradient(45deg, #d585ff, #0fe)',
  'linear-gradient(45deg, #f24645, #ebc08d)',
  'linear-gradient(45deg, #3a3897, #a3a1ff)',
  'linear-gradient(45deg, #45145a, #ff5300)',
  'linear-gradient(45deg, #333, #5a5454)',
  'linear-gradient(45deg, #4f00bc, #29abe2)',
  'linear-gradient(45deg, #00b7ff, #ffffc7)',
  'linear-gradient(45deg, #93278f, #00a99d)',
  'linear-gradient(to bottom, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), radial-gradient(at 50% 0%, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.50) 50%)',
  'linear-gradient(to top, #fff1eb 0%, #ace0f9 100%)',
  'linear-gradient(60deg, #29323c 0%, #485563 100%)',
  'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
]

/**
 * 本地开发时，登录uaa使用。
 */
class UdLocalLogin extends Component<IUdLoginProps, IUdLoginState> {

  private color: string = ''
  private authorization: IUaaTokenData = {} as IUaaTokenData

  constructor(props: IUdLoginProps) {
    super(props)
    this.state = {

    }
    http.defaults.baseURL = this.props.loginApiBaseUrl
    this.color = colors[this.random(0, colors.length)]
  }

  public componentDidMount() {
    uaaApp.closeLoader()
    this.getVerifyCode()
  }

  public render() {

    const { getFieldDecorator } = this.props.form

    return (
      <div className="page-login" style={{ ...styles.login, backgroundImage: this.color }}>
        <div className="login-panel" style={styles.panel}>
          <h1 style={styles.panelH1 as any}>开发环境登录界面</h1>
          <Form onSubmit={this.handleSubmit} style={{ padding: '15px' }} className="login-form">
            <Form.Item style={{ marginBottom: '15px' }}>
              {getFieldDecorator('username', {
                initialValue: this.props.username || '',
                rules: [{ required: true, message: '用户名不能为空' }],
              })(
                <Input
                  prefix={<Icon type="user" />}
                  placeholder="用户名"
                />,
              )}
            </Form.Item>
            <Form.Item style={{ marginBottom: '15px' }} >
              {getFieldDecorator('password', {
                initialValue: this.props.password || '',
                rules: [{ required: true, message: '请输入登录密码' }],
              })(
                <Input
                  prefix={<Icon type="lock" />}
                  type="password"
                  placeholder="密码"
                />,
              )}
            </Form.Item>
            <Form.Item style={{ marginBottom: '15px' }}>
              {getFieldDecorator('vercode', {
                rules: [{ required: true, message: '请输入验证码' }],
                initialValue: "isdfjjsdfdd",
              })(
                <div style={styles.vercodeBox}>
                  <Input
                    prefix={<Icon type="lock" />}
                    placeholder="验证码"
                  />
                  <img style={styles.vercode} alt="验证码" title="点击更换验证码" onClick={() =>
                    this.getVerifyCode()} src={(this.state.verifyCode && this.state.verifyCode.srcBase64) || undefined}
                  />
                </div>
                ,
              )}
            </Form.Item>
            <Form.Item style={{ textAlign: 'center', margin: '0' }}>
              <Button style={{ lineHeight: '1' }} type="primary" htmlType="submit" className="login-form-button">登录</Button>
            </Form.Item>
          </Form>
        </div>

      </div >
    )
  }


  protected getVerifyCode = () => {
    http.get<IVerifyCode>('/api/uaa/uaa/sso/ssoCode').then(res => {
      this.setState({
        verifyCode: res.data
      })
    })
  }

  protected handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        let { ...user } = values
        user.uuid = this.state.verifyCode && this.state.verifyCode.uuid
        http.post<ILoginRes>('/api/uaa/uaa/sso/ssoLogin', user).then(res => {
          console.log(res)
          this.getAccessToken(res.data.ticket)
        }, res => {
          if (res.data.code == 152506 || res.data.code == 152507) {
            this.getVerifyCode()
          }
        })
      }
    })
  }

  private getAccessToken = (ticket: string) => {
    http.get<IUaaTokenData>(`/login?ticket=${ticket}`).then((res) => {
      this.authorization = res.data
      this.getMyInfo()
    })
  }

  private getMyInfo = () => {
    http.post<IUaaAppInfo>('/my/info', {}, {
      headers: {
        Authorization: this.authorization.accessToken
      }
    }).then(res => {
      uaaApp.setToken(this.authorization)
      uaaApp.setSysInfo(res.data)
      window.location.reload()
    })
  }

  private random = (min: number, max: number) => {
    return parseInt((Math.random() * (max - min + 1) + min) as any, 10)
  }

}

interface IUdLoginProps extends FormComponentProps {
  /**
   * 后端接口地址前缀
   */
  loginApiBaseUrl: string
  /**
   * 默认用户名
   */
  username?: string
  /**
   * 默认用户密码
   */
  password?: string
}

interface IUdLoginState {
  verifyCode?: IVerifyCode
}

interface IVerifyCode {
  srcBase64: string
  uuid: string
}

interface ILoginRes {
  ticket: string
  token: string
}

export default Form.create<IUdLoginProps>()(UdLocalLogin)
