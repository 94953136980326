import { GRANULARITY_TYPE_ICONS } from '@/utils/enum';
export const granularityRE = /\(([^()]*)\)/;
/**
 * 日期字段粒度权重
 */
export const granularityWeightMap = {
  year: 1,
  quarter: 5,
  month: 10,
  day: 100
};

/**
 * 过滤普通字段，只保留粒度字段
 */
export const filterOutNormalFields = fields => {
  const ret = [];
  const granularityTypes = Object.keys(GRANULARITY_TYPE_ICONS);
  if (!fields || (Array.isArray(fields) && !fields.length)) return ret;
  const fieldGroup = fields
    .filter(({ attribute: { granularity } }) => {
      return granularity && granularityTypes.includes(granularity);
    })
    .map(field => ({
      ...field,
      group: field.customName.replace(granularityRE, '')
    }))
    .reduce((acc, val) => {
      (acc[val.group] = acc[val.group] || []).push(val);
      return acc;
    }, {});
  Object.keys(fieldGroup).forEach(key => {
    const val = fieldGroup[key];
    if (val.length === 1) {
      ret.push(val[0]);
      return;
    }
    let highWeight = 0;
    let tempField = null;
    val.forEach(item => {
      const granularity = GRANULARITY_TYPE_ICONS[item.attribute.granularity];
      if (granularityWeightMap[granularity] > highWeight) {
        highWeight = granularityWeightMap[granularity];
        tempField = item;
      }
    });
    ret.push(tempField);
  });
  return ret;
};

/**
 * 根据字段粒度生成同环比菜单
 * @param granularity 字段粒度
 */
export const getContrastMenuByFieldGranularity = granularity => {
  switch (granularity) {
    case 'year':
      return [{ label: '年环比', value: 'YEAR_RING_RATIO' }];
    case 'quarter':
      return [
        { label: '季环比', value: 'QUARTER_RING_RATIO' },
        { label: '年同比', value: 'QUARTER_YEAR_RATIO' }
      ];
    case 'month':
      return [
        { label: '月环比', value: 'MONTH_RING_RATIO' },
        { label: '年同比', value: 'MONTH_YEAR_RATIO' }
      ];
    case 'day':
      return [
        { label: '日环比', value: 'DAY_RING_RATIO' },
        { label: '月同比', value: 'DAY_MONTH_RATIO' },
        { label: '年同比', value: 'DAY_YEAR_RATIO' }
      ];
  }
};

export const granularityZhCnMap = {
  year: '年',
  quarter: '季度',
  month: '月',
  day: '日'
};

export const generateContrastConfig = () => {
  return {
    visibleModalContrastConfig: false,
    fieldOpts: [],
    fieldsValue: {
      dateField: '',
      type: '',
      valueType: 'INCREASE_RATIO',
      granularity: ''
    },
    hasDynamicRingRatio: false
  };
};
