import udMainStore, { IUdMainStore } from '@/store/UdMain';
import dataSetStore, { DataSetStore } from '@/store/DataSet';
import udMainPageTabsStore, { IUdMainPageTabsStore } from '@/store/UdMainPageTabs';
import dataBoardStore, { DataBoardStore } from '@/store/DataBoard';
import dataSourceStore, { DataSourceStore } from '@/store/DataSource';
import dataPermissionStore, { DataPermissionStore } from '@/store/DataPermission';
import screenStore, { ScreenStore } from '@/store/Screen';
import dashboardStore, { DashboardStore } from '@/store/Dashboard';
import licenseStore, { LicenseStore } from '@/store/License';

export interface IStores {
  udMainStore: IUdMainStore;
  dataSetStore: DataSetStore;
  udMainPageTabsStore: IUdMainPageTabsStore;
  dataBoardStore: DataBoardStore;
  dataSourceStore: DataSourceStore;
  dataPermissionStore: DataPermissionStore;
  screenStore: ScreenStore;
  dashboardStore: DashboardStore;
  licenseStore: LicenseStore
}

const stores: IStores = {
  udMainStore,
  dataSetStore,
  udMainPageTabsStore,
  dataBoardStore,
  dataSourceStore,
  dataPermissionStore,
  screenStore,
  dashboardStore,
  licenseStore
};

export default stores;
