import React, { Component, useState } from 'react';
import { Menu, Icon, Badge, Dropdown, Avatar, message, Button, Input, Tag } from 'antd';
import './style.scss';
import { observer, inject } from 'mobx-react';
import { IUdMainStore } from '@/store/UdMain';
import { UdModal, http, UdModalForm, validators, uaaApp } from '@/lib';
import apis from '@/apis';
import APIS from '@/apis/api';
import Password from 'antd/lib/input/Password';
import Modal, { ModalProps } from 'antd/lib/modal';
import headImg from '@/assets/timg.jpg';
import { ILicenseInfo } from '@/model/common/commons';
import stores from '@/store';
// import udMainStore from "@/store/UdMain"


@inject('udMainStore')
@observer
class Index extends Component<IUdMainHeaderRightProps, IUdMainHeaderRightState> {
  private isInitialPwd = uaaApp.isInitialPwd();
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
    };
  }

  componentDidMount() {
    if (this.isInitialPwd) {
      this.tipEditPassword();
    }
    
  }

  static getDerivedStateFromProps(props:IUdMainHeaderRightProps, state: IUdMainHeaderRightState) {
    const mappingTag = (license: ILicenseInfo): ILicenseTagState => {
      if (license) {
        switch (license.licenseType) {
          case 'TRY_FREE':
            return {
              color: 'red',
              tagText: '免费试用'
            };
          case 'PERIOD':
            return {
              color: 'volcano',
              tagText: '时间段授权'
            };
          case 'PERPETUAL':
            return {
              color: 'green',
              tagText: '永久授权'
            }
        }
      }
    };
    if(props.license){
       return {
        ...state,
        licenseTag: mappingTag(props.license),
        license: props.license
       } as IUdMainHeaderRightState
    }
  }

  render() {
    const UaaSysInfo = JSON.parse(sessionStorage.getItem('UaaSysInfo')) || null;
    // const userName = UaaSysInfo?.profile?.username || '';
    const name = UaaSysInfo?.profile?.name || '';
    const menu = (
      <Menu>
        <Menu.Item key="0" onClick={this.editPassword}>
          修改密码
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" onClick={this.logOut}>
          退出登录
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="main-hd-right">
        <Dropdown overlay={menu}>
          <div className="hd-user right-item">
            {/* <Avatar src={headImg} style={{ backgroundColor: '#26d7eb' }}> */}
            您好，
            {/* </Avatar> */}
            <span className="hd-user-name">{name}</span>
            <Icon type="down" />
          </div>
        </Dropdown>

        <Tag color={this.state.licenseTag?.color} onClick={this.showLicenseInfo} className='license-tag'>{this.state.licenseTag?.tagText}</Tag>

      </div>
    );
  }

  public showLicenseInfo = () => {
    const licenseInfo = this.state.license;
    Modal.info({
      title: '授权信息',
      content: (
        <div>
          <p>授权方式：{this.state.licenseTag?.tagText}</p>
          <p>生效日期：{licenseInfo?.applyTimeDesc}</p>
          <p>失效日期：{licenseInfo?.licenseType == 'PERPETUAL' ? '永不' : licenseInfo?.expireTimeDesc}</p>
        </div>
      ),
      okText: '确定',
      onOk() { },
    });
  };

  public logOut = () => {
    this.props.udMainStore.logOut();
  };

  public editPassword = () => {
    const UaaSysInfo = JSON.parse(sessionStorage.getItem('UaaSysInfo'))?.profile || {};
    const { id, phone } = UaaSysInfo;
    let modalProps: ModalProps = {};
    if (this.isInitialPwd) {
      // 修改初始密码屏蔽可关闭弹框的属性
      modalProps = { closable: false, maskClosable: false, cancelButtonProps: { style: { display: 'none' } } };
    }
    UdModal.open({
      title: '修改密码',
      content: (
        <UdModalForm
          items={[
            // {id:'phone',label:'手机号',render: <div>{phone}</div>},
            // {id:'verificationCode',label:'验证码',rules:[validators.required],render: <VerificationCodeItem phone={phone} />},
            { id: 'oldPassword', label: '旧密码', rules: [validators.required], render: <Password /> },
            {
              id: 'password',
              label: '新密码',
              rules: [
                validators.required,
                //validators.min(8),
                //validators.max(20),
                ({ form }) => ({
                  validator: (rule, value, callback) => this.validateToNextPassword(rule, value, callback, form)
                }),
                /* { pattern: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,20}$/, message: '密码必须是大小写字母加数字！' } ,*/
                {
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  message: '密码长度至少8个字符，并且至少包含1个大写字母，1个小写字母和1个数字'
                }
              ],
              decoratorOptions: { validateFirst: true },
              render: <Password />
            },
            {
              id: 'confirm',
              label: '确认新密码',
              rules: [
                validators.required,
                ({ form }) => ({
                  validator: (rule, value, callback) => this.compareToFirstPassword(rule, value, callback, form)
                }),
                {
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  message: '密码长度至少8个字符，并且至少包含1个大写字母，1个小写字母和1个数字'
                }
              ],
              render: <Password onBlur={this.handleConfirmBlur} />
            }
          ]}
        />
      ),
      onOk: values => {
        const { verificationCode, password, oldPassword } = values;
        // const params = {id,flag:1,phone,verificationCode,password}
        const params = { id, flag: 1, verificationCode, passWord: password, oldPassWord: oldPassword };
        return http.post(APIS.ModifyPassword, params).then(res => {
          message.success('修改成功，请重新登录！', 1.5, () => {
            // this.logOut();
            sessionStorage.clear();
            window.location.reload();
          });
        });
      },
      ...modalProps
    });
  };

  public validateToNextPassword = (rule, value, callback, form) => {
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  public compareToFirstPassword = (rule, value, callback, form) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('您输入的两个密码不一致！');
    } else {
      callback();
    }
  };

  public handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  /* 弹框提示用户修改初始密码 */
  public tipEditPassword = () => {
    const tipEditPwdModl = UdModal.open({
      title: '提示',
      content: <div>为了您的账号安全，请修改登录密码！</div>,
      closable: false,
      maskClosable: false,
      footer: [
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            tipEditPwdModl.destroy();
            this.editPassword();
          }}
        >
          去修改
        </Button>
      ]
    });
  };
}

interface IUdMainHeaderRightProps {
  udMainStore?: IUdMainStore;
  license: ILicenseInfo
}
interface IUdMainHeaderRightState {
  confirmDirty?: boolean;
  licenseTag?: ILicenseTagState,
  license?: ILicenseInfo
}

type LicenseTagColor = 'red' | 'green' | 'volcano';

interface ILicenseTagState {
  color: LicenseTagColor;
  tagText: string;
}



export default Index;

function VerificationCodeItem(props) {
  const { phone } = props;
  const [time, setTime] = useState(0);
  const getVerCode = () => {
    const { accountType } = JSON.parse(sessionStorage.getItem('UaaSysInfo'))?.profile || {};
    const params = { phone, type: accountType, flag: 0 };
    http.get(apis.SMSVerificationCode, { params }).then(res => {
      setTime(60);
      let timer = setTimeout(() => {
        clearTimeout(timer);
        countdownTime();
      }, 1000);
    });
  };
  const countdownTime = () => {
    setTime(prevCount => {
      if (prevCount > 1) {
        let timer = setTimeout(() => {
          clearTimeout(timer);
          countdownTime();
        }, 1000);
      }
      return prevCount - 1;
    });
  };
  return (
    <div className="verification-code-item">
      <Input placeholder="请输入验证码" onChange={props.onChange} />
      <div className="get-vercode-btn">
        <Button onClick={getVerCode} disabled={time > 0 ? true : false}>
          {time ? time + 's' : '获取验证码'}
        </Button>
      </div>
    </div>
  );
}
