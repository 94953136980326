import { WidgetService } from '../service/widget-service';
import { Base } from '../data';

const genDialogPanel = () => ({
  options: {
    attrs: {
      multiple: false,
      placeholderMin: 'ibNumberRange.pleaseKeyMin',
      placeholderMax: 'ibNumberRange.pleaseKeyMax',
      enableRange: false,
      chartIds: [],
      dataSetId: '',
      fieldUniqueId: '',
      dragItems: []
    },
    value: '',
    defaultValue: '',
    manualModify: false
  },
  label: 'ibNumberSelect.label',
  refresh: false,
  defaultClass: 'tree-filter',
  component: 'ib-number-range'
});

const genDrawPanel = () => ({
  type: 'custom',
  style: {
    showTitle: false,
    mainTitle: '',
    labelPos: 'top',
    fontSize: 14,
    color: '#333',
    bold: false,
    italic: false,
    letterSpacing: 0
  },
  component: 'ib-number-range',
  miniSizeX: 1,
  miniSizeY: 2
});

class NumberRangeServiceImpl extends WidgetService implements Base {
  public filterDialog: boolean;
  public showSwitch: boolean;
  constructor(options = {}) {
    Object.assign(options, { name: 'numberRangeWidget' });
    super(options);
    this.filterDialog = true;
    this.showSwitch = false;
  }

  initFilterDialog() {
    return genDialogPanel();
  }

  initDrawPanel() {
    return genDrawPanel();
  }

  filterField(fields) {
    return fields.filter(field => field.attribute.valueType === 'NUMBER');
  }

  setDefaultValue(element) {
    return element.options.defaultValue;
  }

  getParam() {}
}
const numberRangeServiceImpl = new NumberRangeServiceImpl();
export default numberRangeServiceImpl;
