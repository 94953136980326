import React, { useState, useEffect, useMemo, useContext, forwardRef, useImperativeHandle } from 'react';
import { Select, Tabs, Checkbox, message } from 'antd';
import cls from 'classnames';
import _ from 'lodash';
import { stringify } from 'flatted';
import { http } from '@/lib';
import APIS from '@/apis/api';
import { FIELD_VALUE_TYPE_ICONS } from '@/utils/enum';
import { flattenDataSetFields } from './tree-methods';
import { reverseStr } from '@/utils/utils';
import { LinkageContext } from './ctx-linkage';
import SvgIcon from '@/components/SvgIcon';
import ColumnSelect from './column-select';

const { Option } = Select;
const { TabPane } = Tabs;
function LinkageContent(props, ref) {
  useImperativeHandle(ref, () => ({
    relationFields,
    dataSetFieldMap
  }));
  const { relationChart, charts, activeChart, dataSets } = useContext(LinkageContext);
  const [relationFields, setRelationFields] = useState([]);
  const [activeRelationFieldIdx, setActiveRelationFieldIdx] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState('sameDs');
  const [relationCharts, setRelationCharts] = useState([]);
  const [dataSetFieldMap, setDataSetFieldMap] = useState({}); // 缓存非同数据集字段
  const activeRelationField = useMemo(() => {
    return relationFields[activeRelationFieldIdx];
  }, [activeRelationFieldIdx, stringify(relationFields)]);
  const activeSameDsRelationCharts = useMemo(() => {
    return activeRelationField ? activeRelationField.charts.filter(chart => chart.group === 'sameDs') : [];
  }, [stringify(activeRelationField)]);
  const activeDiffDsRelationCharts = useMemo(() => {
    return activeRelationField ? activeRelationField.charts.filter(chart => chart.group === 'diffDs') : [];
  }, [stringify(activeRelationField)]);
  const activeSameDsCheckedRelationCharts = useMemo(() => {
    return activeSameDsRelationCharts.filter(chart => chart.checked);
  }, [stringify(activeSameDsRelationCharts)]);
  const activeDiffDsCheckedRelationCharts = useMemo(() => {
    return activeDiffDsRelationCharts.filter(chart => chart.checked);
  }, [stringify(activeDiffDsRelationCharts)]);

  useEffect(() => {
    initState();
  }, []);

  const initState = () => {
    const newRelationCharts = charts
      .filter(chart => chart.frontChartId !== activeChart.frontChartId)
      .map(chart => {
        const {
          dataConfig: { dataSetId }
        } = chart;
        const group = dataSetId === activeChart.dataConfig.dataSetId ? 'sameDs' : 'diffDs';
        const result: any = {
          ...chart,
          dataSetName: dataSets.filter(({ key }) => key === dataSetId)[0].value,
          group
        };
        return result;
      });
    const newRelationFields = resolveRelationChart(newRelationCharts);
    const isNewRelationFieldsEmpty = !newRelationFields.length;
    const newRelationField = {
      uniqueId: undefined,
      customName: '',
      charts: _.cloneDeep(newRelationCharts).map(relationChart => ({
        ...relationChart,
        checked: false
      }))
    };

    setDataSetFieldMap(activeChart.advancedConfig.linkageConfig.dataSetFieldMap);
    setRelationCharts(newRelationCharts);
    setRelationFields(isNewRelationFieldsEmpty ? [...relationFields, newRelationField] : newRelationFields);
  };

  const resolveRelationChart = relationCharts => {
    const relationFields = [];
    const relationChartKeys = Object.keys(relationChart);
    if (!relationChartKeys.length) {
      return [];
    }
    relationChartKeys.forEach(key => {
      const [chartId, passiveFieldUniqueId, fieldUniqueId, passiveChartId] = key.split('*-');
      if (relationFields.some(field => field.uniqueId === passiveFieldUniqueId)) {
        const index = relationFields.findIndex(field => field.uniqueId === passiveFieldUniqueId);
        relationFields[index].charts = relationFields[index].charts.map(chart => {
          if (chart.frontChartId === chartId) {
            return {
              ...chart,
              checked: true
            };
          }
          return chart;
        });
      } else {
        relationFields.push({
          uniqueId: passiveFieldUniqueId,
          customName: activeChart.dataConfig.flattenedFields.filter(field =>
            passiveFieldUniqueId.startsWith(field.uniqueId)
          )[0].customName,
          charts: _.cloneDeep(relationCharts).map(relationChart => {
            const result = {
              ...relationChart,
              checked: relationChart.frontChartId === chartId
            };
            if (relationChart.group === 'diffDs') {
              const _dataSetFieldMap = activeChart.advancedConfig.linkageConfig.dataSetFieldMap;
              result.fieldOpts = _dataSetFieldMap.hasOwnProperty(relationChart.dataConfig.dataSetId)
                ? _dataSetFieldMap[relationChart.dataConfig.dataSetId].filter(
                    field => field.attribute.type === 'DIMENSION'
                  )
                : [];
            }
            return result;
          })
        });
      }
    });
    return relationFields;
  };

  const addField = () => {
    const newRelationField = {
      uniqueId: undefined,
      customName: '',
      charts: _.cloneDeep(relationCharts).map(relationChart => ({
        ...relationChart,
        checked: false
      }))
    };
    setRelationFields([...relationFields, newRelationField]);
  };

  const removeField = (ev, index) => {
    ev.stopPropagation();
    const newRelationFields = relationFields.filter((field, fieldIdx) => fieldIdx !== index);
    setRelationFields(newRelationFields);
  };

  const handleRelationFieldChange = (value, option) => {
    setRelationFields(
      relationFields.map((relationField, fieldIdx) => {
        if (fieldIdx === activeRelationFieldIdx) {
          return {
            ...relationField,
            uniqueId: value,
            customName: option.props.title
          };
        }
        return relationField;
      })
    );
  };

  const handleActiveTabKeyChange = activeKey => {
    setActiveTabKey(activeKey);
  };

  const toggleSameDsChart = (frontChartId, checked) => {
    if (existRecycleRelation(frontChartId)) {
      message.warning('存在循环控制!');
      return;
    }
    const newRelationFields = relationFields.map((field, fieldIdx) => {
      if (fieldIdx === activeRelationFieldIdx) {
        return {
          ...field,
          charts: field.charts.map(chart => {
            if (chart.frontChartId === frontChartId) {
              return {
                ...chart,
                checked
              };
            }
            return chart;
          })
        };
      }
      return field;
    });
    setRelationFields(newRelationFields);
  };

  const toggleDiffDsChart = async (frontChartId, checked) => {
    const {
      dataConfig: { dataSetId }
    } = activeDiffDsRelationCharts.filter(chart => chart.frontChartId === frontChartId)[0];
    const isDataSetFieldsEmpty = !dataSetFieldMap.hasOwnProperty(dataSetId);
    let _flattenedFields;
    const newDataSetFieldMap = _.cloneDeep(dataSetFieldMap);
    if (isDataSetFieldsEmpty) {
      const res = await fetchDataSetFields(dataSetId);
      const { dimensions, measures } = res.data;
      const newDataSetFields = [...dimensions, ...measures];
      _flattenedFields = flattenDataSetFields(newDataSetFields);
      newDataSetFieldMap[dataSetId] = _flattenedFields;
    } else {
      _flattenedFields = dataSetFieldMap[dataSetId];
    }
    setDataSetFieldMap(newDataSetFieldMap);
    updateRelationFieldsHandler(frontChartId, checked, _flattenedFields);
  };

  const updateRelationFieldsHandler = (frontChartId, checked, flattenedFields) => {
    const newRelationFields = relationFields.map((field, fieldIdx) => {
      if (fieldIdx === activeRelationFieldIdx) {
        return {
          ...field,
          charts: field.charts.map(chart => {
            if (chart.frontChartId === frontChartId) {
              return {
                ...chart,
                checked,
                fieldOpts: flattenedFields
                  .filter(field => field.attribute.type === 'DIMENSION')
                  .map(field => ({ ...field, checked: false }))
              };
            }
            return chart;
          })
        };
      }
    });
    setRelationFields(newRelationFields);
  };

  const toggleAll = async (group, checked) => {
    if (group === 'sameDs') {
      if (activeRelationField.charts.some(chart => existRecycleRelation(chart.frontChartId))) {
        message.warning('存在循环控制!');
      }
    }
    let promises;
    let executeResult;
    const newDataSetFieldMap = _.cloneDeep(dataSetFieldMap);
    const diffDataSetIds = Object.keys(newDataSetFieldMap);
    if (activeDiffDsRelationCharts.some(chart => !diffDataSetIds.includes(chart.dataConfig.dataSetId))) {
      promises = activeDiffDsRelationCharts
        .filter(chart => !diffDataSetIds.includes(chart.dataConfig.dataSetId))
        .map(chart => chart.dataConfig.dataSetId)
        .map(dataSetId => {
          return new Promise((resolve, reject) => {
            return fetchDataSetFields(dataSetId).then(res => {
              resolve({
                dataSetId,
                data: res.data
              });
            });
          });
        });
      executeResult = await Promise.all(promises);
      for (let item of executeResult) {
        const {
          dataSetId,
          data: { dimensions, measures }
        } = item;
        if (newDataSetFieldMap[dataSetId]) {
          continue;
        }
        newDataSetFieldMap[dataSetId] = flattenDataSetFields([...dimensions, ...measures]);
      }
    }
    const newRelationFields = relationFields.map((field, fieldIdx) => {
      if (fieldIdx === activeRelationFieldIdx) {
        return {
          ...field,
          charts: field.charts.map(chart => {
            if (chart.group === group) {
              const result = {
                ...chart,
                checked
              };
              if (group === 'sameDs' && existRecycleRelation(chart.frontChartId)) {
                result.checked = false;
              }
              if (group === 'diffDs') {
                result.fieldOpts = newDataSetFieldMap[chart.dataConfig.dataSetId].filter(
                  field => field.attribute.type === 'DIMENSION'
                );
              }
              return result;
            }
            return chart;
          })
        };
      }
      return field;
    });
    setDataSetFieldMap(newDataSetFieldMap);
    setRelationFields(newRelationFields);
  };

  /**
   * 是否存在循环控制
   */
  const existRecycleRelation = chartId => {
    const { uniqueId } = activeRelationField;
    const activeFieldRelationChartKey = [chartId, uniqueId, uniqueId, activeChart.frontChartId].join('*-');
    const chart = charts.find(chart => chart.frontChartId === chartId);

    return Object.keys(chart.advancedConfig.linkageConfig.relationChart).includes(
      reverseStr(activeFieldRelationChartKey, '*-')
    );
  };

  const handleColCheckedChange = (chartId, col, checked) => {
    const newRelationFields = relationFields.map((field, fieldIdx) => {
      if (fieldIdx === activeRelationFieldIdx) {
        return {
          ...field,
          charts: field.charts.map(chart => {
            if (chart.frontChartId === chartId) {
              return {
                ...chart,
                fieldOpts: chart.fieldOpts.map(option => {
                  if (option.uniqueId === col.uniqueId) {
                    return {
                      ...option,
                      checked
                    };
                  }
                  return option;
                })
              };
            }
            return chart;
          })
        };
      }
    });
    const newDataSetFieldMap = _.cloneDeep(dataSetFieldMap);
    const {
      dataConfig: { dataSetId }
    } = charts.find(chart => chart.frontChartId === chartId);
    newDataSetFieldMap[dataSetId] = newDataSetFieldMap[dataSetId].map(field => {
      if (field.uniqueId === col.uniqueId) {
        return {
          ...field,
          checked
        };
      }
      return field;
    });
    setDataSetFieldMap(newDataSetFieldMap);
    setRelationFields(newRelationFields);
  };

  const fetchDataSetFields = dataSetId => {
    return http.post(APIS.DataBoardFindDataSetFields, { id: dataSetId });
  };
  /* console.log(
    'render LinkageContent: ',
    relationCharts,
    activeRelationField,
    activeSameDsRelationCharts,
    activeDiffDsRelationCharts
  ); */
  return (
    <div className="linkage-wrapper">
      <div className="main-container">
        <div className="item-container">
          <div className="add-container">
            <span className="item-title" title="请选择需要绑定的字段">
              请选择需要绑定的字段
            </span>
            <i className="svg-icon-wrapper icon-add-circle" onClick={addField}>
              <SvgIcon iconClass="add-circle" />
            </i>
          </div>
          <div className="selecter-container">
            {relationFields.map((relationField, index) => (
              <div
                className={cls('item-content', {
                  active: index === activeRelationFieldIdx
                })}
                key={`${relationField.uniqueId}-${index}`}
              >
                <div className="selected-box" onClick={() => setActiveRelationFieldIdx(index)}>
                  <div className="selected-item">
                    <Select
                      size="small"
                      dropdownClassName="selected-item-dropdown"
                      showSearch
                      filterOption={(inputValue, option) => {
                        return option.props.children[1].indexOf(inputValue) > -1;
                      }}
                      value={relationField.uniqueId}
                      onFocus={() => setActiveRelationFieldIdx(index)}
                      onChange={handleRelationFieldChange}
                      placeholder="请选择被关联维度字段"
                    >
                      {activeChart.dataConfig.dimensionFields.map(option => (
                        <Option
                          key={option.uniqueId}
                          value={option.uniqueId}
                          title={option.customName}
                          disabled={relationFields.includes(option.uniqueId)}
                        >
                          <i className="svg-icon-wrapper icon-field-value-type">
                            <SvgIcon iconClass={FIELD_VALUE_TYPE_ICONS[option.attribute.valueType]} />
                          </i>
                          {option.customName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <i className="svg-icon-wrapper icon-del" onClick={ev => removeField(ev, index)}>
                    <SvgIcon iconClass="delete-1" />
                  </i>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="chart-container">
          <div className="main-chart">
            <Tabs
              animated={false}
              activeKey={activeTabKey}
              tabBarExtraContent={<span className="tab-link-chart">关联图表</span>}
              onChange={handleActiveTabKeyChange}
            >
              <TabPane key="sameDs" tab="同数据集">
                <div className="main-chart-body">
                  <div className="main-chart-box">
                    <div className="sameDs">
                      <p className="chart-num">
                        同数据集(已关联{activeSameDsCheckedRelationCharts.length}个图表,共
                        {activeSameDsRelationCharts.length}个图表)
                      </p>
                      {!activeSameDsRelationCharts.length && (
                        <div className="no-chart">
                          <span>没有图表</span>
                        </div>
                      )}
                      {!!activeSameDsRelationCharts.length && (
                        <>
                          <div className="ds-header">
                            <Checkbox
                              checked={activeSameDsRelationCharts.length === activeSameDsCheckedRelationCharts.length}
                              disabled={!activeRelationField.uniqueId}
                              onChange={ev => toggleAll('sameDs', ev.target.checked)}
                            >
                              全选
                            </Checkbox>
                          </div>
                          <ul className="chart-column-box">
                            {activeSameDsRelationCharts.map(chart => (
                              <li className="chart-column" key={chart.frontChartId}>
                                <Checkbox
                                  checked={chart.checked}
                                  disabled={!activeRelationField.uniqueId}
                                  onChange={ev => toggleSameDsChart(chart.frontChartId, ev.target.checked)}
                                >
                                  <span className="icontext">
                                    <i
                                      className={cls(['component-type-icon', `component-type-${chart.chartType}`])}
                                    ></i>
                                    <span className="chart-name">{chart.styleConfig.title}</span>
                                  </span>
                                </Checkbox>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane key="diffDs" tab="非同数据集">
                <div className="main-chart-body">
                  <div className="main-chart-box">
                    <div className="diffDs">
                      <p className="chart-num">
                        非同数据集(已关联{activeDiffDsCheckedRelationCharts.length}个图表,共
                        {activeDiffDsRelationCharts.length}个图表)
                      </p>
                      {!activeDiffDsRelationCharts.length && (
                        <div className="no-chart">
                          <span>没有图表</span>
                        </div>
                      )}
                      {!!activeDiffDsRelationCharts.length && (
                        <>
                          <div className="ds-header">
                            <Checkbox
                              checked={activeDiffDsRelationCharts.length === activeDiffDsCheckedRelationCharts.length}
                              disabled={!activeRelationField.uniqueId}
                              onChange={ev => toggleAll('diffDs', ev.target.checked)}
                            >
                              全选
                            </Checkbox>
                          </div>
                          <ul className="chart-column-box">
                            {activeDiffDsRelationCharts.map((diffDsChart, chartIdx) => (
                              <li className="diff-chart-column" key={diffDsChart.frontChartId}>
                                <Checkbox
                                  checked={diffDsChart.checked}
                                  disabled={!activeRelationField.uniqueId}
                                  onChange={ev => toggleDiffDsChart(diffDsChart.frontChartId, ev.target.checked)}
                                >
                                  <span className="icontext">
                                    <i
                                      className={cls([
                                        'component-type-icon',
                                        `component-type-${diffDsChart.chartType}`
                                      ])}
                                    ></i>
                                    <span className="chart-name">{diffDsChart.styleConfig.title}</span>
                                  </span>
                                </Checkbox>
                                {diffDsChart.checked && (
                                  <div>
                                    <div className="cube-box">
                                      <div className="cube-label">
                                        <i className="svg-icon-wrapper icon-cube">
                                          <SvgIcon iconClass="cube" />
                                        </i>
                                        <span>{diffDsChart.dataSetName}</span>
                                      </div>
                                      <ColumnSelect
                                        chartId={diffDsChart.frontChartId}
                                        index={chartIdx}
                                        columns={diffDsChart.fieldOpts}
                                        onColCheckedChange={handleColCheckedChange}
                                      />
                                    </div>
                                  </div>
                                )}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(LinkageContent);
