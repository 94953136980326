import _ from 'lodash'
import React from 'react'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { IUdFormItem, UdFormItem } from '..'
import { IUdFormItemProps } from '../ui/UdFormItem/UdFormItem'
import { UdFormInner } from '../ui/UdForm'

let formUtils = {

  handleValues: (values: any, keepEmptyItem = false) => {
    let items = {}
    for (const id in values) {
      let keys = id.split('|')
      if (keys.length > 1) {
        for (let i = 0; i < keys.length; i++) {
          if (keepEmptyItem || (values[id] && values[id][i] != null && values[id][i] !== '')) {
            if (_.isString(values[id][i])) {
              items[keys[i]] = values[id][i].trim()
            } else {
              items[keys[i]] = values[id][i]
            }
          }
        }
      } else {
        if (keepEmptyItem || (values[id] != null && values[id] !== '')) {
          if (_.isString(values[id])) {
            items[id] = values[id].trim()
          } else {
            items[id] = values[id]
          }
        }
      }
    }
    return items
  },

  getValues: (form: WrappedFormUtils, keepEmptyItem: boolean = false) => {
    let values = form.getFieldsValue()
    return formUtils.handleValues(values, keepEmptyItem)
  },

  setValues: (form: WrappedFormUtils, items: IUdFormItem[], values: { [key: string]: any }) => {
    let result: any = {}
    if (values && items && items.length > 0) {
      for (const item of items) {
        if (_.isArray(item.id)) {
          let key = item.id.join('|')
          let val = []
          for (const id of item.id) {
            val.push(values[id])
          }
          result[key] = val
        } else {
          result[item.id] = values[item.id]
        }
      }
    }
    form.setFieldsValue(result)
  },
  getInitialValue: (items: IUdFormItem[]) => {
    let result: any = {}
    if (items && items.length > 0) {
      for (let item of items) {
        let key: string = ''
        if (_.isArray(item.id)) {
          key = item.id.join('|')
        } else {
          key = item.id
        }
        let initValue: any = undefined
        if (item.initialValue) {
          initValue = item.initialValue
        }
        if (item.decoratorOptions && item.decoratorOptions.initialValue) {
          initValue = item.decoratorOptions.initialValue
        }
        result[key] = initValue
      }
    }
    return result
  },

  buildFields: (items: IUdFormItem[] | IUdFormItem, form: WrappedFormUtils, props: Partial<IUdFormItemProps> = {} as any) => {
    const children = []
    if (form == null) {
      return children
    }
    const { getFieldDecorator } = form
    const extendProps = _.extend({}, {
      wrapperCol: UdFormInner.defaultProps.wrapperCol,
      labelCol: UdFormInner.defaultProps.labelCol
    }, props)
    let fields = _.isArray(items) ? items : [items]
    for (const item of fields) {
      let itemProps = {
        form,
        getFieldDecorator,
        ...extendProps,
        ...item
      }
      children.push(
        <UdFormItem key={itemProps.id.toString()} {...itemProps} />
      )
    }
    return children
  }

}

export { formUtils }
