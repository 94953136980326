import React, { useState, useEffect, useMemo } from 'react';
import { Input, Checkbox } from 'antd';
import cls from 'classnames';
import _ from 'lodash';
import { FIELD_VALUE_TYPE_ICONS } from '@/utils/enum';
import SvgIcon from '@/components/SvgIcon';
import './style/column-select.less';

const { Search } = Input;
function ColumnSelect({ chartId, index, columns, onColCheckedChange }) {
  const [hide, setHide] = useState(true);
  const [inputVal, setInputVal] = useState('');
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [colSelectBoxStyle, setColSelectBoxStyle] = useState({
    top: 0,
    left: 0
  });
  const checkedColumns = useMemo(() => {
    return columns.filter(col => col.checked);
  }, [JSON.stringify(columns)]);

  useEffect(() => {
    setFilteredColumns(_.cloneDeep(columns).filter(col => col.customName.includes(inputVal)));
    calColumnSelectBoxPos();
  }, [index, JSON.stringify(columns)]);

  const calColumnSelectBoxPos = () => {
    const { height: screenH } = document.documentElement.getBoundingClientRect();
    const { top, left, height } = document.querySelector(`#showColumnSelect-${index}`).getBoundingClientRect();
    const columnSelectBoxHeight = 192;
    let _top = 0;
    if (screenH - top - height >= columnSelectBoxHeight) {
      _top = top + height;
    } else {
      _top = top - columnSelectBoxHeight;
    }
    setColSelectBoxStyle({
      top: _top,
      left
    });
  };

  const toggleHide = () => {
    setHide(!hide);
  };

  const handleClickOutside = () => {
    setHide(true);
  };

  const handleSearch = value => {
    setInputVal(value);
    setFilteredColumns(JSON.parse(JSON.stringify(columns)).filter(col => col.customName.includes(value)));
  };

  return (
    <div className="cube-column-item" id={`showColumnSelect-${index}`}>
      <div className="selected-column-item" onClick={toggleHide}>
        <span className="selected-column-item-placeholder">
          <span>选择字段</span>
        </span>
        {checkedColumns.map(checkedCol => (
          <span className="checked-column" key={checkedCol.uniqueId}>
            <i className="svg-icon-wrdapper icon-dim-color icon-field-value-type">
              <SvgIcon iconClass={FIELD_VALUE_TYPE_ICONS[checkedCol.attribute.valueType]} />
            </i>
            {checkedCol.customName}
          </span>
        ))}
        <i
          className={cls('svg-icon-wrapper icon-arrow-down', {
            hide: !hide
          })}
        >
          <SvgIcon iconClass="arrow-down" />
        </i>
      </div>
      <div
        className={cls('select-mask', {
          hide: hide
        })}
        onClick={handleClickOutside}
      ></div>
      <ul
        className={cls('column-select-box', {
          hide: hide
        })}
        style={colSelectBoxStyle}
      >
        <li className="select-search-box">
          <Search placeholder="请输入搜索关键字" onSearch={handleSearch} />
        </li>
        <div className="item-box">
          <div>
            {filteredColumns.map(col => (
              <li className="column-select-item" key={col.uniqueId}>
                <Checkbox checked={col.checked} onChange={ev => onColCheckedChange(chartId, col, ev.target.checked)}>
                  <span className="column-select-name">
                    <i className="svg-icon-wrapper icon-dim-color icon-field-value-type">
                      <SvgIcon iconClass={FIELD_VALUE_TYPE_ICONS[col.attribute.valueType]} />
                    </i>
                    {col.customName}
                  </span>
                </Checkbox>
              </li>
            ))}
          </div>
        </div>
      </ul>
    </div>
  );
}

export default ColumnSelect;
