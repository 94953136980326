import { WidgetService } from '../service/widget-service';
import { Base } from '../data';

const genDialogPanel = () => ({
  options: {
    attrs: {
      multiple: false,
      placeholder: 'ibSelectTree.placeholder',
      enableRange: false,
      chartIds: [],
      parameters: [],
      dataSetId: '',
      fieldUniqueId: '',
      data: [],
      dragItems: [],
      sort: {}
    },
    value: '',
    defaultValue: '',
    manualModify: false
  },
  label: 'ibSelectTree.label',
  refresh: false,
  defaultClass: 'text-filter',
  component: 'ib-select-tree',
  miniSizeX: 1,
  miniSizeY: 1
});

const genDrawPanel = () => ({
  type: 'custom',
  style: {
    showTitle: false,
    mainTitle: '',
    labelPos: 'top',
    fontSize: 14,
    color: '#333',
    bold: false,
    italic: false,
    letterSpacing: 0
  },
  component: 'ib-select-tree'
});

class TextSelectTreeServiceImpl extends WidgetService implements Base {
  public filterDialog: boolean;
  public showSwitch: boolean;
  public isTree: boolean;
  constructor(options = {}) {
    Object.assign(options, { name: 'textSelectTreeWidget' });
    super(options);
    this.filterDialog = true;
    this.showSwitch = true;
    this.isTree = true;
  }

  initFilterDialog() {
    return genDialogPanel();
  }

  initDrawPanel() {
    return genDrawPanel();
  }

  filterField(fields) {
    return fields.filter(field => field.attribute.valueType === 'TEXT');
  }

  setDefaultValue(element) {
    return element.options.defaultValue;
  }

  optionData() {}

  isSortWidget() {
    return true;
  }
}

const textSelectTreeServiceImpl = new TextSelectTreeServiceImpl();
export default textSelectTreeServiceImpl;
