import { dateFormat } from '@/pages/dataBoard/components/ConfigCompareFieldModalContent';

export const DEFAULT_COLOR_CASE = {
  value: 'default',
  colors: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
  // alpha: 100
};

export const DEFAULT_SIZE = {
  barDefault: true,
  barWidth: 40,
  barGap: 0.4,
  lineWidth: 2,
  lineType: 'solid',
  lineSymbol: 'circle',
  lineSymbolSize: 4,
  lineSmooth: true,
  lineArea: false,
  pieInnerRadius: 0,
  pieOuterRadius: 80,
  pieRoseType: 'radius',
  pieRoseRadius: 5,
  funnelWidth: 80,
  radarShape: 'polygon',
  radarSize: 80,
  gaugeMin: 0,
  gaugeMax: 100,
  gaugeStartAngle: 225,
  gaugeEndAngle: -45,
  scatterSymbol: 'circle',
  scatterSymbolSize: 20,
  treemapWidth: 80,
  treemapHeight: 80,
  dimensionFontSize: 18,
  quotaFontSize: 18,
  spaceSplit: 10,
  dimensionShow: true,
  tableHeaderFontSize: 12,
  tableCellFontSize: 12,
  tableHeaderHeight: 36,
  tableCellHeight: 36
};

export const DEFAULT_LABEL = {
  show: false,
  position: 'top',
  color: '#909399',
  fontSize: '10',
  labelLine: {
    show: true
  }
};

export const DEFAULT_TITLE_STYLE = {
  show: true,
  text: '未命名图表',
  textStyle: {
    fontSize: 18,
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  hPosition: 'left',
  vPosition: 'top'
};

export const DEFAULT_LEGEND_STYLE = {
  show: true,
  hPosition: 'right',
  vPosition: 'top',
  orient: 'horizontal',
  icon: 'circle',
  textStyle: {
    fontSize: '12',
    color: '#999'
  }
};

export const DEFAULT_XAXIS_STYLE = {
  show: true,
  position: 'bottom',
  name: '',
  nameTextStyle: {
    width: 64,
    overflow: 'truncate',
    fontSize: 12,
    color: '#ddd'
  },
  axisLabel: {
    show: true,
    color: '#aaa',
    fontSize: 12,
    rotate: 0
  },
  splitLine: {
    show: false,
    lineStyle: {
      color: '#e6e6e6',
      width: 1,
      type: 'solid'
    }
  }
};

export const DEFAULT_YAXIS_STYLE = {
  show: true,
  position: 'left',
  name: '',
  nameTextStyle: {
    fontSize: 12,
    color: '#ddd'
  },
  axisLabel: {
    show: true,
    fontSize: 12,
    color: '#aaa',
    rotate: 0
  },
  splitLine: {
    show: false,
    lineStyle: {
      color: '#e6e6e6',
      width: 1,
      type: 'solid'
    }
  }
};

export const DEFAULT_SPLIT = {
  name: {
    show: true,
    color: '#999999',
    fontSize: '12'
  },
  splitNumber: 5,
  axisLine: {
    show: true,
    lineStyle: {
      color: '#999999',
      width: 1,
      type: 'solid'
    }
  },
  axisTick: {
    show: false,
    length: 5,
    lineStyle: {
      color: '#999999',
      width: 1,
      type: 'solid'
    }
  },
  axisLabel: {
    show: false,
    rotate: 0,
    margin: 8,
    color: '#999999',
    fontSize: '12'
  },
  splitLine: {
    show: true,
    lineStyle: {
      color: '#999999',
      width: 1,
      type: 'solid'
    }
  },
  splitArea: {
    show: true
  }
};

export const COLOR_PANEL = [
  '#ff4500',
  '#ff8c00',
  '#ffd700',
  '#90ee90',
  '#00ced1',
  '#1e90ff',
  '#c71585',
  '#999999',
  '#000000',
  '#FFFFFF'
];

export const generateFormModel = () => {
  return {
    valueCategory: 'STATIC_MULTIVALUED',
    staticValues: [{ value: '', validateStatus: 'success', errorMsg: dateFormat }],
    rangeValues: [{ start: '', end: '', validateStatus: 'success', errorMsg: dateFormat }],
    dynamicValue: {
      baseValue: {
        value: ''
      },
      increment: {
        value: ''
      },
      diffType: 'minus'
    }
  };
};

export const DEFAULT_COMPARE_FIELD_CONFIG = {
  fieldSelected: null,
  visibleModalCompareFieldConfig: false,
  selectedFieldValues: [],
  formData: generateFormModel()
};
