import React, { FC, useState, useEffect, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { Popover, Select, Radio, Button, Slider, InputNumber, Row, Col } from 'antd';
import { hexToRGBA, isHexColor } from '@/utils/utils';
import { DEFAULT_COLOR_CASE, COLOR_PANEL } from '../../../chart';
import ColorPicker from '@/components/FormComponents/Color';
import './index.less';

const { Option } = Select;
const ColorSelector: FC<any> = ({ backgroundColor, activeColorCase, onColorChange, hideBgColorFill }) => {
  const [colorCases, setColorCases] = useState([
    {
      name: '默认',
      value: 'default',
      colors: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
    },
    {
      name: '复古',
      value: 'retro',
      colors: ['#0780cf', '#765005', '#fa6d1d', '#0e2c82', '#b6b51f', '#da1f18', '#701866', '#f47a75', '#009db2']
    },
    {
      name: '淡雅',
      value: 'elegant',
      colors: ['#95a2ff', '#fa8080', '#ffc076', '#fae768', '#87e885', '#3cb9fc', '#73abf5', '#cb9bff', '#434348']
    },
    {
      name: '未来',
      value: 'future',
      colors: ['#63b2ee', '#76da91', '#f8cb7f', '#f89588', '#7cd6cf', '#9192ab', '#7898e1', '#efa666', '#eddd86']
    },
    {
      name: '渐变',
      value: 'gradual',
      colors: ['#71ae46', '#96b744', '#c4cc38', '#ebe12a', '#eab026', '#e3852b', '#d85d2a', '#ce2626', '#ac2026']
    },
    {
      name: '简洁',
      value: 'simple',
      colors: ['#929fff', '#9de0ff', '#ffa897', '#af87fe', '#7dc3fe', '#bb60b2', '#433e7c', '#f47a75', '#009db2']
    },
    {
      name: '商务',
      value: 'business',
      colors: ['#194f97', '#555555', '#bd6b08', '#00686b', '#c82d31', '#625ba1', '#898989', '#9c9800', '#007f54']
    },
    {
      name: '柔和',
      value: 'gentle',
      colors: ['#5b9bd5', '#ed7d31', '#70ad47', '#ffc000', '#4472c4', '#91d024', '#b235e6', '#02ae75', '#5b9bd5']
    },
    {
      name: '科技',
      value: 'technology',
      colors: ['#05f8d6', '#0082fc', '#fdd845', '#22ed7c', '#09b0d3', '#1d27c9', '#f9e264', '#f47a75', '#009db2']
    },
    {
      name: '明亮',
      value: 'light',
      colors: ['#884898', '#808080', '#82ae46', '#00a3af', '#ef8b07', '#007bbb', '#9d775f', '#fae800', '#5f9b3c']
    },
    {
      name: '经典',
      value: 'classical',
      colors: ['#007bbb', '#ffdb4f', '#dd4b4b', '#2ca9e1', '#ef8b07', '#4a488e', '#82ae46', '#dd4b4b', '#bb9581']
    },
    {
      name: '清新',
      value: 'fresh',
      colors: ['#5f9b3c', '#75c24b', '#83d65f', '#aacf53', '#c7dc68', '#d8e698', '#e0ebaf', '#bbc8e6', '#e5e5e5']
    },
    {
      name: '活力',
      value: 'energy',
      colors: ['#ef8b07', '#2a83a2', '#f07474', '#c55784', '#274a78', '#7058a3', '#0095d9', '#75c24b', '#808080']
    },
    {
      name: '火红',
      value: 'red',
      colors: ['#ff0000', '#ef8b07', '#4c6cb3', '#f8e944', '#69821b', '#9c5ec3', '#00ccdf', '#f07474', '#bb9581']
    },
    {
      name: '轻快',
      value: 'fast',
      colors: ['#fae800', '#00c039', '#0482dc', '#bb9581', '#ff7701', '#9c5ec3', '#00ccdf', '#00c039', '#ff7701']
    },
    {
      name: '灵动',
      value: 'spiritual',
      colors: ['#00a3af', '#4da798', '#57baaa', '#62d0bd', '#6ee4d0', '#86e7d6', '#aeede1', '#bde1e6', '#e5e5e5']
    }
  ]);
  const [colorForm, setColorForm] = useState(cloneDeep(DEFAULT_COLOR_CASE));
  const [customColor, setCustomColor] = useState(null);
  const [colorIndex, setColorIndex] = useState(0);
  const [predefineColors, setPredefineColors] = useState(COLOR_PANEL);
  const [bgColor, setBgColor] = useState('');

  useEffect(() => {
    init();
  }, [activeColorCase]);

  const init = () => {
    const { name, value, colors } = cloneDeep(activeColorCase || colorCases[0]);
    setCustomColor(isHexColor(colors[0]) ? hexToRGBA(colors[0]) : colors[0]);
    setColorIndex(0);
    setBgColor(isHexColor(backgroundColor) ? hexToRGBA(backgroundColor) : backgroundColor);
    setColorForm({
      ...colorForm,
      value,
      colors
    });
  };
  const renderColorCase = () => {
    return (
      <div className="color-case-wrapper">
        {colorForm.colors.map((color, index) => (
          <span key={index} style={{ background: color }}></span>
        ))}
      </div>
    );
  };
  const renderColorCaseContent = useCallback(() => {
    return (
      <div className="pop-color-case">
        <div className="attr-item">
          <label className="attr-item-label">系统方案</label>
          <div className="attr-item-content">
            <Select
              size="small"
              dropdownClassName="dropdown-color-case"
              value={colorForm.value}
              onChange={handleColorOptionChange}
            >
              {colorCases.map(option => (
                <Option key={option.value} label={option.name} value={option.value}>
                  <div className="select-colors-wrapper">
                    {option.colors.map((color, index) => (
                      <span key={index} style={{ background: color }}></span>
                    ))}
                  </div>
                  <span className="option-name">{option.name}</span>
                </Option>
              ))}
            </Select>
            <Button type="link" size="small" onClick={resetCustomColor}>
              重置
            </Button>
          </div>
        </div>
        <div className="attr-item">
          <label className="attr-item-label">自定义</label>
          <div className="attr-item-content">
            <Radio.Group className="color-type" buttonStyle="solid" value={colorIndex} onChange={switchColor}>
              {colorForm.colors.map((color, index) => (
                <Radio key={index} value={index}>
                  <span style={{ display: 'inline-block', width: '20px', height: '20px', background: color }}></span>
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
        <div className="attr-item" style={{ marginTop: '4px' }}>
          <label className="attr-item-label"></label>
          <div className="attr-item-content">
            <ColorPicker value={customColor} onChange={switchColorCase} />
          </div>
        </div>
      </div>
    );
  }, [customColor]);

  const handleColorOptionChange = (selectedVal?: string) => {
    const items = colorCases.filter(item => item.value === (selectedVal ? selectedVal : colorForm.value));
    const colors = cloneDeep(items[0].colors);
    const newColorForm = {
      ...colorForm,
      value: selectedVal || colorForm.value,
      colors
    };
    setColorForm({
      ...colorForm,
      ...newColorForm
    });
    setCustomColor(isHexColor(colors[0]) ? hexToRGBA(colors[0]) : colors[0]);
    setColorIndex(0);
    changeColorCase(newColorForm);
  };

  const switchColor = ev => {
    const activeIndex = ev.target.value;
    setColorIndex(activeIndex);
    setCustomColor(
      isHexColor(colorForm.colors[activeIndex])
        ? hexToRGBA(colorForm.colors[activeIndex])
        : colorForm.colors[activeIndex]
    );
  };

  const switchColorCase = rgba => {
    const newColorForm = {
      ...colorForm,
      colors: colorForm.colors.map((c, index) => {
        if (index === colorIndex) return rgba;
        return c;
      })
    };
    setColorForm({
      ...colorForm,
      ...newColorForm
    });
    setCustomColor(rgba);
    changeColorCase(newColorForm);
  };

  const resetCustomColor = () => {
    handleColorOptionChange();
  };

  const changeColorCase = payload => {
    onColorChange && onColorChange('color', payload);
  };

  const handleAlphaValChange = val => {
    const newColorForm = {
      ...colorForm,
      alpha: isNaN(val) || !val ? 0 : val
    };
    setColorForm({
      ...colorForm,
      ...newColorForm
    });
    changeColorCase(newColorForm);
  };

  const handleBgColorChange = rgba => {
    setBgColor(rgba);
    onColorChange && onColorChange('backgroundColor', rgba);
  };
  // console.log('ColorSelector render: ', bgColor);
  return (
    <div className="color-selector-wrapper">
      <div className="attr-item">
        <label className="attr-item-label">配色方案</label>
        <div className="attr-item-content">
          <Popover placement="bottom" trigger="click" content={renderColorCaseContent()}>
            {renderColorCase()}
          </Popover>
        </div>
      </div>
      {!hideBgColorFill && (
        <div className="attr-item">
          <label className="attr-item-label">背景填充</label>
          <div className="attr-item-content">
            <ColorPicker value={bgColor} onChange={handleBgColorChange} theme="dark" />
          </div>
        </div>
      )}
      {/* <div className="attr-item">
        <label className="attr-item-label">不透明度</label>
        <div className="attr-item-content">
          <Row>
            <Col span={16}>
              <Slider
                min={0}
                max={100}
                step={1}
                value={typeof colorForm.alpha === 'number' ? colorForm.alpha : 0}
                onChange={handleAlphaValChange}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                min={0}
                max={100}
                step={1}
                value={colorForm.alpha}
                onChange={handleAlphaValChange}
                style={{ marginLeft: 16 }}
              />
            </Col>
          </Row>
        </div>
      </div> */}
    </div>
  );
};

export default ColorSelector;
