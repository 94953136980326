import dayjs from 'dayjs';
import { WidgetService } from '../service/widget-service';
import { Base } from '../data';

let DATE_FORMAT = 'YYYY-MM-DD';
const genDialogPanel = () => ({
  options: {
    attrs: {
      type: 'date',
      placeholder: 'ibdate.placeholder',
      enableRange: false,
      chartIds: [],
      dataSetId: '',
      fieldUniqueId: '',
      dragItems: [],
      default: {
        isDynamic: false,
        dkey: 0,
        dynamicPrefix: 1,
        dynamicInfill: 'day',
        dynamicSuffix: 'before',
        radioOptions: [
          { key: 'fix', value: false, text: 'dynamic.time.fix' },
          { key: 'dynamic', value: true, text: 'dynamic.time.dynamic' }
        ],
        relativeOptions: [
          { key: 'today', value: 0, text: 'dynamic.time.today' },
          { key: 'yesterday', value: 1, text: 'dynamic.time.yesterday' },
          { key: 'firstOfMonth', value: 2, text: 'dynamic.time.firstOfMonth' },
          { key: 'firstOfYear', value: 4, text: 'dynamic.time.firstOfYear' },
          { key: 'custom', value: 3, text: 'dynamic.time.custom' }
        ],
        custom: {
          unitsOptions: [
            { value: 'day', text: 'dynamic.time.date' },
            { value: 'week', text: 'dynamic.time.week' },
            { value: 'month', text: 'dynamic.time.month' },
            { value: 'year', text: 'dynamic.time.year' }
          ],
          limits: [1, 12]
        },
        fixedValue: null
      },
      showTime: false,
      accuracy: 'HH:mm'
    },
    value: '',
    manualModify: false
  },
  label: 'ibdate.label',
  defaultClass: 'time-filter',
  component: 'ib-date',
  miniSizeX: 1,
  miniSizeY: 1
});

const genDrawPanel = () => ({
  type: 'custom',
  style: {
    showTitle: false,
    mainTitle: '',
    labelPos: 'top',
    fontSize: 14,
    color: '#333',
    bold: false,
    italic: false,
    letterSpacing: 0
  },
  component: 'ib-date'
});

class TimeDateServiceImpl extends WidgetService implements Base {
  public filterDialog: boolean;
  public showSwitch: boolean;
  constructor(options = {}) {
    Object.assign(options, {
      name: 'timeDateWidget'
    });
    super(options);
    this.filterDialog = true;
    this.showSwitch = false;
  }

  initFilterDialog() {
    return genDialogPanel();
  }

  initDrawPanel() {
    return genDrawPanel();
  }

  filterField(fields) {
    return fields.filter(field => field.attribute.valueType === 'DATE');
  }

  getDefaultSetting() {
    const { options } = genDialogPanel();
    return options.attrs.default;
  }

  customValue() {
    return 3;
  }

  dynamicDateFormNow(formData) {
    if (formData === null || typeof formData === 'undefined' || !formData.isDynamic) return null;

    switch (formData.dkey) {
      case 0:
        return dayjs().format(DATE_FORMAT);
      case 1:
        return dayjs().subtract(1, 'day').format(DATE_FORMAT);
      case 2:
        return dayjs().startOf('month').format(DATE_FORMAT);
      case 4:
        return dayjs().startOf('year').format(DATE_FORMAT);
      case 3: {
        const dynamicPrefix = parseInt(formData.dynamicPrefix);
        const dynamicInfill = formData.dynamicInfill;
        const dynamicSuffix = formData.dynamicSuffix;

        return dayjs() // eslint-disable-next-line no-unexpected-multiline
          [dynamicSuffix === 'before' ? 'subtract' : 'add'](dynamicPrefix, dynamicInfill)
          .format(DATE_FORMAT);
      }
    }
  }

  setDefaultValue(element) {
    if (element.options.attrs.default && element.options.attrs.default.isDynamic) {
      return this.formatValues(this.dynamicDateFormNow(element.options.attrs.default), element);
    }
    return this.formatValues(element.options.attrs.default.fixedValue || '', element);
  }

  formatValues(value, element) {
    const defaultRangeTime = ['00:00:00', '23:59:59'];

    if (element.options.attrs.showTime) {
      const accuracy = element.options.attrs.accuracy;
      const accuracyLen = accuracy.split(':').length;
      const time = defaultRangeTime[0].split(':').slice(0, accuracyLen).join(':');
      return [value.indexOf(':') > -1 ? value.split(' ')[0] : value, time].join(' ');
    }
    return value.split(' ')[0];
  }

  isTimeWidget() {
    return true;
  }

  isParamWidget() {
    return true;
  }
}

const timeDateServiceImpl = new TimeDateServiceImpl();
export default timeDateServiceImpl;
