import React, { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';

const { TextArea } = Input;
function ModalMarkContent({ visible, content, onOk, onCancel }) {
  const [formData, setFormData] = useState({
    content: ''
  });

  useEffect(() => {
    setFormData({
      content
    });
  }, []);

  const handleOk = () => {
    onOk(formData.content);
  };

  return (
    <Modal visible={visible} title="备注内容" width={610} maskClosable={false} onCancel={onCancel} onOk={handleOk}>
      <TextArea
        rows={4}
        placeholder="请输入备注"
        value={formData.content}
        onChange={ev => setFormData({ ...formData, content: ev.target.value })}
      />
    </Modal>
  );
}

export default ModalMarkContent;
