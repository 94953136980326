import { formatEchartsValue } from '@/utils/utils';
import { tooltipFormatterFn } from './echarts-helpers';

export const BASE_BAR = {
  grid: {
    top: 36,
    right: 18,
    bottom: 18,
    left: 18,
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    formatter: params => tooltipFormatterFn(params)
  },
  xAxis: {
    type: 'category',
    // 隐藏x轴刻度线
    axisTick: { show: false },
    data: []
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: function (value, index) {
        return formatEchartsValue(value);
      }
    }
  },
  series: [
    {
      data: [],
      type: 'bar'
    }
  ]
};

export const HORIZONTAL_BAR = {
  grid: {
    top: 36,
    right: 18,
    bottom: 18,
    left: 6,
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    formatter: params => tooltipFormatterFn(params)
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      formatter: value => formatEchartsValue(value)
    }
  },
  yAxis: {
    type: 'category',
    axisTick: {
      show: false
    },
    inverse: true,
    data: []
  },
  series: [
    {
      type: 'bar',
      data: []
    }
  ]
};

export const BAR_STACK_PERCENT = {
  grid: {
    top: 36,
    right: 18,
    bottom: 18,
    left: 18,
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    /*
     v5.3.0支持
    valueFormatter: value => {
      return parseFloat((value * 100).toFixed(10)) + '%';
    } */
    formatter: params => {
      const { name } = params[0];
      const paramsSize = params.length;
      let str = `${name}<br/>
        ${params
          .map(({ seriesName, data: { value, displayValue }, marker }, index) => {
            return `${marker} ${seriesName}: <label style="margin-right: 16px;">${displayValue}</label>${
              (value * 100).toFixed(1) + '%'
            }${index < paramsSize - 1 ? '<br/>' : ''}`;
          })
          .join('')}
      `;
      return str;
    }
  },
  xAxis: {
    type: 'category',
    axisTick: { show: false },
    data: []
  },
  yAxis: {
    type: 'value',
    min: 0,
    max: 1,
    splitNumber: 4,
    interval: 0.25,
    axisLabel: {
      formatter: value => {
        return parseFloat((value * 100).toFixed(10)) + '%';
      }
    }
  },
  series: [
    {
      data: [],
      type: 'bar'
    }
  ]
};

export const STRIP_STACK_PERCENT = {
  grid: {
    top: 36,
    right: 18,
    bottom: 18,
    left: 6,
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    formatter: params => {
      const { name } = params[0];
      const paramsSize = params.length;
      let str = `${name}<br/>
        ${params
          .map(({ seriesName, data: { value, displayValue }, marker }, index) => {
            return `${marker} ${seriesName}: <label style="margin-right: 16px;">${displayValue}</label>${
              (value * 100).toFixed(1) + '%'
            }${index < paramsSize - 1 ? '<br/>' : ''}`;
          })
          .join('')}
      `;
      return str;
    }
  },
  xAxis: {
    type: 'value',
    min: 0,
    max: 1,
    splitNumber: 4,
    interval: 0.25,
    axisLabel: {
      formatter: value => {
        return parseFloat((value * 100).toFixed(10)) + '%';
      }
    }
  },
  yAxis: {
    type: 'category',
    axisTick: {
      show: false
    },
    inverse: true,
    data: []
  },
  series: [
    {
      type: 'bar',
      data: []
    }
  ]
};
