import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Checkbox, Input, InputNumber, Radio, Select, Tooltip } from 'antd';
import cls from 'classnames';
import SvgIcon from '@/components/SvgIcon';
import { hexToRGBA, isHexColor } from '@/utils/utils';
import ColorPicker from '@/components/FormComponents/Color';

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const { Option } = Select;

function FilterStylePanelTab(props) {
  const { style, onStylePropChange } = props;
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setFormData(props.style);
  }, [JSON.stringify(style)]);

  const onPropChange = (key, val) => {
    setFormData({
      ...formData,
      [key]: val
    });
    onStylePropChange(key, val);
  };

  const toggleFontStyle = type => {
    setFormData({
      ...formData,
      [type]: !formData[type]
    });
    onStylePropChange(type, !formData[type]);
  };

  return (
    <>
      {formData && (
        <Form {...formItemLayout} colon={false} labelAlign="left">
          <Form.Item label="显示标题">
            <Checkbox checked={formData.showTitle} onChange={ev => onPropChange('showTitle', ev.target.checked)} />
          </Form.Item>
          <Form.Item label="标签内容">
            <Input
              placeholder="请输入"
              value={formData.mainTitle}
              onChange={ev => onPropChange('mainTitle', ev.target.value)}
            />
          </Form.Item>
          <Form.Item label="文本">
            <Row gutter={12}>
              <Col span={8}>
                <Select value={formData.fontSize} onChange={val => onPropChange('fontSize', val)}>
                  {new Array(26)
                    .fill(0)
                    .map((item, index) => 10 + index * 2)
                    .map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={3}>
                <i
                  className={cls('svg-icon-wrapper icon-font-style icon-font-weight', {
                    checked: formData.bold
                  })}
                  onClick={() => toggleFontStyle('bold')}
                >
                  <SvgIcon iconClass="bold" />
                </i>
              </Col>
              <Col span={3}>
                <i
                  className={cls('svg-icon-wrapper icon-font-style icon-font-style', {
                    checked: formData.italic
                  })}
                  onClick={() => toggleFontStyle('italic')}
                >
                  <SvgIcon iconClass="italic" />
                </i>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="颜色">
            <ColorPicker
              value={isHexColor(formData.color) ? hexToRGBA(formData.color) : formData.color}
              onChange={rgba => onPropChange('color', rgba)}
            />
          </Form.Item>
          <Form.Item label="字间距">
            <InputNumber
              min={0}
              precision={0}
              value={formData.letterSpacing}
              onChange={val => onPropChange('letterSpacing', val)}
            />
          </Form.Item>
          <Form.Item label="标题位置">
            <Radio.Group value={formData.labelPos} onChange={ev => onPropChange('labelPos', ev.target.value)}>
              <Tooltip placement="top" title="上方" overlayClassName="theme-light">
                <Radio.Button value="top">
                  <SvgIcon iconClass="query-label-align-top" />
                </Radio.Button>
              </Tooltip>
              <Tooltip placement="top" title="左侧" overlayClassName="theme-light">
                <Radio.Button value="left">
                  <SvgIcon iconClass="query-label-align-left" />
                </Radio.Button>
              </Tooltip>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default FilterStylePanelTab;
