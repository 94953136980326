import _ from 'lodash'
import React, { Component } from 'react'
import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { IUdTableProps, IUdColumn, Modify, IColumnStandard, IUdColumnConcise } from '../../../types'
import classNames from 'classnames'
import { columnsClasses } from './columnClasses'
import { findDOMNode } from 'react-dom'
import { watermark } from './watermark'
import { udConfigProvider } from '../../../core/udConfigProvider'


class UdTable<T = any> extends Component<IUdTableProps<T>, IUdTableState<T>> {

  static ThPadding: string = '28px'

  constructor(props: IUdTableProps<T>) {
    super(props)
    this.state = {
      finalProps: {}
    }
  }

  public componentDidMount() {
    columnsClasses.createElement()
    this.processProps(this.props)
  }

  public componentWillReceiveProps(nextProps: Readonly<IUdTableProps<T>>) {
    this.processProps(nextProps)
  }

  public render() {
    return (
      <Table ref={this.addWatermark} {...this.state.finalProps} />
    )
  }

  private addWatermark = (ref) => {
    let dom = findDOMNode(ref) as HTMLDivElement
    if (dom != null) {
      let config = _.defaultsDeep({}, this.props.watermark, udConfigProvider.ui.table.watermark)
      if (!config.enable) {
        return
      }
      config.parent = dom
      if (_.isFunction(config.text)) {
        config.text = config.text()
      }
      watermark.add(config)
    }
  }

  private processProps(oldProps: Readonly<IUdTableProps<T>>): void {
    let props = _.cloneDeep(oldProps) as FinalProps<T>
    // if (props.bordered == null) {
    //   props.bordered = true
    // }
    props.className = classNames('ud-table', props.className)


    props.columns = this.processColumns(props.columns as any)
    this.setState({ finalProps: props } as any)
  }

  private processColumns(columns: IUdColumn<T>[]): ColumnProps<T>[] {
    let result: ColumnProps<T>[] = []
    if (columns) {
      for (let column of columns) {
        let item = column as IColumnStandard<T>
        if (_.isArray(column)) {
          let array = column as IUdColumnConcise<T>
          item = { title: array[0], dataIndex: array[1], render: array[2], ...array[3] }
        }

        let columnClasses: string[] = columnsClasses.createClasses(item)
        item.className = classNames(item.className, columnClasses)

        if (item.style) {
          let inner = item.render || ((text: any, record: T, index: number) => {
            return text
          })
          if (item.width == null) {
            // if (item.minWidth) {
            //   item.width = `calc(${item.minWidth} + ${UdTable.ThPadding})`
            // }
            if (item.fixedWidth) {
              item.width = `calc(${item.fixedWidth} + ${UdTable.ThPadding})`
            }
          }
          let styles = _.extend(
            {},
            item.style
          )
          item.render = (text: any, record: T, index: number) => {
            return <div style={styles}>{inner(text, record, index)}</div>
          }
        }
        if (item.sorter == true && item.sortOrder == null) {
          if (this.props.sortValues && this.props.sortValues.length > 0) {
            let sortItem = this.props.sortValues.find(n => n.key == item.dataIndex)
            if (sortItem) {
              item.sortOrder = sortItem.value
            } else {
              item.sortOrder = false
            }
          } else {
            item.sortOrder = false
          }
        }
        result.push(item)
      }
    }

    columnsClasses.createStyles()

    return result
  }

}

interface IUdTableState<T> {
  finalProps: FinalProps<T>
}

type FinalProps<T> = Modify<IUdTableProps<T>, {
  columns?: ColumnProps<T>[]
}>

export default UdTable
