import _ from 'lodash';
import React, { Component, FormEvent, ReactNode } from 'react';
import { Button, Form, Col, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { IUdFormItem } from '../../types';
import UdFormItem from '../UdFormItem';
import { formUtils } from '../../utils/formUtils';

/**
 * 检索条件过滤器
 */
class UdFilter extends Component<IUdFilterProps, IUdFilterState> {
  static defaultProps = {
    colNumber: 3,
    minRowNumber: 2,
    loading: false,
    removeValueUndefinedItem: true
  };

  // private fields: IUdFormItem[] = []

  constructor(props: IUdFilterProps) {
    super(props);
    this.state = {
      expand: false
    };
  }

  public componentDidMount() {
    if (_.isFunction(this.props.getForm)) {
      this.props.getForm(this.props.form);
    }
    // this.processFields()
  }

  public render() {
    const showExpand = this.props.items.length > this.props.minRowNumber * this.props.colNumber;
    const { hideRequiredMark = false, extraInfo } = this.props;
    return (
      <div className="ud-filter">
        <Form onSubmit={this.handleSearch} hideRequiredMark={hideRequiredMark}>
          {/* <Row className="ud-filter-body" gutter={12}>
            {this.buildFields()}
          </Row> */}
          <div className="list-filter-opts">{this.buildFields()}</div>
          <div className="list-filter-actions">
            <Button type="primary" htmlType="submit" loading={this.props.loading} style={{ marginRight: 8 }}>
              搜索
            </Button>
            <Button type="default" htmlType="reset" disabled={this.props.loading} onClick={this.handleReset}>
              重置
            </Button>
          </div>
          {extraInfo && <Row className="ud-filter-extra-info">{extraInfo}</Row>}
          {showExpand && (
            <div className="ud-filter-footer">
              <Button type="default" icon={this.state.expand ? 'caret-up' : 'caret-down'} onClick={this.expand}>
                {this.state.expand ? '收起' : '展开'}
              </Button>
            </div>
          )}
        </Form>
      </div>
    );
  }

  // private processFields = () => {
  //   this.fields = _.cloneDeep(this.props.items)
  // }

  private buildFields = () => {
    const count = this.state.expand ? this.props.items.length : this.props.minRowNumber * this.props.colNumber;
    const { getFieldDecorator } = this.props.form;
    // const colSpan = 24 / this.props.colNumber
    const colSpan = 4;

    const children = [];
    for (let i = 0; i < this.props.items.length; i++) {
      let item = this.props.items[i];
      item.props = _.extend({}, { allowClear: true }, item.props);
      let span = item.colSpan || { span: colSpan };
      children.push(
        // <Col {...span} key={i} style={{ display: i < count ? 'block' : 'none' }}>
        <UdFormItem key={i} form={this.props.form} getFieldDecorator={getFieldDecorator} {...item} />
        // </Col >
      );
    }
    return children;
  };

  protected handleSearch = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      let items = formUtils.handleValues(values, false);
      this.props.onSearch(items);
    });
  };

  protected handleReset = () => {
    this.props.form.resetFields();
    if (_.isFunction(this.props.resetAfter)) {
      this.props.resetAfter(this.props.form);
    } else {
      this.props.onSearch({});
    }
  };

  protected expand = () => {
    this.setState({ expand: !this.state.expand });
  };
}

interface IUdFilterProps extends FormComponentProps {
  /** 表单项 */
  items: IUdFormItem[];
  /** 提交事件 */
  onSearch: (values: any) => void;
  /** 获取表单实例对象 */
  getForm?: (form: WrappedFormUtils) => void;
  /** 加载状态 */
  loading?: boolean;
  /** 是否移除为空的项，默认为true */
  removeValueUndefinedItem?: boolean;
  /** 每行多少列，默认3个 */
  colNumber?: number;
  /** 超过多少行隐藏，默认2行 */
  minRowNumber?: number;

  /**
   * 点击重置按钮后
   * @default `this.props.onSearch({})`
   */
  resetAfter?: (form: WrappedFormUtils) => void;
  /** 隐藏所有表单项的必选标记 */
  hideRequiredMark?: boolean;
  /** 筛选项底部额外内容 */
  extraInfo?: ReactNode;
}

interface IUdFilterState {
  expand: boolean;
}

export default Form.create<IUdFilterProps>()(UdFilter);
