import React, { useState, useEffect } from 'react';
import { Input, Icon } from 'antd';
import useLocale from '@/utils/useLocale';
import useDebounce from '@/hooks/useDebounce';
import locale from '../locale';
import { IProps } from './data';

function IbInputSearch(props: IProps) {
  const { inDraw, widget, defaultValue, onInputValChange, onSearch, onUpdateChartConditionsMap } = props;
  const t = useLocale(locale);
  const [inputVal, setInputVal] = useState('');
  const debouncedValue = useDebounce(inputVal, 500);

  useEffect(() => {
    setInputVal(defaultValue || undefined);
  }, [defaultValue]);

  const handleChange = ev => {
    setInputVal(ev.target.value);
  };

  const handleBlur = () => {
    !inDraw && onInputValChange(debouncedValue);
    inDraw && onUpdateChartConditionsMap(widget.widgetId, debouncedValue);
  };

  const handleSearch = () => {
    inDraw && onSearch(inputVal);
    setCondition();
  };

  const getCondition = () => {};

  const setCondition = () => {};

  return (
    <div className="ib-input-search-wrapper">
      <Input
        placeholder={t[widget.options.attrs.placeholder]}
        value={inputVal}
        onChange={handleChange}
        onBlur={handleBlur}
        addonAfter={<Icon type="search" onClick={handleSearch} />}
      />
    </div>
  );
}

export default IbInputSearch;
