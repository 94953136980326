import React, { FC, useState, useContext } from 'react';
import { Input, Icon, Modal, message } from 'antd';
import classNames from 'classnames';
import APIS from '@/apis/api';
import { http } from '@/lib';
import { IItemProps } from './data';
import { WarningSettingCtx } from './context';

const { confirm } = Modal;
const Item: FC<IItemProps> = props => {
  const {
    handleDeleteItem,
    handleUpdateItemMonitorName,
    handleUpdateItemStatusActive,
    handleUpdateItemStatusValid
  } = useContext(WarningSettingCtx);
  const { uuId, monitorName, isActive, isValid, inputRef, index } = props;
  const itemRuleClasses = classNames('item-rule', {
    'is-active': isActive,
    'has-error': !isValid
  });
  const [inputValue, setInputValue] = useState(monitorName);
  const handleInputValueChange = e => {
    setInputValue(e.target.value);
    handleUpdateItemMonitorName({ id: uuId, monitorName: e.target.value });
  };
  const handleDelete = () => {
    // 删除二次确认
    confirm({
      title: '确定删除所选规则?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        if (uuId.startsWith('fake')) {
          // 如果删除的是临时数据，那么直接删除
          handleDeleteItem({ id: uuId, index });
        } else {
          http.post(APIS.DeleteMonitor, { id: uuId }).then(res => {
            message.success('删除成功');
            handleDeleteItem({ id: uuId, index });
          });
        }
      },
      onCancel() {}
    });
  };
  const toggleItemStatusActive = uuId => {
    handleUpdateItemStatusActive({ id: uuId, isActive: true });
  };
  return (
    <li className={itemRuleClasses} key={uuId} onClick={() => toggleItemStatusActive(uuId)}>
      <div className="item-rule-input">
        <Input value={inputValue} onChange={handleInputValueChange} ref={inputRef} />
      </div>
      <div className="item-rule-actions">
        <Icon type="delete" onClick={handleDelete} />
      </div>
    </li>
  );
};

export default Item;
