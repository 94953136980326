const i18n = {
  'zh-CN': {
    'ibyear.label': '年份',
    'ibyear.placeholder': '请选择年份',
    'ibyearmonth.label': '年月',
    'ibyearmonth.placeholder': '请选择年月',
    'ibdate.label': '日期',
    'ibdate.placeholder': '请选择日期',
    'ibdaterange.label': '日期范围',
    'ibdaterange.split.placeholder': '至',
    'ibdaterange.from.placeholder': '开始日期',
    'ibdaterange.to.placeholder': '结束日期',
    'dynamic.time.setDefault': '设置默认值',
    'dynamic.time.fix': '固定时间',
    'dynamic.time.dynamic': '动态时间',
    'dynamic.time.relative': '相对当前',
    'dynamic.time.today': '今天',
    'dynamic.time.yesterday': '昨天',
    'dynamic.time.firstOfMonth': '月初',
    'dynamic.time.firstOfYear': '年初',
    'dynamic.time.custom': '自定义',
    'dynamic.time.date': '日',
    'dynamic.time.week': '周',
    'dynamic.time.month': '月',
    'dynamic.time.year': '年',
    'dynamic.time.before': '前',
    'dynamic.time.after': '后',
    'dynamic.time.preview': '预览',
    'dynamic.time.set': '设置',
    'dynamic.time.cweek': '本周',
    'dynamic.time.lweek': '上周',
    'dynamic.time.cmonth': '本月',
    'dynamic.time.cquarter': '本季',
    'dynamic.time.lquarter': '上季',
    'dynamic.time.cyear': '本年',
    'dynamic.year.fix': '固定年份',
    'dynamic.year.dynamic': '动态年份',
    'dynamic.year.current': '今年',
    'dynamic.year.last': '去年',
    'dynamic.month.fix': '固定年月',
    'dynamic.month.dynamic': '动态年月',
    'dynamic.month.current': '当月',
    'dynamic.month.last': '上月',
    'dynamic.month.firstOfYear': '当年首月',
    'dynamic.month.sameMonthLastYear': '去年同月',
    'panel.showTime': '显示时间',
    'panel.customScope': '控制范围',
    'dataset.startTime': '开始时间',
    'dataset.endTime': '结束时间',
    'dataset.checkAll': '全选',
    'ibTextSelect.label': '文本下拉',
    'ibTextSelect.placeholder': '请选择文本',
    'ibTextGridSelect.label': '文本列表',
    'ibTextGridSelect.placeholder': '请选择',
    'panel.singleChoice': '单选',
    'panel.multipleChoice': '多选',
    'panel.showTitle': '标题',
    'panel.inputTitle': '请输入标题',
    'ibInputSearch.label': '文本搜索',
    'ibInputSearch.placeholder': '请输入关键字',
    'commons.all': '全部',
    'ibSelectTree.label': '下拉树',
    'ibSelectTree.placeholder': '请选择',
    'ibNumberSelect.label': '数字下拉',
    'ibNumberSelect.placeholder': '请选择数字',
    'ibNumberGridSelect.label': '数字列表',
    'ibNumberGridSelect.placeholder': '请选择',
    'ibNumberRange.label': '数值区间',
    'ibNumberRange.pleaseKeyMin': '请输入最小值',
    'ibNumberRange.pleaseKeyMax': '请输入最大值',
    'ibNumberRange.split.placeholder': '至',
    'ibSearchButton.label': '查询按钮',
    'ibSearchButton.text': '名称',
    'ibSearchButton.autoTrigger': '自动触发',
    'ibSearchButton.range': '控制范围',
    'ibSearchButton.relative': '关联组件',
    'ibSearchButton.relative.placeholder': '请选择',
    'ibSearchButton.autoTriggerTip': '进入预览页面自动触发一次',
    'ibSearchButton.rangeTip': '默认关联全部过滤组件',
    'ibResetButton.label': '清空按钮',
    'ibResetButton.reset': '重置按钮',
    'ibResetButton.text': '名称'
  }
};

export default i18n;
