/** 监控预警工具类 */
import { uuid } from '@/utils/utils';

export const cornTypeMap = {
  // 预警间隔类型映射表
  EVERY_MONTH: {
    label: '每月',
    value: 'EVERY_MONTH',
    valueOpts: new Array(28)
      .fill(null)
      .map((_, index) => ({ label: index + 1, value: (index + 1).toString(), id: uuid() })),
    unit: '号'
  },
  EVERY_DAY: {
    label: '每天',
    value: 'EVERY_DAY',
    valueOpts: new Array(24).fill(null).map((_, index) => ({ label: index, value: index.toString(), id: uuid() })),
    unit: '时'
  },
  EVERY_HOUR: {
    label: '每时',
    value: 'EVERY_HOUR',
    valueOpts: new Array(60).fill(null).map((_, index) => ({ label: index, value: index.toString(), id: uuid() })),
    unit: '分'
  }
};

export const messageTemplateMap = {
  // 消息模板映射表 钉钉群和企业微信目前只需要传accessToken
  EMAIL: {
    emailMsg: {
      headerAddress: ''
    }
  },
  SMS: {
    smsMsg: {
      mobiles: ''
    }
  },
  DING: {
    dingTalkMsg: {
      accessToken: ''
    }
  },
  WE_CHAT: {
    weChatMsg: {
      accessToken: ''
    }
  },
  FLY_BOOK: {
    flyBookMsg: {
      accessToken: ''
    }
  }
};

export const sqlConditionOpts = [
  // sql 查询条件
  { label: '等于', value: 'EQUALS', id: uuid() },
  { label: '不等于', value: 'NOT_EQUALS', id: uuid() },
  { label: '包含', value: 'LIKE', id: uuid() },
  { label: '大于', value: 'GT', id: uuid() },
  { label: '小于', value: 'LT', id: uuid() },
  { label: '小于等于', value: 'LE', id: uuid() },
  { label: '大于等于', value: 'GE', id: uuid() },
  { label: '为空', value: 'IS_NULL', id: uuid() },
  { label: '不为空', value: 'IS_NOT_NULL', id: uuid() }
];

export const warningDefaultConfig = {
  // 新增默认预警配置数据
  monitorName: '监控名称',
  havingConditionModels: [
    {
      id: uuid(),
      dataSetField: null, // 度量
      operator: '',
      values: [''],
      conditionJoin: 'OR'
    }
  ],
  conditions: [], // 维度
  status: 'ENABLE', // 是否开启预警 ENABLE | DISABLE 默认开启
  cronType: '',
  cronModel: null,
  notifyType: 'EMAIL', // 预警方式 默认邮件
  message: {
    emailMsg: {
      headerAddress: '' // 收件人地址
    },
    smsMsg: {
      mobiles: ''
    },
    dingTalkMsg: {
      accessToken: ''
    },
    weChatMsg: {
      accessToken: ''
    },
    flyBookMsg: {
      accessToken: ''
    }
  }
};
