import React from 'react';
import cls from 'classnames';
import { Icon } from 'antd';
import SvgIcon from '@/components/SvgIcon';
import { FIELD_VALUE_TYPE_ICONS, GRANULARITY_TYPE_ICONS } from '@/utils/enum';

function DragItem({ itemField, onRemoveItemField }) {
  const {
    uniqueId,
    customName,
    attribute: { type, valueType, granularity }
  } = itemField;

  const removeItemField = () => {
    onRemoveItemField(uniqueId);
  };

  const isDerivedField = valueType === 'DATE' && !!granularity;
  const isNormalField = !isDerivedField;
  return (
    <div className={cls(['drag-item-field', type.toLowerCase()])}>
      <div className="field-caption">
        <i className="svg-icon-wrapper">
          <SvgIcon
            iconClass={cls({
              [GRANULARITY_TYPE_ICONS[granularity]]: isDerivedField,
              [FIELD_VALUE_TYPE_ICONS[valueType]]: isNormalField
            })}
          />
        </i>
        {customName}
      </div>
      <Icon type="close" className="icon-del" onClick={removeItemField} />
    </div>
  );
}

export default DragItem;
