import { formatEchartsValueWithDecimal } from '@/utils/utils';

export const BASE_TREEMAP = {
  title: {
    text: '',
    textStyle: {
      fontWeight: 'normal'
    }
  },
  grid: {
    containLabel: true
  },
  tooltip: {
    trigger: 'item',
    formatter: params => {
      if (!params.data.name) return;
      return `${params.data.name} : ${params.data.displayValue}`;
    }
  },
  legend: {
    show: true,
    type: 'scroll',
    itemWidth: 10,
    itemHeight: 10,
    icon: 'rect'
  },
  series: [
    {
      type: 'treemap',
      itemStyle: {
        gapWidth: 1
      },
      label: {
        fontSize: 16
      },
      breadcrumb: {
        show: false
      },
      data: [],
      roam: false
    }
  ]
};
