import React, { useState, useEffect, useRef, useContext } from 'react';
import { Input, Select, Spin, message } from 'antd';
import { ReactSortable } from 'react-sortablejs';
import cls from 'classnames';
import _ from 'lodash';
import { WidgetFilterContext } from './ctx';
import { http } from '@/lib';
import APIS from '@/apis/api';
import SvgIcon from '@/components/SvgIcon';
import { FIELD_VALUE_TYPE_ICONS, GRANULARITY_TYPE_ICONS } from '@/utils/enum';
import { containsIgnoreCase } from '@/utils/utils';
import { convertDataSetFields } from './utils';
import { getService } from '@/components/widget/utils';

const { Search } = Input;
const { Option } = Select;

function TabDataset() {
  const { widget, setWidget } = useContext(WidgetFilterContext);
  const [loading, setLoading] = useState(false);
  const [dataSetId, setDataSetId] = useState(undefined);
  const [dataSetOpts, setDataSetOpts] = useState([]);
  const [dataSetFields, setDataSetFields] = useState([]);
  const originalDataSetFieldsRef = useRef([]);

  useEffect(() => {
    fetchDataSets();
    setDataSetId(widget.options.attrs.dataSetId || undefined);
  }, []);

  useEffect(() => {
    if (!dataSetId) return;
    fetchDataSetFields();
  }, [dataSetId]);

  const fetchDataSets = () => {
    http.post(APIS.DataBoardFindUserDataSets, {}).then(res => {
      setDataSetOpts(res.data);
    });
  };

  const fetchDataSetFields = () => {
    setLoading(true);
    http
      .post(APIS.DataBoardFindDataSetFields, { id: dataSetId })
      .then(res => {
        const widgetInstance = getService(widget.serviceName);
        const { dimensions, measures } = res.data;
        const newDataSetFields = convertDataSetFields([...dimensions, ...measures]);
        setDataSetFields(widgetInstance.filterField(newDataSetFields));
        originalDataSetFieldsRef.current = widgetInstance.filterField(newDataSetFields);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = dataSetId => {
    setDataSetId(dataSetId);
    setWidget({
      ...widget,
      ..._.set(widget, 'options.attrs.dataSetId', dataSetId)
    });
  };

  const handleSearch = keyword => {
    setDataSetFields(
      originalDataSetFieldsRef.current.filter(item => item.customName.includes(keyword.toLocaleLowerCase()))
    );
  };

  const handleEnd = evt => {
    console.log('handleEnd: ', evt, evt.item.dataset.id);
    if (!evt.to.className.includes('filter-field')) return;
    if (widget.options.attrs.dragItems.length > 0) {
      message.warning('禁止同数据集多字段');
      return;
    }
    const newItem = dataSetFields.filter(item => item.uniqueId === evt.item.dataset.id)[0];
    const newWidget = _.cloneDeep(widget);
    const obj = {
      'options.attrs.dragItems': [...widget.options.attrs.dragItems, newItem],
      'options.attrs.fieldUniqueId': evt.item.dataset.id
    };
    Object.keys(obj).forEach(key => {
      _.set(newWidget, key, obj[key]);
    });
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  return (
    <div className="tab-dataset">
      <div className="cube-switch">
        <Select
          size="small"
          placeholder="请选择数据集"
          showSearch
          filterOption={(input, option) => containsIgnoreCase(option.props.children.toString(), input)}
          value={dataSetId}
          onChange={handleChange}
          dropdownClassName="light-theme"
        >
          {dataSetOpts.map(opt => (
            <Option key={opt.key} value={opt.key}>
              {opt.value}
            </Option>
          ))}
        </Select>
      </div>
      <Spin spinning={loading} tip="正在取数中..." wrapperClassName="cube-loading-spin">
        {dataSetId && (
          <div className="cube-field-wrapper">
            <div className="cube-field-search">
              <Search size="small" placeholder="输入关键字搜索" onSearch={handleSearch} />
            </div>
            <ReactSortable
              group={{
                name: 'source',
                pull: 'clone'
              }}
              sort={false}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              className="cube-field-list"
              filter=".checked"
              list={dataSetFields}
              setList={() => {}}
              onEnd={handleEnd}
              onMove={ev => !ev.related.classList.contains('checked')}
            >
              {dataSetFields.map(field => {
                const {
                  customName,
                  attribute: { type, valueType, granularity }
                } = field;
                const isDerivedField = valueType === 'DATE' && !!granularity;
                const isNormalField = !isDerivedField;
                return (
                  <div
                    key={field.uniqueId}
                    className={cls('item-field', {
                      checked: widget.options.attrs.dragItems.map(item => item.uniqueId).includes(field.uniqueId)
                    })}
                  >
                    <i className={cls(['svg-icon-wrapper', type.toLowerCase()])}>
                      <SvgIcon
                        iconClass={cls({
                          [GRANULARITY_TYPE_ICONS[granularity]]: isDerivedField,
                          [FIELD_VALUE_TYPE_ICONS[valueType]]: isNormalField
                        })}
                      />
                    </i>
                    {customName}
                  </div>
                );
              })}
            </ReactSortable>
          </div>
        )}
      </Spin>
    </div>
  );
}

export default TabDataset;
