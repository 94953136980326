import React, { FC } from 'react';
import { Popover } from 'antd';
import SvgIcon from '@/components/SvgIcon';
import './style.less';

const Help: FC = () => {
  const content = (
    <div className="help-container">
      <div className="space">帮助</div>
      <ul>
        <li>
          <a href="https://jzsz.yuque.com/docs/share/e3fbd202-2e7d-4e9f-ad7c-be3c9a08b073" target="blank">
            <i className="svg-icon-wrap icon-help-doc">
              <SvgIcon iconClass="doc" />
            </i>
            <span>操作手册</span>
          </a>
        </li>
        {/* <li>
          <a href="https://support.qq.com/products/374721" target="blank">
            <i className="svg-icon-wrap icon-help-message">
              <SvgIcon iconClass="message" />
            </i>
            <span>建议/问题反馈</span>
          </a>
        </li> */}
      </ul>
    </div>
  );
  return (
    <Popover content={content} placement="bottomRight" overlayClassName="pop-help">
      <div className="help-wrap">
        <i className="svg-icon-wrap icon-help">
          <SvgIcon iconClass="help" />
        </i>
      </div>
    </Popover>
  );
};
export default Help;
