import React, { memo } from 'react';
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Tooltip, Icon } from 'antd';
import SvgIcon from '@/components/SvgIcon';
import FieldItem from './field-item';
import { FIELD_AREAS, FIELD_AREA_TIPS } from '@/utils/enum';
import { IFieldAreaProps } from './data';

const Container = styled.div<any>`
  border: 1px ${props => (props.isDraggingOver ? 'dashed rgba(51, 51, 51, 0.4)' : 'dashed rgba(51, 51, 51, 0.2)')};
  background: rgba(255, 255, 255, 0.05);
  padding: 4px;
  border-radius: 2px;
  width: 100%;
`;

const Notice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 4px 0;
  border: 1px solid transparent;
  color: rgba(51, 51, 51, 0.5);
`;

const List = styled.div<any>`
  transform: ${props => !props.isDragging && 'translate(0px, 0px) !important'};
  margin-top: ${props => (props.index === 0 ? '0px' : '6px')};
`;

function FieldArea({ type, fields, onChartTypeValueChange }: IFieldAreaProps) {
  // console.log('render FieldArea', type, fields);
  return (
    <div className="field-area-wrapper">
      <div className="field-area-header">
        <div className="area-name">
          {(type === 'dimensionFields' || type === 'measureFields' || type === 'compareFields' || type === 'xaxis') && (
            <i className="svg-icon-wrapper require-icon">
              <SvgIcon iconClass="asterisk" />
            </i>
          )}
          {FIELD_AREAS[type]}
          {type === 'drillDownFields' && (
            <Tooltip title="按此区域的字段顺序进行下钻">
              <Icon type="question-circle-o" style={{ marginLeft: '2px' }} />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="field-area-body">
        <Droppable droppableId={type}>
          {(provided, snapshot) => (
            <Container ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
              {Array.isArray(fields) && fields.length
                ? fields.map((item, index) => (
                    <Draggable key={item.uniqueId} draggableId={item.uniqueId} index={index}>
                      {(provided, snapshot) => (
                        <List
                          ref={provided.innerRef}
                          isDragging={snapshot.isDragging}
                          style={provided.draggableProps.style}
                          index={index}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FieldItem
                            fieldAreaType={type}
                            itemField={item}
                            index={index}
                            onChartTypeValueChange={onChartTypeValueChange}
                          />
                        </List>
                      )}
                    </Draggable>
                  ))
                : provided.placeholder && <Notice>拖动{FIELD_AREA_TIPS[type]}字段至此处</Notice>}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      </div>
    </div>
  );
}

export default memo(FieldArea);
