import dayjs from 'dayjs';
import { WidgetService } from '../service/widget-service';
import { Base } from '../data';

const DATE_FORMAT = 'YYYY';
const genDialogPanel = () => ({
  options: {
    attrs: {
      type: 'year',
      placeholder: 'ibyear.placeholder',
      enableRange: false,
      chartIds: [],
      dataSetId: '',
      fieldUniqueId: '',
      dragItems: [],
      default: {
        isDynamic: false,
        dkey: 0,
        dynamicPrefix: 1,
        dynamicInfill: 'year',
        dynamicSuffix: 'before',
        radioOptions: [
          { key: 'fix', value: false, text: 'dynamic.year.fix' },
          { key: 'dynamic', value: true, text: 'dynamic.year.dynamic' }
        ],
        relativeOptions: [
          { key: 'current', value: 0, text: 'dynamic.year.current' },
          { key: 'last', value: 1, text: 'dynamic.year.last' },
          { key: 'custom', value: 2, text: 'dynamic.time.custom' }
        ],
        custom: {
          unitsOptions: [{ value: 'year', text: 'dynamic.time.year' }],
          limits: [0, 10]
        },
        fixedValue: null
      }
    },
    value: '',
    manualModify: false
  },
  label: 'ibyear.label',
  defaultClass: 'time-filter',
  component: 'ib-date',
  miniSizeX: 1,
  miniSizeY: 1
});

const genDrawPanel = () => ({
  type: 'custom',
  style: {
    showTitle: false,
    mainTitle: '',
    labelPos: 'top',
    fontSize: 14,
    color: '#333',
    bold: false,
    italic: false,
    letterSpacing: 0
  },
  component: 'ib-date'
});

class TimeYearServiceImpl extends WidgetService implements Base {
  public filterDialog: boolean;
  public showSwitch: boolean;
  constructor(options = {}) {
    Object.assign(options, {
      name: 'timeYearWidget'
    });
    super(options);
    this.filterDialog = true;
    this.showSwitch = false;
  }

  initFilterDialog() {
    return genDialogPanel();
  }

  initDrawPanel() {
    return genDrawPanel();
  }

  filterField(fields) {
    return fields.filter(field => field.attribute.valueType === 'DATE');
  }

  getDefaultSetting() {
    const { options } = genDialogPanel();
    return options.attrs.default;
  }

  dynamicDateFormNow(formData) {
    if (formData === null || typeof formData === 'undefined' || !formData.isDynamic) return;

    switch (formData.dkey) {
      case 0:
        return dayjs().format(DATE_FORMAT);
      case 1:
        return dayjs().subtract(1, 'year').format(DATE_FORMAT);
      case 2: {
        const dynamicPrefix = parseInt(formData.dynamicPrefix);
        const dynamicSuffix = formData.dynamicSuffix;

        return dayjs()[dynamicSuffix === 'before' ? 'subtract' : 'add'](dynamicPrefix, 'year').format(DATE_FORMAT);
      }
    }
  }

  setDefaultValue(element) {
    if (element.options.attrs.default && element.options.attrs.default.isDynamic) {
      return this.dynamicDateFormNow(element.options.attrs.default);
    }
    return element.options.attrs.default.fixedValue;
  }

  formatValues() {}

  isParamWidget() {
    return true;
  }
}

const timeYearServiceImpl = new TimeYearServiceImpl();
export default timeYearServiceImpl;
