import React, { FC } from 'react';
import classNames from 'classnames';
import SvgIcon from '@/components/SvgIcon';

export type FieldType = 'DIMENSION' | 'MEASURE';
export type FieldValueType = 'TEXT' | 'NUMBER' | 'DATE';
export interface IItemField {
  attribute: {
    type: FieldType;
    valueType: FieldValueType;
    [key: string]: any;
  };
  customName: string;
}
interface IProps {
  itemField: IItemField;
  fieldFrom: 'source' | 'destination';
  isLast?: boolean;
  onDragItemRemove?: (item: IItemField) => void;
}
const DragItem: FC<IProps> = ({ itemField, fieldFrom, isLast, onDragItemRemove }) => {
  const {
    attribute: { type, valueType },
    customName
  } = itemField;
  const dragItemClasses = classNames('drag-item', {
    'drag-item-dimension': type === 'DIMENSION',
    'drag-item-destination': fieldFrom === 'destination',
    'drag-item-last': isLast
  });
  const fieldSvgIconClass = classNames({
    Str: valueType === 'TEXT',
    No: valueType === 'NUMBER',
    Date: valueType === 'DATE'
  });
  return (
    <div className={dragItemClasses}>
      <span className="field-icon">
        <i className="svg-icon-wrapper">
          <SvgIcon iconClass={fieldSvgIconClass} />
        </i>
      </span>
      <span className="field-caption">{customName}</span>
      {fieldFrom === 'destination' && (
        <div className="right-hover-icons">
          <span className="icon-wrapper">
            <i className="svg-icon-wrapper icon-del" onClick={() => onDragItemRemove(itemField)}>
              <SvgIcon iconClass="delete" />
            </i>
          </span>
        </div>
      )}
    </div>
  );
};

export default DragItem;
