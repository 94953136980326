import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

const generateDefaultState = () => {
  return {
    screenConfig: {
      title: '',
      desc: '',
      width: 1920,
      height: 1080,
      zoomMode: {
        default: 'YOY',
        option: [
          { label: '同比缩放', value: 'YOY' },
          { label: '自适应缩放', value: 'adaptive' }
        ]
      },
      backgroundColor: '#0d1d31',
      backgroundImage: '',
      leftSideW: 200,
      rightSideW: 400
    },
    componentList: [],
    activeIndex: -1,
    curElementType: 'blur'
  };
};
export class ScreenStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  state = generateDefaultState();

  get activeComp() {
    return this.state.activeIndex > -1 ? this.state.componentList[this.state.activeIndex] : null;
  }

  setCompList(payload) {
    this.state.componentList = payload;
  }

  addComp(payload) {
    this.state.componentList = [...this.state.componentList, payload];
    this.state.activeIndex = this.state.componentList.length - 1;
    this.state.curElementType = this.state.componentList[this.state.activeIndex].compGroup;
  }

  delComp(compId) {
    if (this.activeComp.compId === compId) {
      this.state.activeIndex = -1;
      this.state.curElementType = 'blur';
    }
    this.state.componentList = this.state.componentList.filter(comp => comp.compId !== compId);
  }

  modComp({ compId, item }) {
    const newCompList = this.state.componentList.map(comp => {
      if (comp.compId === compId) {
        return item;
      }
      return comp;
    });
    // const activeIndex = this.state.componentList.findIndex(itemComp => itemComp.compId === compId);
    // this.state.activeIndex = activeIndex;
    this.state.componentList = newCompList;
  }

  setActiveIndex(activeIndex) {
    this.state.activeIndex = activeIndex;
  }

  setScreenConfig(payload) {
    this.state.screenConfig = payload;
  }

  setScreenConfigFieldVal({ field, val }) {
    this.state.screenConfig = {
      ...this.state.screenConfig,
      [field]: val
    };
  }

  setDataSetIdAndFields({ dataSetFields, flattenedFields, dataSetId, dataSetName, isResetFields }) {
    const newItem = {
      ...this.activeComp,
      dataConfig: {
        ...this.activeComp.dataConfig,
        ...(isResetFields ? this.resetActiveCompDataConfig(this.activeComp.dataConfig) : {}),
        dataSetId,
        dataSetName,
        dataSetFields,
        flattenedFields
      }
    };
    this.modComp({ compId: newItem.compId, item: newItem });
  }

  resetActiveCompDataConfig(dataConfig) {
    return Object.assign(
      {},
      {
        ...dataConfig,
        measureFields: [],
        filterFields: [],
        filters: []
      },
      dataConfig.dimensionFields ? { dimensionFields: [] } : {},
      dataConfig.compareFields ? { compareFields: [], compareField: null } : {},
      dataConfig.drillDownFields ? { drillDownFields: [] } : {}
    );
  }

  setIsFetchingData(isFetchingData) {
    const newItem = {
      ...this.activeComp,
      dataConfig: {
        ...this.activeComp.dataConfig,
        isFetchingData
      }
    };
    this.modComp({ compId: newItem.compId, item: newItem });
  }

  setCurElementType(type) {
    this.state.curElementType = type;
    if (type === 'blur') {
      this.state.activeIndex = -1;
    }
  }

  resetState() {
    this.state = generateDefaultState();
  }

  addExtraConfig({ compId, newItem, fieldAreaType }) {
    this.state.componentList = this.state.componentList.map(comp => {
      if (comp.compId === compId) {
        if (comp.type === 'combination') {
          return {
            ...comp,
            dataConfig: {
              ...comp.dataConfig,
              extraConfigFieldMap: {
                ...comp.dataConfig.extraConfigFieldMap,
                [fieldAreaType]: [...comp.dataConfig.extraConfigFieldMap[fieldAreaType], newItem]
              }
            }
          };
        }
        return {
          ...comp,
          dataConfig: {
            ...comp.dataConfig,
            extraConfigFields: [...comp.dataConfig.extraConfigFields, newItem]
          }
        };
      }
      return comp;
    });
  }

  modExtraConfig({ compId, index, updatedItem, fieldAreaType }) {
    this.state.componentList = this.state.componentList.map(comp => {
      if (comp.compId === compId) {
        if (comp.type === 'combination') {
          return {
            ...comp,
            dataConfig: {
              ...comp.dataConfig,
              extraConfigFieldMap: {
                ...comp.dataConfig.extraConfigFieldMap,
                [fieldAreaType]: comp.dataConfig.extraConfigFieldMap[fieldAreaType].map((item, idx) => {
                  if (idx === index) return updatedItem;
                  return item;
                })
              }
            }
          };
        }
        return {
          ...comp,
          dataConfig: {
            ...comp.dataConfig,
            extraConfigFields: comp.dataConfig.extraConfigFields.map((item, idx) => {
              if (idx === index) {
                return updatedItem;
              }
              return item;
            })
          }
        };
      }
      return comp;
    });
  }
}

export default new ScreenStore();
