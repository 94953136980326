import React, { useState, useContext } from 'react';
import { toJS } from 'mobx';
import _ from 'lodash';
import cls from 'classnames';
import { Tooltip } from 'antd';
import { useStore } from '@/store/useStore';
import { WorkplaceContext } from './context';
import { genRelationChartMap } from './chart-type';
import SvgIcon from '@/components/SvgIcon';
import ComponentLoader from './component-loader';
import CardMenu from './card-menu';
import DrillPath from './drill-path';

function GridItem({
  dashboardId,
  dashboardName,
  activeBreakPoint,
  charts,
  customComps,
  item,
  index,
  mode,
  isTypeLink,
  token,
  onGridItemClick,
  onCopy,
  onRemove,
  onSettingWarning,
  onSettingDrillDown
}: any) {
  const { dashboardStore } = useStore();
  const { activeChart } = dashboardStore;
  const { setTriggerUpdate } = useContext(WorkplaceContext);
  const [isHovered, setIsHovered] = useState(false);

  // 返回上一级
  const backToParent = ({ itemField, index: itemFieldIndex }) => {
    console.log('backToParent: ', itemField, itemFieldIndex);
    const updatedItem = {
      ...activeChart,
      dataConfig: {
        ...activeChart.dataConfig,
        isActionDrill: itemFieldIndex > 0,
        index: itemFieldIndex,
        pointParam: {
          data: {
            name: activeChart.dataConfig.pointValues[itemFieldIndex - 1]
          }
        }
      }
    };
    if (activeChart.chartType.includes('map') && activeChart.chartType !== 'treemap') {
      const adcodeSize = updatedItem.dataConfig.drillPathAdcodes.length;
      const [mapName, adcode] = updatedItem.dataConfig.drillPathAdcodes[itemFieldIndex].split('-');
      updatedItem.dataConfig.mapName = mapName;
      updatedItem.dataConfig.adcode = adcode;
      for (let i = 0; i < adcodeSize - 1 - itemFieldIndex; i++) {
        updatedItem.dataConfig.drillPathAdcodes.pop();
      }
    }
    dashboardStore.modChart({ frontChartId: updatedItem.frontChartId, updatedItem });
    setTimeout(() => {
      setTriggerUpdate(true);
    }, 200);
  };

  // 取消联动
  const cancelLinkage = item => {
    const updatedItem = _.cloneDeep(item);
    const {
      advancedConfig: {
        linkageConfig: { relationChart }
      }
    } = item;
    const relationChartMap = genRelationChartMap(relationChart);
    updatedItem.advancedConfig.linkageConfig.linkageWorkByClick = false;
    updatedItem.dataConfig.chartOptions.series = updatedItem.dataConfig.chartOptions.series.map(itemSeries => {
      delete itemSeries.emphasis;
      return {
        ...itemSeries,
        itemStyle: {
          opacity: 1
        },
        data: itemSeries.data.map(itemSeriesData => {
          itemSeriesData.itemStyle && delete itemSeriesData.itemStyle;
          itemSeriesData.label && delete itemSeriesData.label;
          return itemSeriesData;
        })
      };
    });

    dashboardStore.setCharts(
      charts.map(chart => {
        if (chart.frontChartId === updatedItem.frontChartId) {
          return updatedItem;
        } else if (Object.keys(relationChartMap).includes(chart.frontChartId)) {
          return {
            ...chart,
            dataConfig: {
              ...chart.dataConfig,
              status: 'refreshRelationChartData',
              hasRelationChart: false,
              relationChartType: '',
              relationChartParam: []
            }
          };
        }
        return chart;
      })
    );
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // console.log('render GridItem: ', toJS(item));
  return (
    <div
      className="layout-item"
      data-grid={item.layout}
      onClick={() => onGridItemClick(index)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="layout-item-box">
        <div className="component-card-wrapper chart-component">
          <div className="component-card">
            <div className="card-header-wrapper">
              <div className="caption-wrapper">
                <div className="caption">{item.styleConfig.title}</div>
                {item.styleConfig.mark &&
                  item.styleConfig.mark.show &&
                  item.styleConfig.mark.position === 'title' &&
                  item.styleConfig.mark.content && (
                    <Tooltip
                      title={item.styleConfig.mark.content}
                      placement="bottomLeft"
                      overlayClassName="no-arrow theme-light"
                    >
                      <i className="svg-icon-wrapper icon-info">
                        <SvgIcon iconClass="info-circle" />
                      </i>
                    </Tooltip>
                  )}
              </div>
              {item.advancedConfig.linkageConfig.hasLinkageParamCheck && (
                <i className="svg-icon-wrapper icon-linkage" title="已设置联动">
                  <SvgIcon iconClass="linkage" />
                </i>
              )}
              {item.dataConfig.hasRelationChart && (
                <Tooltip
                  overlayClassName="relation-chart-tip theme-light"
                  title={() => (
                    <>
                      <i
                        className={cls(['component-type-icon', `component-type-${item.dataConfig.relationChartType}`])}
                      ></i>
                      <span>{item.dataConfig.relationChartParam[0].values[0]}</span>
                    </>
                  )}
                  placement="bottomRight"
                >
                  <i className="svg-icon-wrapper icon-linkage-effect">
                    <SvgIcon iconClass="linkage-effect" />
                  </i>
                </Tooltip>
              )}
              {mode === 'edit' && (
                <CardMenu
                  dashboardId={dashboardId}
                  dashboardName={dashboardName}
                  activeBreakPoint={activeBreakPoint}
                  charts={charts}
                  customComps={customComps}
                  item={item}
                  index={index}
                  onCopy={onCopy}
                  onRemove={onRemove}
                  onSettingWarning={onSettingWarning}
                  onSettingDrillDown={onSettingDrillDown}
                  isHovered={isHovered}
                />
              )}
              {mode === 'preview' && <div style={{ height: '100%', flex: 1 }}></div>}
              {item.advancedConfig.linkageConfig.linkageWorkByClick && (
                <Tooltip title="取消联动">
                  <i className="svg-icon-wrapper icon-linkage-cancel" onClick={() => cancelLinkage(item)}>
                    <SvgIcon iconClass="linkage-cancel" />
                  </i>
                </Tooltip>
              )}
            </div>
            {item.styleConfig.mark &&
              item.styleConfig.mark.show &&
              item.styleConfig.mark.position === 'chart' &&
              item.styleConfig.mark.content && (
                <div className="mark-wrapper">
                  <p>{item.styleConfig.mark.content}</p>
                </div>
              )}
            <ComponentLoader
              item={item}
              index={index}
              mode={mode}
              isTypeLink={mode === 'preview' && isTypeLink}
              dashboardId={dashboardId}
              token={token}
            />
            {Array.isArray(item.dataConfig.drillDownFields) &&
              !!item.dataConfig.drillDownFields.length &&
              !!item.dataConfig.index && (
                <div className="card-footer-wrapper">
                  <DrillPath
                    drillDownFields={item.dataConfig.drillDownFields.slice(0, item.dataConfig.index + 1)}
                    onBreadcrumbItemClick={backToParent}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridItem;
