import { ChartType } from './index';
interface IItemTypeConfig {
  category: string;
  items: IItem[];
}

interface IItem {
  title: string;
  value: ChartType;
  icon: string;
}

export const TYPE_CONFIGS: IItemTypeConfig[] = [
  {
    category: '表格',
    items: [
      {
        title: '交叉表',
        value: 'table',
        icon: 'table-normal'
      },
      {
        title: '对比交叉表',
        value: 'tableMultidimensional',
        icon: 'table-info'
      }
    ]
  },
  {
    category: '指标',
    items: [
      {
        title: 'kpi指标卡',
        value: 'kpi',
        icon: 'kpi-text'
      },
      {
        title: '仪表盘',
        value: 'gauge',
        icon: 'gauge'
      }
      /* {
        title: '进度条',
        value: 'progress',
        icon: ''
      },
      {
        title: '水波图',
        value: 'gauge',
        icon: ''
      } */
    ]
  },
  {
    category: '趋势',
    items: [
      {
        title: '折线图',
        value: 'line',
        icon: 'line'
      },
      {
        title: '面积图',
        value: 'area',
        icon: 'area'
      }
    ]
  },
  {
    category: '比较',
    items: [
      {
        title: '柱状图',
        value: 'bar',
        icon: 'bar'
      },
      {
        title: '条形图',
        value: 'bar-horizontal',
        icon: 'bar-horizontal'
      }
    ]
  },
  {
    category: '分布',
    items: [
      {
        title: '饼图',
        value: 'pie',
        icon: 'pie'
      },
      {
        title: '雷达图',
        value: 'radar',
        icon: 'radar'
      },
      {
        title: '玫瑰图',
        value: 'pie-rose',
        icon: 'pie-rose'
      },
      {
        title: '矩形树图',
        value: 'treemap',
        icon: 'treemap'
      }
      /* {
        title: '词云图',
        value: '',
        icon: ''
      } */
    ]
  },
  {
    category: '关系',
    items: [
      {
        title: '漏斗图',
        value: 'funnel',
        icon: 'funnel'
      },
      /* {
        title: '气泡图',
        value: 'bubble',
        icon: ''
      }, */
      {
        title: '散点图',
        value: 'scatter',
        icon: 'scatter'
      }
    ]
  },
  {
    category: '空间',
    items: [
      {
        title: '色彩地图',
        value: 'map',
        icon: 'map'
      },
      {
        title: '气泡地图',
        value: 'bubble-map',
        icon: 'bubble-map'
      }
    ]
  }
];

export const TYPE_NORMAL_CONFIGS: IItem[] = [
  {
    title: '折线图',
    value: 'line',
    icon: 'line'
  },
  {
    title: '柱状图',
    value: 'bar',
    icon: 'bar'
  },
  {
    title: '饼图',
    value: 'pie',
    icon: 'pie'
  }
];

export const DEFAULT_CHART_TYPE_CONFIGS = [
  {
    name: '交叉表',
    chartType: 'table',
    icon: 'table-normal'
  },
  {
    name: '对比交叉表',
    chartType: 'tableMultidimensional',
    icon: 'table-info'
  },
  {
    name: 'kpi指标卡',
    chartType: 'kpi',
    icon: 'kpi-text'
  },
  {
    name: '仪表盘',
    chartType: 'gauge',
    icon: 'gauge'
  },
  {
    name: '折线图',
    chartType: 'line',
    icon: 'line'
  },
  {
    name: '面积图',
    chartType: 'area',
    icon: 'area'
  },
  {
    name: '柱状图',
    chartType: 'bar',
    icon: 'bar'
  },
  {
    name: '条形图',
    chartType: 'bar-horizontal',
    icon: 'bar-horizontal'
  },
  {
    name: '饼图',
    chartType: 'pie',
    icon: 'pie'
  },
  {
    name: '雷达图',
    chartType: 'radar',
    icon: 'radar'
  },
  {
    name: '玫瑰图',
    chartType: 'pie-rose',
    icon: 'pie-rose'
  },
  {
    name: '矩形树图',
    chartType: 'treemap',
    icon: 'treemap'
  },
  {
    name: '漏斗图',
    chartType: 'funnel',
    icon: 'funnel'
  },
  {
    name: '散点图',
    chartType: 'scatter',
    icon: 'scatter'
  },
  {
    name: '色彩地图',
    chartType: 'map',
    icon: 'map'
  },
  {
    name: '气泡地图',
    chartType: 'bubble-map',
    icon: 'bubble-map'
  }
];

export const CHART_ICON_MAP = new Map()
  .set('line', 'line')
  .set('bar', 'bar')
  .set('bar-stack', 'bar-stack')
  .set('bar-stack-percent', 'bar-stack-percent')
  .set('bar-horizontal', 'bar-horizontal')
  .set('strip-stack', 'strip-stack')
  .set('strip-stack-percent', 'strip-stack-percent')
  .set('pie', 'pie')
  .set('table', 'table-normal')
  .set('tableMultidimensional', 'table-info')
  .set('kpi', 'kpi-text')
  .set('gauge', 'gauge')
  .set('area', 'area')
  .set('radar', 'radar')
  .set('pie-rose', 'pie-rose')
  .set('treemap', 'treemap')
  .set('funnel', 'funnel')
  .set('scatter', 'scatter')
  .set('map', 'map')
  .set('bubble-map', 'bubble-map');

export const ECHART_SERIES_TYPE_MAP = new Map()
  .set('line', 'line')
  .set('area', 'line')
  .set('bar', 'bar')
  .set('bar-stack', 'bar')
  .set('bar-stack-percent', 'bar')
  .set('bar-horizontal', 'bar')
  .set('strip-stack', 'bar')
  .set('strip-stack-percent', 'bar')
  .set('pie', 'pie')
  .set('pie-rose', 'pie')
  .set('table', 'table')
  .set('tableMultidimensional', 'tableMultidimensional')
  .set('kpi', 'kpi')
  .set('gauge', 'gauge')
  .set('radar', 'radar')
  .set('treemap', 'treemap')
  .set('funnel', 'funnel')
  .set('scatter', 'scatter')
  .set('map', 'map')
  .set('bubble-map', 'bubble-map');

export const API_CHART_TYPE_MAP = new Map()
  .set('LINE', 'line')
  .set('AREA', 'area')
  .set('BAR', 'bar')
  .set('BAR_STACK', 'bar-stack')
  .set('BAR_STACK_PERCENT', 'bar-stack-percent')
  .set('BAR_HORIZONTAL', 'bar-horizontal')
  .set('STRIP_STACK', 'strip-stack')
  .set('STRIP_STACK_PERCENT', 'strip-stack-percent')
  .set('PIE', 'pie')
  .set('PIE_ROSE', 'pie-rose')
  .set('TABLE', 'table')
  .set('COMPARE_TABLE', 'tableMultidimensional')
  .set('KPI_CAR', 'kpi')
  .set('GAUGE', 'gauge')
  .set('RADAR', 'radar')
  .set('TREEMAP', 'treemap')
  .set('FUNNEL', 'funnel')
  .set('SCATTER', 'scatter')
  .set('MAP', 'map')
  .set('BUBBLE_MAP', 'bubble-map')
  .set('TEXT', 'text')
  .set('IMAGE', 'image')
  .set('VIDEO', 'video')
  .set('STATISTIC', 'statistic')
  .set('RANKING_LIST', 'ranking-list');

export const CHART_TYPE_MAP = [...API_CHART_TYPE_MAP]
  .map(([key, val]) => ({ [val]: key }))
  .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const funcSize = (value, bubbleArray) => {
  const k = 80;
  const max = Math.max(...bubbleArray);
  return (value / max) * k;
};
