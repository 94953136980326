import React, { useState } from 'react';
import _ from 'lodash';
import cls from 'classnames';
import { Form, Tooltip, Icon, Switch } from 'antd';
import { useStore } from '@/store/useStore';
import { LinkageContext } from './ctx-linkage';
import { AuxiliaryLineContext } from './ctx-auxiliary-line';
import { PROXIMITY_ANALYSIS_CHARTS, LINE_CALCULATED_TYPES, AUXILIARY_LINE_CHARTS } from '@/utils/enum';
import { genLinkageConfig } from './util';
import SvgIcon from '@/components/SvgIcon';
import ModalLinkage from './modal-linkage';
import ModalAuxiliaryLine from './modal-auxiliary-line';
import './style/linkage-set.less';

const formItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 }
};
function AdvancedPanelTab() {
  const { dashboardStore } = useStore();
  const {
    activeChart,
    activeChart: {
      frontChartId,
      chartType,
      dataConfig: { dimensionFields },
      advancedConfig: { proximityAnalysisStatus, linkageConfig, auxiliaryLines }
    },
    state: { charts, dataSets }
  } = dashboardStore;
  const [linkageParam, setLinkageParam] = useState({
    visible: false
  });
  const [visibleModalAuxiliaryLine, setVisibleModalAuxiliaryLine] = useState(false);

  const handleProximityAnalysisStatusChange = status => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.advancedConfig.proximityAnalysisStatus = status;
    dashboardStore.modChart({ frontChartId, updatedItem });
  };

  const handleRelationSet = () => {
    setLinkageParam({
      ...linkageParam,
      visible: true
    });
  };

  const handleRelationRemove = () => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.advancedConfig.linkageConfig = genLinkageConfig();
    dashboardStore.modChart({ frontChartId, updatedItem });
  };

  const cancelLinkageSetting = () => {
    setLinkageParam({
      ...linkageParam,
      visible: false
    });
  };

  const confirmLinkageSetting = ({ relationChart, dataSetFieldMap }) => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.advancedConfig.linkageConfig.hasLinkageParamCheck = true;
    updatedItem.advancedConfig.linkageConfig.relationChart = relationChart;
    updatedItem.advancedConfig.linkageConfig.dataSetFieldMap = dataSetFieldMap;
    dashboardStore.modChart({ frontChartId, updatedItem });
    cancelLinkageSetting();
  };

  const handleAuxiliaryLineSet = () => {
    setVisibleModalAuxiliaryLine(true);
  };

  const cancelAuxiliaryLineSetting = () => {
    setVisibleModalAuxiliaryLine(false);
  };

  const confirmAuxiliaryLineSetting = auxiliaryLines => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.advancedConfig.auxiliaryLines = auxiliaryLines;
    dashboardStore.modChart({ frontChartId, updatedItem });
    cancelAuxiliaryLineSetting();
  };

  const isDimFieldAreaEmpty = !dimensionFields || (Array.isArray(dimensionFields) && !dimensionFields.length);
  const linkageCtxValue = {
    activeChart,
    charts,
    dataSets,
    relationChart: linkageConfig.relationChart
  };
  const auxiliaryLineCtxValue = {
    activeChart
  };
  return (
    <div className="setting-panel-tab advanced-panel-tab">
      {/* 接近值分析 */}
      {PROXIMITY_ANALYSIS_CHARTS.includes(chartType) && (
        <Form {...formItemLayout} colon={false} labelAlign="right">
          <Form.Item
            label={
              <>
                <span>接近值分析</span>
                <Tooltip title="用于分析一组变化都很接近值的数据">
                  <Icon type="question-circle-o" style={{ marginLeft: '2px' }} />
                </Tooltip>
              </>
            }
          >
            <Switch checked={proximityAnalysisStatus} onChange={handleProximityAnalysisStatusChange} />
          </Form.Item>
        </Form>
      )}
      {/* 联动设置 */}
      <div className="relation-set">
        {isDimFieldAreaEmpty && <div className="relation-tip">不支持高级联动功能</div>}
        {!isDimFieldAreaEmpty && (
          <>
            <label>联动</label>
            <span className="state">
              {linkageConfig.hasLinkageParamCheck && (
                <span className="has-setting">
                  已配置
                  <i className="svg-icon-wrapper icon-del" onClick={handleRelationRemove}>
                    <SvgIcon iconClass="delete-1" />
                  </i>
                </span>
              )}
              <span className="set-icon" onClick={handleRelationSet}>
                <i className="svg-icon-wrapper icon-pen">
                  <SvgIcon iconClass="pen" />
                </i>
              </span>
            </span>
          </>
        )}
      </div>
      {/* 辅助线 */}
      {AUXILIARY_LINE_CHARTS.includes(chartType) && (
        <div className="senior-config-item-container auxiliary-line-container">
          <div className="senior-config-common-title">
            <div className="advanced-set">
              <label>辅助线</label>
              <i className="svg-icon-wrdapper icon-pen" onClick={handleAuxiliaryLineSet}>
                <SvgIcon iconClass="pen" />
              </i>
            </div>
          </div>
          {auxiliaryLines.map(line => (
            <div className="advanced-set-list" key={line.id}>
              <div className={cls(['advanced-set-list-item', 'advanced-set-list-item-' + line.type])}>
                <div className="ellipsis item-name">{line.name}</div>
                {line.type === 'fixed' && (
                  <>
                    <div className="ellipsis item-location">主轴</div>
                    <div className="ellipsis item-value">{line.value}</div>
                  </>
                )}
                {line.type === 'calculated' && (
                  <>
                    <div className="ellipsis item-field">
                      {
                        activeChart.dataConfig.measureFields.filter(field => field.uniqueId === line.fieldId)[0]
                          .customName
                      }
                    </div>
                    <div className="ellipsis item-calculated-type">{LINE_CALCULATED_TYPES[line.calculatedType]}</div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {linkageParam.visible && (
        <LinkageContext.Provider value={linkageCtxValue}>
          <ModalLinkage visible={linkageParam.visible} onCancel={cancelLinkageSetting} onOk={confirmLinkageSetting} />
        </LinkageContext.Provider>
      )}
      {visibleModalAuxiliaryLine && (
        <AuxiliaryLineContext.Provider value={auxiliaryLineCtxValue}>
          <ModalAuxiliaryLine
            visible={visibleModalAuxiliaryLine}
            onCancel={cancelAuxiliaryLineSetting}
            onOk={confirmAuxiliaryLineSetting}
          />
        </AuxiliaryLineContext.Provider>
      )}
    </div>
  );
}

export default AdvancedPanelTab;
