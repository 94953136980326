import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { Provider } from 'mobx-react';
import 'normalize.css/normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './style/style.scss';
// import App from './App';
import Stores from '@/store';
import { HashRouter } from 'react-router-dom';
import microApp, { MicroApp } from '@micro-zoe/micro-app';
import './App.less';
import { menus } from './config/menus';
import { routes } from './config/routes';
import { uaaApp } from './lib/core/uaaApp';
import { udConfigProvider } from './lib/core/udConfigProvider';
import { IUaaAppStartOptions } from './lib/types';
import AppContainer from './pages/AppContainer';
import Login from './pages/Login';
// 导入svg
import './icons';
import { StoresContext } from './store/context';

declare global {
  interface Window {
    microApp: MicroApp;
  }
}

udConfigProvider.http.requestBefore = config => {
  config = { ...config, headers: { ...config.headers, accountType: 0 } };
  return config;
};

if (process.env.REACT_APP_ENV == 'local') {
  // require("../mock")
}

let options: IUaaAppStartOptions = {
  appCode: 'int-bi-app',
  routes: routes,
  loginComponent: Login,
  success: () => {
    udConfigProvider.api.useConditionsField = false;
    if (window.location.hash === '#/' || !window.location.hash) {
      // 如果没有hash数据，或者#/时，获取默认第一个菜单进行跳转
      // const menuKey = uaaApp.menus[0].children && uaaApp.menus[0].children.length ? uaaApp.menus[0].children[0].key : uaaApp.menus[0].key;
      // const path = uaaApp.routesMaps[menuKey]?.path;
      // window.location.hash = path as string;
    }
    let isInit = sessionStorage.getItem('isInit');
    if (!isInit) {
      window.location.hash = '/';
      sessionStorage.setItem('isInit', 'true');
    }
    ReactDOM.render(
      // <App />,
      <StoresContext.Provider value={Stores}>
        <ConfigProvider locale={zh_CN}>
          <Provider {...Stores}>
            <HashRouter>
              <AppContainer />
            </HashRouter>
          </Provider>
        </ConfigProvider>
      </StoresContext.Provider>,
      document.getElementById('root')
    );
  }
};

if (process.env.REACT_APP_ENV === 'local') {
  options.local = {
    useLocalMenu: false,
    menus: menus,
    loginApiBaseUrl: '',
    username: 'admin',
    password: '123456'
  };
}

// 初始化 micro-app
microApp.start();
window.microApp = microApp;

uaaApp.start(options);
