import React, { useContext } from 'react';
import { useStore } from '@/store/useStore';
import { WorkplaceContext } from './context';
import { InputNumber, Button, message, Radio, Checkbox, Select } from 'antd';
import _ from 'lodash';
import { FIELD_AREAS } from '@/utils/enum';
import { checkDuplicate } from './util';
import FieldArea from './field-area';
import { TypeFieldArea } from './data';
import './style/data-panel-field.less';

const FIELD_AREA_KEYS = [
  'dimensionFields',
  'xaxis',
  'measureFields',
  'yaxis',
  'yaxisExt',
  'legend',
  'filterFields',
  'compareFields',
  'drillDownFields'
];
const { Option } = Select;

function CubePanelTab() {
  const { setTriggerUpdate } = useContext(WorkplaceContext);
  const { dashboardStore } = useStore();
  const { activeChart } = dashboardStore;

  const handleDataMaxLenLimitValChange = val => {
    const { frontChartId } = activeChart;
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig.dataMaxLengthLimit = val;
    dashboardStore.modChart({ frontChartId, updatedItem });
  };

  const handleRefreshTimeValChange = (key, val) => {
    const { frontChartId } = activeChart;
    const updatedItem = _.cloneDeep(activeChart);
    _.set(updatedItem.dataConfig, key, val);
    dashboardStore.modChart({ frontChartId, updatedItem });
  };

  const handleUpdate = () => {
    // validate
    const {
      chartType,
      dataConfig: { dimensionFields, measureFields, filterFields, filters, compareField, xaxis, yaxis, yaxisExt }
    } = activeChart;
    if (Array.isArray(dimensionFields) && !dimensionFields.length) {
      message.error(`${FIELD_AREAS['dimensionFields']}缺少必填字段`);
      return;
    }
    if (Array.isArray(measureFields) && !measureFields.length) {
      message.error(`${FIELD_AREAS['measureFields']}缺少必填字段`);
      return;
    }
    if (chartType === 'combination' && ((Array.isArray(xaxis) && !xaxis.length) || !xaxis)) {
      message.error(`${FIELD_AREAS['xaxis']}缺少必填字段`);
      return;
    }
    if (Array.isArray(filterFields) && filterFields.length && !filters.length) {
      message.error('请设置筛选字段过滤条件');
      return;
    }
    if (chartType === 'tableMultidimensional' && !compareField) {
      message.error('请设置对比字段条件');
      return;
    }
    // 度量字段唯一性校验
    if (measureFields && checkDuplicate(measureFields)) {
      message.error('度量存在重复项');
      return;
    }
    if (chartType === 'combination' && checkDuplicate([...(yaxis ? yaxis : []), ...(yaxisExt ? yaxisExt : [])])) {
      message.error('度量存在重复项');
      return;
    }
    setTriggerUpdate(true);
  };

  const handleBranchTagValueChange = ev => {
    const { frontChartId } = activeChart;
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig.branchTag = ev.target.value;
    dashboardStore.modChart({ frontChartId, updatedItem });
  };

  const handleChartTypeValueChange = (fieldArea, index, chartType) => {
    const { frontChartId } = activeChart;
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.dataConfig[fieldArea] = updatedItem.dataConfig[fieldArea].map((field, fieldIdx) => {
      if (fieldIdx === index) {
        return {
          ...field,
          chartType
        };
      }
      return field;
    });
    dashboardStore.modChart({ frontChartId, updatedItem });
  };

  const sortedDataConfigProps = {};
  FIELD_AREA_KEYS.forEach(key => {
    Object.keys(activeChart.dataConfig)
      .filter(item => FIELD_AREA_KEYS.includes(item))
      .forEach(item => {
        if (item === key) {
          sortedDataConfigProps[key] = activeChart.dataConfig[key];
        }
      });
  });
  return (
    <div className="data-panel-field-wrapper">
      <div className="areas-wrapper">
        {Object.keys(sortedDataConfigProps).map((key: TypeFieldArea) => (
          <FieldArea
            key={key}
            type={key}
            fields={activeChart.dataConfig[key]}
            onChartTypeValueChange={handleChartTypeValueChange}
          />
        ))}
        {activeChart.chartType === 'radar' && (
          <div className="branch-tag-wrapper">
            <Radio.Group
              size="small"
              value={activeChart.dataConfig.branchTag || 'dimension'}
              onChange={handleBranchTagValueChange}
            >
              <Radio value="dimension">维度作为分支标签</Radio>
              <Radio value="measure">度量作为分支标签</Radio>
            </Radio.Group>
          </div>
        )}
      </div>
      <div className="auto-refresh-wrapper">
        <div className="auto-refresh-time-enable">
          <Checkbox
            className="checkbox-small"
            checked={activeChart.dataConfig.freshTime && activeChart.dataConfig.freshTime.isFreshEnable}
            onChange={ev => handleRefreshTimeValChange('freshTime.isFreshEnable', ev.target.checked)}
          >
            自动刷新
          </Checkbox>
        </div>
        {activeChart.dataConfig.freshTime && activeChart.dataConfig.freshTime.isFreshEnable && (
          <>
            <div className="auto-refresh-time-number">
              <InputNumber
                defaultValue={5}
                min={activeChart.dataConfig.freshTime.freshTimeUnit === 'min' ? 1 : 5}
                value={activeChart.dataConfig.freshTime.freshTimeNumber}
                onBlur={ev => handleRefreshTimeValChange('freshTime.freshTimeNumber', ev.target.value)}
              />
            </div>
            <div className="auto-refresh-time-unit">
              <Select
                size="small"
                value={activeChart.dataConfig.freshTime.freshTimeUnit}
                onChange={val => handleRefreshTimeValChange('freshTime.freshTimeUnit', val)}
              >
                <Option key="min" value="min">
                  分
                </Option>
                <Option key="sec" value="sec">
                  秒
                </Option>
              </Select>
            </div>
          </>
        )}
      </div>
      <div className="update-wrapper">
        <div className="result-display">
          <span className="title">结果展示</span>
          <InputNumber
            defaultValue={1000}
            min={1}
            value={activeChart.dataConfig.dataMaxLengthLimit}
            onChange={handleDataMaxLenLimitValChange}
            className="theme-dark"
          />
        </div>
        <div className="btn-wrapper">
          <Button type="primary" block onClick={handleUpdate}>
            更新
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CubePanelTab;
