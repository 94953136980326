import { formatNumber } from '@/utils/utils';

export const BASE_MAP = {
  tooltip: {
    formatter: (params: any) => {
      const {
        data: { name, displayValue, areaData },
        marker
      } = params;
      if (name && displayValue && areaData) {
        return `${name}<br/>
           ${marker} ${Object.keys(areaData)[0]}<label style="margin-left: 32px">${displayValue}</label>
         `;
      }
    }
  },
  visualMap: {
    show: false,
    selectModal: false,
    pieces: [],
    left: 'left',
    splitNumber: 6,
    type: 'piecewise',
    text: ['高', '低']
  },
  geo: {
    type: 'map',
    map: 'china',
    roam: false, // 一定要关闭拖拽
    // 地图下钻 不能添加zoom 和center 属性，不然下钻地图也会沿用父类的属性，从而下钻地图出现不居中问题
    zoom: 1.2, // 地图放大
    // center: [105, 36], // 调整地图位置
    label: {
      show: true,
      fontSize: '10',
      color: 'rgba(0,0,0,0.7)'
    },
    itemStyle: {
      areaColor: '#3a7fd5',
      borderColor: '#0a53e9', //线
      shadowColor: '#092f8f', //外发光
      shadowBlur: 10
    }
  },
  emphasis: {
    label: {
      show: false
    },
    itemStyle: {
      areaColor: '#3a7fd5' //悬浮区背景
    }
  },
  series: [
    {
      type: 'map',
      map: 'china',
      roam: false,
      zoom: 1.2, // 地图放大
      // aspectScale: 0.75, //长宽比
      showLegendSymbol: false, // 存在legend时显示
      label: {
        show: true,
        color: '#535353',
        fontSize: 10
      },
      itemStyle: {
        areaColor: '#fff',
        borderColor: '#389dff',
        borderWidth: 0.5
      },
      // 高亮状态下的多边形和标签样式
      emphasis: {
        label: {
          show: false,
          color: '#fff'
        },
        itemStyle: {
          areaColor: '#1e3166',
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 5,
          borderWidth: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      data: []
    }
  ]
};

export const BUBBLE_MAP = {
  tooltip: {
    trigger: 'item',
    // formatter: ({ data, marker }) => `${marker} ${data.name}: ${data.value[2]}`
    formatter: params => {
      const {
        data: { name, displayValue, seriesName },
        marker
      } = params;
      if (name && displayValue && seriesName) {
        return `${name}<br/>
        ${marker} ${seriesName}<label style="margin-left: 32px">${displayValue}</label>
      `;
      }
    }
  },
  geo: {
    map: 'china',
    roam: false,
    zoom: 1.2,
    itemStyle: {
      areaColor: '#eee',
      borderColor: '#fff'
    },
    emphasis: {
      label: {
        show: false
      },
      itemStyle: {
        areaColor: '#ebedf0',
        borderColor: '#fff'
      },
      tooltip: {
        show: false
      }
    }
  },
  series: [
    {
      type: 'scatter',
      coordinateSystem: 'geo',
      label: {
        formatter: '{b}',
        position: 'bottom',
        show: true
      },
      labelLayout: {
        hideOverlap: true
      },
      data: []
    }
  ]
};
