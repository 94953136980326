const allComponents = {
  charts: {
    label: '图表',
    opts: [
      {
        label: '列表',
        type: 'list',
        value: [
          { type: 'table', name: '交叉表' },
          { type: 'tableMultidimensional', name: '对比交叉表' },
          { type: 'ranking-list', name: '排行榜列表' }
        ]
      },
      {
        label: '趋势',
        type: 'trend',
        value: [
          { type: 'line', name: '折线图' },
          { type: 'area', name: '面积图' },
          { type: 'combination', name: '组合图' }
        ]
      },
      {
        label: '比较',
        type: 'compare',
        value: [
          { type: 'bar', name: '柱状图' },
          { type: 'bar-stack', name: '堆积柱状图' },
          { type: 'bar-stack-percent', name: '百分比堆积柱状图' },
          { type: 'bar-horizontal', name: '条形图' },
          { type: 'strip-stack', name: '堆积条形图' },
          { type: 'strip-stack-percent', name: '百分比堆积条形图' }
        ]
      },
      {
        label: '分布',
        type: 'distributed',
        value: [
          { type: 'pie', name: '饼图' },
          { type: 'radar', name: '雷达图' },
          { type: 'pie-rose', name: '玫瑰图' },
          { type: 'treemap', name: '矩形树图' }
        ]
      },
      {
        label: '指标',
        type: 'indicators',
        value: [
          { type: 'gauge', name: '仪表盘' },
          { type: 'kpi', name: 'kpi指标卡' }
        ]
      },
      {
        label: '关系',
        type: 'relation',
        value: [
          { type: 'funnel', name: '漏斗图' },
          { type: 'scatter', name: '散点图' }
        ]
      },
      {
        label: '空间',
        type: 'space',
        value: [
          { type: 'map', name: '色彩地图' },
          { type: 'bubble-map', name: '气泡地图' }
        ]
      }
    ]
  },
  /* list: {
    label: '表格',
    opts: [
      {
        label: '表格',
        type: 'table',
        value: [
          { type: 'table', name: '交叉表' },
          { type: 'tableMultidimensional', name: '对比交叉表' }
        ]
      }
    ]
  }, */
  element: {
    label: '图文',
    opts: [
      {
        label: '图文',
        type: 'element',
        value: [
          { type: 'text', name: '文字' },
          { type: 'image', name: '图片' },
          { type: 'statistic', name: '数字翻牌器' },
          { type: 'curTime', name: '当前时间' }
        ]
      }
    ]
  },
  media: {
    label: '媒体',
    opts: [
      {
        label: '媒体',
        type: 'media',
        value: [{ type: 'video', name: '视频' }]
      }
    ]
  },
  decoration: {
    label: '装饰',
    opts: [
      {
        label: '边框',
        type: 'border',
        value: [
          { type: 'border1', name: '边框1' },
          { type: 'border2', name: '边框2' },
          { type: 'border3', name: '边框3' },
          { type: 'border4', name: '边框4' },
          { type: 'border5', name: '边框5' },
          { type: 'border6', name: '边框6' },
          { type: 'border7', name: '边框7' },
          { type: 'border8', name: '边框8' },
          { type: 'border9', name: '边框9' },
          { type: 'border10', name: '边框10' },
          { type: 'border11', name: '边框11' },
          { type: 'border12', name: '边框12' },
          { type: 'border13', name: '边框13' },
          { type: 'border14', name: '边框14' },
          { type: 'border15', name: '边框15' },
          { type: 'border16', name: '边框16' },
          { type: 'border17', name: '边框17' },
          { type: 'border18', name: '边框18' },
          { type: 'border19', name: '边框19' },
          { type: 'border20', name: '边框20' }
        ]
      },
      {
        label: '线条',
        type: 'd-line',
        value: [{ type: 'd-line', name: '线条' }]
      }
    ]
  }
};

export default allComponents;
