import stores from '@/store';
import { splitString } from '@/utils/utils';
import { Button, Collapse, message, notification } from 'antd';
import { AxiosRequestConfig } from 'axios';
import { trace } from 'console';
import _ from 'lodash';
import React, { CSSProperties, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { ITableWatermark, uaaApp } from '..';

const { Panel } = Collapse;

interface IUdConfigProvider {
  /** 请求相关 */
  http: {
    /** 使用 Rpc 包装格式 */
    useRpcWrap: boolean;
    /** 使用 */
    useBizErrorHandler: boolean;
    useSysErrorHandler: boolean;
    /** 错误提示方法 */
    errorTip: (tip: { message?: string; description?: string; traceId?: string }) => void;

    /** 设置token到请求中 */
    requestBeforeSetToken: (config: AxiosRequestConfig) => AxiosRequestConfig;
    /** 请求发出前 */
    requestBefore: (config: AxiosRequestConfig) => AxiosRequestConfig;
    /** 失败时的处理函数 */
    errorHandler: {
      [key in string | number]: (error: any) => void;
    };
    /**
     * 业务异常处理
     */
    bizErrorHandler: {
      [errorIdentifier in string]: (error: IException) => void;
    };
  };
  /** 授权相关 */
  auth: {
    canUse?: (key: string) => boolean;
  };
  api: {
    /** 请求分页数据时，是否把条件放到 conditions 字段中 */
    useConditionsField: boolean;
  };
  ui: {
    getPageContainer: () => Window | HTMLElement;
    table: {
      watermark: ITableWatermark;
    };
  };
}

/** 配置服务 */
let udConfigProvider: IUdConfigProvider = {
  http: {
    useRpcWrap: true,
    useBizErrorHandler: true,
    useSysErrorHandler: true,

    errorTip: (tip: { message?: string; description?: string; traceId?: string }) => {
      /* let args = _.extend({}, { message: '系统提示' }, tip) as any;
      if (tip.description === 'token无效或已过期') {
        args.onClose = () => {
          uaaApp.signOut();
        };
      }
      notification.error(args); */
      errorNotify({
        identifier: 'SYSTEM',
        code: 500,
        reason: tip.message ? tip.message : '系统异常',
        message: tip.description,
        traceId: tip.traceId
      });
    },

    requestBeforeSetToken: (config: AxiosRequestConfig): AxiosRequestConfig => {
      let token = uaaApp.getToken();
      if (token) {
        config.headers = {
          Authorization: token.accessToken,
          ...config.headers
        };
      }
      return config;
    },
    requestBefore: (config: AxiosRequestConfig): AxiosRequestConfig => {
      return config;
    },

    /** 失败时的处理函数 */
    errorHandler: {
      request: error => {
        let func = _.get(error, 'config.errorTip', udConfigProvider.http.errorTip);
        func({ message: '请求失败', description: error });
      },
      400: (error: any) => {
        error.config.errorTip({
          message: '错误的请求',
          description: _.get(error, 'response.data.msg', error.statusText)
        });
      },
      401: () => {
        notification.error({
          message: '登录超时',
          description: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ flex: '1' }}>请点击退出按钮后重新登录。</span>
              <Button
                type="primary"
                style={{ fontSize: '12px' }}
                size="small"
                onClick={() => {
                  uaaApp.signOut();
                }}
              >
                退出
              </Button>
            </div>
          ),
          onClose: () => {
            uaaApp.signOut();
          }
        });
      },
      403: (error: any) => {
        error.config.errorTip({ message: '没有权限', description: '你的账号没有该操作的权限' });
      },
      404: (error: any) => {
        error.config.errorTip({ description: '请求的资源不存在' });
      },
      500: (error: any) => {
        // 服务器500，且请求体里code:12004为登录超时
        if (_.get(error, 'response.data.status') === 1008) {
          const errorHandle = udConfigProvider.http.errorHandler[401];
          errorHandle(error);
          return;
        }
        let msg = _.get(error, 'response.data.data.msg', _.get(error, 'response.data.msg', '系统错误，请稍后重试！'));
        if (error.data) {
          // 非服务器错误接口code:500情况
          msg = _.get(error, 'data.data.msg', _.get(error, 'data.msg', '系统错误，请稍后重试！'));
        }
        error.config.errorTip({ description: msg });
      },
      other: (error: any) => {
        let msg = '系统错误，请稍后重试！';
        if (error.message == 'Network Error') {
          msg = '网络异常，请检查你的网络。';
        } else {
          if (process.env.NODE_ENV == 'development') {
            msg = error.message;
          }
        }
        error.config.errorTip({ description: msg });
      }
    },

    bizErrorHandler: {
      AUTH: (error: IException) => {
        const errorCode = error.code;
        if (errorCode === 1202 || errorCode === 1205 || errorCode === 1206 || errorCode === 1210 || errorCode === 1209) {
          notification.error({
            message: error.reason,
            description: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ flex: '1' }}>请点击重新登录按钮或关闭通知，到登录页面重新登录</span>
                <Button
                  type="primary"
                  style={{ fontSize: '12px' }}
                  size="small"
                  onClick={() => {
                    uaaApp.signOut();
                  }}
                >
                  重新登录
                </Button>
              </div>
            ),
            onClose: () => {
              uaaApp.signOut();
            }
          });
        } else {
          errorNotify(error);
        }

      },
      LICENSE: (error: IException) => {
        const msg = error.message ? error.message : error.reason;
        stores.licenseStore.updateLicense(false, msg);
      },
      global: (error: IException) => {
        errorNotify(error);
      }
    }
  },
  auth: {
    canUse: null
  },
  api: {
    useConditionsField: true
  },
  ui: {
    getPageContainer: () => {
      return document.querySelector<HTMLElement>('.ud-main-content') || window;
    },
    table: {
      watermark: {
        enable: false,
        color: '#000',
        opacity: 0.06,
        fontSize: 18,
        angle: -25,
        text: () => {
          let info = uaaApp.getSysInfo();
          return info ? info.profile.id : '';
        },
        width: 80,
        height: 50,
        x: 10,
        y: 25
      }
    }
  }
};

/**
 * 信息超长自动折叠
 *
 * from https://juejin.cn/post/6963904955262435336
 */
const NotifyDescription = styled.div`

  .ant-collapse {
    border: 0px;
    border-bottom: 0px;
    border-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    background-color: #fff;
    font-size: 12px;
    

    .ant-collapse-header {
      background-color: #fff;
      padding: 4px 16px;
      color: #0089C4;

      .ant-collapse-arrow {
        left: 0px;
      }
    }

    .ant-collapse-content {
      border-top: 0px;
      max-height: 240px;
      overflow: auto;

      .ant-collapse-content-box {
        padding: 0px;

        p {
          margin: 0;
        }
      }
    }

    .ant-collapse-item {
      border-bottom: 0px;
    }
  }
  
`;

const errorNotify = (error: IException) => {
  const { reason, message, traceId } = error;

  const meesageStyle: CSSProperties = { fontSize: '14px' };
  const traceIdStyle: CSSProperties = { fontSize: '12px', margin: 0 };

  notification.error({
    style: {
      maxHeight: '500px',
      overflow: 'scroll'
    },
    // duration: null,
    message: <span style={meesageStyle}>{reason}</span>,
    description: (
      <NotifyDescription>
        {traceId && (
          <>
            <p style={traceIdStyle}>错误日志ID: {traceId}</p>
            <p style={traceIdStyle}>可将错误日志ID复制给系统人员以处理问题</p>
          </>
        )}
        {message && (
          <Collapse>
            <Panel header="详细信息" key="1">
              <p>{message}</p>
            </Panel>
          </Collapse>
        )}

      </NotifyDescription>
    )
  });
};

export { udConfigProvider };
