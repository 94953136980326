import React, { FC, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import cls from 'classnames';
import { Table } from 'antd';
import { PaginationConfig } from 'antd/es/pagination';
import { toThousands } from '@/utils/utils';
import './index.less';

const TableNormal: FC<any> = ({
  chartType,
  chart,
  hideTitle,
  theme,
  summary,
  activeGridItemLayout,
  tableFields,
  drillDownFields,
  onItemClick,
  showPagination
}) => {
  const [state, setState] = useState({
    titleShow: hideTitle ? false : true,
    columns: [],
    dataSource: [],
    scrollY: 300,
    depth: 0
  });
  const [pagination, setPagination] = useState<PaginationConfig>({
    position: 'bottom',
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '100']
  })
  const [style, setStyle] = useState<any>({
    bg: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: '8px',
      overflow: 'hidden',
      background: 'rgba(255, 255, 255, 0)'
    },
    title: {
      margin: '0 0',
      padding: '0 0 8px',
      width: '100%',
      textAlign: 'left',
      fontSize: '18px',
      color: '#303133',
      fontStyle: 'normal',
      fontWeight: 'normal'
    },
    header: {
      height: '36px',
      background: 'rgba(29, 29, 29, 1)',
      fontSize: '12px',
      color: '#fff',
      fontWeight: 'bold'
    },
    cell: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '8px',
      height: '36px',
      background: 'rgba(20, 20, 20, 1)',
      fontSize: '12px',
      color: '#fff'
    },
    cellStripe: {
      height: '36px',
      background: 'rgba(20, 20, 20, 1)',
      fontSize: '12px',
      color: '#fff'
    }
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLParagraphElement>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    initStyle();
  }, [chart]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const scrollY = calcHeight() + (hideTitle ? 16 : 0);
    const gap = 18;
    setState({
      ...state,
      scrollY: scrollY - gap
    });
  }, [state.titleShow, style.title.fontSize, style.header.height, state.depth, activeGridItemLayout]);

  const initStyle = () => {
    const newStyle = _.cloneDeep(style);
    if (chart.backgroundColor) {
      newStyle.bg.background = chart.backgroundColor;
    }
    if (chart.customAttr) {
      const { color, size } = chart.customAttr;
      if (color) {
        newStyle.header.background = color.tableHeaderBgColor;
        newStyle.header.color = color.tableFontColor;
        newStyle.cell.background = color.tableCellBgColor;
        newStyle.cell.color = color.tableFontColor;
      }
      if (size) {
        newStyle.header.height = size.tableHeaderHeight + 'px';
        newStyle.header.fontSize = size.tableHeaderFontSize + 'px';
        newStyle.cell.height = size.tableCellHeight + 'px';
        newStyle.cell.fontSize = size.tableCellFontSize + 'px';
      }
    }
    if (chart.title) {
      const { show, hPosition, textStyle } = chart.title;
      newStyle.title.display = show ? 'block' : 'none';
      newStyle.title.textAlign = hPosition;
      newStyle.title.fontSize = textStyle.fontSize + 'px';
      newStyle.title.color = textStyle.color;
      newStyle.title.fontStyle = textStyle.fontStyle;
      newStyle.title.fontWeight = textStyle.fontWeight;
    }
    setStyle({
      ...style,
      ...newStyle
    });

    setState({
      ...state,
      titleShow: chart.title && chart.title.show && state.titleShow,
      columns: chart.columns,
      dataSource: chart.dataSource,
      scrollY: calcHeight(),
      depth: getDepth(chart.columns)
    });
    setPagination({
      ...pagination,
      total: chart.dataSource.length
    });
  };

  const calcHeight = () => {
    if (containerRef.current) {
      const currentHeight = containerRef.current.offsetHeight;
      const contentHeight = titleRef.current
        ? currentHeight - titleRef.current.offsetHeight - 16
        : currentHeight - (hideTitle ? 0 : 16);
      const tableHeaderHeight =
        chartType === 'tableMultidimensional'
          ? parseInt(style.header.height) * state.depth
          : parseInt(style.header.height);
      return contentHeight - tableHeaderHeight;
    }
  };

  const convertColumns = columns => {
    const recursionFn = data => {
      if (Array.isArray(data) && data.length) {
        data.forEach(item => {
          item.title = <span style={calTableThHeight(item)}>{item.title}</span>;
          item.render = (text, record, index) => {
            // console.log('render:', text, record, index);
            const drillDownFieldSize = Array.isArray(drillDownFields) && drillDownFields.length;
            let drillDownFieldIdx = -1;
            if (drillDownFieldSize) {
              drillDownFieldIdx = drillDownFields.findIndex(field => field.customName === item.dataIndex);
            }
            const enableDrillDown = drillDownFieldIdx >= 0 && drillDownFieldIdx < drillDownFieldSize - 1;
            return (
              <span
                style={{
                  ...style.cell,
                  textDecoration: enableDrillDown ? 'underline' : 'none',
                  overflow: 'hidden'
                }}
                onClick={
                  enableDrillDown
                    ? ev => {
                      ev.stopPropagation();
                      onItemClick({
                        ...record,
                        data: { index: record.index, chartIndex: record.chartIndex, name: text },
                        seriesType: chartType
                      });
                    }
                    : null
                }
              >
                <label title={text}>{text}</label>
              </span>
            );
          };
          if (item.children) {
            recursionFn(item.children);
          }
        });
      }
      return data;
    };
    return recursionFn(_.cloneDeep(columns));
  };

  const calTableThHeight = item => {
    if (chartType === 'tableMultidimensional') {
      const { key, children } = item;
      if (!children) {
        // is leaf node
        const curLevel = key.split('-').length;
        const tableHeaderHeight = chart.customAttr ? chart.customAttr.size.tableHeaderHeight : 36;
        return {
          ...style.header,
          height: (state.depth - curLevel + 1) * tableHeaderHeight
        };
      }
      return style.header;
    }
    return style.header;
  };

  const getDepth = data => {
    let max = Number.MIN_VALUE;
    const helpFn = (arr, count = 0) => {
      if (Array.isArray(arr) && arr.length > 0) {
        arr.forEach(item => {
          max = Math.max(count + 1, max);
          if (item.children) helpFn(item.children, count + 1);
        });
      }
    };
    helpFn(data);
    return max;
  };

  const getSummary = (data) => {
    if (!data) return null;
    const rowData = data[0];
    const keys = Object.keys(rowData);
    return data.reduce(
      (accu, curr) => {
        keys.slice(1).forEach(key => {
          if (!isNaN(curr[key])) {
            accu[key] = parseFloat((accu[key] + Number(curr[key])).toFixed(10));
          }
        });
        return accu;
      },
      Object.keys(rowData).reduce((accu, curr, currIndex) => {
        if (currIndex === 0) {
          accu[curr] = '总计';
        }
        if (!isNaN(rowData[curr])) {
          accu[curr] = 0;
        }
        return accu;
      }, {})
    );
  };

  const onPaginationChange = pagination => {
    const { current, pageSize } = pagination;
    setPagination({
      ...pagination,
      current,
      pageSize
    })
  };

  // console.log('TableNormal render: ', state, style, summary);
  let dataSource = [];
  let len = state.dataSource.length;
  if (state.dataSource && len > 0) {
    dataSource = state.dataSource.map(item => {
      for (let key in item) {
        const fieldInfo = tableFields.find(field => field.customName === item[key]);
        if (!isNaN(Number(item[key])) && fieldInfo && fieldInfo.attribute.valueType !== 'DATE') {
          item[key] = toThousands(item[key]);
        }
      }
      return item;
    });
    console.log('------summary:', summary, 'showPagination:', showPagination, state, pagination);

    // 判断是否有汇总
    if (summary) {
      const start = showPagination ? (pagination.current - 1) * pagination.pageSize : 0;
      const end = showPagination ? pagination.current * pagination.pageSize - 1 : dataSource.length;
      dataSource = [...dataSource.slice(start, end), getSummary(dataSource.slice(start, end))];
    } else {
      const lastItem = state.dataSource[len - 1];
      const isLastItemSummary = Object.values(lastItem).includes('总计');
      if (isLastItemSummary) {
        dataSource = dataSource.slice(0, -1);
      }
    }
  }
  return (
    <div
      className={cls('table-normal-wrapper', { 'theme-light': theme && theme === 'light', hasSummary: summary })}
      ref={containerRef}
      style={style.bg}
    >
      {state.titleShow && (
        <p ref={titleRef} style={style.title}>
          {chart.title.text}
        </p>
      )}
      <div className="table-normal-content">
        <Table
          className={`table-normal ${chartType === 'tableMultidimensional' ? 'compare-table' : ''}`}
          rowKey={(row, index: number) => index + ''}
          size="small"
          scroll={{ y: state.scrollY - (showPagination ? 70 : 0), x: state.columns.length * 100 }}
          columns={convertColumns(state.columns).map(col => ({ ...col, ellipsis: true }))}
          dataSource={dataSource}
          pagination={showPagination ? pagination : false}
          onChange={onPaginationChange}
        />
      </div>
    </div>
  );
};

export default TableNormal;
