import React, { Component } from 'react';
import { Form, Input, Icon, Button, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { http } from '../../lib';
import { IUaaTokenData, IUaaAppInfo } from '../../lib/types';
import { uaaApp } from '../../lib/core/uaaApp';
import APIS from '@/apis/api';
import './login.less';
import ScreenAdpter from '../ScreenAdpter/ScreenAdpter';
import { uuid } from '@/utils/utils';
/* eslint-disable */
const logo = require('../../assets/logo.png');

interface ILoginProps extends FormComponentProps {
  loginApiBaseUrl: string;
  username?: string;
  password?: string;
}

interface IVerifyCode {
  srcBase64: string;
  uuid: string;
}
interface ILoginState {
  verifyCode?: IVerifyCode;
  loading?: boolean;
  requestUUID?: string;
  visibleVerifyCode: boolean;
}

interface ILoginRes {
  ticket: string;
  token: string;
  agentList: any[];
  initPassword: boolean;
}
class Login extends Component<ILoginProps, ILoginState> {
  private authorization: IUaaTokenData = {} as IUaaTokenData;

  constructor(props: ILoginProps) {
    super(props);
    this.state = {
      loading: false,
      visibleVerifyCode: false
    };
    if (this.props.loginApiBaseUrl !== null && this.props.loginApiBaseUrl !== undefined) {
      http.defaults.baseURL = this.props.loginApiBaseUrl;
    }
  }

  public componentDidMount() {
    // this.getVerifyCode()
    document.title = '欢迎登录';
    const requestUUID = uuid();
    this.setState({
      ...this.state,
      requestUUID
    });
    this.getOrRefrenshVerifyCode(requestUUID);
  }

  //   public componentWillUnmount() {}

  public render() {
    const year = new Date().getFullYear();
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-page-content">
        <div className="login-form-content">
          <img className="login-page-logo" src={logo}></img>
          <div className="login-form">
            <div className="login-form-title">欢迎登录</div>
            <Form onSubmit={this.handleSubmit} className="login-form-item">
              <Form.Item>
                {getFieldDecorator('username', {
                  initialValue: this.props.username || '',
                  rules: [{ required: true, message: '请输入登录账户' }]
                })(
                  <Input
                    className="login-form-input"
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="请输入登录账户"
                    onChange={this.userNameChange}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  initialValue: this.props.password || '',
                  rules: [{ required: true, message: '请输入登录密码' }]
                })(
                  <Input
                    className="login-form-input"
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="请输入登录密码"
                  />
                )}
              </Form.Item>
              {this.state.visibleVerifyCode && (
                <Form.Item>
                  {getFieldDecorator('captcha', {
                    initialValue: '',
                    rules: [{ required: true, message: '请输入验证码' }]
                  })(
                    <div className="form-item-captcha-wrapper">
                      <Input
                        className="login-form-input"
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="请输入验证码"
                      />
                      <img
                        src={this.state.verifyCode?.srcBase64}
                        onClick={() => this.getOrRefrenshVerifyCode(this.state.requestUUID)}
                      />
                    </div>
                  )}
                </Form.Item>
              )}

              <Form.Item style={{ textAlign: 'center', margin: '0' }}>
                <Button
                  loading={this.state.loading}
                  style={{ lineHeight: '1' }}
                  type="primary"
                  htmlType="submit"
                  className="login-form-btn"
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="copy-right">
            <div>
              {`Copyright © 2021-${year} 矩子数智 版权所有`}
            </div>
            <div>当前版本 V1.4.0</div>
          </div>
        </div>
      </div>
    );
  }

  private getOrRefrenshVerifyCode = (requestUUID: string) => {
    // http.get<IVerifyCode>('/api/uaa/uaa/sso/ssoCode').then(res => {
    http
      .get(APIS.VisibleCaptcha, {
        headers: {
          'X-captcha-r-id': requestUUID
        }
      })
      .then(res => {
        this.setState({
          ...this.state,
          visibleVerifyCode: res.data
        });
        if (res.data) {
          http
            .get(APIS.GenerateCaptcha, {
              headers: {
                'X-captcha-r-id': requestUUID
              }
            })
            .then(res => {
              this.setState({
                ...this.state,
                verifyCode: {
                  srcBase64: res.data,
                  uuid: requestUUID
                }
              });
            });
        }
      });
  };

  protected handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        this.setState({ loading: true });
        const { ...user } = values;
        user.uuid = this.state.verifyCode && this.state.verifyCode.uuid;
        http
          .post<ILoginRes>(APIS.OauthToken, user, {
            auth: { username: 'juzishuzhi', password: '123456' },
            headers: {
              'X-captcha-r-id': user.uuid
            }
          })
          .then(
            (res: any) => {
              const { accessToken, refreshToken } = res.data;
              const authorization: any = {
                accessToken: res.data && accessToken,
                accessTokenExpireTime: '' + new Date().getTime() * 24 * 3600 * 1000,
                refreshToken: res.data && refreshToken,
                refreshTokenExpireTime: '' + new Date().getTime() * 24 * 3600 * 1000
              };
              this.authorization = authorization;
              window.localStorage.setItem('token', accessToken);
              this.getMyInfo();
            },
            res => {
              this.setState({ loading: false });
            }
          )
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  private getMyInfo = () => {
    http
      .get<IUaaAppInfo>(APIS.UserInfo, {
        headers: { Authorization: this.authorization.accessToken }
      })
      .then((res: any) => {
        const { apps, ...userInfo } = res.data;
        apps[0].type = 'MENU_GROUP';
        const uaaSysInfo = {
          profile: userInfo || {},
          webUrls: {},
          urls: {},
          authorities: [],
          attributes: '',
          generalMenus: apps
        };
        uaaApp.setToken(this.authorization);
        uaaApp.setSysInfo(uaaSysInfo as IUaaAppInfo);
        window.location.reload();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  public userNameChange = e => {
    let val = e.target.value;
    // val = val.replace(/^\s+|\s+$/g, "")
    val = val.replace(/\s+/g, '');
    e.target.value = val;
  };
}

export default Form.create<ILoginProps>()(Login);
