import React, { forwardRef, useImperativeHandle, useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Form, Input, Select, Radio, Checkbox, Tooltip, Icon, Button, Divider, Modal, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { cornTypeMap, sqlConditionOpts } from './utils';
import { IItemProps, IDataFieldModel } from './data';
import { uuid } from '@/utils/utils';
import { WarningSettingCtx } from './context';
import TimerTask from '@/components/TimerTask';
import _ from 'lodash';
import { CALCULATOR_TYPES, CONTRAST_TYPES } from '@/utils/enum';

interface IFormSettingRef extends FormComponentProps {}
interface IFormSettingProps extends FormComponentProps {
  activeFormConfig: IItemProps;
  dimensionFieldOpts: IDataFieldModel[];
  measureFieldOpts: IDataFieldModel[];
  saveEvent: Function;
  cancelEvent: Function;
  originalList?: IItemProps[];
  activeIndex?: number;
}
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    sm: { span: 4 }
  },
  wrapperCol: {
    sm: { span: 20 }
  }
};
const FormSetting = forwardRef<IFormSettingRef, IFormSettingProps>((props, ref) => {
  const { handleUpdateItemStatusValid } = useContext(WarningSettingCtx);
  const {
    form,
    form: { getFieldDecorator, validateFields, getFieldsValue, setFieldsValue, setFields },
    activeFormConfig,
    dimensionFieldOpts,
    measureFieldOpts,
    saveEvent,
    cancelEvent
  } = props;
  const { uuId, monitorName } = activeFormConfig;
  useImperativeHandle(ref, () => ({
    form,
    formData
  }));
  const [formData, setFormData] = useState<any>({
    havingConditionModels: [
      {
        id: uuid(),
        dataSetField: {
          uniqueId: ''
        },
        operator: '',
        values: ['']
      }
    ],
    conditions: [],
    status: 'ENABLE',
    message: {
      emailMsg: {
        headerAddress: ''
      },
      smsMsg: {
        mobiles: ''
      },
      dingTalkMsg: {
        accessToken: ''
      },
      weChatMsg: {
        accessToken: ''
      },
      flyBookMsg: {
        accessToken: ''
      }
    },
    cronType: '',
    cronModel: null,
    notifyType: 'EMAIL'
  });
  const [visibleModalTimerTask, setVisibleModalTimerTask] = useState(false);
  const timerTaskRef = useRef<HTMLFormElement>();
  const convertMessage = msg => {
    return Object.assign(
      {},
      {
        dingTalkMsg: { accessToken: '' },
        weChatMsg: { accessToken: '' },
        smsMsg: { mobiles: '' },
        emailMsg: { headerAddress: '' },
        flyBookMsg: { accessToken: '' }
      },
      msg
    );
  };
  useEffect(() => {
    // console.log('FormSetting useEffect: ', activeFormConfig);
    // 预警方式消息转换
    const msgConverted = convertMessage(activeFormConfig.message);
    setFormData({
      ...formData,
      havingConditionModels: activeFormConfig.havingConditionModels.map(itemModel => {
        if (!itemModel.dataSetField) {
          return { ...itemModel, dataSetField: { uniqueId: '' }, id: uuid() };
        }
        return { ...itemModel, id: uuid() };
      }),
      conditions:
        activeFormConfig.conditions.length > 0
          ? activeFormConfig.conditions.map(itemCondition => ({
              ...itemCondition,
              id: uuid()
            }))
          : [],
      status: activeFormConfig.status,
      message: msgConverted,
      cronType: activeFormConfig.cronType,
      cronModel: activeFormConfig.cronModel,
      notifyType: activeFormConfig.notifyType
    });
    /* const values = getFieldsValue();
        console.log('getFieldsValue: ', values)
        values.formData.havingConditionModels = activeFormConfig.havingConditionModels;
        setFieldsValue(values);
        setFieldsValue({
            // 'formData.havingConditionModels': activeFormConfig.havingConditionModels,
            'formData.status': activeFormConfig.status,
            'formData.cornType': activeFormConfig.cornType
        }); */
  }, [activeFormConfig]);
  // 取消回调 重置表单字段
  const handleCancel = () => {
    cancelEvent();
  };
  // 保存回调
  const handleSave = () => {
    if (!monitorName) {
      handleUpdateItemStatusValid({ id: uuId, isValid: false });
      return;
    }
    // form validate
    validateFields((err, values) => {
      if (err) return;
      // validate timer task
      if (!formData.cronType) {
        message.warning('请设置定时计划');
        return;
      }
      if (_.isEmpty(formData.havingConditionModels)) {
        message.warning('至少需要选择一个度量作为条件');
        return;
      }
      saveEvent(values);
    });
  };
  // 新增度量
  const handleAddItemMeasure = () => {
    const newItem = {
      id: uuid(),
      dataSetField: {
        uniqueId: ''
      },
      operator: '',
      values: [''],
      conditionJoin: 'OR'
    };
    setFormData({
      ...formData,
      havingConditionModels: [...formData.havingConditionModels, newItem]
    });
  };
  // 删除度量
  const handleDeleteItemMeasure = id => {
    setFormData({
      ...formData,
      havingConditionModels: formData.havingConditionModels.filter(item => item.id !== id)
    });
  };
  // 新增维度
  const handleAddItemDimension = () => {
    const newItem = {
      id: uuid(),
      fieldUniqueId: '',
      operator: '',
      values: [''],
      conditionJoin: 'AND'
    };
    setFormData({
      ...formData,
      conditions: [...formData.conditions, newItem]
    });
  };
  // 删除维度
  const handleDeleteItemDimension = id => {
    setFormData({
      ...formData,
      conditions: formData.conditions.filter(item => item.id !== id)
    });
  };
  // 预警方式变更
  const handleNotifyTypeChange = e => {
    setFormData({
      ...formData,
      notifyType: e.target.value
    });
  };
  // 度量操作符变更
  const handleModelSqlConditionChange = (value, index) => {
    setFormData({
      ...formData,
      havingConditionModels: formData.havingConditionModels.map((itemModel, itemModelIndex) => {
        if (itemModelIndex === index) {
          return { ...itemModel, conditionJoin: value };
        }
        return itemModel;
      })
    });
  };
  // 维度操作符变更
  const handleConditionSqlConditionChange = (value, index) => {
    setFormData({
      ...formData,
      conditions: formData.conditions.map((itemCondition, itemConditionIndex) => {
        if (itemConditionIndex === index) {
          return { ...itemCondition, conditionJoin: value };
        }
        return itemCondition;
      })
    });
  };
  // 警告通知状态变更
  const handleStatusChange = e => {
    setFormData({
      ...formData,
      status: e.target.checked ? 'ENABLE' : 'DISABLE'
    });
  };

  const timerTaskActionHandler = () => {
    setVisibleModalTimerTask(true);
  };

  const handleTimerTaskCancel = () => {
    timerTaskRef.current.form.resetFields();
    setVisibleModalTimerTask(false);
  };

  const handleTimerTaskOk = () => {
    timerTaskRef.current.form.validateFields((err, values) => {
      if (err) return;
      const { taskFrequency, taskDate, taskTimeHour, taskTimeMinute, taskTimeSecond } = values;
      setFormData({
        ...formData,
        cronType: taskFrequency,
        cronModel: {
          cycleTime: Array.isArray(taskDate)
            ? taskDate.includes('lastDay')
              ? taskDate.filter(item => item !== 'lastDay').join(',')
              : taskDate.join(',')
            : taskDate,
          hour: taskTimeHour,
          minute: taskTimeMinute,
          second: taskTimeSecond,
          includeLastDay: Array.isArray(taskDate) && taskDate.includes('lastDay')
        }
      });
      setVisibleModalTimerTask(false);
    });
  };

  const initializeMeasureFieldVal = measureField => {
    const { uniqueId, calculatorType, ratioCalculator } = measureField;
    return `${uniqueId}-${calculatorType}${ratioCalculator ? '-' + ratioCalculator.type : ''}`;
  };

  // console.log('FormSetting render: ', formData, form.getFieldsValue());
  return (
    <>
      <Form {...formItemLayout} colon={false} labelAlign="left">
        <Form.Item
          className="form-item-measure"
          label={
            <span>
              预警条件&nbsp;
              <Tooltip title="当添加多条预警条件时，命中任意规则就会发送预警。">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {formData.havingConditionModels?.length > 0 &&
            formData.havingConditionModels.map((itemMeasure, itemMeasureIndex) => {
              return (
                <Row gutter={8} key={itemMeasure.id} className="form-item-condition-model">
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator(`formData.havingConditionModels[${itemMeasureIndex}].dataSetField.uniqueId`, {
                        rules: [{ required: true, message: '请选择度量字段' }],
                        initialValue: itemMeasure.dataSetField.uniqueId
                          ? initializeMeasureFieldVal(itemMeasure.dataSetField)
                          : undefined
                      })(
                        <Select placeholder="请选择">
                          {measureFieldOpts?.length > 0 &&
                            measureFieldOpts.map(itemMeasureField => {
                              const { uniqueId, calculatorType, ratioCalculator } = itemMeasureField;
                              const value = `${uniqueId}-${calculatorType}${
                                ratioCalculator ? '-' + ratioCalculator.type : ''
                              }`;
                              return (
                                <Option key={value} value={value}>
                                  {`${itemMeasureField.customName}(${
                                    CALCULATOR_TYPES[itemMeasureField.calculatorType]
                                  }${ratioCalculator ? '-' + CONTRAST_TYPES[ratioCalculator.type] : ''})`}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      {getFieldDecorator(`formData.havingConditionModels[${itemMeasureIndex}].operator`, {
                        rules: [{ required: true, message: '请选择操作符' }],
                        initialValue: itemMeasure.operator ? itemMeasure.operator : undefined
                      })(
                        <Select
                          placeholder="请选择"
                          onChange={value => handleModelSqlConditionChange(value, itemMeasureIndex)}
                        >
                          {sqlConditionOpts?.length > 0 &&
                            sqlConditionOpts.map(itemSqlCondition => (
                              <Option value={itemSqlCondition.value} key={itemSqlCondition.id}>
                                {itemSqlCondition.label}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  {itemMeasure.operator !== 'IS_NULL' && itemMeasure.operator !== 'IS_NOT_NULL' && (
                    <Col span={8}>
                      <Form.Item>
                        {getFieldDecorator(`formData.havingConditionModels[${itemMeasureIndex}].values[0]`, {
                          rules: [{ required: true, message: '请输入字段值' }],
                          initialValue: itemMeasure.values[0] ? itemMeasure.values[0] : undefined
                        })(<Input placeholder="请输入" />)}
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={2}>
                    <Icon
                      type="delete"
                      className="item-measure-action-delete"
                      onClick={() => handleDeleteItemMeasure(itemMeasure.id)}
                    />
                  </Col>
                </Row>
              );
            })}
          <div className="form-item-actions">
            <Button type="primary" disabled={formData.havingConditionModels.length >= 5} onClick={handleAddItemMeasure}>
              + 添加度量
            </Button>
            <span className="indicators-count">{formData.havingConditionModels.length}/5</span>
          </div>
        </Form.Item>
        <Form.Item
          label={
            <span>
              监控维度&nbsp;
              <Tooltip title="当添加多维度时，系统取交集。">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {formData.conditions?.length > 0 &&
            formData.conditions.map((itemDimension, itemDimensionIndex) => (
              <Row gutter={8} key={itemDimension.id} className="form-item-condition">
                <Col span={8}>
                  <Form.Item>
                    {getFieldDecorator(`formData.conditions[${itemDimensionIndex}].fieldUniqueId`, {
                      rules: [{ required: true, message: '请选择维度字段' }],
                      initialValue: itemDimension.fieldUniqueId ? itemDimension.fieldUniqueId : undefined
                    })(
                      <Select placeholder="请选择">
                        {dimensionFieldOpts.length > 0 &&
                          dimensionFieldOpts.map(itemDimensionField => (
                            <Option value={itemDimensionField.uniqueId} key={itemDimensionField.uniqueId}>
                              {itemDimensionField.customName}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    {getFieldDecorator(`formData.conditions[${itemDimensionIndex}].operator`, {
                      rules: [{ required: true, message: '请选择操作符' }],
                      initialValue: itemDimension.operator ? itemDimension.operator : undefined
                    })(
                      <Select
                        placeholder="请选择"
                        onChange={value => handleConditionSqlConditionChange(value, itemDimensionIndex)}
                      >
                        {sqlConditionOpts.length > 0 &&
                          sqlConditionOpts.map(itemSqlCondition => (
                            <Option value={itemSqlCondition.value} key={itemSqlCondition.id}>
                              {itemSqlCondition.label}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {itemDimension.sqlCondition !== 'IS_NULL' && itemDimension.sqlCondition !== 'IS_NOT_NULL' && (
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator(`formData.conditions[${itemDimensionIndex}].values[0]`, {
                        rules: [{ required: true, message: '请输入字段值' }],
                        initialValue: itemDimension.values[0] ? itemDimension.values[0] : undefined
                      })(<Input placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                <Col span={2}>
                  <Icon
                    type="delete"
                    className="item-dimension-action-delete"
                    onClick={() => handleDeleteItemDimension(itemDimension.id)}
                  />
                </Col>
              </Row>
            ))}
          <div className="form-item-actions">
            <Button type="primary" disabled={formData.conditions.length >= 5} onClick={handleAddItemDimension}>
              + 添加维度
            </Button>
            <span className="indicators-count">{formData.conditions.length}/5</span>
          </div>
        </Form.Item>
        <Form.Item label="定时计划" required={true}>
          <Button type="link" icon={!formData.cronType ? 'plus' : 'edit'} onClick={timerTaskActionHandler}>
            {!formData.cronType ? '添加' : '编辑'}
          </Button>
        </Form.Item>
        <Divider />
        <Form.Item label="预警方式">
          {getFieldDecorator('formData.notifyType', {
            rules: [{ required: true, message: '请选择预警方式' }],
            initialValue: formData.notifyType
          })(
            <Radio.Group onChange={handleNotifyTypeChange}>
              <Radio value="EMAIL">邮件</Radio>
              <Radio value="SMS">短信</Radio>
              <Radio value="DING">钉钉群</Radio>
              <Radio value="WE_CHAT">企业微信</Radio>
              <Radio value="FLY_BOOK">飞书群</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        {formData.notifyType === 'EMAIL' && (
          <Form.Item label="接收人">
            {getFieldDecorator('formData.message.emailMsg.headerAddress', {
              rules: [{ required: true, message: '请输入收件人地址' }],
              initialValue: formData.message.emailMsg.headerAddress
                ? formData.message.emailMsg.headerAddress
                : undefined
            })(<Input placeholder="多个接收人时英文逗号隔开" />)}
          </Form.Item>
        )}
        {formData.notifyType === 'SMS' && (
          <Form.Item label="接收人">
            {getFieldDecorator('formData.message.smsMsg.mobiles', {
              rules: [{ required: true, message: '请输入收信人手机号' }],
              initialValue: formData.message.smsMsg.mobiles ? formData.message.smsMsg.mobiles : undefined
            })(<Input placeholder="多个接收人时英文逗号隔开" />)}
          </Form.Item>
        )}
        {/* @后台 发送内容暂时不考虑 */}
        {formData.notifyType === 'DING' && (
          <Form.Item
            label={
              <span>
                密钥&nbsp;
                <Tooltip title="密钥为钉钉群/企业微信群/飞书群添加机器人Webhook地址">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('formData.message.dingTalkMsg.accessToken', {
              rules: [{ required: true, message: '请输入钉钉群密钥' }],
              initialValue: formData.message.dingTalkMsg.accessToken
                ? formData.message.dingTalkMsg.accessToken
                : undefined
            })(<Input placeholder="请输入钉钉群密钥" />)}
          </Form.Item>
        )}
        {formData.notifyType === 'WE_CHAT' && (
          <Form.Item
            label={
              <span>
                密钥&nbsp;
                <Tooltip title="密钥为钉钉群/企业微信群/飞书群添加机器人Webhook地址">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('formData.message.weChatMsg.accessToken', {
              rules: [{ required: true, message: '请输入企业微信密钥' }],
              initialValue: formData.message.weChatMsg.accessToken ? formData.message.weChatMsg.accessToken : undefined
            })(<Input placeholder="请输入企业微信密钥" />)}
          </Form.Item>
        )}
        {formData.notifyType === 'FLY_BOOK' && (
          <Form.Item
            label={
              <span>
                密钥&nbsp;
                <Tooltip title="密钥为钉钉群/企业微信群/飞书群添加机器人Webhook地址">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('formData.message.flyBookMsg.accessToken', {
              rules: [{ required: true, message: '请输入飞书群密钥' }],
              initialValue: formData.message.flyBookMsg.accessToken
                ? formData.message.flyBookMsg.accessToken
                : undefined
            })(<Input placeholder="请输入飞书群密钥" />)}
          </Form.Item>
        )}
        <Form.Item>
          {getFieldDecorator('formData.status', {
            initialValue: formData.status
          })(
            <Checkbox checked={formData.status === 'ENABLE'} onChange={handleStatusChange}>
              开启警告通知
            </Checkbox>
          )}
        </Form.Item>
      </Form>
      <div className="bottom-actions">
        <Button onClick={handleCancel}>取消</Button>
        <Button type="primary" style={{ marginLeft: '8px' }} onClick={handleSave}>
          保存
        </Button>
      </div>
      {/* 定时任务 Modal start */}
      {visibleModalTimerTask && (
        <Modal
          title={`${!formData.cronType ? '添加' : '编辑'}定时任务`}
          visible={visibleModalTimerTask}
          onCancel={handleTimerTaskCancel}
          onOk={handleTimerTaskOk}
          width={420}
          maskClosable={false}
          wrapClassName="dlg-timer-task-wrapper"
        >
          <TimerTask
            wrappedComponentRef={timerTaskRef}
            activeTimerTask={{ cronType: formData.cronType, cronModel: formData.cronModel }}
          />
        </Modal>
      )}
      {/* 定时任务 Modal end */}
    </>
  );
});
const WrappedFormSetting = Form.create<IFormSettingProps>()(FormSetting);

export default WrappedFormSetting;
