import { WidgetService } from '../service/widget-service';
import { Base } from '../data';

const genDrawPanel = () => ({
  type: 'custom',
  label: 'ibResetButton.label',
  style: {
    showTitle: false,
    mainTitle: '',
    labelPos: 'top',
    fontSize: 14,
    color: '#333',
    bold: false,
    italic: false,
    letterSpacing: 0
  },
  options: {
    attrs: {
      type: '',
      round: false,
      plain: true
    },
    value: '清空'
  },
  component: 'ib-reset-button',
  miniSizeX: 1,
  miniSizeY: 1
});

class ButtonResetServiceImpl extends WidgetService implements Base {
  public filterDialog: boolean;
  public showSwitch: boolean;
  public buttonDialog: boolean;
  constructor(options = {}) {
    Object.assign(options, { name: 'buttonResetWidget' });
    super(options);
    this.filterDialog = false;
    this.buttonDialog = true;
    this.showSwitch = false;
  }

  initDrawPanel() {
    return genDrawPanel();
  }
}
const buttonResetServiceImpl = new ButtonResetServiceImpl();
export default buttonResetServiceImpl;
