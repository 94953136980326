import React from 'react';
import cls from 'classnames';

function ChartTypeMenu({ activeChart }) {
  const {
    chartType,
    styleConfig: { title }
  } = activeChart;
  return (
    <div className="change-chart-type-menu">
      <span className="chart-type chart-list-box">
        <span className="chart-list more-chart-span">
          <i className={cls(['component-type-icon', `component-type-${chartType}`])}></i>
          <span className="chart-name-span" title={title}>
            {title}
          </span>
        </span>
      </span>
    </div>
  );
}

export default ChartTypeMenu;
