import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { useStore } from '@/store/useStore';
import { toJS } from 'mobx';
import { WorkplaceContext } from './context';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import { mergeOptions, genHighlightOption, genRelationChartMap } from './chart-type';
import { DRILL_DOWN_CHARTS } from '@/utils/enum';
import KpiCard from '@/components/chart/components/normal/KpiCard';
import TableNormal from '@/components/chart/components/normal/TableNormal';
import { message } from 'antd';

function RenderEngine({ chartIndex, chartType, dataConfig, styleConfig, advancedConfig }: any) {
  const { dashboardStore } = useStore();
  const { charts, customComps } = dashboardStore.state;
  const { activeGridItemLayout, setTriggerUpdate } = useContext(WorkplaceContext);
  const [option, setOption] = useState(null);
  const dataConfigRef = useRef(null);
  const allCompsRef = useRef(null);
  dataConfigRef.current = dataConfig;
  allCompsRef.current = [...charts, ...customComps].sort((prev, next) => prev.order - next.order);

  useEffect(() => {
    const newOption = mergeOptions({
      chartIndex,
      chartType,
      dataConfig,
      styleConfig,
      advancedConfig
    });
    setOption(newOption);
  }, [
    JSON.stringify(dataConfig.chartOptions),
    JSON.stringify(styleConfig),
    JSON.stringify(advancedConfig),
    dataConfig.branchTag
  ]);

  // 下钻到下一级
  const drillJump = useCallback(param => {
    // console.log('drillJump: ', dataConfigRef.current, chartsRef.current[param.data.chartIndex], param);
    const drillDownFields = dataConfigRef.current.drillDownFields;
    const isDrillDownAreaEmpty = Array.isArray(drillDownFields) && !drillDownFields.length;
    if (Array.isArray(drillDownFields) && param.data.index === drillDownFields.length - 1) {
      message.warning('已经钻取到最后一层了');
      return;
    }
    if (Array.isArray(drillDownFields) && param.data.index > drillDownFields.length - 1) {
      message.warning('缺少关联字段');
      return;
    }
    if (!DRILL_DOWN_CHARTS.includes(chartType)) return;
    const activeChart = _.cloneDeep(allCompsRef.current[param.data.chartIndex]);
    const { advancedConfig } = activeChart;
    if (advancedConfig && advancedConfig.linkageConfig && advancedConfig.linkageConfig.hasLinkageParamCheck) {
      // 刷新关联图表数据
      const updatedChart = _.cloneDeep(activeChart);
      const highlightOption = genHighlightOption();
      const relationChartMap = genRelationChartMap(advancedConfig.linkageConfig.relationChart);
      updatedChart.advancedConfig.linkageConfig.linkageWorkByClick = true;
      updatedChart.dataConfig.chartOptions = {
        ...updatedChart.dataConfig.chartOptions,
        series: updatedChart.dataConfig.chartOptions.series.map(itemSeries => ({
          ...itemSeries,
          ...highlightOption,
          data: itemSeries.data.map((itemSeriesData, i) => {
            if (i === param.dataIndex) {
              return {
                ...itemSeriesData,
                itemStyle: highlightOption.emphasis.itemStyle,
                label: highlightOption.emphasis.label
              };
            }
            delete itemSeriesData.label;
            return {
              ...itemSeriesData,
              itemStyle: highlightOption.itemStyle
            };
          })
        }))
      };
      // console.log('relationChartMap: ', relationChartMap);
      dashboardStore.setCharts(
        allCompsRef.current.map(chart => {
          if (chart.frontChartId === updatedChart.frontChartId) {
            return updatedChart;
          } else if (Object.keys(relationChartMap).includes(chart.frontChartId)) {
            return {
              ...chart,
              dataConfig: {
                ...chart.dataConfig,
                status: 'refreshRelationChartData',
                hasRelationChart: true,
                relationChartType: updatedChart.chartType,
                relationChartParam: relationChartMap[chart.frontChartId].map(condition => ({
                  fieldUniqueId: condition,
                  operator: 'EQUALS',
                  values: [param.data.name],
                  conditionJoin: 'AND'
                }))
              }
            };
          }
          return chart;
        })
      );
    }
    if (!DRILL_DOWN_CHARTS.includes(param.seriesType) || !drillDownFields || isDrillDownAreaEmpty) {
      return;
    }
    const pointValues = _.cloneDeep(dataConfigRef.current.pointValues);
    pointValues[param.data.index] = param.data.name;
    const updatedItem = {
      ...allCompsRef.current[param.data.chartIndex],
      dataConfig: {
        ...allCompsRef.current[param.data.chartIndex].dataConfig,
        isActionDrill: true,
        index: param.data.index + 1,
        pointParam: param,
        drillDownFields: dataConfigRef.current.drillDownFields,
        pointValues
      }
    };
    if (chartType.includes('map') && chartType !== 'treemap') {
      const { name, adcode } = param.data;
      updatedItem.dataConfig.mapName = name;
      updatedItem.dataConfig.adcode = adcode;
      updatedItem.dataConfig.drillPathAdcodes.push(`${name}-${adcode}`);
    }
    dashboardStore.modChart({ frontChartId: updatedItem.frontChartId, updatedItem });
    dashboardStore.setActiveIndex(param.data.chartIndex);
    setTimeout(() => {
      setTriggerUpdate(true);
    }, 200);
  }, []);

  const onEvents = {
    click: drillJump
  };
  console.log('render RenderEngine: ', toJS(option));
  return (
    <>
      {option && (
        <div className="render-engine-wrapper">
          {chartType === 'kpi' ? (
            <KpiCard
              chart={{
                ...option,
                data: {
                  series: option.series.map(item => ({ ...item, data: [item.value], name: option.legend.data }))
                },
                customAttr: {
                  size: { dimensionShow: true, spaceSplit: 10 },
                  addon: { prefix: styleConfig.addon.prefix, suffix: styleConfig.addon.suffix }
                },
                indicator: {
                  name: { fontSize: styleConfig.indicator.name.fontSize },
                  value: { fontSize: styleConfig.indicator.value.fontSize }
                }
              }}
              hideTitle
              theme="light"
              valueColor={option.color[0]}
            />
          ) : chartType.includes('table') ? (
            <TableNormal
              chartType={chartType}
              chart={option}
              hideTitle
              theme="light"
              summary={styleConfig.summary}
              showPagination={styleConfig.pagination}
              activeGridItemLayout={activeGridItemLayout}
              tableFields={[...dataConfig.dimensionFields, ...dataConfig.measureFields]}
              drillDownFields={dataConfig.drillDownFields}
              onItemClick={drillJump}
            />
          ) : (
            <ReactEcharts
              option={option}
              notMerge={true}
              lazyUpdate={true}
              theme="shine"
              style={{ width: '100%', height: '100%' }}
              onEvents={onEvents}
            />
          )}
        </div>
      )}
    </>
  );
}

export default React.memo(RenderEngine);
