import { lazy } from 'react';

type ModuleType = 'DATA_SOURCE' | 'DATA_SET' | 'DATA_BOARD' | 'DATA_SCREEN' | 'DATA_REPORT';

interface IRoutes {
  path: string;
  component: any;
  exact?: boolean;
  isDetail?: boolean;
  customId?: string;
  hasParent?: boolean;
  parentCustomId?: string;
  isRefreshDataPermission?: boolean;
  moduleType?: ModuleType;
}

const Home = lazy(() => import('./../pages/HomePage')); // 首页
const DataCenterList = lazy(() => import('./../pages/DataCenter/List')); // 数仓
const DataCenterTableStructure = lazy(() => import('./../pages/DataCenter/TableStructure')); // 数仓查看表结构
const DataCenterTableDetail = lazy(() => import('./../pages/DataCenter/TableDetail')); // 数仓查看数据
const DataSetList = lazy(() => import('../pages/DataSet/List')); // 数据集
const DataSetEditAndView = lazy(() => import('../pages/DataSet/Edit')); // 数据集编辑、查看
const DataSetViewer = lazy(() => import('../pages/DataSet/viewer'));
const DashboardList = lazy(() => import('./../pages/dataBoard/list')); // 仪表板
// const DashboardPreview = lazy(() => import('./../pages/dataBoard/previewBoard')); // 仪表板预览
// const DashboardEdit = lazy(() => import('./../pages/dataBoard/edit')); // 仪表板编辑
const DashboardWorkplace = lazy(() => import('./../pages/dashboard/workplace'));
const DashboardPreview = lazy(() => import('./../pages/dashboard/workplace/preview'));
const MonitorWarningList = lazy(() => import('./../pages/WarningManagement/List')); // 监控预警
const MonitorWarningLogList = lazy(() => import('./../pages/WarningManagement/Log/List')); // 监控预警日志列表
const MonitorWarningLogDetail = lazy(() => import('./../pages/WarningManagement/Log/Detail')); // 监控预警日志详情
const IndicatorLib = lazy(() => import('./../pages/IndicatorLib/SelfBuilt')); // 指标库
const DataIndicator = lazy(() => import('./../pages/DataIndicator/List')); // 数据指标
const DataIndicatorEdit = lazy(() => import('./../pages/DataIndicator/Edit')); // 数据指标编辑
const DataIndicatorDetails = lazy(() => import('./../pages/DataIndicator/Details')); // 数据指标详情
const SelfBuiltIndicatorEdit = lazy(() => import('./../pages/IndicatorLib/SelfBuilt/Module')); // 自建指标库编辑指标
const UserList = lazy(() => import('./../pages/UserManagement/User')); // 账号列表
const RoleList = lazy(() => import('./../pages/UserManagement/Role')); // 角色列表
const JobList = lazy(() => import('./../pages/UserManagement/Job')); // 岗位列表
const OrganizationList = lazy(() => import('./../pages/UserManagement/Organization')); // 组织列表
const DataReportList = lazy(() => import('./../pages/DataReport/List')); // 数据报表
const DataReportDetail = lazy(() => import('./../pages/DataReport/Detail')); // 数据报表详情
const DataSourceList = lazy(() => import('./../pages/DataSource/List')); // 数据源列表
const DataSourceTableStructure = lazy(() => import('./../pages/DataSource/TableStructure')); // 数据源列表
const DataSourceTableData = lazy(() => import('./../pages/DataSource/TableData')); // 数据源表数据
const DataPermission = lazy(() => import('./../pages/DataPermission')); // 数据权限
const Subscribe = lazy(() => import('./../pages/Subscribe')); // 订阅
const SubsLogList = lazy(() => import('./../pages/Subscribe/components/SendRecord')); // 订阅发送记录
const Link = lazy(() => import('./../pages/Link')); // 链接分享
const ScreenList = lazy(() => import('../pages/Screen/List')); // 可视化大屏列表
const ScreenWorkspace = lazy(() => import('../pages/Screen/Workspace'));
const ScreenPreview = lazy(() => import('../pages/Screen/Workspace/core/Preview'));
const RPDesigner = lazy(() => import('../pages/excel-report/designer'));
const RPViewer = lazy(() => import('../pages/excel-report/viewer'));
const RPTplList = lazy(() => import('../pages/excel-report/tpl-list'));
const OperationLog = lazy(() => import('../pages/log-manage/operation'));
export const NotFound = lazy(() => import('./../pages/NotFound')); // 404
const AppPolicyAndCalculate = lazy(() => import('../pages/app-policy-and-calculate'));

const routes: IRoutes[] = [
  {
    path: '/home',
    component: Home
  },
  {
    path: '/intBI',
    component: Home,
    exact: true
  },
  {
    path: '/uaaAdmin',
    component: Home,
    exact: true
  },
  {
    path: '/logManage',
    component: Home,
    exact: true
  },
  {
    path: '/delink',
    component: Link,
    exact: true
  },
  {
    path: '/intBI/dataCenter/list',
    component: DataCenterList,
    customId: 'M_DATA_MANAGE_DATA_WAREHOUSE_LIST'
  },
  {
    path: '/intBI/dataCenter/tableStructure/:tableName',
    component: DataCenterTableStructure,
    isDetail: true, // 是否是详情页(详情页不展示头部和侧边栏)
    customId: 'R_DATA_MANAGE_DATA_CENTER_TABLE_STRUCTURE'
  },
  {
    path: '/intBI/dataCenter/tableDetail/:tableName',
    component: DataCenterTableDetail,
    isDetail: true,
    customId: 'R_DATA_MANAGE_DATA_CENTER_TABLE_DATA_VIEW'
  },
  {
    path: '/intBI/dataSet/list',
    component: DataSetList,
    customId: 'M_DATA_MANAGE_DATA_SET_LIST',
    isRefreshDataPermission: true,
    moduleType: 'DATA_SET'
  },
  {
    path: '/intBI/dataSet/edit',
    component: DataSetEditAndView,
    isDetail: true,
    exact: true
  },
  {
    path: '/intBI/dataSet/edit/:id',
    component: DataSetEditAndView,
    isDetail: true,
    exact: true
  },
  {
    path: '/intBI/dataSet/edit/:id/:isDetail',
    component: DataSetViewer,
    customId: 'R_DATA_MANAGE_DATA_PROCESSING_SAVE_OR_UPDATE',
    isDetail: true,
    exact: true
  },
  {
    path: '/intBI/dataBoard/list',
    component: DashboardList,
    customId: 'M_DATA_MANAGE_DATA_BOARD_LIST',
    isRefreshDataPermission: true,
    moduleType: 'DATA_BOARD'
  },
  {
    path: '/intBI/dataBoard/preview/:id',
    component: DashboardPreview,
    isDetail: true
  },
  {
    path: '/intBI/dataBoard/edit/:id',
    // component: DashboardEdit,
    component: DashboardWorkplace,
    isDetail: true
  },
  {
    path: '/intBI/monitorWarning/list',
    component: MonitorWarningList,
    customId: 'M_DATA_MANAGE_DATA_MONITOR_LIST',
    hasParent: true,
    parentCustomId: 'M_DATA_MANAGE_DATA_MONITOR_MANAGE'
  },
  {
    path: '/intBI/monitorWarning/log/list',
    component: MonitorWarningLogList,
    customId: 'M_DATA_MANAGE_DATA_MONITOR_LOG_LIST',
    hasParent: true,
    parentCustomId: 'M_DATA_MANAGE_DATA_MONITOR_MANAGE'
  },
  {
    path: '/intBI/monitorWarning/log/detail/:id',
    component: MonitorWarningLogDetail,
    isDetail: true
  },
  {
    path: '/intBI/dataIndicator/list',
    component: DataIndicator,
    customId: 'M_DATA_MANAGE_DATA_INDICATOR'
  },
  {
    path: '/intBI/dataIndicator/edit/:id',
    component: DataIndicatorEdit,
    isDetail: true,
    customId: 'R_DATA_MANAGE_DATA_INDICATOR_DETAILS'
  },
  {
    path: '/intBI/dataIndicator/details/:id',
    component: DataIndicatorDetails,
    isDetail: true,
    customId: 'R_DATA_MANAGE_DATA_INDICATOR_DETAILS'
  },
  {
    path: '/intBI/dataReport/list',
    component: RPTplList,
    customId: 'M_DATA_MANAGE_DATA_REPORT_LIST',
    isRefreshDataPermission: true,
    moduleType: 'DATA_REPORT'
  },
  {
    path: '/intBI/dataReport/detail/:id',
    component: DataReportDetail,
    isDetail: true
  },
  {
    path: '/intBI/dataSource/list',
    component: DataSourceList,
    customId: 'M_DATA_MANAGE_DATA_SOURCE_LIST',
    isRefreshDataPermission: true,
    moduleType: 'DATA_SOURCE'
  },
  {
    path: '/intBI/dataSource/tableStructure/:datasourceId/:tableName',
    component: DataSourceTableStructure,
    isDetail: true, // 是否是详情页(详情页不展示头部和侧边栏)
    customId: 'R_DATA_MANAGE_DATA_SOURCE_LIST_TABLES'
  },
  {
    path: '/intBI/dataSource/tableData/:datasourceId/:tableName',
    component: DataSourceTableData,
    isDetail: true,
    customId: 'R_DATA_MANAGE_DATA_SOURCE_VIEW_TABLE_DATA'
  },
  {
    path: '/intBI/subscribe/list',
    component: Subscribe,
    customId: 'M_DATA_MANAGE_DATA_SUBS_LIST'
  },
  {
    path: '/intBI/subscribe/logList/:subsId',
    component: SubsLogList,
    customId: 'M_DATA_MANAGE_DATA_SUBSCRIBE_LOG_LIST',
    hasParent: true,
    parentCustomId: 'M_DATA_MANAGE_DATA_SUBSCRIBE_LIST'
  },
  {
    path: '/intBI/screen/list',
    component: ScreenList,
    customId: 'M_DATA_MANAGE_DATA_SCREEN_LIST',
    isRefreshDataPermission: true,
    moduleType: 'DATA_SCREEN'
  },
  {
    path: '/intBI/screen/workspace/:id',
    component: ScreenWorkspace,
    isDetail: true
  },
  {
    path: '/intBI/screen/preview/:id',
    component: ScreenPreview,
    isDetail: true
  },
  {
    path: '/intBI/excelReport/designer',
    component: RPDesigner,
    isDetail: true
  },
  {
    path: '/intBI/excelReport/viewer',
    component: RPViewer,
    isDetail: true
  },
  {
    path: '/uaaAdmin/user/list',
    component: UserList,
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_USER_LIST',
    hasParent: true,
    parentCustomId: 'M_ACCOUNT_PERMISSIONS_MANAGE'
  },
  {
    path: '/uaaAdmin/role/list',
    component: RoleList,
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_ROLE_LIST',
    hasParent: true,
    parentCustomId: 'M_ACCOUNT_PERMISSIONS_MANAGE'
  },
  {
    path: '/uaaAdmin/job/list',
    component: JobList,
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_DEPARTMENT_LIST',
    hasParent: true,
    parentCustomId: 'M_ACCOUNT_PERMISSIONS_MANAGE'
  },
  {
    path: '/uaaAdmin/org/list',
    component: OrganizationList,
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_ORG_LIST',
    hasParent: true,
    parentCustomId: 'M_ACCOUNT_PERMISSIONS_MANAGE'
  },
  {
    path: '/uaaAdmin/dataPermission',
    component: DataPermission,
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_DATA_PERMISSIONS',
    hasParent: true,
    parentCustomId: 'M_ACCOUNT_PERMISSIONS_MANAGE'
  },
  {
    path: '/logManage/operation',
    component: OperationLog,
    customId: 'M_DATA_MANAGE_LOGGING_OPERATE_LIST',
    hasParent: true,
    parentCustomId: 'M_LOGGING_MANAGE'
  },
  {
    path: '/app-policy-and-calculate',
    component: AppPolicyAndCalculate
  }
];

export default routes;
