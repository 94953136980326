import _ from 'lodash'
import React, { Component, FormEvent, ReactNode } from 'react'
import Form, { FormComponentProps } from 'antd/lib/form'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { formUtils } from '../../../utils/formUtils'
import { ColProps } from 'antd/lib/col'
import { IUdFormItem, UdFormItem } from '../../..'
import { Card, Row, Col } from 'antd'
import classNames from 'classnames'

class UdAdvancedForm extends Component<IUdAdvancedFormProps, IUdAdvancedFormState> {

  componentDidMount() {
    if (_.isFunction(this.props.getForm)) {
      this.props.getForm(this.props.form)
    }
  }

  render() {
    let nodes = []
    let groups = this.props.groups
    if (_.isArray(groups) && groups.length > 0) {
      groups.forEach((group, index) => {
        let isFormItems = _.isArray(group.children)
        nodes.push(<Card key={index} title={group.title} size="small"
          className={classNames({ 'ant-card-form-items': isFormItems })}>
          {
            isFormItems ?
              this.buildFields(group.children as IUdFormItem[], { wrapperCol: group.wrapperCol }) :
              group.children
          }
        </Card>)
      })
    }

    return (
      <Form className="ud-advanced-form" onSubmit={this.handleSubmit} layout="vertical">
        <div className="ud-advanced-form-body">
          {nodes}
        </div>
        <div className="ud-advanced-form-footer">
          {this.props.children}
        </div>
      </Form>
    )
  }

  private buildFields = (items: IUdFormItem[], options?: { wrapperCol?: ColProps }) => {
    const { getFieldDecorator } = this.props.form
    const children = []
    let finalOptions = _.defaultsDeep({}, options, {
      wrapperCol: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 8 }
    })
    for (let i = 0; i < items.length; i++) {
      let itemProps = {
        form: this.props.form,
        getFieldDecorator: getFieldDecorator,
        ...items[i]
      }
      children.push(
        <Col key={itemProps.id.toString()} {...finalOptions.wrapperCol}>
          <UdFormItem {...itemProps} />
        </Col>
      )
    }
    return <Row gutter={24}>{children}</Row>
  }

  private handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return
      }
      let items = formUtils.handleValues(values, false)
      this.props.onSubmit(items)
    })
  }
}

interface IUdAdvancedFormProps extends FormComponentProps {

  /** 表单分组 */
  groups: IUdFormGroup[]

  /** 提交事件 */
  onSubmit: (values: any) => void

  /** 获取表单实例对象 */
  getForm?: (form: WrappedFormUtils) => void

  /** 加载状态 */
  loading?: boolean

  wrapperCol?: ColProps

}

interface IUdAdvancedFormState {

}

export interface IUdFormGroup {
  title: ReactNode
  wrapperCol?: ColProps
  children: ReactNode | IUdFormItem[]
}

export default Form.create<IUdAdvancedFormProps>()(UdAdvancedForm)
