import React, { useState, useEffect } from 'react';
import { InputNumber } from 'antd';
import useLocale from '@/utils/useLocale';
import locale from '../locale';
import './style/ib-number-range.less';
import { IProps } from './data';

function IbNumberRange(props: IProps) {
  const { widget, inDraw, defaultValue, onInputNumberValChange } = props;
  const t = useLocale(locale);
  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);

  useEffect(() => {
    const [min, max] = defaultValue.split(',');
    setMin(min);
    setMax(max);
  }, [defaultValue]);

  useEffect(() => {
    if (!min && !max) {
      onInputNumberValChange('');
      return;
    }
    inputValueChange();
  }, [min, max]);

  const handleMinValueChange = value => {
    if (isNaN(value) || (max && +value > max)) return;
    setMin(value);
  };

  const handleMaxValueChange = value => {
    if (isNaN(value) || (min && +value < min)) return;
    setMax(value);
  };

  const inputValueChange = () => {
    onInputNumberValChange([min, max].join(','));
  };

  return (
    <div className="ib-number-range-wrapper">
      <InputNumber
        placeholder={t[widget.options.attrs.placeholderMin]}
        precision={0} // 只能输入整数
        value={min}
        onChange={handleMinValueChange}
      />
      <span>{t['ibNumberRange.split.placeholder']}</span>
      <InputNumber
        placeholder={t[widget.options.attrs.placeholderMax]}
        precision={0}
        value={max}
        onChange={handleMaxValueChange}
      />
    </div>
  );
}

export default IbNumberRange;
