import { cloneDeep } from 'lodash';
import { hexToRGBA, isHexColor, toThousands, formatEchartsValue } from '@/utils/utils';
import { getSeriesDataMinAndRadix } from '@/pages/dataBoard/components/ChartsPanel/utils';
export const componentStyle = (chartOption, chart) => {
  const padding = '8px';
  const { title, color, label, xAxis, yAxis, legend, customAttr, customStyle, highConfig } = cloneDeep(
    chart.styleConfig
  );

  // backgroundColor
  if (customStyle && customStyle.backgroundColor) {
    chartOption.backgroundColor = customStyle.backgroundColor;
  }
  // title
  if (!chartOption.title) chartOption.title = {};
  chartOption.title.show = false; // 组件标题从option配置中剥离出去
  chartOption.title.text = title.text;
  // 水平方向
  if (title.hPosition === 'left') {
    chartOption.title.right && delete chartOption.title.right;
    chartOption.title.left = padding;
  } else if (title.hPosition === 'right') {
    chartOption.title.left && delete chartOption.title.left;
    chartOption.title.right = padding;
  } else {
    chartOption.title.right && delete chartOption.title.right;
    chartOption.title.left = title.hPosition;
  }
  // 垂直方向
  if (title.vPosition === 'top') {
    chartOption.title.bottom && delete chartOption.title.bottom;
    chartOption.title.top = padding;
  } else if (title.vPosition === 'bottom') {
    chartOption.title.top && delete chartOption.title.top;
    chartOption.title.bottom = padding;
  } else {
    chartOption.title.bottom && delete chartOption.title.bottom;
    chartOption.title.top = title.vPosition;
  }
  chartOption.title.textStyle = title.textStyle;
  // grid
  if (chartOption.grid) {
    chartOption.grid.top = chartOption.title.show ? 52 : 36;
  }
  // color
  if (color && !chart.type.includes('kpi')) {
    chartOption.color = color.colors.map(c => (isHexColor(c) ? hexToRGBA(c) : c));
  }
  // legend
  if (chartOption.legend && legend) {
    chartOption.legend.show = legend.show;
    // 水平方向
    if (legend.hPosition === 'left') {
      chartOption.legend.right && delete chartOption.legend.right;
      chartOption.legend.left = padding;
    } else if (legend.hPosition === 'right') {
      chartOption.legend.left && delete chartOption.legend.left;
      chartOption.legend.right = padding;
    } else {
      chartOption.legend.right && delete chartOption.legend.right;
      chartOption.legend.left = legend.hPosition;
    }
    // 垂直方向
    if (legend.vPosition === 'top') {
      chartOption.legend.bottom && delete chartOption.legend.bottom;
      chartOption.legend.top = padding;
    } else if (legend.vPosition === 'bottom') {
      chartOption.legend.top && delete chartOption.legend.top;
      chartOption.legend.bottom = padding;
    } else {
      chartOption.legend.bottom && delete chartOption.legend.bottom;
      chartOption.legend.top = legend.vPosition;
    }
    chartOption.legend.orient = legend.orient;
    chartOption.legend.icon = legend.icon;
    chartOption.legend.textStyle = legend.textStyle;
  }
  // xAxis
  if (
    xAxis &&
    (chart.type.includes('line') ||
      chart.type.includes('area') ||
      chart.type.includes('bar') ||
      chart.type.includes('strip') ||
      chart.type.includes('scatter'))
  ) {
    chartOption.xAxis.show = xAxis.show;
    chartOption.xAxis.position = xAxis.position;
    chartOption.xAxis.name = xAxis.name;
    chartOption.xAxis.axisLabel = chart.type.includes('strip-stack-percent')
      ? chartOption.xAxis.axisLabel
      : xAxis.axisLabel;
    chartOption.xAxis.splitLine = xAxis.splitLine;
    chartOption.xAxis.nameTextStyle = xAxis.nameTextStyle;
    chartOption.grid.right = xAxis.name ? 64 : 18;

    if (!xAxis.show) {
      chartOption.xAxis.axisLabel.show = false;
    }
    if (chartOption.xAxis.type === 'value') {
      chartOption.xAxis.axisLabel.formatter = (value, index) => {
        return formatEchartsValue(value);
      };
    }
  }
  // yAxis
  if (
    yAxis &&
    (chart.type.includes('line') ||
      chart.type.includes('area') ||
      chart.type.includes('bar') ||
      chart.type.includes('strip') ||
      chart.type.includes('scatter'))
  ) {
    chartOption.yAxis.show = yAxis.show;
    chartOption.yAxis.position = yAxis.position;
    chartOption.yAxis.name = yAxis.name;
    chartOption.yAxis.axisLabel = chart.type.includes('bar-stack-percent')
      ? chartOption.yAxis.axisLabel
      : yAxis.axisLabel;
    chartOption.yAxis.splitLine = yAxis.splitLine;
    chartOption.yAxis.nameTextStyle = yAxis.nameTextStyle;

    if (!yAxis.show) {
      chartOption.yAxis.axisLabel.show = false;
    }
    if (chartOption.yAxis.type === 'value') {
      chartOption.yAxis.axisLabel.formatter = (value, index) => {
        return formatEchartsValue(value);
      };
    }
  }
  // radar split
  if (customStyle && customStyle.split && chart.type.includes('radar')) {
    chartOption.radar.name = customStyle.split.name;
    chartOption.radar.splitNumber = customStyle.split.splitNumber;
    chartOption.radar.axisLine = customStyle.split.axisLine;
    chartOption.radar.axisTick = customStyle.split.axisTick;
    chartOption.radar.axisLabel = customStyle.split.axisLabel;
    chartOption.radar.splitLine = customStyle.split.splitLine;
    chartOption.radar.splitArea = customStyle.split.splitArea;
    chartOption.radar.indicator = chartOption.radar.indicator.map(item => ({
      ...item,
      color: customStyle.split.name.color
    }));
  }
  // size
  if (customAttr) {
    if (chart.type.includes('line') || chart.type.includes('area')) {
      !!chartOption.series.length &&
        chartOption.series.forEach((item, i) => {
          item.itemStyle = {
            color: isHexColor(color.colors[i % color.colors.length])
              ? hexToRGBA(color.colors[i % color.colors.length])
              : color.colors[i % color.colors.length]
          };
          item.symbol = customAttr.size.lineSymbol;
          item.symbolSize = customAttr.size.lineSymbolSize;
          item.lineStyle = {
            width: customAttr.size.lineWidth,
            type: customAttr.size.lineType
          };
          item.smooth = customAttr.size.lineSmooth;
          item.label = label;
          item.type = 'line';
          chartOption.legend && chartOption.legend.data.push(item.name);
        });
    } else if (chart.type.includes('bar') || chart.type.includes('strip')) {
      !!chartOption.series.length &&
        chartOption.series.forEach((item, i) => {
          item.itemStyle = {
            color: isHexColor(color.colors[i % color.colors.length])
              ? hexToRGBA(color.colors[i % color.colors.length])
              : color.colors[i % color.colors.length]
          };
          if (customAttr.size.barDefault) {
            item.barWidth = null;
            item.barGap = null;
          } else {
            item.barWidth = customAttr.size.barWidth;
            item.barGap = customAttr.size.barGap;
          }
          item.label = label;
          item.type = 'bar';
          chartOption.legend && chartOption.legend.data.push(item.name);
        });
    } else if (chart.type.includes('scatter')) {
      !!chartOption.series.length &&
        chartOption.series.forEach((item, i) => {
          item.itemStyle = {
            color: isHexColor(color.colors[i % color.colors.length])
              ? hexToRGBA(color.colors[i % color.colors.length])
              : color.colors[i % color.colors.length]
          };
          item.symbol = customAttr.size.scatterSymbol ? customAttr.size.scatterSymbol : 'circle';
          // item.symbolSize = customAttr.size.scatterSymbolSize ? customAttr.size.scatterSymbolSize : 20;
          item.label = label;
          item.type = 'scatter';
          chartOption.legend && chartOption.legend.data.push(item.name);
        });
    } else if (chart.type.includes('pie')) {
      if (chartOption.series.length > 0) {
        chartOption.series[0].radius = [customAttr.size.pieInnerRadius + '%', customAttr.size.pieOuterRadius + '%'];
        chartOption.series[0].roseType = customAttr.size.pieRoseType;
        chartOption.series[0].label = label;
        const valueArr = chartOption.series[0].data;
        valueArr.forEach((item, i) => {
          item.itemStyle = {
            color: isHexColor(color.colors[i % color.colors.length])
              ? hexToRGBA(color.colors[i % color.colors.length])
              : color.colors[i % color.colors.length],
            borderRadius: customAttr.size.pieRoseRadius
          };
          item.type = 'pie';
        });
      }
    } else if (chart.type.includes('radar')) {
      chartOption.radar.shape = customAttr.size.radarShape;
      chartOption.radar.radius = (customAttr.size.radarSize ? customAttr.size.radarSize : 80) + '%';
      !!chartOption.series.length &&
        chartOption.series.forEach(item => {
          item.label = label;
        });
    } else if (chart.type.includes('gauge')) {
      if (chartOption.series.length > 0) {
        chartOption.series[0].min = customAttr.size.gaugeMin;
        chartOption.series[0].max = customAttr.size.gaugeMax;
        chartOption.series[0].startAngle = customAttr.size.gaugeStartAngle;
        chartOption.series[0].endAngle = customAttr.size.gaugeEndAngle;
        chartOption.series[0].detail.show = label.show;
        chartOption.series[0].detail.fontSize = label.fontSize;
        chartOption.series[0].detail.color = label.color;
        chartOption.series[0].type = 'gauge';
        chartOption.series[0].itemStyle = {
          color: isHexColor(color.colors[0]) ? hexToRGBA(color.colors[0]) : color.colors[0]
        };
      }
    } else if (chart.type.includes('funnel')) {
      if (chartOption.series.length > 0) {
        chartOption.series[0].width = customAttr.size.funnelWidth + '%';
        chartOption.series[0].label = label;
        const valueArr = chartOption.series[0].data;
        valueArr.forEach((item, i) => {
          item.itemStyle = {
            color: isHexColor(color.colors[i % color.colors.length])
              ? hexToRGBA(color.colors[i % color.colors.length])
              : color.colors[i % color.colors.length]
          };
        });
      }
    } else if (chart.type.includes('kpi')) {
      if (chartOption.series.length > 0) {
        chartOption.series[0].data[0] = chartOption.series[0].value;
        chartOption.series[0].name = chartOption.legend.data;
        chartOption.title = title;
        chartOption.data = {
          series: chartOption.series
        };
        if (customAttr.color) {
          const { dimensionColor, quotaColor, backgroundColor } = color || customAttr.color;
          chartOption.customAttr = {
            ...customAttr,
            color: {
              ...customAttr.color,
              dimensionColor,
              quotaColor
            }
          };
          chartOption.backgroundColor = backgroundColor || customStyle.backgroundColor;
        }
      }
    } else if (chart.type.includes('treemap')) {
      if (chartOption.series.length > 0) {
        // size
        if (customAttr.size) {
          chartOption.series[0].width = (customAttr.size.treemapWidth ? customAttr.size.treemapWidth : 80) + '%';
          chartOption.series[0].height = (customAttr.size.treemapHeight ? customAttr.size.treemapHeight : 80) + '%';
        }
        // label
        const l = {
          show: label.show,
          position: label.position,
          color: label.color,
          fontSize: label.fontSize
        };
        chartOption.series[0].label = l;
        const valueArr = chartOption.series[0].data;
        valueArr.map((val, i) => {
          // color
          const c = color.colors[i % color.colors.length];
          val.itemStyle = {
            color: isHexColor(c) ? hexToRGBA(c) : c
          };
          return val;
        });
      }
    } else if (chart.type.includes('table')) {
      chartOption.title = title;
      if (customAttr.color) {
        const { tableHeaderBgColor, tableCellBgColor, backgroundColor } = color || customAttr.color;
        chartOption.customAttr = {
          ...customAttr,
          color: {
            ...customAttr.color,
            tableHeaderBgColor,
            tableCellBgColor
          }
        };
        chartOption.backgroundColor = backgroundColor || customStyle.backgroundColor;
      }
    }
  }
  // map label
  if (chart.type === 'map') {
    if (chartOption.series.length > 0) {
      chartOption.series[0].label = label;
      chartOption.series[0].itemStyle.areaColor = 'rgba(238,238,238,0.1)';
      chartOption.series[0].itemStyle.borderColor = 'rgba(255,255,255,0.1)';
    }
    const seriesDataValues = chartOption.series[0].data.map(item => (!item.value ? 0 : +item.value));
    chartOption.visualMap = chartOption.series[0].data.length
      ? {
          min: Math.min(...seriesDataValues),
          max: Math.max(...seriesDataValues),
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          inRange: {
            color: new Array(5)
              .fill(null)
              .map((item, index) =>
                chartOption.color[0].replace(/[\d\.]+\)$/g, `${parseFloat(((index + 1) * 0.2).toFixed(10))})`)
              )
          },
          textStyle: {
            color: '#fff'
          },
          formatter: val => toThousands(val)
        }
      : null;
    chartOption.geo.label = label;
    chartOption.geo.itemStyle.areaColor = 'transparent';
    chartOption.geo.itemStyle.borderColor = 'transparent';
  }
  if (chart.type === 'bubble-map') {
    chartOption.series[0].label = {
      ...chartOption.series[0].label,
      ...label
    };
    chartOption.geo.emphasis = null;
    chartOption.geo.itemStyle.areaColor = 'rgba(238,238,238,0.1)';
    chartOption.geo.itemStyle.borderColor = 'rgba(255,255,255,0.1)';
  }
  // pie label formatter
  if (chart.type.includes('pie')) {
    if (chartOption.series.length > 0) {
      chartOption.series[0].label.formatter = '{b}-{d}%';
    }
  }
  // treemap label  formatter
  if (chart.type.includes('treemap')) {
    if (chartOption.series.length > 0) {
      const total = chartOption.series[0].data.map(item => item.value).reduce((prev, curr) => prev + curr, 0);
      chartOption.series[0].label.formatter = params => {
        const {
          data: { name, value }
        } = params;
        const percent = (value / total) * 100;
        return `${name}-${percent.toFixed(2)}%`;
      };
    }
  }
  // highConfig
  if (highConfig) {
    resolveHighConfig(chart.type, chartOption, highConfig.proximityAnalysis);
  }
  // combination legend and series
  if (chart.type === 'combination') {
    chartOption.series = chartOption.series.map(item => {
      const result = item;
      if (item.type === 'area') {
        result.smooth = true;
        result.areaStyle = {};
      }
      return result;
    });
    chartOption.grid.top = 62;
  }
  if (chart.type.includes('percent')) {
    chartOption.series = chartOption.series.map(item => {
      item.label.formatter = ({ value }) => {
        return (value * 100).toFixed(1) + '%';
      };
      return item;
    });
  }

  return chartOption;
};

export const resolveHighConfig = (chartType, chartOption, status) => {
  if (chartType.includes('line') || chartType.includes('area') || chartType.includes('bar')) {
    if (status) {
      chartOption[chartOption.xAxis.type === 'category' ? 'yAxis' : 'xAxis'].min = value => value.min;
    }
  } else if (chartType.includes('radar')) {
    const { zippedMin, radix } = getSeriesDataMinAndRadix(chartOption.series[0].data);
    chartOption.radar.indicator = chartOption.radar.indicator.map((item, index) => {
      if (status) {
        item.min = zippedMin[index] - radix[index];
      }
      return item;
    });
  }
};
