import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Result } from 'antd';
import './style.less';

const LinkError: FC = () => {
  const history = useHistory();
  useEffect(() => {
    /* const delay = 5;
    let timer = setTimeout(() => {
      history.push('/login');
    }, delay * 1000);
    return () => {
      clearTimeout(timer);
    }; */
  }, []);
  return (
    <div className="link-error">
      <Result status="500" title="链接无效" />
    </div>
  );
};

export default LinkError;
