import React, { FC, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Checkbox,
  Button,
  Tag,
  Pagination
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import classNames from 'classnames';
import moment from 'moment';
import { uuid } from '@/utils/utils';
import SvgIcon from '@/components/SvgIcon';
import { http } from '@/lib';
import APIS from '@/apis/api';
import './style.less';

interface IConfigCompareFieldModalContentProps {
  compareField: {};
  fields: any[];
  compareFieldConfig: any;
  formDataConditions: any;
}
type valueCategoryType = 'STATIC_MULTIVALUED' | 'DYNAMIC_MULTIVALUED' | 'RANGE';
type validateStatusType = '' | 'error' | 'success' | 'warning' | 'validating';
interface IItemStaticValue {
  value: string;
  validateStatus: validateStatusType;
  errorMsg: string;
}
interface IFormData {
  valueCategory: valueCategoryType;
  staticValues?: IItemStaticValue[];
  rangeValues?: any[];
  dynamicValue?: any;
}
const formItemLayout = {
  labelCol: {
    sm: { span: 4 }
  },
  wrapperCol: {
    sm: { span: 20 }
  }
};
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

export const dateFormat = 'YYYY-MM-DD';
export const dateErrorMsgTip = '请选择日期';
/**
 * 动态日期对比条件校验
 * @param value
 * @returns
 */
export const validateDynamicBaseValue = value => {
  if (!value) {
    return {
      validateStatus: 'error',
      errorMsg: dateErrorMsgTip
    };
  }
  return {
    validateStatus: 'success',
    errorMsg: null
  };
};
/**
 * 动态日期动态差值校验
 * @param value
 * @returns
 */
export const validateDynamicIncrement = value => {
  if (!value) {
    return {
      validateStatus: 'error',
      errorMsg: '请输入动态差值'
    };
  }
  return {
    validateStatus: 'success',
    errorMsg: null
  };
};
/**
 * 日期区间对比条件校验
 * @param dateRange
 */
export const validateRangeItemCondition = ([start, end]) => {
  if (!start || !end) {
    return {
      validateStatus: 'error',
      errorMsg: dateErrorMsgTip
    };
  }
  return {
    validateStatus: 'success',
    errorMsg: null
  };
};

const ConfigCompareFieldModalContent: FC<any> = forwardRef<any, any>((props, ref) => {
  const {
    dataSetId,
    compareField,
    fields,
    compareFieldConfig,
    compareFieldConfigFormData,
    selectedFieldValuesFromParent
  } = props;
  const fieldSelected = fields.find(field => field.customName === compareField.customName);
  useImperativeHandle(ref, () => ({
    fieldSelected,
    formData,
    selectedFieldValues
  }));
  const [fieldValuesConfig, setFieldValuesConfig] = useState({
    keyword: '',
    checkAll: false, // 全选
    originalFieldValues: [], // 原始字段值
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    }
  });
  const [selectedFieldValues, setSelectedFieldValues] = useState([]); // 选中字段值
  const [formData, setFormData] = useState<IFormData>({
    valueCategory: 'STATIC_MULTIVALUED',
    staticValues: [
      {
        value: '',
        validateStatus: 'success',
        errorMsg: dateErrorMsgTip
      }
    ],
    rangeValues: [
      {
        start: '',
        end: '',
        validateStatus: 'success',
        errorMsg: dateErrorMsgTip
      }
    ],
    dynamicValue: {
      baseValue: {
        value: ''
      },
      increment: {
        value: ''
      },
      diffType: 'minus'
    }
  });
  useEffect(() => {
    if (fieldSelected.attribute.valueType === 'TEXT') {
      // 非日期类字段
      if (!selectedFieldValuesFromParent.length) {
        fetchDataSetFieldValues();
      } else {
        const _selectedFieldValues = selectedFieldValuesFromParent.map(item => ({
          key: item,
          value: item
        }));
        setSelectedFieldValues(_selectedFieldValues);
        fetchDataSetFieldValues(1, '', _selectedFieldValues);
      }
      return;
    }
    if (compareFieldConfig) {
      const { valueCategory, staticValues, dynamicValue, rangeValues } = compareFieldConfig;
      if (valueCategory === 'STATIC_MULTIVALUED') {
        setFormData({
          ...formData,
          valueCategory,
          staticValues: staticValues.map(val => ({
            value: moment(val).format(dateFormat),
            validateStatus: 'success',
            errorMsg: ''
          }))
        });
      } else if (valueCategory === 'DYNAMIC_MULTIVALUED') {
        const { baseValue, increment } = dynamicValue;
        let incrementVal;
        if (typeof increment === 'number') {
          incrementVal = increment.toString().slice(increment < 0 ? 1 : 0);
        } else {
          incrementVal = increment.slice(parseInt(increment) < 0 ? 1 : 0);
        }
        setFormData({
          ...formData,
          valueCategory,
          dynamicValue: {
            ...formData.dynamicValue,
            baseValue: {
              value: baseValue
            },
            increment: {
              value: incrementVal
            },
            diffType: parseInt(increment) < 0 ? 'minus' : 'plus'
          }
        });
      } else {
        setFormData({
          ...formData,
          valueCategory,
          rangeValues: rangeValues.map(({ start, end }) => ({
            start: moment(start, dateFormat),
            end: moment(end, dateFormat),
            validateStatus: 'success',
            errorMsg: ''
          }))
        });
      }
      return;
    }
    setFormData({
      ...formData,
      ...compareFieldConfigFormData
    });
  }, [compareFieldConfigFormData]);
  /**
   * 获取数据集字段对应的值
   */
  const fetchDataSetFieldValues = async (
    current = fieldValuesConfig.pagination.current,
    keyword = fieldValuesConfig.keyword,
    arrSelectedFieldValues = selectedFieldValues
  ) => {
    const {
      data: { content, page, size, totalElements }
    } = await http.post(APIS.DataBoardFindDataSetFieldValues, {
      dataSetId,
      fieldId: fieldSelected.uniqueId,
      page: current,
      size: fieldValuesConfig.pagination.pageSize,
      keyword
    });
    const selectedFieldValueKeys = arrSelectedFieldValues.map(fieldVal => fieldVal.key);
    setFieldValuesConfig({
      ...fieldValuesConfig,
      keyword,
      checkAll: content.every(({ key }) => selectedFieldValueKeys.includes(key)),
      originalFieldValues: content.map(fieldVal => ({
        ...fieldVal,
        checked: arrSelectedFieldValues
          .map(itemSelectedFieldVal => itemSelectedFieldVal.key)
          .includes(fieldVal.key)
      })),
      pagination: {
        current: page,
        pageSize: size,
        total: totalElements
      }
    });
  };
  /**
   * 对比字段值过滤搜索
   * @param value
   */
  const handleSearch = value => {
    fetchDataSetFieldValues(fieldValuesConfig.pagination.current, value);
  };
  /**
   * 字段值选中状态变化
   * @param ev
   * @param itemField
   */
  const handleFieldCheckedChange = (ev, itemField) => {
    const itemFieldChecked = ev.target.checked;
    setFieldValuesConfig({
      ...fieldValuesConfig,
      originalFieldValues: fieldValuesConfig.originalFieldValues.map(fieldVal => {
        if (fieldVal.key === itemField.key) {
          return {
            ...itemField,
            checked: itemFieldChecked
          };
        }
        return fieldVal;
      })
    });
    if (itemFieldChecked) {
      setSelectedFieldValues([...selectedFieldValues, itemField]);
    } else {
      setSelectedFieldValues(selectedFieldValues.filter(({ key }) => key !== itemField.key));
    }
  };
  /**
   * 移除选中字段值
   * @param ev
   */
  const handleRemoveTag = (ev, itemTagKey) => {
    setSelectedFieldValues(selectedFieldValues.filter(({ key }) => key !== itemTagKey));
    setFieldValuesConfig({
      ...fieldValuesConfig,
      originalFieldValues: fieldValuesConfig.originalFieldValues.map(fieldVal => {
        if (fieldVal.key === itemTagKey) {
          return {
            ...fieldVal,
            checked: false
          };
        }
        return fieldVal;
      })
    });
  };
  /**
   * 清除选中字段值
   */
  const handleClearTag = () => {
    setSelectedFieldValues([]);
    setFieldValuesConfig({
      ...fieldValuesConfig,
      originalFieldValues: fieldValuesConfig.originalFieldValues.map(fieldVal => ({
        ...fieldVal,
        checked: false
      }))
    });
  };
  /**
   * 对比字段值分页变化
   * @param page
   */
  const handlePaginationChange = page => {
    fetchDataSetFieldValues(page);
  };
  /**
   * 对比字段值全选
   * @param ev
   */
  const handleCheckAll = ev => {
    setFieldValuesConfig({
      ...fieldValuesConfig,
      originalFieldValues: fieldValuesConfig.originalFieldValues.map(fieldVal => ({
        ...fieldVal,
        checked: ev.target.checked
      })),
      checkAll: ev.target.checked
    });
    if (ev.target.checked) {
      const checkedFieldValues = fieldValuesConfig.originalFieldValues.map(fieldVal => ({
        ...fieldVal,
        checked: true
      }));
      setSelectedFieldValues(selectedFieldValues.concat(checkedFieldValues));
    } else {
      const removedKeys = fieldValuesConfig.originalFieldValues.map(fieldVal => fieldVal.key);
      setSelectedFieldValues(selectedFieldValues.filter(({ key }) => !removedKeys.includes(key)));
    }
  };
  /**
   * 日期方式变化
   * @param ev
   */
  const handleValueCategoryChange = ev => {
    setFormData({
      ...formData,
      valueCategory: ev.target.value
    });
  };
  /**
   * 静态日期对比条件变化
   * @param date
   * @param itemIndex
   */
  const handleStaticItemConditionChange = (date, itemIndex) => {
    setFormData({
      ...formData,
      staticValues: formData.staticValues.map((item, index) => {
        if (index === itemIndex) {
          const itemConditionValue = date;
          return {
            value: itemConditionValue,
            validateStatus: !itemConditionValue ? 'error' : 'success',
            errorMsg: !itemConditionValue ? dateErrorMsgTip : null
          };
        }
        return item;
      })
    });
  };
  /**
   * 添加静态对比条件
   */
  const handleAddItemConStatic = () => {
    const newItem: IItemStaticValue = {
      value: '',
      validateStatus: 'success',
      errorMsg: dateErrorMsgTip
    };
    setFormData({
      ...formData,
      staticValues: [...formData.staticValues, newItem]
    });
  };
  /**
   * 移除静态对比条件
   * @param index
   */
  const handleRemoveItemConStatic = itemIndex => {
    setFormData({
      ...formData,
      staticValues: formData.staticValues.filter((val, index) => index !== itemIndex)
    });
  };
  /**
   * 动态日期对比条件变化
   * @param date
   */
  const handleDynamicBaseValueChange = date => {
    setFormData({
      ...formData,
      dynamicValue: {
        ...formData.dynamicValue,
        baseValue: {
          ...validateDynamicBaseValue(date),
          value: date
        }
      }
    });
  };
  /**
   * 动态日期动态差值值变化
   * @param ev
   */
  const handleDynamicIncrementValueChange = ev => {
    const value = ev.target.value;
    setFormData({
      ...formData,
      dynamicValue: {
        ...formData.dynamicValue,
        increment: {
          ...validateDynamicIncrement(value),
          value
        }
      }
    });
  };
  /**
   * 动态差值类型变化
   * @param itemSelected
   */
  const handleDynamicDiffTypeChange = itemSelected => {
    setFormData({
      ...formData,
      dynamicValue: {
        ...formData.dynamicValue,
        diffType: itemSelected
      }
    });
  };
  /**
   * 日期区间对比条件变化
   * @param date
   * @param itemIndex
   */
  const handleRangeItemConditionChange = (dateRange, itemIndex) => {
    setFormData({
      ...formData,
      rangeValues: formData.rangeValues.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...validateRangeItemCondition(dateRange),
            start: dateRange[0],
            end: dateRange[1]
          };
        }
        return item;
      })
    });
  };
  /**
   * 添加日期区间对比条件
   */
  const handleAddItemConRange = () => {
    const newItem = {
      start: '',
      end: ''
    };
    setFormData({
      ...formData,
      rangeValues: [...formData.rangeValues, newItem]
    });
  };
  /**
   * 移除日期区间对比条件
   * @param itemIndex
   */
  const handleRemoveItemConRange = itemIndex => {
    setFormData({
      ...formData,
      rangeValues: formData.rangeValues.filter((val, index) => index !== itemIndex)
    });
  };
  const fieldSelectedValueType = fieldSelected.attribute.valueType;
  const fieldIconClasses = classNames('field-icon svg-icon-wrap', {
    'icon-datetime': fieldSelectedValueType === 'DATE',
    'icon-str': fieldSelectedValueType === 'TEXT',
    'icon-no': fieldSelectedValueType === 'NUMBER'
  });
  const svgIconClasses = classNames({
    Date: fieldSelectedValueType === 'DATE',
    Str: fieldSelectedValueType === 'TEXT',
    No: fieldSelectedValueType === 'NUMBER'
  });
  return (
    <div className="config-compare-field-wrap">
      <div className="compare-field-content-header">
        <div className="form-item">
          <span className="label">选中字段</span>
          <span className="form-item-value">
            <div className="item-field">
              <i className={fieldIconClasses}>
                <SvgIcon iconClass={svgIconClasses} />
              </i>
              <span>{fieldSelected.customName}</span>
            </div>
          </span>
        </div>
      </div>
      <div className="compare-field-content-info">
        {fieldSelectedValueType === 'DATE' && (
          <Form {...formItemLayout} colon={false} labelAlign="right">
            <Form.Item label="日期方式">
              <Radio.Group value={formData.valueCategory} onChange={handleValueCategoryChange}>
                <Radio value="STATIC_MULTIVALUED">静态日期</Radio>
                <Radio value="DYNAMIC_MULTIVALUED">动态日期</Radio>
                <Radio value="RANGE">日期区间</Radio>
              </Radio.Group>
            </Form.Item>
            {formData.valueCategory === 'STATIC_MULTIVALUED' && (
              <Form.Item label="对比条件">
                {formData.staticValues.map((condition, index) => (
                  <Row gutter={8} key={uuid()}>
                    <Col span={13}>
                      <Form.Item
                        validateStatus={condition.validateStatus}
                        help={condition.validateStatus === 'error' ? condition.errorMsg : ''}
                      >
                        <DatePicker
                          placeholder={dateErrorMsgTip}
                          format="YYYY-MM-DD"
                          style={{ width: '192px' }}
                          value={condition.value ? moment(condition.value, dateFormat) : null}
                          onChange={date => handleStaticItemConditionChange(date, index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <div className="form-item-actions">
                        <i className="svg-icon-wrap icon-add" onClick={handleAddItemConStatic}>
                          <SvgIcon iconClass="add" />
                        </i>
                        {formData.staticValues.length > 1 && (
                          <i
                            className="svg-icon-wrap icon-sub"
                            onClick={() => handleRemoveItemConStatic(index)}
                          >
                            <SvgIcon iconClass="sub" />
                          </i>
                        )}
                      </div>
                    </Col>
                  </Row>
                ))}
              </Form.Item>
            )}
            {formData.valueCategory === 'DYNAMIC_MULTIVALUED' && (
              <>
                <Form.Item
                  label="对比条件"
                  validateStatus={formData.dynamicValue.baseValue.validateStatus}
                  help={
                    formData.dynamicValue.baseValue.validateStatus === 'error'
                      ? dateErrorMsgTip
                      : ''
                  }
                >
                  <DatePicker
                    placeholder={dateErrorMsgTip}
                    format="YYYY-MM-DD"
                    style={{ width: '192px' }}
                    value={
                      formData.dynamicValue.baseValue.value
                        ? moment(formData.dynamicValue.baseValue.value, dateFormat)
                        : null
                    }
                    onChange={handleDynamicBaseValueChange}
                  />
                </Form.Item>
                <Form.Item label="动态差值">
                  <Row gutter={8}>
                    <Col span={2}>
                      <i className="icon-svg-wrap icon-time-pre">
                        <SvgIcon iconClass="time-pre" />
                      </i>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        <Select
                          value={formData.dynamicValue.diffType}
                          defaultValue={formData.dynamicValue.diffType}
                          onChange={handleDynamicDiffTypeChange}
                        >
                          <Option value="plus">+</Option>
                          <Option value="minus">-</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        validateStatus={formData.dynamicValue.increment.validateStatus}
                        help={
                          formData.dynamicValue.increment.validateStatus === 'error'
                            ? '请输入动态差值'
                            : ''
                        }
                      >
                        <Input
                          value={formData.dynamicValue.increment.value}
                          onChange={handleDynamicIncrementValueChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <i className="icon-svg-wrap icon-relative-time">
                        <SvgIcon iconClass="relative-time" />
                      </i>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
            {formData.valueCategory === 'RANGE' && (
              <Form.Item label="对比条件">
                <div className="time-duration-config">
                  <div className="duration-headers">
                    <span className="line">
                      <span className="left-join-point"></span>
                      <span className="right-join-point"></span>
                    </span>
                  </div>
                  <div className="time-default-item left">
                    <span className="label">开始于</span>
                  </div>
                  <div className="time-default-item right">
                    <span className="label">结束于</span>
                  </div>
                </div>
                {formData.rangeValues.map((condition, index) => (
                  <Row gutter={8} key={uuid()}>
                    <Col span={20}>
                      <Form.Item
                        validateStatus={condition.validateStatus}
                        help={condition.validateStatus === 'error' ? condition.errorMsg : ''}
                      >
                        <RangePicker
                          className="date-range-picker"
                          placeholder={[dateErrorMsgTip, dateErrorMsgTip]}
                          value={[
                            condition.start ? moment(condition.start, dateFormat) : null,
                            condition.end ? moment(condition.end, dateFormat) : null
                          ]}
                          onChange={dateRange => handleRangeItemConditionChange(dateRange, index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <div className="form-item-actions">
                        <i className="svg-icon-wrap icon-add" onClick={handleAddItemConRange}>
                          <SvgIcon iconClass="add" />
                        </i>
                        {formData.rangeValues.length > 1 && (
                          <i
                            className="svg-icon-wrap icon-sub"
                            onClick={() => handleRemoveItemConRange(index)}
                          >
                            <SvgIcon iconClass="sub" />
                          </i>
                        )}
                      </div>
                    </Col>
                  </Row>
                ))}
              </Form.Item>
            )}
          </Form>
        )}
        {fieldSelectedValueType === 'TEXT' && (
          <>
            <div className="field-select-wrap">
              <div className="field-select-l">
                <div className="search-wrap">
                  <Search placeholder="请输入关键字搜索" onSearch={handleSearch} />
                </div>
                {fieldValuesConfig.originalFieldValues.length > 0 && (
                  <>
                    <div
                      style={{
                        marginBottom: '6px',
                        paddingBottom: '6px',
                        borderBottom: '1px solid #e9e9e9'
                      }}
                    >
                      <Checkbox checked={fieldValuesConfig.checkAll} onChange={handleCheckAll}>
                        全选
                      </Checkbox>
                    </div>
                    <div className="field-values-box">
                      {fieldValuesConfig.originalFieldValues.map(itemField => (
                        <div className="field-select-row" key={itemField.key}>
                          <Checkbox
                            className="field-select-row-checked"
                            onChange={ev => handleFieldCheckedChange(ev, itemField)}
                            checked={itemField.checked}
                          >
                            <span className="field-select-row-caption">{itemField.value}</span>
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                    <div className="field-values-footer">
                      <Pagination
                        simple
                        current={fieldValuesConfig.pagination.current}
                        pageSize={fieldValuesConfig.pagination.pageSize}
                        total={fieldValuesConfig.pagination.total}
                        onChange={handlePaginationChange}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="field-select-r">
                <div className="field-selected-hd">
                  <span className="field-selected-count">已选中:{selectedFieldValues.length}</span>
                  <div className="field-selected-action">
                    <Button type="link" onClick={handleClearTag}>
                      清除
                    </Button>
                  </div>
                </div>
                <div className="field-selected-con">
                  {selectedFieldValues.map(({ key, value }) => (
                    <Tag
                      key={key}
                      closable
                      onClose={ev => handleRemoveTag(ev, key)}
                      color="#0089c4"
                    >
                      {value}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default ConfigCompareFieldModalContent;
