import classNames from 'classnames'
import React, { ReactNode, CSSProperties } from 'react'
import { ClassValue } from 'classnames/types'

const UdPage: React.FC<IUdPageProps> = (props: IUdPageProps) => {
  return <div style={props.style} className={classNames('ud-page', props.className)}>{props.children}</div>
}

interface IUdPageProps {
  /**
   * style
   */
  style?: CSSProperties
  /**
   * 不管传没传，都会有一个 `ud-page` 的 className
   */
  className?: ClassValue
  /**
   * 页面内容
   */
  children: ReactNode
}

export default UdPage
