import React, { FC, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { scaleLinear } from 'd3-scale';
import { arc } from 'd3-shape';
import './index.less';

const Border: FC<any> = ({ baseConfig, index, fill, stroke, borderColor, animate, rotationDir }) => {
  const borderBoxWrapperRef = useRef(null);
  const [borderSvgShape2Style, setBorderSvgShape2Style] = useState({
    size: {
      width: 550,
      height: 400
    },
    circleRadius: 0,
    point: {
      top: 187,
      left: 431,
      transformOrigin: [-156, 13]
    }
  });

  useEffect(() => {
    if (index === 2) {
      const { width, height } = getRect(borderBoxWrapperRef.current);
      const offset = 40;
      const circleRadius = Math.min(width, height) / 2 - offset;
      setBorderSvgShape2Style({
        ...borderSvgShape2Style,
        size: {
          width,
          height
        },
        point: {
          top: (height - 26) / 2,
          left: width / 2 + circleRadius - 4,
          transformOrigin: [circleRadius - 4, 13]
        },
        circleRadius
      });
    }
  }, [baseConfig.width, baseConfig.height]);

  const getRect = element => {
    if (!element) {
      return {
        width: 0,
        height: 0
      };
    }
    return element.getBoundingClientRect();
  };

  const colorScale = colorEnd => {
    return scaleLinear().domain([0, 1]).range(['#000000', colorEnd]);
  };

  const drawArc = (x, y, startAngle, endAngle) => {
    return arc().innerRadius(x).outerRadius(y).startAngle(startAngle).endAngle(endAngle)();
  };
  // console.log('Border render: ', borderSvgShape2Style);

  return (
    <div className={classNames('border-box-wrapper', `border-${index}`)} ref={borderBoxWrapperRef}>
      {index === 1 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path
            d="M50,10L525,10Q540,10,540,25L540,370Q538,388,520,388L25,388Q5,388,5,370L5,50Z"
            strokeWidth="1"
            stroke={stroke}
            fill="none"
          ></path>
          <path
            d="M550,133.33333333333334L550,172.7777777777778C550,212.22222222222226,550,291.11111111111114,550,332.22222222222223C550,373.3333333333333,550,376.6666666666667,549,380.6666666666667C548,384.6666666666667,546,389.3333333333333,542.6666666666666,392.3333333333333C539.3333333333334,395.3333333333333,534.6666666666666,396.6666666666667,530.6666666666666,397.3333333333333C526.6666666666666,398,523.3333333333334,398,503.75,398C484.1666666666667,398,448.3333333333333,398,430.4166666666667,398L412.5,398"
            strokeWidth="1"
            stroke={stroke}
            fill="none"
          ></path>
          <path
            d="M540,143.33333333333334L550,133.33333333333334L550,311.1111111111111L540,321.1111111111111L540,0L550,0L550,0L540,0Z"
            fill={fill}
          ></path>
          <path
            d="M2,133.33333333333334L11,143.33333333333334L11,266.66666666666663L2,256.66666666666663L2,0L11,0L11,0L2,0Z"
            fill={fill}
          ></path>
          <path d="M5,5L40,5L5,35L5,0L40,0L5,0Z" fill={fill}></path>
          <path d="M100,10L200,10" stroke={stroke} fill="none" strokeWidth="10" strokeDasharray="17,3"></path>
          <path d="M317.5,394L417.5,394" stroke={stroke} fill="none" strokeWidth="10" strokeDasharray="17,3"></path>
        </svg>
      )}
      {index === 2 && (
        <>
          <svg width={borderSvgShape2Style.size.width} height={borderSvgShape2Style.size.height}>
            <defs>
              <filter id="filter_apluginPreview">
                <feGaussianBlur in="SourceGraphic" stdDeviation="2"></feGaussianBlur>
              </filter>
              <filter id="filter_bpluginPreview">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10"></feGaussianBlur>
              </filter>
              <linearGradient id="gradient_apluginPreview" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopOpacity="1" style={{ stopColor: colorScale(borderColor)(0.6) }}></stop>
                <stop offset="50%" stopOpacity="1" style={{ stopColor: colorScale(borderColor)(0.3) }}></stop>
                <stop offset="100%" stopOpacity="1" style={{ stopColor: colorScale(borderColor)(0.6) }}></stop>
              </linearGradient>
              <linearGradient id="gradient_bpluginPreview" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopOpacity="0" style={{ stopColor: colorScale(borderColor)(0.3) }}></stop>
                <stop offset="5%" stopOpacity="0.1" style={{ stopColor: colorScale(borderColor)(0.3) }}></stop>
                <stop offset="50%" stopOpacity="1" style={{ stopColor: colorScale(borderColor)(1) }}></stop>
                <stop offset="95%" stopOpacity="0.3" style={{ stopColor: colorScale(borderColor)(0.3) }}></stop>
                <stop offset="100%" stopOpacity="0" style={{ stopColor: colorScale(borderColor)(0.3) }}></stop>
              </linearGradient>
              <linearGradient id="gradient_cpluginPreview" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopOpacity="0" style={{ stopColor: colorScale(borderColor)(0.3) }}></stop>
                <stop offset="50%" stopOpacity="0.3" style={{ stopColor: colorScale(borderColor)(1) }}></stop>
                <stop offset="100%" stopOpacity="0" style={{ stopColor: colorScale(borderColor)(0.3) }}></stop>
              </linearGradient>
            </defs>
            <g transform={`translate(${borderSvgShape2Style.size.width / 2},${borderSvgShape2Style.size.height / 2})`}>
              <path
                d={drawArc(borderSvgShape2Style.circleRadius - 3, borderSvgShape2Style.circleRadius, 0, 2 * Math.PI)}
                fill="url(#gradient_apluginPreview)"
                filter="url(#filter_apluginPreview)"
              ></path>
              <path
                d={drawArc(borderSvgShape2Style.circleRadius - 3, borderSvgShape2Style.circleRadius, 0, Math.PI)}
                fill="url(#gradient_bpluginPreview)"
                className={classNames({
                  border_svg_shape_2_animate_b: animate && rotationDir,
                  border_svg_shape_2_animate_a: animate && !rotationDir
                })}
              ></path>
              <path
                d={drawArc(
                  borderSvgShape2Style.circleRadius - 20,
                  borderSvgShape2Style.circleRadius + 20,
                  Math.PI / 4,
                  (3 * Math.PI) / 4
                )}
                fill="url(#gradient_cpluginPreview)"
                filter="url(#filter_bpluginPreview)"
                className={classNames({
                  border_svg_shape_2_animate_b: animate && rotationDir,
                  border_svg_shape_2_animate_a: animate && !rotationDir
                })}
              ></path>
            </g>
          </svg>
          <div
            className={classNames('border_svg_shape_2_point', {
              border_svg_shape_2_animate_b: animate && rotationDir,
              border_svg_shape_2_animate_a: animate && !rotationDir
            })}
            style={{
              left: borderSvgShape2Style.point.left + 'px',
              top: borderSvgShape2Style.point.top + 'px',
              transformOrigin: `-${borderSvgShape2Style.point.transformOrigin[0]}px 13px`,
              boxShadow: `rgb(255, 255, 255) 0px 0px 5px, rgb(255, 255, 255) 0px 0px 10px, rgb(255, 255, 255) 0px 0px 15px, ${borderColor} 0px 0px 20px, ${borderColor} 0px 0px 35px, ${borderColor} 0px 0px 40px, ${borderColor} 0px 0px 150px, ${borderColor} 0px 0px 175px`
            }}
          ></div>
        </>
      )}
      {index === 3 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <g transform="translate(275,200)">
            <path
              d="M-199.9100067497975,-5.999100040499206A200,200,0,0,1,199.9100067497975,-5.999100040499138L194.91225658105256,-5.849122539486659A195,195,0,0,0,-194.91225658105256,-5.849122539486726Z"
              fill={colorScale(borderColor)(0.7)}
              className={classNames({
                border_svg_shape_3_animate_b: animate && rotationDir,
                border_svg_shape_3_animate_a: animate && !rotationDir
              })}
            ></path>
            <path
              d="M199.9100067497975,5.999100040499138A200,200,0,0,1,-199.9100067497975,5.999100040499206L-194.91225658105256,5.849122539486726A195,195,0,0,0,194.91225658105256,5.849122539486659Z"
              fill={colorScale(borderColor)(1)}
              className={classNames({
                border_svg_shape_3_animate_b: animate && rotationDir,
                border_svg_shape_3_animate_a: animate && !rotationDir
              })}
            ></path>
            <path
              d="M-188,-2.302335982397024e-14A188,188,0,1,1,188,0L186,0A186,186,0,1,0,-186,-2.277843046414077e-14Z"
              fill={colorScale(borderColor)(0.7)}
              className={classNames({
                border_svg_shape_3_animate_a: animate && rotationDir,
                border_svg_shape_3_animate_b: animate && !rotationDir
              })}
            ></path>
            <path
              d="M188,0A188,188,0,1,1,-188,2.302335982397024e-14L-186,2.277843046414077e-14A186,186,0,1,0,186,0Z"
              fill={colorScale(borderColor)(1)}
              className={classNames({
                border_svg_shape_3_animate_a: animate && rotationDir,
                border_svg_shape_3_animate_b: animate && !rotationDir
              })}
            ></path>
          </g>
        </svg>
      )}
      {index === 4 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <g transform="translate(275,200)">
            <path
              d="M169.74097914174996,-98A196,196,0,0,1,169.74097914175,97.99999999999997L165.4108521228278,95.49999999999997A191,191,0,0,0,165.41085212282778,-95.5Z"
              fill={colorScale(borderColor)(0.7)}
              className={classNames({
                border_svg_shape_4_animate_sw: animate && rotationDir,
                border_svg_shape_4_animate_ac: animate && !rotationDir
              })}
            ></path>
            <path
              d="M1.2001538631644062e-14,196A196,196,0,0,1,-169.74097914174993,98.00000000000007L-165.41085212282775,95.50000000000006A191,191,0,0,0,1.1695376931857222e-14,191Z"
              fill={colorScale(borderColor)(0.7)}
              className={classNames({
                border_svg_shape_4_animate_sw: animate && rotationDir,
                border_svg_shape_4_animate_ac: animate && !rotationDir
              })}
            ></path>
            <path
              d="M-169.74097914175005,-97.99999999999989A196,196,0,0,1,-3.6004615894932184e-14,-196L-3.5086130795571664e-14,-191A191,191,0,0,0,-165.41085212282786,-95.49999999999989Z"
              fill={colorScale(borderColor)(0.7)}
              className={classNames({
                border_svg_shape_4_animate_sw: animate && rotationDir,
                border_svg_shape_4_animate_ac: animate && !rotationDir
              })}
            ></path>
            <path
              d="M-141.42135623730954,141.42135623730948A200,200,0,1,1,190.2113032590307,61.803398874989476L188.3091902264404,61.18536488623958A198,198,0,1,0,-140.00714267493643,140.0071426749364Z"
              fill="url(#border_svg_shape_4_gradientpluginPreview)"
              className={classNames({
                border_svg_shape_4_animate_b: animate
              })}
            ></path>
            <path
              d="M-137.17871555019025,137.17871555019022A194,194,0,1,1,184.50496416125978,59.9492969087398L182.60285112866947,59.331262919989896A192,192,0,1,0,-135.76450198781714,135.7645019878171Z"
              fill="url(#border_svg_shape_4_gradientpluginPreview)"
              transform="rotate(-90)"
              className={classNames({
                border_svg_shape_4_animate_c: animate
              })}
            ></path>
            <path
              d="M-133.6431816442575,133.64318164425748A189,189,0,1,1,179.749681579784,58.40421193686506L177.8475685471937,57.78617794811516A187,187,0,1,0,-132.2289680818844,132.22896808188437Z"
              fill="url(#border_svg_shape_4_gradientpluginPreview)"
              transform="rotate(180)"
              className={classNames({
                border_svg_shape_4_animate_d: animate
              })}
            ></path>
          </g>
          <defs>
            <linearGradient id="border_svg_shape_4_gradientpluginPreview" x1="0" y1="0.5" x2="1" y2="0.5">
              <stop stopColor={borderColor} offset="0" stopOpacity="0.8"></stop>
              <stop stopColor={borderColor} offset="0.25" stopOpacity="0.65"></stop>
              <stop stopColor={borderColor} offset="0.5" stopOpacity="0.5"></stop>
              <stop stopColor={borderColor} offset="0.75" stopOpacity="0.25"></stop>
              <stop stopColor={borderColor} offset="0.9" stopOpacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
      )}
      {index === 5 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path d="M2,194L8,194L8,10L542,10L542,194L548,194" fill="none" stroke={stroke} strokeWidth="2"></path>
          <path d="M2,206L8,206L8,390L542,390L542,206L548,206" fill="none" stroke={stroke} strokeWidth="2"></path>
          <path d="M548,50L542,54L542,104L548,100L548,50L548,0L548,0L542,0L542,0L548,0Z" fill={fill}></path>
          <path d="M2,50L8,54L8,104L2,100L2,50L2,0L2,0L8,0L8,0L2,0Z" fill={fill}></path>
          <line x1="8" y1="4" x2="542" y2="4" stroke={stroke} strokeWidth="4" strokeDasharray="4,1,4,1,4,1,4,4">
            {animate && (
              <animate attributeName="stroke-dashoffset" dur="2s" values="23;0" repeatCount="indefinite"></animate>
            )}
          </line>
          <line x1="8" y1="396" x2="542" y2="396" stroke={stroke} strokeWidth="4" strokeDasharray="4,1,4,1,4,1,4,4">
            {animate && (
              <animate attributeName="stroke-dashoffset" dur="2s" values="-23;0" repeatCount="indefinite"></animate>
            )}
          </line>
        </svg>
      )}
      {index === 6 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <rect x="18" y="2" width="514" height="396" stroke={stroke} strokeWidth="2" fill="none"></rect>
          <rect x="6" y="4" width="6" height="6" fill={fill}></rect>
          <rect x="0" y="10" width="6" height="6" fill={fill}></rect>
          <rect x="6" y="390" width="6" height="6" fill={fill}></rect>
          <rect x="0" y="384" width="6" height="6" fill={fill}></rect>
          <rect x="538" y="4" width="6" height="6" fill={fill}></rect>
          <rect x="544" y="10" width="6" height="6" fill={fill}></rect>
          <rect x="538" y="390" width="6" height="6" fill={fill}></rect>
          <rect x="544" y="384" width="6" height="6" fill={fill}></rect>
        </svg>
      )}
      {index === 7 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path
            d="M40.50000000000001,2L509.49999999999994,2L548,30.000000000000004L548,370L509.49999999999994,398L40.50000000000001,398L2,370L2,30.000000000000004L40.50000000000001,2"
            strokeWidth="2"
            stroke={stroke}
            fill="none"
          ></path>
          <path d="M34,1L1,1L1,25L34,1L34,0L1,0L1,0L34,0Z" fill={fill} opacity="0.5"></path>
          <path d="M516,1L549,1L549,25L516,1L516,0L549,0L549,0L516,0Z" fill={fill} opacity="0.5"></path>
          <path d="M549,375L549,399L516,399L549,375L549,0L516,0L549,0L549,0Z" fill={fill} opacity="0.5"></path>
          <path d="M34,399L1,399L1,375L34,399L34,0L1,0L1,0L34,0Z" fill={fill} opacity="0.5"></path>
          <circle cx="11.22" cy="8.16" r="4" fill={fill}></circle>
          <circle cx="11.22" cy="391.84" r="4" fill={fill}></circle>
          <circle cx="538.78" cy="391.84" r="4" fill={fill}></circle>
          <circle cx="538.78" cy="8.16" r="4" fill={fill}></circle>
        </svg>
      )}
      {index === 8 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path
            d="M6,6L489,6L504,6A40,40,0,0,1,544,45.99999999999999L544,394L6,394L6,6"
            stroke={stroke}
            strokeWidth="2"
            fill="none"
          ></path>
          <circle cx="6" cy="6" r="6" fill={fill}></circle>
          <circle cx="6" cy="394" r="6" fill={fill}></circle>
          <circle cx="544" cy="394" r="6" fill={fill}></circle>
          <circle cx="544" cy="225" r="4" fill={fill}></circle>
          <circle cx="544" cy="215" r="4" fill={fill}></circle>
          <circle cx="544" cy="205" r="4" fill={fill}></circle>
          <circle cx="544" cy="195" r="4" fill={fill}></circle>
          <circle cx="544" cy="185" r="4" fill={fill}></circle>
          <circle cx="544" cy="175" r="4" fill={fill}></circle>
          <circle cx="544" cy="165" r="4" fill={fill}></circle>
        </svg>
      )}
      {index === 9 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path d="M29.5,2L2,2L2,22" strokeWidth="2" stroke={stroke} fill="none"></path>
          <path d="M520.5,2L548,2L548,22" strokeWidth="2" stroke={stroke} fill="none"></path>
          <path d="M548,378L548,398L520.5,398" strokeWidth="2" stroke={stroke} fill="none"></path>
          <path d="M29.5,398L2,398L2,378" strokeWidth="2" stroke={stroke} fill="none"></path>
        </svg>
      )}
      {index === 10 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path d="M50,2L6,2L6,398L50,398" stroke={stroke} strokeWidth="2" fill="none"></path>
          <path d="M500,2L544,2L544,398L500,398" stroke={stroke} strokeWidth="2" fill="none"></path>
          <path d="M6,80L6,320" stroke={stroke} fill="none" strokeWidth="7" strokeDasharray="2,5"></path>
          <path d="M544,80L544,320" stroke={stroke} fill="none" strokeWidth="7" strokeDasharray="2,5"></path>
        </svg>
      )}
      {index === 11 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path d="M2,2L548,2L548,362L497,398L2,398L2,2" fill="none" stroke={stroke} strokeWidth="2"></path>
        </svg>
      )}
      {index === 12 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path d="M2,2M2,2h546v396h-546Z" fill="none" strokeWidth="2" stroke={stroke}></path>
          <path d="M0,20L0,0L20,0" fill="none" strokeWidth="10" stroke={stroke}></path>
          <path d="M530,0L550,0L550,20" fill="none" strokeWidth="10" stroke={stroke}></path>
          <path d="M550,380L550,400L530,400" fill="none" strokeWidth="10" stroke={stroke}></path>
          <path d="M0,380L0,400L20,400" fill="none" strokeWidth="10" stroke={stroke}></path>
        </svg>
      )}
      {index === 13 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path
            d="M413.7770897832817,392.156862745098L44.27244582043344,392.156862745098L13.622291021671828,341.1764705882353L13.622291021671828,37.254901960784316L39.1640866873065,7.8431372549019605L493.80804953560374,7.8431372549019605L543.1888544891641,33.33333333333333L543.1888544891641,364.70588235294116L515.9442724458204,392.156862745098L510.8359133126935,392.156862745098L510.8359133126935,384.3137254901961L413.7770897832817,384.3137254901961L413.7770897832817,396.07843137254906L417.1826625386997,396.07843137254906L417.1826625386997,388.2352941176471L507.43034055727554,388.2352941176471L507.43034055727554,396.07843137254906L515.9442724458204,396.07843137254906L519.3498452012384,396.07843137254906L550,364.70588235294116L550,31.372549019607842L526.1609907120743,0L35.758513931888544,0L1.7027863777089784,41.17647058823529L1.7027863777089784,349.01960784313724L44.27244582043344,396.07843137254906L413.7770897832817,396.07843137254906L413.7770897832817,392.156862745098"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M141.33126934984523,7.8431372549019605L161.76470588235296,56.86274509803921"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M412.0743034055728,7.8431372549019605L389.938080495356,56.86274509803921"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M161.76470588235296,56.86274509803921L389.938080495356,56.86274509803921L386.53250773993807,62.745098039215684L165.1702786377709,62.745098039215684L161.76470588235296,56.86274509803921"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M71.51702786377709,7.8431372549019605L76.62538699690403,15.686274509803921"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M81.73374613003095,7.8431372549019605L86.84210526315789,15.686274509803921"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M473.37461300309593,7.8431372549019605L468.26625386996903,15.686274509803921"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M483.5913312693499,7.8431372549019605L478.48297213622294,15.686274509803921"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M49.38080495356037,7.8431372549019605L13.622291021671828,50.98039215686274"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M68.11145510835914,384.3137254901961L73.21981424148608,392.156862745098"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M78.328173374613,384.3137254901961L83.43653250773994,392.156862745098"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M476.78018575851394,374.5098039215686L471.671826625387,384.3137254901961"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M486.9969040247678,374.5098039215686L481.8885448916409,384.3137254901961"
            fill={fill}
            strokeWidth="1"
            stroke={stroke}
          ></path>
          <path
            d="M420.5882352941176,392.25490196078437L502.3219814241486,392.25490196078437"
            strokeWidth="1"
            stroke={stroke}
            strokeDasharray="3,2"
          ></path>
          <path
            d="M416.3312693498452,396.07843137254906L416.3312693498452,400L508.28173374613004,400L508.28173374613004,396.07843137254906"
            fill="none"
            strokeWidth="4"
            stroke={stroke}
          ></path>
          <path
            d="M20.433436532507738,50.98039215686274L20.433436532507738,341.1764705882353"
            strokeWidth="1"
            stroke={stroke}
            strokeDasharray="3,2"
          ></path>
          <path
            d="M534.6749226006192,50.98039215686274L534.6749226006192,341.1764705882353"
            strokeWidth="1"
            stroke={stroke}
            strokeDasharray="3,2"
          ></path>
          <defs>
            <linearGradient id="c2e8ae5" x1="50%" y1="0%" x2="50%" y2="100%">
              <stop offset="0%" style={{ stopColor: stroke }} stopOpacity="0"></stop>
              <stop offset="100%" style={{ stopColor: stroke }} stopOpacity="1"></stop>
            </linearGradient>
          </defs>
          <path
            d="M141.33126934984523,7.8431372549019605L161.76470588235296,56.86274509803921L389.938080495356,56.86274509803921L412.0743034055728,7.8431372549019605L141.33126934984523,7.8431372549019605"
            fill="url(#c2e8ae5)"
          ></path>
        </svg>
      )}
      {index === 14 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path
            d="M229.87616099071207,384.3137254901961L40.866873065015476,384.3137254901961L10.216718266253869,352.94117647058823L10.216718266253869,39.21568627450981L40.866873065015476,7.8431372549019605L206.0371517027864,7.8431372549019605L246.90402476780187,31.372549019607842L521.0526315789473,31.372549019607842C521.0526315789473,31.372549019607842,551.702786377709,29.411764705882355,550,62.745098039215684"
            fill="none"
            strokeWidth="4"
            stroke={stroke}
          ></path>
          <path
            d="M550,62.745098039215684L550,123.52941176470588"
            fill="none"
            strokeWidth="4"
            stroke={stroke}
            opacity="1"
          ></path>
          <path
            d="M550,123.52941176470588L550,288.2352941176471"
            fill="none"
            strokeWidth="4"
            stroke={stroke}
            opacity="0.5"
          ></path>
          <path
            d="M550,288.2352941176471ZC550,352.94117647058823,553.405572755418,384.3137254901961,507.43034055727554,384.3137254901961"
            fill="none"
            strokeWidth="4"
            stroke={stroke}
            opacity="1"
          ></path>
          <path
            d="M507.43034055727554,384.3137254901961L243.4984520123839,384.3137254901961"
            fill="none"
            strokeWidth="4"
            stroke={stroke}
            opacity="0.5"
          ></path>
          <path
            d="M233.28173374613004,376.4705882352941L229.87616099071207,376.4705882352941L229.87616099071207,380.3921568627451"
            fill="none"
            strokeWidth="1"
            stroke={stroke}
            opacity="1"
          ></path>
          <path
            d="M240.09287925696594,376.4705882352941L243.4984520123839,376.4705882352941L243.4984520123839,380.3921568627451"
            fill="none"
            strokeWidth="1"
            stroke={stroke}
            opacity="1"
          ></path>
          <path
            d="M229.87616099071207,388.2352941176471L229.87616099071207,392.156862745098L233.28173374613004,392.156862745098"
            fill="none"
            strokeWidth="1"
            stroke={stroke}
            opacity="1"
          ></path>
          <path
            d="M240.09287925696594,392.156862745098L243.4984520123839,392.156862745098L243.4984520123839,388.2352941176471"
            fill="none"
            strokeWidth="1"
            stroke={stroke}
            opacity="1"
          ></path>
          <path
            d="M250.3095975232198,13.725490196078432L529.5665634674923,13.725490196078432"
            fill="none"
            strokeWidth="6"
            stroke={stroke}
            strokeDasharray="2,3.5"
          ></path>
          <defs>
            <linearGradient id="c2e8ae5" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0" stopColor={stroke} stopOpacity="1"></stop>
              <stop offset="1" stopColor={stroke} stopOpacity="0"></stop>
            </linearGradient>
          </defs>
          <path
            d="M44.27244582043344,27.450980392156865L170.27863777089783,27.47058823529412"
            fill="none"
            strokeWidth="6"
            stroke="url(#c2e8ae5)"
            strokeDasharray="13,2"
          ></path>
          <path
            d="M0,313.72549019607845L0,339.2156862745098"
            fill="none"
            strokeWidth="4"
            stroke={stroke}
            opacity="4"
          ></path>
        </svg>
      )}
      {index === 15 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <g id="gruop1659074918620">
            <rect x="0" y="0" width="13.622291021671828" height="15.686274509803921" fill={fill} opacity="0.8"></rect>
            <path
              d="M13.622291021671828,7.8431372549019605L536.3777089783282,7.8431372549019605"
              fill="none"
              stroke={stroke}
              opacity="0.4"
              strokeWidth="2"
            ></path>
            <rect
              x="536.3777089783282"
              y="0"
              width="13.622291021671828"
              height="15.686274509803921"
              fill={fill}
              opacity="0.8"
            ></rect>
          </g>
          <use xlinkHref="#gruop1659074918620" x="0" y="384.3137254901961"></use>
        </svg>
      )}
      {index === 16 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <g id="goup21">
            <path d="M0,0L550,0" fill="none" stroke={stroke} strokeWidth="5"></path>
            <path
              d="M0,244.2156862745098L550,244.2156862745098"
              fill="none"
              stroke={stroke}
              opacity="0.1"
              strokeWidth="375"
            ></path>
          </g>
        </svg>
      )}
      {index === 17 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <g id="goup22">
            <path d="M519.3498452012384,0L0,0.1" fill="none" stroke="url(#c2e8ae5)" strokeWidth="5"></path>
            <path d="M519.3498452012384,0L550,0" fill="none" stroke={stroke} strokeWidth="5"></path>
            <path
              d="M0,244.2156862745098L550,244.2156862745098"
              fill="none"
              stroke={stroke}
              opacity="0.1"
              strokeWidth="375"
            ></path>
          </g>
          <defs>
            <linearGradient id="c2e8ae5" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0.5" stopColor={stroke} stopOpacity="0"></stop>
              <stop offset="1" stopColor={stroke} stopOpacity="0.3"></stop>
            </linearGradient>
          </defs>
        </svg>
      )}
      {index === 18 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <g id="goup23">
            <path
              d="M0,29.411764705882355L550,29.401764705882353"
              fill="none"
              stroke="url(#c2e8ae5)"
              strokeWidth="58.82352941176471"
            ></path>
            <rect
              x="2"
              y="78.43137254901961"
              width="546"
              height="317.5686274509804"
              fill="none"
              stroke={stroke}
              strokeWidth="2"
              opacity="0.5"
            ></rect>
          </g>
          <defs>
            <linearGradient id="c2e8ae5" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0" stopColor={stroke} stopOpacity="0.5"></stop>
              <stop offset="0.5" stopColor={stroke} stopOpacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
      )}
      {index === 19 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <g id="goup22">
            <rect
              x="11.270491803278688"
              y="10.1010101010101"
              width="527.4590163934427"
              height="379.7979797979798"
              fill="none"
              stroke={stroke}
              strokeWidth="2"
            ></rect>
          </g>
          <g id="rectFa">
            <path
              d="M0,0L22.540983606557376,0L22.540983606557376,20.2020202020202L0,20.2020202020202L0,0"
              fill={fill}
              opacity="0.5"
              stroke={stroke}
              strokeWidth="0"
              transform="translate(0 0)"
            ></path>
            <path
              d="M0,0L22.540983606557376,0L22.540983606557376,20.2020202020202L0,20.2020202020202L0,0"
              fill={fill}
              opacity="0.5"
              stroke={stroke}
              strokeWidth="0"
              transform="translate(527.4590163934427 0)"
            ></path>
            <path
              d="M0,0L22.540983606557376,0L22.540983606557376,20.2020202020202L0,20.2020202020202L0,0"
              fill={fill}
              opacity="0.5"
              stroke={stroke}
              strokeWidth="0"
              transform="translate(527.4590163934427 379.7979797979798)"
            ></path>
            <path
              d="M0,0L22.540983606557376,0L22.540983606557376,20.2020202020202L0,20.2020202020202L0,0"
              fill={fill}
              opacity="0.5"
              stroke={stroke}
              strokeWidth="0"
              transform="translate(0 379.7979797979798)"
            ></path>
            <path
              d="M5.635245901639344,5.05050505050505L16.90573770491803,5.05050505050505L16.90573770491803,15.15151515151515L5.635245901639344,15.15151515151515L5.635245901639344,5.05050505050505"
              fill={fill}
              opacity="0.8"
              stroke={stroke}
              strokeWidth="0"
              transform="translate(0 0)"
            ></path>
            <path
              d="M5.635245901639344,5.05050505050505L16.90573770491803,5.05050505050505L16.90573770491803,15.15151515151515L5.635245901639344,15.15151515151515L5.635245901639344,5.05050505050505"
              fill={fill}
              opacity="0.8"
              stroke={stroke}
              strokeWidth="0"
              transform="translate(527.4590163934427 0)"
            ></path>
            <path
              d="M5.635245901639344,5.05050505050505L16.90573770491803,5.05050505050505L16.90573770491803,15.15151515151515L5.635245901639344,15.15151515151515L5.635245901639344,5.05050505050505"
              fill={fill}
              opacity="0.8"
              stroke={stroke}
              strokeWidth="0"
              transform="translate(527.4590163934427 379.7979797979798)"
            ></path>
            <path
              d="M5.635245901639344,5.05050505050505L16.90573770491803,5.05050505050505L16.90573770491803,15.15151515151515L5.635245901639344,15.15151515151515L5.635245901639344,5.05050505050505"
              fill={fill}
              opacity="0.8"
              stroke={stroke}
              strokeWidth="0"
              transform="translate(0 379.7979797979798)"
            ></path>
          </g>
        </svg>
      )}
      {index === 20 && (
        <svg width="100%" height="100%" viewBox="0 0 550 400" preserveAspectRatio="none">
          <path
            id="p1659075950409"
            d="M38.372093023255815,1L5,1L5,399L38.372093023255815,399"
            fill="none"
            strokeWidth="2"
            stroke={stroke}
            opacity="0.6"
          ></path>
          <use xlinkHref="#p1659075950409" x="-554" y="0" style={{ transform: 'rotateY(-180deg)' }}></use>
          <rect
            x="0"
            y="135.82089552238807"
            width="10"
            height="128.3582089552239"
            fill={fill}
            strokeWidth="0"
            stroke={stroke}
          ></rect>
        </svg>
      )}
    </div>
  );
};

export default Border;
