import React, { FC, useState, useEffect, createRef, useRef } from 'react';
import { Icon, message, Empty, Button, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { uuid } from '@/utils/utils';
import APIS from '@/apis/api';
import { http } from '@/lib';
import List from './List';
import WrappedFormSetting from './FormSetting';
import { IWarningSettingProps } from './data';
import { WarningSettingCtx } from './context';
import { warningDefaultConfig } from './utils';
import './style.less';

const WarningSetting: FC<IWarningSettingProps> = props => {
  const {
    activeChart: { id: boardChartsId, dimensionFields, measureFields },
    boardId,
    monitorName,
    sourceType,
    onSave
  } = props;
  const [list, setList] = useState([]); // 规则列表
  const [originalList, setOriginalList] = useState([]); // 原始规则列表
  const [activeIndex, setActiveIndex] = useState(0); // 当前规则索引
  const [activeFormConfig, setActiveFormConfig] = useState(null); // 当前规则配置项
  const [count, setCount] = useState(1); // 规则名称序号
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isFetchingDataDone, setIsFetchingDataDone] = useState(false);
  // const formRef = createRef<FormComponentProps>();
  const formRef = useRef<HTMLFormElement>();
  useEffect(() => {
    http.post(APIS.MonitorDetails, { id: boardChartsId }).then(res => {
      // console.log('fetch list: ', res, monitorName);
      const ruleList = res.data;
      setIsFetchingDataDone(true);
      if (ruleList.length === 0) {
        // 如果后台返回规则列表为空，默认添加一条规则
        const defaultConfig = Object.assign({}, warningDefaultConfig, {
          uuId: `fake-${uuid()}`,
          isActive: true,
          isValid: true,
          monitorName: `${warningDefaultConfig.monitorName}${count}`
        });
        setList([defaultConfig]);
        setActiveFormConfig(defaultConfig);
        return;
      }
      setList(
        ruleList.map((itemRule, index) => ({
          ...itemRule,
          havingConditionModels:
            itemRule.havingConditionModels.length > 0
              ? itemRule.havingConditionModels.map(itemModel => ({ ...itemModel, id: uuid() }))
              : [],
          conditions:
            itemRule.conditions.length > 0
              ? itemRule.conditions.map(itemCondition => ({ ...itemCondition, id: uuid() }))
              : [],
          isActive: index === 0,
          isValid: true,
          uuId: itemRule.id
        }))
      );
      setActiveFormConfig({
        ...ruleList[0],
        havingConditionModels:
          ruleList[0].havingConditionModels.length > 0
            ? ruleList[0].havingConditionModels.map(itemModel => ({ ...itemModel, id: uuid() }))
            : [],
        conditions:
          ruleList[0].conditions.length > 0
            ? ruleList[0].conditions.map(itemCondition => ({ ...itemCondition, id: uuid() }))
            : [],
        isActive: true,
        isValid: true,
        uuId: ruleList[0].id
      });
      setOriginalList(
        ruleList.map(itemRule => ({
          ...itemRule,
          havingConditionModels:
            itemRule.havingConditionModels.length > 0
              ? itemRule.havingConditionModels.map(itemModel => ({ ...itemModel, id: uuid() }))
              : [],
          conditions:
            itemRule.conditions.length > 0
              ? itemRule.conditions.map(itemCondition => ({ ...itemCondition, id: uuid() }))
              : [],
          isActive: false,
          isValid: true,
          uuId: itemRule.id
        }))
      );
      if (monitorName) {
        const monitorNameIndex = ruleList.findIndex(itemRule => itemRule.monitorName === monitorName);
        setActiveIndex(monitorNameIndex);
      }
      if (ruleList.length > 1) {
        setCount(ruleList.length);
      }
    });
  }, []);
  useEffect(() => {
    if (isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }
    // console.log('activeIndex: ', activeIndex, list[activeIndex]);
    setList(
      list.map((item, index) => {
        if (index === activeIndex) {
          return { ...item, isActive: true };
        }
        return { ...item, isActive: false };
      })
    );
    setActiveFormConfig(list[activeIndex]);
  }, [activeIndex]);
  // 渲染列表
  const renderList = list => {
    return <List list={list} />;
  };
  const handleAddItem = () => {
    if (list.length >= 10) {
      message.error('最多只能添加10条监控规则');
      return;
    }
    const newItem = Object.assign({}, warningDefaultConfig, {
      uuId: `fake-${uuid()}`,
      isActive: true,
      isValid: true,
      monitorName: `${warningDefaultConfig.monitorName}${count + 1}`
    });
    const prevRuleFormData = formRef.current.form.getFieldsValue().formData; // 缓存上条规则配置
    // console.log('prevRuleFormData: ', prevRuleFormData);
    setList([
      ...list.map((item, index) => {
        if (index === activeIndex) {
          return { ...item, ...prevRuleFormData, isActive: false };
        }
        return { ...item, isActive: false };
      }),
      newItem
    ]);
    setActiveIndex(list.length);
    setCount(count + 1);
  };
  const handleAddItemNow = () => {
    const newItem = Object.assign({}, warningDefaultConfig, {
      uuId: `fake-${uuid()}`,
      isActive: true,
      isValid: true,
      monitorName: `${warningDefaultConfig.monitorName}${count}`
    });
    setList([newItem]);
    setActiveIndex(0);
    setActiveFormConfig(newItem);
  };
  const handleDeleteItem = ({ id, index }) => {
    let activeIndex = index === 0 ? 0 : index - 1;
    setList(
      list.filter(item => item.uuId !== id).map((item, itemIndex) => ({ ...item, isActive: itemIndex === activeIndex }))
    );
    setActiveIndex(activeIndex);
    setCount(activeIndex > 0 ? count - 1 : 1);
  };
  const handleUpdateItemMonitorName = ({ id, monitorName }) => {
    const updatedList = list.map(item => {
      if (item.uuId === id) {
        return { ...item, monitorName, isValid: monitorName !== '' };
      }
      return item;
    });
    setList(updatedList);
  };
  const handleUpdateItemStatusValid = ({ id, isValid }) => {
    const updatedList = list.map(item => {
      if (item.uuId === id) {
        return { ...item, isValid };
      }
      return item;
    });
    setList(updatedList);
  };
  const handleUpdateItemStatusActive = ({ id, isActive }) => {
    const index = list.findIndex(item => item.uuId === id);
    const prevRuleFormData = formRef.current.form.getFieldsValue().formData; // 缓存上条规则配置
    const updatedList = list.map((item, index) => {
      if (item.uuId === id) {
        return { ...item, isActive };
      }
      return index === activeIndex ? { ...item, ...prevRuleFormData, isActive: false } : { ...item, isActive: false };
    });
    setList(updatedList);
    setActiveIndex(index);
  };
  const updateActiveItem = itemActive => {
    const updatedList = list.map((item, index) => {
      if (index === activeIndex) {
        return { ...item, ...itemActive, uuId: itemActive.id };
      }
      return item;
    });
    setList(updatedList);
    setActiveFormConfig({
      ...itemActive,
      uuId: itemActive.id
    });
  };
  const handleSaveActiveItemConfig = formData => {
    // 表单配置项 维度 度量特殊处理
    formData.formData.havingConditionModels = formData.formData.havingConditionModels.map(itemModel => {
      const dataSetField = measureFields.filter(itemMeasureField => {
        const { uniqueId, calculatorType, ratioCalculator } = itemMeasureField;
        let val = `${uniqueId.indexOf('clone') !== -1 ? uniqueId.replace('-clone', '') : uniqueId}-${calculatorType}`;
        if (ratioCalculator) {
          val += `-${ratioCalculator.type}`;
        }
        return val === itemModel.dataSetField.uniqueId;
      })[0];
      return {
        ...itemModel,
        dataSetField: {
          ...dataSetField,
          uniqueId: dataSetField.uniqueId.replace('-clone', '')
        },
        conditionJoin: 'OR'
      };
    });
    formData.formData.conditions =
      formData.formData.conditions && formData.formData.conditions.length > 0
        ? formData.formData.conditions.map(itemCondition => ({
            ...itemCondition,
            conditionJoin: 'AND',
            fieldUniqueId: itemCondition.fieldUniqueId.replace('-clone', '')
          }))
        : [];
    if (typeof formData.formData.status === 'boolean') {
      formData.formData.status = formData.formData.status ? 'ENABLE' : 'DISABLE';
    }
    const cronType = formRef.current.formData.cronType;
    const cronModel = formRef.current.formData.cronModel;
    if (cronType === 'EVERY_HOUR') {
      delete cronModel.hour;
    }
    let params = {
      boardId,
      boardChartsId,
      warnReqVos: [
        {
          ...list[activeIndex],
          ...formData.formData,
          cronType,
          cronModel
        }
      ]
    };
    setIsFetchingDataDone(false);
    http.post(APIS.SaveMonitor, params).then(res => {
      if (list[activeIndex].uuId.startsWith('fake')) {
        // 替换临时数据id
        const resData = res.data;
        updateActiveItem(resData[0]);
      }
      setIsFetchingDataDone(true);
      message.success('当前预警规则保存成功，请继续添加或关闭本页面。');
      if (sourceType && sourceType === 'dashboard') {
        onSave(boardChartsId);
      }
    });
  };
  const handleCancelActiveItemConfig = () => {
    if (activeFormConfig.uuId.startsWith('fake')) {
      setActiveFormConfig({
        havingConditionModels: [
          {
            id: uuid(),
            dataSetField: {
              name: ''
            },
            operator: '',
            values: ['']
          }
        ],
        conditions: [],
        status: 'ENABLE',
        message: {
          emailMsg: {
            headerAddress: ''
          },
          smsMsg: {
            mobiles: ''
          },
          dingTalkMsg: {
            accessToken: ''
          },
          weChatMsg: {
            accessToken: ''
          },
          feiShuMsg: {
            accessToken: ''
          }
        },
        cornType: 'EVERY_MONTH',
        cornValue: '1',
        notifyType: 'EMAIL'
      });
      return;
    }
    const activeItemOriginalFormConfig = originalList[activeIndex];
    setActiveFormConfig({
      ...activeFormConfig,
      havingConditionModels: activeItemOriginalFormConfig.havingConditionModels,
      conditions: activeItemOriginalFormConfig.conditions,
      status: activeItemOriginalFormConfig.status,
      message: activeItemOriginalFormConfig.message,
      cornType: activeItemOriginalFormConfig.cornType,
      cornValue: activeItemOriginalFormConfig.cornValue,
      notifyType: activeItemOriginalFormConfig.notifyType
    });
  };
  // console.log('WarningSetting render: ', list, activeFormConfig);
  return (
    <>
      {!isFetchingDataDone && !list.length && (
        <div style={{ padding: '52px', textAlign: 'center' }}>
          <Spin />
        </div>
      )}
      {isFetchingDataDone && list.length === 0 && (
        <div className="no-data">
          <Empty description={<span>暂无数据，您可以点击"立即添加"按钮添加相关监控规则</span>}>
            <Button type="primary" onClick={handleAddItemNow}>
              立即添加
            </Button>
          </Empty>
        </div>
      )}
      {list.length > 0 && (
        <WarningSettingCtx.Provider
          value={{
            handleDeleteItem,
            handleUpdateItemMonitorName,
            handleUpdateItemStatusActive,
            handleUpdateItemStatusValid
          }}
        >
          <Spin spinning={!isFetchingDataDone}>
            <div className="warning-setting-wrap">
              <div className="warning-setting-aside">
                <div className="monitor-hd">
                  <h3 className="monitor-title">监控规则({list.length}/10)</h3>
                  <Icon type="plus" style={{ fontSize: '16px', color: '#0089c4' }} onClick={handleAddItem} />
                </div>
                {renderList(list)}
              </div>
              <div className="warning-setting-content">
                <div className="warning-settings-form-wrap">
                  {activeFormConfig && list.findIndex(item => item.isActive) === activeIndex && (
                    <WrappedFormSetting
                      activeFormConfig={activeFormConfig}
                      dimensionFieldOpts={
                        Array.isArray(dimensionFields)
                          ? dimensionFields.map(field => ({
                              ...field,
                              uniqueId: field.uniqueId.indexOf('-clone')
                                ? field.uniqueId.replace('-clone', '')
                                : field.uniqueId
                            }))
                          : []
                      }
                      measureFieldOpts={measureFields.map(field => ({
                        ...field,
                        uniqueId: field.uniqueId.indexOf('-clone')
                          ? field.uniqueId.replace('-clone', '')
                          : field.uniqueId
                      }))}
                      saveEvent={handleSaveActiveItemConfig}
                      cancelEvent={handleCancelActiveItemConfig}
                      wrappedComponentRef={formRef}
                    />
                  )}
                </div>
              </div>
            </div>
          </Spin>
        </WarningSettingCtx.Provider>
      )}
    </>
  );
};

export default WarningSetting;
