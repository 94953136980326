export const DEFAULT_TEXT_STYLE = {
  backgroundColor: 'rgba(41,52,65,1)',
  fontSize: 16,
  color: '#fff',
  fontStyle: 'normal',
  fontWeight: 'normal',
  hPosition: 'center',
  letterSpacing: 0,
  a: {
    enable: false,
    href: 'https://',
    target: '_blank'
  }
};

export const DEFAULT_CUR_TIME_STYLE = {
  backgroundColor: 'rgba(41,52,65,1)',
  fontSize: 22,
  color: '#fff',
  fontStyle: 'normal',
  fontWeight: 'normal',
  hPosition: 'center',
  dateFormat: 'ymdhms2',
  weekFormat: 'toWest'
};

export const DEFAULT_PICTURE_STYLE = {
  backgroundColor: 'rgba(41,52,65,1)',
  borderWidth: 0,
  borderStyle: 'solid',
  borderColor: 'rgba(0,0,0,1)',
  borderRadius: 0,
  adaptation: 'adaptation',
  hyperlinks: {
    enable: false,
    openMode: '_blank',
    content: 'https://'
  }
};

export const DEFAULT_VIDEO_STYLE = {
  borderRadius: 0,
  loop: true,
  autoPlay: true
};

export const DEFAULT_STATISTIC_STYLE = {
  theme: 'shadow',
  themeColor: '#2f54c9',
  addon: {
    fontSize: 14,
    color: 'rgba(255,255,255,0.65)'
  },
  prefix: {
    marginRight: 4
  },
  suffix: {
    marginLeft: 4
  },
  value: {
    interval: 5,
    fontSize: 26,
    color: 'rgba(255,255,255,1)',
    fontFamily: 'monospace',
    fontWeight: 'normal',
    precision: 2,
    groupSeparator: true
  }
};
