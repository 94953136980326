import { observable, action, makeObservable } from 'mobx';

export class DataSourceStore {
  activeDataSource = null; // 当前选中数据源

  constructor() {
    makeObservable(this, {
      activeDataSource: observable,
      setActiveDataSource: action
    });
  }
  setActiveDataSource(payload) {
    this.activeDataSource = payload;
  }
}
export default new DataSourceStore();
