import _ from 'lodash';
import React, { ReactNode } from 'react';
import { Icon, Tooltip, Affix, Button } from 'antd';
import { AffixProps } from 'antd/lib/affix';
import { udConfigProvider } from '../..';

/**
 * 页面头部
 */
const UdPageHeader: React.FC<IUdPageHeaderProps> = (props: IUdPageHeaderProps) => {
  let node = (
    <div className="ud-page-header">
      <div className="ud-page-header-heading">
        {props.title && <span className="ud-page-header-heading-title">{props.title}</span>}
        {props.subTitle && <span className="ud-page-header-heading-sub-title">{props.subTitle}</span>}
      </div>
      <div className="ud-page-header-extra">
        {props?.saveButton}
        {_.isFunction(props.onRefresh) && (
          <Tooltip title="刷新" placement="bottom">
            <Icon type="sync" onClick={props.onRefresh} />
          </Tooltip>
        )}
        {props.onBack != null && (
          <Tooltip title="返回上一页" placement="bottomRight">
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => {
                back(props.onBack!);
              }}>
              返回
            </Button>
            {/* <Icon type="arrow-left" onClick={() => {
              back(props.onBack!)
            }} /> */}
          </Tooltip>
        )}
      </div>
    </div>
  );
  if (props.useAffix !== false) {
    node = (
      <Affix className="ud-page-header-affix" offsetTop={0} target={() => udConfigProvider.ui.getPageContainer()} {...props.affixProps}>
        {node}
      </Affix>
    );
  }

  return node;
};

const back = (param: (() => void) | boolean) => {
  if (param === true) {
    window.history.back();
  } else {
    (param as any)();
  }
};

interface IUdPageHeaderProps {
  /**
   * 标题
   */
  title: ReactNode;
  /**
   * 副标题
   */
  subTitle?: ReactNode;
  /**
   * 是否使用固钉
   * @default true
   */
  useAffix?: boolean;
  /**
   * 固定Props
   */
  affixProps?: Partial<AffixProps>;
  /**
   * 刷新
   */
  onRefresh?: () => void;
  /**
   * 回退，传入 `true` 为返回上一页
   */
  onBack?: boolean | (() => void);

  /**
   * 保存按钮
   */
  saveButton?: ReactNode;
}

export default UdPageHeader;
