import moment from 'moment';
import dataSetStore from '@/store/DataSet';
import { FieldsType } from '@/pages/DataSet/Edit/common';

interface IDeepLoopFormateTreeOption {
  datas: any;
  translate?: { value?: string; title?: string; key?: string; children?: string; extra?: string };
  originKey?: { value?: string; title?: string; children?: string };
}
export const deepLoopFormateTree = ({
  datas,
  translate = { value: 'value', title: 'title', key: 'key', children: 'children', extra: '' },
  originKey = { value: 'customId', title: 'text', children: 'child' }
}: IDeepLoopFormateTreeOption) => {
  if (!Array.isArray(datas) || datas.length == 0) {
    return datas;
  }
  let res = [];
  datas.map(data => {
    const { [originKey.children]: children, [originKey.value]: value, [originKey.title]: title } = data;
    if (!value) {
      return;
    }
    let item: any = {
      [translate.value]: value,
      [translate.title]: title,
      [translate.key]: value,
      [translate.children]: []
    };
    if (children) {
      item[translate.children] = deepLoopFormateTree({ datas: children, translate, originKey });
    }
    if (translate.extra && data[translate.extra]) {
      item[translate.extra] = data[translate.extra];
    }
    res.push(item);
  });
  return res;
};

export const findTreeNodeInfo = (datas, val, findKey = 'value', children = 'children') => {
  let info: any = {};
  const loop = datas => {
    if (!Array.isArray(datas) || datas.length == 0) {
      return;
    }
    for (const key in datas) {
      if (datas.hasOwnProperty(key)) {
        if (val == datas[key][findKey]) {
          info = datas[key];
          return;
        }
        datas[key][children] && loop(datas[key][children]);
      }
    }
  };
  loop(datas);
  return info;
};

export const findTreeParentInfo = (datas, val, findKey = 'value', children = 'children') => {
  let info: any = {};
  if (!val) {
    return;
  }
  const loop = datas => {
    if (!Array.isArray(datas) || datas.length == 0) {
      return;
    }
    for (const key in datas) {
      if (datas.hasOwnProperty(key)) {
        if (datas[key][children].some(item => item[findKey] === val)) {
          info = datas[key];
          return;
        }
        datas[key][children] && loop(datas[key][children]);
      }
    }
  };
  loop(datas);
  return info;
};

export const debounce = (fn, wait = 500, delay = 300) => {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    let arg = arguments;
    timer = setTimeout(() => {
      fn.apply(this, arg);
      timer = null;
    }, wait);
  };
};

// Blob文件转换下载
export const downBlobFile = (
  result,
  fileName,
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;',
  submitEndDo?,
  timeSuffix = true
) => {
  const data = result;
  const blob = new Blob([data], { type: fileType });
  if (timeSuffix) {
    // 默认导出的文件名称添加时间
    const time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    fileName = fileName + ' ' + time;
  }

  const objectUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('style', 'display:none');
  a.setAttribute('href', objectUrl);
  a.setAttribute('download', fileName + '.xlsx');
  a.click();
  URL.revokeObjectURL(objectUrl);
  if (submitEndDo) {
    submitEndDo();
    submitEndDo = null;
  }
};

interface TreeDataDts {
  id: string;
  name: string;
  children: TreeDataDts[];
  sapMatCodes: { name: string; code: string }[];
}
type TreeDatasDts = TreeDataDts[];

//树形遍历
export const treeMap = (data: TreeDatasDts) => {
  data = data || [];
  return data.map(item => {
    if (!item.children) {
      item.children = [];
    }
    return {
      value: item.id,
      label: item.name,
      children: treeMap(item.children)
    };
  });
};

// 获取oss文件key
export const getOssFileKey = url => {
  const findIndexString = '.aliyuncs.com/';
  const index = url.indexOf(findIndexString);
  const key = url.substring(index + findIndexString.length);
  return key;
};

//树形遍历节点下所有子值
export const treeMapSap = (arr: any[], data: string[]) => {
  for (let i = 0; i < data.length; i++) {
    for (let a = 0; a < arr.length; a++) {
      if (arr[a].id === data[i]) {
        if (data.length !== i + 1) {
          arr = arr[a].children;
        } else {
          return arr[a].sapMatCodes;
        }
      }
    }
  }
};

/**
 * 处理formData格式参数中需要支持List[Map]类型的接口格式，目前只支持一层List[Map]数据，返回格式如：
  comment: 测试审批接口
  taskId: 27524
  userId: 421
  attachments[0].url: http://lzlj-jxsmh.oss-accelerate.aliyuncs.com/xxx.jpg
  attachments[0].name: {FD0AE82F-7F63-42F6-A68D-D740600A01FF}_20200310102847.jpg
  attachments[0].type: url
 * 使用qs.stringify()方法处理的formData数据如果是List[Map]格式的，会默认处理成attachments[0][url]，服务端无法接收
 * @param params 请求参数对象
 */
export function formatFormDataParams(params) {
  const formData = new FormData();
  for (const paramkey in params) {
    if (params.hasOwnProperty(paramkey)) {
      const param = params[paramkey];
      if (Array.isArray(param)) {
        let arr = param;
        for (let i = 0; i < arr.length; i++) {
          if (typeof arr[i] === 'string') {
            // 如果是字符串数组，则不处理，传默认数组
            formData.append(`${paramkey}`, arr as any);
            break;
          }
          for (let key in arr[i]) {
            formData.append(`${paramkey}[${i}].${key}`, arr[i][key]);
          }
        }
      } else {
        formData.append(`${paramkey}`, params[paramkey]);
      }
    }
  }
  return formData;
}

export function isBlobBufferResponse(responseType) {
  return responseType == 'blob' || responseType == 'arraybuffer';
}

export function arraybufferToObject(arraybuffer) {
  let uint8: any = new Uint8Array(arraybuffer); // 提取uint8Array
  let fromCharCode = String.fromCharCode(...uint8);
  let resToString = decodeURIComponent(escape(fromCharCode));
  // 不是文件流数据，将转换好的数据重新覆盖
  return JSON.parse(resToString);
}

export function blobToObject(blob) {
  // 读取blob内容只能通过FileReader，且是异步读取到内容
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function () {
      resolve(JSON.parse(reader.result as string));
    };
    reader.readAsText(blob, 'utf-8');
  });
}

// children默认会使table渲染成可展开树型table，这里需要替换children为其他字段名
export function replaceChildrenTableData(datas, oldKey = 'children', key = 'items') {
  if (!Array.isArray(datas) || datas.length < 1) {
    return datas;
  }
  let res = [];
  datas.map(data => {
    const { [oldKey]: children, ...reset } = data;
    // 剔除oldKey数据
    let item = reset;
    if (children) {
      // 使用新key替换oldKey的数据
      item[key] = replaceChildrenTableData(children);
    }
    res.push(item);
  });
  return res;
}

/**
 * 通过valueType 获取对应展示的图标
 * @param valueType
 */
const valueTypeObj = {
  TEXT: 'icon-string',
  NUMBER: 'icon-num',
  DATE: 'icon-riqi'
};
export function getIconByType(valueType) {
  return valueTypeObj[valueType] || valueTypeObj['TEXT'];
}

/**
 * 通过key 查询维度和度量，返回valueType，再根据valueType找到对应的图标
 * @param key
 * @returns
 */
export function getIconByKey(key) {
  const { dimensions, measures } = dataSetStore.dataSetData;
  let valueType = '',
    fieldType: FieldsType = 'DIMENSION';
  const loop = (data: any[]) => {
    if (!data) return;
    for (let index = 0; index < data.length; index++) {
      if (data[index].name === key) {
        valueType = data[index].valueType;
        break;
      }
      if (data[index].items && data[index].items.length) {
        loop(data[index].items);
      }
    }
  };
  loop(dimensions);
  // 如果没有返回valueType 代码没有找到，继续循环modules
  if (!valueType) {
    loop(measures);
    fieldType = 'MEASURE';
  }
  return { icon: getIconByType(valueType), fieldType };
}

/**
 * 获取字段属于维度还是度量
 * @param dimensions 维度数据
 * @param measures 度量数据
 * @param name 比较的字段名
 * @returns 返回查询到的字段信息以及所属类型
 */
export const getFieldType = (dimensions, measures, name) => {
  // let allFields = [...dimensions, ...measures];
  let allFields = [];
  const _recursionHelperFn = data => {
    if (Array.isArray(data) && data.length) {
      data.forEach(item => {
        allFields.push(item);
        if (Array.isArray(item.derivedFields) && item.derivedFields.length) {
          _recursionHelperFn(item.derivedFields);
        }
      });
    }
  };
  let field: any = {};
  let fieldType: FieldsType = 'DIMENSION';
  const loop = (data: any[]) => {
    for (let index = 0; index < data.length; index++) {
      if (data[index].customName === name) {
        field = data[index];
        break;
      }
      if (data[index].items && data[index].items.length) {
        loop(data[index].items);
      }
    }
  };
  _recursionHelperFn([...dimensions, ...measures]);
  loop(allFields);
  /* if (!field.name) {
    loop(measures);
    fieldType = 'MEASURE';
  } */
  fieldType = field.attribute.type;

  return { field, fieldType };
};

// 将数组转换为json对象
export function array2Json(arr: any[], tag: string) {
  let obj: any = {};
  let arrCopy = JSON.parse(JSON.stringify(arr));
  arrCopy.forEach((item: any) => {
    obj[item[tag]] = item;
  });

  return obj;
}

export const toDouble = number => {
  return number < 10 ? '0' + number : '' + number;
};

export const capitalize = str => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
};
