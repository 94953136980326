import React, { FC } from 'react';
// import PreviewBoard from '@/pages/dataBoard/previewBoard';
import DashboardViewer from '@/pages/dashboard/workplace/preview';
import RPViewer from '@/pages/excel-report/viewer';
import ScreenViewer from '@/pages/Screen/Workspace/core/Preview';

interface IProps {
  resourceType: string;
  resourceId: string;
  token: string;
}
const LinkView: FC<IProps> = ({ resourceType, resourceId, token }) => {
  return (
    <div className="link-view">
      {/* <PreviewBoard boardId={resourceId} type="link" token={token} /> */}
      {resourceType === 'DATA_BOARD_RESOURCE' && <DashboardViewer boardId={resourceId} type="link" token={token} />}
      {resourceType === 'DATA_REPORT_RESOURCE' && <RPViewer resourceId={resourceId} type="link" token={token} />}
      {resourceType === 'DATA_SCREEN_RESOURCE' && <ScreenViewer screenId={resourceId} type="link" token={token} />}
    </div>
  );
};

export default LinkView;
