import React, { useState } from 'react';
import { Tabs } from 'antd';
import TabDataset from './tab-dataset';
import TabAssembly from './tab-assembly';

const { TabPane } = Tabs;

function FilterAside() {
  const [activeKey, setActiveKey] = useState('dataset');

  return (
    <div className="filter-aside">
      <Tabs
        defaultActiveKey={activeKey}
        onChange={activeKey => setActiveKey(activeKey)}
        animated={false}
        tabBarGutter={0}
        className="filter-dialog-tabs"
      >
        <TabPane tab="按表选择" key="dataset">
          <TabDataset />
        </TabPane>
        <TabPane tab="按组件选择" key="assembly">
          <TabAssembly />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default FilterAside;
