import { formatEchartsValue } from '@/utils/utils';

export const BASE_MIX = {
  title: {
    text: '',
    textStyle: {
      fontWeight: 'normal'
    }
  },
  grid: {
    top: 52,
    right: 18,
    bottom: 18,
    left: 18,
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    confine: true,
    formatter: params => {
      const { name } = params[0];
      const str = `${name}<br/>
        ${params
          .map(({ seriesName, marker, data: { displayValue } }, index) => {
            return `${marker} ${seriesName}<span style="float: right; margin-left: 32px; font-weight: bold;">${displayValue}</span>${
              index < params.length - 1 ? '<br/>' : ''
            }`;
          })
          .join('')}
      `;
      return str;
    }
  },
  legend: {
    show: true,
    type: 'scroll',
    itemWidth: 12,
    itemHeight: 12,
    icon: 'rect',
    data: []
  },
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: value => formatEchartsValue(value)
      }
    },
    {
      type: 'value',
      axisLabel: {
        formatter: value => formatEchartsValue(value)
      }
    }
  ],
  series: []
};
