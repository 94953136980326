/**
 * 生成过滤组件
 * @returns
 */
export const genFilterGroup = () => {
  return [
    {
      label: '时间过滤组件',
      value: 'time',
      items: [
        { label: 'ibyear.label', value: 'timeYear', icon: 'filter-time-year', serviceName: 'timeYearWidget' },
        { label: 'ibyearmonth.label', value: 'timeMonth', icon: 'filter-time-month', serviceName: 'timeMonthWidget' },
        { label: 'ibdate.label', value: 'timeDate', icon: 'filter-time-date', serviceName: 'timeDateWidget' },
        {
          label: 'ibdaterange.label',
          value: 'timeDateRange',
          icon: 'filter-time-date-range',
          serviceName: 'timeDateRangeWidget'
        }
      ]
    },
    {
      label: '文本过滤组件',
      value: 'text',
      items: [
        {
          label: 'ibTextSelect.label',
          value: 'textSelect',
          icon: 'filter-text-select',
          serviceName: 'textSelectWidget'
        },
        {
          label: 'ibTextGridSelect.label',
          value: 'textSelectGrid',
          icon: 'filter-text-select',
          serviceName: 'textSelectGridWidget'
        },
        { label: 'ibInputSearch.label', value: 'textInput', icon: 'filter-text-input', serviceName: 'textInputWidget' },
        {
          label: 'ibSelectTree.label',
          value: 'textSelectTree',
          icon: 'filter-text-select-tree',
          serviceName: 'textSelectTreeWidget'
        }
      ]
    },
    {
      label: '数字过滤组件',
      value: 'number',
      items: [
        {
          label: 'ibNumberSelect.label',
          value: 'numberSelect',
          icon: 'filter-text-select',
          serviceName: 'numberSelectWidget'
        },
        {
          label: 'ibNumberGridSelect.label',
          value: 'numberSelectGrid',
          icon: 'filter-text-select',
          serviceName: 'numberSelectGridWidget'
        },
        {
          label: 'ibNumberRange.label',
          value: 'numberRange',
          icon: 'filter-number-range',
          serviceName: 'numberRangeWidget'
        }
      ]
    },
    {
      label: '按钮',
      value: 'button',
      items: [
        {
          label: 'ibSearchButton.label',
          value: 'buttonSure',
          icon: 'filter-button-sure',
          serviceName: 'buttonSureWidget'
        },
        {
          label: 'ibResetButton.label',
          value: 'buttonReset',
          icon: 'filter-button-reset',
          serviceName: 'buttonResetWidget'
        }
      ]
    }
  ];
};

/**
 * 数据转换: 拍平派生字段
 * @param dataSetFields
 */
export const convertDataSetFields = dataSetFields => {
  return dataSetFields.reduce((acc, cur) => {
    acc = [...acc, cur];
    if (Array.isArray(cur.derivedFields) && cur.derivedFields.length) {
      acc = [...acc, ...cur.derivedFields];
    }
    return acc;
  }, []);
};
