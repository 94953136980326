export const tooltipFormatterFn = params => {
  const { name } = params[0];
  const paramsSize = params.length;
  let str = `${name}<br/>
    ${params
      .map(({ seriesName, data: { displayValue }, marker }, index) => {
        return `${marker} ${seriesName}: ${displayValue}${index < paramsSize - 1 ? '<br/>' : ''}`;
      })
      .join('')}
  `;
  return str;
};
