import React, { useState, useEffect, useMemo, useRef, forwardRef, useImperativeHandle, CSSProperties } from 'react';
import BTween from 'b-tween';
import classNames from 'classnames';
import _ from 'lodash';
import { isNumber } from '@/utils/is';
import './index.less';

interface IProps {
  // 数值
  value?: string | number;
  // 数字动态变大
  countUp?: boolean;
  /* 动态变大的过渡时间(ms)
  @defaultValue 2000 */
  countDuration?: number;
  /* 从什么数字开始变大
  @defaultValue 0 */
  countFrom?: number;
  // 数字精度
  precision?: number;
  // 前缀
  prefix?: string;
  // 后缀
  suffix?: string;
  // 显示千位分隔符
  groupSeparator?: boolean;
  // 组件样式
  compStyle?: {
    [key: string]: CSSProperties | any;
  };
}

type DigitalFlopperHandle = {
  countUp: () => void;
};
const defaultProps: IProps = {
  countFrom: 0,
  countDuration: 2000
};
const DigitalFlopper = (props: IProps, ref) => {
  const { groupSeparator, precision, prefix, suffix, compStyle } = props;
  const wrapperRef = useRef<HTMLDivElement>();
  const tween = useRef<BTween>();
  const [value, setValue] = useState<string | number>('value' in props ? props.value : undefined);
  const [style, setStyle] = useState<{ [key: string]: CSSProperties | any }>({
    theme: 'shadow',
    themeColor: '#2f54c9',
    addon: {
      fontSize: 14,
      color: 'rgba(255,255,255,0.65)'
    },
    prefix: {
      marginRight: 4
    },
    suffix: {
      marginLeft: 4
    },
    value: {
      interval: 20,
      fontSize: 26,
      color: 'rgba(255,255,255,1)',
      fontFamily: 'monospace',
      fontWeight: 'normal'
    }
  });

  const countUp = (from = props.countFrom || defaultProps.countFrom, to = props.value) => {
    const { countDuration } = props;
    if (from !== to) {
      tween.current = new BTween({
        from: {
          value: from
        },
        to: {
          value: to
        },
        duration: countDuration,
        easing: 'quartOut',
        onUpdate: keys => {
          setValue(keys.value.toFixed(precision));
        },
        onFinish: () => {
          setValue(to);
        }
      });
      tween.current.start();
    }
  };

  useEffect(() => {
    if (props.countUp) {
      if (tween.current) {
        tween.current.stop();
      }
      if (value !== props.value) {
        countUp(Number(value), props.value);
      } else {
        countUp();
      }
    } else {
      setValue(props.value);
    }

    return () => {
      tween.current && tween.current.stop();
      tween.current = null;
    };
  }, [props.value]);

  useEffect(() => {
    if (compStyle) {
      initStyle();
    }
  }, [compStyle]);

  useImperativeHandle<any, DigitalFlopperHandle>(ref, () => ({
    countUp
  }));

  const initStyle = () => {
    let newStyle = _.cloneDeep(style);
    newStyle.theme = compStyle.theme;
    newStyle.themeColor = compStyle.themeColor;
    newStyle.addon.fontSize = compStyle.addon.fontSize;
    newStyle.addon.color = compStyle.addon.color;
    newStyle.prefix.marginRight = compStyle.prefix.marginRight;
    newStyle.suffix.marginLeft = compStyle.suffix.marginLeft;
    newStyle.value.interval = compStyle.value.interval;
    newStyle.value.fontSize = compStyle.value.fontSize;
    newStyle.value.color = compStyle.value.color;
    newStyle.value.fontFamily = compStyle.value.fontFamily;
    newStyle.value.fontWeight = compStyle.value.fontWeight;
    setStyle({
      ...style,
      ...newStyle
    });
  };

  const { int, decimal } = useMemo(() => {
    let _value = value;
    if (isNumber(precision) && precision >= 0) {
      _value = Number(value).toFixed(precision);
    }
    let int = String(_value).split('.')[0];
    const decimal = String(_value).split('.')[1];
    if (groupSeparator && isNumber(Number(value))) {
      int = Number(int).toLocaleString('en-US');
    }
    return {
      int,
      decimal
    };
  }, [groupSeparator, precision, value]);

  const valueFormatted = (_, formattedValue) => formattedValue;

  const getComputedStyleByTheme = () => {
    if (!wrapperRef.current) return;
    const { height } = wrapperRef.current.getBoundingClientRect();
    switch (style.theme) {
      case 'pure':
        return {
          backgroundColor: style.themeColor
        };
      case 'shadow':
        return {
          backgroundColor: style.themeColor,
          border: `2px solid ${style.themeColor}`,
          boxShadow: `inset 0px -${height - 4}px ${(height - 4) / 2}px rgba(0,0,0,0.5)`
        };
      case 'border':
        return {
          border: `2px solid ${style.themeColor}`
        };
      case 'none':
        return {};
    }
  };
  return (
    <div className="digital-flopper-wrapper" ref={wrapperRef}>
      <div className="df-content">
        <div className="df-value">
          {!isNumber(Number(value)) ? (
            valueFormatted(value, value)
          ) : (
            <>
              {prefix && (
                <span className="df-value-prefix" style={style.addon}>
                  {prefix}
                </span>
              )}
              <ul className={style.theme}>
                {valueFormatted(value, int)
                  .split('')
                  .map((item, index) => (
                    <li
                      className={classNames({
                        'style-number': isNumber(Number(item)),
                        'style-separator': !isNumber(Number(item))
                      })}
                      key={`${item}-${index}`}
                      style={{
                        ...getComputedStyleByTheme(),
                        marginLeft: `${index > 0 ? style.value.interval : 0}px`,
                        fontSize: `${style.value.fontSize}px`,
                        color: style.value.color,
                        fontFamily: style.value.fontFamily,
                        fontWeight: style.value.fontWeight
                      }}
                    >
                      {item}
                    </li>
                  ))}
                {isNumber(Number(value)) &&
                  decimal !== undefined &&
                  `.${decimal}`.split('').map((item, index) => (
                    <li
                      className={classNames({
                        'style-number': isNumber(Number(item)),
                        'style-separator': !isNumber(Number(item))
                      })}
                      key={`${item}-${index}`}
                      style={{
                        ...getComputedStyleByTheme(),
                        marginLeft: `${style.value.interval}px`,
                        fontSize: `${style.value.fontSize}px`,
                        color: style.value.color,
                        fontFamily: style.value.fontFamily,
                        fontWeight: style.value.fontWeight
                      }}
                    >
                      {item}
                    </li>
                  ))}
              </ul>
            </>
          )}

          {suffix ? (
            <span className="df-value-decimal">
              {suffix && (
                <span className="df-value-suffix" style={style.addon}>
                  {suffix}
                </span>
              )}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(DigitalFlopper);
