import { WidgetService } from '../service/widget-service';
import { Base } from '../data';

const genDrawPanel = () => ({
  type: 'custom',
  label: 'ibSearchButton.label',
  style: {
    showTitle: false,
    mainTitle: '',
    labelPos: 'top',
    fontSize: 14,
    color: '#333',
    bold: false,
    italic: false,
    letterSpacing: 0
  },
  options: {
    attrs: {
      type: 'primary',
      round: false,
      plain: true,
      customRange: false,
      filterIds: [],
      autoTrigger: true
    },
    value: '查询'
  },
  component: 'ib-button',
  miniSizeX: 1,
  miniSizeY: 1
});

class ButtonSureServiceImpl extends WidgetService implements Base {
  public filterDialog: boolean;
  public showSwitch: boolean;
  public buttonDialog: boolean;
  constructor(options = {}) {
    Object.assign(options, { name: 'buttonSureWidget' });
    super(options);
    this.filterDialog = false;
    this.buttonDialog = true;
    this.showSwitch = false;
  }

  initDrawPanel() {
    return genDrawPanel();
  }
}
const buttonSureServiceImpl = new ButtonSureServiceImpl();
export default buttonSureServiceImpl;
