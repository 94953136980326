import React, { FC, useRef } from 'react';
import Item from './Item';
import { IListProps } from './data';

const List: FC<IListProps> = ({ list }) => {
    const monitorNameInputRefs = useRef([]);
    // 渲染列表项
    const renderItem = ({ item, index }) => {
        return <Item {...item} key={item.uuId} index={index} inputRef={el => (monitorNameInputRefs.current[index] = el)} />
    };
    return (
        <ul className="monitor-list">
            {list.map((item, index) => renderItem({item, index}))}
        </ul>
    );
};

export default List;