import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '@/store/useStore';
import qs from 'query-string';
import { Spin } from 'antd';
import { http } from '@/lib';
import APIS from '@/apis/api';
import Header from './header';
import LuckysheetPanel from './luckysheet-panel';
import './style/index.less';

function Viewer(props) {
  const location = useLocation();
  const { udMainStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [tplName, setTplName] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1000
  });
  const [allSheetsOption, setAllSheetsOption] = useState([]);

  useEffect(() => {
    udMainStore.setIsShowFrame(false);
    return () => {
      udMainStore.setIsShowFrame(true);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination.current]);

  const fetchData = () => {
    const isTypeLink = props.type && props.type === 'link';
    const { tplId } = qs.parse(location.search);
    setLoading(true);
    http
      .post(
        isTypeLink ? APIS.LinkPreviewReport : APIS.PreviewReportTpl,
        {
          tplId: tplId || props.resourceId,
          page: pagination.current,
          size: pagination.pageSize
        },
        Object.assign(
          {},
          isTypeLink
            ? {
                headers: {
                  Authorization: props.token
                }
              }
            : {}
        )
      )
      .then(res => {
        const { previews, tplName } = res.data;
        setTplName(tplName);
        renderExcel(previews);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderExcel = sheets => {
    const luckysheet = window.luckysheet;
    let allSheets = luckysheet.getAllSheets();
    const activeSheet = allSheets[0];
    const {
      sheetName,
      sheetIndex,
      result: { cellData, config, luckysheet_conditionformat_save }
    } = sheets[0];
    const sheetOption = {
      ...activeSheet,
      name: sheetName,
      index: sheetIndex,
      data: luckysheet.transToData(cellData),
      config,
      luckysheet_conditionformat_save
    };
    setAllSheetsOption([sheetOption]);
  };
  const isAllSheetsOptionEmpty = allSheetsOption.length === 0;
  return (
    <Spin spinning={loading}>
      <div className="rp-viewer-wrapper">
        <Header editMode="viewer" tplName={tplName} />
        <div className="rp-content-wrapper">
          {!isAllSheetsOptionEmpty && (
            <LuckysheetPanel
              type={props.type}
              token={props.token}
              resourceId={props.resourceId}
              allSheetsOption={allSheetsOption}
              editMode="viewer"
            />
          )}
        </div>
      </div>
    </Spin>
  );
}

export default Viewer;
