import React, { useRef } from 'react';
import { message, Modal } from 'antd';
import AuxiliaryLineContent from './auxiliary-line-content';

function ModalAuxiliaryLine({ visible, onCancel, onOk }) {
  const auxiliaryLineConRef = useRef(null);

  const handleOk = () => {
    // validate field is empty
    let isValid = true;
    const { auxiliaryLines } = auxiliaryLineConRef.current;
    for (let line of auxiliaryLines) {
      const { type, name, value } = line;
      if (!name) {
        message.error('辅助线名称必填');
        isValid = false;
        break;
      }
      if (type === 'fixed' && !value) {
        message.error('辅助线(固定值)必填');
        isValid = false;
        break;
      }
    }
    if (isValid) {
      onOk(auxiliaryLines);
    }
  };

  return (
    <Modal visible={visible} title="辅助线" width={800} maskClosable={false} onCancel={onCancel} onOk={handleOk}>
      <AuxiliaryLineContent ref={auxiliaryLineConRef} />
    </Modal>
  );
}

export default ModalAuxiliaryLine;
