import React, { FC, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import { ScreenWorkplaceCtx } from '@/pages/Screen/Workspace/context';
import _ from 'lodash';
import { InputNumber, Button, message, Radio, Checkbox, Select } from 'antd';
import { http } from '@/lib';
import APIS from '@/apis/api';
import { FIELD_AREAS, API_CHART_TYPE } from '@/utils/enum';
import FieldArea, { TypeArea } from './FieldArea';
import { formatCompareFieldDate, replaceFieldUniqueId } from '@/pages/dashboard/workplace/chart-type';
import { checkDuplicate } from '@/pages/dashboard/workplace/util';

type PanelTargetHandle = {
  handleUpdate: () => void;
};
const { Option } = Select;
const PanelTarget: FC<any> = inject('screenStore')(
  observer(
    forwardRef(({ screenStore, onFinish }, ref) => {
      // console.log('PanelTarget render');
      useImperativeHandle<any, PanelTargetHandle>(ref, () => ({
        handleUpdate
      }));
      const { setTriggerUpdate } = useContext(ScreenWorkplaceCtx);
      const [dataMaxLengthLimit, setDataMaxLengthLimit] = useState(1000);

      const handleFieldOper = newItem => {
        screenStore.modComp({ compId: screenStore.activeComp.compId, item: newItem });
      };

      const handleFieldExtraConfigChange = params => {
        screenStore.modExtraConfig(params);
      };

      const handleUpdate = async () => {
        // required validate
        const {
          activeComp,
          activeComp: {
            dataConfig: {
              dimensionFields,
              measureFields,
              filterFields,
              filters,
              compareField,
              branchTag,
              dataSetId,
              xaxis
            },
            styleConfig,
            type
          }
        } = screenStore;
        if (Array.isArray(dimensionFields) && !dimensionFields.length) {
          message.error(`${FIELD_AREAS['dimensionFields']}缺少必填字段`);
          return;
        }
        if (Array.isArray(measureFields) && !measureFields.length) {
          message.error(`${FIELD_AREAS['measureFields']}缺少必填字段`);
          return;
        }
        if (type === 'combination' && Array.isArray(xaxis) && !xaxis.length) {
          message.error(`${FIELD_AREAS['xaxis']}缺少必填字段`);
          return;
        }
        if (Array.isArray(filterFields) && filterFields.length && !filters.length) {
          message.error('请设置筛选字段过滤条件');
          return;
        }
        if (type === 'tableMultidimensional' && !compareField) {
          message.error('请设置对比字段条件');
          return;
        }
        // 度量字段唯一性校验
        if (measureFields && checkDuplicate(measureFields)) {
          message.error('度量存在重复项');
          return;
        }
        setTriggerUpdate(true);
      };
      const handleDataMaxLenLimitValChange = val => {
        setDataMaxLengthLimit(val);
        handleFieldOper({
          ...screenStore.activeComp,
          dataConfig: {
            ...screenStore.activeComp.dataConfig,
            dataMaxLengthLimit: val
          }
        });
      };

      const handleChartTypeValueChange = (fieldArea, index, chartType) => {
        const newItem = _.cloneDeep(screenStore.activeComp);
        newItem.dataConfig[fieldArea] = newItem.dataConfig[fieldArea].map((field, fieldIdx) => {
          if (fieldIdx === index) {
            return {
              ...field,
              chartType
            };
          }
          return field;
        });
        handleFieldOper(newItem);
      };

      const radioStyle = {
        display: 'block',
        height: '24px',
        lineHeight: '24px'
      };

      const handleBranchTagChange = ev => {
        const branchTag = ev.target.value;
        handleFieldOper({
          ...screenStore.activeComp,
          dataConfig: {
            ...screenStore.activeComp.dataConfig,
            branchTag
          }
        });
      };

      const handleRefreshTimeValChange = (key, val) => {
        const newItem = _.cloneDeep(screenStore.activeComp);
        _.set(newItem.dataConfig, key, val);
        handleFieldOper(newItem);
      };

      const dataConfigPropsInView = [
        'dimensionFields',
        'measureFields',
        'xaxis',
        'yaxis',
        'yaxisExt',
        'legend',
        'filterFields',
        'compareFields'
      ];
      const sortedDataConfigPropsInView = {};
      dataConfigPropsInView.forEach(itemProp => {
        Object.keys(screenStore.activeComp.dataConfig)
          .filter(item => dataConfigPropsInView.includes(item))
          .forEach(key => {
            if (itemProp === key) {
              sortedDataConfigPropsInView[key] = screenStore.activeComp.dataConfig[key];
            }
          });
      });
      return (
        <div className="panel-target-wrapper dark">
          <div className="areas-wrapper">
            {Object.keys(sortedDataConfigPropsInView).map((item: TypeArea) => (
              <FieldArea
                key={item}
                type={item}
                activeComp={screenStore.activeComp}
                onFieldOperate={handleFieldOper}
                onChartTypeValueChange={handleChartTypeValueChange}
                onFieldExtraConfigChange={handleFieldExtraConfigChange}
              />
            ))}
            {screenStore.activeComp.type === 'radar' && (
              <div className="radar-branch-wrapper">
                <Radio.Group
                  className="dark-theme"
                  value={screenStore.activeComp.dataConfig.branchTag}
                  onChange={handleBranchTagChange}
                >
                  <Radio value="dimension" style={radioStyle}>
                    维度作为分支标签
                  </Radio>
                  <Radio value="measure" style={radioStyle}>
                    度量作为分支标签
                  </Radio>
                </Radio.Group>
              </div>
            )}
          </div>
          <div className="auto-refresh-wrapper">
            <div className="auto-refresh-time-enable">
              <Checkbox
                className="checkbox-small"
                checked={
                  screenStore.activeComp.dataConfig.freshTime &&
                  screenStore.activeComp.dataConfig.freshTime.isFreshEnable
                }
                onChange={ev => handleRefreshTimeValChange('freshTime.isFreshEnable', ev.target.checked)}
              >
                自动刷新
              </Checkbox>
            </div>
            {screenStore.activeComp.dataConfig.freshTime && screenStore.activeComp.dataConfig.freshTime.isFreshEnable && (
              <>
                <div className="auto-refresh-time-number">
                  <InputNumber
                    defaultValue={5}
                    min={screenStore.activeComp.dataConfig.freshTime.freshTimeUnit === 'min' ? 1 : 5}
                    value={screenStore.activeComp.dataConfig.freshTime.freshTimeNumber}
                    onChange={val => handleRefreshTimeValChange('freshTime.freshTimeNumber', val)}
                    parser={val => {
                      const parsedValue = parseInt(val, 10);
                      if (isNaN(parsedValue) || parsedValue <= 0) {
                        return screenStore.activeComp.dataConfig.freshTime.freshTimeUnit === 'min' ? 1 : 5;
                      }
                      return parsedValue;
                    }}
                  />
                </div>
                <div className="auto-refresh-time-unit">
                  <Select
                    size="small"
                    dropdownClassName="dark-theme"
                    value={screenStore.activeComp.dataConfig.freshTime.freshTimeUnit}
                    onChange={val => handleRefreshTimeValChange('freshTime.freshTimeUnit', val)}
                  >
                    <Option key="min" value="min">
                      分
                    </Option>
                    <Option key="sec" value="sec">
                      秒
                    </Option>
                  </Select>
                </div>
              </>
            )}
          </div>
          <div className="update-wrapper">
            <div className="result-display">
              <span className="title">结果展示</span>
              <InputNumber
                defaultValue={1000}
                min={1}
                value={dataMaxLengthLimit}
                onChange={handleDataMaxLenLimitValChange}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                type="primary"
                block
                onClick={handleUpdate}
                loading={screenStore.activeComp.dataConfig.isFetchingData}
              >
                更新
              </Button>
            </div>
          </div>
        </div>
      );
    })
  )
);

export const fetchApi = async ({
  type,
  dataSetId,
  dataMaxLengthLimit,
  dimensionFields,
  measureFields,
  filterFields,
  filters,
  compareField,
  xaxis,
  yaxis,
  yaxisExt,
  legend
}) => {
  if (Array.isArray(dimensionFields) && !dimensionFields.length) {
    message.error(`${FIELD_AREAS['dimensionFields']}缺少必填字段`);
    return;
  }
  if (Array.isArray(measureFields) && !measureFields.length) {
    message.error(`${FIELD_AREAS['measureFields']}缺少必填字段`);
    return;
  }
  if (type === 'combination' && Array.isArray(xaxis) && !xaxis.length) {
    message.error(`${FIELD_AREAS['xaxis']}缺少必填字段`);
    return;
  }
  if (Array.isArray(filterFields) && filterFields.length && !filters.length) {
    message.error('请设置筛选字段过滤条件');
    return;
  }
  if (type === 'tableMultidimensional' && !compareField) {
    message.error('请设置对比字段条件');
    return;
  }
  // 度量字段唯一性校验
  if (measureFields && checkDuplicate(measureFields)) {
    message.error('度量存在重复项');
    return;
  }
  // merge dimension
  const dimensionMerge = !['table', 'map', 'bubble-map', 'ranking-list'].includes(type);
  let tempDimFields = null;
  let tempMeaFields = null;
  if (type === 'combination') {
    tempDimFields = [
      ...(xaxis ? xaxis.map(item => ({ ...item, fieldType: 'MAIN_DIMENSION' })) : []),
      ...(legend ? legend.map(item => ({ ...item, fieldType: 'ASSISTANT_DIMENSION' })) : [])
    ].map(field => ({
      ...field,
      uniqueId: replaceFieldUniqueId(field.uniqueId)
    }));
    tempMeaFields = [
      ...(yaxis ? yaxis.map(item => ({ ...item, fieldType: 'MAIN_MEASURE' })) : []),
      ...(yaxisExt ? yaxisExt.map(item => ({ ...item, fieldType: 'ASSISTANT_MEASURE' })) : [])
    ].map(field => ({
      ...field,
      uniqueId: replaceFieldUniqueId(field.uniqueId)
    }));
  } else {
    tempDimFields = !dimensionFields
      ? []
      : dimensionFields.map(itemField => {
          if (itemField.sortType === 'NONE') itemField.sortType = null;
          return {
            ...itemField,
            uniqueId: replaceFieldUniqueId(itemField.uniqueId),
            sort: !!itemField.sortType
          };
        });
    tempMeaFields = measureFields.map(itemField => {
      if (itemField.sortType === 'NONE') itemField.sortType = null;
      return {
        ...itemField,
        uniqueId: replaceFieldUniqueId(itemField.uniqueId),
        calculatorType: itemField.calculatorType || 'SUM',
        sort: !!itemField.sortType
      };
    });
  }
  return await http.post(APIS.DataBoardGetChartsData, {
    chartsType: API_CHART_TYPE[type],
    dataSetId,
    dimensionMerge,
    size: dataMaxLengthLimit,
    dimensionFields: tempDimFields,
    measureFields: tempMeaFields,
    filters: filters.map(itemCon => ({
      ...itemCon,
      fieldUniqueId: replaceFieldUniqueId(itemCon.fieldUniqueId)
    })),
    compareField: compareField
      ? {
          ...formatCompareFieldDate(compareField),
          fieldUniqueId: replaceFieldUniqueId(compareField.fieldUniqueId)
        }
      : null
  });
};

export default PanelTarget;
