import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { WidgetService } from '../service/widget-service';
import { Base } from '../data';

dayjs.extend(quarterOfYear);
const DATE_FORMAT = 'YYYY-MM-DD';
const genDialogPanel = () => {
  return {
    options: {
      attrs: {
        type: 'dateRange',
        rangeSeparator: 'ibdaterange.split.placeholder',
        startPlaceholder: 'ibdaterange.from.placeholder',
        endPlaceholder: 'ibdaterange.to.placeholder',
        enableRange: false,
        chartIds: [],
        dataSetId: '',
        fieldUniqueId: '',
        dragItems: [],
        default: {
          isDynamic: false,
          dkey: 0,
          sDynamicPrefix: 1,
          sDynamicInfill: 'day',
          sDynamicSuffix: 'before',
          eDynamicPrefix: 1,
          eDynamicInfill: 'day',
          eDynamicSuffix: 'after',
          radioOptions: [
            { key: 'fix', value: false, text: 'dynamic.time.fix' },
            { key: 'dynamic', value: true, text: 'dynamic.time.dynamic' }
          ],
          relativeOptions: [
            { key: 'cweek', value: 0, text: 'dynamic.time.cweek' },
            { key: 'cmonth', value: 1, text: 'dynamic.time.cmonth' },
            { key: 'cquarter', value: 2, text: 'dynamic.time.cquarter' },
            { key: 'cyear', value: 3, text: 'dynamic.time.cyear' },
            { key: 'lweek', value: 5, text: 'dynamic.time.lweek' },
            { key: 'lmonth', value: 6, text: 'dynamic.month.last' },
            { key: 'lquarter', value: 7, text: 'dynamic.time.lquarter' },
            { key: 'lyear', value: 8, text: 'dynamic.year.last' },
            { key: 'custom', value: 4, text: 'dynamic.time.custom' }
          ],
          custom: {
            unitsOptions: [
              { value: 'day', text: 'dynamic.time.date' },
              { value: 'week', text: 'dynamic.time.week' },
              { value: 'month', text: 'dynamic.time.month' },
              { value: 'year', text: 'dynamic.time.year' }
            ],
            limits: [1, 12]
          },
          fixedValue: null
        },
        showTime: false,
        accuracy: 'HH:mm',
        parameters: [],
        startParameters: [],
        endParameters: []
      },
      value: '',
      manualModify: false
    },
    label: 'ibdaterange.label',
    defaultClass: 'time-filter',
    component: 'ib-date',
    miniSizeX: 1,
    miniSizeY: 1
  };
};

const genDrawPanel = () => {
  return {
    type: 'custom',
    style: {
      showTitle: false,
      mainTitle: '',
      labelPos: 'top',
      fontSize: 14,
      color: '#333',
      bold: false,
      italic: false,
      letterSpacing: 0
    },
    component: 'ib-date'
  };
};

class TimeDateRangeServiceImpl extends WidgetService implements Base {
  public filterDialog: boolean;
  public showSwitch: boolean;
  constructor(options = {}) {
    Object.assign(options, {
      name: 'timeDateRangeWidget'
    });
    super(options);
    this.filterDialog = true;
    this.showSwitch = false;
  }

  initFilterDialog() {
    return genDialogPanel();
  }

  initDrawPanel() {
    return genDrawPanel();
  }

  filterField(fields) {
    return fields.filter(field => field.attribute.valueType === 'DATE');
  }

  getDefaultSetting() {
    const { options } = genDialogPanel();
    return options.attrs.default;
  }

  dynamicDateFormNow(formData, element) {
    return this.formatDynamicTimes(this.dynamicDateFormNowProxy(formData), element);
  }

  dynamicDateFormNowProxy(formData) {
    if (formData === null || typeof formData === 'undefined' || !formData.isDynamic) return null;

    switch (formData.dkey) {
      case 0:
        return [this.getStartDayOfWeek(), this.getEndDayOfWeek()];
      case 5:
        return [this.getStartDayOfLastWeek(), this.getEndDayOfLastWeek()];
      case 1:
        return [this.getStartDayOfMonth(), this.getEndDayOfMonth()];
      case 6:
        return [this.getStartDayOfLastMonth(), this.getEndDayOfLastMonth()];
      case 2:
        return [this.getStartDayOfQuarter(), this.getEndDayOfQuarter()];
      case 7:
        return [this.getStartDayOfLastQuarter(), this.getEndDayOfLastQuarter()];
      case 3:
        return [this.getStartDayOfYear(), this.getEndDayOfYear()];
      case 8:
        return [this.getStartDayOfLastYear(), this.getEndDayOfLastYear()];
      case 4: {
        const sDynamicPrefix = parseInt(formData.sDynamicPrefix);
        const sDynamicInfill = formData.sDynamicInfill;
        const sDynamicSuffix = formData.sDynamicSuffix;
        const eDynamicPrefix = parseInt(formData.eDynamicPrefix);
        const eDynamicInfill = formData.eDynamicInfill;
        const eDynamicSuffix = formData.eDynamicSuffix;
        const startTime = dayjs() // eslint-disable-next-line no-unexpected-multiline
          [sDynamicSuffix === 'before' ? 'subtract' : 'add'](sDynamicPrefix, sDynamicInfill)
          .format(DATE_FORMAT);
        const endTime = dayjs() // eslint-disable-next-line no-unexpected-multiline
          [eDynamicSuffix === 'before' ? 'subtract' : 'add'](eDynamicPrefix, eDynamicInfill)
          .format(DATE_FORMAT);
        return [startTime, endTime];
      }
    }
  }

  formatDynamicTimes(values, element) {
    if (!Array.isArray(values) || (Array.isArray(values) && !values.length)) {
      return values;
    }
    return this.formatValues(values, element);
  }

  shortcuts() {
    return [
      { text: 'dynamic.time.cweek', value: () => [this.getStartDayOfWeek(), this.getEndDayOfWeek()] },
      { text: 'dynamic.month.current', value: () => [this.getStartDayOfMonth(), this.getEndDayOfMonth()] },
      { text: 'dynamic.time.cquarter', value: () => [this.getStartDayOfQuarter(), this.getEndDayOfQuarter()] },
      { text: 'dynamic.year.current', value: () => [this.getStartDayOfYear(), this.getEndDayOfYear()] },
      { text: 'dynamic.time.lweek', value: () => [this.getStartDayOfLastWeek(), this.getEndDayOfLastWeek()] },
      { text: 'dynamic.month.last', value: () => [this.getStartDayOfLastMonth(), this.getEndDayOfLastMonth()] },
      {
        text: 'dynamic.time.lquarter',
        value: () => [this.getStartDayOfLastQuarter(), this.getEndDayOfLastQuarter()]
      },
      { text: 'dynamic.year.last', value: () => [this.getStartDayOfLastYear(), this.getEndDayOfLastYear()] }
    ];
  }

  formatShortValues(values, element) {
    if (!values || values.length === 0) return [];
    return this.formatValues(values, element);
  }

  formatValues(values, element) {
    const defaultRangeTime = ['00:00:00', '23:59:59'];
    return values.map((val, idx) => {
      if (element.options.attrs.showTime) {
        const accuracy = element.options.attrs.accuracy;
        const accuracyLen = accuracy.split(':').length;
        const time = defaultRangeTime[idx].split(':').slice(0, accuracyLen).join(':');
        return [val.indexOf(':') > -1 ? val.split(' ')[0] : val, time].join(' ');
      }
      return val.split(' ')[0];
    });
  }

  setDefaultValue(element) {
    if (element.options.attrs.default && element.options.attrs.default.isDynamic) {
      return this.dynamicDateFormNow(element.options.attrs.default, element);
    }
    return element.options.attrs.default.fixedValue;
  }

  getStartDayOfWeek() {
    return dayjs().startOf('week').format(DATE_FORMAT);
  }

  getEndDayOfWeek() {
    return dayjs().endOf('week').format(DATE_FORMAT);
  }

  getStartDayOfMonth() {
    return dayjs().startOf('month').format(DATE_FORMAT);
  }

  getEndDayOfMonth() {
    return dayjs().endOf('month').format(DATE_FORMAT);
  }

  getStartDayOfQuarter() {
    return dayjs().startOf('quarter').format(DATE_FORMAT);
  }

  getEndDayOfQuarter() {
    return dayjs().endOf('quarter').format(DATE_FORMAT);
  }

  getStartDayOfYear() {
    return dayjs().startOf('year').format(DATE_FORMAT);
  }

  getEndDayOfYear() {
    return dayjs().endOf('year').format(DATE_FORMAT);
  }

  getStartDayOfLastWeek() {
    return dayjs().subtract(1, 'week').startOf('week').format(DATE_FORMAT);
  }

  getEndDayOfLastWeek() {
    return dayjs().subtract(1, 'week').endOf('week').format(DATE_FORMAT);
  }

  getStartDayOfLastMonth() {
    return dayjs().subtract(1, 'month').startOf('month').format(DATE_FORMAT);
  }

  getEndDayOfLastMonth() {
    return dayjs().subtract(1, 'month').endOf('month').format(DATE_FORMAT);
  }

  getStartDayOfLastQuarter() {
    return dayjs().subtract(1, 'quarter').startOf('quarter').format(DATE_FORMAT);
  }

  getEndDayOfLastQuarter() {
    return dayjs().subtract(1, 'quarter').endOf('quarter').format(DATE_FORMAT);
  }

  getStartDayOfLastYear() {
    return dayjs().subtract(1, 'year').startOf('year').format(DATE_FORMAT);
  }

  getEndDayOfLastYear() {
    return dayjs().subtract(1, 'year').endOf('year').format(DATE_FORMAT);
  }

  isTimeWidget() {
    return true;
  }

  isParamWidget() {
    return true;
  }

  isRangeParamWidget() {
    return true;
  }
}

const timeDateRangeServiceImpl = new TimeDateRangeServiceImpl();
export default timeDateRangeServiceImpl;
