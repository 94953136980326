import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import { Button, Input, Select, InputNumber, message } from 'antd';
import _ from 'lodash';
import SvgIcon from '@/components/SvgIcon';
import ColorPicker from '@/components/FormComponents/Color';
import { AuxiliaryLineContext } from './ctx-auxiliary-line';
import { genAuxiliaryLine } from './util';
import { LINE_CALCULATED_TYPES } from '@/utils/enum';
import './style/auxiliary-line-content.less';

const { Option } = Select;
function AuxiliaryLineContent(props, ref) {
  useImperativeHandle(ref, () => ({
    auxiliaryLines
  }));
  const { activeChart } = useContext(AuxiliaryLineContext);
  const [auxiliaryLines, setAuxiliaryLines] = useState([]);

  useEffect(() => {
    initState();
  }, []);

  const initState = () => {
    setAuxiliaryLines(_.cloneDeep(activeChart.advancedConfig.auxiliaryLines));
  };

  const addItemLine = () => {
    const size = auxiliaryLines.length;
    const newItem = genAuxiliaryLine(size + 1);

    if (size >= 5) {
      message.error('辅助线最多添加 5 项');
      return;
    }
    setAuxiliaryLines([...auxiliaryLines, newItem]);
  };

  const removeItemLine = itemLine => {
    setAuxiliaryLines(auxiliaryLines.filter(line => line.id !== itemLine.id));
  };

  const handlePropChange = (targetIdx, key, val) => {
    setAuxiliaryLines(
      auxiliaryLines.map((line, lineIdx) => {
        if (targetIdx === lineIdx) {
          const ret = {
            ...line,
            [key]: val
          };
          if (key === 'type' && val === 'calculated') {
            ret.fieldId = activeChart.dataConfig.measureFields[0].uniqueId;
          }
          return ret;
        }
        return line;
      })
    );
  };

  const isAreaMeaFieldEmpty =
    !activeChart.dataConfig.measureFields ||
    (Array.isArray(activeChart.dataConfig.measureFields) && !activeChart.dataConfig.measureFields.length);
  // console.log('render AuxiliaryLineContent: ', activeChart);
  return (
    <div className="auxiliary-line-content">
      <Button block icon="plus" type="primary" ghost onClick={addItemLine} disabled={isAreaMeaFieldEmpty}>
        添加辅助线
      </Button>
      <div className="advanced-line-container">
        {auxiliaryLines.map((itemLine, lineIdx) => (
          <div className="advanced-line-group" key={itemLine.id}>
            <div className="advanced-line-item">
              <Input
                placeholder="请填写辅助线名称"
                value={itemLine.name}
                onChange={ev => handlePropChange(lineIdx, 'name', ev.target.value)}
                style={{ width: '160px' }}
              />
            </div>
            <div className="advanced-line-item">
              <Select
                value={itemLine.type}
                onChange={value => handlePropChange(lineIdx, 'type', value)}
                style={{ width: '100px' }}
              >
                <Option key="fixed" value="fixed">
                  固定值
                </Option>
                <Option key="calculated" value="calculated">
                  计算值
                </Option>
              </Select>
            </div>
            {itemLine.type === 'fixed' && (
              <>
                <div className="advanced-line-item">
                  <Select value={itemLine.location} disabled style={{ width: '160px' }}>
                    <Option key="0" value="0">
                      主轴
                    </Option>
                  </Select>
                </div>
                <div className="advanced-line-item">
                  <InputNumber
                    placeholder="请填入值"
                    value={itemLine.value}
                    onChange={value => handlePropChange(lineIdx, 'value', value)}
                    style={{ width: '100px' }}
                  />
                </div>
              </>
            )}
            {itemLine.type === 'calculated' && (
              <>
                <div className="advanced-line-item">
                  <Select
                    value={itemLine.fieldId}
                    defaultActiveFirstOption
                    onChange={value => handlePropChange(lineIdx, 'fieldId', value)}
                    style={{ width: '160px' }}
                  >
                    {activeChart.dataConfig.measureFields.map(field => (
                      <Option key={field.uniqueId} value={field.uniqueId}>
                        {field.customName}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="advanced-line-item">
                  <Select
                    value={itemLine.calculatedType}
                    onChange={value => handlePropChange(lineIdx, 'calculatedType', value)}
                    style={{ width: '100px' }}
                  >
                    {Object.keys(LINE_CALCULATED_TYPES).map(key => (
                      <Option key={key} value={key}>
                        {LINE_CALCULATED_TYPES[key]}
                      </Option>
                    ))}
                  </Select>
                </div>
              </>
            )}
            <div className="advanced-line-item line-style">
              <Select
                value={itemLine.lineStyle}
                onChange={value => handlePropChange(lineIdx, 'lineStyle', value)}
                style={{ width: '100px' }}
                optionLabelProp="label"
              >
                {['solid', 'dotted', 'dashed'].map(option => (
                  <Option
                    key={option}
                    value={option}
                    label={
                      <div className="line-style-result">
                        <i className="icon-svg-wrapper icon-line-type">
                          <SvgIcon iconClass="line-type" />
                        </i>
                        <div className="line-border" style={{ borderStyle: option }}></div>
                      </div>
                    }
                  >
                    <div style={{ display: 'flex', height: '22px', alignItems: 'center' }}>
                      <div style={{ borderWidth: '1.5px', borderStyle: option, flexGrow: 1 }}></div>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
            <div className="advanced-line-item line-color">
              <ColorPicker value={itemLine.lineColor} onChange={rgba => handlePropChange(lineIdx, 'lineColor', rgba)} />
            </div>
            <div className="advanced-line-item item-delete">
              <i className="svg-icon-wraper icon-delete" onClick={() => removeItemLine(itemLine)}>
                <SvgIcon iconClass="delete-1" />
              </i>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default forwardRef(AuxiliaryLineContent);
