import React, { useContext, useEffect, useMemo } from 'react';
import { ReactSortable } from 'react-sortablejs';
import _ from 'lodash';
import { WidgetFilterContext } from './ctx';
import DragItem from './drag-item';

function FilterHead() {
  const { widget, setWidget } = useContext(WidgetFilterContext);
  const dragItems = useMemo(() => {
    return widget.options.attrs.dragItems;
  }, [JSON.stringify(widget.options.attrs.dragItems)]);

  const handleRemove = uniqueId => {
    const newWidget = _.cloneDeep(widget);
    const obj = {
      'options.attrs.dragItems': widget.options.attrs.dragItems.filter(item => item.uniqueId !== uniqueId),
      'options.attrs.fieldUniqueId': ''
    };
    Object.keys(obj).forEach(key => {
      _.set(newWidget, key, obj[key]);
    });
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  // console.log('render FilterHead: ', widget, dragItems);
  return (
    <div className="filter-head">
      <ReactSortable
        className="filter-field"
        group={{
          name: 'target',
          put: ['source']
        }}
        list={dragItems}
        setList={() => {}}
      >
        {dragItems.map(item => (
          <DragItem key={item.uniqueId} itemField={item} onRemoveItemField={handleRemove} />
        ))}
      </ReactSortable>
      {!dragItems.length && <span className="drag-items-placeholder">请将左侧字段拖至此处</span>}
    </div>
  );
}

export default FilterHead;
