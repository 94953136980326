import React, { useRef } from 'react';
import { toJS } from 'mobx';
import cls from 'classnames';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import GridItem from './grid-item';
import GridCustomItem from './grid-custom-item';

const ResponsiveGridLayout = WidthProvider(Responsive);

function GridLayoutMobile({
  dashboardId,
  dashboardName,
  data,
  layouts,
  activeIndex,
  activeBreakPoint,
  mode,
  isTypeLink,
  boardId,
  token,
  onLayoutChange,
  onResizeStop,
  onGridItemClick,
  onGridCustomItemClick,
  onRemove,
  onCustomEdit,
  onCustomRemove,
  onQuery,
  onReset,
  onWidgetOptionValChange,
  onUpdateChartConditionsMap
}: any) {
  const gridLayoutRef = useRef(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

  // console.log('render GridLayout: ', gridLayoutRef, toJS(data), activeBreakPoint, layouts);
  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={{ [activeBreakPoint]: layouts }}
      breakpoints={{ lg: 1024, md: 996, sm: 768, xs: isMobile ? window.screen.width : 306, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      ref={gridLayoutRef}
      rowHeight={75}
      isDraggable={mode === 'edit'}
      isResizable={mode === 'edit'}
      onLayoutChange={onLayoutChange}
      onResizeStop={onResizeStop}
    >
      {data.map(item => {
        return (
          <div
            key={item.frontChartId || item.widgetId}
            className={cls('component-card-wrapper', { 'card-selected': item.order === activeIndex })}
          >
            <div className="component-card">
              {item.type && item.type === 'custom' ? (
                <GridCustomItem
                  mode={mode}
                  item={item}
                  index={item.order}
                  allComps={data}
                  customComps={data.filter(item => item.type && item.type === 'custom')}
                  onGridItemClick={onGridCustomItemClick}
                  onEdit={onCustomEdit}
                  onRemove={onCustomRemove}
                  onQuery={onQuery}
                  onReset={onReset}
                  onWidgetOptionValChange={onWidgetOptionValChange}
                  onUpdateChartConditionsMap={onUpdateChartConditionsMap}
                />
              ) : (
                <GridItem
                  key={item.frontChartId}
                  activeBreakPoint={activeBreakPoint}
                  dashboardId={dashboardId || boardId}
                  dashboardName={dashboardName}
                  charts={data.filter(item => !(item.type && item.type === 'custom'))}
                  customComps={data.filter(item => item.type && item.type === 'custom')}
                  item={item}
                  index={item.order}
                  mode={mode}
                  isTypeLink={mode === 'preview' && isTypeLink}
                  token={token}
                  onGridItemClick={onGridItemClick}
                  onRemove={onRemove}
                />
              )}
            </div>
          </div>
        );
      })}
    </ResponsiveGridLayout>
  );
}

export default GridLayoutMobile;
