import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useStore } from '@/store/useStore';
import { WorkplaceContext, DeviceType } from './context';
import qs from 'query-string';
import cls from 'classnames';
import { http } from '@/lib';
import APIS from '@/apis/api';
import * as echarts from 'echarts';
import themeLightJson from '@/pages/Screen/Workspace/core/theme/light.json';
import {
  calGridLayoutBreakPoint,
  convertChartsHandler,
  genChartConditionsMap,
  genWidgetConditionMap,
  filterOutWidgetBtn
} from './util';
import Header from './header';
import CanvasPanel from './canvas-panel';
import PicLoadingSpin from '@/assets/spin-loading.gif';
import './style/index.less';

function Preview(props) {
  const { useFor } = qs.parse(window.location.href);
  const { dashboardStore, udMainStore } = useStore();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [widgetConditionMap, setWidgetConditionMap] = useState({});
  const [chartConditionsMap, setChartConditionsMap] = useState({});
  const [activeDevice, setActiveDevice] = useState<DeviceType>(
    ((useFor as string)?.toLowerCase() as DeviceType) || 'pc'
  );
  const [QRCodeConfig, setQRCodeConfig] = useState({
    refresh: false,
    value: ''
  });
  const [dashboardName, setDashboardName] = useState('');

  useEffect(() => {
    init();
    return () => {
      reset();
    };
  }, []);

  const init = () => {
    udMainStore.setIsShowFrame(false);
    dashboardStore.setActiveBreakPoint(calGridLayoutBreakPoint('preview'));
    echarts.registerTheme('shine', themeLightJson);
    fetchData();
    // fetchResourceLink();
  };

  const reset = () => {
    udMainStore.setIsShowFrame(true);
    dashboardStore.setActiveBreakPoint('lg');
  };

  const fetchData = () => {
    const isTypeLink = props.type && props.type === 'link';
    const reqViewURL = isTypeLink ? APIS.LinkBoardDetail : APIS.DataBoardView;

    setLoading(true);
    http
      .post(
        reqViewURL,
        { id: id || props.boardId },
        Object.assign(
          {},
          isTypeLink
            ? {
                headers: {
                  Authorization: props.token
                }
              }
            : {}
        )
      )
      .then(res => {
        const { id: dashboardId, name: dashboardName, charts, customComps } = res.data;
        const newCharts = convertChartsHandler(charts);
        const newWidgetConditionMap = genWidgetConditionMap(customComps.filter(filterOutWidgetBtn));

        setWidgetConditionMap({
          ...widgetConditionMap,
          ...newWidgetConditionMap
        });
        setChartConditionsMap(genChartConditionsMap(customComps.filter(filterOutWidgetBtn), newWidgetConditionMap));
        dashboardStore.init({
          dashboardId,
          dashboardName,
          charts: newCharts,
          customComps,
          activeIndex: -1,
          dataSets: [],
          activeBreakPoint: activeDevice === 'pc' ? 'lg' : 'xs'
        });
        setDashboardName(dashboardName);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const contextValue = {
    triggerUpdate,
    setTriggerUpdate,
    widgetConditionMap,
    setWidgetConditionMap,
    chartConditionsMap,
    setChartConditionsMap,
    activeDevice,
    QRCodeConfig,
    setQRCodeConfig,
    dashboardName
  };
  console.log('render Preview: ', props, activeDevice);
  return (
    <>
      {loading ? (
        <Spin className="dashboard-loading-spin" indicator={<img src={PicLoadingSpin} />} />
      ) : (
        <WorkplaceContext.Provider value={contextValue}>
          <div
            className={cls('dashboard-preview-container theme-light', { 'preview-mobile': activeDevice === 'mobile' })}
          >
            {activeDevice === 'pc' && <Header mode="preview" />}
            <CanvasPanel
              mode="preview"
              isTypeLink={props.type && props.type === 'link'}
              boardId={props.boardId}
              token={props.token}
            />
          </div>
        </WorkplaceContext.Provider>
      )}
    </>
  );
}

export default Preview;
