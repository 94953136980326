import { IRouteItem } from '../lib/types';
import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router';

const routes: IRouteItem[] = [
  /* 用户管理 */
  // 内部员工
  {
    path: '/',
    customId: 'HOME_PAGE',
    component: lazy(() => import('../pages/HomePage')),
  },
  {
    path: '/user/list',
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_USER_LIST',
    component: lazy(() => import('../pages/UserManagement/User')),
  },
  /* 角色权限管理 */
  // 角色权限管理列表
  {
    path: '/user/role/list',
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_ROLE_LIST',
    component: lazy(() => import('../pages/UserManagement/Role')),
  },
  // 岗位列表
  {
    path: '/user/job/list',
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_DEPARTMENT_LIST',
    component: lazy(() => import('../pages/UserManagement/Job')),
  },
  // 组织列表
  {
    path: '/user/organization/list',
    customId: 'M_ACCOUNT_SYSTEM_MANAGE_ORG_LIST',
    component: lazy(() => import('../pages/UserManagement/Organization'))
  },
  /* 接口日志 */
  // 日志列表
  {
    path: '/interfaceLog/list',
    customId: 'interface_log',
    component: lazy(() => import('../pages/InterfaceLog')),
  },
  /* 数据中心 */
  // 数据中心列表
  {
    path: '/dataCenter/list',
    customId: 'M_DATA_MANAGE_DATA_WAREHOUSE_LIST',
    component: lazy(() => import('../pages/DataCenter/List')),
  },
  // 表结构详情
  {
    path: '/dataCenter/tableStructure/:tableName',
    customId: 'R_DATA_MANAGE_DATA_CENTER_TABLE_STRUCTURE',
    component: lazy(() => import('../pages/DataCenter/TableStructure')),
  },
  // 表数据详情
  {
    path: '/dataCenter/tableDetail/:tableName',
    customId: 'R_DATA_MANAGE_DATA_CENTER_TABLE_DATA_VIEW',
    component: lazy(() => import('../pages/DataCenter/TableDetail')),
  },
  /* 数据集 */
  // 加工列表
  {
    path: '/dataProcess/list',
    customId: 'M_DATA_MANAGE_DATA_SET_LIST',
    component: lazy(() => import('../pages/DataSet/List')),
  },
  // 添加加工配置
  {
    path: '/dataProcess/edit/:id?/:isDetail?',
    customId: 'R_DATA_MANAGE_DATA_PROCESSING_SAVE_OR_UPDATE',
    component: lazy(() => import('../pages/DataSet/Edit')),
  },
  // 加工配置详情
  // {
  //   path: '/dataProcess/tableDetail/:id/:isDetail?',
  //   customId: 'data_machining_result',
  //   component: lazy(() => import('../pages/DataProcess/TableDetail')),
  // },

  /* 数据看板 */
  // 列表
  {
    path: '/dataBoard/list',
    customId: 'M_DATA_MANAGE_DATA_BOARD_LIST',
    component: lazy(() => import('../pages/dataBoard/list')),
  },
  // 预览
  {
    path: '/dataBoard/preview/:id',
    customId: 'data_board_preview',
    component: lazy(() => import('../pages/dataBoard/previewBoard')),
  },
  // 看板配置
  {
    path: '/dataBoard/edit/:id',
    customId: 'data_board_edit',
    component: lazy(() => import('../pages/dataBoard/edit')),
  },

  /* 开发配置菜单 */
  {
    path: '/menus',
    customId: 'user_system_menu_manage',
    component: lazy(() => import('../pages/MenusManage/List')),
  },

  // 预警管理
  {
    path: '/warningManage/log/list',
    customId: 'M_DATA_MANAGE_DATA_MONITOR_LOG_LIST',
    component: lazy(() => import('../pages/WarningManagement/Log/List'))
  },
  {
    path: '/warningManage/log/detail/:id',
    component: lazy(() => import('../pages/WarningManagement/Log/Detail'))
  },
  {
    path: '/warningManage/list',
    customId: 'M_DATA_MANAGE_DATA_MONITOR_LIST',
    component: lazy(() => import('../pages/WarningManagement/List'))
  },
  // 指标库
  {
    path: '/dataIndicator/list',
    customId: 'M_DATA_MANAGE_DATA_INDICATOR',
    component: lazy(() => import('../pages/DataIndicator/List'))
  },
  
  /* 经销商业务往来模块路由 END */

  {
    path: '/404',
    component: lazy(() => import('../pages/NotFound')),
  },
  {
    route: <Route key="NotFound" render={() => <Redirect to="/" />} />,
  },
];

export { routes };
