import React, { FC, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import cls from 'classnames';
import './index.less';

const KpiCard: FC<any> = ({ chart, hideTitle, theme, valueColor }) => {
  const [state, setState] = useState({
    height: 'auto',
    splitHeight: '10px',
    dimensionShow: true,
    titleShow: hideTitle ? false : true
  });
  const [style, setStyle] = useState<any>({
    bg: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: '8px',
      overflow: 'hidden',
      background: 'rgba(255, 255, 255, 0)'
    },
    title: {
      margin: '0 0',
      width: '100%',
      textAlign: 'left',
      fontSize: '18px',
      color: '#303133',
      fontStyle: 'normal',
      fontWeight: 'normal'
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'auto'
    },
    label: {
      margin: 0,
      fontSize: 12
    },
    value: {
      fontSize: 24
    },
    labelContent: {
      margin: 0
    },
    labelSpace: {
      marginTop: '10px',
      textAlign: 'center'
    },
    addon: {
      fontSize: '12px'
    }
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    // console.log('KpiCard useEffect: ', chart);
    init();
    // calcHeight();
  }, [JSON.stringify(chart)]);

  const init = () => {
    initStyle();
  };

  const calcHeight = () => {
    if (containerRef.current) {
      const currentHeight = containerRef.current.offsetHeight;
      const contentHeight = currentHeight - titleRef.current.offsetHeight - 16;
      setState({
        ...state,
        height: `${contentHeight}px`
      });
      setStyle({
        ...style,
        content: {
          ...style.content,
          height: `${contentHeight}px`
        }
      });
    }
  };

  const initStyle = () => {
    const newStyle = _.cloneDeep(style);
    if (chart.backgroundColor) {
      newStyle.bg.background = chart.backgroundColor;
    }
    if (chart.customAttr) {
      const { color, size, addon } = chart.customAttr;
      if (color) {
        newStyle.label.color = color.dimensionColor;
        newStyle.labelContent.color = color.quotaColor;
      }
      if (size) {
        newStyle.label.fontSize = size.dimensionFontSize + 'px';
        newStyle.labelContent.fontSize = size.quotaFontSize + 'px';
        newStyle.labelSpace.marginTop = `${size.dimensionShow ? size.spaceSplit : 0}px`;
        newStyle.value.fontSize = size.quotaFontSize + 'px';
      }
      if (addon) {
        newStyle.addon.fontSize = size.quotaFontSize / 2 + 'px';
      }
    }
    if (chart.indicator) {
      newStyle.label.fontSize = chart.indicator.name.fontSize + 'px';
      newStyle.value.fontSize = chart.indicator.value.fontSize + 'px';
      newStyle.addon.fontSize = chart.indicator.value.fontSize / 2 + 'px';
    }
    if (chart.addon) {
      chart.customAttr.addon.prefix = chart.addon.prefix;
      chart.customAttr.addon.suffix = chart.addon.suffix;
    }
    if (chart.title) {
      const { show, hPosition, textStyle } = chart.title;
      newStyle.title.display = show ? 'block' : 'none';
      newStyle.title.textAlign = hPosition;
      newStyle.title.fontSize = textStyle.fontSize + 'px';
      newStyle.title.color = textStyle.color;
      newStyle.title.fontStyle = textStyle.fontStyle;
      newStyle.title.fontWeight = textStyle.fontWeight;
    }
    setStyle({
      ...style,
      ...newStyle
    });
    setState({
      ...state,
      titleShow: chart.title && chart.title.show && state.titleShow,
      dimensionShow: chart.customAttr.size.dimensionShow,
      splitHeight: chart.customAttr.size.spaceSplit + 'px'
    });
  };

  const chartResize = () => {
    calcHeight();
  };
  // console.log('KpiCard render: ', state, style, chart);
  return (
    <div
      className={cls('kpi-card-container', { 'theme-light': theme && theme === 'light' })}
      ref={containerRef}
      style={style.bg}
    >
      {state.titleShow && (
        <p ref={titleRef} style={style.title}>
          {chart.title.text}
        </p>
      )}
      {chart.data && chart.data.series && chart.data.series.length > 0 && (
        <div id="labelContent" style={style.content}>
          <span style={style.label}>
            {chart.data.series[0].data && chart.data.series[0].data.length > 0 && (
              <p style={style.labelContent}>
                {chart.customAttr.addon.prefix && <span style={style.addon}>{chart.customAttr.addon.prefix}</span>}
                <span
                  className="kpi-value"
                  style={Object.assign(
                    { fontSize: style.value.fontSize },
                    valueColor ? { padding: '0 2px', color: valueColor } : { padding: '0 2px' }
                  )}
                >
                  {chart.data.series[0].data[0]}
                </span>
                {chart.customAttr.addon.suffix && <span style={style.addon}>{chart.customAttr.addon.suffix}</span>}
              </p>
            )}
          </span>
          {state.dimensionShow && (
            <span style={style.labelSpace}>
              <p style={style.label}>{chart.data.series[0].name}</p>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default KpiCard;
