import React, { useRef } from 'react';
import { Modal, Tooltip, Icon } from 'antd';
import ContrastSettingForm from './contrast-setting-form';

function ModalContrast({ visible, onOk, onCancel, fieldOpts, fieldsValue, hasDynamicRingRatio }) {
  const contrastSettingFormRef = useRef(null);

  const handleOk = () => {
    if (contrastSettingFormRef.current) {
      onOk({ ...contrastSettingFormRef.current.formData, contrastType: 'CONTRAST_CUSTOM' });
    }
  };
  return (
    <Modal
      visible={visible}
      width={520}
      title={
        <>
          <span>同环比设置</span>
          <Tooltip title="如果维度中没有日期字段时，需要搭配同日期字段同周期的条件使用才会生效哦">
            <Icon type="question-circle-o" style={{ marginLeft: '2px' }} />
          </Tooltip>
        </>
      }
      okText="确认"
      cancelText="取消"
      onOk={handleOk}
      onCancel={onCancel}
    >
      <ContrastSettingForm
        ref={contrastSettingFormRef}
        fieldOpts={fieldOpts}
        fieldsValue={fieldsValue}
        hasDynamicRingRatio={hasDynamicRingRatio}
      />
    </Modal>
  );
}

export default ModalContrast;
