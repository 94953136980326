import { http } from '@/lib';
import APIS from '@/apis/api';

export const fetchOwnerOperates = async (authType, ownerType, item, store) => {
  const { id, createName } = item;
  const res = await http.post(APIS.PermissionOwnerOperates, {
    authorizationType: authType,
    ownerType,
    model: {
      dataSign: id,
      parentDataSign: '',
      creator: createName
    }
  });
  store.setActiveTreeNodeAndOwnerOperates({
    ownerType,
    ownerOperates: !res.data ? [] : [res.data],
    treeNode: {
      ...store.activeTreeNode,
      key: id,
      creator: createName
    },
    visibleModalDataAuth: true
  });
};

export const cancelDataAuthHandler = (store, callback?: () => void) => {
  store.setActiveTreeNodeAndOwnerOperates({
    ownerType: 'USER',
    ownerOperates: store.activeOwnerOperates,
    treeNode: {
      ...store.activeTreeNode,
      key: '',
      dataSource: [],
      creator: ''
    },
    visibleModalDataAuth: false
  });
  if (typeof callback === 'function' && callback) {
    callback();
  }
};

export const confirmDataAuthHandler = async (store, authType, ownerType, setIsSavingFn, callback?: () => void) => {
  const { key, creator, dataSource } = store.activeTreeNode;
  const filteredDataSource = dataSource.filter(item => item.view === 'on' || item.edit === 'on');
  let params = {
    authorizeType: authType,
    ownerType,
    ownerOperates: [
      {
        model: {
          dataSign: key,
          creator
        },
        operates: filteredDataSource.length
          ? filteredDataSource.map(({ key, value, view, edit }) => ({
              permissionOwner: key,
              ownerType,
              operates: [view === 'on' ? 'USE_LOOK' : '', edit === 'on' ? 'EDIT' : ''].filter(item => item)
            }))
          : []
      }
    ]
  };

  try {
    setIsSavingFn(true);
    const res = await http.post(APIS.PermissionAuthorize, params);
    if (typeof callback === 'function' && callback) {
      callback();
    }
  } catch (err) {
    // throw new Error(err);
    console.error('Error: ', err);
  } finally {
    setIsSavingFn(false);
  }
};

export const currentUserName = () => {
  return JSON.parse(sessionStorage.getItem('UaaSysInfo')).profile.name;
};
