import React, { useState, useEffect } from 'react';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';
import cls from 'classnames';
import { hexToRGBA, isHexColor } from '@/utils/utils';
import SvgIcon from '@/components/SvgIcon';
import ColorPicker from '@/components/FormComponents/Color';

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const { Option } = Select;

const genFormData = () => ({
  showTitleAndUnit: true,
  title: undefined,
  unit: undefined,
  titleFontStyle: {
    fontSize: 12,
    fontColor: '#333',
    bold: false,
    italic: false
  }
});

function StyleAxisConfig({ type, styleConfig, onPropChange }) {
  const [formData, setFormData] = useState(genFormData());

  useEffect(() => {
    initState();
  }, [JSON.stringify(styleConfig)]);

  const initState = () => {
    const { showTitleAndUnit, title, unit, titleFontStyle } = styleConfig[type];
    setFormData({
      ...formData,
      showTitleAndUnit,
      title,
      unit,
      titleFontStyle
    });
  };

  const handlePropChange = (key, val) => {
    const newFormData = _.set(formData, key, val);
    setFormData({
      ...formData,
      ...newFormData
    });
    onPropChange(`${type}.${key}`, val);
  };

  return (
    <Form {...formItemLayout} colon={false} labelAlign="left">
      <Form.Item label="显示">
        <Checkbox
          checked={formData.showTitleAndUnit}
          onChange={ev => handlePropChange('showTitleAndUnit', ev.target.checked)}
        />
      </Form.Item>
      <Form.Item label="轴标题">
        <Input
          placeholder="标题名称"
          value={formData.title}
          onChange={ev => handlePropChange('title', ev.target.value)}
        />
      </Form.Item>
      <Form.Item label="单位">
        <Input placeholder="单位" value={formData.unit} onChange={ev => handlePropChange('unit', ev.target.value)} />
      </Form.Item>
      <Form.Item label="文本">
        <Row gutter={12}>
          <Col span={12}>
            <Select
              value={formData.titleFontStyle.fontSize}
              onChange={val => handlePropChange('titleFontStyle.fontSize', val)}
            >
              {new Array(26)
                .fill(0)
                .map((item, index) => 10 + index * 2)
                .map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={4}>
            <i
              className={cls('svg-icon-wrapper icon-font-style icon-font-weight', {
                checked: formData.titleFontStyle.bold
              })}
              onClick={() => handlePropChange('titleFontStyle.bold', !formData.titleFontStyle.bold)}
            >
              <SvgIcon iconClass="bold" />
            </i>
          </Col>
          <Col span={4}>
            <i
              className={cls('svg-icon-wrapper icon-font-style icon-font-style', {
                checked: formData.titleFontStyle.italic
              })}
              onClick={() => handlePropChange('titleFontStyle.italic', !formData.titleFontStyle.italic)}
            >
              <SvgIcon iconClass="italic" />
            </i>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label="颜色">
        <ColorPicker
          value={
            isHexColor(formData.titleFontStyle.fontColor)
              ? hexToRGBA(formData.titleFontStyle.fontColor)
              : formData.titleFontStyle.fontColor
          }
          verticalAlign="middle"
          onChange={rgba => handlePropChange('titleFontStyle.fontColor', rgba)}
        />
      </Form.Item>
    </Form>
  );
}

export default StyleAxisConfig;
