import React, { useState, useEffect, useContext } from 'react';
import { Card } from 'antd';
import _ from 'lodash';
import { WidgetFilterContext } from './ctx';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import IbDateDefault from './default-value/ib-date-default';
import IbDateRangeDefault from './default-value/ib-date-range-default';
import IbSelect from '@/components/widget/ib-widget/ib-select';
import IbSelectGrid from '@/components/widget/ib-widget/ib-select-grid';
import IbInputSearch from '@/components/widget/ib-widget/ib-input-search';
import IbSelectTree from '@/components/widget/ib-widget/ib-select-tree';
import IbNumberRange from '@/components/widget/ib-widget/ib-number-range';

const CUSTOM_COMPS = {
  'ib-select': IbSelect,
  'ib-select-grid': IbSelectGrid,
  'ib-input-search': IbInputSearch,
  'ib-select-tree': IbSelectTree,
  'ib-number-range': IbNumberRange
};

function FilterContent() {
  const t = useLocale(locale);
  const { widget, setWidget } = useContext(WidgetFilterContext);

  const handleWidgetPropChange = (key, val) => {
    const newWidget = _.set(widget, key, val);
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleWidgetMultiPropsChange = obj => {
    const newWidget = _.cloneDeep(widget);
    Object.keys(obj).forEach(key => {
      _.set(newWidget, key, obj[key]);
    });
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleFixedValueChange = fixedValue => {
    const newWidget = _.set(widget, 'options.attrs.default.fixedValue', fixedValue);
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleDataOptsChange = opts => {
    const newWidget = _.set(widget, 'options.attrs.dataOpts', opts);
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleTreeDataChange = data => {
    const newWidget = _.set(
      widget,
      'options.attrs.data',
      data.map(item => ({
        key: item,
        title: item,
        value: item
      }))
    );
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleSelectedValChange = selectedVal => {
    updateDefaultValueHandler(selectedVal || []);
  };

  const handleCheckedValChange = checkedVal => {
    updateDefaultValueHandler(checkedVal || []);
  };

  const handleTreeSelectChange = data => {
    updateDefaultValueHandler(data);
  };

  const handleInputValChange = inputVal => {
    const newWidget = _.set(widget, 'options.defaultValue', inputVal);
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleInputNumberValChange = inputVal => {
    const newWidget = _.set(widget, 'options.defaultValue', inputVal);
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const updateDefaultValueHandler = defaultVal => {
    const newWidget = _.set(widget, 'options.defaultValue', defaultVal.join(','));
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const renderCustomComp = props => {
    const CustomComp = CUSTOM_COMPS[props.component];
    return (
      <CustomComp
        widgetId={props.widgetId}
        widget={props}
        inDraw={false}
        defaultValue={props.options.defaultValue}
        onWidgetPropChange={handleWidgetPropChange}
        onWidgetMultiPropsChange={handleWidgetMultiPropsChange}
        onDataOptsChange={handleDataOptsChange}
        onSelectedValChange={handleSelectedValChange}
        onCheckedValChange={handleCheckedValChange}
        onInputValChange={handleInputValChange}
        onTreeDataChange={handleTreeDataChange}
        onTreeSelectChange={handleTreeSelectChange}
        onInputNumberValChange={handleInputNumberValChange}
      />
    );
  };

  console.log('render FilterContent: ', widget);
  return (
    <div className="filter-content">
      {widget.component && widget.component !== 'ib-date' && (
        <Card>
          <div style={{ marginBottom: '10px' }}>
            <span>{t['custom.component.setDefault']}</span>
          </div>
          <div className="custom-component-class">{renderCustomComp(widget)}</div>
        </Card>
      )}
      {widget.component &&
        widget.component === 'ib-date' &&
        widget.serviceName &&
        widget.serviceName !== 'timeDateRangeWidget' && (
          <Card>
            <IbDateDefault widget={widget} onWidgetPropChange={handleWidgetPropChange} />
          </Card>
        )}
      {widget.component &&
        widget.component === 'ib-date' &&
        widget.serviceName &&
        widget.serviceName === 'timeDateRangeWidget' && (
          <Card>
            <IbDateRangeDefault
              widget={widget}
              onWidgetPropChange={handleWidgetPropChange}
              onFixedValueChange={handleFixedValueChange}
            />
          </Card>
        )}
    </div>
  );
}

export default FilterContent;
