import React, { useState, useEffect, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { Button, Icon, message, Select, Spin } from 'antd';
import qs from 'query-string';
import cls from 'classnames';
import { http } from '@/lib';
import APIS from '@/apis/api';
import throttleByRaf from '@/utils/throttleByRaf';

const { Option } = Select;
const generateBaseOption = () => {
  return {
    container: 'luckysheet',
    plugins: [],
    lang: 'zh',
    showinfobar: false,
    data: [
      {
        name: 'Sheet1',
        index: 0,
        status: 1,
        order: 0,
        hide: 0,
        cellData: []
      }
    ],
    cellRightClickConfig: {
      copyAs: false,
      deleteCell: false,
      hideRow: false,
      hideColumn: false,
      matrix: false,
      sort: false,
      filter: false,
      chart: false,
      image: false,
      data: false,
      cellFormat: false
    },
    showsheetbarConfig: {
      add: false
    },
    showtoolbarConfig: {
      numberDecrease: false,
      numberIncrease: false,
      image: false,
      chart: false,
      pivotTable: false,
      dataVerification: false,
      splitColumn: false,
      screenshot: false,
      protection: false,
      print: false,
      function: false,
      frozenMode: false,
      sortAndFilter: false
    },
    enableAddRow: false
  };
};
function LuckysheetPanel({
  onTreeNodeDragOver,
  onTreeNodeDrop,
  onRowInsertBefore,
  onRowDeleteBefore,
  onCellUpdated,
  onRangeSelect,
  allSheetsOption,
  editMode,
  type,
  token,
  resourceId
}: any) {
  const location = useLocation();
  const { tplId } = qs.parse(location.search);
  useEffect(() => {
    initLuckySheet();
    if (editMode === 'viewer') {
      ReactDOM.render(
        <CustomPager tplId={tplId || resourceId} type={type} token={token} />,
        document.querySelector('#custom-pager-container')
      );
    }
  }, []);

  const initLuckySheet: (baseOption?) => void = (baseOption = generateBaseOption()) => {
    const luckysheet = window.luckysheet;
    const options: { [key: string]: any } = baseOption;
    if (editMode === 'designer') {
      options.hook = {
        cellDragStop: (cell, pos, sheet, ctx, ev) => {
          onTreeNodeDrop(ev, pos);
        },
        rowInsertBefore: (index, value, direction) => {
          onRowInsertBefore(index, value, direction);
        },
        rowDeleteBefore: (startIndex, endIndex) => {
          onRowDeleteBefore(startIndex, endIndex);
        },
        cellUpdated: (r, c, oldValue, newValue, isRefresh) => {
          onCellUpdated(r, c, newValue);
        },
        rangeSelect: (sheet, range) => {
          onRangeSelect(range);
        }
      };
    }
    if (editMode === 'viewer') {
      options.showtoolbarConfig.chart = true;
      options.plugins = ['chart'];
    }
    if (allSheetsOption.length) {
      options.data = allSheetsOption;
    }
    luckysheet.create(options);
  };

  const luckyStyle: CSSProperties = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '100%'
  };
  return (
    <>
      {editMode === 'viewer' ? (
        <>
          <div id="custom-pager-container"></div>
          <div className="luckysheet-panel viewer">
            <div id="luckysheet" style={luckyStyle}></div>
          </div>
        </>
      ) : (
        <div className="luckysheet-panel" onDragOver={onTreeNodeDragOver}>
          <div id="luckysheet" style={luckyStyle}></div>
        </div>
      )}
    </>
  );
}

function CustomPager({ tplId, type, token }) {
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1000
  });
  const [isPageLast, setIsPageLast] = useState(false);

  const handlePagerChange: (currentPage: number, pageSize?: number) => void = (
    currentPage = pagination.current,
    pageSize = pagination.pageSize
  ) => {
    fetchData(currentPage, pageSize);
  };

  const fetchData = (currentPage, pageSize) => {
    const isTypeLink = type && type === 'link';
    setLoading(true);
    http
      .post(
        isTypeLink ? APIS.LinkPreviewReport : APIS.PreviewReportTpl,
        {
          tplId,
          page: currentPage,
          size: pageSize
        },
        Object.assign(
          {},
          isTypeLink
            ? {
                headers: {
                  Authorization: token
                }
              }
            : {}
        )
      )
      .then(res => {
        const { previews } = res.data;
        const { result, sheetIndex, sheetName } = previews[0];
        const luckysheet = window.luckysheet;
        setPagination({
          ...pagination,
          current: result ? currentPage : pagination.current,
          pageSize
        });
        setIsPageLast(!result);
        if (!result) {
          message.warning('无更多数据');
          return;
        }
        const { cellData, config, luckysheet_conditionformat_save } = result;
        const baseOption = generateBaseOption();
        const option = {
          ...baseOption,
          showtoolbarConfig: {
            ...baseOption.showtoolbarConfig,
            chart: true
          },
          plugins: ['chart'],
          data: [
            {
              ...luckysheet.getAllSheets()[0],
              name: sheetName,
              index: sheetIndex,
              data: luckysheet.transToData(cellData),
              config,
              luckysheet_conditionformat_save
            }
          ]
        };
        luckysheet.destroy();
        luckysheet.create(option);
        /* const columnLen = luckysheet.getLuckysheetfile()[0].data[0].length;
        luckysheet.setRangeValue(luckysheet.transToData(result.cellData), {
          range: { row: [0, pagination.pageSize - 1], column: [0, columnLen - 1] }
        }); */
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExport = () => {
    setExporting(true);
    http
      .post(APIS.ExportReportTpl, {
        tplId,
        page: pagination.current,
        size: pagination.pageSize
      })
      .then(res => {
        message.success('导出成功，请前往任务列表下载');
      })
      .finally(() => {
        setExporting(false);
      });
  };

  return (
    <>
      <div className="custom-pager">
        <Button.Group size="small">
          <Button
            className="prev"
            disabled={pagination.current === 1}
            onClick={() => handlePagerChange(pagination.current - 1)}
          >
            <Icon type="left" />
            上一页
          </Button>
          <Button className="next" disabled={isPageLast} onClick={() => handlePagerChange(pagination.current + 1)}>
            下一页
            <Icon type="right" />
          </Button>
        </Button.Group>
        {loading && (
          <div className="custom-pager-loading-container">
            <div className="mask"></div>
            <Spin className="desc" spinning={loading} tip="数据加载中..." />
          </div>
        )}
        <Select
          size="small"
          value={pagination.pageSize}
          style={{ marginLeft: '8px' }}
          onChange={selectedVal => handlePagerChange(pagination.current, selectedVal)}
          dropdownClassName="report-viewer-custom-pager"
        >
          <Option value={500}>每页500条</Option>
          <Option value={1000}>每页1000条</Option>
          <Option value={2000}>每页2000条</Option>
        </Select>
      </div>
      <div className="action-btns">
        <Button size="small" loading={exporting} onClick={handleExport}>
          <Icon type="export" />
          导出
        </Button>
      </div>
    </>
  );
}

export default LuckysheetPanel;
