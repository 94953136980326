import { cloneDeep } from 'lodash';
import { formatEchartsValue } from '@/utils/utils';
import { tooltipFormatterFn } from './echarts-helpers';

export const BASE_LINE = {
  grid: {
    top: 36,
    right: 18,
    bottom: 18,
    left: 18,
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    formatter: params => tooltipFormatterFn(params)
  },
  xAxis: {
    type: 'category',
    axisLine: {
      show: true
    },
    // 隐藏x轴刻度线
    axisTick: { show: false },
    data: []
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: function (value, index) {
        return formatEchartsValue(value);
      }
    }
  },
  series: [
    {
      data: [],
      type: 'line'
    }
  ]
};

export const AREA_LINE = {
  ...cloneDeep(BASE_LINE),
  series: [
    {
      type: 'line',
      smooth: true,
      areaStyle: {},
      data: []
    }
  ]
};
