import React, { memo } from 'react';
import Header from './header';
import ComponentsPanel from './components-panel';
import CanvasPanel from './canvas-panel';
import SettingPanel from './setting-panel';

function DesignerContent({ onCompSelect, onCustomCompSelect, onSave, onFilterWidgetEdit }) {
  // console.log('render DesignerContent');
  return (
    <>
      <Header mode="edit" onSave={onSave} />
      <ComponentsPanel onCompSelect={onCompSelect} onCustomCompSelect={onCustomCompSelect} />
      <SettingPanel />
      <CanvasPanel mode="edit" onFilterWidgetEdit={onFilterWidgetEdit} />
    </>
  );
}

export default memo(DesignerContent);
