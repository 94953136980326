import React, { FC, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Form, Select, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import './style.less';

interface IProps extends FormComponentProps {
  activeTimerTask?: any;
}
interface IRef extends FormComponentProps {}
const formItemLayout = {
  labelCol: {
    sm: { span: 5 }
  },
  wrapperCol: {
    sm: { span: 19 }
  }
};
const { Option } = Select;
const perDayOpts = new Array(30).fill(null).map((item, index) => `${index + 1}`);
const hourOpts = new Array(24).fill(null).map((item, index) => (index < 10 ? `0${index}` : '' + index));
const minuteOpts = new Array(60).fill(null).map((item, index) => (index < 10 ? `0${index}` : '' + index));
const secondOpts = new Array(60).fill(null).map((item, index) => (index < 10 ? `0${index}` : '' + index));
export const EnumWeek: Map<string, string> = new Map()
  .set('1', '周一')
  .set('2', '周二')
  .set('3', '周三')
  .set('4', '周四')
  .set('5', '周五')
  .set('6', '周六')
  .set('7', '周日');
const dateOpts = [].concat(
  new Array(31).fill(null).map((item, index) => ({ key: index + 1, value: `${index + 1}` })),
  [{ key: 'lastDay', value: 'lastDay' }]
);
const TimerTask: FC<IProps> = forwardRef<IRef, IProps>((props, ref) => {
  const {
    form,
    form: { getFieldDecorator, setFieldsValue, getFieldValue },
    activeTimerTask
  } = props;
  useImperativeHandle(ref, () => ({
    form
  }));
  useEffect(() => {
    init();
  }, []);
  const init = () => {
    if (activeTimerTask && activeTimerTask.cronModel) {
      const {
        cronType,
        cronModel: { cycleTime, hour, minute, second, includeLastDay }
      } = activeTimerTask;
      let taskDate;
      if (cronType === 'EVERY_DAY') {
        taskDate = cycleTime;
      } else {
        taskDate = includeLastDay ? [].concat(cycleTime.split(','), ['lastDay']) : cycleTime.split(',');
      }
      setFieldsValue({
        taskFrequency: cronType,
        taskDate,
        taskTimeHour: hour,
        taskTimeMinute: minute,
        taskTimeSecond: second
      });
      return;
    }
    setFieldsValue({
      taskFrequency: 'EVERY_DAY',
      taskDate: '1',
      taskTimeHour: '00',
      taskTimeMinute: '00',
      taskTimeSecond: '00'
    });
  };
  const handleTaskFrequencyValueChange = selectedValue => {
    setFieldsValue({
      taskDate: selectedValue === 'EVERY_DAY' ? '1' : []
    });
  };
  const taskFrequency = getFieldValue('taskFrequency');
  return (
    <Form {...formItemLayout} colon={false} labelAlign="right" className="form-timer-task-wrapper">
      <Form.Item label="任务频率">
        {getFieldDecorator('taskFrequency')(
          <Select onChange={handleTaskFrequencyValueChange}>
            <Option value="EVERY_HOUR">时</Option>
            <Option value="EVERY_DAY">天</Option>
            <Option value="EVERY_WEEK">周</Option>
            <Option value="EVERY_MONTH">月</Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="任务日期" style={{ display: taskFrequency === 'EVERY_HOUR' ? 'block' : 'none' }}>
        {getFieldDecorator('taskDate', {
          rules: [{ required: true, message: '日期不可以为空' }]
        })(
          <Select mode="multiple">
            {hourOpts.map(hour => (
              <Option key={hour} value={hour}>
                {hour}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="任务日期" style={{ display: taskFrequency === 'EVERY_DAY' ? 'block' : 'none' }}>
        <Row gutter={8}>
          <Col span={2}>每</Col>
          <Col span={8}>
            {getFieldDecorator('taskDate')(
              <Select>
                {perDayOpts.map(day => (
                  <Option key={day} value={day}>
                    {day}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          <Col span={2}>天</Col>
        </Row>
      </Form.Item>
      <Form.Item label="任务日期" style={{ display: taskFrequency === 'EVERY_WEEK' ? 'block' : 'none' }}>
        {getFieldDecorator('taskDate', {
          rules: [{ required: true, message: '日期不可以为空' }]
        })(
          <Select mode="multiple">
            {[...EnumWeek].map(([key, val]) => (
              <Option key={key} value={key}>
                {val}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="任务日期" style={{ display: taskFrequency === 'EVERY_MONTH' ? 'block' : 'none' }}>
        {getFieldDecorator('taskDate', {
          rules: [{ required: true, message: '日期不可以为空' }]
        })(
          <Select mode="multiple">
            {dateOpts.map(({ key, value }) => (
              <Option key={key} value={value}>
                {value === 'lastDay' ? '最后一天' : value}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        label="任务时间"
        className="form-last-item no-margin"
        style={{ display: taskFrequency != 'EVERY_HOUR' ? 'block' : 'none' }}
      >
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item className="no-margin">
              {getFieldDecorator('taskTimeHour')(
                <Select>
                  {hourOpts.map(hour => (
                    <Option key={hour} value={hour}>
                      {hour}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>:</span>
          </Col>
          <Col span={8}>
            <Form.Item className="no-margin">
              {getFieldDecorator('taskTimeMinute')(
                <Select>
                  {minuteOpts.map(minute => (
                    <Option key={minute} value={minute}>
                      {minute}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label="任务时间"
        className="form-last-item no-margin"
        style={{ display: taskFrequency === 'EVERY_HOUR' ? 'block' : 'none' }}
      >
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item className="no-margin">
              {getFieldDecorator('taskTimeMinute')(
                <Select>
                  {minuteOpts.map(minute => (
                    <Option key={minute} value={minute}>
                      {minute}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>:</span>
          </Col>
          <Col span={8}>
            <Form.Item className="no-margin">
              {getFieldDecorator('taskTimeSecond')(
                <Select>
                  {secondOpts.map(second => (
                    <Option key={second} value={second}>
                      {second}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
});

export default Form.create<IProps>()(TimerTask);
