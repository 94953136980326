// 仪表板头部
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from '@/store/useStore';
import { Input, Radio, Button, message, Switch } from 'antd';
import { WorkplaceContext } from './context';
import { replaceFieldUniqueIdFn } from './util';
import { API_CHART_TYPE } from '@/utils/enum';
import SvgIcon from '@/components/SvgIcon';

function Header({ mode, onSave }: any) {
  const history = useHistory();
  const { activeDevice, setActiveDevice, setDashboardName, setShowWatermark } = useContext(WorkplaceContext);
  const { dashboardStore } = useStore();
  const [state, setState] = useState({
    name: ''
  });

  useEffect(() => {
    if (dashboardStore.state.dashboardName) {
      setState({
        ...state,
        name: dashboardStore.state.dashboardName
      });
    }
  }, []);

  const handleNameValueChange = ev => {
    setState({
      ...state,
      name: ev.target.value
    });
    setDashboardName(ev.target.value);
  };

  const handleSave = () => {
    if (!state.name) {
      message.error('仪表板名不能为空');
      return;
    }
    if (!dashboardStore.state.charts.length) {
      message.error('请配置至少一个图表');
      return;
    }
    let params = {
      name: state.name,
      charts: dashboardStore.state.charts.map(chart => {
        const {
          order,
          frontChartId,
          chartType,
          layout,
          mobileTileLayout,
          mobileConfig,
          dataConfig: {
            dataSetId,
            dimensionFields,
            measureFields,
            filters,
            compareField,
            dataMaxLengthLimit,
            branchTag,
            drillDownFields,
            xaxis,
            legend,
            yaxis,
            yaxisExt,
            extraConfigFields,
            extraConfigFieldMap,
            freshTime
          },
          styleConfig,
          advancedConfig,
          drillDownConfig
        } = chart;
        return Object.assign(
          {},
          {
            order,
            frontChartId,
            chartsType: API_CHART_TYPE[chartType],
            dataSetId,
            measureFields: [
              ...(chartType === 'combination'
                ? [
                  ...(Array.isArray(yaxis) ? yaxis.map(item => ({ ...item, fieldType: 'MAIN_MEASURE' })) : []),
                  ...(Array.isArray(yaxisExt)
                    ? yaxisExt.map(item => ({ ...item, fieldType: 'ASSISTANT_MEASURE' }))
                    : [])
                ]
                : measureFields)
            ].map(replaceFieldUniqueIdFn),
            filters: filters.map(filter => ({ ...filter, fieldUniqueId: filter.fieldUniqueId.replace('-clone', '') })),
            dataMaxLengthLimit,
            dimensionMerge: !['table', 'map', 'bubble-map'].includes(chartType),
            chartsConfig: JSON.stringify(
              {
                layout,
                mobileTileLayout,
                mobileConfig,
                styleConfig,
                advancedConfig,
                drillDownConfig,
                branchTag,
                drillDownFields,
                yaxis,
                yaxisExt,
                extraConfigFields,
                extraConfigFieldMap,
                freshTime,
                order
              },
              censor
            )
          },
          dimensionFields
            ? {
              dimensionFields: dimensionFields.map(replaceFieldUniqueIdFn)
            }
            : {},
          chartType === 'combination'
            ? {
              dimensionFields: [
                ...(Array.isArray(xaxis) ? xaxis.map(item => ({ ...item, fieldType: 'MAIN_DIMENSION' })) : []),
                ...(Array.isArray(legend) ? legend.map(item => ({ ...item, fieldType: 'ASSISTANT_DIMENSION' })) : [])
              ]
            }
            : {},
          compareField ? { compareField } : {}
        );
      }),
      customComps: dashboardStore.state.customComps
    };
    onSave(params);
  };

  // 解决 JSON.stringify 序列化时 Infinity 变为 null
  const censor = (key, value) => {
    if (value == Infinity) {
      return 'Infinity';
    }
    return value;
  };

  const handleBack = () => {
    history.goBack();
  };

  const switchDevice = ev => {
    const activeDevice = ev.target.value;
    setActiveDevice(activeDevice);
    dashboardStore.setActiveBreakPoint(activeDevice === 'pc' ? 'lg' : 'xs');
  };

  // console.log('render Header');

  return (
    <div className="action-panel-container">
      <div className="header">
        <div className="header-icon square header-back header-border-right no-margin" onClick={handleBack}>
          <i className="svg-icon-wrapper icon-arrow-left">
            <SvgIcon iconClass="arrow-left" />
          </i>
        </div>
        <div className="header-content">
          <div className="dashboard-action-panel">
            <div className="action-panel-left">
              <div className="header-name">
                <div className="work-type-icon">
                  <div className="header-icon">
                    <i className="svg-icon-wrapper icon-dashboard-small">
                      <SvgIcon iconClass="dashboard" />
                    </i>
                  </div>
                </div>
                {mode === 'edit' ? (
                  <Input
                    type="text"
                    className="ant-v4-input header-name-input"
                    placeholder="请输入仪表板名称"
                    value={state.name}
                    onChange={handleNameValueChange}
                  />
                ) : (
                  <span>{state.name}</span>
                )}
              </div>
            </div>
            {mode === 'edit' && (
              <>
                <div className="action-panel-middle">
                  <Radio.Group defaultValue="pc" buttonStyle="solid" value={activeDevice} onChange={switchDevice}>
                    <Radio.Button value="pc">
                      <i className="svg-icon-wrapper icon-pc">
                        <SvgIcon iconClass="pc" />
                      </i>
                    </Radio.Button>
                    <Radio.Button value="mobile">
                      <i className="svg-icon-wrapper icon-mobile">
                        <SvgIcon iconClass="mobile" />
                      </i>
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <div className="action-panel-right">
                  <div className="header-btn-wrapper" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <div style={{ marginRight: '20px' }}>
                      <span style={{ color: '#666' }}>水印</span>
                      <Switch style={{ marginLeft: '10px' }}
                        defaultChecked={false}
                        onChange={
                          (checked) => {
                            setShowWatermark(checked)
                          }
                        }
                      />
                    </div> */}

                    <Button type="primary" className="header-btn" onClick={handleSave}>
                      保存
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
