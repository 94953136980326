import React, { useState, useEffect, useMemo } from 'react';
import { TreeSelect } from 'antd';
import { http } from '@/lib';
import APIS from '@/apis/api';
import useLocale from '@/utils/useLocale';
import locale from '../locale';
import { IProps } from './data';

const { TreeNode, SHOW_ALL } = TreeSelect;

function IbSelectTree(props: IProps) {
  const { widget, inDraw, defaultValue, onTreeDataChange, onTreeSelectChange } = props;
  const t = useLocale(locale);
  const [value, setValue] = useState(undefined);
  const treeData = useMemo(() => {
    return widget.options.attrs.data;
  }, [JSON.stringify(widget.options.attrs.data)]);
  const isMultiple = useMemo(() => {
    return widget.options.attrs.multiple;
  }, [widget.options.attrs.multiple]);

  useEffect(() => {
    setValue(!defaultValue || !defaultValue.split(',').length ? undefined : defaultValue.split(','));
  }, [defaultValue]);

  useEffect(() => {
    if (inDraw || !widget.options.attrs.fieldUniqueId) return;

    fetchData();
  }, [widget.options.attrs.fieldUniqueId]);

  const fetchData = () => {
    http
      .post(APIS.GetDataSetDataByFields, {
        id: widget.options.attrs.dataSetId,
        queryFields: {
          fieldUniqueIds: widget.options.attrs.fieldUniqueId,
          groupDuplicate: true
        },
        page: 1,
        size: 10000
      })
      .then(res => {
        const { content } = res.data;
        onTreeDataChange(content.map(item => Object.values(item)).flat(Infinity));
      });
  };

  const handleChange = value => {
    const newValue = isMultiple ? value : [value];
    setValue(newValue);
    onTreeSelectChange(newValue);
  };

  return (
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      value={isMultiple ? value : !value ? undefined : value[0]}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeCheckable={isMultiple}
      placeholder={t[widget.options.attrs.placeholder]}
      allowClear
      treeDefaultExpandAll
      showCheckedStrategy={SHOW_ALL}
      maxTagCount={1}
      maxTagPlaceholder={omittedValues => `+${omittedValues.length}`}
      treeData={treeData}
      onChange={handleChange}
    />
  );
}

export default IbSelectTree;
