import _ from 'lodash';
import {
  DEFAULT_COLOR_CASE,
  DEFAULT_LABEL,
  DEFAULT_LEGEND_STYLE,
  DEFAULT_SIZE,
  DEFAULT_TITLE_STYLE,
  DEFAULT_XAXIS_STYLE,
  DEFAULT_YAXIS_STYLE,
  DEFAULT_SPLIT
} from '@/components/chart/chart';
import { API_CHART_TYPE } from '@/utils/enum';
import {
  DEFAULT_TEXT_STYLE,
  DEFAULT_PICTURE_STYLE,
  DEFAULT_VIDEO_STYLE,
  DEFAULT_STATISTIC_STYLE,
  DEFAULT_CUR_TIME_STYLE
} from '@/components/element/element';
import { supportAnimateBorders } from '@/pages/Screen/Workspace/core/Canvas/RenderEngine';

const COMMON_BASE_CONFIG = {
  x: 0,
  y: 0,
  zIndex: 1
};

/**
 * 生成图表备注默认配置
 */
export const genChartMarkConfig = () => {
  return {
    show: false,
    position: 'title',
    content: ''
  };
};

/**
 * 获取画布区左上角坐标值
 */
export const getCanvasCoordinate = (leftSideW, topbarH = 70) => {
  const ele = document.querySelector('.canvas-wrapper');
  const { top, left } = ele.getBoundingClientRect();
  const rulerSize = [30, 30];
  const offset = 20;
  return {
    x: leftSideW + rulerSize[0] + offset === left ? 0 : leftSideW - left + rulerSize[0],
    y: topbarH + rulerSize[1] + offset === top ? 0 : topbarH - top + rulerSize[1]
  };
};
export function getBaseConfig(compGroup, chartType) {
  switch (compGroup) {
    case 'charts':
      if (chartType === 'ranking-list') {
        return {
          ...COMMON_BASE_CONFIG,
          width: 382,
          height: 198
        };
      }
      return {
        ...COMMON_BASE_CONFIG,
        width: 400,
        height: 300
      };
    case 'element':
      if (chartType === 'text' || chartType === 'curTime') {
        return {
          ...COMMON_BASE_CONFIG,
          width: 380,
          height: 56
        };
      } else if (chartType === 'image') {
        return {
          ...COMMON_BASE_CONFIG,
          width: 400,
          height: 200,
          backgroundColor: 'rgba(2,7,26,1)',
          imageUrl: ''
        };
      } else if (chartType === 'statistic') {
        return {
          ...COMMON_BASE_CONFIG,
          width: 194,
          height: 45,
          backgroundColor: 'rgba(2,7,26,1)'
        };
      }
    case 'media':
      if (chartType === 'video') {
        return {
          ...COMMON_BASE_CONFIG,
          width: 408,
          height: 256,
          backgroundColor: 'rgba(2,7,26,1)',
          source: {
            type: 'video/mp4'
          },
          data: ''
        };
      }
    case 'decoration':
      if (chartType.includes('border')) {
        return {
          ...COMMON_BASE_CONFIG,
          width: 330,
          height: 240,
          backgroundColor: 'rgba(2,7,26,1)'
        };
      } else if (chartType.includes('d-line')) {
        return {
          ...COMMON_BASE_CONFIG,
          width: 400,
          height: 50,
          backgroundColor: 'rgba(2,7,26,1)'
        };
      }
  }
}

const chartBaseProps = {
  measureFields: [],
  filterFields: [],
  filters: [],
  chartOptions: null,
  dataSetId: undefined,
  dataSetName: '',
  dataSetFields: [],
  flattenedFields: [],
  isFetchingData: false,
  dataMaxLengthLimit: 1000,
  extraConfigFields: [],
  freshTime: {
    isFreshEnable: false,
    freshTimeNumber: 5,
    freshTimeUnit: 'min'
  }
};
export function getDataConfig(chartType) {
  switch (chartType) {
    case 'line':
    case 'area':
    case 'bar':
    case 'bar-stack':
    case 'bar-stack-percent':
    case 'bar-horizontal':
    case 'strip-stack':
    case 'strip-stack-percent':
    case 'pie':
    case 'pie-rose':
    case 'treemap':
    case 'scatter':
    case 'map':
    case 'bubble-map':
    case 'table':
    case 'ranking-list':
      return {
        dimensionFields: [],
        ...chartBaseProps
      };
    case 'combination': {
      const chartCombinationConfig = {
        ...chartBaseProps,
        xaxis: [],
        yaxis: [],
        yaxisExt: [],
        legend: [],
        extraConfigFieldMap: {
          yaxis: [],
          yaxisExt: []
        }
      };
      delete chartCombinationConfig.measureFields;
      delete chartCombinationConfig.extraConfigFields;
      return chartCombinationConfig;
    }
    case 'radar':
      return {
        dimensionFields: [],
        ...chartBaseProps,
        branchTag: 'dimension'
      };
    case 'kpi':
    case 'gauge':
    case 'funnel':
      return {
        ...chartBaseProps
      };
    case 'tableMultidimensional':
      return {
        dimensionFields: [],
        ...chartBaseProps,
        compareFields: [], // 目前对比交叉表只支持一个字段
        compareField: null
      };
    case 'text':
      return {
        static: '我是文字组件'
      };
    case 'statistic':
      return {
        ...chartBaseProps,
        dataBindType: 'static',
        value: '1234',
        refresh: 10,
        responseData: ''
      };
    case 'curTime':
      return {
        nowTime: ''
      };
  }
}

export function getStyleConfig(chartType) {
  if (chartType.includes('border')) {
    if (supportAnimateBorders.includes(chartType)) {
      return {
        skin: {
          color: '#2e8ae5',
          animate: true,
          rotationDir: true // true: 顺时针 false: 逆时针
        }
      };
    }
    return {
      skin: {
        color: '#2e8ae5'
      }
    };
  } else if (chartType.includes('d-line')) {
    return {
      arrowDir: 'horizontal',
      arrowStyle: 'none',
      lineStyle: 'dashed',
      lineColor: '#1b64ee'
    };
  }
  const {
    lineSymbol,
    lineSymbolSize,
    lineWidth,
    lineType,
    lineSmooth,
    barDefault,
    barWidth,
    pieInnerRadius,
    pieOuterRadius,
    pieRoseType,
    pieRoseRadius,
    radarShape,
    radarSize,
    gaugeMin,
    gaugeMax,
    gaugeStartAngle,
    gaugeEndAngle,
    funnelWidth,
    scatterSymbol,
    scatterSymbolSize,
    treemapWidth,
    treemapHeight,
    tableHeaderHeight,
    tableHeaderFontSize,
    tableCellHeight,
    tableCellFontSize
  } = DEFAULT_SIZE;
  switch (chartType) {
    case 'line':
    case 'area':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: DEFAULT_LABEL,
        xAxis: DEFAULT_XAXIS_STYLE,
        yAxis: DEFAULT_YAXIS_STYLE,
        legend: DEFAULT_LEGEND_STYLE,
        customAttr: {
          size: {
            lineSymbol,
            lineSymbolSize,
            lineWidth,
            lineType,
            lineSmooth
          }
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        },
        highConfig: {
          proximityAnalysis: false
        }
      };
    case 'bar':
    case 'bar-stack':
    case 'bar-stack-percent':
    case 'bar-horizontal':
    case 'strip-stack':
    case 'strip-stack-percent':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: {
          ...DEFAULT_LABEL,
          position: chartType === 'bar' || chartType === 'bar-stack' ? DEFAULT_LABEL.position : 'inside'
        },
        xAxis:
          chartType === 'bar' || chartType === 'bar-stack'
            ? DEFAULT_XAXIS_STYLE
            : { ...DEFAULT_YAXIS_STYLE, position: 'bottom' },
        yAxis:
          chartType === 'bar' || chartType === 'bar-stack'
            ? DEFAULT_YAXIS_STYLE
            : { ...DEFAULT_XAXIS_STYLE, position: 'left' },
        legend: DEFAULT_LEGEND_STYLE,
        customAttr: {
          size: {
            barDefault,
            barWidth
          }
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        },
        highConfig: {
          proximityAnalysis: false
        }
      };
    case 'combination':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'scatter':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: {
          ...DEFAULT_LABEL,
          position: 'inside'
        },
        xAxis: DEFAULT_XAXIS_STYLE,
        yAxis: DEFAULT_YAXIS_STYLE,
        legend: DEFAULT_LEGEND_STYLE,
        customAttr: {
          size: {
            scatterSymbol
            // scatterSymbolSize
          }
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'pie':
    case 'pie-rose':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: {
          ...DEFAULT_LABEL,
          position: 'inside'
        },
        legend: DEFAULT_LEGEND_STYLE,
        customAttr: {
          size: Object.assign(
            {},
            {
              pieInnerRadius,
              pieOuterRadius
            },
            chartType === 'pie-rose' ? { pieRoseType, pieRoseRadius } : {}
          )
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'radar':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: { ...DEFAULT_LABEL, position: 'inside' },
        legend: DEFAULT_LEGEND_STYLE,
        customAttr: {
          size: {
            radarShape,
            radarSize
          }
        },
        customStyle: {
          split: DEFAULT_SPLIT,
          backgroundColor: 'rgba(41,52,65,1)'
        },
        highConfig: {
          proximityAnalysis: false
        }
      };
    case 'gauge':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: { ...DEFAULT_LABEL, position: 'inside' },
        customAttr: {
          size: {
            gaugeMin,
            gaugeMax,
            gaugeStartAngle,
            gaugeEndAngle
          }
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'funnel':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: { ...DEFAULT_LABEL, position: 'inside' },
        legend: DEFAULT_LEGEND_STYLE,
        customAttr: {
          size: {
            funnelWidth
          }
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'map':
    case 'bubble-map':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: DEFAULT_LABEL,
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'kpi':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        customAttr: {
          color: {
            dimensionColor: '#fff',
            quotaColor: '#fff'
          },
          size: {
            dimensionShow: DEFAULT_SIZE.dimensionShow,
            dimensionFontSize: DEFAULT_SIZE.dimensionFontSize,
            quotaFontSize: DEFAULT_SIZE.quotaFontSize,
            spaceSplit: DEFAULT_SIZE.spaceSplit
          },
          addon: {
            prefix: '',
            suffix: ''
          }
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'treemap':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        color: DEFAULT_COLOR_CASE,
        label: { ...DEFAULT_LABEL, show: true, position: 'inside', color: '#fff' },
        customAttr: {
          size: {
            treemapWidth,
            treemapHeight
          }
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'table':
    case 'tableMultidimensional':
      return {
        title: DEFAULT_TITLE_STYLE,
        mark: genChartMarkConfig(),
        customAttr: {
          color: {
            tableHeaderBgColor: 'rgba(29,29,29,1)',
            tableCellBgColor: 'rgba(20,20,20,1)',
            tableFontColor: '#fff'
          },
          size: {
            tableHeaderHeight,
            tableCellHeight,
            tableHeaderFontSize,
            tableCellFontSize
          }
        },
        customStyle: {
          backgroundColor: 'rgba(41,52,65,1)'
        }
      };
    case 'ranking-list':
      return {
        tHeader: {
          visible: true,
          lineHeight: 32,
          backgroundColor: 'rgba(0,0,0,1)',
          fontSize: 12,
          color: 'rgba(255,255,255,0.85)',
          fontWeight: 'normal',
          fontFamily: 'STHeiti'
        },
        row: {
          default: {
            displayRows: 5,
            even: {
              backgroundColor: 'rgba(27,100,238,0.1)'
            },
            odd: {
              backgroundColor: 'rgba(27,100,238,0.1)'
            }
          },
          selected: {
            backgroundColor: 'rgba(27,100,238,1)'
          },
          special: {
            firstBgColor: '#c66e16',
            secondBgColor: '#c3a408',
            thirdBgColor: '#049867'
          }
        },
        serialNo: {
          default: {
            shape: 'circle',
            symbolSize: 25,
            backgroundColor: 'rgba(27,100,238,1)'
          },
          specialRow: {
            first: {
              backgroundColor: '#c66e16',
              fontSize: 12,
              color: 'rgba(255,255,255,1)',
              fontWeight: 'normal',
              fontFamily: 'STHeiti'
            },
            second: {
              backgroundColor: '#c3a408',
              fontSize: 12,
              color: 'rgba(255,255,255,1)',
              fontWeight: 'normal',
              fontFamily: 'STHeiti'
            },
            third: {
              backgroundColor: '#049867',
              fontSize: 12,
              color: 'rgba(255,255,255,1)',
              fontWeight: 'normal',
              fontFamily: 'STHeiti'
            }
          }
        },
        animate: {
          loop: true,
          interval: 3000
        }
      };
    case 'text':
      return {
        backgroundColor: DEFAULT_TEXT_STYLE.backgroundColor,
        fontSize: DEFAULT_TEXT_STYLE.fontSize,
        color: DEFAULT_TEXT_STYLE.color,
        hPosition: DEFAULT_TEXT_STYLE.hPosition,
        fontStyle: DEFAULT_TEXT_STYLE.fontStyle,
        fontWeight: DEFAULT_TEXT_STYLE.fontWeight,
        letterSpacing: DEFAULT_TEXT_STYLE.letterSpacing,
        a: {
          enable: DEFAULT_TEXT_STYLE.a.enable,
          href: DEFAULT_TEXT_STYLE.a.href,
          target: DEFAULT_TEXT_STYLE.a.target
        }
      };
    case 'image':
      return {
        borderWidth: DEFAULT_PICTURE_STYLE.borderWidth,
        borderStyle: DEFAULT_PICTURE_STYLE.borderStyle,
        borderColor: DEFAULT_PICTURE_STYLE.borderColor,
        borderRadius: DEFAULT_PICTURE_STYLE.borderRadius,
        adaptation: DEFAULT_PICTURE_STYLE.adaptation,
        a: {
          enable: DEFAULT_PICTURE_STYLE.hyperlinks.enable,
          href: DEFAULT_PICTURE_STYLE.hyperlinks.content,
          target: DEFAULT_PICTURE_STYLE.hyperlinks.openMode
        }
      };
    case 'video':
      return {
        borderRadius: DEFAULT_VIDEO_STYLE.borderRadius,
        autoPlay: DEFAULT_VIDEO_STYLE.autoPlay,
        loop: DEFAULT_VIDEO_STYLE.loop
      };
    case 'statistic':
      return {
        theme: DEFAULT_STATISTIC_STYLE.theme,
        themeColor: DEFAULT_STATISTIC_STYLE.themeColor,
        addon: {
          prefix: '',
          suffix: '',
          fontSize: DEFAULT_STATISTIC_STYLE.addon.fontSize,
          color: DEFAULT_STATISTIC_STYLE.addon.color
        },
        prefix: {
          marginRight: DEFAULT_STATISTIC_STYLE.prefix.marginRight
        },
        suffix: {
          marginLeft: DEFAULT_STATISTIC_STYLE.suffix.marginLeft
        },
        value: {
          interval: DEFAULT_STATISTIC_STYLE.value.interval,
          fontSize: DEFAULT_STATISTIC_STYLE.value.fontSize,
          color: DEFAULT_STATISTIC_STYLE.value.color,
          fontFamily: DEFAULT_STATISTIC_STYLE.value.fontFamily,
          fontWeight: DEFAULT_STATISTIC_STYLE.value.fontWeight,
          precision: DEFAULT_STATISTIC_STYLE.value.precision,
          groupSeparator: DEFAULT_STATISTIC_STYLE.value.groupSeparator
        }
      };
    case 'curTime':
      return {
        backgroundColor: DEFAULT_CUR_TIME_STYLE.backgroundColor,
        fontSize: DEFAULT_CUR_TIME_STYLE.fontSize,
        color: DEFAULT_CUR_TIME_STYLE.color,
        hPosition: DEFAULT_CUR_TIME_STYLE.hPosition,
        fontStyle: DEFAULT_CUR_TIME_STYLE.fontStyle,
        fontWeight: DEFAULT_CUR_TIME_STYLE.fontWeight,
        dateFormat: DEFAULT_CUR_TIME_STYLE.dateFormat,
        weekFormat: DEFAULT_CUR_TIME_STYLE.weekFormat
      };
  }
}

export const replaceFieldUniqueId = fieldUniqueId => fieldUniqueId.replace('-clone', '');

export const formatCompareFieldDate = compareField => {
  const regExp = /-/g;
  const newCompareField = _.cloneDeep(compareField);
  if (newCompareField.valueCategory === 'STATIC_MULTIVALUED') {
    newCompareField.staticValues = newCompareField.staticValues.map(val => val.replace(regExp, ''));
  } else if (newCompareField.valueCategory === 'DYNAMIC_MULTIVALUED') {
    newCompareField.dynamicValue.baseValue = newCompareField.dynamicValue.baseValue.replace(regExp, '');
  } else {
    newCompareField.rangeValues = newCompareField.rangeValues.map(val => ({
      start: val.start.replace(regExp, ''),
      end: val.end.replace(regExp, '')
    }));
  }
  return newCompareField;
};

export const convertItemCompDataConfig = itemComp => {
  const {
    type,
    dataConfig: {
      dataSetId,
      dimensionFields,
      measureFields,
      filterFields,
      filters,
      compareField,
      dataMaxLengthLimit,
      xaxis,
      yaxis,
      yaxisExt,
      legend
    }
  } = itemComp;
  const dimensionMerge = !['table', 'map', 'bubble-map', 'ranking-list'].includes(type);
  let tempDimFields = null;
  let tempMeaFields = null;
  if (type === 'combination') {
    tempDimFields = [
      ...(xaxis ? xaxis.map(item => ({ ...item, fieldType: 'MAIN_DIMENSION' })) : []),
      ...(legend ? legend.map(item => ({ ...item, fieldType: 'ASSISTANT_DIMENSION' })) : [])
    ].map(field => ({
      ...field,
      uniqueId: replaceFieldUniqueId(field.uniqueId)
    }));
    tempMeaFields = [
      ...(yaxis ? yaxis.map(item => ({ ...item, fieldType: 'MAIN_MEASURE' })) : []),
      ...(yaxisExt ? yaxisExt.map(item => ({ ...item, fieldType: 'ASSISTANT_MEASURE' })) : [])
    ].map(field => ({
      ...field,
      uniqueId: replaceFieldUniqueId(field.uniqueId)
    }));
  } else {
    tempDimFields = !dimensionFields
      ? []
      : dimensionFields.map(itemField => {
          if (itemField.sortType === 'NONE') itemField.sortType = null;
          return {
            ...itemField,
            uniqueId: replaceFieldUniqueId(itemField.uniqueId),
            sort: !!itemField.sortType
          };
        });
    tempMeaFields = measureFields.map(itemField => {
      if (itemField.sortType === 'NONE') itemField.sortType = null;
      return {
        ...itemField,
        uniqueId: replaceFieldUniqueId(itemField.uniqueId),
        calculatorType: itemField.calculatorType || 'SUM',
        sort: !!itemField.sortType
      };
    });
  }
  return {
    chartsType: API_CHART_TYPE[type],
    dataSetId,
    dimensionMerge,
    size: dataMaxLengthLimit,
    dimensionFields: tempDimFields,
    measureFields: tempMeaFields,
    filters: filters.map(itemCon => ({
      ...itemCon,
      fieldUniqueId: replaceFieldUniqueId(itemCon.fieldUniqueId)
    })),
    compareField: compareField
      ? {
          ...formatCompareFieldDate(compareField),
          fieldUniqueId: replaceFieldUniqueId(compareField.fieldUniqueId)
        }
      : null
  };
};
