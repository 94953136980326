import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Switch, Select } from 'antd';
import _ from 'lodash';
import useLocale from '@/utils/useLocale';
import locale from '@/components/widget/locale';
import { filterOutWidgetBtn } from '../util';
import '../style/modal-button.less';

const initialState = () => ({
  value: '查询',
  autoTrigger: true,
  customRange: false,
  filterIds: []
});

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 18 }
  }
};

const { Option } = Select;

function ModalButton({ visible, widgetLabel, widget, customComps, onCancel, onOk }) {
  const t = useLocale(locale);
  const [formData, setFormData] = useState(initialState());

  useEffect(() => {
    initState();
  }, []);

  const initState = () => {
    const {
      options: {
        attrs: { autoTrigger, customRange, filterIds },
        value
      }
    } = widget;
    const newFormData = _.cloneDeep(formData);
    newFormData.value = value;
    newFormData.autoTrigger = autoTrigger;
    newFormData.customRange = customRange;
    newFormData.filterIds = filterIds;
    setFormData({
      ...formData,
      ...newFormData
    });
  };

  const handleOk = () => {
    const { value, autoTrigger, customRange, filterIds } = formData;
    const newWidget = _.cloneDeep(widget);
    newWidget.options.value = value;
    newWidget.options.attrs.autoTrigger = autoTrigger;
    newWidget.options.attrs.customRange = customRange;
    newWidget.options.attrs.filterIds = filterIds;
    onOk({
      ...widget,
      ...newWidget
    });
  };

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  return (
    <Modal
      title={`${widgetLabel}组件`}
      visible={visible}
      width={420}
      wrapClassName="filter-button-dialog"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form {...formItemLayout} colon={false}>
        <Form.Item label={t['ibSearchButton.text']}>
          <Input value={formData.value} onChange={ev => handleChange('text', ev.target.value)} />
        </Form.Item>
        <Form.Item
          label={t['ibSearchButton.autoTrigger']}
          extra={<span className="form-item-tip">{t['ibSearchButton.autoTriggerTip']}</span>}
        >
          <Switch checked={formData.autoTrigger} onChange={checked => handleChange('autoTrigger', checked)} />
        </Form.Item>
        <Form.Item
          label={t['ibSearchButton.range']}
          extra={<span className="form-item-tip warning">{t['ibSearchButton.rangeTip']}</span>}
        >
          <Switch checked={formData.customRange} onChange={checked => handleChange('customRange', checked)} />
        </Form.Item>
        <Form.Item label={t['ibSearchButton.relative']} style={{ display: formData.customRange ? 'block' : 'none' }}>
          <Select
            placeholder={t['ibSearchButton.relative.placeholder']}
            mode="multiple"
            maxTagCount={1}
            maxTagPlaceholder={omittedValues => `+${omittedValues.length}`}
            value={formData.filterIds}
            onChange={checked => handleChange('filterIds', checked)}
          >
            {customComps
              .filter(filterOutWidgetBtn)
              .map(comp => ({
                label: `${t[comp.label]}${comp.style.mainTitle ? '【' + comp.style.mainTitle + '】' : ''}`,
                value: comp.widgetId
              }))
              .map(opt => (
                <Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalButton;
