import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'antd';
import FieldFilter from '@/components/FieldFilter';

function ModalFilter({ visible, onOk, onCancel, itemField, filters }) {
  const fieldFilterRef = useRef(null);

  const handleOk = () => {
    const { filterConditions, conditionJoin } = fieldFilterRef.current;
    const {
      customName,
      attribute: { type, valueType, dateFormat, customFieldFunction }
    } = itemField;
    onOk(
      filterConditions.map(condition => {
        return {
          ...condition,
          conditionJoin,
          frontAdditionalInfo: {
            uuid: uuidv4(),
            fieldCustomName: customName,
            type,
            valueType,
            dateFormat,
            customFieldFunction,
            timeDim: valueType === 'DATE',
            isDerivedField: !!customFieldFunction
          }
        };
      })
    );
  };
  return (
    <Modal
      title="添加过滤"
      visible={visible}
      width={800}
      maskClosable={false}
      wrapClassName="dlg-field-filter-wrapper"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <FieldFilter ref={fieldFilterRef} filters={filters} itemField={itemField} />
    </Modal>
  );
}

export default ModalFilter;
