import React, { PureComponent, Validator } from 'react'
import { IUdFormItem } from '../../..'
import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import { ValidationRule } from 'antd/lib/form'

/**
 * 数字范围输入框
 */
class UdNumberRange extends PureComponent<IUdNumberRangeProps, IUdNumberRangeState> {

  constructor(props: IUdNumberRangeProps) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    this.handleProps(this.props)
  }

  componentWillReceiveProps(nextProps: Readonly<IUdNumberRangeProps>) {
    this.handleProps(nextProps)
  }

  render() {
    return (
      <div className="ud-number-range">
        <InputNumber {...this.props.begin} value={this.state.begin} onChange={(val) => {
          this.triggerChange(0, val)
        }} />
        <span className="ud-number-range-divider">到</span>
        <InputNumber {...this.props.end} value={this.state.end} onChange={(val) => {
          this.triggerChange(1, val)
        }} />
      </div>
    )
  }

  private handleProps = (props: IUdNumberRangeProps) => {
    if (props.value && (props.value[0] != null || props.value[1] != null)) {
      this.setState({ begin: props.value[0], end: props.value[1] })
    } else {
      this.setState({ begin: null, end: null })
    }
  }

  private triggerChange = (index: number, val: number) => {
    let value = this.props.value || []
    value[index] = val

    this.props.onChange!(value)
  }
}

/**
 * UdNumberRange 验证器
 * 目前不需要传入参数，后期可能会根据需求增加参数
 */
export let UdNumberRangeValidator = (): any => {
  return (item: IUdFormItem): ValidationRule => {
    return {
      validator: (rule: any, value: any, callback: any, source?: any, options?: any) => {
        if (value && value[0] != null && value[1] != null) {
          if (value[0] > value[1]) {
            callback('开始值不能大于结束值')
            return
          }
        }
        callback()
      }
    }
  }
}

export interface IUdNumberRangeProps extends Partial<IUdFormItem> {
  begin?: InputNumberProps
  end?: InputNumberProps
}

export interface IUdNumberRangeState {
  begin?: number
  end?: number
}

export { UdNumberRange }
