// import Watermark, { WatermarkConfig } from '@pansy/watermark';
import _, { isNumber } from 'lodash';
import { RGBColor } from 'react-color';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

/* export const getWaterMark = (options: WatermarkConfig) => {
  const defaultOptions = {
    mode: 'interval',
    monitor: true,
    opacity: 0.15,
    width: 120,
    height: 64,
    offsetLeft: 0,
    offsetTop: 0,
    gapX: 100,
    gapY: 100,
    zIndex: 999,
    rotate: -22,
    fontSize: 16,
    textAlign: 'center',
    fontStyle: 'normal',
    fontColor: '#aaa',
    fontFamily: 'sans-serif',
    fontWeight: '500'
  };
  return new Watermark(Object.assign(defaultOptions, options));
}; */

/**
 * 数字千分位
 * @param num
 * @returns
 */
export const formatNumber = num => {
  return (Number.isInteger(num) ? num.toFixed(2) : num).toString().replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
};

/**
 * 数字千分位（不固定小数位数）
 * @param num
 * @returns
 */
export const toThousands = num => {
  const formatter = new Intl.NumberFormat();
  return formatter.format(num);
};

/**
 * 图表类数值格式化
 * @param num
 * @returns
 */
export const formatEchartsValue = num => {
  let isNegativeNum = false;
  let formattedNum;
  if (num < 0) {
    isNegativeNum = true;
    num = +num.toString().slice(1);
  }
  // 仪表板数字小数位数控制在2位
  if (num >= 10000 && num < 100000000) {
    formattedNum = toThousands((num / 10000).toFixed(2)) + '万'
    // formattedNum = `${num / 10000}万`;
  } else if (num >= 100000000) {
    formattedNum = toThousands((num / 100000000).toFixed(2)) + '亿'
    // formattedNum = `${num / 100000000}亿`;
  } else {
    formattedNum = num;
  }
  return isNegativeNum ? '-' + formattedNum : formattedNum;
};

/**
 * 图表类数值格式化保留小数位两位
 * @param num
 * @returns
 */
export const formatEchartsValueWithDecimal = num => {
  if (num >= 10000 && num < 100000000) {
    return toThousands((num / 10000).toFixed(2)) + '万';
  } else if (num >= 100000000) {
    return toThousands((num / 100000000).toFixed(2)) + '亿';
  }
  return num;
};

/**
 * 生成uuid
 */
export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const parseQuery: (qs: string) => { [prop: string]: any } = qs => {
  const query = {};
  const pairs = (qs[0] === '?' ? qs.substr(1) : qs).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

/**
 * 函数防抖
 * @param {*} func
 * @param {*} wait
 * @param {*} params // 传递给fn的参数
 */
export const debounce = (() => {
  let timer = null;
  return (fn, duration) =>
    function (...args) {
      if (typeof timer === 'number') clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
        timer = null;
      }, duration);
    };
})();

/**
 * 将rgba字符串对象转化为rgba对象
 * @param rgba
 */
export const rgba2Obj = (rgba = '') => {
  let reg = /rgba\((\d+),(\d+),(\d+),([01]\.?\d*)\)/g;
  let rgbaObj: RGBColor = { r: 0, g: 0, b: 0, a: 0 };

  rgba.replace(reg, (_m, r, g, b, a) => {
    rgbaObj = { r, g, b, a };
    return rgba;
  });
  return rgbaObj;
};

export const hexToRGBA = hex => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
  }
  throw new Error('Bad Hex');
};

export const isHexColor = str => /^#([0-9a-f]{3}){1,2}$/i.test(str);

export const hexColorToRGBA = (hex, alpha) => {
  const rgb = []; // 定义rgb数组
  if (/^\#[0-9A-F]{3}$/i.test(hex)) {
    // 判断传入是否为#三位十六进制数
    let sixHex = '#';
    hex.replace(/[0-9A-F]/gi, function (kw) {
      sixHex += kw + kw; // 把三位16进制数转化为六位
    });
    hex = sixHex; // 保存回hex
  }
  if (/^#[0-9A-F]{6}$/i.test(hex)) {
    // 判断传入是否为#六位十六进制数
    hex.replace(/[0-9A-F]{2}/gi, function (kw) {
      // eslint-disable-next-line no-eval
      rgb.push(eval('0x' + kw)); // 十六进制转化为十进制并存如数组
    });
    return `rgba(${rgb.join(',')},${alpha / 100})`; // 输出RGB格式颜色
  } else {
    return 'rgb(0,0,0)';
  }
};

export const replaceAlpha = (color, alpha) => {
  return color.replace(/[\d\.]+\)$/g, `${alpha})`);
};

/**
 * 图表类数据转换
 * {a: [1, 2, 3], b: [4, 5, 6]} => [{ a: 1, b: 4 }, { a: 2, b: 5 }, { a: 3, b: 6 }]
 * @param data
 */
export const dataConvert = data => {
  let _tempArr = [];
  for (let [key, value] of Object.entries(data)) {
    if (_.isArray(value)) {
      const size = value.length;
      _tempArr = !_tempArr.length ? new Array(size).fill(null) : _tempArr;
      _tempArr = _tempArr.map((item, index) => {
        if (_.isObject(item)) {
          return {
            ...item,
            [key]: value[index]['dislayValue']
          };
        }
        return {
          [key]: value[index]['displayValue']
        };
      });
    }
  }
  return _tempArr;
};

/**
 * 匹配元字符串是否包含给定的字符（不区分大小写）
 *
 * @param source 元字符串
 * @param search 需要匹配的字符串
 * @return true 包含 false 不包含
 */
export const containsIgnoreCase = (source: string, search: string): boolean => {
  if (!source || !search) {
    return false;
  }
  return contains(source.toUpperCase(), search.toLocaleUpperCase());
};

/**
 * 匹配元字符串是否包含给定的字符（区分大小写）
 *
 * @param source 元字符串
 * @param search 需要匹配的字符串
 * @return true 包含 false 不包含
 */
export const contains = (source: string, search: string): boolean => {
  if (!source || !search) {
    return false;
  }
  return source.indexOf(search) > -1;
};

/**
 * 按照给定的分隔符分割字符串
 * @param source 需要分割的字符串
 * @param delimiter 分隔符
 * @returns 分割之后的字符串
 */
export const splitString = (source: string, delimiter: string): Array<string> => {
  if (!source || !delimiter) {
    return [source];
  }
  return source.split(delimiter);
};

/**
 * 判断数字是否为小数
 * @param num
 */
export const isDecimal = num => {
  return isFinite(num) && parseFloat(num) === num && num % 1 !== 0;
};

/**
 * 格式化数字(单位: 万 or 亿)
 * @param num
 */
export const formatNumber1 = num => {
  if (num >= 100000000) {
    return `${parseFloat((num / 100000000).toFixed(2))}亿`;
  } else if (num >= 10000) {
    return `${parseFloat((num / 10000).toFixed(2))}万`;
  }
  return num;
};

/**
 * 格式化数字(指定单位)
 * @param num
 * @param unit
 * @returns
 */
export const formatNumber2 = (num, unit = '') => {
  let units = ['', '千', '万', '百万', '千万', '亿'];
  let index = units.indexOf(unit);
  if (index === -1) return num;
  let digits = [0, 3, 4, 6, 7, 8];
  let factor = Math.pow(10, digits[index]);
  let formatted = parseFloat((num / factor).toFixed(2)) + unit;
  return formatted;
};

/**
 * 反转字符串
 * @param str
 */
export const reverseStr = (str, separator = '') => {
  return str.split(separator).reverse().join(separator);
};

/**
 * 数组是否有重复项
 * @param arr
 */
export const hasDuplicates = arr => {
  return new Set(arr).size !== arr.length;
};
