import { ReactNode } from 'react';
import { ColumnProps, TableProps, SortOrder } from 'antd/lib/table';
import { Modify } from '.';

export type IUdTableProps<T> = Modify<
  TableProps<T>,
  {
    /** 水印配置 */
    watermark?: ITableWatermark;
    columns?: IUdColumn<T>[];
    sortValues?: {
      key: string;
      value: SortOrder | boolean;
    }[];
    // 表格禁止勾选项
    getCheckboxProps?: (record: any) => void;
  }
>;

export type IUdColumn<T = any> = IColumnStandard<T> | IUdColumnConcise<T>;

export type IUdColumnConcise<T> = [
  ReactNode,
  string,
  ((text: any, record: T, index: number) => React.ReactNode)?,
  Partial<IColumnStandard<T>>?
];

export interface IColumnStandard<T = any> extends ColumnProps<T> {
  /** 最小宽度，优先级低于 fixedWidth 中的 min-width */
  minWidth?: string;

  /** 最大宽度，优先级低于 fixedWidth 中的 max-width */
  maxWidth?: string;

  /**
   * 固定宽度，min-width 和 max-width
   */
  fixedWidth?: string;

  /** 样式，优先级高于 fixedWidth、minWidth和maxWidth */
  style?: React.CSSProperties;

  /** 是否在配置列中显示，默认为true */
  allowCustomize?: boolean;

  // className?: string | ((text: any, record: any, index: number) => string)
}

export interface IColumnActions {
  title: string | ReactNode;
  icon?: string;
  enable?: boolean;
  disabled?: boolean;
  auth?: string | ((text: any, model: any, index: number) => boolean);
  show?: (text: any, model: any, index: number) => boolean;
  action: (text: any, model: any, index: number) => any;
}

export enum Actions {
  Modal = 'Modal'
}

export interface ITableWatermark {
  enable: boolean;
  color: string;
  opacity: number;
  fontSize: number;
  angle: number;
  text: (() => string) | string;
  width: number;
  height: number;
  x: number;
  y: number;
}
