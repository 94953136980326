import _ from 'lodash'
import React, { Component, ReactNode } from 'react'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { Select } from 'antd'
import { AxiosPromise } from 'axios'
import { IUdFormItem, Modify } from '../../..'
import { SelectProps } from 'antd/lib/select'

const Option = Select.Option
class UdSelectAjax extends Component<IUdSelectAjaxProps, IUdSelectAjaxState> {

  constructor(props: IUdSelectAjaxProps) {
    super(props)
    this.state = {
      items: []
    }
  }

  componentDidMount() {
    this.query()
  }

  render() {
    let mapping = this.props.mapping
    let items = this.state.items
    let nodes = []
    if (_.isArray(items) && items.length > 0) {
      let func = ((value: any, index: number, array: any[]) => {
        return <Option key={value.value} value={value.value}>{value.title}</Option> as ReactNode
      })
      if (_.isFunction(mapping)) {
        func = mapping
      } else if (_.isObject(mapping)) {
        func = ((value: any, index: number, array: any[]) => {
          return <Option key={value[(mapping as any).value]} value={value[(mapping as any).value]}>{value[(mapping as any).title]}</Option> as ReactNode
        })
      }
      items.forEach((value, index, array) => {
        nodes.push(func(value, index, array))
      })
    }

    return (
      <Select onChange={this.props.onChange} value={this.props.value}
        className="ud-select-ajax" {...this.props}>
        {nodes}
      </Select>
    )
  }

  private query() {
    let func = (res: any) => {
      console.log(res)
      let items = res.data
      if (_.isFunction(this.props.transformResponse)) {
        items = this.props.transformResponse(res)
      }
      this.setState({ items })
    }
    if (_.isFunction(this.props.query)) {
      (this.props.query() as any).then(func)
   
    } else {
      (this.props.query as any).then(func)
    }
  }
}

interface IUdSelectAjaxProps extends Partial<IUdFormItem>, SelectProps {
  id?: string
  value?: any
  onChange?(value: any): void

  /** 数据查询 */
  query: (() => (Promise<any> | AxiosPromise<any>)) | (Promise<any> | AxiosPromise<any>)
  /** 响应数据的转换 */
  transformResponse?: (res: any) => any[]
  /** 
   * 用于把数据映射成Select.Option的方法 
   * 默认使用 title和value字段
   */
  mapping?: ((value: any, index: number, array: any[]) => ReactNode) | { title: string, value: string }
}

interface IUdSelectAjaxState {
  items?: any
}

export default UdSelectAjax
