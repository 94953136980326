import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import _ from 'lodash';
import { DATE_FORMAT_TYPES, WEEK_FORMAT_TYPES } from './enum';
import { DEFAULT_CUR_TIME_STYLE } from '@/components/element/element';
dayjs.locale('zh-cn');

function CurTime({ option }) {
  const [time, setTime] = useState<any>(
    dayjs().format(
      [DATE_FORMAT_TYPES[option.styleConfig.dateFormat], WEEK_FORMAT_TYPES[option.styleConfig.weekFormat]].join(' ')
    )
  );
  const [style, setStyle] = useState<any>({
    bg: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      background: 'rgba(255, 255, 255, 0)'
    },
    ...DEFAULT_CUR_TIME_STYLE
  });
  const format = useMemo(() => {
    const { dateFormat, weekFormat } = option.styleConfig;
    return weekFormat
      ? [DATE_FORMAT_TYPES[dateFormat], WEEK_FORMAT_TYPES[weekFormat]].join(' ')
      : DATE_FORMAT_TYPES[dateFormat];
  }, [JSON.stringify(option.styleConfig)]);

  useEffect(() => {
    let timer = null;
    if (timer) clearInterval(timer);
    timer = setInterval(() => {
      setTime(dayjs().format(format));
    }, 1000);
    initStyle();

    return () => {
      clearInterval(timer);
    };
  }, [JSON.stringify(option.styleConfig)]);

  const initStyle = () => {
    const newStyle = _.cloneDeep(style);
    if (option.styleConfig) {
      // bg
      newStyle.bg.background = option.styleConfig.backgroundColor;
      // text
      newStyle.hPosition = option.styleConfig.hPosition;
      newStyle.fontSize = option.styleConfig.fontSize;
      newStyle.color = option.styleConfig.color;
      newStyle.fontStyle = option.styleConfig.fontStyle;
      newStyle.fontWeight = option.styleConfig.fontWeight;
      // dateFormat weekFormat
      newStyle.dateFormat = option.styleConfig.dateFormat;
      newStyle.weekFormat = option.styleConfig.weekFormat;
    }
    setStyle({
      ...style,
      ...newStyle
    });
  };

  return (
    <div style={style.bg}>
      <p
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: style.hPosition || 'center',
          fontSize: style.fontSize + 'px',
          color: style.color,
          fontStyle: style.fontStyle,
          fontWeight: style.fontWeight
        }}
      >
        {time}
      </p>
    </div>
  );
}

export default CurTime;
