import React, { Suspense } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { Spin } from 'antd';
import { http } from '@/lib';
import APIS from '@/apis/api';
import { getUserProfile } from '@/utils/userInfo';
import useStorage from '@/utils/useStorage';
import routes, { NotFound } from './config';

export default () => {
  const { username } = getUserProfile();
  const [_, setDataPermission] = useStorage(`${username}ModuleDataPermission`);
  const guardHander = (to, from, next) => {
    if (to.meta.isRefreshDataPermission) {
      http.post(APIS.DataPermissionUserBind, { type: to.meta.moduleType }).then(res => {
        setDataPermission(JSON.stringify(res.data));
        next();
      });
    } else if (to.meta.redirect) {
      next.redirect('/home');
    } else {
      next();
    }
  };
  return (
    <Suspense
      fallback={
        <div style={{ width: '100vw', height: '100vh', textAlign: 'center', lineHeight: '100vh' }}>
          <Spin tip="加载中..." />
        </div>
      }
    >
      <GuardProvider guards={[guardHander]}>
        <Switch>
          {routes.map(({ component: Component, path, exact, isRefreshDataPermission, moduleType }) => (
            <GuardedRoute
              key={path}
              path={path}
              exact={exact}
              component={Component}
              meta={{ isRefreshDataPermission, moduleType }}
            />
          ))}
          <GuardedRoute path="/" exact meta={{ redirect: true }} />
          <GuardedRoute path="*" component={NotFound} />
        </Switch>
        {/* <Switch>
        {routes.map(({ component: Component, path, exact, isRefreshDataPermission, moduleType }) => (
          <Route
            key={path}
            path={path}
            exact={exact}
            render={routeProps => {
              if (isRefreshDataPermission) {
                refreshDataPermission(moduleType);
              }
              return <Component {...routeProps} />;
            }}
          />
        ))}
        <Redirect to="/home" />
        <Route path="*" component={NotFound} />
      </Switch> */}
      </GuardProvider>
    </Suspense>
  );
};
