import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { toDouble } from '@/utils/methods';
import {
  GRID_LAYOUT_BREAK_POINTS,
  SWAPPED_API_CHART_TYPE,
  CALCULATOR_TYPES,
  CONTRAST_TYPES,
  CONTRAST_VALUE_TYPES,
  DRILL_DOWN_CHARTS
} from '@/utils/enum';
import { getDataConfig } from './chart-type';

dayjs.extend(quarterOfYear);
export const calGridLayoutBreakPoint = (mode = 'edit') => {
  let breakPoint;
  const screenWidth = window.screen.width;
  const canvasContainerWidth = screenWidth - (mode === 'edit' ? 380 : 0);
  const breakPoints = ['lg', 'md', 'sm', 'xs', 'xxs'];
  for (let i = 0; i < breakPoints.length; i++) {
    if (canvasContainerWidth >= GRID_LAYOUT_BREAK_POINTS[breakPoints[i]].width) {
      breakPoint = breakPoints[i];
      break;
    }
  }
  return breakPoint;
};

export const modFieldUniqueIdFn = field => ({ ...field, uniqueId: field.uniqueId + '-clone' });

export const replaceFieldUniqueIdFn = field => ({ ...field, uniqueId: field.uniqueId.replace('-clone', '') });

export const convertChartsHandler = charts => {
  return charts.map(chart => {
    const {
      chartsConfig,
      chartsType,
      dataSetId,
      dimensionFields,
      measureFields,
      filters,
      compareField,
      dataMaxLengthLimit
    } = chart;
    const {
      layout,
      mobileTileLayout,
      mobileConfig,
      styleConfig,
      advancedConfig,
      drillDownConfig,
      branchTag,
      drillDownFields,
      yaxis,
      yaxisExt,
      extraConfigFields,
      extraConfigFieldMap,
      freshTime,
      order
    } = JSON.parse(chartsConfig);
    const chartType = SWAPPED_API_CHART_TYPE[chartsType];
    const filterHashMap = {};
    if (filters.length) {
      filters.forEach(condition => {
        if (filterHashMap[condition.fieldUniqueId]) {
          filterHashMap[condition.fieldUniqueId].push(condition);
        } else {
          filterHashMap[condition.fieldUniqueId] = [condition];
        }
      });
    }
    const filterFields = Object.keys(filterHashMap).map(key => {
      const {
        fieldUniqueId,
        dateFilterType,
        intervalType,
        frontAdditionalInfo: {
          type,
          valueType,
          dateFormat,
          customFieldFunction,
          fieldCustomName,
          isDerivedField,
          granularity
        }
      } = filterHashMap[key][0];
      const filterField: any = {
        uniqueId: fieldUniqueId,
        customName: fieldCustomName,
        attribute: {
          type,
          valueType,
          dateFormat,
          customFieldFunction,
          granularity
        }
      };
      if (isDerivedField) {
        filterField.complexFilter = {
          dateFilterType,
          intervalType,
          filters: filterHashMap[key]
        };
      }
      return filterField;
    });
    const dataConfig: any = {
      ...getDataConfig(chartType),
      status: dataSetId ? 'getChartData' : 'intoCanvas',
      dataSetId: dataSetId ? dataSetId : undefined,
      filterFields: filterFields.map(modFieldUniqueIdFn),
      filters,
      filterHashMap,
      compareField,
      dataMaxLengthLimit,
      branchTag,
      extraConfigFields,
      extraConfigFieldMap,
      freshTime
    };
    if (DRILL_DOWN_CHARTS.includes(chartType)) {
      dataConfig.drillDownFields = drillDownFields;
    }
    if (chartType.includes('map') && chartType !== 'treemap') {
      const {
        mapRange: { name, code }
      } = styleConfig;
      dataConfig.mapName = name;
      dataConfig.adcode = code;
      dataConfig.drillPathAdcodes = [`${name}-${code}`];
    }
    if (chartType === 'combination') {
      dataConfig.xaxis = dimensionFields.filter(item => item.fieldType === 'MAIN_DIMENSION');
      dataConfig.legend = dimensionFields.filter(item => item.fieldType === 'ASSISTANT_DIMENSION');
      dataConfig.yaxis = measureFields
        .filter(item => item.fieldType === 'MAIN_MEASURE')
        .map(item => {
          const { chartType } = yaxis.filter(field => resolveFieldCaption(field) === resolveFieldCaption(item))[0];
          return {
            ...item,
            chartType
          };
        })
        .map(modFieldUniqueIdFn);
      dataConfig.yaxisExt = measureFields
        .filter(item => item.fieldType === 'ASSISTANT_MEASURE')
        .map(item => {
          const { chartType } = yaxisExt.filter(field => resolveFieldCaption(field) === resolveFieldCaption(item))[0];
          return {
            ...item,
            chartType
          };
        })
        .map(modFieldUniqueIdFn);
    } else {
      dataConfig.dimensionFields = Array.isArray(dimensionFields)
        ? dimensionFields.map(modFieldUniqueIdFn)
        : dimensionFields;
      dataConfig.measureFields = measureFields.map(modFieldUniqueIdFn);
    }
    if (!dataConfig.dimensionFields) {
      delete dataConfig.dimensionFields;
    }
    return Object.assign(
      {},
      {
        order,
        frontChartId: layout.i,
        chartType,
        layout,
        mobileTileLayout,
        mobileConfig,
        dataConfig,
        styleConfig,
        advancedConfig: {
          ...advancedConfig,
          linkageConfig: {
            ...advancedConfig.linkageConfig,
            linkageWorkByClick: false
          }
        },
        drillDownConfig
      },
      chart.id ? { id: chart.id } : {}
    );
  });
};

export const convertValFormat = (val, granularity) => {
  const _dayjs = dayjs(val);
  const year = _dayjs.year();
  const quarter = _dayjs.quarter();
  const month = _dayjs.month() + 1;
  const date = _dayjs.date();
  switch (granularity) {
    case 'year':
      return '' + year;
    case 'month':
      return `${year}${toDouble(month)}`;
    case 'quarter': {
      return `${year}Q${quarter}`;
    }
    case 'day':
      return `${year}${toDouble(month)}${toDouble(date)}`;
  }
};

export const resolveFieldCaption = field => {
  let res = '';
  const {
    attribute: { type },
    customName,
    calculatorType,
    ratioCalculator
  } = field;
  if (type === 'DIMENSION') {
    res = customName;
  } else {
    res = `${customName}(${CALCULATOR_TYPES[calculatorType]}${
      ratioCalculator ? '-' + CONTRAST_TYPES[ratioCalculator.type] : ''
    })`;
  }
  return res;
};

export const measureFieldCaption = field => {
  const { customName, calculatorType, ratioCalculator } = field;
  let result = `${customName}_${CALCULATOR_TYPES[calculatorType]}`;
  if (ratioCalculator) {
    result += `_${CONTRAST_TYPES[ratioCalculator.type]}_${CONTRAST_VALUE_TYPES[ratioCalculator.valueType]}`;
  }
  return result;
};

export const measureFieldCaptionExcludeCalculatorType = field => {
  const { customName, ratioCalculator } = field;
  let result = customName;
  if (ratioCalculator) {
    result += `_${CONTRAST_TYPES[ratioCalculator.type]}_${CONTRAST_VALUE_TYPES[ratioCalculator.valueType]}`;
  }
  return result;
};

export const groupSeriesByChartType = series => {
  const result = {};
  series.forEach(item => {
    const { type } = item;
    if (result[type]) {
      result[type].push(item);
    } else {
      result[type] = [item];
    }
  });
  return result;
};

export const checkDuplicate = fields => {
  const fieldCaptions = fields.map(field => resolveFieldCaption(field));
  let result = false;
  const s = new Set(fieldCaptions);
  if (fieldCaptions.length !== s.size) {
    result = true;
  }
  return result;
};

export const getFieldsCount = fields => {
  return Number(Array.isArray(fields) && fields.length);
};

export const parseFieldValFormatPattern = pattern => {
  const patternRE = /^(#,?##)?0(\.(0{1,10})|)(%?)$/;
  const execedPattern = patternRE.exec(pattern);
  return {
    percent: execedPattern[4] ? execedPattern[3].length : 0,
    numberType: execedPattern[4] ? 'percent' : 'number',
    number: execedPattern[4] ? 0 : execedPattern[2] ? execedPattern[3].length : 0,
    thousands: execedPattern[1].includes(',')
  };
};

export const calculateFieldVid = field => {
  const { uniqueId, calculatorType, ratioCalculator } = field;
  let vid = `${uniqueId}|${calculatorType}|`;
  if (ratioCalculator) {
    vid += `${ratioCalculator.type}|${ratioCalculator.valueType}`;
  } else {
    vid += '|';
  }
  return vid;
};

export const calculateExtraConfigFieldCount = fields => {
  const fieldCountMap = {};
  fields.forEach(field => {
    const { uniqueId, contrastType, contrastValueType } = field;
    let key = uniqueId;
    if (contrastType) {
      key += `_${contrastType}_${contrastValueType}`;
    }
    if (fieldCountMap[key]) {
      fieldCountMap[key] += 1;
    } else {
      fieldCountMap[key] = 1;
    }
  });
  return fieldCountMap;
};

/**
 * 生成表关联配置
 */
export const genLinkageConfig = () => {
  return {
    hasLinkageParamCheck: false,
    linkageWithDrill: {
      enable: true
    },
    linkageWorkByClick: false,
    relationChart: {},
    dataSetFieldMap: {}
  };
};

/**
 * 生成辅助线配置
 * @returns
 */
export const genAuxiliaryLine = index => {
  return {
    id: uuidv4(),
    name: `辅助线${index}`,
    lineStyle: 'solid', // solid | dashed | dotted
    lineColor: 'rgba(51,70,124,1)',
    type: 'fixed', // fixed | calculated
    location: '0',
    value: undefined,
    fieldId: '',
    calculatedType: 'average'
  };
};

/**
 * 生成过滤条件
 * @param widget
 * @param value
 * @returns
 */
export const genFilterConditionByWidget = (widget, value) => {
  const {
    options: {
      attrs: { fieldUniqueId, multiple }
    },
    name,
    widgetId
  } = widget;
  const replaceSeparator = str => str.replaceAll('-', '');
  const genBaseCondition = () => ({
    id: uuidv4(),
    fieldUniqueId,
    conditionJoin: 'AND',
    frontAdditionalInfo: {
      widgetId
    }
  });

  switch (name) {
    case 'timeYearWidget':
    case 'timeMonthWidget':
    case 'timeDateWidget':
      return {
        ...genBaseCondition(),
        operator: 'EQUALS',
        values: value ? [value].map(replaceSeparator) : []
      };
    case 'timeDateRangeWidget':
      return {
        ...genBaseCondition(),
        operator: 'BETWEEN',
        values: value ? value.split(',').map(replaceSeparator) : []
      };
    case 'textSelectWidget':
    case 'textSelectGridWidget':
    case 'textSelectTreeWidget':
    case 'numberSelectWidget':
    case 'numberSelectGridWidget':
      return {
        ...genBaseCondition(),
        operator: multiple ? 'IN' : 'EQUALS',
        values: value.split(',')
      };
    case 'numberRangeWidget':
      return {
        ...genBaseCondition(),
        operator: 'BETWEEN',
        values: value.split(',')
      };
    case 'textInputWidget':
      return {
        ...genBaseCondition(),
        operator: 'EQUALS',
        values: [value]
      };
  }
};

/**
 * 生成图表过滤条件映射表
 * @param widgets
 * @returns
 */
export const genChartConditionsMap = (widgets, widgetConditionMap) => {
  const chartConditionsMap = {};
  widgets.forEach(widget => {
    const {
      options: {
        attrs: { enableRange, chartIds }
      },
      widgetId
    } = widget;
    const condition = widgetConditionMap[widgetId];
    if (enableRange && chartIds.length) {
      chartIds.forEach(chartId => {
        if (!chartConditionsMap[chartId]) {
          chartConditionsMap[chartId] = [condition];
        } else {
          chartConditionsMap[chartId].push(condition);
        }
      });
    }
  });
  return chartConditionsMap;
};

export const genWidgetConditionMap = widgets => {
  const widgetConditionMap = {};
  widgets.forEach(widget => {
    const {
      widgetId,
      options: { value }
    } = widget;
    widgetConditionMap[widgetId] = genFilterConditionByWidget(widget, value);
  });
  return widgetConditionMap;
};

/**
 * 过滤按钮组件
 * @param itemWidget
 * @returns
 */
export const filterOutWidgetBtn = itemWidget => !itemWidget.component.includes('button');

/**
 * 过滤组件控制范围为空
 * @param widget
 * @returns
 */
export const isWidgetRangeEmpty = widget => {
  return widget.options.attrs.chartIds.length === 0;
};

/**
 * 画布区是否存在确认按钮组件
 * @param store
 * @returns
 */
export const hasWidgetSureBtn = store => {
  return store.state.customComps.some(comp => comp.component === 'ib-button');
};

/**
 * 包含空字符串
 * @param element
 * @returns
 */
export const containEmptyStr = element => element === '';

/**
 * 更新条件映射表
 * @param param0
 * @returns
 */
export const updateConditionMapByWidget = ({ itemComp, widgetConditionMap, dashboardStore }) => {
  const {
    options: {
      attrs: { chartIds },
      value
    },
    widgetId
  } = itemComp;
  const filterCondition = genFilterConditionByWidget(itemComp, value);
  const newWidgetConditionMap = _.cloneDeep(widgetConditionMap);
  if (!newWidgetConditionMap[widgetId]) {
    newWidgetConditionMap[widgetId] = filterCondition;
  } else {
    newWidgetConditionMap[widgetId] = {
      ...filterCondition,
      id: newWidgetConditionMap[widgetId].id
    };
  }
  const newChartConditionsMap = genChartConditionsMap(
    dashboardStore.state.customComps.filter(filterOutWidgetBtn),
    newWidgetConditionMap
  );
  return {
    newWidgetConditionMap,
    newChartConditionsMap
  };
};

/**
 * 生成图表类坐标轴样式配置(仅支持趋势类、比较类图表和散点图)
 * @returns
 */
export const genAxisConfig = () => ({
  xAxis: {
    showTitleAndUnit: true,
    title: '',
    unit: '',
    titleFontStyle: {
      fontSize: 12,
      fontColor: '#333',
      bold: false,
      italic: false
    }
  },
  yAxis: {
    showTitleAndUnit: true,
    title: '',
    unit: '',
    titleFontStyle: {
      fontSize: 12,
      fontColor: '#333',
      bold: false,
      italic: false
    }
  },
  y2Axis: {
    showTitleAndUnit: true,
    title: '',
    unit: '',
    titleFontStyle: {
      fontSize: 12,
      fontColor: '#333',
      bold: false,
      italic: false
    }
  }
});
