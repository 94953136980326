export const BASE_TABLE = {
  series: [
    {
      type: 'table',
      data: []
    }
  ]
};

export const MULTI_DIMENSIONAL_TABLE = {
  series: [
    {
      type: 'tableMultidimensional',
      data: []
    }
  ]
};
