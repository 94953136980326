import React, { useState, useEffect, useMemo } from 'react';
import { Input, Checkbox, Radio } from 'antd';
import _ from 'lodash';
import useLocale from '@/utils/useLocale';
import locale from '../locale';
import { http } from '@/lib';
import APIS from '@/apis/api';
import './style/ib-select-grid.less';
import { IProps } from './data';

const { Search } = Input;
const groupItemStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
};
const initialState = () => {
  return {
    checkedList: [],
    indeterminate: false,
    checkAll: false,
    value: ''
  };
};

function IbSelectGrid(props: IProps) {
  const { inDraw, widget, defaultValue, onDataOptsChange, onCheckedValChange } = props;
  const t = useLocale(locale);
  const [keyword, setKeyword] = useState('');
  const [state, setState] = useState(initialState());
  const options = useMemo(() => {
    return widget.options.attrs.dataOpts;
  }, [JSON.stringify(widget.options.attrs.dataOpts)]);
  const isMultiple = useMemo(() => {
    return widget.options.attrs.multiple;
  }, [widget.options.attrs.multiple]);

  useEffect(() => {
    initState();
  }, [defaultValue, JSON.stringify(options)]);

  useEffect(() => {
    if (!widget.refresh) return;
    initState();
  }, [widget.refresh]);

  const initState = () => {
    const newState = _.cloneDeep(state);
    if (isMultiple) {
      newState.checkedList = !defaultValue ? [] : defaultValue.split(',');
      newState.checkAll = options.length === newState.checkedList.length;
      newState.indeterminate = !!newState.checkedList.length && newState.checkedList.length < options.length;
    } else {
      newState.value = defaultValue;
    }
    setState({
      ...state,
      ...newState
    });
  };

  useEffect(() => {
    if (inDraw || !widget.options.attrs.fieldUniqueId) return;

    fetchData();
  }, [widget.options.attrs.fieldUniqueId]);

  const fetchData = () => {
    http
      .post(APIS.GetDataSetDataByFields, {
        id: widget.options.attrs.dataSetId,
        queryFields: {
          fieldUniqueIds: widget.options.attrs.fieldUniqueId,
          groupDuplicate: true
        },
        page: 1,
        size: 10000
      })
      .then(res => {
        const { content } = res.data;
        onDataOptsChange(
          content
            .map(item => Object.values(item))
            .flat(Infinity)
            .map(item => item.toString())
        );
      });
  };

  const reset = () => {
    setState(initialState());
  };

  const handleSearch = keyword => {
    setKeyword(keyword);
  };

  const toggleAll = ev => {
    setState({
      ...state,
      checkedList: ev.target.checked ? options : [],
      indeterminate: false,
      checkAll: ev.target.checked
    });
    onCheckedValChange && onCheckedValChange(ev.target.checked ? options : []);
  };

  const handleChange = checkedList => {
    setState({
      ...state,
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < options.length,
      checkAll: checkedList.length === options.length
    });
    onCheckedValChange && onCheckedValChange(checkedList);
  };

  const handleRadioGroupChange = ev => {
    setState({
      ...state,
      value: ev.target.value
    });
    onCheckedValChange && onCheckedValChange([ev.target.value]);
  };

  // console.log('render IbSelectGrid: ', widget, state);
  return (
    <div className="ib-select-grid-wrapper">
      <div className="ib-select-grid-search">
        <Search placeholder={t['ibInputSearch.placeholder']} allowClear onSearch={handleSearch} />
      </div>
      <div className="list">
        {isMultiple && (
          <div className="checkbox-group-container">
            <Checkbox
              indeterminate={state.indeterminate}
              checked={state.checkAll}
              onChange={toggleAll}
              style={groupItemStyle}
            >
              {t['commons.all']}
            </Checkbox>
            <Checkbox.Group onChange={handleChange} value={state.checkedList}>
              {options
                .filter(opt => !keyword || opt.includes(keyword))
                .map((opt, index) => (
                  <Checkbox key={`${opt}-${index}`} value={opt} style={groupItemStyle}>
                    {opt}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          </div>
        )}
        {!isMultiple && (
          <div className="radio-group-container">
            <Radio.Group value={state.value} onChange={handleRadioGroupChange}>
              {options
                .filter(opt => !keyword || opt.includes(keyword))
                .map((opt, index) => (
                  <Radio key={`${opt}-${index}`} value={opt} style={groupItemStyle}>
                    {opt}
                  </Radio>
                ))}
            </Radio.Group>
          </div>
        )}
      </div>
    </div>
  );
}

export default IbSelectGrid;
