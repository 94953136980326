export const BASE_FUNNEL = {
  title: {
    text: '',
    textStyle: {
      fontWeight: 'normal'
    }
  },
  grid: {
    containLabel: true
  },
  tooltip: {
    trigger: 'item',
    formatter: params => {
      const {
        name,
        marker,
        data: { displayValue }
      } = params;
      return `${marker} ${name}: ${displayValue}`;
    }
  },
  legend: {
    show: true,
    type: 'scroll',
    itemWidth: 10,
    itemHeight: 10,
    icon: 'rect',
    data: []
  },
  series: [
    {
      name: '',
      type: 'funnel',
      left: 'center',
      top: 30,
      bottom: 30,
      width: '100%',
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '100%',
      sort: 'descending',
      gap: 1,
      label: {
        position: 'inside',
        align: 'center',
        color: '#fff'
      },
      labelLayout: {
        hideOverlap: true
      },
      labelLine: {
        length: 10,
        lineStyle: {
          width: 1,
          type: 'solid'
        }
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1
      },
      data: []
    }
  ]
};
