import React, { useState, useEffect, useContext, useRef } from 'react';
import cls from 'classnames';
import _ from 'lodash';
import { ReactSortable } from 'react-sortablejs';
import { Breadcrumb, Input, message, Spin } from 'antd';
import { http } from '@/lib';
import APIS from '@/apis/api';
import { WidgetFilterContext } from './ctx';
import { FIELD_VALUE_TYPE_ICONS, GRANULARITY_TYPE_ICONS } from '@/utils/enum';
import { convertDataSetFields } from './utils';
import { getService } from '@/components/widget/utils';
import SvgIcon from '@/components/SvgIcon';

const { Search } = Input;

function TabAssembly() {
  const { widget, setWidget, charts } = useContext(WidgetFilterContext);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('comp');
  const [activeComp, setActiveComp] = useState(null);
  const [inputCompValue, setInputCompValue] = useState('');
  const [inputFieldValue, setInputFieldValue] = useState('');
  const [comps, setComps] = useState(charts);
  const [fields, setFields] = useState([]);
  const [compFieldMap, setCompFieldMap] = useState(new Map());
  const originalDataSetFields = useRef([]);

  useEffect(() => {
    setComps(
      !inputCompValue
        ? charts
        : charts.filter(chart => chart.styleConfig.title.toLowerCase().includes(inputCompValue.toLowerCase()))
    );
  }, [inputCompValue]);

  useEffect(() => {
    setFields(
      !inputFieldValue
        ? originalDataSetFields.current
        : originalDataSetFields.current.filter(field =>
            field.customName.toLowerCase().includes(inputFieldValue.toLowerCase())
          )
    );
  }, [inputFieldValue]);

  useEffect(() => {
    if (!activeComp) return;
    if (activeComp.dataConfig.dataSetFields.length > 0 || compFieldMap.has(activeComp.frontChartId)) {
      const widgetInstance = getService(widget.serviceName);
      const newFields =
        activeComp.dataConfig.dataSetFields.length > 0
          ? widgetInstance.filterField(activeComp.dataConfig.dataSetFields)
          : compFieldMap.get(activeComp.frontChartId);
      setFields(newFields);
      originalDataSetFields.current = newFields;
      setType('field');
      if (!compFieldMap.has(activeComp.frontChartId)) {
        compFieldMap.set(activeComp.frontChartId, newFields);
      }
      return;
    }
    fetchDataSetFields();
  }, [activeComp]);

  const fetchDataSetFields = () => {
    const {
      dataConfig: { dataSetId },
      frontChartId
    } = activeComp;
    setLoading(true);
    http
      .post(APIS.DataBoardFindDataSetFields, { id: dataSetId })
      .then(res => {
        const widgetInstance = getService(widget.serviceName);
        const { dimensions, measures } = res.data;
        const newDataSetFields = convertDataSetFields([...dimensions, ...measures]);
        const newFields = widgetInstance.filterField(newDataSetFields);
        setFields(newFields);
        originalDataSetFields.current = newFields;
        setType('field');
        if (!compFieldMap.has(frontChartId)) {
          compFieldMap.set(frontChartId, newFields);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (type, value) => {
    setType(type);
    if (type === 'comp') {
      setInputCompValue(value);
    }
    if (type === 'field') {
      setInputFieldValue(value);
    }
  };

  const handleChartClick = chart => {
    setActiveComp(chart);
  };

  const handleBack = ev => {
    ev.preventDefault();
    setType('comp');
    setActiveComp(null);
  };

  const handleEnd = evt => {
    console.log('handleEnd: ', evt, evt.item.dataset.id);
    if (!evt.to.className.includes('filter-field')) return;
    if (widget.options.attrs.dragItems.length > 0) {
      message.warning('禁止同数据集多字段');
      return;
    }
    const newItem = fields.filter(item => item.uniqueId === evt.item.dataset.id)[0];
    const newWidget = _.cloneDeep(widget);
    const obj = {
      'options.attrs.dragItems': [...widget.options.attrs.dragItems, newItem],
      'options.attrs.fieldUniqueId': evt.item.dataset.id,
      'options.attrs.dataSetId': activeComp.dataConfig.dataSetId
    };
    Object.keys(obj).forEach(key => {
      _.set(newWidget, key, obj[key]);
    });
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  return (
    <div className="tab-assembly">
      <div className="tab-assembly-breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="" onClick={handleBack}>
              所有组件
            </a>
          </Breadcrumb.Item>
          {activeComp && <Breadcrumb.Item>{activeComp.styleConfig.title}</Breadcrumb.Item>}
        </Breadcrumb>
      </div>
      <div className="tab-assembly-search">
        {type === 'comp' && (
          <Search size="small" placeholder="搜索组件和字段" onSearch={value => handleSearch('comp', value)} />
        )}
        {type === 'field' && (
          <Search size="small" placeholder="搜索" onSearch={value => handleSearch('field', value)} />
        )}
      </div>
      <Spin spinning={loading} tip="正在取数中..." wrapperClassName="cube-loading-spin">
        {type === 'comp' && (
          <div className="tab-assembly-comps">
            {comps.map(chart => (
              <div className="item-comp" key={chart.frontChartId} onClick={() => handleChartClick(chart)}>
                <i className={cls(['component-type-icon', `component-type-${chart.chartType}`])}></i>
                <span className="chart-name">{chart.styleConfig.title}</span>
                <i className="svg-icon-wrapper">
                  <SvgIcon iconClass="ok-1" />
                </i>
              </div>
            ))}
          </div>
        )}
        {type === 'field' && (
          <div className="tab-assembly-field-list">
            <ReactSortable
              group={{
                name: 'source',
                pull: 'clone'
              }}
              sort={false}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              className="cube-field-list"
              filter=".checked"
              list={fields}
              setList={() => {}}
              onEnd={handleEnd}
              onMove={ev => !ev.related.classList.contains('checked')}
            >
              {fields.map(field => {
                const {
                  customName,
                  attribute: { type, valueType, granularity }
                } = field;
                const isDerivedField = valueType === 'DATE' && !!granularity;
                const isNormalField = !isDerivedField;
                return (
                  <div
                    key={field.uniqueId}
                    className={cls('item-field', {
                      checked: widget.options.attrs.dragItems.map(item => item.uniqueId).includes(field.uniqueId)
                    })}
                  >
                    <i className={cls(['svg-icon-wrapper', type.toLowerCase()])}>
                      <SvgIcon
                        iconClass={cls({
                          [GRANULARITY_TYPE_ICONS[granularity]]: isDerivedField,
                          [FIELD_VALUE_TYPE_ICONS[valueType]]: isNormalField
                        })}
                      />
                    </i>
                    {customName}
                  </div>
                );
              })}
            </ReactSortable>
          </div>
        )}
      </Spin>
    </div>
  );
}

export default TabAssembly;
