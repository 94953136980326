import { IColumnStandard } from '../../../types'

let udClassesCache: any = {}

let columnsClasses = {
  createElement: () => {
    const stylesContainer = document.getElementById('ud-style-container')
    if (!stylesContainer) {
      const styleContainer = document.createElement('style')
      styleContainer.id = 'ud-style-container'
      document.head.appendChild(styleContainer)
    }
  },
  createClasses: (item: IColumnStandard) => {
    let columnClasses: string[] = []
    if (item.minWidth) {
      const name = ('ud-table-min-width-' + item.minWidth)
      columnClasses.push(name)
      if (udClassesCache[name] == null) {
        udClassesCache[name] = (`.${name} { min-width:${item.minWidth}; box-sizing: content-box;}`)
      }
    }
    if (item.maxWidth) {
      const name = ('ud-table-max-width-' + item.maxWidth)
      columnClasses.push(name)
      if (udClassesCache[name] == null) {
        udClassesCache[name] = (`.${name} { max-width:${item.maxWidth}; word-break: keep-all; overflow-wrap: break-word; white-space: nowrap; box-sizing: content-box;}`)
      }
    }
    if (item.fixedWidth) {
      const name = ('ud-table-fixed-width-' + item.fixedWidth)
      columnClasses.push(name)
      if (udClassesCache[name] == null) {
        udClassesCache[name] = (`.${name} { width:${item.fixedWidth}; min-width:${item.fixedWidth}; max-width:${item.fixedWidth}; box-sizing: content-box;}`)
      }
    }
    return columnClasses
  },
  createStyles: () => {
    let udTableClassContent: string = ''
    for (let i in udClassesCache) {
      udTableClassContent += udClassesCache[i]
    }

    let stylesContainer = document.getElementById('ud-style-container')
    if (stylesContainer && udTableClassContent.length > 0 && stylesContainer.innerHTML != udTableClassContent) {
      stylesContainer.innerHTML = udTableClassContent
    }
  }
}

export { columnsClasses } 