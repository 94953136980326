import React, { useState, useEffect } from 'react';
import { DatePicker } from '@arco-design/web-react';
import '@arco-design/web-react/dist/css/arco.css';
import useLocale from '@/utils/useLocale';
import { getService } from '@/components/widget/utils';
import locale from '../locale';

const { YearPicker, MonthPicker, RangePicker } = DatePicker;
function IbDate({
  widgetId,
  className,
  inDraw,
  mode,
  element,
  readOnly,
  defaultValue,
  showTime,
  accuracy,
  onDateChange
}: any) {
  const t = useLocale(locale);
  const [values, setValues] = useState(null);

  useEffect(() => {
    const newValues = inDraw ? element.options.value : defaultValue;
    setValues(newValues && newValues.indexOf(',') > -1 ? newValues.split(',') : newValues);
  }, [defaultValue]);

  const handleChange = (dateStr, date) => {
    setValues(dateStr);
    if (mode === 'dateRange' && Array.isArray(dateStr) && dateStr.length === 1) return;
    onDateChange && onDateChange(Array.isArray(dateStr) ? dateStr.join(',') : dateStr);
  };

  const handleShortcutChange = shortcut => {
    const widgetInstance = getService(element.serviceName);
    const newValues = widgetInstance.formatShortValues(shortcut.value(), element);
    setValues(newValues);
    onDateChange && onDateChange(newValues.join(','));
  };

  // console.log('render IbDate: ', defaultValue, values);
  return (
    <>
      {mode === 'year' && (
        <YearPicker
          className={className}
          editable={false}
          disabled={readOnly}
          defaultValue={values}
          value={values}
          format="YYYY"
          placeholder={t['ibyear.placeholder']}
          onChange={handleChange}
        />
      )}
      {mode === 'month' && (
        <MonthPicker
          className={className}
          editable={false}
          disabled={readOnly}
          defaultValue={values}
          value={values}
          format="YYYY-MM"
          placeholder={t['ibyearmonth.placeholder']}
          onChange={handleChange}
        />
      )}
      {mode === 'date' && (
        <DatePicker
          className={className}
          editable={false}
          disabled={readOnly}
          defaultValue={values}
          value={values}
          format={showTime ? ['YYYY-MM-DD', accuracy].join(' ') : 'YYYY-MM-DD'}
          placeholder={t['ibdate.placeholder']}
          showTime={showTime}
          onChange={handleChange}
        />
      )}
      {mode === 'dateRange' && (
        <RangePicker
          className={className}
          editable={false}
          disabled={readOnly}
          mode="date"
          defaultValue={values}
          value={values}
          format={showTime ? ['YYYY-MM-DD', accuracy].join(' ') : 'YYYY-MM-DD'}
          placeholder={[t['ibdaterange.from.placeholder'], t['ibdaterange.to.placeholder']]}
          showTime={showTime}
          shortcutsPlacementLeft
          shortcuts={getService(element.serviceName)
            .shortcuts()
            .map(shortcut => ({
              ...shortcut,
              key: shortcut.text,
              text: t[shortcut.text]
            }))}
          onChange={handleChange}
          onSelect={handleChange}
          onSelectShortcut={handleShortcutChange}
        />
      )}
    </>
  );
}

export default IbDate;
