const opt = Object.prototype.toString;

export function isNumber(obj: any): obj is number {
  return opt.call(obj) === '[object Number]' && obj === obj;
}

/**
 * 判断 obj 是否为 {}
 * @param obj
 * @returns
 */
export function isEmptyObj(obj: any): boolean {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

/**
 * 判断数组是否存在负数项
 * @param arr
 * @returns
 */
export function isArrayIncludeNegativeItem(arr: Number[]): boolean {
  return arr.some(item => item < 0);
}
