import React, { memo, useMemo } from "react"
import { currentUserName } from '@/components/ModalDataAuth/helper';
import { renderToString } from "react-dom/server";

const WatermarkCon = (props: React.PropsWithChildren<{ fillColor?: string, fillOpacity?: number, fontSize?: number, width?: string, height?: string }>) => {
    const currentUserNameText = currentUserName()

    const svgUrl = useMemo(() => {
        const svgRes = (
            <svg xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={props.width || '150px'}
                height={props.height || '90px'}
                viewBox="0 0 20 100">
                <text x="-150" y="-90"
                    fill={props.fillColor || '#666'}
                    transform="rotate(-35 220 -220)"
                    fill-opacity={props.fillOpacity || '0.1'}
                    font-size={`${props.fontSize || '18'}px`}>
                    {currentUserNameText}
                </text>
            </svg>)
        return URL.createObjectURL(new Blob([renderToString(svgRes)], {
            type: 'image/svg+xml',
        }))
    }, [props]);

    return (<div className={'waterMaker'} style={{
        backgroundImage: `url(${svgUrl})`,
        position: 'absolute',
        width: '100%', // 整个水印页面的宽和高
        height: '100%',// 整个水印页面的宽和高
        pointerEvents: 'none',
        top: 0,
        left: 0,
        zIndex: 999,
    }}
    >
        {props.children}
    </div>)

}

export default memo(WatermarkCon)

