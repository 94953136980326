import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useStore } from '@/store/useStore';
import { ScreenWorkplaceCtx } from '../../context';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import cls from 'classnames';
import { message, Spin } from 'antd';
import * as echarts from 'echarts';
import { http } from '@/lib';
import axios from 'axios';
import APIS from '@/apis/api';
import { convertItemCompDataConfig } from '../../../utils';
import { dataConvert } from '@/utils/utils';
import { calculateExtraConfigFieldCount } from '@/pages/dashboard/workplace/util';
import { CALCULATOR_TYPES, CONTRAST_TYPES, CONTRAST_VALUE_TYPES } from '@/utils/enum';
import { getChartConfig } from '@/pages/dataBoard/components/ChartsPanel/utils';
import { componentStyle } from '@/components/chart/chart-option';
import RenderEngine from './RenderEngine';
import '../../../../dashboard/workplace/style/component-loader.less';

function ComponentLoader({ itemComp, index }) {
  const { screenStore } = useStore();
  const { triggerUpdate, setTriggerUpdate } = useContext(ScreenWorkplaceCtx);
  const [loading, setLoading] = useState(false);
  const {
    type,
    dataConfig,
    dataConfig: { status, branchTag, freshTime, mapName, adcode },
    styleConfig
  } = itemComp;
  const hasError = useMemo(() => {
    return status === 'intoCanvas' || status === 'queryError';
  }, [status]);
  const isDataEmpty = useMemo(() => {
    return status === 'intoCanvas';
  }, [status]);

  useEffect(() => {
    if (status === 'getChartData') {
      fetchData();
    }
  }, [status]);

  useEffect(() => {
    if (triggerUpdate && index === screenStore.state.activeIndex) {
      fetchData();
    }
  }, [triggerUpdate]);

  useEffect(() => {
    let autoRefreshTimer = null;
    if (freshTime && freshTime.isFreshEnable) {
      autoRefreshTimer = setInterval(() => {
        fetchData();
      }, freshTime.freshTimeNumber * 1000 * (freshTime.freshTimeUnit === 'min' ? 60 : 1));
    } else {
      clearInterval(autoRefreshTimer);
    }

    return () => {
      clearInterval(autoRefreshTimer);
    };
  }, [JSON.stringify(freshTime)]);

  const fetchData = async () => {
    let activeAreaGEOJson;
    if (type.includes('map') && type !== 'treemap') {
      if (!adcode) {
        message.error('缺少关联字段');
        return;
      }
      const res = await axios.get(`${APIS.GetAreaGEOJson}/${adcode}.json`);
      activeAreaGEOJson = res.data;
      echarts.registerMap(mapName, activeAreaGEOJson);
    }
    setLoading(true);
    http
      .post(APIS.DataBoardGetChartsData, convertItemCompDataConfig(itemComp))
      .then(res => {
        const { dimensionData, measureData, mapChartsData, compareTableData } = res.data;
        if (type.includes('ranking-list')) {
          const measureDataKey = Object.keys(measureData)[0];
          const dataConverted = _.zip(dataConvert(dimensionData), dataConvert(measureData))
            .map(item => {
              return item.reduce((acc, cur) => ({ ...acc, ...cur }), {});
            })
            .sort((a, b) => b[measureDataKey] - a[measureDataKey]);
          const newItem = {
            ...itemComp,
            dataConfig: {
              ...itemComp.dataConfig,
              status: 'done'
            },
            element: {
              ...itemComp.element,
              data:
                itemComp.dataConfig.measureFields[0].sortType && itemComp.dataConfig.measureFields[0].sortType === 'ASC'
                  ? dataConverted.map(item => ({ ...item, key: uuidv4() })).reverse()
                  : dataConverted.map(item => ({ ...item, key: uuidv4() }))
            }
          };
          screenStore.modComp({ compId: itemComp.compId, item: newItem });
          return;
        }
        const extraConfigFields =
          itemComp.type === 'combination'
            ? Object.values(itemComp.dataConfig.extraConfigFieldMap).flat(Infinity)
            : itemComp.dataConfig.extraConfigFields;
        const extraConfigFieldCountMap = calculateExtraConfigFieldCount(extraConfigFields);
        const mappedExtraConfigFields = extraConfigFields.map(field => {
          const { vid, customName } = field;
          const [uniqueId, calculatorType, contrastType, contrastValueType] = vid.split('|');
          let fieldCaptionExcludeCalcType = `${uniqueId}`;
          if (contrastType) fieldCaptionExcludeCalcType += `_${contrastType}_${contrastValueType}`;
          let caption = customName;
          if (extraConfigFieldCountMap[fieldCaptionExcludeCalcType] > 1) {
            caption += `_${CALCULATOR_TYPES[calculatorType]}`;
          }
          if (contrastType) {
            caption += `_${CONTRAST_TYPES[contrastType]}_${CONTRAST_VALUE_TYPES[contrastValueType]}`;
          }
          return {
            ...field,
            caption
          };
        });
        const newMeasureData = {};
        measureData &&
          Object.keys(measureData).forEach(key => {
            const aliasName = mappedExtraConfigFields.find(field => field.caption === key).aliasName;
            newMeasureData[`${aliasName}${key.split('_').length > 1 ? '_' : ''}${key.split('_').slice(1).join('_')}`] =
              measureData[key];
          });
        const chartOptions = getChartConfig(
          res.data,
          type === 'map' || type === 'bubble-map' ? mapChartsData : dimensionData, //如果是地图，则只会有mapChartsData数据
          newMeasureData,
          type,
          branchTag || 'dimension',
          styleConfig,
          null,
          type === 'tableMultidimensional' || (type.includes('map') && type !== 'treemap')
            ? mappedExtraConfigFields
            : _.cloneDeep(dataConfig),
          type.includes('map') && type !== 'treemap' ? { mapName, GEOJson: activeAreaGEOJson } : null
        );
        // convert styleConfig to chartOptions before merge
        const mergeOptions = componentStyle(chartOptions, itemComp);
        // console.log('mergeOptions: ', mergeOptions);
        const newItem = {
          ...itemComp,
          dataConfig: {
            ...itemComp.dataConfig,
            chartOptions: mergeOptions,
            status: 'done'
          }
        };
        setTriggerUpdate(false);
        screenStore.modComp({ compId: itemComp.compId, item: newItem });
      })
      .catch(err => {
        console.log('queryError: ', err);
        const newItem = {
          ...itemComp,
          dataConfig: {
            ...itemComp.dataConfig,
            status: 'queryError'
          }
        };
        setTriggerUpdate(false);
        screenStore.modComp({
          compId: itemComp.compId,
          item: newItem
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div
      className={cls('component-loader', {
        'loader-has-loading': loading,
        'loader-has-error': hasError && type !== 'ranking-list'
      })}
    >
      <div className="component-wrapper">
        {(status === 'done' || (type === 'ranking-list' && status === 'intoCanvas')) && (
          <RenderEngine comp={itemComp} editMode="edit" />
        )}
      </div>
      <div className="component-loader-masks"></div>
      {loading && (
        <div className="component-loader-loading">
          <div className="loading-content">
            <Spin tip="数据加载中..." />
          </div>
        </div>
      )}
      {hasError && type !== 'ranking-list' && (
        <div
          className={cls(['component-loader-error', 'default', `${type}`], {
            'error-empty-notice': isDataEmpty
          })}
        >
          <div className="error-image"></div>
          <div className="error-description">
            {status === 'queryError' && <span>数据查询失败</span>}
            {isDataEmpty && <div className="detail">当前图表无数据</div>}
          </div>
        </div>
      )}
    </div>
  );
}

export default ComponentLoader;
