import React, { useEffect } from 'react';
import { Button } from 'antd';
import { IProps } from './data';

function IbButton(props: IProps) {
  const { mode, widget, inDraw, onQuery } = props;

  useEffect(() => {
    // auto trigger update
    if (mode === 'preview' && widget.options.attrs.autoTrigger) {
      triggerSearch();
    }
  }, []);

  const triggerSearch = () => {
    onQuery();
  };

  return (
    <Button type={widget.options.attrs.type} onClick={triggerSearch}>
      {widget.options.value}
    </Button>
  );
}

export default IbButton;
