import React, { FC, useState, useRef } from 'react';
import cls from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import moment from 'moment';
import { Tooltip, Dropdown, Menu, Modal, message, Icon } from 'antd';
import SvgIcon from '@/components/SvgIcon';
import { ICON_VALUE_TYPE_MAP } from './FieldItem';
import FieldFilter from '@/components/FieldFilter';
import ModalGranularityFieldFilter from '@/pages/dashboard/workplace/modal-granularity-field-filter';
import ConfigCompareFieldModalContent, {
  validateDynamicBaseValue,
  validateDynamicIncrement,
  dateFormat,
  dateErrorMsgTip,
  validateRangeItemCondition
} from '@/pages/dataBoard/components/ConfigCompareFieldModalContent';
import { DEFAULT_COMPARE_FIELD_CONFIG, generateFormModel } from '@/components/chart/chart';
import {
  FieldType,
  granularityMap,
  CALCULATOR_TYPES,
  DIMENSION_FIELD_DROPDOWN_MENU,
  MEASURE_FIELD_DROPDOWN_MENU,
  VALUE_DISPLAY_FORMAT
} from '@/utils/enum';
import { generateDateDisplayFormatMenu } from '@/pages/DataSet/Edit/util';
import ModalDisplayFormatSetting from '@/pages/dashboard/workplace/modal-display-format-setting';
import ModalSettingFieldAliasName from '@/pages/dashboard/workplace/modal-setting-field-alias-name';

const { SubMenu } = Menu;
const SortListItem: FC<any> = ({
  activeComp,
  type,
  item,
  index,
  filters,
  dataSetId,
  dataSetName,
  dataSetFields,
  flattenedFields,
  compareField,
  onFieldCompareConfigChange,
  onFieldDropdownMenuChange,
  onFieldRemove,
  onFieldFiltersChange,
  onDerivedFieldFilterChange,
  onChartTypeValueChange,
  onFieldDisplayFormatChange,
  onFieldExtraConfigChange
}) => {
  const {
    attribute: { type: fieldType, valueType, dateFormat, granularity, customFieldFunction, valueMagnitude },
    customName,
    sortType,
    calculatorType,
    chartType
  } = item;
  const [visibleModalFieldFilter, setVisibleModalFieldFilter] = useState(false);
  const [derivedFieldFilterConfig, setDerivedFieldFilterConfig] = useState({
    visible: false,
    dataSetName: '',
    itemField: null
  });
  const [compareFieldConfig, setCompareFieldConfig] = useState(_.cloneDeep(DEFAULT_COMPARE_FIELD_CONFIG));
  const [displayFormatConfig, setDisplayFormatConfig] = useState({
    visible: false,
    pattern: '#,##0.0',
    valueMagnitude: valueMagnitude || 'MAGNITUDE_NONE'
  });
  const [aliasNameConfig, setAliasNameConfig] = useState({
    visible: false,
    extraConfigField: null
  });
  const fieldFilterRef = useRef(null);
  const formConfigCompareFieldRef = useRef(null);

  const renderFieldOperMenu = (fieldType: FieldType, itemField) => {
    const selectedKeys = [];
    const {
      sort,
      sortType,
      calculatorType,
      attribute: { type, valueType, granularity, displayFormat, dataSetFieldValueFormat }
    } = itemField;
    const isDateField = type === 'DIMENSION' && valueType === 'DATE';
    if (sort && sortType) selectedKeys.push(sortType);
    if (calculatorType) selectedKeys.push(calculatorType);
    if (isDateField && displayFormat) selectedKeys.push(displayFormat);
    if (type === 'MEASURE' && dataSetFieldValueFormat) selectedKeys.push(dataSetFieldValueFormat);
    const dimFieldDropdownMenu = isDateField
      ? [...generateDateDisplayFormatMenu(granularityMap[granularity]), ...DIMENSION_FIELD_DROPDOWN_MENU]
      : DIMENSION_FIELD_DROPDOWN_MENU;
    const meaFieldDropdownMenu = [
      {
        label: '数据展示格式',
        children: [].concat(
          Object.keys(VALUE_DISPLAY_FORMAT).map(key => ({
            label: VALUE_DISPLAY_FORMAT[key],
            value: key
          })),
          [{ label: '自定义', value: 'CUSTOM' }]
        )
      },
      ...MEASURE_FIELD_DROPDOWN_MENU,
      {
        label: '字段显示内容',
        value: 'ALIAS_NAME'
      }
    ];
    if (chartType === 'tableMultidimensional') {
      meaFieldDropdownMenu.shift();
    }
    return (
      <Menu selectedKeys={selectedKeys}>
        {renderMenuItem(fieldType === 'DIMENSION' ? dimFieldDropdownMenu : meaFieldDropdownMenu)}
      </Menu>
    );
  };
  const renderMenuItem = menu => {
    return menu.map(item => {
      if (item.children) {
        return (
          <SubMenu key={item.label} title={item.label} popupClassName="dark-theme field-box-sub-menu-list">
            {renderMenuItem(item.children)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={item.value} onClick={() => handleMenuItemClick(item.value)}>
          {item.label}
        </Menu.Item>
      );
    });
  };
  const renderFieldBox = () => {
    let fieldCaption = customName;
    if (valueType === 'DATE' && !dateFormat.includes('HMS') && !customFieldFunction) {
      fieldCaption += `(${granularityMap[granularity]})`;
    }
    return (
      <div className={cls(['field-box-wrapper', `${fieldType.toLowerCase()}-field-box`])}>
        <span className="field-icon">
          <i className="svg-icon-wrapper">
            <SvgIcon iconClass={ICON_VALUE_TYPE_MAP.get(valueType)} />
          </i>
        </span>
        {sortType && sortType !== 'NONE' && (
          <span className="sort-icon">
            <i className="svg-icon-wrapper">
              <SvgIcon iconClass={sortType.toLowerCase()} />
            </i>
          </span>
        )}
        <span className="field-caption">
          {fieldCaption}
          {type === 'measureFields' ? `(${getFieldCalVal(calculatorType)})` : ''}
        </span>
        <div className="right-hover-icons">
          {(type === 'dimensionFields' || type === 'measureFields' || type.includes('axis')) && (
            <Tooltip title="更多">
              <i className="svg-icon-wrapper icon-arrow-down">
                <SvgIcon iconClass="arrow-down" />
              </i>
            </Tooltip>
          )}
          {type === 'filterFields' && (
            <Tooltip title="筛选">
              <i className="svg-icon-wrapper icon-funnel" onClick={handleFilter}>
                <SvgIcon iconClass="funnel-1" />
              </i>
            </Tooltip>
          )}
          {type === 'compareFields' && (
            <Tooltip title="配置对比字段">
              <Icon type="setting" onClick={handleConfigCompareField} />
            </Tooltip>
          )}
          <Tooltip title="删除">
            <i className="svg-icon-wrapper icon-del" onClick={handleDelete}>
              <SvgIcon iconClass="delete-1" />
            </i>
          </Tooltip>
        </div>
        {type.includes('yaxis') && (
          <Dropdown
            overlayClassName="chart-type-dropdown-menu dark-theme"
            overlay={
              <Menu
                onClick={({ key, domEvent }) => {
                  domEvent.stopPropagation();
                  onChartTypeValueChange(type, index, key);
                }}
              >
                <Menu.Item key="bar">
                  <i className="svg-icon-wrapper">
                    <SvgIcon iconClass="chart-histogram" />
                  </i>
                  柱
                </Menu.Item>
                <Menu.Item key="line">
                  <i className="svg-icon-wrapper">
                    <SvgIcon iconClass="chart-line" />
                  </i>
                  线
                </Menu.Item>
                <Menu.Item key="area">
                  <i className="svg-icon-wrapper">
                    <SvgIcon iconClass="chart-area" />
                  </i>
                  面
                </Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <i className="svg-icon-wrapper chart-type-icon selected-icon dark">
              <SvgIcon
                iconClass={cls({
                  'chart-histogram': chartType === 'bar',
                  'chart-line': chartType === 'line',
                  'chart-area': chartType === 'area'
                })}
              />
            </i>
          </Dropdown>
        )}
      </div>
    );
  };
  const getFieldCalVal = calType => {
    if (!calType) return CALCULATOR_TYPES['SUM'];
    return CALCULATOR_TYPES[calType];
  };
  const handleMenuItemClick = selectedVal => {
    console.log('handleMenuItemClick: ', type, selectedVal, index);
    // 数值展示格式设置
    if (selectedVal === 'CUSTOM') {
      setDisplayFormatConfig({
        ...displayFormatConfig,
        visible: true
      });
      return;
    }
    // 字段别名设置
    if (selectedVal === 'ALIAS_NAME') {
      let extraConfigField;
      if (chartType === 'combination') {
        extraConfigField = activeComp.dataConfig.extraConfigFieldMap[type].find((_, idx) => idx === index);
      } else {
        extraConfigField = activeComp.dataConfig.extraConfigFields.find((_, idx) => idx === index);
      }
      setAliasNameConfig({
        visible: true,
        extraConfigField
      });
      return;
    }
    onFieldDropdownMenuChange(type, selectedVal, index);
  };
  const handleDelete = ev => {
    ev.stopPropagation();
    onFieldRemove(type, index);
  };
  const handleFilter = () => {
    const isDerivedField = valueType === 'DATE' && granularity !== null;
    if (isDerivedField) {
      setDerivedFieldFilterConfig({
        visible: true,
        dataSetName,
        itemField: item
      });
      return;
    }
    setVisibleModalFieldFilter(true);
  };
  const onCancel = () => {
    setVisibleModalFieldFilter(false);
  };
  const onOk = () => {
    // filter conditions values empty valid
    const { filterConditions, conditionJoin } = fieldFilterRef.current;
    let isValid = true;
    if (filterConditions.length) {
      // 当条件操作符不为 IS_NOT_NULL 或 IS_NULL 的时候才判断是否有条件值
      isValid = filterConditions
        .filter(item => !['IS_NULL', 'IS_NOT_NULL'].includes(item.operator))
        .every(item => !item.values.includes(''));
    }
    if (!isValid) {
      message.error('过滤值不能为空');
      return;
    }
    onFieldFiltersChange(
      filterConditions.map(item => ({
        ...item,
        conditionJoin
      }))
    );
    onCancel();
  };
  const handleConfigCompareField = () => {
    setCompareFieldConfig({
      ...compareFieldConfig,
      visibleModalCompareFieldConfig: true,
      fieldSelected: item,
      selectedFieldValues: compareField ? compareField.staticValues : []
    });
  };
  const confirmCompareFieldConfig = () => {
    const { fieldSelected, formData, selectedFieldValues } = formConfigCompareFieldRef.current;
    const {
      uniqueId,
      attribute: { valueType }
    } = fieldSelected;
    let compareFieldCfg;

    if (valueType === 'DATE') {
      if (formData.valueCategory === 'STATIC_MULTIVALUED') {
        const staticValues = _.cloneDeep(formData.staticValues);
        // custom validate
        const isValid = staticValues.every(val => val.validateStatus === 'success' && val.value);
        if (!isValid) {
          setCompareFieldConfig({
            ...compareFieldConfig,
            formData: {
              ...compareFieldConfig.formData,
              valueCategory: formData.valueCategory,
              staticValues: staticValues.map(val => ({
                ...val,
                validateStatus: !val.value ? 'error' : 'success',
                errorMsg: !val.value ? dateErrorMsgTip : null
              }))
            }
          });
          return;
        }
        compareFieldCfg = {
          fieldUniqueId: uniqueId,
          valueCategory: formData.valueCategory,
          staticValues: staticValues.map(val => moment(val.value).format(dateFormat))
        };
      } else if (formData.valueCategory === 'DYNAMIC_MULTIVALUED') {
        const { dynamicValue } = _.cloneDeep(formData);
        const isValid = Object.keys(dynamicValue)
          .filter(key => key !== 'diffType')
          .every(key => dynamicValue[key].value !== '');
        const dynamicBaseValue = dynamicValue.baseValue.value;
        const dynamicIncrement = dynamicValue.increment.value;
        const dynamicDiffType = dynamicValue.diffType;
        if (!isValid) {
          setCompareFieldConfig({
            ...compareFieldConfig,
            formData: {
              ...compareFieldConfig.formData,
              valueCategory: formData.valueCategory,
              dynamicValue: {
                ...compareFieldConfig.formData.dynamicValue,
                baseValue: {
                  ...validateDynamicBaseValue(dynamicBaseValue),
                  value: dynamicBaseValue
                },
                increment: {
                  ...validateDynamicIncrement(dynamicIncrement),
                  value: dynamicIncrement
                }
              }
            }
          });
          return;
        }
        const conditionStandard = moment(dynamicBaseValue).format(dateFormat);
        compareFieldCfg = {
          fieldUniqueId: uniqueId,
          valueCategory: formData.valueCategory,
          dynamicValue: {
            baseValue: conditionStandard,
            increment: dynamicDiffType === 'minus' ? `-${dynamicIncrement}` : `${dynamicIncrement}`
          }
        };
      } else {
        const { rangeValues } = _.cloneDeep(formData);
        const isValid = rangeValues
          .map(({ start, end }) => ({
            ...validateRangeItemCondition([start, end]),
            start,
            end
          }))
          .every(val => val.validateStatus === 'success');
        if (!isValid) {
          setCompareFieldConfig({
            ...compareFieldConfig,
            formData: {
              ...compareFieldConfig.formData,
              valueCategory: formData.valueCategory,
              rangeValues: rangeValues.map(({ start, end }) => ({
                ...validateRangeItemCondition([start, end]),
                start,
                end
              }))
            }
          });
          return;
        }
        compareFieldCfg = {
          fieldUniqueId: uniqueId,
          valueCategory: formData.valueCategory,
          rangeValues: rangeValues.map(({ start, end }) => ({
            start: moment(start).format(dateFormat),
            end: moment(end).format(dateFormat)
          }))
        };
      }
    } else {
      compareFieldCfg = {
        fieldUniqueId: uniqueId,
        valueCategory: 'STATIC_MULTIVALUED',
        staticValues: selectedFieldValues.map(fieldVal => fieldVal.value)
      };
    }
    setCompareFieldConfig({
      ...compareFieldConfig,
      visibleModalCompareFieldConfig: false
    });
    onFieldCompareConfigChange && onFieldCompareConfigChange(compareFieldCfg);
  };
  const cancelCompareFieldConfig = () => {
    setCompareFieldConfig({
      ...compareFieldConfig,
      fieldSelected: null,
      visibleModalCompareFieldConfig: false,
      formData: generateFormModel()
    });
  };

  const cancelDerivedFieldFilterConfig = () => {
    setDerivedFieldFilterConfig({
      ...derivedFieldFilterConfig,
      visible: false
    });
  };

  const confirmDerivedFieldFilterConfig = complexFilter => {
    onDerivedFieldFilterChange({ complexFilter, index, itemField: item });
    cancelDerivedFieldFilterConfig();
  };

  const cancelSettingDisplayFormat = () => {
    setDisplayFormatConfig({
      ...displayFormatConfig,
      visible: false
    });
  };

  const confirmSettingDisplayFormat = ({ pattern, numberType, valueMagnitude }) => {
    setDisplayFormatConfig({
      ...displayFormatConfig,
      visible: false,
      pattern,
      valueMagnitude
    });
    onFieldDisplayFormatChange(type, index, pattern, numberType, valueMagnitude);
  };

  const cancelSettingAliasName = () => {
    setAliasNameConfig({
      ...aliasNameConfig,
      visible: false
    });
  };

  const confirmSettingAliasName = aliasName => {
    let updatedItem;
    if (chartType === 'combination') {
      updatedItem = _.cloneDeep(activeComp.dataConfig.extraConfigFieldMap[type].find((_, idx) => idx === index));
    } else {
      updatedItem = _.cloneDeep(activeComp.dataConfig.extraConfigFields.find((_, idx) => idx === index));
    }
    updatedItem.aliasName = aliasName;
    const params: any = {
      compId: activeComp.compId,
      index,
      updatedItem
    };
    if (activeComp.chartType === 'combination') {
      params.fieldAreaType = type;
    }
    cancelSettingAliasName();
    onFieldExtraConfigChange(params);
  };

  const isItemDimensionOrMeasure = ['dimensionFields', 'measureFields', 'xaxis', 'yaxis', 'yaxisExt'].includes(type);
  // console.log('SortListItem render: ', item);
  return (
    <div className="sort-list-item">
      {isItemDimensionOrMeasure && (
        <Dropdown
          overlay={() => renderFieldOperMenu(fieldType, item)}
          trigger={['click']}
          placement="bottomCenter"
          overlayClassName="dark-theme field-box-menu-list"
        >
          {renderFieldBox()}
        </Dropdown>
      )}
      {!isItemDimensionOrMeasure && renderFieldBox()}
      {visibleModalFieldFilter && (
        <Modal
          title="添加过滤"
          visible={visibleModalFieldFilter}
          onCancel={onCancel}
          onOk={onOk}
          width={800}
          maskClosable={false}
          wrapClassName="dlg-field-filter-wrapper"
        >
          <FieldFilter ref={fieldFilterRef} filters={filters} itemField={item} />
        </Modal>
      )}

      {derivedFieldFilterConfig.visible && (
        <ModalGranularityFieldFilter
          {...derivedFieldFilterConfig}
          onCancel={cancelDerivedFieldFilterConfig}
          onOk={confirmDerivedFieldFilterConfig}
        />
      )}
      {compareFieldConfig.visibleModalCompareFieldConfig && (
        <Modal
          visible={compareFieldConfig.visibleModalCompareFieldConfig}
          title="对比字段配置"
          width={520}
          okText="确认"
          cancelText="取消"
          onOk={confirmCompareFieldConfig}
          onCancel={cancelCompareFieldConfig}
        >
          <ConfigCompareFieldModalContent
            ref={formConfigCompareFieldRef}
            dataSetId={dataSetId}
            compareField={compareFieldConfig.fieldSelected}
            fields={flattenedFields}
            compareFieldConfig={compareField}
            compareFieldConfigFormData={compareFieldConfig.formData}
            selectedFieldValuesFromParent={compareField ? compareField.staticValues : []}
          />
        </Modal>
      )}
      {displayFormatConfig.visible && (
        <ModalDisplayFormatSetting
          visible={displayFormatConfig.visible}
          pattern={displayFormatConfig.pattern}
          valueMagnitude={displayFormatConfig.valueMagnitude}
          onCancel={cancelSettingDisplayFormat}
          onOk={confirmSettingDisplayFormat}
        />
      )}
      {aliasNameConfig.visible && (
        <ModalSettingFieldAliasName
          visible={aliasNameConfig.visible}
          extraConfigField={aliasNameConfig.extraConfigField}
          onCancel={cancelSettingAliasName}
          onOk={confirmSettingAliasName}
        />
      )}
    </div>
  );
};

export default SortListItem;
