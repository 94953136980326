import React, { FC } from 'react';
import classNames from 'classnames';
import './index.less';

interface IProps {
  // 线条样式
  lineStyle?: string;
  // 箭头方向 @defaultValue horizontal
  arrowDir?: 'horizontal' | 'vertical';
  // 箭头样式 @defaultValue none
  arrowStyle?: 'none' | 'start' | 'end' | 'double';
  // 线条颜色
  lineColor?: string;
}
const Line: FC<IProps> = ({ lineStyle, arrowDir, arrowStyle, lineColor }) => {
  const getSvgSize = () => {
    switch (arrowStyle) {
      case 'none':
        return arrowDir === 'horizontal' ? { width: 400, height: 2 } : { width: 2, height: 400 };
      case 'start':
      case 'end':
      case 'double':
        return arrowDir === 'horizontal' ? { width: 400, height: 12 } : { width: 12, height: 400 };
    }
  };
  const calPoints = () => {
    return arrowDir === 'horizontal'
      ? ['12,0,0,6,12,12', '388,0,400,6,388,12']
      : ['12,12,6,0,0,12', '12,388,6,400,0,388'];
  };

  const getLineCoordinate = () => {
    switch (arrowStyle) {
      case 'none':
        return arrowDir === 'horizontal' ? { x1: 0, y1: 1, x2: 400, y2: 1 } : { x1: 1, y1: 0, x2: 1, y2: 400 };
      case 'start':
        return arrowDir === 'horizontal' ? { x1: 12, y1: 6, x2: 400, y2: 6 } : { x1: 6, y1: 12, x2: 6, y2: 400 };
      case 'end':
        return arrowDir === 'horizontal' ? { x1: 0, y1: 6, x2: 388, y2: 6 } : { x1: 6, y1: 0, x2: 6, y2: 388 };
      case 'double':
        return arrowDir === 'horizontal' ? { x1: 12, y1: 6, x2: 388, y2: 6 } : { x1: 6, y1: 12, x2: 6, y2: 388 };
    }
  };

  const getStrokeDasharray = () => {
    switch (lineStyle) {
      case 'dashed':
        return '4,2';
      case 'solid':
        return '';
      case 'dotted':
        return '2,2';
      case 'dotted-line':
        return '8,4,2,2,2,4';
    }
  };
  const svgSize = getSvgSize();
  return (
    <div
      className={classNames('decoration-line-wrapper', {
        'is-vertical': arrowDir === 'vertical'
      })}
    >
      <svg
        width={arrowDir === 'horizontal' ? '100%' : svgSize.width}
        height={arrowDir === 'horizontal' ? svgSize.height : '100%'}
        viewBox={`0 0 ${svgSize.width} ${svgSize.height}`}
        preserveAspectRatio="none"
      >
        {(arrowStyle === 'start' || arrowStyle === 'double') && (
          <polygon fill={lineColor} points={calPoints()[0]}></polygon>
        )}
        <line {...getLineCoordinate()} stroke={lineColor} strokeWidth="2" strokeDasharray={getStrokeDasharray()}></line>
        {(arrowStyle === 'end' || arrowStyle === 'double') && (
          <polygon fill={lineColor} points={calPoints()[1]}></polygon>
        )}
      </svg>
    </div>
  );
};

Line.defaultProps = {
  arrowDir: 'horizontal',
  arrowStyle: 'none',
  lineStyle: 'dashed',
  lineColor: '#1b64ee'
};

export default Line;
