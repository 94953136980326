import React, { useRef } from 'react';
import { Modal } from 'antd';
import DrillDown from '@/pages/dataBoard/components/DrillDown';

function ModalSettingDrillDown({ visible, dimensionFields, drillDownConfig, onCancel, onOk }: any) {
  const drillDownRef = useRef(null);
  const handleOk = () => {
    if (drillDownRef.current) {
      const { selected } = drillDownRef.current.state;
      onOk(selected);
    }
  };
  return (
    <Modal
      title="钻取设置"
      visible={visible}
      maskClosable={false}
      width={640}
      wrapClassName="dlg-drill-down-wrapper"
      onOk={handleOk}
      onCancel={onOk}
    >
      <DrillDown ref={drillDownRef} dimensionFields={dimensionFields} destinationFields={drillDownConfig} />
    </Modal>
  );
}

export default ModalSettingDrillDown;
