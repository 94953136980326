import React from 'react';
import { Breadcrumb } from 'antd';

function DrillPath({ drillDownFields, onBreadcrumbItemClick }) {
  const handleItemClick = (ev, itemField, index) => {
    if (index === drillDownFields.length - 1) return;
    onBreadcrumbItemClick({ itemField, index });
  };
  return (
    <div className="drill-crumbs">
      <Breadcrumb separator=">">
        {drillDownFields.map((itemField, index) => (
          <Breadcrumb.Item key={itemField.uniqueId} onClick={ev => handleItemClick(ev, itemField, index)}>
            {itemField.customName}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
}

export default DrillPath;
