import _ from 'lodash'
import React, { PureComponent } from 'react'
import { DatePicker } from 'antd'
import { IUdFormItem } from '../../..'
import moment from 'moment'
import 'moment/locale/zh-cn.js'
import { DatePickerProps } from 'antd/lib/date-picker/interface'

moment.locale('zh-cn')

/**
 * 封装antd DatePicker 组件
 * 解决 返回的value不是string格式的问题
 */
class UdDatePicker extends PureComponent<IUdDatePickerProps, IUdDatePickerState> {

  constructor(props: IUdDatePickerProps) {
    super(props);
    this.state = {
      values: null,
    }
  }

  public componentDidMount() {
    this.handleProps(this.props)
  }

  public componentWillReceiveProps(nextProps: Readonly<IUdDatePickerProps>) {
    this.handleProps(nextProps)
  }

  public render() {
    const { format } = this.props
    let values: any = {}

    if (this.state.values != null) {
      values.value = this.state.values
    }
    return (
      <DatePicker className="ud-date-picker" {...this.props}
        onChange={this.triggerChange} format={format} {...values} />
    )
  }

  protected handleProps = (props: IUdDatePickerProps) => {
    if (props.value && props.value !== '') {
      this.setState({ values: moment(props.value) })
    } else {
      this.setState({ values: null })
    }
  }

  protected triggerChange = (value: any) => {
    let format = this.props.format ? this.props.format : 'YYYY-MM-DD HH:mm:ss'
    this.props.onChange(value ? value.format(format) : null)
  }
}

interface IUdDatePickerProps extends Partial<IUdFormItem>, DatePickerProps {
  id?: string
  format?: string
  value?: any
  onChange?(value: any): void
}


interface IUdDatePickerState {
  values?: any
}

export default UdDatePicker
