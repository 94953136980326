import React, { useRef } from 'react';
import { Modal } from 'antd';
import SvgIcon from '@/components/SvgIcon';
import { granularityIconMap } from '../../dataBoard/components/FieldsOperation/contrast';
import DatetimeConfigForm from '../../dataBoard/components/FieldsOperation/components/ModalDynamicRingRatioFilter/DatetimeConfigForm';
import './style/derived-field-filter.less';

function ModalGranularityFieldFilter({ visible, dataSetName, itemField, onOk, onCancel }) {
  const formRef = useRef(null);

  const handleOk = () => {
    onOk(formRef.current.formData);
  };

  // console.log('render ModalGranularityFieldFilter: ', dataSetName, itemField);
  return (
    <Modal
      visible={visible}
      title="设置过滤器"
      width={560}
      okText="确认"
      cancelText="取消"
      onOk={handleOk}
      onCancel={onCancel}
      wrapClassName="modal-granularity-field-filter-wrapper"
    >
      <div className="filter-model-content">
        <div className="filter-model-content-header">
          <div className="header-item">
            <label className="header-item-label">数据集</label>
            <span className="header-item-value">
              {/* <i className="svg-icon-wrapper icon-cube">
                <SvgIcon iconClass="cube" />
              </i> */}
              <span className="label">{dataSetName}</span>
            </span>
          </div>
          <div className="header-item">
            <label className="header-item-label">选中字段</label>
            <div className="field-item dimension">
              <span className="field-item-icon">
                <i className="svg-icon-wrapper">
                  <SvgIcon iconClass={granularityIconMap[itemField.attribute.granularity]} />
                </i>
              </span>
              <span className="field-item-text">{itemField.customName}</span>
            </div>
          </div>
        </div>
        <div className="filter-model-content-info">
          <div className="filter-datetime-config">
            <DatetimeConfigForm
              ref={formRef}
              granularity={granularityIconMap[itemField.attribute.granularity]}
              complexFilter={itemField.complexFilter}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalGranularityFieldFilter;
