import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/store/useStore';
import { DragDropContext } from 'react-beautiful-dnd';
import _ from 'lodash';
import { message, Tabs } from 'antd';
import { getFieldsCount, calculateFieldVid } from './util';
import ChartTypeMenu from './chart-type-menu';
import SettingPanelTabs from './setting-panel-tabs';
import DataPanelCube from './data-panel-cube';
import FilterStylePanelTab from './filter-style-panel-tab';
import './style/setting-panel.less';

const { TabPane } = Tabs;

const copy = (source, draggableId) => {
  const sourceClone: any[] = Array.from(source);
  const item = sourceClone.find(itemField => itemField.uniqueId === draggableId);

  return Object.assign(
    {},
    { ...item, uniqueId: `${item.uniqueId}-clone` },
    item.attribute.type === 'MEASURE' ? { calculatorType: item.calculatorType || 'SUM' } : {}
  );
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
function SettingPanel() {
  const { dashboardStore } = useStore();
  const { activeChart, activeComp, state } = dashboardStore;
  const { activeIndex } = state;
  const onDragEnd = result => {
    const { source, destination, draggableId } = result;
    console.log('===> result', result);
    // dropped outside the list
    if (!destination) return;

    // 字段类型判断
    if (
      source.droppableId === 'SOURCE_FIELD_MEASURE_ITEMS' &&
      (destination.droppableId === 'dimensionFields' || destination.droppableId === 'xaxis')
    ) {
      message.warning('不支持添加度量到[维度]');
      return;
    }
    if (
      source.droppableId === 'SOURCE_FIELD_DIMENSION_ITEMS' &&
      (destination.droppableId === 'measureFields' || destination.droppableId.includes('yaxis'))
    ) {
      message.warning('不支持添加维度到[度量]');
      return;
    }
    if (source.droppableId === 'SOURCE_FIELD_MEASURE_ITEMS' && destination.droppableId === 'compareFields') {
      message.warning('不支持添加度量到[对比字段]');
      return;
    }
    if (
      destination.droppableId === 'compareFields' &&
      Array.isArray(activeChart.dataConfig.compareFields) &&
      activeChart.dataConfig.compareFields.length
    ) {
      message.warning('只能拖入一个维度字段');
      return;
    }
    if (source.droppableId === 'SOURCE_FIELD_MEASURE_ITEMS' && destination.droppableId === 'drillDownFields') {
      message.warning('不支持添加度量到[钻取]');
      return;
    }

    // 组合图字段校验
    if (destination.droppableId.includes('yaxis')) {
      const { legend } = activeChart.dataConfig;
      const legendCount = getFieldsCount(legend);
      const isLegendFieldsEmpty = !legendCount;
      const destinationFields = activeChart.dataConfig[destination.droppableId];

      if (!isLegendFieldsEmpty && Array.isArray(destinationFields) && destinationFields.length) {
        message.warning('颜色图例轴已有项，主轴值/副轴值只能出现一项');
        return;
      }
    }
    if (destination.droppableId === 'legend') {
      const { yaxis, yaxisExt, legend } = activeChart.dataConfig;
      const yaxisCount = getFieldsCount(yaxis);
      const yaxisExtCount = getFieldsCount(yaxisExt);
      const legendCount = getFieldsCount(legend);
      const isYaxisMultiple = yaxisCount > 1 || yaxisExtCount > 1;

      if (isYaxisMultiple) {
        message.warning('值轴的项超过一项，颜色图例轴不能添加项');
        return;
      }
      if (legendCount > 0) {
        message.warning('已超过[颜色图例/维度]最多可添加项数量(1)');
        return;
      }
    }

    // 字段唯一性校验
    if (
      Array.isArray(activeChart.dataConfig[destination.droppableId]) &&
      activeChart.dataConfig[destination.droppableId]
        .map(itemField => itemField.uniqueId)
        .includes(`${draggableId}-clone`) &&
      destination.droppableId !== 'measureFields'
    ) {
      message.warning('已存在该字段');
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId: {
        console.log('reorder', source, destination);
        const updatedItem = _.cloneDeep(activeChart);
        updatedItem.dataConfig[destination.droppableId] = reorder(
          updatedItem.dataConfig[destination.droppableId],
          source.index,
          destination.index
        );
        dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
        break;
      }
      case 'SOURCE_FIELD_DIMENSION_ITEMS':
      case 'SOURCE_FIELD_MEASURE_ITEMS': {
        const appendedItem = copy(activeChart.dataConfig.flattenedFields, draggableId);
        const updatedItem = _.cloneDeep(activeChart);
        const isAppendedItemDerivedField =
          appendedItem.attribute.valueType === 'DATE' && appendedItem.attribute.granularity !== null;
        if (isAppendedItemDerivedField) {
          appendedItem.complexFilter = {
            dateFilterType: 'INTERVAL',
            intervalType: 'TIME_INTERVAL',
            filters: []
          };
        }
        if (destination.droppableId.includes('yaxis')) {
          appendedItem.chartType = destination.droppableId.includes('Ext') ? 'line' : 'bar';
        }
        updatedItem.dataConfig[destination.droppableId] = updatedItem.dataConfig[destination.droppableId]
          ? [...activeChart.dataConfig[destination.droppableId], appendedItem]
          : [appendedItem];
        /* const updatedItem = {
                ...activeChart,
                dataConfig: {
                  ...activeChart.dataConfig,
                  [destination.droppableId]: activeChart.dataConfig[destination.droppableId]
                    ? [...activeChart.dataConfig[destination.droppableId], appendedItem]
                    : [appendedItem]
                }
              }; */
        dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
        const {
          uniqueId,
          customName,
          attribute: { type },
          calculatorType,
          ratioCalculator
        } = appendedItem;
        if (type === 'MEASURE' && destination.droppableId !== 'filterFields') {
          const newItem = {
            vid: calculateFieldVid(appendedItem),
            type,
            uniqueId,
            customName,
            aliasName: customName,
            calculatorType,
            contrastType: ratioCalculator ? ratioCalculator.type : '',
            contrastValueType: ratioCalculator ? ratioCalculator.valueType : ''
          };
          const params: {
            frontChartId: string;
            newItem: { [key: string]: any };
            fieldAreaType?: string;
          } = { frontChartId: activeChart.frontChartId, newItem };
          if (activeChart.chartType === 'combination') {
            params.fieldAreaType = destination.droppableId;
          }
          dashboardStore.addExtraConfig(params);
        }
        break;
      }
      default:
        console.log('move');
        break;
    }
  };

  const handleStylePropChange = (key, val) => {
    const updatedItem = _.set(_.cloneDeep(activeComp), `style.${key}`, val);
    dashboardStore.modComp({ compId: activeComp.widgetId, updatedItem });
  };

  // console.log('render SettingPanel: ', activeChart, activeComp);
  return (
    <div className="setting-panel-container">
      {activeChart && (
        <div className="setting-panel show-cube">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="setting-panel-wrapper">
              <div className="setting-design-panel">
                <ChartTypeMenu activeChart={activeChart} />
              </div>
              <SettingPanelTabs />
            </div>
            <DataPanelCube />
          </DragDropContext>
        </div>
      )}
      {activeComp && !activeComp.component.includes('button') && (
        <div className="setting-panel">
          <div className="setting-panel-wrapper">
            <div className="custom-setting-panel">
              <Tabs
                className="custom-setting-panel-tabs"
                animated={false}
                type="card"
                activeKey="style"
                defaultActiveKey="style"
              >
                <TabPane className="custom-setting-panel-tab" key="style" tab="样式">
                  <FilterStylePanelTab style={activeComp.style} onStylePropChange={handleStylePropChange} />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      )}
      {(activeIndex === -1 || (activeComp && activeComp.component.includes('button'))) && (
        <div className="empty-tips">请选择图表添加配置信息...</div>
      )}
    </div>
  );
}

export default observer(SettingPanel);
