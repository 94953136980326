import React, { useState, useEffect, useMemo } from 'react';
import cls from 'classnames';
import { Tabs } from 'antd';
import { useStore } from '@/store/useStore';
import CubePanelTab from './cube-panel-tab';
import StylePanelTab from './style-panel-tab';
import AdvancedPanelTab from './advanced-panel-tab';

const SETTING_PANEL_TABS = [
  { key: 'field', tab: '字段', component: CubePanelTab, cls: 'cube-panel-tab' },
  { key: 'style', tab: '样式', component: StylePanelTab, cls: 'style-panel-tab' },
  { key: 'advanced', tab: '高级', component: AdvancedPanelTab, cls: 'advanced-panel-tab' }
];
const { TabPane } = Tabs;
function SettingPanelTabs() {
  const { dashboardStore } = useStore();
  const {
    activeChart: { chartType }
  } = dashboardStore;
  const [activeKey, setActiveKey] = useState('field');

  useEffect(() => {
    initState();
  }, [dashboardStore.state.activeIndex]);

  const initState = () => {
    setActiveKey('field');
  };
  return (
    <Tabs
      className="setting-panel-tabs ui-schema-antd-tabs"
      animated={false}
      type="card"
      defaultActiveKey={activeKey}
      activeKey={activeKey}
      onChange={activeKey => setActiveKey(activeKey)}
    >
      {SETTING_PANEL_TABS.map(tab => (
        <TabPane key={tab.key} tab={tab.tab} className={cls(['setting-panel-tab', `${tab.cls}`])}>
          <tab.component />
        </TabPane>
      ))}
    </Tabs>
  );
}

export default SettingPanelTabs;
