import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import { useStore } from '@/store/useStore';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as echarts from 'echarts';
import themeLightJson from '@/pages/Screen/Workspace/core/theme/light.json';
import { http } from '@/lib';
import APIS from '@/apis/api';
import useLocale from '@/utils/useLocale';
import locale from '@/components/widget/locale';
import { WorkplaceContext } from './context';
import { getDataConfig } from './chart-type';
import { GRID_LAYOUT_BREAK_POINTS, AXIS_STYLE_CHARTS } from '@/utils/enum';
import { getService } from '@/components/widget/utils';
import {
  calGridLayoutBreakPoint,
  convertChartsHandler,
  genLinkageConfig,
  genFilterConditionByWidget,
  genChartConditionsMap,
  genWidgetConditionMap,
  filterOutWidgetBtn,
  isWidgetRangeEmpty,
  hasWidgetSureBtn,
  updateConditionMapByWidget,
  containEmptyStr,
  genAxisConfig
} from './util';
import { DEFAULT_COLOR_CASE } from '@/components/chart/chart';
import widgetMap from '@/components/widget';
import DesignerContent from './designer-content';
import ModalFilter from './widget-filter/modal-filter';
import ModalButton from './widget-filter/modal-button';
import ModalButtonReset from './widget-filter/modal-button-reset';
import { DeviceType } from './context';
import PicLoadingSpin from '@/assets/spin-loading.gif';
import './style/index.less';

const generateItemCompCfg = (itemComp, dashboardStoreState) => {
  const { charts, customComps, activeBreakPoint } = dashboardStoreState;
  const { label: title, value: chartType } = itemComp;
  const frontChartId = `${chartType}-${uuidv4().slice(0, 6)}`;
  const allComps = [...charts, ...customComps];
  let x = 0;
  const len = allComps.length;
  const pcCols = GRID_LAYOUT_BREAK_POINTS['lg'].cols;
  const mobileCols = GRID_LAYOUT_BREAK_POINTS['xs'].cols;

  if (len > 0) {
    const lastItem = allComps[len - 1];
    const { x: lastItemX, w: lastItemW } = lastItem.layout;
    x =
      lastItemX + lastItemW + (chartType === 'map' || chartType === 'bubble-map' ? pcCols : pcCols / 2) > pcCols
        ? 0
        : lastItemX + lastItemW;
  }
  const layout = {
    i: frontChartId,
    x,
    y: Infinity,
    w: chartType === 'map' || chartType === 'bubble-map' || chartType === 'tableMultidimensional' ? pcCols : pcCols / 2,
    h: 4
  };
  const itemCompCfg: any = {
    frontChartId,
    chartType,
    layout,
    mobileTileLayout: {
      i: frontChartId,
      x,
      y: Infinity,
      w: mobileCols,
      h: 3
    },
    mobileConfig: {
      isMobileHide: false,
      hideIndex: -1
    },
    dataConfig: getDataConfig(chartType),
    styleConfig: {
      title,
      colorCase: DEFAULT_COLOR_CASE,
      bgColor: '#fff',
      mark: {
        show: false,
        position: 'title',
        content: ''
      }
    },
    advancedConfig: {
      proximityAnalysisStatus: false,
      linkageConfig: genLinkageConfig(),
      auxiliaryLines: []
    }
  };
  if (AXIS_STYLE_CHARTS.includes(chartType)) {
    itemCompCfg.styleConfig = {
      ...itemCompCfg.styleConfig,
      ...genAxisConfig()
    };
  }
  if (chartType === 'gauge') {
    itemCompCfg.styleConfig.endValue = void 0;
  }
  if (chartType === 'kpi') {
    itemCompCfg.styleConfig.addon = {
      prefix: undefined,
      suffix: undefined
    };
    itemCompCfg.styleConfig.indicator = {
      name: {
        fontSize: 12
      },
      value: {
        fontSize: 24
      }
    };
  }
  if (chartType.includes('map') && chartType !== 'treemap') {
    itemCompCfg.styleConfig.mapRange = {
      name: '中国',
      code: '100000'
    };
  }
  if (chartType === 'table') {
    itemCompCfg.styleConfig.summary = true;
  }
  return itemCompCfg;
};

export const genCustomCompLayout = (itemComp, dashboardStoreState) => {
  const { charts, customComps, activeBreakPoint } = dashboardStoreState;
  const comps = [...charts, ...customComps];
  let x = 0;
  const len = comps.length;
  const cols = 12;
  const mobileCols = 4;
  if (len > 0) {
    const lastItem = comps[len - 1];
    const { x: lastItemX, y: lastItemY, w: lastItemW } = lastItem.layout;
    x = lastItemX + lastItemW + cols / 3 > cols ? 0 : lastItemX + lastItemW;
  }
  return {
    layout: {
      i: itemComp.widgetId,
      x,
      y: Infinity,
      w: itemComp.component.includes('button') ? cols / 12 : cols / 3,
      h: itemComp.component.includes('grid') ? 3 : 1
    },
    mobileTileLayout: {
      i: itemComp.widgetId,
      x,
      y: Infinity,
      w: itemComp.component.includes('button') ? mobileCols / 4 : mobileCols / 2,
      h: itemComp.component.includes('grid') ? 3 : 1
    }
  };
};
function Workplace() {
  const store = useStore();
  const t = useLocale(locale);
  const { id } = useParams<{ id: string }>();
  const { dashboardStore } = store;
  const [loading, setLoading] = useState(false);
  const [activeDevice, setActiveDevice] = useState<DeviceType>('pc');
  const [dashboardName, setDashboardName] = useState(dashboardStore.state.dashboardName || '');
  const [QRCodeConfig, setQRCodeConfig] = useState({
    refresh: false,
    value: ''
  });
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [showWatermark, setShowWatermark] = useState(false);
  const [activeGridItemLayout, setActiveGridItemLayout] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    visible: false,
    visibleButton: false,
    actionType: 'add',
    widgetLabel: '',
    widget: null
  });
  const [widgetConditionMap, setWidgetConditionMap] = useState({});
  const [chartConditionsMap, setChartConditionsMap] = useState({});
  const chartConditionsMapRef = useRef(chartConditionsMap);

  useEffect(() => {
    init();
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    chartConditionsMapRef.current = chartConditionsMap;
  }, [chartConditionsMap]);

  const init = () => {
    store.udMainStore.setIsShowFrame(false);
    dashboardStore.setActiveBreakPoint(calGridLayoutBreakPoint());
    echarts.registerTheme('shine', themeLightJson);
    if (id !== '0') {
      store.dashboardStore.setDashboardId(id);
      fetchDashboardView(id);
      fetchResourceLink();
    }
  };

  const reset = () => {
    store.udMainStore.setIsShowFrame(true);
    store.dashboardStore.resetState();
  };

  const fetchDashboardView = id => {
    setLoading(true);
    http
      .post(APIS.DataBoardView, { id })
      .then(res => {
        const { id: dashboardId, name: dashboardName, charts, customComps } = res.data;
        const newCharts = convertChartsHandler(charts);
        const newWidgetConditionMap = genWidgetConditionMap(customComps.filter(filterOutWidgetBtn));
        const newChartConditionsMap = genChartConditionsMap(
          customComps.filter(filterOutWidgetBtn),
          newWidgetConditionMap
        );

        setWidgetConditionMap({
          ...widgetConditionMap,
          ...newWidgetConditionMap
        });
        setChartConditionsMap({
          ...chartConditionsMap,
          ...newChartConditionsMap
        });
        setDashboardName(dashboardName);
        store.dashboardStore.init({
          dashboardId,
          dashboardName,
          charts: newCharts,
          customComps,
          activeIndex: -1,
          dataSets: [],
          activeBreakPoint: activeDevice === 'pc' ? 'lg' : 'xs'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchResourceLink = async () => {
    const { data } = await http.post(APIS.publicGetResourceLink, {
      resourceId: id,
      resourceType: 'DATA_BOARD_RESOURCE',
      useFor: 'MOBILE'
    });
    setQRCodeConfig({
      ...QRCodeConfig,
      value: data?.shortUri
    });
  };

  const handleCompSelect = useCallback(itemComp => {
    const newItem = generateItemCompCfg(itemComp, dashboardStore.state);
    const { charts, customComps } = dashboardStore.state;
    const allComps = [...charts, ...customComps];
    if (newItem.chartType.includes('map') && newItem.chartType !== 'treemap') {
      const { name, code } = newItem.styleConfig.mapRange;
      newItem.dataConfig.mapName = name;
      newItem.dataConfig.adcode = code;
      newItem.dataConfig.drillPathAdcodes = [`${name}-${code}`];
    }
    dashboardStore.addChart({ ...newItem, order: allComps.length });
  }, []);

  const handleCustomCompSelect = useCallback(itemWidget => {
    console.log('handleItemWidgetSelect: ', itemWidget);
    const widgetInstance = getService(itemWidget.serviceName);
    const widget = {
      ...widgetInstance,
      ...widgetInstance.getDrawPanel(widgetInstance),
      widgetId: `${itemWidget.serviceName}-${uuidv4().slice(0, 6)}`
    };
    setFilterConfig({
      actionType: 'add',
      visible: !widget.component.includes('button'),
      visibleButton: widget.component.includes('button'),
      widgetLabel: t[itemWidget.label],
      widget
    });
  }, []);

  const handleSave = useCallback(
    params => {
      setIsSaving(true);
      http
        .post(
          APIS.DataBoardSave,
          dashboardStore.state.dashboardId ? { ...params, id: dashboardStore.state.dashboardId } : params
        )
        .then(res => {
          const { id, chartIdMapping } = res.data;
          dashboardStore.setDashboardId(id);
          dashboardStore.setCharts(
            dashboardStore.state.charts.map(chart => ({
              ...chart,
              id: chartIdMapping[chart.frontChartId]
            }))
          );
          message.success('仪表板保存成功');
          // generate mobile end public chain address & bind qr code
          if (activeDevice === 'mobile' && !QRCodeConfig.value) {
            generatePublicLink({ resourceId: id, resourceType: 'DATA_BOARD_RESOURCE' }).then(resData => {
              const { shortUri } = resData.data;
              setQRCodeConfig({
                refresh: true,
                value: shortUri
              });
            });
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [activeDevice]
  );

  const generatePublicLink = async ({ resourceId, resourceType }) => {
    return await http.post(APIS.PublicLinkChangeStatus, {
      resourceId,
      resourceType,
      enablePwd: 'DISABLE',
      useFor: 'MOBILE'
    });
  };

  const handleFilterWidgetEdit = useCallback(widgetId => {
    const widget = dashboardStore.state.customComps.find(comp => comp.widgetId === widgetId);
    setFilterConfig({
      actionType: 'edit',
      visible: !widget.component.includes('button'),
      visibleButton: widget.component.includes('button'),
      widgetLabel: t[widget.label],
      widget
    });
  }, []);

  const cancelWidgetFilter = () => {
    setFilterConfig({
      ...filterConfig,
      visible: false,
      visibleButton: false
    });
  };

  const confirmWidgetFilter = itemComp => {
    if (filterConfig.actionType === 'add') {
      const { charts, customComps } = dashboardStore.state;
      const allComps = [...charts, ...customComps];
      const { layout, mobileTileLayout } = genCustomCompLayout(itemComp, dashboardStore.state);
      const newItem = {
        ...itemComp,
        layout,
        mobileTileLayout,
        mobileConfig: {
          isMobileHide: false,
          hideIndex: -1
        },
        order: allComps.length
      };
      dashboardStore.addComp(newItem);
    } else {
      dashboardStore.modComp({ compId: itemComp.widgetId, updatedItem: itemComp });
    }
    const { customComps } = dashboardStore.state;
    const newChartConditionsMap = genChartConditionsMap(customComps.filter(filterOutWidgetBtn), widgetConditionMap);
    setChartConditionsMap({
      ...chartConditionsMap,
      ...newChartConditionsMap
    });
    cancelWidgetFilter();
    if (itemComp.component.includes('button')) return;
    // 设置控制范围相关图表过滤条件并更新数据
    // 单个过滤查询条件值更新触发图表数据刷新条件：
    // 1.画布区无查询按钮
    if (isWidgetRangeEmpty(itemComp) || hasWidgetSureBtn(dashboardStore)) {
      return;
    }
    // 2.check if the values of NumberRange widget are empty
    if (
      itemComp.name === 'numberRangeWidget' &&
      itemComp.options.value &&
      itemComp.options.value.split(',').some(containEmptyStr)
    ) {
      message.warning('【数值区间】起始结束值均不能为空');
      return;
    }
    setRangeChartsFilterConditions(itemComp);
  };

  const setRangeChartsFilterConditions = itemComp => {
    const {
      options: {
        attrs: { chartIds }
      }
    } = itemComp;
    const { newWidgetConditionMap, newChartConditionsMap } = updateConditionMapByWidget({
      itemComp,
      widgetConditionMap,
      dashboardStore
    });
    setWidgetConditionMap({
      ...widgetConditionMap,
      ...newWidgetConditionMap
    });
    setChartConditionsMap({
      ...chartConditionsMap,
      ...newChartConditionsMap
    });
    dashboardStore.setCharts(
      dashboardStore.state.charts.map(chart => {
        if (chartIds.includes(chart.frontChartId)) {
          return {
            ...chart,
            dataConfig: {
              ...chart.dataConfig,
              status: 'refreshRelationChartData'
            }
          };
        }
        return chart;
      })
    );
  };

  const contextValue = {
    triggerUpdate,
    setTriggerUpdate,
    activeGridItemLayout,
    setActiveGridItemLayout,
    widgetMap,
    widgetConditionMap,
    setWidgetConditionMap,
    chartConditionsMap,
    setChartConditionsMap,
    activeDevice,
    setActiveDevice,
    dashboardName,
    setDashboardName,
    QRCodeConfig,
    setQRCodeConfig,
    showWatermark,
    setShowWatermark
  };
  // console.log('render Workplace: ', widgetConditionMap, chartConditionsMap);
  return (
    <>
      {loading ? (
        <Spin className="dashboard-loading-spin" indicator={<img src={PicLoadingSpin} />} />
      ) : (
        <div className="dashboard-edit">
          <div className="dashboard-designer theme-light">
            <WorkplaceContext.Provider value={contextValue}>
              <DesignerContent
                onCompSelect={handleCompSelect}
                onCustomCompSelect={handleCustomCompSelect}
                onSave={handleSave}
                onFilterWidgetEdit={handleFilterWidgetEdit}
              />
            </WorkplaceContext.Provider>
            {isSaving && (
              <div className="dashboard-global-loading-container">
                <div className="global-loading-mask"></div>
                <div className="global-loading">
                  <Spin className="global-loading-spin" tip="正在保存中..." indicator={<img src={PicLoadingSpin} />} />
                </div>
              </div>
            )}
          </div>

          {filterConfig.visible && (
            <ModalFilter
              visible={filterConfig.visible}
              widgetLabel={filterConfig.widgetLabel}
              widget={filterConfig.widget}
              charts={dashboardStore.state.charts}
              onCancel={cancelWidgetFilter}
              onOk={confirmWidgetFilter}
            />
          )}
          {filterConfig.visibleButton && filterConfig.widget.serviceName === 'buttonSureWidget' && (
            <ModalButton
              visible={filterConfig.visibleButton}
              widgetLabel={filterConfig.widgetLabel}
              widget={filterConfig.widget}
              customComps={dashboardStore.state.customComps}
              onCancel={cancelWidgetFilter}
              onOk={confirmWidgetFilter}
            />
          )}
          {filterConfig.visibleButton && filterConfig.widget.serviceName === 'buttonResetWidget' && (
            <ModalButtonReset
              visible={filterConfig.visibleButton}
              widgetLabel={filterConfig.widgetLabel}
              widget={filterConfig.widget}
              onCancel={cancelWidgetFilter}
              onOk={confirmWidgetFilter}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Workplace;
