import { makeAutoObservable } from 'mobx';

const generateDefaultState = () => {
  return {
    activeBreakPoint: 'lg',
    dashboardId: '',
    dashboardName: '',
    charts: [],
    customComps: [],
    activeIndex: -1,
    dataSets: []
  };
};

export class DashboardStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  state = generateDefaultState();

  init({ dashboardId, dashboardName, charts, customComps, activeIndex, dataSets, activeBreakPoint }) {
    this.state.dashboardId = dashboardId;
    this.state.dashboardName = dashboardName;
    this.state.charts = charts;
    this.state.customComps = customComps;
    this.state.activeIndex = activeIndex;
    this.state.dataSets = dataSets;
    this.state.activeBreakPoint = activeBreakPoint;
  }

  setDashboardId(dashboardId) {
    this.state.dashboardId = dashboardId;
  }

  setActiveBreakPoint(breakPoint) {
    this.state.activeBreakPoint = breakPoint;
  }

  setDataSets(dataSets) {
    this.state.dataSets = dataSets;
  }

  get activeChart() {
    // return this.state.charts[this.state.activeIndex];
    return this.state.charts.find(chart => chart.order === this.state.activeIndex);
  }

  get activeComp() {
    return this.state.customComps.find(comp => comp.order === this.state.activeIndex);
  }

  setCharts(payload) {
    this.state.charts = payload;
  }

  addChart(newItem) {
    this.state.charts = [...this.state.charts, newItem];
    const allComps = [...this.state.charts, ...this.state.customComps];
    // this.state.activeIndex = this.state.charts.length - 1;
    this.state.activeIndex = allComps.length - 1;
  }

  delChart(chartId) {
    this.state.charts = this.state.charts.filter(chart => chart.chartId !== chartId);
    this.state.activeIndex = -1;
  }

  modChart({ frontChartId, updatedItem }) {
    this.state.charts = this.state.charts.map(chart => {
      if (chart.frontChartId === frontChartId) {
        return updatedItem;
      }
      return chart;
    });
  }

  setCustomComps(payload) {
    this.state.customComps = payload;
  }

  addComp(newComp) {
    this.state.customComps = [...this.state.customComps, newComp];
    const allComps = [...this.state.charts, ...this.state.customComps];
    this.state.activeIndex = allComps.length - 1;
  }

  delComp(compId) {
    this.state.customComps = this.state.customComps.filter(comp => comp.widgetId !== compId);
    this.state.activeIndex = -1;
  }

  modComp({ compId, updatedItem }) {
    this.state.customComps = this.state.customComps.map(comp => {
      if (comp.widgetId === compId) {
        return updatedItem;
      }
      return comp;
    });
  }

  setComps(comps) {
    const isCustomComp = comp => comp.type && comp.type === 'custom';
    this.state.charts = comps.filter(comp => !isCustomComp(comp));
    this.state.customComps = comps.filter(comp => isCustomComp(comp));
  }

  addExtraConfig({
    frontChartId,
    newItem,
    fieldAreaType
  }: {
    frontChartId: string;
    newItem: { [key: string]: any };
    fieldAreaType?: string;
  }) {
    this.state.charts = this.state.charts.map(chart => {
      if (chart.frontChartId === frontChartId) {
        if (chart.chartType === 'combination') {
          return {
            ...chart,
            dataConfig: {
              ...chart.dataConfig,
              extraConfigFieldMap: {
                ...chart.dataConfig.extraConfigFieldMap,
                [fieldAreaType]: [...chart.dataConfig.extraConfigFieldMap[fieldAreaType], newItem]
              }
            }
          };
        }
        return {
          ...chart,
          dataConfig: {
            ...chart.dataConfig,
            extraConfigFields: [...chart.dataConfig.extraConfigFields, newItem]
          }
        };
      }
      return chart;
    });
  }

  modExtraConfig({
    frontChartId,
    index,
    updatedItem,
    fieldAreaType
  }: {
    frontChartId: string;
    index: number;
    updatedItem: { [key: string]: any };
    fieldAreaType?: string;
  }) {
    this.state.charts = this.state.charts.map(chart => {
      if (chart.frontChartId === frontChartId) {
        if (chart.chartType === 'combination') {
          return {
            ...chart,
            dataConfig: {
              ...chart.dataConfig,
              extraConfigFieldMap: {
                ...chart.dataConfig.extraConfigFieldMap,
                [fieldAreaType]: chart.dataConfig.extraConfigFieldMap[fieldAreaType].map((item, idx) => {
                  if (idx === index) return updatedItem;
                  return item;
                })
              }
            }
          };
        }
        return {
          ...chart,
          dataConfig: {
            ...chart.dataConfig,
            extraConfigFields: chart.dataConfig.extraConfigFields.map((item, idx) => {
              if (idx === index) {
                return updatedItem;
              }
              return item;
            })
          }
        };
      }
      return chart;
    });
  }

  setActiveIndex(activeIndex) {
    this.state.activeIndex = activeIndex;
  }

  resetState() {
    this.state = generateDefaultState();
  }
}

export default new DashboardStore();
