import React, { FC, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Radio, DatePicker as AntDDatePicker } from 'antd';
import _ from 'lodash';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cn from 'date-fns/locale/zh-CN';
import { granularityZhCnMap } from '../../contrast';
registerLocale('cn', cn);

const DATE_FORMAT_MAP = {
  year: 'yyyy',
  quarter: 'yyyy,QQQ',
  month: 'yyyy-MM',
  day: 'yyyy-MM-dd'
};
const generateFormModel = () => {
  return {
    dateFilterType: 'INTERVAL',
    intervalType: 'TIME_INTERVAL',
    values: [new Date(), new Date()]
  };
};
const DatetimeConfigForm: FC<any> = forwardRef(({ granularity, complexFilter }, ref) => {
  useImperativeHandle(ref, () => ({
    formData
  }));

  const [formData, setFormData] = useState<any>(generateFormModel());

  useEffect(() => {
    initState();
  }, []);

  const initState = () => {
    const { dateFilterType, intervalType, filters } = complexFilter;
    if (filters.length) {
      setFormData({
        ...formData,
        dateFilterType,
        intervalType,
        values: filters.map(filter => convertToDate(filter.values[0]))
      });
    }
  };

  const convertToDate = val => {
    if (granularity === 'quarter') {
      const [year, quarter] = val.split('Q');
      return new Date(`${year}-${quarterToMonth(quarter)}-01`);
    }
    return new Date(dateStrGroup(val).join('-'));
  };

  const quarterToMonth = quarter => {
    switch (quarter) {
      case '1':
        return '01';
      case '2':
        return '04';
      case '3':
        return '07';
      case '4':
        return '10';
    }
  };

  const dateStrGroup = str => {
    const len = str.length;
    if (len <= 4) return [str];
    const ret = [];
    let tempStr = '';
    for (let i = 0; i < len; i++) {
      tempStr += str[i];
      if (i === 3 || i === 5 || i === 7) {
        ret.push(tempStr);
        tempStr = '';
      }
    }
    return ret;
  };

  const handleFieldValueChange = (field, val) => {
    let newFormData = _.cloneDeep(formData);
    if (field.includes('start') || field.includes('end')) {
      newFormData.values[field === 'start' ? 0 : 1] = val;
    } else {
      newFormData[field] = val;
    }
    setFormData({
      ...formData,
      ...newFormData
    });
  };

  const renderFilterTypeOpts = granularity => {
    return (
      <>
        <Radio value="SINGLE">{`单${granularityZhCnMap[granularity]}`}</Radio>
        <Radio value="INTERVAL">{`${granularityZhCnMap[granularity]}区间`}</Radio>
      </>
    );
  };

  const renderTimePicker = valIndex => (
    <DatePicker
      locale="cn"
      selected={formData.values[valIndex]}
      onChange={date => handleFieldValueChange(`${valIndex === 0 ? 'start' : 'end'}`, date)}
      showYearPicker={granularity === 'year'}
      showQuarterYearPicker={granularity === 'quarter'}
      showMonthYearPicker={granularity === 'month'}
      dateFormat={DATE_FORMAT_MAP[granularity]}
    />
  );
  // console.log('DatetimeConfigForm render: ', formData);
  return (
    <>
      <div className="form-item">
        <label className="form-item-label">过滤方式</label>
        <span className="form-item-value">
          <Radio.Group
            value={formData.dateFilterType}
            size="small"
            onChange={ev => handleFieldValueChange('dateFilterType', ev.target.value)}
          >
            {renderFilterTypeOpts(granularity)}
          </Radio.Group>
        </span>
      </div>
      {formData.dateFilterType === 'INTERVAL' && (
        <div className="form-item">
          <label className="form-item-label">区间类型</label>
          <span className="form-item-value">
            <Radio.Group
              value={formData.intervalType}
              size="small"
              onChange={ev => handleFieldValueChange('intervalType', ev.target.value)}
            >
              <Radio value="START_WITH">开始于</Radio>
              <Radio value="END_WITH">结束于</Radio>
              <Radio value="TIME_INTERVAL">时间区间</Radio>
            </Radio.Group>
          </span>
        </div>
      )}
      <div className="form-item">
        <label className="form-item-label">过滤条件</label>
        <span className="form-item-value">
          <div className="filter-datetime-config-duration">
            <div className="filter-datetime-config-duration-atom start">
              {formData.intervalType !== 'END_WITH' && (
                <div
                  className="time-config-wrap"
                  style={{ paddingTop: `${formData.dateFilterType === 'SINGLE' ? 0 : 42}px` }}
                >
                  {renderTimePicker(0)}
                </div>
              )}
            </div>
            <div className="filter-datetime-config-duration-atom end">
              {formData.dateFilterType === 'INTERVAL' && formData.intervalType !== 'START_WITH' && (
                <div className="time-config-wrap">{renderTimePicker(1)}</div>
              )}
            </div>
            {formData.dateFilterType === 'INTERVAL' && (
              <div className="filter-datetime-config-duration-line">
                {formData.intervalType !== 'END_WITH' && (
                  <div className="filter-datetime-config-duration-tag start">
                    <span>开始于</span>
                  </div>
                )}
                {formData.intervalType !== 'START_WITH' && (
                  <div className="filter-datetime-config-duration-tag end">
                    <span>结束于</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </span>
      </div>
    </>
  );
});

export default DatetimeConfigForm;
