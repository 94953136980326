import { observable, action, makeAutoObservable } from 'mobx';
import { ChartType } from '@/pages/dataBoard/components/ChartType';
export interface IChart {
  id: string;
  name: string;
  icon: string;
  chartType: ChartType;
  chartsType: string;
  styleConfig: IStyleConfig;
  autoRefrenshConfig?: IChartAutoRefrenshConfig;
  drillDownConfig?: any;
  [key: string]: any;
}

/**
 * 图表自动刷新时间间隔单位
 */
export enum ChartAutoRefrenshInvtervalTimeUnit {
  MIN = '分',
  HOURS = '时'
}

export interface IChartAutoRefrenshConfig {
  /**
   * 是否启用自动刷新
   */
  enable?: boolean;

  /**
   * 自动刷新间隔
   */
  interval?: number;

  /**
   * 时间间隔单位
   */
  intervalTimeUnit?: ChartAutoRefrenshInvtervalTimeUnit;
}

export interface IStyleConfig {
  //放大或缩小倍数
  scale: number;
  // 图表当前占比
  flexBase: number;
  // 图表高度
  heigth: number;
  // 图表宽度
  width: number;
  // 请输入终点值
  endValue: number;
  // 图表名称
  chartName: string;
  // x轴名称
  xName: string;
  // y轴名称
  yName: string;
  // x轴单位
  xUnit: string;
  // y轴单位
  yUnit: string;
  // 图表颜色
  color: string | string[];
  // 前缀
  prefix: string;
  // 后缀
  suffix: string;
  // 字体颜色
  fontSize: number;
  // 地图图例配色
  colorBarIndex: number;
  // 地图取值范围最小值
  min: number;
  // 地图取值范围最大值
  max: number;
}

export interface IDataProcessions {
  key: string;
  value: string;
}

export interface IDataProcessingFields {
  dimensions: any[];
  measures: any[];
}
export class DataBoardStore {
  constructor() {
    makeAutoObservable(this);
  }
  public chartList = [];
  public chartRef = null;
  public isLoadingCanvasData: boolean = false; // 画布区数据加载中
  public isLoadingChartData: boolean = false; // 图表数据加载中
  public currentIndex: number = -1; // 当前点击的图表索引
  public currentChart: IChart = null; // 当前点击的图表
  public dataProcessions: IDataProcessions[] = []; //数据加工下拉数据
  public dataSetId: string = ''; //数据加工id
  public dataSetFields: IDataProcessingFields = { dimensions: [], measures: [] }; // 选择的数据加工中对应的度量和维度数据
  public activeDashBoard: string = ''; // 当前仪表板Id

  public setChartList(data) {
    this.chartList = data;
  }

  public setChartRef(ref) {
    this.chartRef = ref;
  }

  public setCurrentChart(chartInfo) {
    this.currentChart = chartInfo;
  }

  public setCurrentIndex(index: number) {
    if (this.currentIndex !== index) {
      this.currentIndex = index;
    }
  }

  /**
   * 设置选择的数据加工id  保存提交的时候需要用到
   * @param id
   */
  public setDataSetId(id) {
    this.dataSetId = id;
  }

  /**
   * 设置选择的数据集列表
   * @param id
   */
  public setDataProcessions(list) {
    this.dataProcessions = list;
  }

  /**
   * 设置数据加工数据
   * @param fields
   */
  public setDataSetFields = fields => {
    this.dataSetFields = fields;
  };

  /**
   * 清空状态缓存数据
   */
  public resetStore() {
    this.chartList = [];
    this.chartRef = null;
    this.currentIndex = -1;
    this.currentChart = null as any;
    this.dataProcessions = [];
    this.dataSetId = '';
    this.dataSetFields = { dimensions: [], measures: [] };
  }

  /**
   *
   */
  public resetChart() {
    this.chartList = [];
    this.currentIndex = -1;
    this.currentChart = null as any;
  }

  public initChart(chartList) {
    this.chartList = chartList;
    this.currentIndex = 0;
    this.currentChart = chartList[0];
  }

  public setActiveDashBoardId(payload) {
    this.activeDashBoard = payload;
  }

  public setIsLoadingChartData(payload) {
    this.isLoadingChartData = payload;
  }

  public setIsLoadingCanvasData(payload) {
    this.isLoadingCanvasData = payload;
  }

  public pushStackDrillNames(index, payload) {
    if (this.chartList[index]['stackDrillNames']) {
      this.chartList[index]['stackDrillNames'].push(payload);
    } else {
      this.chartList[index]['stackDrillNames'] = [payload];
    }
  }

  public popStackDrillNames(index) {
    this.chartList[index]['stackDrillNames'].pop();
  }

  public clearStackDrillNames(index) {
    this.chartList[index]['stackDrillNames'] = [];
  }
}

const dataBoardStore = new DataBoardStore();

export default dataBoardStore;
