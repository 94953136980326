import React, { FC, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Radio, Input, Select, Row, Col } from 'antd';
import './index.less';

interface ICondition {
  fieldUniqueId: string;
  operator: ConditionOperator;
  conditionJoin: ConditionJoin;
  values: any[];
}
interface IProps {
  mode?: 'multiple' | 'single';
  fieldOpts?: any[];
  sqlConditionJoin?: ConditionJoin;
  filters: ICondition[];
  itemField?: any;
  ref: any;
}
interface IRef { }
const SQL_OPERATOR_MAP = {
  EQUALS: '等于',
  NOT_EQUALS: '不等于',
  LIKE: '包含',
  NOT_LIKE: '不包含',
  GT: '大于',
  LT: '小于',
  LE: '小于等于',
  GE: '大于等于',
  IS_NULL: '为空',
  IS_NOT_NULL: '不为空',
  IN: '在列表中',
  NOT_IN: '不在列表中',
  BETWEEN: '区间'
};
const { Option } = Select;
const FieldFilter: FC<IProps> = forwardRef<any, IProps>(
  ({ mode, fieldOpts, sqlConditionJoin, filters, itemField }, ref) => {
    useImperativeHandle(ref, () => ({
      filterConditions,
      conditionJoin
    }));
    const [filterConditions, setFilterConditions] = useState<Array<any>>([]);
    const [conditionJoin, setConditionJoin] = useState(sqlConditionJoin || 'AND');
    useEffect(() => {
      if (filters) {
        setFilterConditions(filters);
      }

    }, []);
    const renderFilterCondition = condition => {
      const { id, fieldUniqueId, operator, values } = condition;

      const handleSqlOperatorValChange = (selectedVal, id) => {
        setFilterConditions(
          filterConditions.map(item => {
            if (item.id === id) {
              return {
                ...item,
                operator: selectedVal
              };
            }
            return item;
          })
        );
      };

      const handleValuesChange = (ev, id) => {
        setFilterConditions(
          filterConditions.map(item => {
            if (item.id === id) {
              return {
                ...item,
                values: [ev.target.value]
              };
            }
            return item;
          })
        );
      };

      const handleFieldUniqueIdValChange = (selectedVal, id) => {
        setFilterConditions(
          filterConditions.map(item => {
            if (item.id === id) {
              return {
                ...item,
                fieldUniqueId: selectedVal
              };
            }
            return item;
          })
        );
      };

      const isRangeValue = ['IN', 'NOT_IN', 'BETWEEN'].includes(operator);
      const inputPlaceholderTips = isRangeValue ? '使用英文逗号分隔,例如:A,B' : '过滤值';
      return (
        <Row className="filter-item" key={id}>
          <Col span={6}>
            {mode && mode === 'multiple' ? (
              <Select
                placeholder="请选择"
                size="small"
                dropdownClassName="small-select-dropdown"
                value={fieldUniqueId}
                onChange={selectedVal => handleFieldUniqueIdValChange(selectedVal, id)}
                showSearch
                filterOption={(input, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {fieldOpts.map(opt => (
                  <Option key={opt.uniqueId} value={opt.uniqueId}>
                    {opt.customName}
                  </Option>
                ))}
              </Select>
            ) : (
              <span className="field-name">{itemField.customName}</span>
            )}
          </Col>
          <Col span={6}>
            <Select
              size="small"
              dropdownClassName="small-select-dropdown"
              value={operator}
              onChange={selectedVal => handleSqlOperatorValChange(selectedVal, id)}
            >
              {Object.keys(SQL_OPERATOR_MAP).map(item => (
                <Option key={item} value={item}>
                  {SQL_OPERATOR_MAP[item]}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Input
              size="small"
              value={values[0]}
              onChange={ev => handleValuesChange(ev, id)}
              placeholder={inputPlaceholderTips}
              disabled={operator.includes('NULL')}
            />
          </Col>
          <Col span={6}>
            <Button type="link" icon="delete" onClick={() => removeItemCondition(id)} />
          </Col>
        </Row>
      );
    };
    const handleConJoinValChange = ev => {
      setConditionJoin(ev.target.value);
    };
    const addItemCondition = () => {
      const newItem = {
        id: uuidv4(),
        fieldUniqueId: mode === 'multiple' ? void 0 : itemField.uniqueId,
        operator: 'EQUALS',
        conditionJoin,
        values: ['']
      };
      setFilterConditions([...filterConditions, newItem]);
    };
    const removeItemCondition = id => {
      setFilterConditions(filterConditions?.filter(item => item.id !== id));
    };
    console.log('FieldFilter render: ', filterConditions);
    return (
      <div className="field-filter-wrapper">
        <div className="filter-head">
          <Button shape="circle" icon="plus" size="small" onClick={addItemCondition} />
          {filterConditions && filterConditions.length > 1 && (
            <Radio.Group
              defaultValue={conditionJoin}
              buttonStyle="solid"
              size="small"
              onChange={handleConJoinValChange}
            >
              <Radio.Button value="AND">与</Radio.Button>
              <Radio.Button value="OR">或</Radio.Button>
            </Radio.Group>
          )}
        </div>
        {filterConditions && filterConditions.length > 0 && (
          <div className="filter-body">{filterConditions.map(condition => renderFilterCondition(condition))}</div>
        )}
      </div>
    );
  }
);

export default FieldFilter;
