export const DATE_FORMAT_ENUM_VALUE = {
  ymdzh: '2019年01月30日',
  ymdhmszh: '2019年01月30日 12时00分00秒',
  ymd1: '2019-01-30',
  ymdhms1: '2019-01-30 12:00:00',
  ymd2: '2019/01/30',
  ymdhms2: '2019/01/30 12:00:00'
};

export const DATE_FORMAT_TYPES = {
  ymdzh: 'YYYY年MM月DD日',
  ymdhmszh: 'YYYY年MM月DD日 HH时mm分ss秒',
  ymd1: 'YYYY-MM-DD',
  ymdhms1: 'YYYY-MM-DD HH:mm:ss',
  ymd2: 'YYYY/MM/DD',
  ymdhms2: 'YYYY/MM/DD HH:mm:ss'
};

export const WEEK_SELECT_ENUM_VALUE = {
  noweek: '不显示',
  toWest: '星期一',
  toChina: '周一'
};

export const WEEK_FORMAT_TYPES = {
  noweek: '',
  toWest: '星期dd',
  toChina: '周dd'
};
