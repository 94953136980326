import React, { FC, useState, useEffect } from 'react';
import LinkError from './components/Error';
import LinkPwd from './components/Pwd';
import LinkView from './components/View';
import { http } from '@/lib';
import APIS from '@/apis/api';
import Stores from '@/store';
import { StoresContext } from '@/store/context';

interface IProps {
  link: string;
}
const Link: FC<IProps> = ({ link }) => {
  const [state, setState] = useState({
    showIndex: -1,
    resourceId: '',
    resourceType: '',
    secrete: ''
  });
  useEffect(() => {
    // 01.validate link is legal
    http
      .post(
        APIS.LinkValid,
        {
          shortCode: link
        },
        Object.assign(
          {},
          localStorage.getItem('secrete')
            ? {
                headers: {
                  Authorization: localStorage.getItem('secrete')
                }
              }
            : {}
        )
      )
      .then(res => {
        const { enablePwd, resourceId, resourceType, secrete, isPassPwd, userId } = res.data;
        if (enablePwd === 'ENABLE' && !isPassPwd) {
          // 03.enable password
          setState({
            ...state,
            showIndex: 1,
            resourceType
          });
          return;
        }
        setState({
          ...state,
          showIndex: 2,
          secrete: secrete || localStorage.getItem('secrete'),
          resourceId: resourceId,
          resourceType
        });
      });
    // 02.expire
  }, []);
  const handleFreshToken = values => {
    http
      .post(APIS.LinkCheckPwd, {
        shortCode: link,
        password: values.password
      })
      .then(res => {
        const { resourceId, secrete, enablePwd, userId } = res.data;
        setState({
          ...state,
          showIndex: 2,
          resourceId,
          secrete
        });
        localStorage.setItem('secrete', secrete);
      });
  };
  return (
    <div className="link-wrapper">
      {state.showIndex === 0 && <LinkError />}
      {state.showIndex === 1 && <LinkPwd shortCode={link} onFreshToken={handleFreshToken} />}
      {state.showIndex === 2 && (
        <StoresContext.Provider value={Stores}>
          <LinkView resourceType={state.resourceType} resourceId={state.resourceId} token={state.secrete} />
        </StoresContext.Provider>
      )}
    </div>
  );
};

export default Link;
