import React, { FC, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';
import { Form, Select, Radio, Tooltip, Icon } from 'antd';
import { getContrastMenuByFieldGranularity } from './contrast';
import { GRANULARITY_TYPE_ICONS } from '@/utils/enum';
import './style/contrast-setting-form.less';

const formItemLayout = {
  labelCol: {
    sm: { span: 4 }
  },
  wrapperCol: {
    sm: { span: 20 }
  }
};
const { Option } = Select;
const ContrastSettingForm: FC<any> = forwardRef(({ fieldOpts, fieldsValue, hasDynamicRingRatio }, ref) => {
  useImperativeHandle(ref, () => ({
    formData
  }));
  const [formData, setFormData] = useState({
    dateField: '',
    type: '',
    valueType: '',
    granularity: ''
  });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setFormData({
      ...formData,
      ...fieldsValue
    });
  };

  const renderContrastType = useCallback(() => {
    return (
      <>
        {getContrastMenuByFieldGranularity(GRANULARITY_TYPE_ICONS[formData.granularity]).map(item => (
          <Radio key={item.value} value={item.value}>
            {item.label}
          </Radio>
        ))}
        {hasDynamicRingRatio && (
          <Radio value="DYNAMIC_RING_RATIO">
            动态环比
            <Tooltip title="根据筛选条件动态去环比">
              <Icon type="question-circle-o" style={{ marginLeft: '2px' }} />
            </Tooltip>
          </Radio>
        )}
      </>
    );
  }, [formData.granularity]);

  const handleFieldValueChange = (field, val) => {
    const newFormData = _.cloneDeep(formData);
    if (field === 'dateField') {
      const {
        attribute: { granularity }
      } = fieldOpts.filter(item => item.uniqueId === val)[0];
      newFormData.granularity = granularity;
      newFormData.type = getContrastMenuByFieldGranularity(GRANULARITY_TYPE_ICONS[granularity])[0].value;
    }
    newFormData[field] = val;
    setFormData({
      ...formData,
      ...newFormData
    });
  };
  return (
    <div className="contrast-setting-form-wrapper">
      <Form {...formItemLayout} colon={false} labelAlign="right">
        <Form.Item label="对比日期">
          <Select
            value={formData.dateField}
            onChange={val => handleFieldValueChange('dateField', val)}
            size="small"
            dropdownClassName="select-opt-wrapper"
          >
            {fieldOpts.map(field => (
              <Option key={field.uniqueId} value={field.uniqueId}>
                {field.customName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="对比类型">
          <Radio.Group
            value={formData.type}
            size="small"
            onChange={ev => handleFieldValueChange('type', ev.target.value)}
          >
            {formData.granularity && renderContrastType()}
          </Radio.Group>
        </Form.Item>
        <Form.Item label="数据设置">
          <Radio.Group
            value={formData.valueType}
            size="small"
            onChange={ev => handleFieldValueChange('valueType', ev.target.value)}
          >
            <Radio value="LAST_VALUE">具体数值</Radio>
            <Radio value="INCREASE_VALUE">对比差值</Radio>
            <Radio value="INCREASE_RATIO">差值百分比</Radio>
            <Radio value="PROPORTION">比值百分数</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  );
});

export default ContrastSettingForm;
