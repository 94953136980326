import _ from 'lodash'
import React, { Component, ReactNode } from 'react'
import { Row, Col, Card } from 'antd'
import classNames from 'classnames'
import { ColProps } from 'antd/lib/col'
import { ClassValue } from 'classnames/types'

/** 
 * 详情展示 
 */
class UdDetail extends Component<IUdDetailProps> {

  static defaultProps: Partial<IUdDetailProps> = {
    nullText: '--',
    rowGutter: 15,
    col: { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 8 },
    dataSource: {}
  }

  render() {
    let { items, dataSource, title, extra, actions, inner } = this.props

    let nodes = items.map((item, index) => {
      if (_.isArray(item)) {
        item = { title: item[0], dataIndex: item[1], render: item[2], ...item[3] }
      }
      let value = _.get(dataSource, item.dataIndex, null)
      if (_.isFunction(item.show) && item.show(value, dataSource, index) === false) {
        return
      }
      let nullText = item.nullText || this.props.nullText
      let col = item.col || this.props.col
      let node
      if (value == null || (value == '' && value !== 0)) {
        node = <div className="item-content-null">{nullText}</div>
      } else {
        if (_.isFunction(item.render)) {
          node = item.render(value, dataSource, index)
        } else {
          node = <div className="item-content-default">{value}</div>
        }
      }
      return (
        <Col key={item.dataIndex} {...col} className={classNames('col-' + item.dataIndex, item.className)}>
          <div className="item-title">{item.title}：</div>
          <div className="item-content">{node}</div>
        </Col>
      )
    })

    let row = <Row gutter={this.props.rowGutter}>{nodes}</Row>
    let content = row
    if (title) {
      content = <Card title={title} extra={extra} actions={actions} bordered={inner !== true} size="small">{row}</Card>
    }
    return (
      <div className={classNames('ud-detail', this.props.className)}>
        {content}
      </div>
    )
  }

}

interface IUdDetailProps {
  /** 
   * 标题 
   */
  title?: ReactNode
  /**
   * 扩展区域
   */
  extra?: ReactNode
  /**
   * 按钮区域
   */
  actions?: ReactNode[]
  /** class，始终会带一个 ud-detail 的 class */
  className?: ClassValue
  /** 
   * 父级是否为详情分组
   * @default false
   */
  inner?: boolean
  /** 
   * 数据源对象
   * @default {}
   */
  dataSource?: any
  /** 
   * 内容为空时显示的内容，
   * @default -- 
   */
  nullText?: ReactNode
  /** 
   * 每列间距
   * @default 15
   */
  rowGutter?: number
  /** 
   * 排列方式
   * @default { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 8 }
   */
  col?: ColProps
  /** 
   * 字段集合 
   */
  items: IUdDetailItem<any>[]
}

export type IUdDetailItem<T = any> = IUdDetailItemStandard<T> | IUdDetailItemConcise<T>

export type IUdDetailItemConcise<T> = [string, string, ((text: any, model: T, index: number) => React.ReactNode)?, Partial<IUdDetailItemStandard<T>>?]

export interface IUdDetailItemStandard<T = any> {
  /** 标题 */
  title: ReactNode
  /** 字段名 */
  dataIndex: string
  /** 内容为空时显示的内容 */
  nullText?: ReactNode
  /** 是否显示 */
  show?: (text: any, model: T, index: number) => boolean
  /** class，始终会带一个 col-{dataIndex} 的 class */
  className?: ClassValue
  /** render函数，不传则使用普通文本来显示 */
  render?: (text: any, model: T, index: number) => React.ReactNode
  /** 排列方式 */
  col?: ColProps
}

export { UdDetail }
