import React, { useContext, useRef } from 'react';
import { Modal, message } from 'antd';
import { LinkageContext } from './ctx-linkage';
import SvgIcon from '@/components/SvgIcon';
import LinkageContent from './linkage-content';

function ModalLinkage({ visible, onCancel, onOk }: any) {
  const { activeChart, dataSets } = useContext(LinkageContext);
  const {
    styleConfig: { title },
    dataConfig: { dataSetId }
  } = activeChart;
  const linkageContentRef = useRef(null);

  const renderTitle = () => {
    return (
      <div className="linkage-modal-header">
        <h3>图表联动设置</h3>
        <span className="label">已选择:</span>
        <span className="result-label caption">
          <span className="result-label">{title}</span>
        </span>
        <span className="label">数据集:</span>
        {/* <i className="svg-icon-wrapper icon-cube">
          <SvgIcon iconClass="cube" />
        </i> */}
        <span className="result-label cube">{dataSets.filter(dataSet => dataSet.key === dataSetId)[0].value}</span>
      </div>
    );
  };
  const handleOk = () => {
    // validate: 1.已选择被关联字段 2.已选择关联的图表 3.非同源图表已选中筛选字段
    let isValid = true;
    const relationFields = linkageContentRef.current.relationFields;
    for (let item of relationFields) {
      const { uniqueId, customName, charts } = item;
      const isCheckedChart = charts.filter(chart => chart.checked).length > 0;
      const diffDsCharts = charts.filter(chart => chart.group === 'diffDs');
      if (!uniqueId) {
        isValid = false;
        message.warning('请选择被关联维度字段');
        break;
      }
      if (!isCheckedChart) {
        isValid = false;
        message.warning(`「${customName}」请选择需要关联的图表`);
        break;
      }
      if (diffDsCharts.some(chart => chart.checked && chart.fieldOpts.every(option => !option.checked))) {
        isValid = false;
        message.warning(`「${customName}」字段您有非同源图表未选中筛选字段哦!`);
        break;
      }
    }
    if (!isValid) return;
    const relationChart = {};
    relationFields.forEach(relationField => {
      const { uniqueId, charts } = relationField;
      charts
        .filter(chart => chart.checked)
        .forEach(({ frontChartId, group, fieldOpts }) => {
          if (group === 'sameDs') {
            const key = [frontChartId, uniqueId, uniqueId, activeChart.frontChartId].join('*-');
            relationChart[key] = true;
          } else {
            const checkedFields = fieldOpts.filter(field => field.checked);
            checkedFields.forEach(field => {
              const key = [frontChartId, uniqueId, field.uniqueId, activeChart.frontChartId].join('*-');
              relationChart[key] = true;
            });
          }
        });
    });
    onOk({ relationChart, dataSetFieldMap: linkageContentRef.current.dataSetFieldMap });
  };

  return (
    <Modal
      title={renderTitle()}
      visible={visible}
      width={946}
      maskClosable={false}
      wrapClassName="linkage-modal"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <LinkageContent ref={linkageContentRef} />
    </Modal>
  );
}

export default ModalLinkage;
