import _ from 'lodash'
import React, { PureComponent } from 'react'
import { DatePicker } from 'antd'
import { IUdFormItem, Modify } from '../../..'
import moment from 'moment'
import 'moment/locale/zh-cn.js'
import { RangePickerValue, RangePickerProps } from 'antd/lib/date-picker/interface'

moment.locale('zh-cn')

const { RangePicker } = DatePicker

class UdDateRangePicker extends PureComponent<IUdDateRangePickerProps, IUdDateRangePickerState> {

  constructor(props: IUdDateRangePickerProps) {
    super(props);
    this.state = {
      values: [],
    }
  }

  componentDidMount() {
    this.handleProps(this.props)
  }

  componentWillReceiveProps(nextProps: Readonly<IUdDateRangePickerProps>) {
    this.handleProps(nextProps)
  }

  render() {
    let values: any = {}
    if (this.state.values && this.state.values.length > 0) {
      values.value = this.state.values
    }
    return (
      <RangePicker className="ud-range-picker" {...this.props} {...values} onChange={this.triggerChange} />
    )
  }

  private handleProps = (props: IUdDateRangePickerProps) => {
    if (props.value && (props.value[0] || props.value[1])) {
      this.setState({ values: [moment(props.value[0]), props.value[1] ? moment(props.value[1]) : ''] })
    } else {
      this.setState({ values: null })
    }
  }

  private triggerChange = (dates: RangePickerValue, dateStrings: [string, string]) => {
    const { valueFormat } = this.props
    if (valueFormat && dateStrings[1]) {
      const formatDate = [moment(dateStrings[0]).format(valueFormat[0]), moment(dateStrings[1]).format(valueFormat[1])]
      this.props.onChange!(formatDate)
    } else {
      this.props.onChange!(dateStrings)
    }
  }

}

interface IUdDateRangePickerProps extends Partial<IUdFormItem>, Modify<RangePickerProps, {
  id?: string | string[]
}> {
  value?: any
  onChange?(value: any): void
  valueFormat?: string[]
}


interface IUdDateRangePickerState {
  values?: any
}

export default UdDateRangePicker
