import React, { useState, useEffect, useMemo } from 'react';
import { Form, Radio, Select, InputNumber, Row, Col } from 'antd';
import _ from 'lodash';
import useLocale from '@/utils/useLocale';
import locale from '@/components/widget/locale';
import { getService } from '@/components/widget/utils';
import IbDate from '@/components/widget/ib-widget/ib-date';
import '../style/ib-date-default.less';

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 18 }
  }
};
const { Option } = Select;
function IbDateRangeDefault({ widget, onWidgetPropChange, onFixedValueChange }: any) {
  const t = useLocale(locale);
  const [formData, setFormData] = useState(null);
  const defaultSetting = useMemo(() => {
    const widgetInstance = getService(widget.serviceName);
    return widgetInstance.getDefaultSetting();
  }, [widget.serviceName]);
  const defaultValue = useMemo(() => {
    const { serviceName } = widget;
    const widgetInstance = getService(serviceName);
    return widgetInstance.dynamicDateFormNow(formData, widget);
  }, [JSON.stringify(formData)]);
  const showTime = useMemo(() => {
    return widget.options.attrs.showTime;
  }, [widget.options.attrs.showTime]);
  const accuracy = useMemo(() => {
    return widget.options.attrs.accuracy;
  }, [widget.options.attrs.accuracy]);

  useEffect(() => {
    const newFormData = _.cloneDeep(widget.options.attrs.default);
    const widgetInstance = getService(widget.serviceName);
    let fixedValue = widget.options.attrs.default.fixedValue || null;
    if (widget.options.attrs.showTime) {
      newFormData.fixedValue = widgetInstance.formatDynamicTimes(fixedValue, widget);
      onFixedValueChange(newFormData.fixedValue);
    }
    setFormData(newFormData);
  }, [widget.options.attrs.showTime, widget.options.attrs.accuracy]);

  const handleChange = (key, val) => {
    const newFormData = _.set(formData, key, val);

    setFormData({
      ...formData,
      ...newFormData
    });
    onWidgetPropChange(`options.attrs.default.${key}`, val);
  };

  return (
    <div className="ib-date-range-default-wrapper">
      {formData && (
        <Form {...formItemLayout} colon={false}>
          <Form.Item label={t['dynamic.time.setDefault']}>
            <Radio.Group value={formData.isDynamic} onChange={ev => handleChange('isDynamic', ev.target.value)}>
              {defaultSetting.radioOptions.map(opt => (
                <Radio key={opt.key} value={opt.value}>
                  {t[opt.text]}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {formData.isDynamic && (
            <Form.Item label={t['dynamic.time.relative']}>
              <Select value={formData.dkey} onChange={val => handleChange('dkey', val)} dropdownClassName="light-theme">
                {defaultSetting.relativeOptions.map(opt => (
                  <Option key={opt.key} value={opt.value}>
                    {t[opt.text]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {formData.isDynamic && formData.dkey === 4 && (
            <>
              <Form.Item label={t['dataset.startTime']}>
                <Row gutter={12}>
                  <Col span={8}>
                    <InputNumber
                      min={0}
                      max={100}
                      value={formData.sDynamicPrefix}
                      onChange={val => handleChange('sDynamicPrefix', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <Select
                      value={formData.sDynamicInfill}
                      onChange={val => handleChange('sDynamicInfill', val)}
                      dropdownClassName="light-theme"
                    >
                      {defaultSetting.custom.unitsOptions.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                          {t[opt.text]}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Select
                      value={formData.sDynamicSuffix}
                      onChange={val => handleChange('sDynamicSuffix', val)}
                      dropdownClassName="light-theme"
                    >
                      <Option key="before" value="before">
                        {t['dynamic.time.before']}
                      </Option>
                      <Option key="after" value="after">
                        {t['dynamic.time.after']}
                      </Option>
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label={t['dataset.endTime']}>
                <Row gutter={12}>
                  <Col span={8}>
                    <InputNumber
                      min={0}
                      max={100}
                      value={formData.eDynamicPrefix}
                      onChange={val => handleChange('eDynamicPrefix', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <Select
                      value={formData.eDynamicInfill}
                      onChange={val => handleChange('eDynamicInfill', val)}
                      dropdownClassName="light-theme"
                    >
                      {defaultSetting.custom.unitsOptions.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                          {t[opt.text]}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Select
                      value={formData.eDynamicSuffix}
                      onChange={val => handleChange('eDynamicSuffix', val)}
                      dropdownClassName="light-theme"
                    >
                      <Option key="before" value="before">
                        {t['dynamic.time.before']}
                      </Option>
                      <Option key="after" value="after">
                        {t['dynamic.time.after']}
                      </Option>
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            </>
          )}
          {formData.isDynamic && (
            <Form.Item label={t['dynamic.time.preview']}>
              <IbDate
                widgetId={widget.widgetId}
                className="relative-time"
                mode={widget.options.attrs.type}
                inDraw={false}
                readOnly={true}
                defaultValue={defaultValue}
                element={widget}
                showTime={showTime}
                accuracy={accuracy}
              />
            </Form.Item>
          )}
          {!formData.isDynamic && (
            <Form.Item label={t['dynamic.time.set']}>
              <IbDate
                widgetId={widget.widgetId}
                className="relative-time"
                mode={widget.options.attrs.type}
                inDraw={false}
                readOnly={false}
                element={widget}
                defaultValue={formData.fixedValue}
                showTime={showTime}
                accuracy={accuracy}
                onDateChange={dateStr => handleChange('fixedValue', dateStr)}
              />
            </Form.Item>
          )}
        </Form>
      )}
    </div>
  );
}

export default IbDateRangeDefault;
