export const granularityRE = /\(([^()]*)\)/;
export const GRANULARITY_TYPE = ['year', 'quarter', 'month', 'day'];
/**
 * 日期字段粒度权重
 */
export const granularityWeightMap = {
  year: 1,
  quarter: 5,
  month: 10,
  day: 100
};

/**
 * 过滤普通字段，只保留粒度字段
 */
export const filterOutNormalFields = fields => {
  const ret = [];
  const granularityType = ['year', 'quarter', 'month', 'day'];
  if (!fields || (Array.isArray(fields) && !fields.length)) return ret;
  const fieldGroup = fields
    .filter(({ customName }) => {
      return granularityRE.exec(customName) && granularityType.includes(granularityRE.exec(customName)[1]);
    })
    .map(field => ({
      ...field,
      group: field.customName.replace(granularityRE, '')
    }))
    .reduce((acc, val) => {
      (acc[val.group] = acc[val.group] || []).push(val);
      return acc;
    }, {});
  Object.keys(fieldGroup).forEach(key => {
    const val = fieldGroup[key];
    if (val.length === 1) {
      ret.push(val[0]);
      return;
    }
    let highWeight = 0;
    let tempField = null;
    val.forEach(item => {
      const granularity = granularityRE.exec(item.customName)[1];
      if (granularityWeightMap[granularity] > highWeight) {
        highWeight = granularityWeightMap[granularity];
        tempField = item;
      }
    });
    ret.push(tempField);
  });
  return ret;
};

/**
 * 根据字段粒度生成同环比菜单
 * @param granularity 字段粒度
 */
export const getContrastMenuByFieldGranularity = granularity => {
  switch (granularity) {
    case 'year':
      return [{ label: '年环比', value: 'YEAR_RING_RATIO' }];
    case 'quarter':
      return [
        { label: '季环比', value: 'QUARTER_RING_RATIO' },
        { label: '年同比', value: 'QUARTER_YEAR_RATIO' }
      ];
    case 'month':
      return [
        { label: '月环比', value: 'MONTH_RING_RATIO' },
        { label: '年同比', value: 'MONTH_YEAR_RATIO' }
      ];
    case 'day':
      return [
        { label: '日环比', value: 'DAY_RING_RATIO' },
        { label: '月同比', value: 'DAY_MONTH_RATIO' },
        { label: '年同比', value: 'DAY_YEAR_RATIO' }
      ];
  }
};

export const granularityZhCnMap = {
  year: '年',
  quarter: '季度',
  month: '月',
  day: '日'
};

export const granularityMap = {
  YEAR: '年',
  YEAR_QUARTER: '季度',
  YEAR_MONTH: '月',
  YEAR_MONTH_DAY: '日'
};

export const granularityIconMap = {
  YEAR: 'year',
  YEAR_QUARTER: 'quarter',
  YEAR_MONTH: 'month',
  YEAR_MONTH_DAY: 'day'
};
