import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useStore } from '@/store/useStore';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import cls from 'classnames';
import _ from 'lodash';
import { Select, Input, Tree, Spin } from 'antd';
import SvgIcon from '@/components/SvgIcon';
import { http } from '@/lib';
import APIS from '@/apis/api';
import { generateFieldList, getParentKey, flattenDataSetFields } from './tree-methods';
import { FIELD_TYPES, FIELD_VALUE_TYPE_ICONS, GRANULARITY_TYPE_ICONS, granularityMap } from '@/utils/enum';
import PicLoadingSpin from '@/assets/spin-loading.gif';
import './style/data-panel-cube.less';
import { containsIgnoreCase } from '@/utils/utils';

const FIELD_DIMENSION_COLOR = '#0089c4';
const FIELD_MEASURE_COLOR = '#229445';
const FieldTreeWrapper = styled.div``;
const FieldItem = styled.div<any>`
  border: 1px
    ${props =>
      props.isDragging
        ? `solid ${props.type === 'DIMENSION' ? FIELD_DIMENSION_COLOR : FIELD_MEASURE_COLOR}`
        : 'solid transparent'};
  transform: ${props => !props.isDragging && 'translate(0px, 0px) !important'};
  background-color: ${props =>
    props.isDragging
      ? `${props.type === 'DIMENSION' ? 'rgba(0, 137, 196, 0.15)' : 'rgba(34, 148, 69, 0.15)'}`
      : 'transparent'};
  .field-tree-title {
    color: ${props =>
      props.isDragging
        ? `${props.type === 'DIMENSION' ? FIELD_DIMENSION_COLOR : FIELD_MEASURE_COLOR}`
        : 'hsla(0, 0%, 0%, 0.6)'};
  }
`;
const Clone = styled(FieldItem)``;
const { Option } = Select;
const { Search } = Input;
const { TreeNode } = Tree;

const fieldList = [];
function DataPanelTree({ type, treeData, searchVal, expandedKeys, autoExpandParent, onExpand }) {
  const loop = data => {
    return data.map((item, idx) => {
      const index = item.title.indexOf(searchVal);
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchVal.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#0089c4' }}>{searchVal}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children && item.children.length) {
        return (
          <TreeNode
            className="field-tree-attr"
            key={item.key}
            title={<TreeNodeTitle node={{ ...item, title, index: idx }} />}
          >
            {loop(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          className="field-tree-attr"
          key={item.key}
          title={<TreeNodeTitle node={{ ...item, title, index: idx }} />}
        />
      );
    });
  };
  // console.log('render DataPanelTree: ', treeData);
  return (
    <div className={`data-panel-${type}-tree`}>
      <div className="data-panel-tree-title">{FIELD_TYPES[type]}</div>
      <Tree
        className={cls(['field-tree', `field-tree-${type}`])}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        defaultExpandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        blockNode
        defaultExpandAll
      >
        {loop(treeData)}
      </Tree>
    </div>
  );
}

function TreeNodeTitle({ node }) {
  const {
    uniqueId,
    attribute: { type },
    index
  } = node;
  // console.log('render TreeNodeTitle: ', node);
  return (
    <Draggable key={uniqueId} draggableId={uniqueId} index={index}>
      {(provided, snapshot) => (
        <React.Fragment>
          <FieldItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            style={provided.draggableProps.style}
            type={type}
            className={cls('field-item', {
              'is-dragging': snapshot.isDragging,
              'filed-item-dimension': type === 'DIMENSION',
              'field-item-measure': type === 'MEASURE'
            })}
          >
            <FieldTreeTitle node={node} />
          </FieldItem>
          {snapshot.isDragging && (
            <Clone type={type}>
              <FieldTreeTitle node={node} />
            </Clone>
          )}
        </React.Fragment>
      )}
    </Draggable>
  );
}

function FieldTreeTitle({ node }) {
  const {
    customName,
    attribute: { type, valueType, granularity, dateFormat, customFieldFunction },
    derivedFields
  } = node;
  const isHierarchyField = valueType === 'DATE' && derivedFields.length;
  const isDerivedField = valueType === 'DATE' && !!customFieldFunction;
  const isNormalField = !isHierarchyField && !isDerivedField;
  return (
    <span className="field-tree-title">
      <div className="field-tree-left">
        <div className={type.toLowerCase()}>
          <i className="svg-icon-wrapper">
            <SvgIcon
              iconClass={cls({
                hierarchy: isHierarchyField,
                [GRANULARITY_TYPE_ICONS[granularity]]: isDerivedField,
                [FIELD_VALUE_TYPE_ICONS[valueType]]: isNormalField
              })}
            />
          </i>
        </div>
        <span className="field-tree-text">
          {isHierarchyField && !dateFormat.includes('HMS')
            ? `${customName}(${granularityMap[granularity]})`
            : customName}
        </span>
      </div>
    </span>
  );
}

function DataPanelCube() {
  const { dashboardStore } = useStore();
  const { activeChart } = dashboardStore;
  const {
    frontChartId,
    dataConfig: { dataSetId, dataSetFields }
  } = activeChart;
  const [loadingDataSetFields, setLoadingDataSetFields] = useState(false);
  const [dataSets, setDataSets] = useState([]);
  const [treeState, setTreeState] = useState({
    treeData: [],
    expandedKeys: [],
    searchVal: '',
    autoExpandParent: true
  });
  const dimFieldTree = useMemo(() => {
    return treeState.treeData.filter(item => item.attribute.type === 'DIMENSION');
  }, [treeState.treeData]);
  const meaFieldTree = useMemo(() => {
    return treeState.treeData.filter(item => item.attribute.type === 'MEASURE');
  }, [treeState.treeData]);
  const treeDataRef = useRef(null);

  useEffect(() => {
    initState();
  }, [dashboardStore.state.activeIndex]);

  const initState = () => {
    if (dataSetId) {
      fetchDataSetFields(dataSetId);
    }
    if (Array.isArray(dashboardStore.state.dataSets) && !dashboardStore.state.dataSets.length) {
      fetchDataSets();
      return;
    }
    setDataSets(dashboardStore.state.dataSets);
  };

  const fetchDataSets = () => {
    http
      .post(APIS.DataBoardFindUserDataSets, {})
      .then(res => {
        // console.log('fetchDataSets: ', res.data);
        setDataSets(res.data);
        dashboardStore.setDataSets(res.data);
      })
      .finally(() => {});
  };

  const fetchDataSetFields = id => {
    setLoadingDataSetFields(true);
    http
      .post(APIS.DataBoardFindDataSetFields, { id })
      .then(res => {
        // console.log('fetchDataSetFields: ', res.data);
        const { dimensions, measures } = res.data;
        const newDataSetFields = [...dimensions, ...measures].map(item => ({
          ...item,
          key: item.uniqueId,
          title: item.customName,
          children: item.derivedFields.map(derivedField => ({
            ...derivedField,
            key: derivedField.uniqueId,
            title: derivedField.customName
          }))
        }));
        generateFieldList(newDataSetFields, fieldList);
        const updatedItem = _.cloneDeep(activeChart);
        updatedItem.dataConfig.dataSetId = id;
        updatedItem.dataConfig.dataSetFields = newDataSetFields;
        updatedItem.dataConfig.flattenedFields = flattenDataSetFields(newDataSetFields);
        if (updatedItem.dataConfig.compareField) {
          const compareFieldUniqueId = updatedItem.dataConfig.compareField.fieldUniqueId.replace('-clone', '');
          updatedItem.dataConfig.compareFields = [
            updatedItem.dataConfig.flattenedFields.find(field => field.uniqueId === compareFieldUniqueId)
          ];
        }
        dashboardStore.modChart({
          frontChartId,
          updatedItem
        });
        const hasHierarchyFields = newDataSetFields.some(item => !!item.derivedFields.length);
        const expandedKeys = hasHierarchyFields
          ? newDataSetFields.filter(item => !!item.derivedFields.length).map(item => item.uniqueId)
          : [];
        setTreeState({
          ...treeState,
          treeData: newDataSetFields,
          expandedKeys
        });
        treeDataRef.current = newDataSetFields;
      })
      .finally(() => {
        setLoadingDataSetFields(false);
      });
  };

  const handleChange = val => {
    fetchDataSetFields(val);
  };

  const handleSearch = value => {
    const treeData = !value ? treeDataRef.current : searchData(value);
    const dataFn = data => data.attribute.valueType === 'DATE' && data.attribute.granularity;
    const expandedKeys = treeData.some(dataFn) ? treeData.filter(dataFn).map(data => data.uniqueId) : [];
    setTreeState({
      treeData,
      expandedKeys,
      searchVal: value,
      autoExpandParent: true
    });
  };

  const searchData = inputValue => {
    const loop = data => {
      const result = [];
      data.forEach(item => {
        if (item.title.indexOf(inputValue) > -1) {
          result.push({ ...item });
        } else if (item.children) {
          const filterData = loop(item.children);

          if (filterData.length) {
            result.push({ ...item, children: filterData });
          }
        }
      });
      return result;
    };
    return loop(treeDataRef.current);
  };

  const handleExpand = expandedKeys => {
    setTreeState({
      ...treeState,
      expandedKeys,
      autoExpandParent: false
    });
  };

  // console.log('render DataPanelCube: ', treeState);
  return (
    <div className="data-panel-cube-wrapper">
      <div className="data-panel-cube-header">
        <span>数据</span>
      </div>
      <div className="data-panel-content">
        <div className="cube-switch">
          <Select
            className="select"
            size="small"
            placeholder="请选择数据集"
            showSearch
            filterOption={(input, option) => containsIgnoreCase(option.props.children.toString(), input)}
            value={dataSetId}
            onChange={handleChange}
            dropdownClassName="light-theme"
          >
            {dataSets.map(dataSet => (
              <Option key={dataSet.key} value={dataSet.key}>
                {dataSet.value}
              </Option>
            ))}
          </Select>
          <i
            className="icon-sync-field-info svg-icon-wrapper"
            title="同步字段信息"
            onClick={() => {
              fetchDataSetFields(dataSetId);
            }}
          >
            <SvgIcon iconClass="refresh" />
          </i>
        </div>
        {dataSetId && (
          <div className="data-panel-cube-tree-wrapper">
            <div className="data-panel-tree">
              <Spin
                className="cube-loading-spin"
                tip="正在取数中..."
                indicator={<img src={PicLoadingSpin} alt="" />}
                style={{
                  display: loadingDataSetFields ? 'block' : 'none'
                }}
              />
              {!!dataSetFields.length && (
                <>
                  <div className="cube-tree-search">
                    <Search size="small" placeholder="输入关键字搜索" onSearch={handleSearch} />
                  </div>
                  <div className="tree-wrapper">
                    {Object.keys(FIELD_TYPES).map(key => (
                      <Droppable
                        key={key}
                        droppableId={
                          key === 'dimension' ? 'SOURCE_FIELD_DIMENSION_ITEMS' : 'SOURCE_FIELD_MEASURE_ITEMS'
                        }
                        isDropDisabled={true}
                      >
                        {(provided, snapshot) => (
                          <FieldTreeWrapper ref={provided.innerRef}>
                            <DataPanelTree
                              key={key}
                              type={key}
                              treeData={key === 'dimension' ? dimFieldTree : meaFieldTree}
                              expandedKeys={treeState.expandedKeys}
                              searchVal={treeState.searchVal}
                              autoExpandParent={treeState.autoExpandParent}
                              onExpand={handleExpand}
                            />
                            {provided.placeholder}
                          </FieldTreeWrapper>
                        )}
                      </Droppable>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DataPanelCube;
