import React, { Component } from 'react'
import { IUdModalContentProps } from '..'
import UdForm, { IUdFormProps } from '../../UdForm/UdForm/UdForm'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { formUtils } from '../../../utils/formUtils'
import classNames from 'classnames'

class UdModalForm extends Component<IUdModalFormProps, IUdFormModalState> {

  private form: WrappedFormUtils = null as any

  componentDidMount() {
    this.props.getHandler(this.getResult)
  }

  render() {
    return (
      <UdForm
        labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
        {...this.props}
        className={classNames('ud-modal-form', this.props.className)}
        getForm={(e) => {
          this.form = e
          if (this.props.getForm) {
            this.props.getForm(e)
          }
        }}
      />
    )
  }

  public getResult = () => {
    return new Promise((resolve, reject) => {
      this.form.validateFieldsAndScroll((errors, values) => {
        if (errors) {
          reject(errors)
          return
        }
        let finalValues = formUtils.handleValues(values)
        resolve(finalValues)
      })
    })
  }

}

export interface IUdModalFormProps extends IUdModalContentProps, Omit<IUdFormProps, 'form'> {

}

interface IUdFormModalState {

}

export { UdModalForm }
