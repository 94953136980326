import React, { FC, CSSProperties, useContext } from 'react';
import QRCode from 'qrcode.react';
import cls from 'classnames';
import { WorkplaceContext } from './context';
import WatermarkCon from '@/components/Watermark';
import './style/canvas-panel-mobile.less'

export interface CanvasPanelMobileProps {
  style?: CSSProperties;
  mode?: 'edit' | 'preview';
}

const CanvasPanelMobile: FC<CanvasPanelMobileProps> = props => {
  const { style, mode } = props;
  const { dashboardName, QRCodeConfig, showWatermark } = useContext(WorkplaceContext);
  const isQRCodeValueEmpty = !QRCodeConfig.value;
  const isPreview = mode === 'preview';
  return (
    <div className="canvas-panel-container dashboard-layout mobile-container" style={style}>
      <div
        className="device-layout-container"
        style={{ padding: isPreview ? 0 : '8px 0', overflowY: isPreview ? 'hidden' : 'scroll' }}
      >
        {mode === 'edit' && (
          <div style={{ position: 'absolute', top: '20px', left: '30px', display: 'flex', flexDirection: 'column' }}>
            <div className="dashboard-qrcode-box">
              {isQRCodeValueEmpty ? (
                <>
                  <div className="lock-image"></div>
                  <div className="view-text">
                    <div>请先保存后, 扫描二维码在手机端预览</div>
                  </div>
                </>
              ) : (
                <>
                  <QRCode renderAs="canvas" value={QRCodeConfig.value} size={120} />
                  <div className="view-text">
                    <div>扫码预览</div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div
          className={cls('device-container', {
            'is-mobile-preview': isPreview
          })}
          style={{
            width: isPreview ? window.screen.width + 'px' : '351px'
          }}
        >
          <div
            className="title"
            style={{
              position: 'absolute',
              top: `${isPreview ? 0 : '58px'}`,
              left: '50%',
              transform: 'translateX(-50%)',
              maxWidth: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '18px',
              color: 'rgba(0,0,0,0.85)'
            }}
          >
            {dashboardName}
          </div>
          {/* 水印-移动端 */}
          {showWatermark && (<WatermarkCon />)}
          <div
            className="content device-content"
            style={{
              position: 'absolute',
              top: `${isPreview ? '48px' : '90px'}`,
              left: `${isPreview ? 0 : '18px'}`,
              width: `${isPreview ? window.screen.width + 'px' : '312px'}`,
              height: `${isPreview ? window.screen.height - 48 + 'px' : '600px'}`,
              overflowY: 'auto',
              boxSizing: 'border-box',
              borderBottomLeftRadius: '45px',
              borderBottomRightRadius: '45px'
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasPanelMobile;
