import _ from 'lodash'
import React, { Component, ReactNode } from 'react'
import { IUdFormItem } from '../../types'
import Form, { WrappedFormUtils, GetFieldDecoratorOptions, ValidationRule } from 'antd/lib/form/Form'
import { Tooltip, Input, DatePicker } from 'antd'
import UdDateRangePicker from '../UdFormControls/UdDateRangePicker/UdDateRangePicker'
import classNames from 'classnames'
import { validators } from '@/lib/utils/validators'

class UdFormItem extends Component<IUdFormItemProps> {

  render() {
    const item = this.props
    let key = _.isArray(item.id) ? item.id.join('|') : item.id
    let options = _.cloneDeep(item.decoratorOptions) || {}
    options.rules = options.rules || []
    if (item.initialValue !== undefined) {
      options.initialValue = item.initialValue
    }

    if (_.isArray(item.rules) && item.rules.length > 0) {
      for (const rule of item.rules) {
        if (_.isFunction(rule)) {
          options.rules.push(rule(item))
          if (rule === validators.required && !this.hasWhitespace(item.rules)) {
            this.formatWhitespace(item, options)
          }
        } else {
          options.rules.push(rule as ValidationRule)
          if (rule.required && !this.hasWhitespace(item.rules)) {
            this.formatWhitespace(item, options)
          }
        }
      }
    }

    return (
      <Form.Item key={key} className={classNames('ud-form-item', 'ud-form-item-' + key, this.props.className)} label={
        item.label && <Tooltip placement="top" title={item.label}>
          {item.label}
        </Tooltip>
      } labelCol={item.labelCol} wrapperCol={item.wrapperCol} extra={item.extra}>
        {item.getFieldDecorator(key, options)(this.getControl())}
      </Form.Item>
    )
  }

  private getControl = (): ReactNode => {
    let newProps = { placeholder: `请输入${this.props.label}`, filterSymbol: true, ...this.props.props }
    let { filterSymbol } = newProps
    delete newProps.filterSymbol
    if (this.props.render) {
      let node
      if (_.isFunction(this.props.render)) {
        node = this.props.render(this.props)
      } else {
        node = this.props.render
      }
      newProps = { ...newProps, ...node.props || {} }
      // 排除下列控件类型，避免自动设置的 placeholder 和这些控件自带的 placeholder 起冲突
      // PickerWrapper 对应 RangePicker 控件
      let excludes = [UdDateRangePicker, DatePicker]
      if (excludes.some(item=>item===node.type)) {
        delete newProps.placeholder
      }
      return React.cloneElement(node, newProps)
    } else {
      return <Input {...newProps} onChange={filterSymbol && this.inputChange} />
    }
  }

  private inputChange = (e) => {
    let val = e.target.value
    // val = val.replace(/\s+/g, "")
    val = val.replace(/[<>=]/g, "")
    e.target.value = val
  }

  private hasWhitespace = (rules) => {
    return (rules.includes(validators.whitespace) || rules.some((rule: ValidationRule) => rule.whitespace))
  }

  private formatWhitespace = (item, options) => {
    if (!item.render) {
      options.rules.push(validators.whitespace(item))
    } else if (_.isFunction(item.render)) {
      const node = item.render(this.props)
      if (node.type === Input) {
        options.rules.push(validators.whitespace(item))
      }
    } else if (item.render.type === Input) {
      options.rules.push(validators.whitespace(item))
    }
  }
}

export interface IUdFormItemProps extends IUdFormItem {
  /**
   * 表单实例
   */
  form: WrappedFormUtils<any>;
  /**
   * getFieldDecorator
   */
  getFieldDecorator<T extends Object = {}>(id: keyof T, options?: GetFieldDecoratorOptions): (node: React.ReactNode) => React.ReactNode;
}

export default UdFormItem
