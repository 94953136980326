import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import _ from 'lodash';
import useLocale from '@/utils/useLocale';
import locale from '@/components/widget/locale';
import '../style/modal-button.less';

const initialState = () => ({
  value: '清空'
});

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 18 }
  }
};

function ModalButtonReset({ visible, widgetLabel, widget, onCancel, onOk }) {
  const t = useLocale(locale);
  const [formData, setFormData] = useState(initialState());

  const handleOk = () => {
    const { value } = formData;
    const newWidget = _.cloneDeep(widget);
    newWidget.options.value = value;
    onOk({
      ...widget,
      ...newWidget
    });
  };

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  return (
    <Modal
      title={`${widgetLabel}组件`}
      visible={visible}
      width={420}
      wrapClassName="filter-button-dialog"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form {...formItemLayout} colon={false}>
        <Form.Item label={t['ibResetButton.text']}>
          <Input value={formData.value} onChange={ev => handleChange('value', ev.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalButtonReset;
