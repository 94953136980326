import React from 'react';
import cls from 'classnames';
import { Tooltip } from 'antd';
import useLocale from '@/utils/useLocale';
import { useStore } from '@/store/useStore';
import locale from '@/components/widget/locale';
import SvgIcon from '@/components/SvgIcon';
import { genFilterGroup } from './utils';
import '../style/filter-group.less';

const FILTER_GROUPS = genFilterGroup();

const hasSureBtn = itemComp => itemComp.component === 'ib-button';
const hasResetBtn = itemComp => itemComp.component === 'ib-reset-button';

function renderItem(item, idx, onSelect, t, customComps) {
  const handleItemWidgetSelect = itemWidget => {
    onSelect(itemWidget);
  };

  return (
    <div className="item-filter" key={item.value}>
      <div className="filter-header">
        <div className="filter-header-txt">{item.label}</div>
      </div>
      <div className="filter-content">
        {item.items.map(itemWidget => (
          <Tooltip key={itemWidget.value} title={t[itemWidget.label]} placement="top" overlayClassName="theme-light">
            <div
              className={cls(['filter-widget', `${item.value}-filter`], {
                'custom-btn-disabled': itemWidget.value === 'buttonSure' && customComps.some(hasSureBtn),
                'custom-btn-reset-disabled':
                  itemWidget.value === 'buttonReset' && (!customComps.some(hasSureBtn) || customComps.some(hasResetBtn))
              })}
              onClick={() => handleItemWidgetSelect(itemWidget)}
            >
              <div className="filter-widget-icon">
                <i className="svg-icon-wrapper">
                  <SvgIcon iconClass={itemWidget.icon} />
                </i>
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
      {idx > 0 && <div className="filter-separate"></div>}
    </div>
  );
}

function FilterGroup({ onSelect }) {
  const { dashboardStore } = useStore();
  const { customComps } = dashboardStore.state;
  const t = useLocale(locale);
  return (
    <div className="filter-group-wrapper">
      {FILTER_GROUPS.map((itemGroup, groupIdx) => renderItem(itemGroup, groupIdx, onSelect, t, customComps))}
    </div>
  );
}

export default FilterGroup;
