import UdMainHeaderRight from '@/components/UdMainHeaderRight';
import useSessionStorage from '@/hooks/useSessionStorage';
import Logo from '@/images/logo.png';
import { uaaApp } from '@/lib/core/uaaApp';
import ExportTask from '@/pages/ExportTask';
import Help from '@/pages/Help';
import Router from '@/router';
import routes from '@/router/config';
import stores from '@/store';
import udMainStore from '@/store/UdMain';
import { BackTop, Icon, Menu, Modal } from 'antd';
import Guide from 'byte-guide';
import classNames from 'classnames';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LicenseInvalid from '../../components/LicenseInvalid';
import './style.less';

const APP_PATHS = {
  'int-bi-app': '/intBI',
  'logging-app': '/logManage',
  'uaa-admin-app': '/uaaAdmin'
};

const MENU_GUIDE_TIPS = {
  M_DATA_MANAGE_DATA_SOURCE_LIST: '在数据源处获得要分析的数据，这是所有数据分析的源头',
  M_DATA_MANAGE_DATA_SET_LIST: '在数据集中对数据加工处理，后续的分析都基于此处的数据集',
  M_DATA_MANAGE_DATA_BOARD_LIST: '可以使用数据集进行图表形式的分析',
  M_DATA_MANAGE_DATA_REPORT_LIST: '可以使用数据集进行报表形式的分析'
};
const MENU_GUIDE_TITLES = {
  M_DATA_MANAGE_DATA_SOURCE_LIST: '数据源',
  M_DATA_MANAGE_DATA_SET_LIST: '数据集',
  M_DATA_MANAGE_DATA_BOARD_LIST: '仪表板',
  M_DATA_MANAGE_DATA_REPORT_LIST: '数据报表'
};
const { SubMenu } = Menu;
const routesWithCustomId = routes.filter(itemRoute => itemRoute.customId);
// const { generalMenus: appList } = uaaApp.getSysInfo();
const AppContainer: FC = props => {
  const localStore = useLocalObservable(() => udMainStore);
  const licenseStore = useLocalObservable(() => stores.licenseStore);

  let history = useHistory();
  let location = useLocation();
  const [guideCompleted, setGuideCompleted] = useSessionStorage('guideCompleted', undefined);
  const [appList, setAppList] = useState([]);
  const [activeApp, setActiveApp] = useState(null);
  const [menuTreeNode, setMenuTreeNode] = useState(null);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [visibleGuideApp, setVisibleGuideApp] = useState(false);
  const [appMenuGuide, setAppMenuGuide] = useState({
    visible: false,
    steps: []
  });
  const [visibleHelpTips, setVisibleHelpTips] = useState(false);
  useEffect(() => {
    const { generalMenus, profile } = uaaApp.getSysInfo();
    console.log('sysInfo: ', uaaApp.getSysInfo());
    setAppList(generalMenus.map(item => ({ ...item, isActive: false })));
    !guideCompleted && setVisibleGuideApp(profile.bootPoint);
    if (window.performance) {
      // 监听页面刷新
      if (performance.navigation.type === 1) {
        refreshHandler();
      }
    }
    licenseStore.init();
    // 获取license信息
    loadLicenseInfo();
  }, []);

  const loadLicenseInfo = () => {
    const { licenseInfo } = licenseStore.state;

    let promptLicense = false;
    if (licenseInfo?.licenseType != 'PERPETUAL') {
      promptLicense = licenseInfo?.licenseRemainDays < 30;
    }
    if (promptLicense) {
      Modal.warning({
        title: '授权即将到期',
        content: (
          <div>
            <p>您的BI产品许可证还有{licenseInfo?.licenseRemainDays}天即将过期，请尽快联系矩子数智续费。</p>
          </div>
        ),
        okText: '确定',
        onOk() {}
      });
    }
  };

  const renderAppList = () => {
    return (
      <ul className="app-list">
        {appList.map(itemApp => {
          const itemAppClasses = classNames('item-app', {
            'is-active': itemApp.isActive
          });
          return (
            <li
              id={itemApp.application}
              className={itemAppClasses}
              key={itemApp.id}
              onClick={() => handleItemAppClick(itemApp)}
            >
              {itemApp.text}
            </li>
          );
        })}
        {/* <li
          className={classNames('item-app', {
            'is-active': window.location.hash.includes('app-policy-and-calculate')
          })}
          onClick={() => handleMicroAppClick()}
        >
          INT BU 商业智能
        </li> */}
      </ul>
    );
  };
  const handleItemAppClick = item => {
    if (item.isActive) return;
    const {
      profile: { bootPoint }
    } = uaaApp.getSysInfo();
    const { items: appMenus, application, id } = item;
    const newAppList = appList.map(itemApp => {
      if (itemApp.id === id) {
        return { ...itemApp, isActive: true };
      }
      return { ...itemApp, isActive: false };
    });
    setAppList(newAppList);
    setActiveApp({ ...item, isActive: true });
    setMenuTreeNode(appMenus);
    setOpenKeys([]);
    setSelectedKeys([]);
    if (application === 'int-bi-app' && bootPoint && !guideCompleted) {
      setAppMenuGuide({
        visible: true,
        steps: appMenus
          .filter(menu => Object.keys(MENU_GUIDE_TIPS).includes(menu.customId))
          .map(menu => ({
            selector: `#${menu.customId}`,
            title: MENU_GUIDE_TITLES[menu.customId],
            content: MENU_GUIDE_TIPS[menu.customId],
            placement: 'right',
            offset: {
              x: 10,
              y: 0
            }
          }))
      });
    }
    history.push(APP_PATHS[application]);
  };
  const handleItemMenuClick = itemMenu => {
    const { customId } = itemMenu;
    const itemMenuPath = routesWithCustomId.filter(itemRoute => itemRoute.customId === customId)[0].path;
    setSelectedKeys([customId]);
    history.push(itemMenuPath);
  };

  const handleMicroAppClick = () => {
    setAppList(appList.map(app => ({ ...app, isActive: false })));
    history.push('/app-policy-and-calculate');
  };
  // 渲染应用菜单
  const renderAppMenu = data => {
    return data.map(item => {
      if (item.type === 'MENU_GROUP' && item.items && item.items.length > 0) {
        return (
          <SubMenu title={renderItemMenuTitle(item)} key={item.customId}>
            {renderAppMenu(item.items)}
          </SubMenu>
        );
      }
      /* if (item.customId === 'M_DATA_MANAGE_DATA_SOURCE_LIST') { // 数据源 即将发布
                return <Menu.Item key={item.customId}>
                    <Icon type={item.icon}/><span>{item.text}</span><span className="tag-coming-soon">即将发布</span>
                </Menu.Item>;
            } */

      if (item.type !== 'RESOURCE') {
        // const itemMenuPath = routesWithCustomId.filter(itemRoute => itemRoute.customId === item.customId)[0].path;
        return (
          <Menu.Item key={item.customId} id={item.customId} onClick={() => handleItemMenuClick(item)}>
            <Icon type={item.icon} />
            <span className="item-menu-title">{item.text}</span>
            {/* <NavLink to={itemMenuPath}>{renderItemMenuTitle(item)}</NavLink> */}
          </Menu.Item>
        );
      }
    });
  };
  const renderItemMenuTitle = itemMenu => {
    const { text, icon } = itemMenu;
    return (
      <>
        <Icon type={icon} />
        <span className="item-menu-title">{text}</span>
      </>
    );
  };
  const toggleCollapsed = () => {
    setCollapse(!collapse);
  };
  // 页面刷新
  const refreshHandler = () => {
    const { generalMenus } = uaaApp.getSysInfo();
    const { pathname } = location;
    let activeApp = null;
    let isPageDetail =
      pathname.startsWith('/intBI/dataIndicator/edit') ||
      pathname.startsWith('/intBI/dataIndicator/details') ||
      pathname.startsWith('/intBI/dataCenter/tableStructure') ||
      pathname.startsWith('/intBI/dataCenter/tableDetail') ||
      pathname.startsWith('/intBI/dataSet/edit') ||
      pathname.startsWith('/intBI/dataBoard/preview') ||
      pathname.startsWith('/intBI/dataBoard/edit') ||
      pathname.startsWith('/intBI/monitorWarning/log/detail') ||
      pathname.startsWith('/intBI/dataReport/detail') ||
      pathname.startsWith('/intBI/dataSource/tableStructure') ||
      pathname.startsWith('/intBI/dataSource/tableData') ||
      pathname.startsWith('/intBI/indicatorLib/selfBuilt/edit') ||
      pathname.startsWith('/intBI/screen/workspace') ||
      pathname.startsWith('/intBI/screen/preview') ||
      pathname.startsWith('/intBI/excelReport/designer') ||
      pathname.startsWith('/intBI/excelReport/viewer');
    if (pathname === '/home' || pathname === '/') {
      return;
    }
    if (pathname.startsWith('/intBI')) {
      activeApp = generalMenus.find(itemApp => itemApp.application === 'int-bi-app');
    } else if (pathname.startsWith('/uaaAdmin')) {
      activeApp = generalMenus.find(itemApp => itemApp.application === 'uaa-admin-app');
    } else if (pathname.startsWith('/logManage')) {
      activeApp = generalMenus.find(itemApp => itemApp.application === 'logging-app');
    } else {
      history.push('/app-policy-and-calculate');
      return;
    }
    const newAppList = generalMenus.map(itemApp => {
      if (itemApp.id === activeApp.id) {
        return { ...itemApp, isActive: true };
      }
      return { ...itemApp, isActive: false };
    });
    setActiveApp(activeApp);
    setMenuTreeNode(activeApp.items);
    setAppList(newAppList);
    if (isPageDetail) {
      udMainStore.setIsShowFrame(false);
    } else {
      if (pathname === '/intBI' || pathname === '/uaaAdmin' || pathname === '/logManage') return;
      if (pathname.startsWith('/intBI/subscribe/logList')) {
        setSelectedKeys([
          routesWithCustomId.filter(itemRoute => itemRoute.path === '/intBI/subscribe/logList/:subsId')[0]
            .parentCustomId
        ]);
        return;
      }
      const { customId, hasParent, parentCustomId } = routesWithCustomId.filter(
        itemRoute => itemRoute.path === pathname
      )[0];
      if (hasParent) {
        setOpenKeys([parentCustomId]);
      }
      setSelectedKeys([customId]);
    }
  };
  const onOpenChange = arrOpenKeys => {
    const latestOpenKey = arrOpenKeys.find(key => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };
  const isPageScreenPreview = location.pathname.startsWith('/intBI/screen/preview');
  const isMicroApp = location.pathname.includes('app');

  // console.log('AppContainer render: ', localStore.isShowFrame, menuTreeNode, appMenuGuide, isMicroApp);
  return (
    <>
      {!licenseStore.state?.licenseValid && <LicenseInvalid />}
      {licenseStore.state?.licenseValid && isPageScreenPreview && <Router />}
      {licenseStore.state?.licenseValid && !isPageScreenPreview && (
        <div className="app-container">
          {localStore.isShowFrame && (
            <div className="header-wrap">
              <div className="logo">
                <img src={Logo} alt="" width="224" height="40" />
              </div>
              <div className="app-menus">{appList.length > 0 && renderAppList()}</div>
              <ExportTask />
              <Help />
              <UdMainHeaderRight license={stores.licenseStore.state.licenseInfo} />
            </div>
          )}
          <div className="main-wrap">
            {localStore.isShowFrame && menuTreeNode && !isMicroApp && (
              <div className="sidebar" style={{ width: collapse ? 48 : 208 }}>
                <div className="sidebar-body">
                  <Menu
                    inlineIndent={10}
                    theme="light"
                    mode="inline"
                    inlineCollapsed={collapse}
                    defaultOpenKeys={openKeys}
                    defaultSelectedKeys={selectedKeys}
                    selectedKeys={selectedKeys}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                  >
                    {renderAppMenu(menuTreeNode)}
                  </Menu>
                </div>
                <div className="sidebar-footer">
                  <Icon type={collapse ? 'menu-unfold' : 'menu-fold'} onClick={toggleCollapsed} />
                </div>
              </div>
            )}
            <div
              className="content-wrap"
              id="scroll"
              style={{
                padding: `${localStore.isShowFrame && !isMicroApp ? '0 15px' : 0}`,
                height: `${localStore.isShowFrame || isMicroApp ? 'calc(100vh - 50px)' : 'calc(100vh)'}`,
                overflow: `${localStore.isShowFrame || isMicroApp ? 'auto' : 'hidden'}`
              }}
            >
              <Router />
            </div>
            <BackTop visibilityHeight={100} target={() => document.getElementById('scroll')} />
          </div>
        </div>
      )}
      {/* 新手引导 */}
      <Guide
        modalClassName="guide-app"
        maskClassName="guide-mask"
        visible={visibleGuideApp}
        steps={[
          {
            selector: '#int-bi-app',
            title: '',
            content: '点击这里开始快速分析',
            placement: 'bottom',
            offset: {
              x: 0,
              y: 10
            }
          }
        ]}
        stepText={(stepIndex, stepCount) => {
          return '';
        }}
        onClose={() => {
          setVisibleGuideApp(false);
          document.documentElement.style.overflow = 'auto';
        }}
      />
      <Guide
        modalClassName="guide-app-menus"
        maskClassName="guide-mask"
        visible={appMenuGuide.visible}
        steps={appMenuGuide.steps}
        showPreviousBtn
        onClose={() => {
          setAppMenuGuide({ ...appMenuGuide, visible: false });
          setVisibleHelpTips(true);
        }}
      />
      <Guide
        modalClassName="guide-help"
        maskClassName="guide-mask"
        visible={visibleHelpTips}
        steps={[
          {
            selector: '.help-wrap',
            title: '帮助',
            content: '可以在此处获得我们的帮助',
            placement: 'bottom',
            offset: {
              x: 0,
              y: 10
            }
          }
        ]}
        stepText={(stepIndex, stepCount) => {
          return '';
        }}
        onClose={() => {
          setVisibleGuideApp(false);
          setGuideCompleted(true);
          document.documentElement.style.overflow = 'auto';
        }}
      />
    </>
  );
};

export default observer(AppContainer);
