import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment';
import cls from 'classnames';
import { IColumnActions } from '../types';
import { Dropdown, Icon, Menu, Switch, Tooltip } from 'antd';
import { uaaApp } from '../core/uaaApp';
import SvgIcon from '@/components/SvgIcon';

let columnRenders = {
  title: (to: string, text?: string) => {
    let compiledTo = _.template(to);
    let compliedText;
    if (text) {
      compliedText = _.template(text);
    }
    return (text: any, record: any, index: number): React.ReactNode => {
      let path = compiledTo(record);
      return (
        <div className="td-title">
          <a
            onClick={() => {
              window.location.hash = path;
            }}
          >
            {compliedText ? compliedText(record) : text}
          </a>
        </div>
      );
    };
  },
  enum: (items: { [key in any]: any }, defaultValue?: '--') => {
    return (text: any): React.ReactNode => {
      return items[text] == null ? defaultValue : items[text];
    };
  },

  datetime: (format: string) => {
    return (text: any, record: any, index: number): React.ReactNode => {
      return <span>{moment(text).format(format)}</span>;
    };
  },
  switch: () => {
    return (text: any, record: any, index: number): React.ReactNode => {
      let loading = false;
      let checked = false;
      return (
        <Switch
          defaultChecked={checked}
          checkedChildren={'开'}
          loading={loading}
          unCheckedChildren={'关'}
          onChange={e => {
            console.log(e);
            loading = true;
            setTimeout(() => {
              loading = false;
              checked = true;
            }, 2000);
          }}
        />
      );
    };
  },

  maxShowLength: (maxLength: number = 200, showTooltip: boolean = true) => {
    return (text: any, record: any, index: number): React.ReactNode => {
      const str = text.substring(0, maxLength) + (text.length <= maxLength ? '' : '...');
      return !showTooltip ? (
        str
      ) : (
        <Tooltip placement="topLeft" title={text.length > 1000 ? '由于该内容过长请在详情页面查看' : text}>
          {str}
        </Tooltip>
      );
    };
  },

  operate: (actions: IColumnActions[], showLimit: number = 3) => {
    return (text: any, model: any, index: number): React.ReactNode => {
      let links = [];
      let mores = null;
      let showActions = [];
      for (let i = 0; i < actions.length; i++) {
        if (_.isFunction(actions[i].show) && actions[i].show(text, model, index) === false) {
          continue;
        }
        if (actions[i].auth) {
          if (_.isFunction(actions[i].auth) && (actions[i].auth as any)(text, model, index) === false) {
            continue;
          }
          if (_.isString(actions[i].auth) && uaaApp.canUse(actions[i].auth as string) === false) {
            continue;
          }
        }
        showActions.push(actions[i]);
      }
      let hasMore = showActions.length > showLimit;
      let endIndex = hasMore ? showLimit - 1 : showActions.length;
      for (let i = 0; i < endIndex; i++) {
        links.push(
          <Tooltip title={showActions[i].title} key={`${showActions[i].title}-${i}`}>
            <i
              className={cls('svg-icon-wrapper action-button', {
                enable: showActions[i].enable,
                disabled: showActions[i].disabled
              })}
              onClick={() => {
                showActions[i].action.call(this, text, model, index);
              }}
            >
              <SvgIcon iconClass={showActions[i].icon} />
            </i>
          </Tooltip>
        );
      }
      if (hasMore) {
        let menuItems = [];
        for (let i = endIndex; i < showActions.length; i++) {
          menuItems.push(
            <Menu.Item
              key={i}
              onClick={() => {
                showActions[i].action.call(this, text, model, index);
              }}
              className={cls('dropdown-action-button', { disabled: showActions[i].disabled })}
            >
              {/* <a onClick={() => { showActions[i].action.call(this, text, model, index) }}>{showActions[i].title}</a> */}
              <i
                className={cls('svg-icon-wrapper action-button', {
                  enable: showActions[i].enable,
                  disabled: showActions[i].disabled
                })}
              >
                <SvgIcon iconClass={showActions[i].icon} />
              </i>
              <span style={{ marginLeft: '4px', fontSize: '12px' }}>{showActions[i].title}</span>
            </Menu.Item>
          );
        }
        mores = (
          <Dropdown overlay={<Menu>{menuItems}</Menu>} placement="bottomRight">
            {/* <a className="ant-dropdown-link">
              更多 <Icon type="down" />
            </a> */}
            <Tooltip title="更多">
              <i className="svg-icon-wrapper action-button">
                <SvgIcon iconClass="a-more" />
              </i>
            </Tooltip>
          </Dropdown>
        );
      }

      return (
        <div className={'td-operate'}>
          {links}
          {mores}
        </div>
      );
    };
  }
};

export { columnRenders };
