import React, { FC, useState, useEffect, useMemo, CSSProperties } from 'react';
import _ from 'lodash';
import './index.less';

interface ICompStyle extends CSSProperties {
  adaptation: string;
}
interface IProps {
  element: {
    hyperlinks?: {
      enable: boolean;
      openMode: '_blank' | '_self';
      content: string;
    };
    propValue: string;
  };
  editMode?: string;
  compStyle?: ICompStyle;
}
const Picture: FC<IProps> = ({ element, editMode, compStyle }) => {
  const [style, setStyle] = useState<{ [key: string]: CSSProperties }>({
    container: {
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(41,52,65,1)',
      borderRadius: 0,
      borderStyle: 'solid',
      borderWidth: 0,
      borderColor: 'rgba(0,0,0,1)'
    },
    img: {
      position: 'relative',
      width: '100%',
      height: '100%'
    }
  });

  useEffect(() => {
    if (compStyle) {
      initStyle();
    }
  }, [compStyle]);

  const initStyle = () => {
    let newStyle = _.cloneDeep(style);
    newStyle.container.backgroundColor = compStyle.backgroundColor;
    newStyle.container.borderStyle = compStyle.borderStyle;
    newStyle.container.borderWidth = compStyle.borderWidth;
    newStyle.container.borderColor = compStyle.borderColor;
    newStyle.container.borderRadius = compStyle.borderRadius;
    if (compStyle.adaptation === 'adaptation') {
      newStyle.img.width = '100%';
      newStyle.img.height = '100%';
    } else if (compStyle.adaptation === 'equiratio') {
      newStyle.img.width = '100%';
      newStyle.img.height = 'auto';
    } else {
      newStyle.img.width = 'auto';
      newStyle.img.height = 'auto';
    }
    setStyle({
      ...style,
      ...newStyle
    });
  };

  const showLink = useMemo(() => {
    return editMode === 'preview' && element && element.hyperlinks && element.hyperlinks.enable;
  }, [editMode, element]);

  return (
    <div className="pic-comp-wrapper">
      <div
        style={{
          ...style.container,
          borderWidth: style.container.borderWidth + 'px',
          borderRadius: style.container.borderRadius + 'px'
        }}
      >
        {element.propValue && !showLink && <img src={element.propValue} style={style.img} />}
        {element.propValue && showLink && (
          <a title={element.hyperlinks.content} target={element.hyperlinks.openMode} href={element.hyperlinks.content}>
            <img src={element.propValue} style={style.img} />
          </a>
        )}
        {!element.propValue && (
          <img src={require('../../../../assets/workspace/templateImg/image.png')} style={style.img} />
        )}
      </div>
    </div>
  );
};

export default Picture;
