import React from 'react';
import { Modal } from 'antd';
import WarningSetting from '@/pages/dataBoard/components/WarningSetting';

function ModalSettingWarning({ visible, dashboardId, activeChart, sourceType, onCancel, onSave }) {
  return (
    <Modal
      title="监控预警设置"
      visible={visible}
      footer={null}
      maskClosable={false}
      width={880}
      wrapClassName="dlg-warning-setting"
      onCancel={onCancel}
    >
      <WarningSetting
        boardId={dashboardId}
        activeChart={activeChart}
        sourceType={sourceType}
        onCancel={onCancel}
        onSave={onSave}
      />
    </Modal>
  );
}

export default ModalSettingWarning;
