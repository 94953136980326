import React, { useState, useEffect, useMemo, useContext, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Input, Checkbox, Popover, Switch } from 'antd';
import cls from 'classnames';
import _, { divide } from 'lodash';
import { WidgetFilterContext } from './ctx';
import { getService } from '@/components/widget/utils';
import useLocale from '@/utils/useLocale';
import locale from '@/components/widget/locale';
import SvgIcon from '@/components/SvgIcon';

const MAX_LEN = 15;
const groupItemStyle = {
  display: 'block',
  margin: 0,
  height: '30px',
  lineHeight: '30px'
};

const genControlAttrs = () => {
  return {
    showTitle: false,
    mainTitle: '',
    showTime: false,
    accuracy: 'HH:mm',
    multiple: false,
    enableRange: false,
    chartIds: []
  };
};

function FilterControl(props, ref) {
  useImperativeHandle(ref, () => ({
    controlAttrs
  }));
  const t = useLocale(locale);
  const { widget, setWidget } = useContext(WidgetFilterContext);
  const [controlAttrs, setControlAttrs] = useState(genControlAttrs());
  const [accuracyOptions, setAccuracyOptions] = useState([
    { label: 'HH', value: 'HH' },
    { label: 'HH:mm', value: 'HH:mm' },
    { label: 'HH:mm:ss', value: 'HH:mm:ss' }
  ]);
  const isTimeWidget = useMemo(() => {
    const widgetInstance = getService(widget.serviceName);
    return widgetInstance.isTimeWidget && widgetInstance.isTimeWidget();
  }, [widget.serviceName]);
  const wordCount = useMemo(() => {
    return controlAttrs.mainTitle.length;
  }, [controlAttrs.mainTitle]);

  useEffect(() => {
    const {
      style: { showTitle, mainTitle },
      options: {
        attrs: { showTime, accuracy, multiple, enableRange, chartIds }
      },
      showSwitch
    } = widget;
    const newControlAttrs = _.cloneDeep(controlAttrs);
    newControlAttrs.showTitle = showTitle;
    newControlAttrs.mainTitle = mainTitle;
    newControlAttrs.enableRange = enableRange;
    newControlAttrs.chartIds = chartIds;
    if (isTimeWidget) {
      newControlAttrs.showTime = showTime;
      newControlAttrs.accuracy = accuracy;
    }
    if (showSwitch) {
      newControlAttrs.multiple = multiple;
    }
    setControlAttrs({
      ...controlAttrs,
      ...newControlAttrs
    });
  }, []);

  const handleControlAttrsChange = (key, val) => {
    const newControlAttrs = _.set(controlAttrs, key, val);
    setControlAttrs({
      ...controlAttrs,
      ...newControlAttrs
    });
    if (isTimeWidget && (key === 'showTime' || key === 'accuracy')) {
      props.onDateTimeChange(newControlAttrs);
    }
    if (key === 'multiple') {
      props.onMultipleChange(newControlAttrs);
    }
    if (key === 'enableRange' || key === 'chartIds') {
      props.onRangeChange(newControlAttrs);
    }
  };

  return (
    <div className="filter-control">
      <Row>
        <Col span={8}>
          <div className="filter-options-left">
            {widget.showSwitch && (
              <div className="show-switch">
                <Switch
                  size="small"
                  checked={controlAttrs.multiple}
                  onChange={checked => handleControlAttrsChange('multiple', checked)}
                />
                <label>{t['panel.multipleChoice']}</label>
              </div>
            )}
            {isTimeWidget && (
              <>
                <Checkbox
                  checked={controlAttrs.showTime}
                  onChange={ev => handleControlAttrsChange('showTime', ev.target.checked)}
                >
                  {t['panel.showTime']}
                </Checkbox>
                <Popover
                  trigger="click"
                  placement="bottomRight"
                  content={
                    <ul className="ib-ul">
                      {accuracyOptions
                        .map(opt => {
                          return {
                            ...opt,
                            checked: opt.value === controlAttrs.accuracy
                          };
                        })
                        .map(opt => (
                          <li
                            key={opt.value}
                            className={cls({ 'ib-active-li': opt.value === widget.options.attrs.accuracy })}
                            onClick={() => handleControlAttrsChange('accuracy', opt.value)}
                          >
                            {opt.label}
                          </li>
                        ))}
                    </ul>
                  }
                >
                  <i className={cls('svg-icon-wrapper', { disabled: !widget.options.attrs.showTime })}>
                    <SvgIcon iconClass="setting" />
                  </i>
                </Popover>
              </>
            )}
          </div>
        </Col>
        <Col span={16}>
          {controlAttrs && (
            <div className="filter-options-right">
              <span>
                <Checkbox
                  checked={controlAttrs.showTitle}
                  onChange={ev => handleControlAttrsChange('showTitle', ev.target.checked)}
                >
                  {t['panel.showTitle']}
                </Checkbox>
                <Popover
                  trigger="click"
                  placement="bottomRight"
                  overlayClassName="pop-option-title"
                  content={
                    <>
                      <Input.TextArea
                        rows={3}
                        maxLength={15}
                        placeholder={t['panel.inputTitle']}
                        value={controlAttrs.mainTitle}
                        onChange={ev => handleControlAttrsChange('mainTitle', ev.target.value)}
                      />
                      <span className="word-limit">
                        {wordCount}/{MAX_LEN}
                      </span>
                    </>
                  }
                >
                  <i className={cls('svg-icon-wrapper', { disabled: !controlAttrs.showTitle })}>
                    <SvgIcon iconClass="setting" />
                  </i>
                </Popover>
              </span>
              <span style={{ marginLeft: '10px' }}>
                <Checkbox
                  checked={controlAttrs.enableRange}
                  onChange={ev => handleControlAttrsChange('enableRange', ev.target.checked)}
                >
                  {t['panel.customScope']}
                </Checkbox>
                <Popover
                  trigger="click"
                  placement="bottomRight"
                  overlayClassName="pop-option-range"
                  content={
                    <div className="view-ids-container">
                      <Checkbox.Group
                        value={controlAttrs.chartIds}
                        onChange={checkedList => handleControlAttrsChange('chartIds', checkedList)}
                      >
                        {props.charts.map(chart => (
                          <Checkbox key={chart.frontChartId} value={chart.frontChartId} style={groupItemStyle}>
                            <span className="icontext">
                              <i className={cls(['component-type-icon', `component-type-${chart.chartType}`])}></i>
                              <span className="chart-name">{chart.styleConfig.title}</span>
                            </span>
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </div>
                  }
                >
                  <i className={cls('svg-icon-wrapper', { disabled: !controlAttrs.enableRange })}>
                    <SvgIcon iconClass="setting" />
                  </i>
                </Popover>
              </span>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default forwardRef(FilterControl);
