import { makeAutoObservable } from "mobx"

class UdMainPageTabsStore implements IUdMainPageTabsStore {
  constructor() {
    makeAutoObservable(this)
  }
  pageTabs: any[] = sessionStorage.getItem("pageTabs") ? JSON.parse(sessionStorage.getItem("pageTabs")) : []

  setPageTabs(tabs) {
    this.pageTabs = tabs
  }
}

export interface IUdMainPageTabsStore {
  pageTabs: any[];
  setPageTabs: (tabs) => void;
}

const udMainPageTabsStore: IUdMainPageTabsStore = new UdMainPageTabsStore()

export default udMainPageTabsStore
