import React, { useState, useEffect, useContext } from 'react';
import { toJS } from 'mobx';
import { Form, Input, InputNumber, TreeSelect, Checkbox, Radio, Select, Switch } from 'antd';
import _ from 'lodash';
import cls from 'classnames';
import axios from 'axios';
import APIS from '@/apis/api';
import { useStore } from '@/store/useStore';
import SvgIcon from '@/components/SvgIcon';
import { WorkplaceContext } from './context';
import { AXIS_STYLE_CHARTS, AXIS_LABELS } from '@/utils/enum';
import ColorSelector from '@/components/chart/components/shape-attr/ColorSelector';
import ModalMarkContent from './modal-mark-content';
import StyleAxisConfig from './style-axis-config';

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};
const tailFormItemLayout = {
  wrapperCol: {
    span: 17,
    offset: 7
  }
};
const { TreeNode } = TreeSelect;
const { Option } = Select;

function StylePanelTab() {
  const { dashboardStore } = useStore();
  const { triggerUpdate, setTriggerUpdate } = useContext(WorkplaceContext);
  const { activeChart } = dashboardStore;
  const [formData, setFormData] = useState(null);
  const [places, setPlaces] = useState(JSON.parse(sessionStorage.getItem('places')));
  const [visibleModalMark, setVisibleModalMark] = useState(false);

  useEffect(() => {
    setFormData(_.cloneDeep(activeChart.styleConfig));
  }, [dashboardStore.state.activeIndex]);

  useEffect(() => {
    if (activeChart.chartType.includes('map') && activeChart.chartType !== 'treemap' && !places) {
      axios.get(APIS.GetGEOTreeMenu).then(res => {
        sessionStorage.setItem('places', JSON.stringify([res.data]));
        setPlaces([res.data]);
      });
    }
  }, []);

  const handleBlur = () => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.styleConfig.title = formData.title;
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
  };

  const handleColorChange = (type, payload) => {
    const { charts, activeIndex } = dashboardStore.state;
    const updatedItem = _.cloneDeep(charts[activeIndex]);
    updatedItem.styleConfig = {
      ...updatedItem.styleConfig,
      colorCase: payload
    };
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    setFormData({
      ...formData,
      ...updatedItem.styleConfig
    });
  };

  const handleEndValueChange = endValue => {
    setFormData({
      ...formData,
      endValue
    });
  };

  const handleEndValueBlur = () => {
    const updatedItem = _.cloneDeep(activeChart);
    const originalValue = updatedItem.dataConfig.chartOptions.series[0].data[0].originalValue;
    updatedItem.styleConfig.endValue = formData.endValue;
    updatedItem.dataConfig.chartOptions.series[0].data[0].value = formData.endValue
      ? parseFloat(((originalValue * 100) / formData.endValue).toFixed(2))
      : 100;
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
  };

  const renderTreeNode = node => {
    const { code, name, children } = node;
    return (
      <TreeNode value={code} title={name} key={`${name}-${code}`}>
        {Array.isArray(children) && children.map(child => renderTreeNode(child))}
      </TreeNode>
    );
  };

  const handleFormDataPropChange = (prop, val) => {
    const newFormData = _.set(formData, prop, val);
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.styleConfig = {
      ...updatedItem.styleConfig,
      ...newFormData
    };
    setFormData({
      ...formData,
      ...newFormData
    });
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
  };

  const editMarkContent = () => {
    setVisibleModalMark(true);
  };

  const cancelMarkContent = () => {
    setVisibleModalMark(false);
  };

  const confirmMarkContent = content => {
    const updatedItem = _.cloneDeep(activeChart);
    const newFormData = _.set(formData, 'mark.content', content);

    setFormData({
      ...formData,
      ...newFormData
    });
    updatedItem.styleConfig.mark.content = content;
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
    cancelMarkContent();
  };

  const handleMapRangeCodeChange = (code, label) => {
    const { frontChartId } = activeChart;
    const updatedItem = _.cloneDeep(activeChart);
    const name = label[0];
    updatedItem.styleConfig.mapRange.name = name;
    updatedItem.styleConfig.mapRange.code = code;
    updatedItem.dataConfig.adcode = code;
    updatedItem.dataConfig.mapName = name;
    dashboardStore.modChart({ frontChartId, updatedItem });
    setTimeout(() => {
      setTriggerUpdate(true);
    }, 200);
  };

  const handleStyleAxisPropChange = (key, val) => {
    const updatedItem = _.cloneDeep(activeChart);
    updatedItem.styleConfig = _.set(updatedItem.styleConfig, key, val);
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
  };

  const handleKpiCardStylePropChange = (key, val) => {
    const updatedItem = _.cloneDeep(activeChart);
    const newFormData = _.set(formData, key, val);

    setFormData({
      ...formData,
      ...newFormData
    });
    updatedItem.styleConfig = _.set(updatedItem.styleConfig, key, val);
    dashboardStore.modChart({ frontChartId: activeChart.frontChartId, updatedItem });
  };
  // console.log('render StylePanelTab: ', formData);
  return (
    <div className="setting-panel-tab style-panel-tab">
      {formData && (
        <>
          <div className="title-selector-wrapper">
            <Form {...formItemLayout} colon={false} labelAlign="left">
              <Form.Item label="标题">
                <Input
                  placeholder="请输入标题"
                  size="small"
                  value={formData.title}
                  onChange={ev => handleFormDataPropChange('title', ev.target.value)}
                  onBlur={handleBlur}
                />
              </Form.Item>
              <Form.Item label="备注">
                <Checkbox
                  checked={formData.mark.show}
                  onChange={ev => handleFormDataPropChange('mark.show', ev.target.checked)}
                />
              </Form.Item>
              <Form.Item {...tailFormItemLayout} className="form-item-mark-box">
                <div className={cls('form-item-mark-content', { disabled: !formData.mark.show })}>
                  <label>备注内容</label>
                  <i className="svg-icon-wrapper icon-edit" onClick={editMarkContent}>
                    <SvgIcon iconClass="edit" />
                  </i>
                </div>
              </Form.Item>
              <Form.Item {...tailFormItemLayout} className="form-item-mark-box">
                <div className={cls('form-item-mark-position', { disabled: !formData.mark.show })}>
                  <label>位置</label>
                  <Radio.Group
                    size="small"
                    value={formData.mark.position}
                    onChange={ev => handleFormDataPropChange('mark.position', ev.target.value)}
                  >
                    <Radio value="title">紧跟标题</Radio>
                    <Radio value="chart">图表上方</Radio>
                  </Radio.Group>
                </div>
              </Form.Item>
              {activeChart.chartType === 'table' && (
                <>
                  <Form.Item label="汇总">
                    <Switch
                      checked={formData.summary}
                      onChange={checked => handleFormDataPropChange('summary', checked)}
                    />
                  </Form.Item>
                  <Form.Item label="分页">
                    <Switch
                      checked={formData.pagination}
                      onChange={checked => handleFormDataPropChange('pagination', checked)}
                    />
                  </Form.Item>

                </>
              )}
            </Form>
          </div>
          {!activeChart.chartType.includes('table') && (
            <ColorSelector
              backgroundColor={formData.bgColor}
              activeColorCase={formData.colorCase}
              onColorChange={handleColorChange}
              hideBgColorFill
            />
          )}
          {activeChart.chartType === 'gauge' && (
            <div className="end-value-selector-wrapper" style={{ marginTop: '8px' }}>
              <Form {...formItemLayout} colon={false} labelAlign="left">
                <Form.Item label="终点值">
                  <InputNumber
                    placeholder="请输入最大值"
                    size="small"
                    value={formData.endValue}
                    min={0}
                    onChange={handleEndValueChange}
                    onBlur={handleEndValueBlur}
                  />
                </Form.Item>
              </Form>
            </div>
          )}
          {activeChart.chartType === 'kpi' && (
            <div className="kpi-addon-selector-wrapper" style={{ marginTop: '8px' }}>
              <Form {...formItemLayout} colon={false} labelAlign="left">
                <Form.Item label="字体大小"></Form.Item>
                <Form.Item label="数值">
                  <Select
                    value={formData.indicator.value.fontSize}
                    onChange={val => handleKpiCardStylePropChange('indicator.value.fontSize', val)}
                  >
                    {new Array(26)
                      .fill(0)
                      .map((item, index) => 10 + index * 2)
                      .map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="名称">
                  <Select
                    value={formData.indicator.name.fontSize}
                    onChange={val => handleKpiCardStylePropChange('indicator.name.fontSize', val)}
                  >
                    {new Array(26)
                      .fill(0)
                      .map((item, index) => 10 + index * 2)
                      .map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="指标数据值前后缀" labelCol={{ span: 24 }}></Form.Item>
                <Form.Item label="前缀">
                  <Input
                    placeholder="请填写 (如￥)"
                    value={formData.addon.prefix}
                    onChange={ev => handleKpiCardStylePropChange('addon.prefix', ev.target.value)}
                  />
                </Form.Item>
                <Form.Item label="后缀">
                  <Input
                    placeholder="请填写 (如元)"
                    value={formData.addon.suffix}
                    onChange={ev => handleKpiCardStylePropChange('addon.suffix', ev.target.value)}
                  />
                </Form.Item>
              </Form>
            </div>
          )}
          {AXIS_STYLE_CHARTS.includes(activeChart.chartType) &&
            ['xAxis', 'yAxis'].concat(activeChart.chartType === 'combination' ? ['y2Axis'] : []).map(item => (
              <div className="style-axis-item" key={item}>
                <label className="style-axis-item-label">{AXIS_LABELS[item]}</label>
                <StyleAxisConfig
                  type={item}
                  styleConfig={activeChart.styleConfig}
                  onPropChange={handleStyleAxisPropChange}
                />
              </div>
            ))}
        </>
      )}
      {activeChart.chartType.includes('map') && activeChart.chartType !== 'treemap' && (
        <div className="map-range-wrapper">
          <div className="map-range-header">
            <div className="area-name">地图范围</div>
          </div>
          <div className="map-range-body">
            <TreeSelect
              dropdownClassName="map-range-tree-select"
              style={{ width: '100%' }}
              value={activeChart.styleConfig.mapRange.code}
              dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
              placeholder="请选择地图范围"
              onChange={(value, label, extra) => handleMapRangeCodeChange(value, label)}
            >
              {Array.isArray(places) && places.map(node => renderTreeNode(node))}
            </TreeSelect>
          </div>
        </div>
      )}
      {visibleModalMark && (
        <ModalMarkContent
          visible={visibleModalMark}
          content={activeChart.styleConfig.mark.content}
          onCancel={cancelMarkContent}
          onOk={confirmMarkContent}
        />
      )}
    </div>
  );
}

export default StylePanelTab;
