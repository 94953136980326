import React, { FC, forwardRef, useImperativeHandle } from 'react';
import { Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import './style.less';

interface IProps extends FormComponentProps {
  shortCode: string;
  onFreshToken: (params: any) => void;
}
interface IRef extends FormComponentProps {}
const LinkPwd: FC<IProps> = forwardRef<IRef, IProps>((props, ref) => {
  const {
    form,
    form: { getFieldDecorator, validateFields },
    shortCode,
    onFreshToken
  } = props;
  useImperativeHandle(ref, () => ({
    form
  }));
  const handleSubmit = () => {
    validateFields((errors, values) => {
      if (errors) return;
      onFreshToken(values);
    });
  };
  return (
    <div className="link-pwd">
      <div className="pwd-content">
        <div className="pwd-header">请输入密码打开链接</div>
        <Form>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: '请输入4位数字或字母' },
                { len: 4, message: '请输入4位数字或字母' }
              ]
            })(<Input type="password" placeholder="请输入4位数字或字母" />)}
          </Form.Item>
          <Button type="primary" className="btn-pwd-submit" onClick={handleSubmit}>
            确定
          </Button>
        </Form>
      </div>
    </div>
  );
});

export default Form.create<IProps>({})(LinkPwd);
