const ENV = process.env.REACT_APP_ENV;

console.log('当前环境=', ENV);

const BASE_URL_OBJ = {
  dev: 'http://dev.bi.jzsz.cc/gateway',
  test: 'http://47.108.134.242:8888',
  uat: 'http://47.108.134.242:8888',
  prod: 'http://dev.bi.jzsz.cc/gateway',
  demo: 'https://data.jzsz.cc/gateway',
  atl: 'http://47.108.58.121:9000/gateway'
};

export const BASE_URL = BASE_URL_OBJ[ENV] || BASE_URL_OBJ.dev;

const APIS = {
  // 登录授权
  OauthToken: BASE_URL + '/uaa-auth/oauth/token',
  // 登出
  OauthLogout: BASE_URL + '/uaa-auth/oauth/logout',
  // 获取用户菜单信息
  UserInfo: BASE_URL + '/uaa-admin-app/user/v1/info',
  // 获取验证码
  GenerateCaptcha: BASE_URL + '/uaa-auth/oauth/generateCaptcha',
  // 刷新验证码
  RefreshCaptcha: BASE_URL + '/uaa-auth/oauth/refreshCaptcha',
  // 是否展示验证码
  VisibleCaptcha: BASE_URL + '/uaa-auth/oauth/visibleCaptcha',

  /**
   * --------------用户----------
   */
  // 用户列表
  UserList: BASE_URL + '/uaa-admin-app/user/v1/list',
  // 用户详情
  UserDetails: BASE_URL + '/uaa-admin-app/user/v1/details',
  // 重置密码
  ResetPassword: BASE_URL + '/uaa-admin-app/user/v1/resetPassword',
  // 修改密码
  ModifyPassword: BASE_URL + '/uaa-admin-app/user/v1/modifyPassword',
  // 更改用户状态
  UserChangeStatus: BASE_URL + '/uaa-admin-app/user/v1/changeStatus',
  // 设置岗位
  UserSetDepartment: BASE_URL + '/uaa-admin-app/user/v1/setDepartment',
  // 查询用户关联的岗位信息
  UserFindUserDepartments: BASE_URL + '/uaa-admin-app/user/v1/findUserDepartments',
  // 新增或者修改用户
  UserSaveOrUpdate: BASE_URL + '/uaa-admin-app/user/v1/saveOrUpdate',
  // 获取用户关联的组织信息
  GetAllOrgGenreByUser: BASE_URL + '/uaa-admin-app/org/v1/getAllOrgGenreByUser',
  // 获取组织维度类型下拉框
  FindOrgGenreComBox: BASE_URL + '/uaa-admin-app/commons/v1/findOrgGenreComBox',
  // 权限类型下拉框
  GetAllDataAuthorization: BASE_URL + '/uaa-admin-app/userDataAuthorization/v1/getAllDataAuthorization',
  // 获取用户绑定的权限信息
  GetUserDataAuthorization: BASE_URL + '/uaa-admin-app/userDataAuthorization/v1/getUserDataAuthorization',
  // 获取 uaa 用户数据源表权限配置
  FindDataSourceTableAuthorization: BASE_URL + '/int-bi-app/datasource/v1/findDataSourceTableAuthorization',
  // 获取 uaa 用户数据源权限相关基础数据
  FindDataSourceAuthorization: BASE_URL + '/int-bi-app/datasource/v1/findDatasourceAuthorization',
  // 保存用户权限
  SaveUserDataAuthorization: BASE_URL + '/uaa-admin-app/userDataAuthorization/v1/save',

  /**
   * --------------岗位----------
   */
  // 岗位列表
  DepartmentList: BASE_URL + '/uaa-admin-app/department/v1/list',
  // 岗位详情
  DepartmentDetails: BASE_URL + '/uaa-admin-app/department/v1/details',
  // 更改岗位状态
  DepartmentChangeStatus: BASE_URL + '/uaa-admin-app/department/v1/changeStatus',
  // 获取岗位数据
  DepartmentFindDepartment: BASE_URL + '/uaa-admin-app/user/v1/findDepartment',
  // 查看关联用户
  DepartmentViewRelationUser: BASE_URL + '/uaa-admin-app/department/v1/viewRelationUser',
  // 查看关联角色
  DepartmentViewRelationRole: BASE_URL + '/uaa-admin-app/department/v1/viewRelationRole',
  // 新增或修改岗位信息
  DepartmentSaveOrUpdate: BASE_URL + '/uaa-admin-app/department/v1/saveOrUpdate',
  // 查询角色信息
  DepartmentFindRoles: BASE_URL + '/uaa-admin-app/department/v1/findRoles',
  // 关联角色信息
  DepartmentRelationRole: BASE_URL + '/uaa-admin-app/department/v1/relationRole',

  /**
   * --------------角色----------
   */
  // 角色列表
  RoleList: BASE_URL + '/uaa-admin-app/role/v1/list',
  // 角色详情
  RoleDetails: BASE_URL + '/uaa-admin-app/role/v1/details',
  // 更改角色状态
  RoleChangeStatus: BASE_URL + '/uaa-admin-app/role/v1/changeStatus',
  // 新增或修改角色信息
  RoleSaveOrUpdate: BASE_URL + '/uaa-admin-app/role/v1/saveOrUpdate',
  // 获取app应用数据
  RoleFindApps: BASE_URL + '/uaa-admin-app/role/v1/findApps',
  // 获取资源树
  RoleFindResourceTree: BASE_URL + '/uaa-admin-app/role/v1/findResourceTree',

  /**
   * --------------数据中心----------
   */
  // 数据中心概览
  DataCenterSummary: BASE_URL + '/int-bi-app/dataWarehouse/v1/dataCenterSummary',
  // 数据中心列表
  DataCenterTableList: BASE_URL + '/int-bi-app/dataWarehouse/v1/tableList',
  // 数据中心表结构
  DataCenterTableStructure: BASE_URL + '/int-bi-app/dataWarehouse/v1/tableStructure',
  // 查看表数据
  DataCenterTableDataView: BASE_URL + '/int-bi-app/dataWarehouse/v1/tableDataView',
  // 导出表数据
  DataCenterTableDataExport: BASE_URL + '/int-bi-app/dataWarehouse/v1/tableDataExport',
  // 数据仓表数据上传
  DataCenterTableDataImport: BASE_URL + '/int-bi-app/dataWarehouse/v1/tableDataImport',
  //数据仓表删除
  DataWarehouseTableDelete: BASE_URL + '/int-bi-app/dataWarehouse/v1/tableDataDelete',

  /**
   * --------------数据集----------
   */
  // 数据集列表
  DataProcessingList: BASE_URL + '/int-bi-app/dataSet/v1/list',
  // 数据集详情
  DataProcessingDetails: BASE_URL + '/int-bi-app/dataSet/v1/details',
  // 修改数据集状态
  DataProcessingChangeStatus: BASE_URL + '/int-bi-app/dataSet/v1/changeStatus',
  // 删除数据集
  DataProcessingDelete: BASE_URL + '/int-bi-app/dataSet/v1/delete',
  // 保存数据集
  DataProcessingSaveOrUpdate: BASE_URL + '/int-bi-app/dataSet/v1/saveOrUpdate',
  // 数据集预览
  DataProcessingPreview: BASE_URL + '/int-bi-app/dataSet/v1/preview',
  // 获取数据仓表
  DataProcessingFindDataSet: BASE_URL + '/int-bi-app/dataSet/v1/findDataWarehouseTables',
  // 获取函数信息
  DataProcessingFindFunctions: BASE_URL + '/int-bi-app/dataSet/v1/findFunctions',
  // 同步表结构
  DataProcessingSyncTableStructure: BASE_URL + '/int-bi-app/dataSet/v1/syncTableStructure',
  // 获取用户下拉框
  FindUserComBox: BASE_URL + '/uaa-admin-app/commons/v1/findUserComBox',
  // 获取角色下拉框
  FindRoleComBox: BASE_URL + '/uaa-admin-app/commons/v1/findRoleComBox',
  // 获取岗位下拉列表
  FindDepartmentComBox: BASE_URL + '/uaa-admin-app/commons/v1/findDepartmentCombox',
  // 获取数据集字段数据
  FindDataSetFields: BASE_URL + '/int-bi-app/dataSetAuthority/v1/findDataSetFields',
  // 新增、更新数据集行级权限配置
  SaveRowAuthority: BASE_URL + '/int-bi-app/dataSetAuthority/v1/saveRowAuthority',
  // 获取行级权限详情
  RowAuthorityDetail: BASE_URL + '/int-bi-app/dataSetAuthority/v1/rowAuthorityDetail',
  // 新增、更新数据集列权限
  SaveColumnAuthority: BASE_URL + '/int-bi-app/dataSetAuthority/v1/saveColumnAuthority',
  // 获取数据集列权限详情
  ColumnAuthorityDetail: BASE_URL + '/int-bi-app/dataSetAuthority/v1/columnAuthorityDetail',
  // 生成数据报表
  GenerateDataReport: BASE_URL + '/int-bi-app/dataSet/v1/generateDataReport',
  // 数据报表详情
  DataSetReportDetail: BASE_URL + '/int-bi-app/dataReport/v1/reportDetail',
  // 获取数据集字段数据
  FindDataSetFielsComBox: BASE_URL + '/int-bi-app/dataSetAuthority/v1/findDataSetFieldsComBox',
  // 查看数据集
  DataSetDataPageList: BASE_URL + '/int-bi-app/dataSet/v1/dataSetDataPageList',
  // 设置导出字段
  DataSetSaveReportColumnConfig: BASE_URL + '/int-bi-app/dataSet/v1/saveReportColumnConfig',
  // 导出数据集
  DataSetExportTask: BASE_URL + '/int-bi-app/dataSet/v1/exportTask',
  // 运行SQL数据集语句
  RunDataSetSQL: BASE_URL + '/int-bi-app/dataSet/v1/runCommand',
  // 解析SQL语句
  ParserDataSetSQL: BASE_URL + '/int-bi-app/dataSet/v1/parserCommand',

  // 获取数据集导出字段配置
  GetDataSetReportConfig: BASE_URL + '/int-bi-app/dataSet/v1/getDataReportConfig',
  // 获取数据集指定字段数据
  GetDataSetDataByFields: BASE_URL + '/int-bi-app/dataSet/v1/getDataSetDataByFields',
  /**
   * --------------数据看板----------
   */
  // 数据看板列表
  DataBoardList: BASE_URL + '/int-bi-app/dataBoard/v1/list',
  // 修改数据看板状态
  DataBoardChangeStatus: BASE_URL + '/int-bi-app/dataBoard/v1/changeStatus',
  // 保存数据看板
  DataBoardSave: BASE_URL + '/int-bi-app/dataBoard/v1/save',
  // 删除数据看板
  DataBoardDelete: BASE_URL + '/int-bi-app/dataBoard/v1/delete',
  // 数据看板详情
  DataBoardDetails: BASE_URL + '/int-bi-app/dataBoard/v1/details',
  // 查看数据看板
  DataBoardView: BASE_URL + '/int-bi-app/dataBoard/v1/view',
  // 根据图表配置获取图表数据
  DataBoardGetChartsData: BASE_URL + '/int-bi-app/dataBoard/v1/getChartsData',
  // 根据图表配置获取对比交叉图表数据
  // GetCompareTableChartsData: BASE_URL + '/int-bi-app/dataBoard/v1/getCompareTableChartsData',
  // 根据id获取图表数据
  DataBoardGetChartsDataById: BASE_URL + '/int-bi-app/dataBoard/v1/getChartsDataById',
  // 获取数据集下拉数据
  DataBoardFindDataSets: BASE_URL + '/int-bi-app/dataBoard/v1/findDataSets',
  // 获取数据集字段数据
  DataBoardFindDataSetFields: BASE_URL + '/int-bi-app/dataBoard/v1/findDataSetFields',

  // 获取sql条件符
  FindSqlCondition: BASE_URL + '/int-bi-app/commons/v1/findSqlCondition',
  // 仪表盘图表导出
  DataBoardExportChart: BASE_URL + '/int-bi-app/dataBoard/v1/exportChart',
  // 获取用户数据集下拉数据
  DataBoardFindUserDataSets: BASE_URL + '/int-bi-app/dataBoard/v1/findUserDataSets',
  // 获取数据集字段对应的值
  DataBoardFindDataSetFieldValues: BASE_URL + '/int-bi-app/dataBoard/v1/findDataSetFieldValues',

  /**
   * --------------监控预警----------
   */
  // 保存监控预警
  SaveMonitor: BASE_URL + '/int-bi-app/monitor/v1/save',
  // 监控预警详情
  MonitorDetails: BASE_URL + '/int-bi-app/monitor/v1/details',
  // 删除预警
  DeleteMonitor: BASE_URL + '/int-bi-app/monitor/v1/delete',
  // 监控日志列表
  MonitorLogList: BASE_URL + '/int-bi-app/monitorLog/v1/list',
  // 监控日志详情列表
  MonitorLogDetail: BASE_URL + '/int-bi-app/monitorLog/v1/detail',
  // 监控预警列表
  MonitorList: BASE_URL + '/int-bi-app/monitor/v1/list',
  // 获取图表预警配置
  FindChartWarningConfig: BASE_URL + '/int-bi-app/monitor/v1/findChartWarningConfig',

  /**
   * --------------组织管理----------
   */
  // 组织列表
  PageOrg: BASE_URL + '/uaa-admin-app/org/v1/pageOrg',
  // 新增、更新组织
  SaveOrg: BASE_URL + '/uaa-admin-app/org/v1/saveOrg',
  // 删除组织
  DeleteOrg: BASE_URL + '/uaa-admin-app/org/v1/deleteOrg',
  // 组织类型列表
  GetAllOrgGenre: BASE_URL + '/uaa-admin-app/org/v1/getAllOrgGenre',
  // 新增、更新组织类型
  SaveOrgGenre: BASE_URL + '/uaa-admin-app/org/v1/saveOrgGenre',
  // 删除组织类型
  DeleteOrgGenre: BASE_URL + '/uaa-admin-app/org/v1/deleteOrgGenre',

  /**
   * --------------数据报表----------
   */
  // 列表查询
  PageDataReport: BASE_URL + '/int-bi-app/dataReport/v1/pageDateReport',
  // 数据报表详情
  DataReportDetail: BASE_URL + '/int-bi-app/dataReport/v1/reportDataDetail',
  // 导出字段配置
  SaveReportColumnConfig: BASE_URL + '/int-bi-app/dataReport/v1/saveReportColumnConfig',
  // 获取登录用户的报表配置
  GetDataReportConfig: BASE_URL + '/int-bi-app/dataReport/v1/getDataReportConfig',
  // 导出数据报表
  ExportTask: BASE_URL + '/int-bi-app/dataReport/v1/exportTask',
  // 获取当前登录用户的下载任务
  GetExportTaskList: BASE_URL + '/int-bi-app/dataReport/v1/getExPortTaskList',
  // 下载数据报表
  DownloadDataReport: BASE_URL + '/int-bi-app/dataReport/v1/download',
  // 删除导出任务
  DeleteExportTask: BASE_URL + '/int-bi-app/dataReport/v1/deleteExportTask',
  // 数据报表编辑
  UpdateDataReport: BASE_URL + '/int-bi-app/dataReport/v1/update',

  /**
   * -------------- 数据集表关联 ----------
   */
  // 获取数据源下拉框
  FindDataSources: BASE_URL + '/int-bi-app/dataSet/v1/findDataSources',
  // 获取数据源表
  FindTables: BASE_URL + '/int-bi-app/dataSet/v1/findTables',
  // 获取数据表字段信息
  FindTableFields: BASE_URL + '/int-bi-app/dataSet/v1/findTableFields',
  // 获取数据集数据
  ViewData: BASE_URL + '/int-bi-app/dataSet/v1/viewData',
  // 保存数据集
  SaveOrUpdateDataSet: BASE_URL + '/int-bi-app/dataSet/v1/saveOrUpdate',
  // 数据集详情
  DataSetDetails: BASE_URL + '/int-bi-app/dataSet/v1/details',
  /**
   * -------------- 数据源 ----------
   */
  // 数据源列表
  DataSourceList: BASE_URL + '/int-bi-app/datasource/v1/list',
  // 查看数据源表
  DataSourceListTables: BASE_URL + '/int-bi-app/datasource/v1/listTables',
  // 新增、编辑数据源
  DataSourceSave: BASE_URL + '/int-bi-app/datasource/v1/save',
  // API数据源保存
  APIDataSourceSave: BASE_URL + '/int-bi-app/datasource/v1/saveAPIConfig',
  // 删除数据源
  DataSourceDelete: BASE_URL + '/int-bi-app/datasource/v1/delete',
  // 数据源详情
  DataSourceDetails: BASE_URL + '/int-bi-app/datasource/v1/details',
  // 获取数据库类型
  FindDatabaseTypes: BASE_URL + '/int-bi-app/commons/v1/findDatabaseTypes',
  // 测试连接
  TestConnect: BASE_URL + '/int-bi-app/datasource/v1/testConnect',
  // API数据源测试连接
  TestAPIConnect: BASE_URL + '/int-bi-app/datasource/v1/testAPIConnect',
  // 数据源表数据上传
  DataSourceUploadTable: BASE_URL + '/int-bi-app/datasource/v1/tableUpload',
  // 获取数据源表字段信息
  GetUploadTableFields: BASE_URL + '/int-bi-app/datasource/v1/getUploadTableFields',
  //查看数据源表结构
  DataSourceTableStructure: BASE_URL + '/int-bi-app/datasource/v1/tableStructure',
  // 查看数据源表数据
  DataSourceViewTableData: BASE_URL + '/int-bi-app/datasource/v1/viewTableData',
  //数据源表删除
  DataSourceTableDelete: BASE_URL + '/int-bi-app/datasource/v1/tableDelete',
  // 数据源表数据上传日志信息
  DataSourceDataImportLog: BASE_URL + '/int-bi-app/datasource/v1/listTableDataImportLog',
  //同步数据源表
  DataSourceSyncTables: BASE_URL + '/int-bi-app/datasource/v1/syncTables',
  // 可用的请求content type
  FindRequestContentTypes: BASE_URL + '/int-bi-app/commons/v1/findAPIContentType',
  // 获取API响应字段
  FindAPIResultFieldTree: BASE_URL + '/int-bi-app/datasource/v1/getAPIResultFieldTree',
  // 预览API数据
  PreviewAPIData: BASE_URL + '/int-bi-app/datasource/v1/previewAPIData',
  // API数据源同步方式
  FindAPIDataSyncMethods: BASE_URL + '/int-bi-app/commons/v1/findAPISyncMethod',
  // API数据源数据更新方式
  FindAPIDataUpdateMethods: BASE_URL + '/int-bi-app/commons/v1/findAPIUpdateType',
  // 获取API数据源配置列表
  FindAPIDataConfigs: BASE_URL + '/int-bi-app/datasource/v1/findAPIConfigs',
  // API数据源配置详情
  APIDataConfigDeatils: BASE_URL + '/int-bi-app/datasource/v1/APIDatasourceConfigDetails',
  // API数据源配置删除
  DeleteAPIDatasourceConfig: BASE_URL + '/int-bi-app/datasource/v1/deleteAPIConfig',
  // API数据源数据同步
  SyncAPIDatasourceData: BASE_URL + '/int-bi-app/datasource/v1/syncAPIData',
  /**
   * -------------- 数据订阅 ----------
   */
  // 订阅任务列表
  DataSubsList: BASE_URL + '/int-bi-app/dataSubs/v1/list',
  // 新增订阅任务
  DataSubsSave: BASE_URL + '/int-bi-app/dataSubs/v1/save',
  // 启用、禁用订阅任务
  DataSubsChangeStatus: BASE_URL + '/int-bi-app/dataSubs/v1/changStatus',
  // 删除任务
  DataSubsDelete: BASE_URL + '/int-bi-app/dataSubs/v1/delete',
  // 获取订阅任务详情
  DataSubsDetail: BASE_URL + '/int-bi-app/dataSubs/v1/detail',
  // 数据报表下拉框
  FindDataReportCombox: BASE_URL + '/int-bi-app/reportTpl/v1/findDataReportComBox',
  // 发送记录
  DataSubsLogList: BASE_URL + '/int-bi-app/dataSubs/v1/logList',
  // 查询仪表板下拉框
  FindDataBoardCombox: BASE_URL + '/int-bi-app/dataBoard/v1/findDataBoardCombox',
  /**
   * -------------- 数据报表模板管理 ----------
   */
  // 保存报表模板
  SaveReportTpl: BASE_URL + '/int-bi-app/reportTpl/v1/saveReportTpl',
  // 预览
  PreviewReportTpl: BASE_URL + '/int-bi-app/reportTpl/v1/preview',
  // 模板分页查询
  ReportTplPageList: BASE_URL + '/int-bi-app/reportTpl/v1/pageList',
  // 模板详情
  ReportTplDetail: BASE_URL + '/int-bi-app/reportTpl/v1/reportTplDetail',
  // 删除报表模板
  DeleteReportTpl: BASE_URL + '/int-bi-app/reportTpl/v1/deleteReportTpl',
  // 导出
  ExportReportTpl: BASE_URL + '/int-bi-app/reportTpl/v1/export ',
  /**
   * -------------- 数据权限 ----------
   */
  // 获取用户绑定的所有数据权限信息
  DataPermissionUserBind: BASE_URL + '/uaa-admin-app/dataPermission/v1/permission',
  // 保存用户权限
  DataPermissionSave: BASE_URL + '/uaa-admin-app/dataPermission/v1/save',
  // 获取数据权限拥有者及数据操作集
  DataPermissionOwnerOperates: BASE_URL + '/uaa-admin-app/dataPermission/v1/ownerOperates',
  // 权限类型下拉框
  DataPermissionAllDataAuthorization: BASE_URL + '/uaa-admin-app/dataPermission/v1/getAllDataAuthorization',
  /**
   * -------------- 数据授权管理 ----------
   */
  PermissionOwnerOperates: BASE_URL + '/int-bi-app/permission/v1/ownerOperates',
  PermissionAuthorize: BASE_URL + '/int-bi-app/permission/v1/authorize',
  /**
   * -------------- 公链管理 ----------
   */
  // 生成公链
  PublicLinkGenerate: BASE_URL + '/int-bi-app/publicLink/v1/generate',
  // 获取资源链接信息
  PublicLinkGetResourceLink: BASE_URL + '/int-bi-app/publicLink/v1/getResourceLink',
  // 启用、禁用公链分享
  PublicLinkChangeStatus: BASE_URL + '/int-bi-app/publicLink/v1/changStatus',
  // 设置公链有效期
  PublicLinkRestOverTime: BASE_URL + '/int-bi-app/publicLink/v1/restOverTime',
  // 设置公链密码
  PublicLinkRestPassword: BASE_URL + '/int-bi-app/publicLink/v1/restPassword',
  // 访问公链前置校验
  LinkValid: BASE_URL + '/int-bi-app/link/v1/valid',
  // 校验短链密码
  LinkCheckPwd: BASE_URL + '/int-bi-app/link/v1/checkPwd',
  // 查看仪表板
  LinkBoardDetail: BASE_URL + '/int-bi-app/link/v1/boardDetail',
  // 根据图表配置获取图表数据
  LinkGetChartsDetail: BASE_URL + '/int-bi-app/link/v1/getChartsDetail',
  // 查看报表模板
  LinkPreviewReport: BASE_URL + '/int-bi-app/link/v1/previewReport',
  // 大屏详情
  LinkScreenDetail: BASE_URL + '/int-bi-app/link/v1/screenDetail',
  // 获取公链地址
  publicGetResourceLink: BASE_URL + '/int-bi-app/publicLink/v1/publicGetResourceLink',
  /**
   * -------------- 大屏管理 ----------
   */
  // 保存数据大屏
  DataScreenSave: BASE_URL + '/int-bi-app/dataScreen/v1/save',
  // 大屏列表
  DataScreenList: BASE_URL + '/int-bi-app/dataScreen/v1/list',
  // 启用、禁用
  DataScreenChangeStatus: BASE_URL + '/int-bi-app/dataScreen/v1/changeStatus',
  // 查看详情
  DataScreenDetail: BASE_URL + '/int-bi-app/dataScreen/v1/detail',
  // 删除数据大屏
  DataScreenDelete: BASE_URL + '/int-bi-app/dataScreen/v1/delete',
  // 获取大屏图表数据
  DataScreenGetScreenChartsData: BASE_URL + '/int-bi-app/dataScreen/v1/getScreenChartsData',
  /**
   * -------------- 地区GEO Json ----------
   */
  GetAreaGEOJson: 'https://oss.jzsz.cc/location',
  // 获取省市区三级菜单
  GetGEOTreeMenu: 'https://oss.jzsz.cc/location/geoTree.json',

  /**
   * -------------- 数据指标 ----------
   */
  // 数据指标列表
  DataIndicatorList: BASE_URL + '/int-bi-app/dataIndicator/v1/list',
  // 数据指标节点树
  DataIndicatorTree: BASE_URL + '/int-bi-app/dataIndicator/v1/tree',
  // 数据指标树节点详情
  DataIndicatorTreeNodeDetails: BASE_URL + '/int-bi-app/dataIndicator/v1/treeNodeDetails',
  // 搜索指标
  DataIndicatorSearch: BASE_URL + '/int-bi-app/dataIndicator/v1/search',
  // 下载导入模板
  DataIndicatorDownloadTmp: BASE_URL + '/int-bi-app/dataIndicator/v1/downloadTmp',
  // 导入数据
  DataIndicatorUpload: BASE_URL + '/int-bi-app/dataIndicator/v1/upload',
  // 删除数据指标
  DataIndicatorDelete: BASE_URL + '/int-bi-app/dataIndicator/v1/delete',

  // License 信息
  LicenseInfo: BASE_URL + '/int-bi-app/license/licenseInfo',

  /**
   * -------------- 统一日志 ----------
   */
  // 操作日志列表
  OperateLogList: BASE_URL + '/logging-app/logging/operate/v1/list',
  // 根据日志类型获取下拉组合
  FindComBoxByLogType: BASE_URL + '/logging-app/logging/app/v1/findComBoxByLogType'
};

export default APIS;
