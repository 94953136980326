import { cloneDeep } from 'lodash';
import { formatEchartsValueWithDecimal } from '@/utils/utils';

export const BASE_PIE = {
  title: {
    show: false
  },
  tooltip: {
    trigger: 'item',
    formatter: ({ data, percent }) => {
      return `${data.name} : ${data.displayValue} (${percent}%)`;
    }
  },
  legend: {
    show: true,
    type: 'scroll',
    top: 'top',
    left: 'center',
    orient: 'horizontal',
    itemWidth: 10,
    itemHeight: 10,
    icon: 'rect'
  },
  series: [
    {
      name: '访问来源',
      type: 'pie',
      radius: '50%',
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

export const ROSE_PIE = {
  ...cloneDeep(BASE_PIE),
  series: [
    {
      type: 'pie',
      radius: [0, 100],
      center: ['50%', '50%'],
      roseType: 'radius',
      itemStyle: {
        borderRadius: 5
      },
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
