import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import {
  SHORT_DATE_YEAR_DISPLAY_FORMAT,
  SHORT_DATE_QUARTER_DISPLAY_FORMAT,
  SHORT_DATE_MONTH_DISPLAY_FORMAT,
  SHORT_DATE_DAY_DISPLAY_FORMAT,
  SHORT_DATE_TIME_DISPLAY_FORMAT,
  DIMENSION_GROUP_TYPES,
  DATE_MODE_FORMAT_TYPES
} from '@/utils/enum';

/**
 * 拍平字段列表(包括派生字段)
 * @param fields
 */
export const flattenFields = fields => {
  const ret = [];
  if (Array.isArray(fields) && !fields.length) return ret;
  const recursionHelperFn = data => {
    for (let i = 0, len = data.length; i < len; i++) {
      ret.push(data[i]);
      if (data[i].derivedFields && data[i].derivedFields.length) {
        recursionHelperFn(data[i].derivedFields);
      }
    }
  };
  recursionHelperFn(fields);
  return ret;
};

/**
 * 获取字段信息
 * @param fields
 * @param targetId
 */
export const getFieldInfoByUniqueId = (fields, targetId) => {
  if (!fields.length) return null;
  return fields.find(field => field.uniqueId === targetId);
};

/**
 * 修改字段属性
 * @param tableColumns
 * @param payload
 */
export const updateTableFields = (tableColumns, targetId, payload) => {
  const ret = _.cloneDeep(tableColumns);
  const {
    customName,
    physicalName,
    customFieldFunction,
    valueType,
    remark,
    source,
    dataSetFieldValueFormat,
    displayFormat,
    dimensionGroup
  } = payload;
  let found = false;
  const recursionHelperFn = (data, isDerivedField?: boolean) => {
    if (Array.isArray(data) && data.length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].uniqueId === targetId) {
          found = true;
          data[i].customName = customName;
          data[i].physicalName = physicalName;
          data[i].remark = remark;
          data[i].dimensionGroup = dimensionGroup;
          data[i].attribute.valueType = valueType;
          data[i].attribute.customFieldFunction = customFieldFunction;
          data[i].attribute.source = source;
          data[i].attribute.dataSetFieldValueFormat = dataSetFieldValueFormat;
          data[i].attribute.displayFormat = displayFormat;
          break;
        } else if (data[i].derivedFields && data[i].derivedFields.length) {
          recursionHelperFn(data[i].derivedFields, true);
          if (found) break;
        }
      }
    }
  };
  recursionHelperFn(ret);
  return ret;
};

export const calGroupFieldValueType = field => {
  const {
    attribute: { valueType, originalValueType, customFieldFunction }
  } = field;
  const isValueTypeChanged = valueType !== originalValueType;
  const isDerivedField = !!customFieldFunction;

  return isValueTypeChanged || isDerivedField ? valueType : originalValueType;
};

/**
 * 生成日期展示格式菜单
 * @param granularity 字段粒度
 * @returns
 */
export const generateDateDisplayFormatMenu = granularity => {
  let childrenObj = null;
  switch (granularity) {
    case 'year':
      childrenObj = SHORT_DATE_YEAR_DISPLAY_FORMAT;
      break;
    case 'quarter':
      childrenObj = SHORT_DATE_QUARTER_DISPLAY_FORMAT;
      break;
    case 'month':
      childrenObj = SHORT_DATE_MONTH_DISPLAY_FORMAT;
      break;
    case 'day':
      childrenObj = SHORT_DATE_DAY_DISPLAY_FORMAT;
      break;
    case 'time':
      childrenObj = SHORT_DATE_TIME_DISPLAY_FORMAT;
      break;
  }
  return [
    {
      label: '日期展示格式',
      children: Object.keys(childrenObj).map(key => ({
        label: childrenObj[key],
        value: key
      }))
    }
  ];
};

export const genGroupConditionByFieldOriginalValueType = field => {
  const { uniqueId } = field;
  const _valueType = calGroupFieldValueType(field);

  if (_valueType === 'TEXT') {
    return [
      {
        fieldUniqueId: uniqueId,
        operator: 'IN',
        values: [],
        conditionJoin: 'AND',
        frontAdditionalInfo: null
      }
    ];
  } else if (_valueType === 'NUMBER') {
    return new Array(2).fill(null).map((_, index) => ({
      fieldUniqueId: uniqueId,
      operator: index === 0 ? 'GE' : 'LE',
      values: [],
      conditionJoin: 'AND',
      frontAdditionalInfo: null
    }));
  } else if (_valueType === 'DATE') {
    return new Array(2).fill(null).map((_, index) => ({
      fieldUniqueId: uniqueId,
      operator: index === 0 ? 'GE' : 'LE',
      values: [dayjs()],
      conditionJoin: 'AND',
      frontAdditionalInfo: null,
      dateFilterType: 'INTERVAL',
      intervalType: 'TIME_INTERVAL'
    }));
  }
};

/**
 * 生成维度分组字段配置
 * @returns
 */
export const genDimGroupField = field => {
  const {
    uniqueId,
    attribute: { valueType, originalValueType, granularity }
  } = field;

  return {
    uniqueId: uuidv4(),
    customName: '',
    physicalName: '',
    remark: '',
    attribute: {
      del: false,
      sort: false,
      type: 'DIMENSION',
      hidden: false,
      source: 'CUSTOM',
      sortType: 'ASC',
      valueType: 'TEXT',
      originalValueType,
      granularity,
      customFieldFunction: null,
      dataSetFieldValueFormat: null,
      displayFormat: null
    },
    dimensionGroup: {
      groupType: DIMENSION_GROUP_TYPES[valueType],
      refDimensionUniqueId: uniqueId,
      groupModels: [
        {
          uId: uuidv4(),
          defaultGroup: false,
          groupName: '分组1',
          groupType: null,
          groupCondition: genGroupConditionByFieldOriginalValueType(field)
        },
        {
          uId: uuidv4(),
          defaultGroup: true,
          groupName: '未分组',
          groupType: null,
          groupCondition: null
        }
      ]
    }
  };
};

export const genGroupModel = (groupNameIdx, field) => {
  return {
    uId: uuidv4(),
    defaultGroup: false,
    groupName: `分组${groupNameIdx + 1}`,
    groupType: null,
    groupCondition: genGroupConditionByFieldOriginalValueType(field)
  };
};

export const transformDateToDateStr = (date, granularity) => {
  return granularity.includes('QUARTER')
    ? `${date.year()}-Q${date.quarter()}`
    : date.format(DATE_MODE_FORMAT_TYPES[granularity]);
};

export const transformDateStrToDate = (dateStr: string, isQuarter?: boolean) => {
  if (isQuarter) {
    const [year, quarter] = dateStr.split('Q');
    const quarterMonthMap = {
      1: '01',
      2: '04',
      3: '07',
      4: '10'
    };
    return dayjs(`${year}${quarterMonthMap[quarter]}`);
  }
  return dayjs(dateStr);
};
