import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';
import _ from 'lodash';
import { WidgetFilterContext } from './ctx';
import { getService } from '@/components/widget/utils';
import FilterAside from './filter-aside';
import FilterHead from './filter-head';
import FilterControl from './filter-control';
import FilterContent from './filter-content';
import '../style/modal-filter.less';

function ModalFilter({ visible, widgetLabel, charts, onCancel, onOk, ...restProps }) {
  const [widget, setWidget] = useState(restProps.widget);
  const filterControlRef = useRef(null);
  const ctxValue = {
    widget,
    setWidget,
    charts
  };

  const handleDateTimeChange = controlAttrs => {
    const { showTime, accuracy } = controlAttrs;
    const newWidget = _.cloneDeep(widget);
    newWidget.options.attrs.showTime = showTime;
    newWidget.options.attrs.accuracy = accuracy;
    if (newWidget.name !== 'timeDateRangeWidget') {
      newWidget.options.attrs.default.isDynamic = false;
    }
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleMultipleChange = controlAttrs => {
    const { multiple } = controlAttrs;
    const newWidget = _.cloneDeep(widget);
    newWidget.options.attrs.multiple = multiple;
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleRangeChange = controlAttrs => {
    const { enableRange, chartIds } = controlAttrs;
    const newWidget = _.cloneDeep(widget);
    newWidget.options.attrs.enableRange = enableRange;
    newWidget.options.attrs.chartIds = chartIds;
    setWidget({
      ...widget,
      ...newWidget
    });
  };

  const handleOk = () => {
    const newWidget = _.cloneDeep(widget);
    const widgetInstance = getService(newWidget.serviceName);
    newWidget.options.value = widgetInstance.setDefaultValue(newWidget);
    if (filterControlRef.current) {
      const { showTitle, mainTitle } = filterControlRef.current.controlAttrs;
      newWidget.style.showTitle = showTitle;
      newWidget.style.mainTitle = mainTitle;
    }
    if (newWidget.hasOwnProperty('refresh')) {
      newWidget.refresh = true;
    }
    onOk(newWidget);
  };

  return (
    <Modal
      title={`${widgetLabel}组件`}
      visible={visible}
      width={840}
      wrapClassName="filter-dialog"
      okButtonProps={{ disabled: !widget.options.attrs.fieldUniqueId }}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <WidgetFilterContext.Provider value={ctxValue}>
        <div className="filter-container">
          <FilterAside />
          <div className="filter-main">
            <FilterHead />
            <FilterControl
              ref={filterControlRef}
              charts={charts}
              onDateTimeChange={handleDateTimeChange}
              onMultipleChange={handleMultipleChange}
              onRangeChange={handleRangeChange}
            />
            <FilterContent />
          </div>
        </div>
      </WidgetFilterContext.Provider>
    </Modal>
  );
}

export default ModalFilter;
