import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Radio, InputNumber, Checkbox, Select } from 'antd';
import { parseFieldValFormatPattern } from './util';
import { VALUE_MAGNITUDE_TYPES } from '@/utils/enum';

const formItemLayout = {
  labelCol: {
    sm: { span: 7 }
  },
  wrapperCol: {
    sm: { span: 17 }
  }
};
const { Option } = Select;
function DisplayFormatSettingForm({ pattern, valueMagnitude }: any, ref) {
  useImperativeHandle(ref, () => ({
    formData
  }));

  const [formData, setFormData] = useState({
    numberType: 'number',
    decimals: 1,
    thousands: true,
    valueMagnitude: 'MAGNITUDE_NONE'
  });

  useEffect(() => {
    initState();
  }, []);

  const initState = () => {
    const { numberType, number, thousands, percent } = parseFieldValFormatPattern(pattern);
    setFormData({
      numberType,
      decimals: numberType === 'number' ? number : percent,
      thousands,
      valueMagnitude
    });
  };

  const handleChange = (key, val) => {
    setFormData({
      ...formData,
      [key]: val
    });
  };
  return (
    <Form {...formItemLayout} colon={false} labelAlign="right">
      <Form.Item label="格式类型">
        <Radio.Group value={formData.numberType} onChange={ev => handleChange('numberType', ev.target.value)}>
          <Radio value="number">数值</Radio>
          <Radio value="percent">百分比</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="保留小数位">
        <InputNumber
          min={0}
          max={10}
          step={1}
          value={formData.decimals}
          onChange={val => handleChange('decimals', val)}
        />
        <span className="ant-form-txt" style={{ marginLeft: '6px' }}>
          位小数
        </span>
      </Form.Item>
      <Form.Item label="使用千分位分隔符">
        <Checkbox checked={formData.thousands} onChange={ev => handleChange('thousands', ev.target.checked)}></Checkbox>
      </Form.Item>
      <Form.Item label="数据量级" style={{ display: formData.numberType === 'number' ? 'block' : 'none' }}>
        <Select value={formData.valueMagnitude} onChange={value => handleChange('valueMagnitude', value)}>
          {Object.keys(VALUE_MAGNITUDE_TYPES).map(key => (
            <Option key={key} value={key}>
              {VALUE_MAGNITUDE_TYPES[key]}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
}

export default forwardRef(DisplayFormatSettingForm);
