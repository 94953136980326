import { observable, makeObservable, action } from 'mobx';
import { OwnerType } from '@/components/ModalDataAuth';
export class DataPermissionStore {
  activeOwnerOperates = [];
  activeOwnerType: OwnerType = 'USER';
  activeTreeNode = {
    key: '', // 当前选中树形节点key
    // ownerType: 'USER',
    // ownerOperates: [], // 当前选中树形节点操作权限
    dataSource: [],
    creator: ''
  };
  visibleModalDataAuth: false;

  constructor() {
    makeObservable(this, {
      activeOwnerOperates: observable,
      activeOwnerType: observable,
      activeTreeNode: observable,
      visibleModalDataAuth: observable,
      setActiveTreeNode: action,
      setActiveOwnerOperates: action,
      setActiveOwnerType: action,
      setActiveTreeNodeAndOwnerOperates: action,
      setActiveOwnerTypeAndOperates: action,
      setVisibleModalDataAuth: action
    });
  }

  setActiveTreeNode(payload) {
    this.activeTreeNode = payload;
  }

  setActiveOwnerOperates(payload) {
    this.activeOwnerOperates = payload;
  }

  setActiveOwnerType(payload) {
    this.activeOwnerType = payload;
  }

  setActiveTreeNodeAndOwnerOperates({ ownerType, ownerOperates, treeNode, visibleModalDataAuth }) {
    this.activeOwnerType = ownerType;
    this.activeOwnerOperates = ownerOperates;
    this.activeTreeNode = treeNode;
    this.visibleModalDataAuth = visibleModalDataAuth;
  }

  setActiveOwnerTypeAndOperates({ ownerType, ownerOperates }) {
    this.activeOwnerType = ownerType;
    this.activeOwnerOperates = ownerOperates;
  }

  setVisibleModalDataAuth(payload) {
    this.visibleModalDataAuth = payload;
  }

  resetStore() {
    this.activeTreeNode = {
      key: '',
      dataSource: [],
      creator: ''
    };
    this.activeOwnerOperates = [];
    this.activeOwnerType = 'USER';
  }
}

export default new DataPermissionStore();
